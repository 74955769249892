import { useState } from 'react'
import { track, useTracking } from 'lib/analytics'
import { InfoButton } from 'components/buttons'
import Close from 'shared/svg/close.svg'
import { useForm } from 'react-hook-form'
import { RadioGroup, Radio, Input } from 'components/forms'
import CategoryChecklist from 'shared/svg/CategoryChecklist.svg'
import { Responses } from '../../global'
import css from './RegistryOrganizationFeedbackModal.styles.scss'

interface RegistryOrganizationFeedbackModalProps {
  closeModal: () => void
  eventLocation: string
  registry: Responses.Registry
}

const RegistryOrganizationFeedbackModal = ({
  closeModal,
  eventLocation,
  registry,
}: RegistryOrganizationFeedbackModalProps) => {
  const { trackEvent } = useTracking()
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit } = useForm()

  const handleCtaClick = (data: any) => {
    setSubmitting(true)
    trackEvent({
      event: track.feedbackModalSubmitted,
      eventLocation,
      featureName: 'registry_organization',
      feedbackValue: data.reason,
      feedbackTextValue: data.freeText || 'None',
      registryId: registry.id,
      registryOwnerId: registry.ownerId,
    })
    closeModal()
  }

  const handleCloseModal = () => {
    trackEvent({
      event: track.feedbackModalDismissed,
      eventLocation,
      featureName: 'registry_organization',
      registryId: registry.id,
      registryOwnerId: registry.ownerId,
    })
    closeModal()
  }

  trackEvent({
    event: track.feedbackModalViewed,
    eventLocation,
    featureName: 'registry_organization',
    registryId: registry.id,
    registryOwnerId: registry.ownerId,
  })

  return (
    <div className={css.RegistryOrganizationFeedbackModal}>
      <div className={css.RegistryOrganizationFeedbackModal__contentWrapper}>
        <div
          className={
            css.RegistryOrganizationFeedbackModal__checklistIconContainer
          }
        >
          <CategoryChecklist
            className={css.RegistryOrganizationFeedbackModal__checklistIcon}
          />
        </div>
        <h4 className={css.RegistryOrganizationFeedbackModal__questionTitle}>
          How easy or difficult is organizing your registry?
        </h4>
        <form onSubmit={handleSubmit(handleCtaClick)}>
          <RadioGroup className="mbl">
            <Radio
              inputRef={register}
              label="Very Easy"
              name="reason"
              value="Very Easy"
            />
            <Radio
              inputRef={register}
              label="Easy"
              name="reason"
              value="Easy"
            />
            <Radio
              inputRef={register}
              label="Neutral"
              name="reason"
              value="Neutral"
            />
            <Radio
              inputRef={register}
              label="Difficult"
              name="reason"
              value="Difficult"
            />
            <Radio
              inputRef={register}
              label="Very Difficult"
              name="reason"
              value="Very Difficult"
            />
          </RadioGroup>
          <Input
            className={css.RegistryOrganizationFeedbackModal__freeText}
            inputRef={register}
            name="freeText"
            placeholder="Please describe why you gave this answer."
          />
          <InfoButton
            block
            className="mtxl"
            submitting={submitting}
            type="submit"
          >
            Submit
          </InfoButton>
        </form>

        <div className={css.RegistryOrganizationFeedbackModal__details}>
          <button
            className={css.RegistryOrganizationFeedbackModal__closeButton}
            data-testid="close-button"
            type="button"
            onClick={handleCloseModal}
          >
            <Close />
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegistryOrganizationFeedbackModal
