import { FC } from 'react'
import { RightArrow } from 'baby-design/icons'
import { Button, Image, TextLink } from 'baby-design'
import { useNavigate } from 'react-router-dom-v5-compat'
import { useQuery } from '@tanstack/react-query'
import dateformat from 'dateformat'
import HorizontalDivider from './icons/HorizontalDivider.svg'
import Calendar from './icons/Calendar.svg'
import {
  FETCH_FEATURED_NEWS_KEY,
  fetchFeaturedNews,
} from '../../../../../api/queries'

import css from './FeaturedNewsSection.styles.scss'

interface PressUpdate {
  title: string
  date: string
  source: string
  logo: string
  url: string
  featuredImageUrl: string
}

interface FeaturedNews {
  featuredArticle: PressUpdate
  secondaryArticles: PressUpdate[]
}

const FeaturedNewsSection: FC = () => {
  const navigate = useNavigate()

  const featuredNewsQuery = useQuery<FeaturedNews>({
    queryKey: [FETCH_FEATURED_NEWS_KEY],
    queryFn: fetchFeaturedNews,
  })

  const featuredArticle = featuredNewsQuery?.data?.featuredArticle

  const dateFormatString = 'mmm d, yyyy'

  return (
    <section className={css.FeaturedNewsSection}>
      <h2 className={css.FeaturedNewsSection__heading}>Featured news</h2>
      <div className={css.FeaturedNewsSection__featuredNews}>
        <div className={css.FeaturedNewsSection__heroEntry}>
          <div
            className={css.FeaturedNewsSection__companyLogoPositioningContainer}
          >
            <div className={css.FeaturedNewsSection__companyLogoWrapper}>
              <Image
                className={css.FeaturedNewsSection__companyLogo}
                src={featuredArticle?.logo as string}
              />
            </div>
          </div>
          <Image
            className={css.FeaturedNewsSection__heroEntryImage}
            src={featuredArticle?.featuredImageUrl as string}
          />
          <div className={css.FeaturedNewsSection__heroEntryDetails}>
            <div className={css.FeaturedNewsSection__heroEntryDateAndSource}>
              <p className={css.FeaturedNewsSection__heroEntryDateWrapper}>
                <Calendar className={css.FeaturedNewsSection__calendarIcon} />
                <span className={css.FeaturedNewsSection__heroEntryDate}>
                  {dateformat(featuredArticle?.date, dateFormatString)}
                </span>
              </p>
              <HorizontalDivider
                className={css.FeaturedNewsSection__heroDivider}
              />
              <p className={css.FeaturedNewsSection__heroEntrySource}>
                {featuredArticle?.source}
              </p>
            </div>
            <h2 className={css.FeaturedNewsSection__heroEntryTitle}>
              {featuredArticle?.title}
            </h2>
            <TextLink
              className={css.FeaturedNewsSection__heroEntryReadMoreLink}
              href={featuredArticle?.url}
              icon={{ side: 'right', icon: <RightArrow /> }}
              size="lg"
            >
              Read More
            </TextLink>
          </div>
        </div>
        <div className={css.FeaturedNewsSection__secondaryFeaturedNews}>
          {featuredNewsQuery?.data?.secondaryArticles
            ?.slice(0, 3)
            ?.map((news: PressUpdate) => (
              <div
                className={css.FeaturedNewsSection__secondaryEntry}
                key={news.title}
              >
                <div
                  className={
                    css.FeaturedNewsSection__secondaryEntryImageWrapper
                  }
                >
                  <Image
                    className={css.FeaturedNewsSection__secondaryEntryImage}
                    src={news.logo}
                  />
                </div>
                <div className={css.FeaturedNewsSection__secondaryEntryDetails}>
                  <h2
                    className={
                      css.FeaturedNewsSection__secondaryEntryTitleContainer
                    }
                  >
                    <TextLink
                      href={news.url}
                      innerContentClassName={
                        css.FeaturedNewsSection__secondaryEntryTitle
                      }
                      size="lg"
                    >
                      {news.title}
                    </TextLink>
                  </h2>
                  <div
                    className={
                      css.FeaturedNewsSection__secondaryEntryDateWrapper
                    }
                  >
                    <HorizontalDivider
                      className={
                        css.FeaturedNewsSection__secondaryEntryDividerIcon
                      }
                    />
                    <p className={css.FeaturedNewsSection__secondaryEntryDate}>
                      {dateformat(news.date, dateFormatString)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Button
        size="md"
        variant="primary"
        onClick={() => navigate('/about/press')}
      >
        See all the news
      </Button>
    </section>
  )
}

export default FeaturedNewsSection
