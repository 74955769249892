import React from 'react'

interface HorizontalDividerProps {
  className?: string
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  className,
}: HorizontalDividerProps) => (
  <svg
    className={className}
    fill="none"
    height="2"
    viewBox="0 0 25 2"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Horizontal Divider Icon</title>
    <path d="M1.08008 1H23.9202" stroke="#6B6669" strokeLinecap="round" />
  </svg>
)

export default HorizontalDivider
