import { FC } from 'react'

import NiceModal from '@ebay/nice-modal-react'

import { track, withContextualizedTracking } from 'lib/analytics'
import { Shipment } from 'src/types/shipments'
import { ExtendedRegistry } from 'src/types/registry'

import ShipNowContext from 'src/contexts/shipNow'

import ShipmentsNavigationCards from 'src/routes/(registry)/shipments/components/ShipmentsNavigationCards'
import ShipmentsLayout from './components/ShipmentsLayout/ShipmentsLayout'
import ShipmentsTitle from './components/ShipmentsTitle/ShipmentsTitle'
import ShipmentsContent from './components/ShipmentsContent/ShipmentsContent'

import { useShipments } from './hooks'
import { useRegistryQuery } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import { useTrackShipmentsPageView } from './hooks/useTrackPageView'
import EventLocationContext from './contexts/EventLocationContext'

const ShipmentsRouteBody: FC<{
  isLoading: boolean
  shipments: Shipment[]
  registry: ExtendedRegistry | undefined
}> = ({ isLoading, shipments, registry }) => {
  // Must be rendered from inside an EventLocationContext.Provider
  useTrackShipmentsPageView()
  const hasAddress = !!registry?.shippingAddress?.address1
  const isBundledShippingEnabled =
    registry?.shippingConsolidation?.consolidationChoice == 'rolling'

  return (
    <ShipmentsLayout>
      <ShipmentsTitle {...{ hasAddress, isBundledShippingEnabled }} />
      <ShipmentsContent {...{ isLoading, shipments, registry }} />
      <ShipmentsNavigationCards />
    </ShipmentsLayout>
  )
}

const ShipmentView = () => {
  const { isLoading: isShipmentsLoading, refetch, shipments } = useShipments()
  const {
    registryQuery: { isLoading: isRegistryLoading },
    registry,
  } = useRegistryQuery()

  const isLoading = isShipmentsLoading || isRegistryLoading

  return (
    <EventLocationContext.Provider value={track.EventLocation.SHIPMENTS}>
      <ShipNowContext.Provider value={{ onShipNowComplete: refetch }}>
        <NiceModal.Provider>
          <ShipmentsRouteBody {...{ isLoading, shipments, registry }} />
        </NiceModal.Provider>
      </ShipNowContext.Provider>
    </EventLocationContext.Provider>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.SHIPMENTS,
})(ShipmentView)
