import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { aboutPath, absoluteUrl } from 'lib/urls'
import AboutHome from './components/AboutHome'

const AboutHomeRoute: FC = () => {
  const title = 'About Babylist | Babylist Inc.'
  const description =
    'Babylist is the trusted platform for millions of growing families, with products and services across health, media, and commerce.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(aboutPath)} rel="canonical" />
      </Helmet>
      <AboutHome />
    </div>
  )
}

export default AboutHomeRoute
