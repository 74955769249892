import React from 'react'
import { registryChecklistPath, registryGiftTrackerPath } from 'lib/urls'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useNavigate } from 'react-router-dom-v5-compat'
import { track, useTracking } from 'lib/analytics'
import { BABY_REGISTRY_TYPE } from 'registry/constants'
import GiftTrackerCard from 'src/routes/(registry)/components/GiftTrackerCard'
import ChecklistCard from 'src/routes/(registry)/components/ChecklistCard'
import css from './MobileActions.styles.scss'
import { MobileActionsProps } from './MobileActions.types'

const MobileActions: React.FC<MobileActionsProps> = ({ currentRegistry }) => {
  const { id: registryId, ownerId: registryOwnerId } = currentRegistry || {}
  const tracker = useTracking()
  const navigate = useNavigate()
  const [currentUser] = useCurrentUser()
  const isRegistryOwner =
    currentRegistry && currentUser && currentRegistry.ownerId === currentUser.id
  const listType = currentRegistry?.type || BABY_REGISTRY_TYPE
  const shouldShowChecklist = listType === BABY_REGISTRY_TYPE

  const handleChecklistClick = () => {
    tracker.trackEvent({
      checklistType: 1,
      event: track.checklistViewed,
      eventLocation: track.EventLocation.REGISTRY,
      registryId,
      registryOwnerId,
      listType,
      isRegistryOwner,
    })
    navigate(registryChecklistPath)
  }

  const handleGiftTrackerClick = () => {
    tracker.trackEvent({
      checklistType: 1,
      event: track.giftTrackerClicked,
      eventLocation: track.EventLocation.REGISTRY,
      registryId,
      registryOwnerId,
      listType,
      isRegistryOwner,
    })
    navigate(registryGiftTrackerPath)
  }

  return (
    <div className={css.MobileActions__skeleton}>
      {shouldShowChecklist && (
        <ChecklistCard
          currentRegistry={currentRegistry}
          variant="mobile"
          onClick={handleChecklistClick}
        />
      )}
      <GiftTrackerCard
        currentRegistry={currentRegistry}
        variant="mobile"
        onClick={handleGiftTrackerClick}
      />
    </div>
  )
}

export default MobileActions
