import React from 'react'
import css from './ShipmentDetailsMetadata.styles.scss'

const MetadataSkeleton: React.FC = () => (
  <div className={css.ShipmentDetailsMetadata__shimmer}>
    <div className={css.ShipmentDetailsMetadata__Title} />
    <div className={css.ShipmentDetailsMetadata__Content}>
      <div />
      <div />
    </div>
  </div>
)

const ShipmentDetailsMetadataSkeleton: React.FC = () => (
  <div className={css.ShipmentDetailsMetadata__Container}>
    <MetadataSkeleton />
    <MetadataSkeleton />
  </div>
)

export default ShipmentDetailsMetadataSkeleton
