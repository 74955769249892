import React from 'react'
import { EstimatedShipDatesProps } from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalBody/components/EstimatedShipDates/EstimatedShipDates.types'
import { shipNowNewShipDate } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import {
  Info,
  RightArrow,
} from '../../../../../../../../../../baby-design/icons'
import css from './EstimatedShipDates.styles.scss'

interface ShipDateProps {
  date: Date
  title: string
}

const ShipDate: React.FC<ShipDateProps> = ({ date, title }) => {
  const dateFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions

  const formattedDate = new Intl.DateTimeFormat(
    'en-US',
    dateFormatOptions
  ).format(date)

  return (
    <div className={css.ShipDate}>
      <span className={css.ShipDate__Title}>{title}</span>
      <span className={css.ShipDate__Date}>{formattedDate}</span>
    </div>
  )
}

const THREE_DAYS_IN_MS = 3 * 24 * 3600 * 1000

const EstimatedShipDates: React.FC<EstimatedShipDatesProps> = ({
  shipment,
}) => {
  const currentShipDateStr = shipment.shipmentStatus?.estimatedShippingDate
  if (!currentShipDateStr) return null

  const currentShipDate = Date.parse(currentShipDateStr)
  if (!currentShipDate) return null

  const newShipDateStr = shipNowNewShipDate(shipment)
  const newShipDate = newShipDateStr ? Date.parse(newShipDateStr) : undefined

  const isLongProcessingTime =
    newShipDate && newShipDate - Date.now() >= THREE_DAYS_IN_MS

  return (
    <>
      <div className={css.EstimatedShipDates}>
        <ShipDate date={new Date(currentShipDate)} title="Original ship date" />
        {newShipDate && (
          <>
            <RightArrow className={css.EstimatedShipDates__Arrow} />
            <ShipDate date={new Date(newShipDate)} title="New ship date" />
          </>
        )}
      </div>
      {isLongProcessingTime && (
        <div
          className={css.EstimatedShipDates__Notice}
          data-testid="long-processing-times-notice"
        >
          <Info className={css.EstimatedShipDates__Notice__Icon} />
          <span className={css.EstimatedShipDates__Notice__Text}>
            Our warehouses are currently experiencing longer processing times.
            We appreciate your patience as we work to ship your order as quickly
            as possible.
          </span>
        </div>
      )}
    </>
  )
}

export default EstimatedShipDates
