import React from 'react'
import classNames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import { TextLink } from 'baby-design'
import { SimpleShipping } from 'baby-design/icons'
import {
  ConsolidatedShippingCallout as DSCallout,
  ConsolidatedShippingExplainerModal,
} from 'src/components'
import { CONSOLIDATED_SHIPPING_COPY } from 'src/constants'
import { ConsolidatedShippingCalloutProps } from './ConsolidatedShippingCallout.types'
import css from './ConsolidatedShippingCallout.styles.scss'

const dataTestId = 'reservation-consolidated-shipping-callout'

const ConsolidatedShippingCallout: React.FC<
  ConsolidatedShippingCalloutProps
> = ({ variant }) => {
  const classes = classNames(
    css.ConsolidatedShippingCallout,
    css[`ConsolidatedShippingCallout--${variant}`]
  )

  const buildSimpleCalloutClass = (className: string | null = null) =>
    classNames(
      css[
        `ConsolidatedShippingCallout__simpleCallout${className ? `__${className}` : ''}`
      ],
      css['ConsolidatedShippingCallout--green']
    )

  const onClick = async () => {
    await NiceModal.show(ConsolidatedShippingExplainerModal)
  }

  return (
    <div className={classes} data-testid={dataTestId}>
      {variant === 'mobile' ? (
        <DSCallout text="This gift may be packaged with other items." />
      ) : (
        <div
          className={buildSimpleCalloutClass()}
          data-testid={`${dataTestId}-simple`}
        >
          <div className={buildSimpleCalloutClass('title')}>
            <span className={buildSimpleCalloutClass('title__text')}>
              {CONSOLIDATED_SHIPPING_COPY}
            </span>
            <SimpleShipping
              filled
              className={buildSimpleCalloutClass('title__icon')}
            />
          </div>
          <div className={buildSimpleCalloutClass('body')}>
            <span className={buildSimpleCalloutClass('body__text')}>
              This gift may be packaged with other items.{' '}
              <TextLink
                underline
                innerContentClassName={buildSimpleCalloutClass('body__link')}
                size="md"
                onClick={onClick}
              >
                Learn more
              </TextLink>
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
export default ConsolidatedShippingCallout
