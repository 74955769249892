import { TextLink } from 'baby-design'
import { BABYLIST_EXTENSION_URL } from 'shared/constants'
import { AlternateInstructionsProps } from './AlternateInstructions.types'
import css from './AlternateInstructions.styles.scss'

const AlternateInstructions: React.FC<AlternateInstructionsProps> = ({
  setBrowser,
}) => (
  <div className={css.AlternateInstructions}>
    <p className={css.listHeading}>Other options:</p>
    <ul className={css.listItems}>
      <li>
        Get the Babylist button for other browsers:{' '}
        <TextLink underline size="lg" onClick={() => setBrowser('Firefox')}>
          Firefox
        </TextLink>
        ,{' '}
        <TextLink underline size="lg" onClick={() => setBrowser('Safari')}>
          Safari
        </TextLink>
        , and{' '}
        <TextLink
          underline
          size="lg"
          onClick={() => setBrowser('Microsoft Edge')}
        >
          Edge
        </TextLink>
        .
      </li>
      <li>
        Install our{' '}
        <TextLink
          underline
          href={BABYLIST_EXTENSION_URL}
          size="lg"
          target="_blank"
        >
          Chrome browser plugin
        </TextLink>
        .
      </li>
    </ul>
  </div>
)

export default AlternateInstructions
