import { useMemo } from 'react'
import { ProgressBar } from 'components/progress-bar'
import { Collapse } from 'react-bootstrap'
import { track, useTracking } from 'lib/analytics'
import { HEALTH_SAFETY_CHECKLIST_CATEGORY_ID } from 'shared/constants'
import { ChecklistFsaHsaBanner } from '../ChecklistFsaHsaBanner/ChecklistFsaHsaBanner'
import { useExpandedState } from '../../api/hooks'
import { ChecklistCategory } from '..'
import { ParentCategory } from '../../RegistryChecklistApp.types'
import css from './styles.scss'

interface ChecklistThemeProps extends ParentCategory {
  defaultExpanded?: boolean
  registryId: number
}

export const ChecklistTheme = (props: ChecklistThemeProps) => {
  const {
    categories,
    checklistType,
    completedCategoriesCount,
    completedImageUrl,
    defaultExpanded,
    id,
    imageUrl,
    name,
    registryId,
    totalCategoriesCount,
  } = props
  const tracker = useTracking()
  const [expanded, toggleExpanded] = useExpandedState({
    storageKey: `checklistThemeExpanded:${registryId}:${id}`,
    defaultValue: defaultExpanded,
  })
  const isThemeCompleted = completedCategoriesCount === totalCategoriesCount

  const MemoizedCategories = useMemo(
    () => (
      <ul className="list-unstyled man bts">
        {categories.map((category) => (
          <ChecklistCategory
            key={category.id}
            {...category}
            registryId={registryId}
          />
        ))}
      </ul>
    ),
    [categories]
  )

  const percentCompleted =
    (completedCategoriesCount / totalCategoriesCount) * 100

  const handleThemeClick = () => {
    if (!expanded) {
      // Only track if the user is logged in
      if (registryId) {
        tracker.trackEvent({
          checklistCategoryId: id,
          checklistCategoryName: name,
          checklistType,
          event: track.checklistCategoryViewed,
          registryId,
        })
      }
    }

    toggleExpanded()
  }

  const displayFsaHsaBanner = id === HEALTH_SAFETY_CHECKLIST_CATEGORY_ID

  return (
    <>
      <button
        className={css.themeWrapper}
        type="button"
        onClick={handleThemeClick}
      >
        <div className={css.themeContent}>
          <img
            alt={name}
            className={css.themeIcon}
            src={!isThemeCompleted && registryId ? imageUrl : completedImageUrl}
          />
          <h2 className={css.themeName}>{name}</h2>
          <div className={css.itemsCount}>
            {`${completedCategoriesCount}/${totalCategoriesCount}`}
          </div>
          <div
            aria-hidden
            className={`fa fa-chevron-${expanded ? 'up' : 'down'} ${
              css.chevronIcon
            }`}
          />
        </div>
        <ProgressBar
          backgroundColor="#fff"
          color="#a884a2"
          height={6}
          percent={percentCompleted}
          radius={10}
        />
      </button>
      <Collapse in={expanded}>
        <div>
          {MemoizedCategories}
          {displayFsaHsaBanner && <ChecklistFsaHsaBanner />}
        </div>
      </Collapse>
    </>
  )
}
