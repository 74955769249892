import { useEffect, useState } from 'react'

import { Button, TextInput, RightDrawer } from 'baby-design'
import classNames from 'classnames'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/analytics/track'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import css from './ShareYourRegistryDrawer.styles.scss'

interface ShareYourRegistryDrawerProps {
  onPreviewClick: () => void
  registryLink: string
}

export default NiceModal.create(
  ({ onPreviewClick, registryLink }: ShareYourRegistryDrawerProps) => {
    const modal = useModal()
    const [currentUser] = useCurrentUser()
    const tracker = useTracking()
    const trimmedLink = registryLink.replace('www.', '')
    const [copySuccess, setCopySuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    useEffect(() => {
      if (modal.visible) {
        return
      }
      setCopySuccess(false)
      setErrorMessage('')
    }, [modal.visible])

    useEffect(() => {
      if (copySuccess) {
        const timeoutId = setTimeout(() => {
          setCopySuccess(false)
        }, 1500)

        return () => clearTimeout(timeoutId)
      }
    }, [copySuccess])

    const handleCopyClick = async () => {
      setErrorMessage(undefined)
      try {
        await navigator.clipboard.writeText(registryLink)
        setCopySuccess(true)
        tracker.trackEvent({
          event: track.listShared,
          eventLocation: track.EventLocation.REGISTRY,
          registryId: currentUser?.currentRegistry.id,
          registryOwnerId: currentUser?.currentRegistry.ownerId,
          listType: currentUser?.currentRegistry.type || 'baby_registry',
          isRegistryOwner:
            currentUser?.currentRegistry.ownerId === currentUser?.id,
        })
      } catch (error) {
        console.error('Failed to copy: ', error)
        setErrorMessage(
          'Failed to copy link to clipboard. Please copy the link manually or update your browser permissions.'
        )
      }
    }

    return (
      <RightDrawer
        handleClose={modal.hide}
        handleDismount={modal.remove}
        isOpen={modal.visible}
        title="Share Your Registry"
      >
        <div className={css.ShareYourRegistryDrawer__container}>
          <h2 className={css.ShareYourRegistryDrawer__heading}>
            Share the link to your registry
          </h2>
          <p className={css.ShareYourRegistryDrawer__description}>
            Spread the word! Copy and share your registry link so friends and
            family can start shopping.
          </p>
          <div className={css.ShareYourRegistryDrawer__copyContainer}>
            <TextInput
              className={css.ShareYourRegistryDrawer__input}
              inputProps={{
                readOnly: true,
                value: trimmedLink,
              }}
              size="lg"
            />
            <Button
              className={classNames(
                css.ShareYourRegistryDrawer__button,
                css['ShareYourRegistryDrawer__button--preview']
              )}
              size="sm"
              type="button"
              variant="secondary"
              onClick={onPreviewClick}
            >
              Preview
            </Button>
            <Button
              className={classNames(
                css.ShareYourRegistryDrawer__button,
                css['ShareYourRegistryDrawer__button--copy']
              )}
              size="sm"
              type="button"
              variant="primary"
              onClick={handleCopyClick}
            >
              {copySuccess ? 'Success!' : 'Copy URL'}
            </Button>
            {errorMessage && (
              <p className={css.ShareYourRegistryDrawer__errorMessage}>
                {errorMessage}
              </p>
            )}
          </div>
        </div>
      </RightDrawer>
    )
  }
)
