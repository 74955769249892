import React, { useState } from 'react'
import { track, useTracking } from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import PrivacySettingsEditModal from './PrivacySettingsEditModal/PrivacySettingsEditModal'
import RegistryGreeting from './RegistryGreeting/RegistryGreeting'
import RegistryHeading from './RegistryHeading/RegistryHeading'
import RegistryPrivacyStatus from './RegistryPrivacyStatus/RegistryPrivacyStatus'
import { MOBILE } from '../../constants'
import css from './RegistryDetails.styles.scss'
import { RegistryDetailsProps } from './RegistryDetails.types'

const RegistryDetails: React.FC<RegistryDetailsProps> = ({
  currentRegistry,
}) => {
  const tracker = useTracking()
  const [currentUser] = useCurrentUser()
  const [privacySettingsEditModalIsOpen, setPrivacySettingsEditModalIsOpen] =
    useState(false)

  const handleClickPrivacyStatus = () => {
    if (!currentRegistry || !currentUser) return

    tracker.trackEvent({
      event: track.visibilityPrivacyClicked,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: currentRegistry.id,
      registryOwnerId: currentRegistry.ownerId,
      listType: currentRegistry.type || 'baby_registry',
      isRegistryOwner: currentRegistry.ownerId === currentUser.id,
    })
    setPrivacySettingsEditModalIsOpen(true)
  }

  return (
    <div className={css.RegistryDetails__skeleton}>
      <div className={css.RegistryDetails__registryInfo}>
        <RegistryPrivacyStatus
          currentRegistry={currentRegistry}
          viewType={MOBILE}
          onClick={handleClickPrivacyStatus}
        />
        <RegistryHeading
          currentRegistry={currentRegistry}
          handlePrivacyStatusClick={handleClickPrivacyStatus}
        />
        <RegistryGreeting currentRegistry={currentRegistry} />
      </div>
      <PrivacySettingsEditModal
        currentRegistry={currentRegistry}
        handleClose={() => setPrivacySettingsEditModalIsOpen(false)}
        isOpen={privacySettingsEditModalIsOpen}
      />
    </div>
  )
}

export default RegistryDetails
