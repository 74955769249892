import { FC } from 'react'

import { useLocation, useParams } from 'react-router-dom-v5-compat'
import NiceModal from '@ebay/nice-modal-react'

import { track, withContextualizedTracking } from 'lib/analytics'
import { shipmentViewPath } from 'lib/urls'
import { Shipment } from 'src/types/shipments'
import ShipNowContext from 'src/contexts/shipNow'

import { ShipmentsAPI } from 'src/routes/(registry)/shipments/api/shipments'
import ShipmentsLayout from 'src/routes/(registry)/shipments/components/ShipmentsLayout/ShipmentsLayout'
import ShipmentDetailsTitle from 'src/routes/(registry)/shipments/[shipmentId]/components/ShipmentDetailsTitle/ShipmentDetailsTitle'
import ShipmentDetailsContent from 'src/routes/(registry)/shipments/[shipmentId]/components/ShipmentDetailsContent/ShipmentDetailsContent'

import { useTrackShipmentDetailsPageView } from '../hooks/useTrackPageView'
import EventLocationContext from '../contexts/EventLocationContext'

const ShipmentDetailsRouteBody: FC<{ shipment: Shipment | undefined }> = ({
  shipment,
}) => {
  // Must be rendered from inside an EventLocationContext.Provider
  useTrackShipmentDetailsPageView()

  return (
    <ShipmentsLayout>
      <ShipmentDetailsTitle shipment={shipment} />
      <ShipmentDetailsContent shipment={shipment} />
    </ShipmentsLayout>
  )
}

const ShipmentDetailsView = () => {
  const { shipmentId } = useParams()
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const useFakeData = queryParams.get('fake_data')
  const scenario = queryParams.get('scenario')

  const { data: shipment } = ShipmentsAPI.useShow(shipmentId as string, {
    fakeData: useFakeData === 'true',
    scenario,
  })

  const onShipNowComplete = () => {
    // don't use react routing so we can force a reload
    window.location.href = shipmentViewPath
  }

  return (
    <EventLocationContext.Provider value={track.EventLocation.SHIPMENT_DETAILS}>
      <ShipNowContext.Provider value={{ onShipNowComplete }}>
        <NiceModal.Provider>
          <ShipmentDetailsRouteBody shipment={shipment} />
        </NiceModal.Provider>
      </ShipNowContext.Provider>
    </EventLocationContext.Provider>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.SHIPMENT_DETAILS,
})(ShipmentDetailsView)
