import fetch from 'lib/fetch'
import { useQuery } from '@tanstack/react-query'

const CACHE_KEY = ['navbar_categories']
const API_PATH = '/store/api/navbar_categories'
const STALE_TIME = 1000 * 60 * 60 * 4 // 4 hours

interface Guide {
  featuredImageUrl: string
  title: string
  url: string
}

interface Category {
  title: string
  urlSlug: string
  children?: Category[]
  guides?: Guide[]
}

const fetchIndex = async (): Promise<Category[]> => await fetch(API_PATH)

const useIndex = () =>
  useQuery({ queryKey: CACHE_KEY, queryFn: fetchIndex, staleTime: STALE_TIME })

export const NavbarCategoriesAPI = {
  useIndex,
}
