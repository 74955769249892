import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge, PricingTreatment, PromoMessage } from 'baby-design'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import { RegItemPricingLockupProps } from './RegItemPricingLockup.types'
import css from './RegItemPricingLockup.styles.scss'
import {
  getBadgeSize,
  getPricingData,
  getPricingTreatmentSize,
  getPromoMessages,
  getPromoMessageSize,
  getSaleBadgeLabel,
  isRegItemOnPromoCodeSale,
  isRegItemOnSale,
} from './RegItemPricingLockup.utils'

const RegItemPricingLockup: React.FC<RegItemPricingLockupProps> = ({
  regItem,
  size = 'medium',
}) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const { isBabyListTheLowestPrice, strikePrice, price } =
    getPricingData(regItem)
  const isOnSale = isRegItemOnSale(regItem)
  const isOnPromoCodeSale = isRegItemOnPromoCodeSale(regItem)
  const saleBadgeLabel = getSaleBadgeLabel(regItem)
  const pricingTreatmentSize = getPricingTreatmentSize(isMobile, size)
  const badgeSize = getBadgeSize(isMobile, size)
  const promoMessageSize = getPromoMessageSize(isMobile, size)
  const showSaleTreatment = isOnSale && isBabyListTheLowestPrice
  const promoMessages = getPromoMessages(regItem)

  return (
    <div
      className={css.RegItemPricingLockup}
      data-testid="RegItemPricingLockup"
    >
      <PricingTreatment
        price={price}
        saleVariant={showSaleTreatment ? 'red' : 'default'}
        size={pricingTreatmentSize}
        strikePrice={isBabyListTheLowestPrice ? strikePrice : undefined}
        weightVariant="bold"
      />
      {showSaleTreatment && (
        <Badge
          fill="discount-ruby"
          label={saleBadgeLabel}
          size={badgeSize}
          variant="promo"
        />
      )}
      {isBabyListTheLowestPrice &&
        isOnPromoCodeSale &&
        promoMessages.length > 0 &&
        promoMessages.map((promoMessage) => (
          <div className={css.RegItemPricingLockup__SaleCallout}>
            <PromoMessage
              key={promoMessage}
              size={promoMessageSize}
              text={promoMessage}
            />
          </div>
        ))}
    </div>
  )
}

export default RegItemPricingLockup
