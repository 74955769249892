import classNames from 'classnames'
import PostCard from 'hello_baby/components/PostCard'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import {
  PREGNANCY_WEEK_BY_WEEK_SLUG,
  PREGNANCY_WBW_FIRST_TRIMESTER_SLUG,
  PREGNANCY_WBW_FIRST_TRIMESTER_TEASER,
  PREGNANCY_WBW_SECOND_TRIMESTER_SLUG,
  PREGNANCY_WBW_SECOND_TRIMESTER_TEASER,
  PREGNANCY_WBW_THIRD_TRIMESTER_SLUG,
  PREGNANCY_WBW_THIRD_TRIMESTER_TEASER,
} from 'hello_baby/constants'
import { StyledSubHeading } from 'components/headings'
import css from './PregnancyWeekByWeekView.styles.scss'
import PostTeaserList from '../PostTeaserList/PostTeaserList'
import { usePosts } from '../../utils/useQueries'

const PregnancyWeekByWeekView = () => {
  const posts = usePosts()

  const weekByWeekPosts = filterPostsByTopicSlug(
    posts,
    PREGNANCY_WEEK_BY_WEEK_SLUG,
    { sortBy: 'title', order: 'asc' }
  )

  const sections = [
    {
      header: 'First Trimester',
      title: 'Weeks 4 - 12',
      entry: posts[PREGNANCY_WBW_FIRST_TRIMESTER_SLUG],
      teaserText: PREGNANCY_WBW_FIRST_TRIMESTER_TEASER,
      posts: weekByWeekPosts.slice(0, 9),
      style: 'weekByWeekHeader__top',
    },
    {
      header: 'Second Trimester',
      title: 'Weeks 13 - 27',
      entry: posts[PREGNANCY_WBW_SECOND_TRIMESTER_SLUG],
      teaserText: PREGNANCY_WBW_SECOND_TRIMESTER_TEASER,
      posts: weekByWeekPosts.slice(9, 24),
      style: 'weekByWeekHeader',
    },
    {
      header: 'Third Trimester',
      title: 'Weeks 28 - 42',
      entry: posts[PREGNANCY_WBW_THIRD_TRIMESTER_SLUG],
      teaserText: PREGNANCY_WBW_THIRD_TRIMESTER_TEASER,
      posts: weekByWeekPosts.slice(24),
      style: 'weekByWeekHeader',
    },
  ]

  return (
    <div className={css.weekByWeekContainer}>
      {sections.map((section) => (
        <div key={section.title}>
          <StyledSubHeading
            className={classNames(css[section.style], 'clearfix mbm h3')}
          >
            {section.header}
          </StyledSubHeading>
          <hr className="mbl" />
          <PostCard
            cardSize="medium"
            className="mbl pbl"
            image={section.entry?.featuredImage?.url}
            slug={section.entry?.slug || ''}
            teaserText={section.teaserText}
            title={section.title}
          />
          <PostTeaserList posts={section.posts} />
        </div>
      ))}
    </div>
  )
}

export default PregnancyWeekByWeekView
