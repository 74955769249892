import { useTracking } from 'lib/analytics'
import { ProductCallout } from 'src/types/productCallout'
import { trackTooltipClicked } from 'src/routes/(shop)/lib/tracking'
import { MessageWithTooltip } from '../MessageWithTooltip'
import css from './ProductCallouts.styles.scss'

interface ProductCalloutsProps {
  callouts: ProductCallout[]
}

export const ProductCallouts = ({ callouts }: ProductCalloutsProps) => {
  const { trackEvent } = useTracking()

  return (
    <>
      <div className={css.ProductCallouts}>
        {callouts.map((callout, index) => (
          <>
            <div
              className={css.ProductCallouts__container}
              key={`callout-${index}`}
            >
              {callout.tooltip ? (
                <MessageWithTooltip
                  content={callout.tooltip.body}
                  message={callout.text}
                  type="callout-info"
                  onClick={() => {
                    trackTooltipClicked({
                      trackEvent,
                      tooltipEventType: callout.tooltip.eventType,
                    })
                  }}
                />
              ) : (
                <>{callout.text}</>
              )}
            </div>
          </>
        ))}

        {/* Hidden modal content for bots */}
        <div data-testid="seo-callouts" style={{ display: 'none' }}>
          {callouts.map((callout, index) =>
            callout.tooltip ? (
              <div key={`seo-callout-${index}`}>
                <h2>{callout.tooltip.title}</h2>
                <p>{callout.tooltip.body}</p>
              </div>
            ) : null
          )}
        </div>
      </div>
    </>
  )
}
