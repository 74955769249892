import { Tooltip, IconButton } from 'baby-design'
import { Info } from 'baby-design/icons'
import React from 'react'
import css from './MessageWithTooltip.styles.scss'

export interface TooltipProps {
  message?: string
  content?: React.ReactNode | string
  type: string
  onClick?: () => void
}

export const MessageWithTooltip = ({
  message,
  content,
  type,
  onClick,
}: TooltipProps) => (
  <div className={css.MessageWithTooltip__container}>
    {message}
    <Tooltip
      showCloseButton
      className={css.MessageWithTooltip__tooltip}
      content={content}
      openOn="click"
      position="bottom"
      trigger={
        <IconButton
          aria-label="More information"
          className={css.MessageWithTooltip__tooltipButton}
          data-testid={`${type}-button-tooltip`}
          size="xs"
          variant="standard-default"
          onClick={onClick}
        >
          <Info />
        </IconButton>
      }
    />
  </div>
)
