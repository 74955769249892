import React from 'react'
import dateformat from 'dateformat'
import { useReservationCardContext } from '../../context'
import { AdditionalPurchaseDetails, PurchaseDetail } from './components'
import css from './PurchaseDetails.styles.scss'

const PurchaseDetails: React.FC = () => {
  const { isPurchased, purchasedAt, createdAt, quantity, regItem } =
    useReservationCardContext()

  const purchasedOrReserved = isPurchased ? 'Purchased' : 'Reserved'
  const displayDate = isPurchased ? purchasedAt : createdAt
  const showAdditionalDetails = regItem && !regItem.isCrowdfund && isPurchased

  return (
    <div className={css.PurchaseDetails}>
      <PurchaseDetail
        label={purchasedOrReserved}
        value={dateformat(displayDate as string, 'mediumDate')}
      />
      <PurchaseDetail label="Qty" value={quantity} />
      {showAdditionalDetails && <AdditionalPurchaseDetails />}
    </div>
  )
}

export default PurchaseDetails
