import { useState } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { Modal, ModalBody } from 'components/modal'
import { saveCashFundPreferences } from 'registry/api'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { AddToBabylistButton } from 'src/routes/(shop)/components/AddToBabylistButton/AddToBabylistButton'
import NiceModal from '@ebay/nice-modal-react'
import { AddCashFundDrawer } from 'src/routes/(shop)/components/AddCashFundDrawer/AddCashFundDrawer'
import { RegItem } from 'src/types/regItem'
import CrowdfundForm from '../../../../../../../../../../store/components/forms/crowdfund-form'
import { CurrentUser } from '../../../../../../../../../../global'

interface AddRegItemArgs {
  // eslint-disable-next-line camelcase
  category_id: number | null
  description: string | null
  title: string | null
}

const CASH_CATEGORY_ID = -13

const isRegistryOwner = (currentUser: CurrentUser | undefined) =>
  currentUser && currentUser.currentRegistry?.ownerId === currentUser.id

export const AddToBabylist = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const [showModal, updateShowModal] = useState(false)
  const [currentUser] = useCurrentUser()

  if (!genericProduct?.activeProduct?.approved) return null

  const { activeProduct } = genericProduct

  const handleAddToBabylist = () => {
    NiceModal.show(AddCashFundDrawer, {
      regItem: {
        categoryId: CASH_CATEGORY_ID,
        registryId: currentUser?.registryId as number,
        productId: activeProduct.id,
        quantity: 1,
        title: activeProduct.name,
        isCrowdfund: true,
        isTitleEditable: true,
        imgUrl: activeProduct?.images[0]?.url,
      } as RegItem,
    })
  }

  return (
    <>
      <AddToBabylistButton
        quantity={1}
        showSuccessMessage={false}
        onClick={handleAddToBabylist}
      >
        {({ addRegItem }) => (
          <Modal
            show={showModal}
            title="Add a Cash Fund"
            onHide={() => updateShowModal(false)}
          >
            <ModalBody>
              <CrowdfundForm
                cashFundPreferences={
                  currentUser?.currentRegistry.cashFundPreferences
                }
                currentRegistryTitle={currentUser?.currentRegistry?.title}
                currentRegistryUrlSlug={currentUser?.currentRegistry?.urlSlug}
                imageUrl={activeProduct?.images[0]?.thumbnailUrl}
                isRegistryOwner={isRegistryOwner(currentUser)}
                title={activeProduct?.name}
                onHide={() => updateShowModal(false)}
                onSaveCashFundPreferences={saveCashFundPreferences}
                onSubmit={({
                  title,
                  description,
                  // eslint-disable-next-line camelcase
                  category_id,
                }: AddRegItemArgs) =>
                  addRegItem({
                    description,
                    title,
                    category_id,
                    onSuccess: () => updateShowModal(false),
                  })
                }
              />
            </ModalBody>
          </Modal>
        )}
      </AddToBabylistButton>
    </>
  )
}
