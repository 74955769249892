import React from 'react'
import classNames from 'classnames'
import { Accordion } from 'components/accordion'
import { TextLink } from 'baby-design'
import Chevron from 'baby-design/icons/Chevron.svg'

import { BABYLIST_EXTENSION_URL, CONTACT_US_URL } from 'shared/constants'
import {
  addItemsPath,
  shareExtensionPath,
  manageCookiesPath,
  wildCardCouponPath,
  helpAndFavorsPath,
  cashFundsPath,
  mobileAppDownloadPath,
  youtubeChromeTutorialUrl,
  youtubeFirefoxTutorialUrl,
  youtubeInternetExplorerTutorialUrl,
  youtubeSafariTutorialUrl,
} from 'lib/urls'
import { browserName } from 'lib/bowser'
import { track } from 'lib/analytics'
import { FrequentlyAskedQuestionsProps } from './FrequentlyAskedQuestions.types'
import css from './FrequentlyAskedQuestions.styles.scss'

interface AccordionTriggerProps {
  onClick: () => void
  isOpen: boolean
}

const enum FAQItems {
  AddFromAnywhere = 1,
  AddFromOtherStores = 2,
  AddWithoutButton = 3,
  WhereIsExtension = 4,
  ButtonNotWorking = 5,
  SizeAndColor = 6,
  GiftCards = 7,
  AddWithoutLink = 8,
}

const chromeUser = browserName === 'Chrome'

// statically hard-code the FAQs, since they are not expected to change frequently
const faqItems = [
  {
    id: FAQItems.AddFromAnywhere,
    show: true,
    question: 'Can I add from anywhere?',
    answer: (
      <span className={css.questionsContainer__answer}>
        Yes, our universal registry lets you add items from anywhere including
        popular stores like Amazon, Target, Walmart, Etsy and more! Plus, you
        can add items from specialty stores, boutiques or small businesses.
        Whatever you find online, you can add it here!
        <br />
        <br />
        If you live outside of the US, be sure that the items you add to your
        registry can be delivered to your address. This will prevent your
        friends and family members from encountering any shipping issues or
        expensive international charges.
        <br />
        <br />
        {chromeUser ? (
          <>
            The easiest way to add from anywhere on Chrome is to{' '}
            <TextLink
              underline
              href={BABYLIST_EXTENSION_URL}
              size="md"
              target="_blank"
            >
              install the Babylist Button Chrome extension
            </TextLink>
            .
          </>
        ) : (
          <>
            If you're trying to add items from other websites while on a
            computer,{' '}
            <TextLink underline href={addItemsPath} size="md" target="_blank">
              click here and follow the directions
            </TextLink>
            .
          </>
        )}{' '}
        You can also download our iOS or Android app. Tap the "+Add Items" tab
        at the bottom. Then you can go to any website and add any item –
        seriously anything!
      </span>
    ),
  },
  {
    id: FAQItems.AddFromOtherStores,
    show: true,
    question: 'How do I add from other stores?',
    answer: (
      <span className={css.questionsContainer__answer}>
        There are a few ways to add items to your Babylist registry from other
        stores:
        <br />
        <br />
        <ul>
          <li>
            <b>On any desktop:</b>{' '}
            <TextLink underline href={addItemsPath} size="md" target="_blank">
              Follow the directions here
            </TextLink>{' '}
            to add the Babylist browser button to your computer's web browser.
          </li>
          <li>
            <b>If you're using Chrome:</b> You can{' '}
            <TextLink
              underline
              href={BABYLIST_EXTENSION_URL}
              size="md"
              target="_blank"
            >
              install our extension here.
            </TextLink>
          </li>
          <li>
            <b>On mobile:</b> Download our{' '}
            <TextLink
              underline
              href={mobileAppDownloadPath('', 'apple')}
              size="md"
              target="_blank"
            >
              iOS
            </TextLink>{' '}
            or{' '}
            <TextLink
              underline
              href={mobileAppDownloadPath('', 'google')}
              size="md"
              target="_blank"
            >
              Android
            </TextLink>{' '}
            app and tap the "+ Any Items" tab at the bottom.
          </li>
          <li>
            If you're using an iOS device, you can also turn on the "
            <TextLink
              underline
              href={shareExtensionPath}
              size="md"
              target="_blank"
            >
              Share to Babylist
            </TextLink>
            " feature in your mobile browser.{' '}
          </li>
        </ul>
      </span>
    ),
  },
  {
    id: FAQItems.AddWithoutButton,
    show: true,
    question:
      "How can I add items from other stores without installing the 'Add to Babylist Button' on my browser?",
    answer: (
      <span className={css.questionsContainer__answer}>
        If you don't want to download the 'Add To Babylist Button' you can add
        items from your mobile device by downloading our iOS or Android app and
        tap the "+ Any Items" tab at the bottom. If you're using an iOS device,
        you can also{' '}
        <TextLink underline href={shareExtensionPath} size="md" target="_blank">
          share a link to Babylist
        </TextLink>{' '}
        from your mobile browser.
      </span>
    ),
  },
  {
    id: FAQItems.WhereIsExtension,
    show: chromeUser,
    question:
      'I installed the Chrome extension but I am having trouble finding it. Where is it?',
    answer: (
      <span className={css.questionsContainer__answer}>
        Follow these steps to find the Babylist Chrome extension:
        <br />
        <br />
        <ul>
          <li>
            Click on the puzzle piece (Extensions) icon in your browser toolbar.
          </li>
          <li>Find the 'Add to Babylist Button' and click on the pin icon.</li>
          <li>
            Now you will see a Babylist heart in your browser bar so it's easy
            to click whenever you browse.
          </li>
        </ul>
      </span>
    ),
  },
  {
    id: FAQItems.ButtonNotWorking,
    show: true,
    question: "My Babylist Button isn't working.",
    answer: (
      <span className={css.questionsContainer__answer}>
        Here are some videos to help with installation. Choose the browser you
        are using and follow the directions:
        <br />
        <br />
        <ul>
          <li>
            <TextLink
              underline
              href={youtubeChromeTutorialUrl}
              size="md"
              target="_blank"
            >
              Chrome
            </TextLink>
          </li>
          <li>
            <TextLink
              underline
              href={youtubeFirefoxTutorialUrl}
              size="md"
              target="_blank"
            >
              Firefox
            </TextLink>
          </li>
          <li>
            <TextLink
              underline
              href={youtubeInternetExplorerTutorialUrl}
              size="md"
              target="_blank"
            >
              Internet Explorer
            </TextLink>
          </li>
          <li>
            <TextLink
              underline
              href={youtubeSafariTutorialUrl}
              size="md"
              target="_blank"
            >
              Safari
            </TextLink>
          </li>
        </ul>
        You'll also want to make sure that your{' '}
        <TextLink underline href={manageCookiesPath} size="md" target="_blank">
          cookies are set up properly
        </TextLink>
        .<br />
        <br />
        If you are using a workplace computer, your work might be blocking the
        button. You can either try again on a personal computer or download our
        mobile app.
      </span>
    ),
  },
  {
    id: FAQItems.SizeAndColor,
    show: true,
    question: 'How do I add size and color preferences to my gifts?',
    answer: (
      <span className={css.questionsContainer__answer}>
        Anytime you add a product to your registry, you can add a note to tell
        your friends and family the color and/or size you want.
        <br />
        <br />
        If you skip this step while adding the product, no worries! You can
        always go back to edit the item's details later. You can also edit the
        product title, images and quantity.
      </span>
    ),
  },
  {
    id: FAQItems.GiftCards,
    show: true,
    question: 'How do I add gift cards from other stores?',
    answer: (
      <span className={css.questionsContainer__answer}>
        Once you identify the gift card you want, find a URL and add it to your
        registry the same way you add an item. (
        <TextLink
          underline
          className={css.FAQTextLink}
          href="https://www.target.com/p/baby-stork-target-giftcard/-/A-85035020#lnk=sametab"
          innerContentClassName={css.FAQTextLinkInner}
          size="md"
          target="_blank"
        >
          Click here for an example from Target.
        </TextLink>
        )<br />
        <br />
        You can update the gift card details by adding the quantity you want to
        receive or leaving the quantity blank (remove the one) so there's no
        limit to how many gift cards can be reserved. We also suggest adding a
        zero to the price box so family and friends don't feel restricted to
        giving one specific amount.
        <br />
        <br />
        Pro tip: If you're adding an e-gift card that has to be emailed to you,
        make sure to include your email address in the note box so friends and
        family members know where to send it.
      </span>
    ),
  },
  {
    id: FAQItems.AddWithoutLink,
    show: true,
    question: "How can I add items that I don't have a link for?",
    answer: (
      <span className={css.questionsContainer__answer}>
        Use our "
        <TextLink underline href={wildCardCouponPath} size="md" target="_blank">
          Wild Card
        </TextLink>
        " blank coupon to register for unique items like hand-me-downs or items
        found in a physical store.
        <br />
        <br />
        To customize your coupon:
        <ul>
          <li>
            Add the coupon to your registry from our{' '}
            <TextLink
              underline
              href={helpAndFavorsPath}
              size="md"
              target="_blank"
            >
              Help & Favors section
            </TextLink>
            .
          </li>
          <li>Click or tap to edit the coupon on your registry.</li>
          <li>Change the title, quantity and category.</li>
          <li>
            Add details in the notes section information about where to purchase
            the items, color, size, and any information the gift giver may need
            because this is a non-traditional gift – the more details the
            better!
          </li>
          <li>You can change the photo as well by clicking on "Replace."</li>
        </ul>
        Pro tip: If you want to register for monetary donations, our{' '}
        <TextLink underline href={cashFundsPath} size="md" target="_blank">
          Cash Funds
        </TextLink>{' '}
        are a better fit than the blank coupon. You can{' '}
        <TextLink underline href={cashFundsPath} size="md" target="_blank">
          add a cash fund here
        </TextLink>
        .
      </span>
    ),
  },
]

const mapFAQItemIdToEventCta = (id: number) => {
  switch (id) {
    case FAQItems.AddFromAnywhere:
      return 'FAQ - Add From Anywhere'
    case FAQItems.AddFromOtherStores:
      return 'FAQ - Add From Other Stores'
    case FAQItems.AddWithoutButton:
      return 'FAQ - Add Without Button'
    case FAQItems.WhereIsExtension:
      return 'FAQ - Where Is Extension'
    case FAQItems.ButtonNotWorking:
      return 'FAQ - Button Not Working'
    case FAQItems.SizeAndColor:
      return 'FAQ - Size And Color'
    case FAQItems.GiftCards:
      return 'FAQ - Gift Cards'
    case FAQItems.AddWithoutLink:
      return 'FAQ - Add Without Link'
    default:
      return 'FAQ - Unknown'
  }
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({
  className,
}) => {
  const FrequentlyAskedQuestionsClasses = classNames(
    css.FrequentlyAskedQuestions,
    className
  )

  return (
    <div className={FrequentlyAskedQuestionsClasses}>
      <h2 className={css.FAQTitle}>Frequently Asked Questions</h2>
      <div className={css.questionsContainer}>
        {faqItems.map((faqItem) => {
          if (faqItem.show) {
            return (
              <Accordion
                defaultOpen={false}
                key={faqItem.id}
                trigger={({ onClick, isOpen }: AccordionTriggerProps) => (
                  <button
                    className={css.questionsContainer__question}
                    onClick={() => {
                      track.addItemsScreenClicked({
                        eventLocation: track.EventLocation.ADD_ITEMS,
                        eventCta: mapFAQItemIdToEventCta(faqItem.id),
                      })
                      onClick()
                    }}
                  >
                    <h3>{faqItem.question}</h3>
                    <Chevron
                      className={classNames(css.questionsContainer__chevron, {
                        [css['questionsContainer__chevron--upsideDown']]:
                          isOpen,
                      })}
                    />
                  </button>
                )}
              >
                {faqItem.answer}
              </Accordion>
            )
          }
          return <></>
        })}
      </div>
      <div className={css.moreQuestionsCopy}>
        Have more questions?{' '}
        <TextLink
          underline
          href={CONTACT_US_URL}
          size="md"
          target="_blank"
          weight="regular"
        >
          Submit a request
        </TextLink>{' '}
        or reach out to our support team at support@babylist.com.{' '}
      </div>
    </div>
  )
}

export default FrequentlyAskedQuestions
