import React from 'react'
import classNames from 'classnames'
import { TextLink, Button } from 'baby-design'
import { LightBulb, ChromeExtension } from 'baby-design/icons'
import ScrollLockModal from 'shared/ScrollLockModal'
import { BABYLIST_EXTENSION_URL } from 'shared/constants'
import { saveUserEvent } from 'registry/api'
import { addItemsPath } from 'lib/urls'
import { track } from 'lib/analytics'
import { browserName } from 'lib/bowser'
import {
  ADD_BABYLIST_BUTTON_EVENT,
  INSTALLED_BABYLIST_EXTENSION_EVENT,
} from 'registry/constants'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import css from './HowToUseModal.styles.scss'
import { HowToUseModalProps } from './HowToUseModal.types'

const HowToUseModal: React.FC<HowToUseModalProps> = ({
  isOpen,
  handleClose,
  showCallout = false,
  className,
}) => {
  const HowToUseModalClasses = classNames(css.HowToUseModal, className)

  const [currentUser] = useCurrentUser()
  const installedBabylistExtension = currentUser?.events?.some(
    (event) => event.name === INSTALLED_BABYLIST_EXTENSION_EVENT
  )

  const openAddButtonPageWithTracking = () => {
    track.addItemsScreenClicked({
      eventLocation: track.EventLocation.ADD_ITEMS,
      eventCta: 'Add Button',
    })
    saveUserEvent(ADD_BABYLIST_BUTTON_EVENT, true)
    window.open(addItemsPath, '_blank')
  }

  const openExtensionInstallPageWithTracking = () => {
    track.addItemsScreenClicked({
      eventLocation: track.EventLocation.ADD_ITEMS,
      eventCta: 'Install Extension',
    })
    saveUserEvent(INSTALLED_BABYLIST_EXTENSION_EVENT, true)
    window.open(BABYLIST_EXTENSION_URL, '_blank')
  }

  const chromeUser = browserName === 'Chrome'

  const buttonCopy =
    chromeUser && !installedBabylistExtension ? 'Add to Chrome' : 'Done'

  const handlePrimaryButtonClick = () => {
    if (chromeUser && !installedBabylistExtension) {
      openExtensionInstallPageWithTracking()
    }
    handleClose()
  }

  const primaryButton = (
    <Button size="md" variant="primary" onClick={handlePrimaryButtonClick}>
      {buttonCopy}
    </Button>
  )

  let additionalInstructions = null
  if (showCallout) {
    additionalInstructions = (
      <div className={css.HowToUseModal__callout}>
        <LightBulb />
        <div className={css.HowToUseModal__helpBody}>
          To add items from other websites, you'll need to install our{' '}
          <TextLink
            underline
            className={css['TextLink--inline']}
            innerContentClassName={css['TextLinkInner--inline']}
            size="lg"
            onClick={openExtensionInstallPageWithTracking}
          >
            Chrome Extension
          </TextLink>{' '}
          or{' '}
          <TextLink
            underline
            className={css['TextLink--inline']}
            innerContentClassName={css['TextLinkInner--inline']}
            size="lg"
            onClick={openAddButtonPageWithTracking}
          >
            Babylist Button
          </TextLink>
          .
        </div>
      </div>
    )
  } else if (chromeUser && installedBabylistExtension) {
    additionalInstructions = (
      <div>
        <h2 className={css.HowToUseModal__helpHeader}>
          Not seeing the button?
        </h2>
        <div className={css.HowToUseModal__helpBody}>
          Try clicking the{' '}
          <ChromeExtension className={css.HowToUseModal__icon} /> in your
          browser toolbar. If that doesn't work, try{' '}
          <TextLink
            underline
            className={css['TextLink--inline']}
            innerContentClassName={css['TextLinkInner--inline']}
            size="lg"
            onClick={openExtensionInstallPageWithTracking}
          >
            installing it again
          </TextLink>
          .
        </div>
      </div>
    )
  } else if (!chromeUser) {
    additionalInstructions = (
      <div>
        <h2 className={css.HowToUseModal__helpHeader}>
          Not seeing the button?
        </h2>
        <div className={css.HowToUseModal__helpBody}>
          Make sure your bookmark bar is visible. If it doesn't appear,{' '}
          <TextLink
            underline
            className={css['TextLink--inline']}
            innerContentClassName={css['TextLinkInner--inline']}
            size="lg"
            onClick={openAddButtonPageWithTracking}
          >
            try installing it again
          </TextLink>
          .
        </div>
      </div>
    )
  }

  return (
    <ScrollLockModal
      className={HowToUseModalClasses}
      handleClose={handleClose}
      isOpen={isOpen}
      primaryButton={primaryButton}
    >
      <h1>How it works</h1>
      <img
        alt="Add to Babylist"
        height={270}
        src={
          chromeUser
            ? 'https://images.babylist.com/image/upload/v1711565586/AddChromeExtension/Extension-img-BLext.png'
            : 'https://images.babylist.com/image/upload/v1713388434/AddChromeExtension/Extension-img-BLbtn.png'
        }
        width={576}
      />
      <h2>Save items from anywhere on the web in 3 simple steps</h2>
      <ul className={css.HowToUseModal__steps}>
        <li>Browse on any store website.</li>
        <li>Click the Babylist Button when you find your item.</li>
        <li>Add the item to your registry!</li>
      </ul>
      {additionalInstructions}
    </ScrollLockModal>
  )
}

export default HowToUseModal
