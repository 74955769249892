import { map } from 'lodash'
import { Select } from 'components/forms'
import { helloBabyPostPath } from 'lib/urls'
import { useNavigate } from 'react-router-dom-v5-compat'

interface PregnancyWeekSelectProps {
  className: string
  posts: Post[]
}

const PregnancyWeekSelect = ({
  className,
  posts,
}: PregnancyWeekSelectProps) => {
  const navigate = useNavigate()

  const handleChangeSort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(event.target.value)
  }

  return (
    <Select
      defaultValue="jump"
      size="small"
      variant="muted"
      wrapperClassName={className}
      onChange={handleChangeSort}
    >
      <option key="jump" value="jump">
        Jump to your pregnancy week
      </option>
      {map(posts, (post) => (
        <option key={post.title} value={helloBabyPostPath(post.slug)}>
          {post.title}
        </option>
      ))}
    </Select>
  )
}

export default PregnancyWeekSelect
