import { FC } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Chat } from 'baby-design/icons'

import GiftMessageGlitter from './GiftMessageGlitter'
import css from './GiftMessage.styles.scss'

const GiftMessageText: FC<{ giftMessage: string }> = ({ giftMessage }) => (
  <div className={css.GiftMessage__GiftMessageText}>{giftMessage}</div>
)

const GiftMessage: FC<{ giftMessage: string | null }> = ({ giftMessage }) => {
  if (!giftMessage) {
    return null
  }

  return (
    <div className={css.GiftMessage}>
      <div className={css.GiftMessage__icon}>
        <Chat />
      </div>
      <GiftMessageText giftMessage={giftMessage} />
      <GiftMessageGlitter />
    </div>
  )
}

export default GiftMessage
