const CategoryChecklist = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 258.35 258.35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M180.86,101.91v82.32A10.9,10.9,0,0,1,170,195.09H95.37a10.9,10.9,0,0,1-10.86-10.86V78.44A10.9,10.9,0,0,1,95.37,67.57h14v3.32a11.16,11.16,0,0,0,1.31,5.28,11.32,11.32,0,0,0,10,6h24a11.32,11.32,0,0,0,10-6A11.17,11.17,0,0,0,156,70.89V67.57h14a10.9,10.9,0,0,1,10.87,10.87Z"
      fill="#f2ece5"
    />
    <path
      d="M156,67.57v3.32a11.17,11.17,0,0,1-1.32,5.28,11.32,11.32,0,0,1-10,6h-24a11.32,11.32,0,0,1-10-6,11.17,11.17,0,0,1-1.32-5.28V67.45a6.13,6.13,0,0,1,6.12-6.11h9.59a1.66,1.66,0,0,0,1.29-2.71,8.17,8.17,0,1,1,12.57,0,1.67,1.67,0,0,0,1.29,2.73h9.59A6.13,6.13,0,0,1,156,67.45ZM135.58,53.41a2.9,2.9,0,1,0-2.9,2.9A2.9,2.9,0,0,0,135.58,53.41Z"
      fill="#ffd000"
    />
    <path
      d="M156,67.57h14a10.9,10.9,0,0,1,10.87,10.87v23.47"
      fill="none"
      stroke="#6e3264"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M151.25,79.76h14.9a3.54,3.54,0,0,1,3.53,3.53v29.44"
      fill="none"
      stroke="#6e3264"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M169.68,130.12v49.25a3.54,3.54,0,0,1-3.53,3.53H99.21a3.54,3.54,0,0,1-3.53-3.53V83.29a3.54,3.54,0,0,1,3.53-3.53H114"
      fill="none"
      stroke="#6e3264"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M109.4,67.57h-14A10.9,10.9,0,0,0,84.51,78.44V184.23a10.9,10.9,0,0,0,10.86,10.86H170a10.9,10.9,0,0,0,10.87-10.86V116.38"
      fill="none"
      stroke="#6e3264"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M156,67.57v-.12a6.13,6.13,0,0,0-6.11-6.11h-9.59A1.67,1.67,0,0,1,139,58.61a8.16,8.16,0,0,0-6.61-13.36,8.17,8.17,0,0,0-6,13.38,1.66,1.66,0,0,1-1.29,2.71h-9.59a6.13,6.13,0,0,0-6.12,6.11v3.44a11.17,11.17,0,0,0,1.32,5.28,11.32,11.32,0,0,0,10,6h24a11.32,11.32,0,0,0,10-6A11.17,11.17,0,0,0,156,70.89Z"
      fill="none"
      stroke="#6e3264"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <circle
      cx="132.68"
      cy="53.41"
      fill="none"
      r="2.9"
      stroke="#6e3264"
      strokeMiterlimit="10"
    />
    <path
      d="M108.32,155.16l2.6,2.59a1,1,0,0,0,1.44,0l4.82-4.82,5.1-5.1"
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M108.32,130.07l2.6,2.59a1,1,0,0,0,1.44,0l4.82-4.82,5.1-5.1"
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M108.32,105l2.6,2.59a1,1,0,0,0,1.44,0l4.82-4.82,5.1-5.1"
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M153.34,147.49l-14.82,7.77a.59.59,0,0,1-.77-.76l7.05-14.73c0,.07,6.77,1.27,6.77,1.27l1.64,6.28A.28.28,0,0,0,153.34,147.49Z"
      fill="#f2ece5"
    />
    <path
      d="M211.66,81.08a1.42,1.42,0,0,1,.1,2l-5.11,5.65-.2-.18L198,80.89l-.11-.11,5.1-5.65a1.41,1.41,0,0,1,2-.1Z"
      fill="#ffd000"
    />
    <polygon
      fill="#f2ece5"
      points="206.65 88.72 202.82 92.97 202.62 92.79 194.14 85.13 194.02 85.03 197.86 80.78 197.97 80.89 206.45 88.54 206.65 88.72"
    />
    <path
      d="M202.82,93,175.9,122.79l-10.51,11.63-11.57,12.82-.47.25h0a.28.28,0,0,1-.13-.17L151.57,141,145,139.92a.29.29,0,0,1-.2-.15l.23-.47h0l20.36-22.56,10.51-11.63L194,85l.12.1,8.48,7.66Z"
      fill="#ffd000"
    />
    <path
      d="M145.09,139.29l20.3-22.55,10.51-11.63L194,85l3.84-4.25,5.1-5.65a1.41,1.41,0,0,1,2-.1l6.71,6a1.42,1.42,0,0,1,.1,2l-5.11,5.65L202.82,93,175.9,122.79l-10.51,11.63-12,13"
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <path
      d="M145,139.3l-.23.47-7.05,14.73a.59.59,0,0,0,.77.76l14.82-7.77"
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="197.97"
      x2="206.45"
      y1="80.89"
      y2="88.54"
    />
    <line
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="192.67"
      x2="201.15"
      y1="86.6"
      y2="94.26"
    />
    <polyline
      fill="none"
      points="153.21 147.32 151.57 141.04 145 139.92"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
    />
    <line
      fill="none"
      stroke="#6e3264"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="4px"
      x1="145.03"
      x2="145.03"
      y1="139.3"
      y2="139.3"
    />
    <path
      d="M205.64,119.77,197.89,133a1.49,1.49,0,0,0,1.29,2.25H215.1a1.49,1.49,0,0,0,1.27-2.28l-8.18-13.25A1.48,1.48,0,0,0,205.64,119.77Z"
      fill="#ff7a5e"
    />
    <path
      d="M71,48.45a15.15,15.15,0,0,0-5.5,19.8,1.09,1.09,0,0,0,1.54.42l5.49-3.38a.92.92,0,0,0,.35-1.16,6.69,6.69,0,0,1,10.93-7.4,1.67,1.67,0,0,0,2.1.29l5.07-3.13a1,1,0,0,0,.27-1.38A15.15,15.15,0,0,0,71,48.45Z"
      fill="#ff7aa9"
    />
    <path
      d="M62.87,151.4a7.36,7.36,0,0,0-7.1,7.59v13.65a1.51,1.51,0,0,0,1.5,1.51H68.33A1.63,1.63,0,0,0,70,172.51V159A7.36,7.36,0,0,0,62.87,151.4Z"
      fill="#00a1c5"
    />
  </svg>
)

export default CategoryChecklist
