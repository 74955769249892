import { MutableRefObject } from 'react'
import classNames from 'classnames'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Lock, MostWanted, MostWantedFilled } from 'baby-design/icons'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Button,
  Checkbox,
  RangeSlider,
  RightDrawer,
  SelectorButton,
  StoreIcon,
} from 'baby-design'
import { PRICE_FILTER_MAX, PRICE_FILTER_MIN } from '../../utils/routeHelpers'
import css from './RegItemFilterDrawer.styles.scss'

export type ShowFilterOption = 'all' | 'notPurchased' | 'purchased'

export type AvailabilityFilterOption =
  | 'all'
  | 'available'
  | 'reservedAndPurchased'
  | 'groupGift'

export interface PriceFilterOption {
  min: number
  max: number
}

export interface StoreFilterData {
  show: boolean
  storeIconName: string
}

export interface ItemSettingsFilter {
  mostWanteds: boolean
  groupGifts: boolean
  privates: boolean
  eligibleForRegistryDiscount: boolean
}

export interface RegItemFilters {
  show: ShowFilterOption
  itemSettings: ItemSettingsFilter
  price: PriceFilterOption
  stores: Record<string, StoreFilterData>
}

export const emptyFilter: RegItemFilters = {
  show: 'all',
  itemSettings: {
    mostWanteds: false,
    groupGifts: false,
    privates: false,
    eligibleForRegistryDiscount: false,
  },
  price: {
    min: PRICE_FILTER_MIN,
    max: PRICE_FILTER_MAX,
  },
  stores: {},
}

interface RegItemFilterDrawerProps {
  filters: RegItemFilters
  setFilters: (filters: RegItemFilters) => void
  filterCountRef: MutableRefObject<number>
  showRegistryDiscountEligible: boolean
}

export default NiceModal.create(
  ({
    filters,
    setFilters,
    filterCountRef,
    showRegistryDiscountEligible,
  }: RegItemFilterDrawerProps) => {
    const modal = useModal()

    const {
      show: showFilter,
      itemSettings: itemSettingsFilter,
      price: priceFilter,
      stores: storesFilter,
    } = filters

    const handleShowFilterChange = (newFilter: ShowFilterOption) => {
      if (newFilter === showFilter) return

      setFilters({
        ...filters,
        show: newFilter,
      })
    }

    const handleItemSettingsFilterChange = (
      itemSettings: ItemSettingsFilter
    ) => {
      setFilters({
        ...filters,
        itemSettings,
      })
    }

    const filterByAllStores = Object.values(filters.stores).every(
      (storeData) => storeData.show
    )

    const handleAllStoresFilterChange = () => {
      const updatedStoresFilter = Object.keys(filters.stores).reduce<
        Record<string, StoreFilterData>
      >((acc, storeDisplayName) => {
        acc[storeDisplayName] = {
          ...storesFilter[storeDisplayName],
          show: !filterByAllStores,
        }
        return acc
      }, {})
      setFilters({
        ...filters,
        stores: updatedStoresFilter,
      })
    }

    const handleStoresFilterChange = (storeDisplayName: string) => {
      const storeData = storesFilter[storeDisplayName]
      const newStoresFilter = {
        ...storesFilter,
        [storeDisplayName]: { ...storeData, show: !storeData.show },
      }

      setFilters({
        ...filters,
        stores: newStoresFilter,
      })
    }

    const handlePriceFilterChange = (
      lesserValue: number,
      greaterValue: number
    ) => {
      setFilters({
        ...filters,
        price: { min: lesserValue, max: greaterValue },
      })
    }

    const sortedStoreKeys = Object.keys(filters.stores).sort((a, b) => {
      const storeA = a.toLowerCase()
      const storeB = b.toLowerCase()
      if (storeA < storeB) return -1
      if (storeA > storeB) return 1
      return 0
    })

    const showFilterOptions: Array<{
      value: ShowFilterOption
      label: string
    }> = [
      { value: 'all', label: 'All' },
      { value: 'notPurchased', label: 'Not purchased' },
      { value: 'purchased', label: 'Purchased' },
    ]

    const closeDrawer = () => modal.hide()

    const handleClearFiltersClick = () => {
      const storeFilterData = Object.keys(storesFilter).reduce<
        Record<string, StoreFilterData>
      >((acc, storeDisplayName) => {
        acc[storeDisplayName] = {
          ...storesFilter[storeDisplayName],
          show: true,
        }
        return acc
      }, {})
      setFilters({ ...emptyFilter, stores: storeFilterData })
    }

    const filteredItemCount = filterCountRef?.current || 0

    return (
      <RightDrawer
        className={css.RegItemFilterDrawer}
        handleClose={closeDrawer}
        handleDismount={modal.remove}
        isOpen={modal.visible}
        title="Filters"
      >
        <div className={css.RegItemFilterDrawer__container}>
          <div className={css.RegItemFilterDrawer__filters}>
            <div className={css.RegItemFilterDrawer__filter}>
              <div className={css.RegItemFilterDrawer__filterHeader}>
                <span className={css.RegItemFilterDrawer__filterTitle}>
                  Show
                </span>
              </div>
              <div className={css.RegItemFilterDrawer__showFilterControls}>
                {showFilterOptions.map(({ value, label }) => (
                  <SelectorButton
                    id={`show_${value}`}
                    key={value}
                    name="show"
                    selected={showFilter === value}
                    selectedVariant="fill"
                    size="md"
                    onClick={() => handleShowFilterChange(value)}
                  >
                    {label}
                  </SelectorButton>
                ))}
              </div>
            </div>
            <div className={css.RegItemFilterDrawer__filter}>
              <div className={css.RegItemFilterDrawer__filterHeader}>
                <span className={css.RegItemFilterDrawer__filterTitle}>
                  Price range
                </span>
              </div>
              <RangeSlider
                openEndedMax
                lowerBound={PRICE_FILTER_MIN}
                type="currency"
                upperBound={PRICE_FILTER_MAX}
                values={{
                  greater: priceFilter.max,
                  lesser: priceFilter.min,
                }}
                onChange={handlePriceFilterChange}
              />
            </div>
            <div className={css.RegItemFilterDrawer__filter}>
              <div className={css.RegItemFilterDrawer__filterHeader}>
                <span className={css.RegItemFilterDrawer__filterTitle}>
                  Item settings
                </span>
              </div>
              <div
                className={css.RegItemFilterDrawer__itemSettingsFilterControls}
              >
                <SelectorButton
                  icon={{
                    side: 'left',
                    icon: itemSettingsFilter.mostWanteds ? (
                      <MostWantedFilled
                        className={
                          css.RegItemFilterDrawer__mostWantedFilledIcon
                        }
                      />
                    ) : (
                      <MostWanted />
                    ),
                  }}
                  selected={itemSettingsFilter.mostWanteds}
                  selectedVariant="outline"
                  size="md"
                  onClick={() =>
                    handleItemSettingsFilterChange({
                      ...itemSettingsFilter,
                      mostWanteds: !itemSettingsFilter.mostWanteds,
                    })
                  }
                >
                  Most Wanted
                </SelectorButton>
                <SelectorButton
                  icon={{
                    side: 'left',
                    icon: <Lock />,
                  }}
                  selected={itemSettingsFilter.privates}
                  selectedVariant="outline"
                  size="md"
                  onClick={() =>
                    handleItemSettingsFilterChange({
                      ...itemSettingsFilter,
                      privates: !itemSettingsFilter.privates,
                    })
                  }
                >
                  Private
                </SelectorButton>
                <SelectorButton
                  selected={itemSettingsFilter.groupGifts}
                  selectedVariant="outline"
                  size="md"
                  onClick={() =>
                    handleItemSettingsFilterChange({
                      ...itemSettingsFilter,
                      groupGifts: !itemSettingsFilter.groupGifts,
                    })
                  }
                >
                  Group gift
                </SelectorButton>
                {showRegistryDiscountEligible && (
                  <SelectorButton
                    selected={itemSettingsFilter.eligibleForRegistryDiscount}
                    selectedVariant="outline"
                    size="md"
                    onClick={() =>
                      handleItemSettingsFilterChange({
                        ...itemSettingsFilter,
                        eligibleForRegistryDiscount:
                          !itemSettingsFilter.eligibleForRegistryDiscount,
                      })
                    }
                  >
                    Registry discount eligible
                  </SelectorButton>
                )}
              </div>
            </div>
            <div
              className={classNames(
                css.RegItemFilterDrawer__storeFilter,
                css.RegItemFilterDrawer__filter
              )}
            >
              <div
                className={classNames(
                  css.RegItemFilterDrawer__storeFilterHeader,
                  css.RegItemFilterDrawer__filterHeader
                )}
              >
                <span className={css.RegItemFilterDrawer__filterTitle}>
                  Stores
                </span>
                <Button
                  className={css.RegItemFilterDrawer__selectAllButton}
                  size="md"
                  variant="text-primary"
                  onClick={handleAllStoresFilterChange}
                >
                  {filterByAllStores ? 'Deselect All' : 'Select All'}
                </Button>
              </div>
              <div className={css.RegItemFilterDrawer__stores}>
                {sortedStoreKeys.map((storeDisplayName) => {
                  const store = storesFilter[storeDisplayName]
                  const id = `store_${storeDisplayName}`
                  return (
                    <div
                      className={css.RegItemFilterDrawer__store}
                      key={storeDisplayName}
                    >
                      <label
                        className={css.RegItemFilterDrawer__storeLabel}
                        htmlFor={id}
                      >
                        <StoreIcon
                          className={css.RegItemFilterDrawer__storeIcon}
                          store={{ name: store.storeIconName }}
                        />
                        <span>{storeDisplayName}</span>
                      </label>
                      <Checkbox
                        className={css.RegItemFilterDrawer__storeCheckbox}
                        inputProps={{
                          id,
                          checked: store.show,
                          value: storeDisplayName,
                          onChange: () =>
                            handleStoresFilterChange(storeDisplayName),
                        }}
                        size="lg"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={css.RegItemFilterDrawer__footer}>
            <Button
              className={css.RegItemFilterDrawer__clearFiltersButton}
              size="md"
              variant="text-secondary"
              onClick={handleClearFiltersClick}
            >
              Clear all
            </Button>
            <Button
              className={css.RegItemFilterDrawer__applyButton}
              size="md"
              variant="primary"
              onClick={closeDrawer}
            >
              {filteredItemCount > 0
                ? `Show ${filteredItemCount} item${filteredItemCount > 1 ? 's' : ''}`
                : '0 items found'}
            </Button>
          </div>
        </div>
      </RightDrawer>
    )
  }
)
