import {
  currentAgeInYears,
  currentAgeInMonths,
  currentAgeInWeeks,
} from 'lib/date'

export const getCurrentAgeStr = (birthdate: string) => {
  const ageInYears = currentAgeInYears(birthdate)
  if (ageInYears >= 2) {
    return ageInYears
  }

  const ageInMonths = currentAgeInMonths(birthdate)
  if (ageInMonths >= 1) {
    return `${ageInMonths}M`
  }

  const ageInWeeks = currentAgeInWeeks(birthdate)
  if (ageInWeeks >= 0) {
    return `${Math.max(ageInWeeks, 1)}W`
  }

  const month = new Date(birthdate).toLocaleString('en-us', { month: 'short' })
  return `Due ${month}!`
}
