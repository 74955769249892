import css from './RegistrySortFilterDropdowns.skeleton.styles.scss'

const RegistrySortFilterDropdownsSkeleton = () => (
  <div
    className={css.RegistrySortFilterDropdownsSkeleton}
    data-testid="RegistrySortFilterDropdownsSkeleton"
  >
    <div className={css.RegistrySortFilterDropdownsSkeleton__Dropdown} />
    <div className={css.RegistrySortFilterDropdownsSkeleton__Dropdown} />
  </div>
)

export default RegistrySortFilterDropdownsSkeleton
