import { track, withContextualizedTracking } from 'lib/analytics'
import { ChecklistOverview, ChecklistThemesList } from './components'

interface RegistryChecklistAppProps {
  registryId: number
}

export const RegistryChecklistApp = ({
  registryId,
}: RegistryChecklistAppProps) => (
  <>
    <ChecklistOverview registryId={registryId} />
    <ChecklistThemesList registryId={registryId} />
  </>
)

export default withContextualizedTracking({
  eventLocation: track.EventLocation.CHECKLIST,
})(RegistryChecklistApp)
