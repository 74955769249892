import RetailerReturn from './retailer-return'
import { RETURNS_TARGET_URL, RETURNS_TARGET_PHONE } from 'shared/constants'

const TargetReturn = ({ orderNum, giftGiverName, giftGiverEmail }) => {
  return (
    <RetailerReturn
      storeName="Target"
      phoneNumber={RETURNS_TARGET_PHONE}
      returnUrl={RETURNS_TARGET_URL}
      orderNum={orderNum}
      giftGiverName={giftGiverName}
      giftGiverEmail={giftGiverEmail}
    />
  )
}

export default TargetReturn
