import React from 'react'
import { Button } from 'baby-design'
import { ArrowTop } from 'baby-design/icons'
import BackToTopFixedButton from './components/BackToTopFloatingButton'
import { RegistryBackToTopProps } from './RegistryBackToTop.types'
import css from './RegistryBackToTop.styles.scss'

const RegistryBackToTop: React.FC<RegistryBackToTopProps> = ({ variant }) => {
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return variant === 'bottom' ? (
    <div className={css.RegistryBackToTop}>
      <Button
        data-testid="RegistryBackToTop-bottom-button"
        icon={{
          icon: <ArrowTop />,
          side: 'left',
          size: 'md',
        }}
        size="md"
        variant="text-secondary"
        onClick={onClick}
      >
        Back to top
      </Button>
    </div>
  ) : (
    <BackToTopFixedButton onClick={onClick} />
  )
}

export default RegistryBackToTop
