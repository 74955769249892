import { useContext, useEffect } from 'react'

import { track, useTracking } from 'lib/analytics'
import { Registry } from 'src/types/registry'
import { useRegistry } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../../../../global'

import { shipmentsEntryPoint } from '../util/shipmentsEntryPoint'

import EventLocationContext from '../contexts/EventLocationContext'

const consolidationValue = ({
  shippingConsolidation: { consolidationChoice },
}: Registry) =>
  !consolidationChoice
    ? track.ConsolidationValue.MODAL_NOT_VIEWED
    : consolidationChoice

interface ShipmentPageViewEventArgs {
  currentUser: CurrentUser
  registry: Registry
  eventLocation: string
  includeEntrypoint: boolean
}

const shipmentPageViewEvent = ({
  currentUser,
  registry,
  eventLocation,
  includeEntrypoint,
}: ShipmentPageViewEventArgs) => ({
  eventLocation,
  event: track.pageViewed,
  consolidationValue: consolidationValue(registry),
  entryPoint: includeEntrypoint ? shipmentsEntryPoint() : null,
  ...trackingRegistryProperties({ currentUser, registry }),
})

const useTrackShipmentPageView = ({
  includeEntrypoint,
}: {
  includeEntrypoint: boolean
}) => {
  const [currentUser] = useCurrentUser()
  const tracking = useTracking()
  const registry = useRegistry()
  const eventLocation = useContext(EventLocationContext)

  useEffect(() => {
    if (!!currentUser && !!registry && !!eventLocation) {
      const event = shipmentPageViewEvent({
        currentUser,
        registry,
        eventLocation,
        includeEntrypoint,
      })
      tracking.trackEvent(event)
    }
  }, [currentUser, registry, eventLocation])
}

export const useTrackShipmentsPageView = () =>
  useTrackShipmentPageView({ includeEntrypoint: true })

export const useTrackShipmentDetailsPageView = () =>
  useTrackShipmentPageView({ includeEntrypoint: false })
