import React, { useEffect, useRef, useState } from 'react'
import { TextLink } from 'baby-design'
import classNames from 'classnames'
import { RegistryGreetingProps } from './RegistryGreeting.types'
import css from './RegistryGreeting.styles.scss'

const RegistryGreeting: React.FC<RegistryGreetingProps> = ({
  currentRegistry,
}) => {
  const { title, description } = currentRegistry || {}
  const [expanded, setExpanded] = useState(false)
  const [showControls, setShowControls] = useState(true)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const containerClassName = classNames(
    css.RegistryViewHeader__greetingContainer,
    {
      [css['RegistryViewHeader__greetingContainer--expanded']]: expanded,
    }
  )

  const isDescriptionOverflowingContainer = () => {
    const descriptionElement = descriptionRef.current
    if (!descriptionElement) return false

    return descriptionElement.scrollHeight > descriptionElement.clientHeight
  }

  useEffect(() => {
    setExpanded(false)
    const descriptionElement = descriptionRef.current
    if (!descriptionElement) return

    if (isDescriptionOverflowingContainer()) {
      setShowControls(true)
    } else {
      setShowControls(false)
    }
  }, [description])

  return (
    <div className={containerClassName}>
      {!title && <div className={css.shimmer} />}
      <p
        className={css.RegistryViewHeader__greeting}
        dangerouslySetInnerHTML={{ __html: description || '' }}
        ref={descriptionRef}
      />
      {description && (showControls || expanded) && (
        <TextLink
          className={css.RegistryViewHeader__greetingToggleTextLink}
          size="md"
          variant="default"
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          {expanded ? 'see less' : 'see more'}
        </TextLink>
      )}
    </div>
  )
}

export default RegistryGreeting
