import { FC } from 'react'

import { Banner } from 'baby-design'
import { GiftTracker1, SimpleShipping1 } from 'baby-design/icons'
import { registryGiftTrackerPath, settingsYourOrdersPath } from 'lib/urls'

import css from './ShipmentsNavigationCards.styles.scss'

interface NavigationCardProps {
  headline: string
  bodyCopy: string
  graphicIcon: React.ReactNode
  buttonHref: string
  testid: string
}
const NavigationCard: React.FC<NavigationCardProps> = ({
  headline,
  bodyCopy,
  graphicIcon,
  buttonHref,
  testid,
}) => (
  <div data-testid={testid}>
    <Banner
      bodyCopy={bodyCopy}
      button={{
        buttonVariant: 'tertiary',
        buttonText: 'View',
        href: buttonHref,
      }}
      color="default"
      dismissable={false}
      graphic={{
        icon: graphicIcon,
        placement: 'left',
      }}
      headline={headline}
      variant="ultra-compact"
    />
  </div>
)

const GiftTrackerBanner: React.FC = () => (
  <NavigationCard
    bodyCopy="Track gifts purchased from other retailers."
    buttonHref={registryGiftTrackerPath}
    graphicIcon={
      <GiftTracker1 className={css.ShipmentsNavigationCards__GiftTrackerIcon} />
    }
    headline="Gift tracker"
    testid="shipment-navigation-card-gift-tracker"
  />
)

const OrdersBanner: React.FC = () => (
  <NavigationCard
    bodyCopy="Manage all your orders from the Babylist Shop."
    buttonHref={settingsYourOrdersPath}
    graphicIcon={
      <SimpleShipping1 className={css.ShipmentsNavigationCards__OrdersIcon} />
    }
    headline="Orders"
    testid="shipment-navigation-card-orders"
  />
)

const ShipmentsNavigationCards: FC = () => (
  <div
    className={css.ShipmentsNavigationCards}
    data-testid="shipment-navigation-cards"
  >
    <GiftTrackerBanner />
    <OrdersBanner />
  </div>
)

export default ShipmentsNavigationCards
