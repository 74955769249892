import { useEffect, useRef } from 'react'

type UseBlinkyTitleBarArgs = {
  altTitle?: string
  interval?: number
  isEnabled?: boolean
}

const useBlinkyTitleBar = ({
  altTitle = 'Return To Babylist',
  interval = 1200,
  isEnabled = true,
}: UseBlinkyTitleBarArgs) => {
  const originalTitleRef = useRef<string>(document.title)
  const intervalIdRef = useRef<number | null>(null)

  useEffect(() => {
    const changeTitle = () => {
      document.title =
        document.title === originalTitleRef.current
          ? altTitle
          : originalTitleRef.current
    }

    const handleBlur = () => {
      if (isEnabled && !intervalIdRef.current) {
        intervalIdRef.current = window.setInterval(changeTitle, interval)
      }
    }

    const handleFocus = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
        document.title = originalTitleRef.current
      }
    }

    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
      }
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
      document.title = originalTitleRef.current
    }
  }, [altTitle, interval, isEnabled])
}

export default useBlinkyTitleBar
