/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable complexity */
import React, { useState, MouseEvent } from 'react'
import classNames from 'classnames'
import { DragHandle, UserOutline } from 'baby-design/icons'
import { Image } from 'baby-design'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import NiceModal from '@ebay/nice-modal-react'

import { RegItemCardProps } from './RegItemCard.types'
import { BuyingOptions } from './components/BuyingOptions/BuyingOptions'
import { RegItemControls } from './components/Controls/Controls'
import { ProductDescription } from './components/ProductDescription/ProductDescription'
import { ProductNotes } from './components/ProductNotes/ProductNotes'
import MobileBuyingSheet from './components/ProductDescription/components/MobileBuyingSheet'

import css from './RegItemCard.styles.scss'

const getScreenSize = () => {
  if (window.innerWidth < 768) {
    return 'phone'
  }
  if (window.innerWidth < 1224) {
    return 'tablet'
  }
  return 'desktop'
}

const RegItemCard: React.FC<RegItemCardProps> = ({
  addedByText,
  product,
  regItem,
  regItemBadges,
  categories = [],
  size,
  onCategoryChange = () => {},
  onEditClick = () => {},
  onDeleteClick = () => {},
  onBuyingOptionClick = () => {},
  badge,
  className,
  ...props
}) => {
  const isDraggingEnabled = props.draggable === true
  const [screenSize, setScreenSize] = useState(
    (size || getScreenSize()) as 'desktop' | 'tablet' | 'phone'
  )
  const { flagValue } = useFeatureFlag(
    '24e_ex_dayzero_self_purchase',
    'Control'
  )
  const isInSelfPurchaseExperiment = flagValue === 'Treatment 1'

  window.addEventListener('resize', () => {
    setScreenSize(getScreenSize())
  })

  const onSelfPurchaseButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    // Button shouldn't be rendered if not in the experiment
    if (!isInSelfPurchaseExperiment) return

    e.stopPropagation()
    NiceModal.show(MobileBuyingSheet, {
      product,
      regItem,
    })
  }

  const RegItemCardClasses = classNames(
    css.RegItemCard,
    {
      [css.reg_item_card__desktop]: screenSize === 'desktop',
      [css.reg_item_card__tablet]: screenSize === 'tablet',
      [css.reg_item_card__phone]: screenSize === 'phone',
      [css.reg_item_card__is_draggable]: isDraggingEnabled,
    },
    className
  )
  const image = (
    <div
      className={classNames(css.reg_item_card__image_container, {
        [css['reg_item_card__image_container--selfPurchaseExperiment']]:
          isInSelfPurchaseExperiment,
      })}
    >
      <a
        aria-label="Link to purchase item"
        href={product.url}
        rel="noopener"
        target="_blank"
      >
        {product.image ? (
          <Image alt={product.heading} src={product.image} />
        ) : (
          <div className={css.reg_item_card__image_placeholder} />
        )}
        {badge && <div className={css.reg_item_card__image_badge}>{badge}</div>}
      </a>
    </div>
  )

  const description = (
    <>
      <ProductDescription
        badges={regItemBadges}
        isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
        product={product}
        size={screenSize}
      />
      {addedByText && (
        <div className={css.reg_item_card__added_by}>
          <UserOutline />
          <span>{addedByText}</span>
        </div>
      )}
    </>
  )
  const controls = (
    <RegItemControls
      categories={categories}
      defaultCategory={product.category}
      size={screenSize}
      onCategoryChange={onCategoryChange}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
    />
  )

  // Invisible button placed over the tablet and phone cards
  const shouldUseButton =
    regItem.canAddDirectlyToCart && !regItem.isExternalRegistry
  const selfPurchaseButton = shouldUseButton ? (
    <button
      className={css.reg_item_card__show_buying_options_button}
      type="button"
      onClick={onSelfPurchaseButtonClick}
    >
      <span className="sr-only">
        See all buying options for {regItem.title}
      </span>
    </button>
  ) : (
    <a
      className={css.reg_item_card__show_buying_options_button}
      href={regItem.url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <span className="sr-only">Go to product page for {regItem.title}</span>
    </a>
  )

  if (screenSize === 'desktop') {
    return (
      <div
        className={RegItemCardClasses}
        ref={props.innerRef}
        {...props.draggableProps}
        {...props}
      >
        <div
          className={classNames(css.reg_item_card__content, {
            [css['reg_item_card__content--selfPurchaseExperiment']]:
              isInSelfPurchaseExperiment,
          })}
        >
          <div
            {...props.dragHandleProps}
            className={classNames(css.reg_item_card__image, {
              [css['reg_item_card__image--selfPurchaseExperiment']]:
                isInSelfPurchaseExperiment,
            })}
          >
            {image}
          </div>
          <div
            {...props.dragHandleProps}
            className={css.reg_item_card__description}
          >
            {description}
            {isInSelfPurchaseExperiment && controls}
          </div>
          <div
            className={classNames(css.reg_item_card__options, {
              [css['reg_item_card__options--selfPurchaseExperiment']]:
                isInSelfPurchaseExperiment,
            })}
          >
            {product.offers &&
              product.offers.length > 0 &&
              !regItem.isExternalRegistry && (
                <BuyingOptions
                  isGiftCard={product.isGiftCard || false}
                  options={product.offers}
                  regItem={regItem}
                  onBuyingOptionClick={onBuyingOptionClick}
                />
              )}
            {!isInSelfPurchaseExperiment && controls}
          </div>
        </div>
        {isDraggingEnabled && (
          <div
            {...props.dragHandleProps}
            aria-label="Drag Handle"
            className={css.reg_item_card__drag_handle}
          >
            <DragHandle />
          </div>
        )}
      </div>
    )
  }

  if (screenSize === 'tablet') {
    return (
      <div
        isDragDisabled
        className={RegItemCardClasses}
        ref={props.innerRef}
        {...props.draggableProps}
        {...props}
        tabIndex={-1}
      >
        <div className={css.reg_item_card__content}>
          <div className={css.reg_item_card__image}>{image}</div>
          <div className={css.reg_item_card__description}>
            <span>{description}</span>
            {controls}
          </div>
          {product.notes && (
            <div className={css.reg_item_card__notes}>
              <ProductNotes notes={product.notes} />
            </div>
          )}
        </div>
        {isInSelfPurchaseExperiment && selfPurchaseButton}
      </div>
    )
  }

  return (
    <div
      isDragDisabled
      className={RegItemCardClasses}
      ref={props.innerRef}
      {...props.draggableProps}
      {...props}
      tabIndex={-1}
    >
      <div className={css.reg_item_card__content}>
        <div className={css.reg_item_card__image}>{image}</div>
        <div className={css.reg_item_card__description}>
          <span>{description}</span>
          {controls}
        </div>
        {product.notes && (
          <div className={css.reg_item_card__notes}>
            <ProductNotes notes={product.notes} />
          </div>
        )}
      </div>
      {isInSelfPurchaseExperiment && selfPurchaseButton}
    </div>
  )
}

export default RegItemCard
