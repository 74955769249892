import classnames from 'classnames'
import { pdpPath, storeCategoryPath } from 'lib/urls'
import { trackBreadcrumbLinkClick } from 'src/routes/(shop)/lib/tracking'
import { useTracking } from 'lib/analytics'
import { TrackingHook } from 'react-tracking'
import css from './Item.styles.scss'

interface ItemProps {
  title: string
  slug: string
  index?: number
  genericProductId?: string
  productId?: string | undefined
  isLast?: boolean
  hidden?: boolean
}

const handleClick = (trackEvent: TrackingHook['trackEvent']) => () => {
  trackBreadcrumbLinkClick(trackEvent)
}

export const Item = (props: ItemProps) => {
  const { title, slug, index, genericProductId, productId, isLast, hidden } =
    props
  const separator = '/'
  const path = productId
    ? pdpPath(slug, genericProductId, productId)
    : storeCategoryPath(slug)
  const { trackEvent } = useTracking()

  return (
    <li
      className={classnames(css.Item, hidden && css['Item--hidden'])}
      key={`crumb-${title}-${index}`}
    >
      <a
        className={css.Item__link}
        href={path}
        onClick={handleClick(trackEvent)}
      >
        {title}
      </a>
      {!isLast && (
        <span aria-hidden="true" className={css.Item__separator}>
          {separator}
        </span>
      )}
    </li>
  )
}
