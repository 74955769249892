import { Registry } from 'src/types/registry'
import NiceModal from '@ebay/nice-modal-react'
import { useState, useEffect, useCallback, useRef } from 'react'
import GiftGiverReminderModal from '../../components/GiftGiverReminderModal'

type UseGiftGiverRemindersProps = {
  registry: Registry | undefined
  anyVisitorReservations?: boolean
  anyCartItems?: boolean
}

export const REMINDER_DELAY = 60000

export const MODAL_VIEWED_STORAGE_KEY = 'giftGiverReminderModalViewed'

const useModalViewCount = (registry: Registry | undefined) => {
  const [currentModalViewCount, setCurrentModalViewCount] = useState(0)
  const [lastViewedAt, setLastViewedAt] = useState<Date | null>(null)

  useEffect(() => {
    if (typeof window === 'undefined' || !registry?.id) return

    const storedData = localStorage.getItem(MODAL_VIEWED_STORAGE_KEY)
    const parsedData = storedData ? JSON.parse(storedData) : {}
    const viewCount = parsedData[registry.id]?.count || 0
    const lastViewed = parsedData[registry.id]?.lastViewedAt || null
    setCurrentModalViewCount(viewCount)
    setLastViewedAt(lastViewed ? new Date(lastViewed) : null)
  }, [registry?.id])

  const incrementModalViewCount = useCallback((): void => {
    if (!registry?.id) return

    const storedData = localStorage.getItem(MODAL_VIEWED_STORAGE_KEY)
    const parsedData = storedData ? JSON.parse(storedData) : {}
    const newViewCount = currentModalViewCount + 1
    const newLastViewedAt = new Date().toISOString()
    parsedData[registry.id] = {
      count: newViewCount,
      lastViewedAt: newLastViewedAt,
    }
    localStorage.setItem(MODAL_VIEWED_STORAGE_KEY, JSON.stringify(parsedData))
    setCurrentModalViewCount(newViewCount)
    setLastViewedAt(new Date(newLastViewedAt))
  }, [currentModalViewCount, registry?.id])

  return { currentModalViewCount, incrementModalViewCount, lastViewedAt }
}

/*
 * Return true if any dialogs are in the document that have an offset parent
 * which means they are actually visible and not set to display none
 */
const anyVisibleDialogs = (): boolean =>
  !!document.querySelector('[role="dialog"]')

export const useGiftGiverReminders = (props: UseGiftGiverRemindersProps) => {
  const { registry } = props
  const { currentModalViewCount, incrementModalViewCount } =
    useModalViewCount(registry)
  /*
   * We use this props ref to ensure our useEffect that sets our timer
   * doesn't restart the timer on props changes/rerenders, but we can
   * refer to the latest state of the props when determining if the
   * modal is eligible.
   */
  const propsRef = useRef(props)
  useEffect(() => {
    propsRef.current = props
  }, [props])

  useEffect(() => {
    const { registry: currentRegistry } = propsRef.current

    const showReminder = () => {
      if (
        currentRegistry?.isEligibleForGiftGiverReminders &&
        !propsRef.current.anyVisitorReservations &&
        !propsRef.current.anyCartItems &&
        !anyVisibleDialogs() &&
        currentModalViewCount < 1
      ) {
        incrementModalViewCount()
        NiceModal.show(GiftGiverReminderModal, {
          registry: currentRegistry,
        })
      }
    }

    const timer = setTimeout(showReminder, REMINDER_DELAY)

    return () => clearTimeout(timer)
  }, [currentModalViewCount, incrementModalViewCount])
}

export default useGiftGiverReminders
