/* eslint-disable complexity */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import classNames from 'classnames'
import { Offer } from 'src/types/offer'
import { Button, TextLink } from 'baby-design'
import { Plus, Minus } from 'baby-design/icons'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { RegItem } from 'src/types/regItem'
import { BuyingOption } from './components/BuyingOption'
import { OutOfStockOption } from './components/OutOfStockOption'
import { SelfPurchaseBuyingOption } from './components/SelfPurchaseBuyingOption'
import useGroupGiftUnredeemedFundsModal from '../../../../hooks/useGroupGiftUnredeemedFundsModal'
import { useCartItemManager } from '../../../../utils/addToCartHelpers'

import css from './BuyingOptions.styles.scss'
import { track } from 'lib/analytics'

interface BuyingOptionsProps {
  options?: Array<Offer>
  onBuyingOptionClick: (offer: Offer) => void
  isGiftCard: boolean
  regItem: RegItem
}

export const BuyingOptions = ({
  options = [],
  onBuyingOptionClick,
  isGiftCard,
  regItem,
}: BuyingOptionsProps) => {
  const { openUnredeemedFundsModalWithDelay } =
    useGroupGiftUnredeemedFundsModal(regItem)
  const [isLoading, setIsLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const toastProductImageOverride = (
    <div className={css.buying_options__productImageContainer}>
      <img
        alt={regItem.title}
        className={css.buying_options__productImage}
        src={regItem.imgUrl}
      />
    </div>
  )
  const { addToCart, updateCartItemQuantity, removeCartItem } =
    useCartItemManager({
      regItem,
      imageOverride: toastProductImageOverride,
      unredeemedFundsCallback: openUnredeemedFundsModalWithDelay,
      trackingEventLocation:
        track.EventLocation.REGISTRY_MINUS_BUYING_OPTION_REG_ITEM_CARD,
    })

  const { flagValue } = useFeatureFlag(
    '24e_ex_dayzero_self_purchase',
    'Control'
  )
  const showBuyingButtons =
    flagValue === 'Treatment 1' && regItem.canAddDirectlyToCart

  const quantityInCart = regItem.cartInfo?.quantity || 0

  const numMinimizedOptionsToShow = 3
  const slicedOptions = isExpanded
    ? options
    : options.slice(0, numMinimizedOptionsToShow)
  const isOptionsSliced = options.length > numMinimizedOptionsToShow
  const numMoreOptions = options.length - numMinimizedOptionsToShow

  const handleAddToCart = () => {
    setIsLoading(true)
    addToCart().finally(() => {
      setIsLoading(false)
    })
  }

  const handleUpdateCartItemQuantity = (quantity: number) => {
    setIsLoading(true)
    updateCartItemQuantity(quantity).finally(() => {
      setIsLoading(false)
    })
  }

  const handleRemoveCartItem = () => {
    setIsLoading(true)
    removeCartItem().finally(() => {
      setIsLoading(false)
    })
  }

  const ShowMoreButton = showBuyingButtons ? (
    <Button
      className={css.buying_options__showMoreButton}
      size="sm"
      variant="secondary"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded
        ? 'Show less'
        : `+ ${numMoreOptions} more option${numMoreOptions > 1 ? 's' : ''}`}
    </Button>
  ) : (
    <TextLink
      className={css.buying_options__control}
      icon={{ side: 'left', icon: isExpanded ? <Minus /> : <Plus /> }}
      size="xs"
      variant="default"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      {isExpanded ? 'Show Less' : `${numMoreOptions} more`}
    </TextLink>
  )

  const renderBuyingOption = (option: Offer) => {
    if (showBuyingButtons) {
      if (option.isBabylist && !regItem.isAvailableOnBabylist) {
        return (
          <OutOfStockOption
            isBabylistProductDiscontinued={
              regItem.isBabylistProductDiscontinued
            }
            option={option}
            productId={regItem.productId}
          />
        )
      }

      return (
        <SelfPurchaseBuyingOption
          handleAddToCart={handleAddToCart}
          handleRemoveCartItem={handleRemoveCartItem}
          handleUpdateCartItemQuantity={handleUpdateCartItemQuantity}
          isGiftCard={isGiftCard}
          isLoading={isLoading}
          key={option.url}
          option={option}
          quantityInCart={quantityInCart}
          regItemId={regItem.id}
          onBuyingOptionClick={onBuyingOptionClick}
        />
      )
    }
    return (
      <BuyingOption
        isGiftCard={isGiftCard}
        key={option.url}
        option={option}
        onBuyingOptionClick={onBuyingOptionClick}
      />
    )
  }

  return (
    <div
      className={classNames(css.buying_options, {
        [css['buying_options--selfPurchase']]: showBuyingButtons,
      })}
    >
      <div className={css.buying_options__heading}>
        {showBuyingButtons ? 'Buying options' : 'Buying Options'}
      </div>
      <div className={css.buying_options__options}>
        {slicedOptions.map(renderBuyingOption)}
      </div>
      {isOptionsSliced && ShowMoreButton}
    </div>
  )
}
