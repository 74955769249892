import React from 'react'

import { Shipment } from 'src/types/shipments'

import {
  deliveredShipments,
  shippedShipments,
  upcomingShipments,
} from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import EmptyState from '../EmptyState/EmptyState'
import ShipmentsSection from '../ShipmentsSection/ShipmentsSection'

import { ShipmentsContentProps } from './ShipmentsContent.types'
import css from './ShipmentsContent.styles.scss'
import ShipmentsContentSkeleton from './ShipmentsContent.skeleton'

const ShipmentsSections: React.FC<{ shipments: Shipment[] }> = ({
  shipments,
}) => {
  const upcoming = upcomingShipments(shipments)
  const shipped = shippedShipments(shipments)
  const delivered = deliveredShipments(shipments)

  return (
    <>
      <ShipmentsSection shipments={upcoming} title="Upcoming" />
      <ShipmentsSection shipments={shipped} title="On the way" />
      <ShipmentsSection shipments={delivered} title="Delivered" />
    </>
  )
}

const ShipmentsContentBody: React.FC<ShipmentsContentProps> = ({
  registry,
  shipments,
  isLoading,
}) => {
  if (isLoading) {
    return <ShipmentsContentSkeleton />
  }

  if (!shipments || shipments.length == 0) {
    const hasAddress = !!registry?.shippingAddress?.address1

    return <EmptyState hasAddress={hasAddress} />
  }

  return <ShipmentsSections shipments={shipments} />
}

const ShipmentsContent: React.FC<ShipmentsContentProps> = (props) => (
  <div className={css.ShipmentsContent}>
    <ShipmentsContentBody {...props} />
  </div>
)

export default ShipmentsContent
