import React, { useState } from 'react'
import RegistryDetailsEditModal from './RegistryDetailsEditModal/RegistryDetailsEditModal'
import RegistryImage from './RegistryImage/RegistryImage'
import RegistryTitleActions from './RegistryActions/RegistryActions'
import RegistryTitle from './RegistryTitle/RegistryTitle'
import css from './RegistryHeading.styles.scss'
import { RegistryHeadingProps } from './RegistryHeading.types'

const RegistryHeading: React.FC<RegistryHeadingProps> = ({
  currentRegistry,
  handlePrivacyStatusClick,
}) => {
  const [registryDetailsEditModalIsOpen, setRegistryDetailsEditModalIsOpen] =
    useState(false)
  const handleClickEditRegistry = () => setRegistryDetailsEditModalIsOpen(true)

  return (
    <div className={css.RegistryHeading__skeleton}>
      <RegistryImage
        currentRegistry={currentRegistry}
        onClick={handleClickEditRegistry}
      />
      <div className={css.RegistryHeading__titleAndActions}>
        <RegistryTitle
          currentRegistry={currentRegistry}
          handleEdit={handleClickEditRegistry}
          handlePrivacyStatusClick={handlePrivacyStatusClick}
        />
        <RegistryTitleActions currentRegistry={currentRegistry} />
      </div>
      <RegistryDetailsEditModal
        currentRegistry={currentRegistry}
        handleClose={() => setRegistryDetailsEditModalIsOpen(false)}
        isOpen={registryDetailsEditModalIsOpen}
      />
    </div>
  )
}

export default RegistryHeading
