import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { Button } from 'baby-design'

import { PrivateAddressDisclaimer } from 'registry/forms/shipping-address-form/shipping-fields'
import { Checkbox, Form, Input, Radio, RadioGroup } from 'components/forms'
import LinkCopier from 'registry/share-page/components/link-copier'
import { CONFIG } from 'shared/constants'
import fetch from 'lib/fetch'

import { errorMessage, successMessage } from 'lib/flash-message'
import { apiV3RegistryPath } from 'lib/urls'
import css from './RegistryVisibilityForm.styles.scss'
import {
  RegistryVisibilityFormProps,
  RegistryVisibilityFormFields,
  SearchLevelOptions,
} from './RegistryVisibilityForm.types'

const RegistryVisibilityForm: FC<RegistryVisibilityFormProps> = ({
  onSubmitSuccess,
  registry,
}) => {
  const { searchLevelOptions } = CONFIG.registry

  const [submitting, setSubmitting] = useState(false)

  const initialValues: RegistryVisibilityFormFields = {
    passwordAccess: !!registry.visitorPassword,
    registry: {
      privateAddress: registry.shippingAddress.privateAddress,
      searchLevel: registry.searchLevel,
      visitorPassword: registry.visitorPassword || '',
    },
  }
  const { errors, register, handleSubmit, watch, setValue } = useForm({
    defaultValues: initialValues,
  })

  useEffect(() => {
    register({ name: 'registry.searchLevel' })
  }, [register])

  const passwordAccess = watch('passwordAccess')
  const searchLevel = watch('registry.searchLevel')
  const privateAddress = watch('registry.privateAddress')

  const handleSearchLevelChange = (value: SearchLevelOptions) => {
    setValue('registry.searchLevel', value)
  }

  const _handleSubmit = (formData: RegistryVisibilityFormFields) => {
    setSubmitting(true)

    if (!passwordAccess) {
      formData.registry.visitorPassword = null
    }

    const submitData = {
      passwordAccess: formData.passwordAccess,
      registry: {
        ...formData.registry,
        id: registry.id,
      },
    }

    fetch(apiV3RegistryPath(registry.id), {
      body: JSON.stringify(submitData),
      method: 'PUT',
    })
      .then(() => {
        setSubmitting(false)
        successMessage(
          `Privacy settings updated! Your registry's visibility has been updated.`
        )
        onSubmitSuccess()
      })
      .catch((error) => {
        console.error('Error updating registry visibility', error)
        errorMessage(
          'Error updating registry visibility. If this continues, please contact customer support.'
        )
        setSubmitting(false)
      })
  }

  const registryShareUrl =
    registry.giftGiverUrl.match(/https?:\/\/(.*)/)?.[1] || ''
  const generateVisitorPasswordErrorMessage = () => {
    if (errors.registry?.visitorPassword?.type === 'required') {
      return 'Visitor Password is required'
    }
    return null
  }

  return (
    <>
      <LinkCopier link={registryShareUrl} />
      <Form onSubmit={handleSubmit(_handleSubmit)}>
        <fieldset>
          <RadioGroup aria-label="Privacy Options" className="">
            <Radio
              checked={
                searchLevel === searchLevelOptions.babylist ||
                searchLevel === searchLevelOptions.public
              }
              help="Visitors can search Babylist to find your registry."
              label={
                <b>
                  <i className="fa fa-globe" /> Public
                </b>
              }
              value={searchLevelOptions.babylist}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                handleSearchLevelChange(e.target.value as SearchLevelOptions)
              }
            />
            {(searchLevel == searchLevelOptions.babylist ||
              searchLevel == searchLevelOptions.public) && (
              <Checkbox
                checked={searchLevel == searchLevelOptions.public}
                groupClassName={classNames(css.publicCheckbox, 'mbl')}
                help="Listing on Google can take up to two weeks."
                label="Visitors can search Google to find your registry."
                onChange={() => {
                  if (searchLevel === searchLevelOptions.public) {
                    handleSearchLevelChange(
                      searchLevelOptions.babylist as SearchLevelOptions
                    )
                  } else {
                    handleSearchLevelChange(
                      searchLevelOptions.public as SearchLevelOptions
                    )
                  }
                }}
              />
            )}
            <Radio
              checked={searchLevel === searchLevelOptions.private}
              help="Visitors must have the direct link to your registry."
              label={
                <b>
                  <i className="fa fa-lock" /> Private
                </b>
              }
              value={searchLevelOptions.private}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                handleSearchLevelChange(e.target.value as SearchLevelOptions)
              }
            />
          </RadioGroup>

          <hr className="mbl" />

          <Checkbox
            className="password-required"
            inputRef={register}
            label="Visitors need a password to access your registry"
            name="passwordAccess"
          />

          <Collapse in={passwordAccess}>
            <div>
              <Input
                error={generateVisitorPasswordErrorMessage()}
                id="visitor_password"
                inputRef={register({ required: passwordAccess })}
                label="Visitor Password"
                name="registry.visitorPassword"
                placeholder="Visitor Password"
              />
            </div>
          </Collapse>

          <Checkbox
            className="address-private"
            inputRef={register}
            label="Do not share my address with my gift givers."
            name="registry.privateAddress"
          />
          {privateAddress && <PrivateAddressDisclaimer />}
        </fieldset>
        <div className="flex center">
          <Button
            block
            loading={submitting}
            size="md"
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  )
}

export default RegistryVisibilityForm
