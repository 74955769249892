import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import css from './RegItemRemovedMessage.styles.scss'

const dataTestId = 'reservation-card-reg-item-removed-message'

const RegItemRemovedMessage: React.FC = () => (
  <div className={css.RegItemRemovedMessage} data-testid={dataTestId}>
    This item was reserved but removed from your registry.
    <OverlayTrigger
      overlay={
        <Tooltip className="h7" id="tooltip">
          Gift givers will no longer see the option to buy this item, but it may
          have been purchased already.
        </Tooltip>
      }
    >
      <i className="fa fa-info-circle mls text-muted" />
    </OverlayTrigger>
  </div>
)

export default RegItemRemovedMessage
