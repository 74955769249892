/* eslint-disable import/no-extraneous-dependencies */
import classNames from 'classnames'
import { Offer } from 'src/types/offer'
import { StoreIcon, PriceTag, Button, QuantityPicker } from 'baby-design'
import { LinkOut, ShoppingCartEmpty } from 'baby-design/icons'
import {
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { track } from 'lib/analytics'
import { formatDisplayPrice } from '../../../../../../utils/productHelpers'

import css from './SelfPurchaseBuyingOption.styles.scss'

interface SelfPurchaseBuyingOptionProps {
  option: Offer
  regItemId: number
  onBuyingOptionClick: (offer: Offer) => void
  quantityInCart: number
  isLoading: boolean
  isGiftCard: boolean
  handleAddToCart: () => void
  handleUpdateCartItemQuantity: (quantity: number) => void
  handleRemoveCartItem: () => void
}

const LegacyPriceTag = ({
  price,
  isGiftCard,
}: {
  price: string | number | undefined
  isGiftCard: boolean
}) => (
  <div className={css.SelfPurchaseBuyingOption__price}>
    {isGiftCard ? '$25 – $1,000' : formatDisplayPrice(price)}
  </div>
)

export const SelfPurchaseBuyingOption = ({
  option,
  regItemId,
  onBuyingOptionClick,
  quantityInCart,
  isLoading,
  isGiftCard,
  handleAddToCart,
  handleRemoveCartItem,
  handleUpdateCartItemQuantity,
}: SelfPurchaseBuyingOptionProps) => {
  let priceTagProps = {}
  if (isGiftCard) {
    priceTagProps = {
      minPrice: ELECTRONIC_GIFT_CARD_MIN,
      maxPrice: ELECTRONIC_GIFT_CARD_MAX,
      truncateZeroCents: true,
    }
  } else if (option.priceDetails) {
    priceTagProps = getPriceTagPropsFromPriceDetails(option.priceDetails)
  }

  const trackedHandleAddToCart = () => {
    track.registryProductOfferClicked({
      eventLocation:
        track.EventLocation.REGISTRY_MINUS_BUYING_OPTION_REG_ITEM_CARD,
      registryId: currentUser?.currentRegistry.id,
      registryOwnerId: currentUser?.currentRegistry.ownerId,
      listType: currentUser?.currentRegistry.type || 'baby_registry',
      isRegistryOwner: currentUser?.currentRegistry.currentUserRole === 'owner',
      store: option.storeDisplayName,
      regItemId,
      offerIds: [option.id],
    })
    handleAddToCart()
  }

  const babylistOfferControl =
    quantityInCart > 0 ? (
      <button
        className={css.SelfPurchaseBuyingOption__quantityPickerWrapper}
        type="button"
        onClick={(e) => e.stopPropagation()}
      >
        <QuantityPicker
          className={css.SelfPurchaseBuyingOption__quantityPicker}
          size="sm"
          type="cart"
          value={quantityInCart}
          variant="fill"
          onMinusClick={() => handleUpdateCartItemQuantity(quantityInCart - 1)}
          onPlusClick={() => handleUpdateCartItemQuantity(quantityInCart + 1)}
          onTrashClick={handleRemoveCartItem}
        />
      </button>
    ) : (
      <Button
        className={classNames(
          css.SelfPurchaseBuyingOption__purchaseButton,
          css.SelfPurchaseBuyingOption__addToCartButton
        )}
        icon={{ side: 'right', icon: <ShoppingCartEmpty />, size: 'xl' }}
        loading={isLoading}
        size="sm"
        variant="de-emphasized"
        onClick={(e) => {
          e.stopPropagation()
          trackedHandleAddToCart()
        }}
      >
        Add
      </Button>
    )

  return (
    <button
      className={css.SelfPurchaseBuyingOption}
      role="link"
      type="button"
      onClick={() => onBuyingOptionClick(option)}
    >
      <div className={css.SelfPurchaseBuyingOption__info}>
        <StoreIcon
          className={css.SelfPurchaseBuyingOption__icon}
          store={{ name: option.storeIconName }}
        />
        <div className={css.SelfPurchaseBuyingOption__nameAndPrice}>
          <div className={css.SelfPurchaseBuyingOption__name}>
            {option.storeDisplayName}
          </div>
          {option.priceDetails ? (
            <PriceTag {...priceTagProps} size="2xs" variant="offer" />
          ) : (
            <LegacyPriceTag isGiftCard={isGiftCard} price={option.price} />
          )}
        </div>
      </div>
      {option.isBabylist ? (
        babylistOfferControl
      ) : (
        <Button
          className={css.SelfPurchaseBuyingOption__purchaseButton}
          icon={{ side: 'right', icon: <LinkOut /> }}
          size="sm"
          variant="tertiary"
          onClick={(e) => {
            e.stopPropagation()
            onBuyingOptionClick(option)
          }}
        >
          View
        </Button>
      )}
    </button>
  )
}
