import { useQuery } from '@tanstack/react-query'
import { fetchRegistry, FETCH_REGISTRY_KEY } from 'src/api/queries'
import { Registry } from 'src/types/registry'

const useGetRegistry = (slug: string | undefined) => {
  const registryQuery = useQuery({
    enabled: !!slug,
    queryKey: [FETCH_REGISTRY_KEY, slug],
    queryFn: () => fetchRegistry(slug),
  })
  const registry: Registry = registryQuery.data || {}

  return { registry, registryQuery }
}

export default useGetRegistry
