import { useDispatch } from 'react-redux'
import { openRecoverReservationModal } from 'registry/modals/actions'

export const useOpenRecoverReservationModal = (regItemId: number) => {
  // Because we are using the old modal flows for this, we need to use redux to manage the state and maintain compatibility.
  // Any calls to this function must be made within the context of a redux provider.
  const dispatch = useDispatch()

  return () => dispatch(openRecoverReservationModal(regItemId))
}
