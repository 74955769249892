import React from 'react'
import css from './SurpriseMessage.styles.scss'

const dataTestId = 'reservation-card-surprise-message'

const SurpriseMessage: React.FC = () => (
  <div className={css.SurpriseMessage} data-testid={dataTestId}>
    <strong>Reminder! 🤫</strong>
    <br />
    This person requested to keep this a surprise.
  </div>
)

export default SurpriseMessage
