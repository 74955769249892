import React from 'react'
import classNames from 'classnames'
import { BIRTHDAY_REGISTRY_TYPE, HOLIDAY_REGISTRY_TYPE } from 'src/constants'
import RegistryAvatar from 'registry/components/registry-avatar/registry-avatar'
import AdditionalHeaderDetails from './AdditionalHeaderDetails'
import EmptyAvatar from './EmptyAvatar'
import WishlistInfo from './WishlistInfo'
import { RegistryGiftGiverHeaderProps } from './RegistryGiftGiverHeader.types'
import styles from './RegistryGiftGiverHeader.styles.scss'

const RegistryGiftGiverHeader: React.FC<RegistryGiftGiverHeaderProps> = ({
  registry,
}) => {
  const { headerDetails, type } = registry
  const { avatarEmptyStateText, avatarImageUrl } = headerDetails
  const isWishlist = [HOLIDAY_REGISTRY_TYPE, BIRTHDAY_REGISTRY_TYPE].includes(
    type || ''
  )

  const avatarClasses = classNames(styles.avatar, 'img-responsive')

  return (
    <div
      className={styles.RegistryGiftGiverHeader}
      data-testid="RegistryGiftGiverHeader"
    >
      <div className={styles.avatar_container}>
        {avatarImageUrl ? (
          <RegistryAvatar
            altText="Registry owner avatar"
            className={avatarClasses}
            editable={false}
            url={avatarImageUrl}
          />
        ) : (
          <EmptyAvatar
            avatarEmptyStateText={avatarEmptyStateText}
            className={avatarClasses}
            isWishlist={isWishlist}
          />
        )}
      </div>
      <div className={styles.details_container}>
        <h1 className={styles.title}>{registry.title}</h1>
        <AdditionalHeaderDetails registry={registry} />
      </div>
      {isWishlist && <WishlistInfo registry={registry} />}
    </div>
  )
}

export default RegistryGiftGiverHeader
