import { DatadogRumAsync } from './datadogRumAsync.types'

export const DATADOG_RUM_READY_INTERVAL = 250

export const datadogRumAsync: DatadogRumAsync = ({
  callback,
  attempts = 1,
  maxAttempts = 1,
}) => {
  if (typeof window === 'undefined') return

  if (typeof window.DD_RUM === 'undefined') {
    if (attempts < maxAttempts) {
      setTimeout(
        () =>
          datadogRumAsync({ callback, attempts: attempts + 1, maxAttempts }),
        DATADOG_RUM_READY_INTERVAL
      )
    }

    return
  }

  window.DD_RUM.onReady(() => callback(window.DD_RUM))
}
