import classnames from 'classnames'
import Clickable from 'components/clickable'
import { useState, useRef, useEffect } from 'react'
import { GenericProduct } from 'src/types/genericProduct'
import { Heart } from 'baby-design/icons'
import { useTracking } from 'lib/analytics'
import {
  trackPdpContentDescriptionInteraction,
  trackPdpContentDetailsInteraction,
} from 'src/routes/(shop)/lib/tracking'
import css from './Content.styles.scss'
import { Section } from './Section/Section'

interface ContentProps {
  genericProduct: GenericProduct
}

function checkOverflow(el: React.RefObject<HTMLDivElement>) {
  if (!el.current) return false

  const { offsetHeight, scrollHeight } = el.current

  // Account for the line height of the last line of text
  return offsetHeight && scrollHeight && offsetHeight + 8 < scrollHeight
}

export const Content = ({ genericProduct }: ContentProps) => {
  const { details } = genericProduct
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false)
  const [isDetailsOverflowing, setIsDetailsOverflowing] = useState(false)
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const { trackEvent } = useTracking()

  if (!details) {
    return null
  }

  const { description, highlight, specs, summary } = details

  const toggleSection = (
    isExpanded: boolean,
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
    ref: React.RefObject<HTMLDivElement>
  ) => {
    if (isExpanded && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
    setExpanded(!isExpanded)
  }

  useEffect(() => {
    if (checkOverflow(descriptionRef)) {
      setIsDescriptionOverflowing(true)
    }
  }, [descriptionRef?.current])

  useEffect(() => {
    if (checkOverflow(detailsRef)) {
      setIsDetailsOverflowing(true)
    }
  }, [detailsRef?.current])

  return (
    <div className={css.Content}>
      {(summary || description || highlight) && (
        <div className={css.Content__block}>
          <h2 className={css.Content__details__header}>Overview</h2>
          <div
            className={classnames(
              css.Content__details,
              isDescriptionExpanded
                ? css['Content__details--expanded']
                : css['Content__details--collapsed']
            )}
            ref={descriptionRef}
          >
            {summary && <Section content={summary} title="Highlights" />}
            {description && (
              <Section content={description} title="Description" />
            )}
            {highlight && (
              <Section
                content={highlight}
                title={
                  <>
                    What we&nbsp;
                    <Heart
                      className={css.Content__details__icon}
                      data-testid="heart-icon"
                      title="love"
                    />
                    &nbsp;about it
                  </>
                }
              />
            )}
          </div>
          {isDescriptionOverflowing && (
            <Clickable
              aria-label={`${
                isDescriptionExpanded ? 'Collapse' : 'Expand'
              } description`}
              className={css.Content__details__toggle}
              to={null}
              url={null}
              onClick={() => {
                trackPdpContentDescriptionInteraction({
                  trackEvent,
                  isExpanded: isDescriptionExpanded,
                })
                toggleSection(
                  isDescriptionExpanded,
                  setIsDescriptionExpanded,
                  descriptionRef
                )
              }}
            >
              {isDescriptionExpanded ? 'Show less' : 'Show more'}
            </Clickable>
          )}
        </div>
      )}
      {specs && (
        <div className={css.Content__block}>
          <div
            className={classnames(
              css.Content__details,
              isDetailsExpanded
                ? css['Content__details--expanded']
                : css['Content__details--collapsed']
            )}
            ref={detailsRef}
          >
            <h2 className={css.Content__details__header}>Details</h2>
            <Section content={specs} />
          </div>
          {isDetailsOverflowing && (
            <Clickable
              aria-label={`${
                isDetailsExpanded ? 'Collapse' : 'Expand'
              } details`}
              className={css.Content__details__toggle}
              to={null}
              url={null}
              onClick={() => {
                trackPdpContentDetailsInteraction({
                  trackEvent,
                  isExpanded: isDetailsExpanded,
                })
                toggleSection(
                  isDetailsExpanded,
                  setIsDetailsExpanded,
                  detailsRef
                )
              }}
            >
              {isDetailsExpanded ? 'Show less' : 'Show more'}
            </Clickable>
          )}
        </div>
      )}
    </div>
  )
}
