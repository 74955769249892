import { Offer } from 'src/types/offer'
import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import { track, useTracking } from 'lib/analytics'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { CurrentUser } from '../../../../../../../global'

export const useTrackRegistryProductOfferClicked = (
  registry: Registry,
  regItem?: RegItem,
  currentUser?: CurrentUser,
  offer?: Offer
) => {
  const store =
    offer?.storeDisplayName || regItem?.offers?.[0]?.storeDisplayName
  const offerIds = [offer?.id || regItem?.offers?.[0]?.id]
  const tracker = useTracking()

  return () => {
    tracker.trackEvent({
      event: track.registryProductOfferClicked,
      store,
      offerIds,
      regItemId: regItem?.id,
      ...trackingRegistryProperties({
        registry,
        currentUser,
      }),
    })
  }
}
