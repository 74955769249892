import React from 'react'
import { SimpleCard } from 'baby-design'
import classNames from 'classnames'
import css from './ShimmerCard.styles.scss'
import { ShimmerCardProps } from './ShimmerCard.types'

const ShimmerCard: React.FC<ShimmerCardProps> = ({ variant }) => {
  const className = classNames(css.ShimmerCard__shimmer, {
    [css.ShimmerCard__shimmer__desktop]: variant === 'desktop',
    [css.ShimmerCard__shimmer__mobile]: variant === 'mobile',
  })

  return (
    <div data-testid="shimmer-card">
      <SimpleCard content={<span className={className} />} />
    </div>
  )
}

export default ShimmerCard
