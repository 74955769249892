import dateformat from 'dateformat'
import pluralize from 'pluralize'
import { PureComponent, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Tappable from 'react-tappable'
import { maxBy, find } from 'lodash'
import { Collapse } from 'react-bootstrap'
import Image from 'components/image'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import GiftMessageCard from './gift-message-card'
import ReservationCard from './reservation-card'
import css from './gift-giver-wrapper.scss'
import { revealReservations } from 'registry/api'
import SurpriseWarningDialog from './SurpriseWarningDialog/SurpriseWarningDialog'

class GiftGiverWrapper extends PureComponent {
  constructor(props) {
    super(props)
    this.toggleCollapse = this.toggleCollapse.bind(this)
  }

  state = {
    show: this.props.show,
    // Currently, we group GGs by email, but technically each reservation can have
    // a different name and gift message.
    // We're setting these as states so that the displayed name and gift messages don't
    // change while using the search filter.
    reservationWithGiftMessage: find(
      this.props.reservations,
      (res) => res.giftMessage
    ),
    ggName: this.props.reservations[0].name,
    ggEmail: this.props.reservations[0].email,
    isSurpriseWarningBypassed: false,
  }

  toggleCollapse() {
    this.setState({
      show: !this.state.show,
    })
  }

  componentDidUpdate(prevProps) {
    const newProps = this.props
    if (prevProps.show !== newProps.show) {
      this.setState({ show: newProps.show })
    }
  }

  render() {
    const {
      reservationWithGiftMessage,
      ggName,
      ggEmail,
      show,
      isSurpriseWarningBypassed,
    } = this.state
    const { reservations, currentUser } = this.props

    const latestDate = maxBy(reservations, (res) => res.createdAt).createdAt
    const areAnyGiftsSurprise = reservations.some(
      (res) => res.isSurprise && !res.isSurpriseRevealedForUser
    )
    const shouldShowSurpriseWarning =
      areAnyGiftsSurprise && !isSurpriseWarningBypassed

    const handleBypassSurpriseWarning = () => {
      this.setState({ isSurpriseWarningBypassed: true })
      revealReservations(reservations.map((res) => res.token))
    }

    return (
      <article
        className={classNames(css.wrapper, 'animated fadeIn animate-400ms')}
      >
        <Tappable className={css.row} onClick={this.toggleCollapse}>
          <section className={classNames(css.section, css.nameSection)}>
            <span className="h7 mtn mbs visible-xs">
              {dateformat(latestDate, 'longDate')}
            </span>
            <span className="h5 text-bold mvn">{ggName}</span>
            <span className="h7 mvn">{ggEmail}</span>
          </section>

          <section
            className={classNames(
              css.section,
              css.dateSection,
              'hidden-xs hidden-sm'
            )}
          >
            <span className="h6">{dateformat(latestDate, 'longDate')}</span>
          </section>

          <section className={classNames(css.section, css.giftsCountSection)}>
            <GiftCountExpander
              reservations={reservations}
              show={show}
              shouldShowSurpriseWarning={shouldShowSurpriseWarning}
            />
            <i
              className={classNames(
                css.toggleIndicator,
                'fa fa-chevron-right',
                show ? css.unToggleIndicator : null
              )}
            />
          </section>
        </Tappable>

        <Collapse in={this.state.show}>
          <section>
            {shouldShowSurpriseWarning && (
              <SurpriseWarningDialog onBypass={handleBypassSurpriseWarning} />
            )}
            {!shouldShowSurpriseWarning && (
              <div className={css.collapsedSection}>
                <div className={css.giftMessageSection}>
                  {reservationWithGiftMessage ? (
                    <GiftMessageCard>
                      {reservationWithGiftMessage.giftMessage}
                    </GiftMessageCard>
                  ) : null}
                </div>
                <div className={css.giftsSection}>
                  {reservations.map((reservation) => (
                    <ReservationCard
                      key={reservation.id}
                      reservation={reservation}
                    />
                  ))}
                </div>
              </div>
            )}
          </section>
        </Collapse>
      </article>
    )
  }
}

GiftGiverWrapper.propTypes = {
  reservations: PropTypes.array,
  show: PropTypes.bool,
  currentUser: PropTypes.object,
}

export default withCurrentUser(GiftGiverWrapper)

export const GiftCountExpander = ({
  show,
  reservations,
  shouldShowSurpriseWarning,
}) => {
  return (
    <div className={classNames(css.giftCountExpander, { expanded: show })}>
      <span className={css.giftCount}>
        {reservations.length}{' '}
        <span>{pluralize('Gift', reservations.length)}</span>
      </span>
      {show &&
        reservations.map((reservation, idx) => {
          return (
            <div
              key={reservation.id}
              className={classNames(
                css.giftThumb,
                `animated animate-250ms fadeIn delay-${100 * idx}ms`
              )}
              style={{
                transform: `translateX(${35 * idx}px)`,
                zIndex: -1 * idx,
              }}
            >
              {!shouldShowSurpriseWarning && (
                <Image
                  role="presentation"
                  src={reservation.regItem.imgUrl}
                  width={60}
                />
              )}
            </div>
          )
        })}
    </div>
  )
}

GiftCountExpander.propTypes = {
  reservations: PropTypes.array,
  show: PropTypes.bool,
}
