import { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { storeCategoryPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { useInRouterContext } from 'react-router-dom-v5-compat'
import Link from 'components/link'
import { Search } from './components/Search'
import { ContentContainer } from '../ContentContainer'
import css from './Navbar.styles.scss'

const MOUSE_DELAY = 400 // ms

interface NavItem {
  title: string
  urlSlug: string
  children?: NavItem[]
  guides?: GuideItem[]
}

interface GuideItem {
  featuredImageUrl: string
  title: string
  url: string
}

interface NavbarProps {
  navItems: NavItem[]
}

interface NavbarDropdownProps {
  navItem: NavItem
  onClick: (item: NavItem) => void
}

const NavbarDropdown = ({ navItem, onClick }: NavbarDropdownProps) => {
  // Context: We need navbar links to navigate via react-router on PLP pages, but function as normal links
  // on PDP pages. When PLP pages have been upgraded to react router v6 we can remove this context check
  // transition everything to react router links.
  const inReactRouter6Context = useInRouterContext()

  return (
    <div className={css.NavbarDropdown} role="menu">
      <ul className={css.NavbarDropdown__list}>
        {navItem.children?.map((child: NavItem) => {
          const linkAction = inReactRouter6Context
            ? { url: storeCategoryPath(child.urlSlug) }
            : { to: storeCategoryPath(child.urlSlug) }
          return (
            <li className={css.NavbarDropdown__item} key={child.urlSlug}>
              <Link
                {...linkAction}
                className={css.NavbarDropdown__link}
                role="menuitem"
                onClick={() => onClick(child)}
              >
                {child.title}
              </Link>
            </li>
          )
        })}
      </ul>
      {navItem.guides && (
        <>
          <div className={css['NavbarDropdown__section-title']}>
            Shop by {navItem.title} Guide
          </div>
          <div className={css.NavbarDropdown__guides}>
            {navItem.guides?.map((guide: GuideItem) => (
              <a
                className={css.NavbarDropdown__guide}
                href={guide.url}
                key={guide.url}
              >
                <img
                  alt={guide.title}
                  className={css['NavbarDropdown__guide-image']}
                  src={guide.featuredImageUrl}
                />
                <div className={css['NavbarDropdown__guide-title']}>
                  {guide.title}
                </div>
              </a>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export const Navbar = ({ navItems = [] }: NavbarProps) => {
  const navRef = useRef<HTMLDivElement>(null)
  const tracker = useTracking()
  const [activeItem, setActiveItem] = useState<NavItem | null>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const inReactRouter6Context = useInRouterContext()

  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setActiveItem(null)
    }
  }

  const handleShowDropdown = (item: NavItem) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    if (activeItem && activeItem.urlSlug !== item.urlSlug) {
      setActiveItem(item)
    } else {
      timeoutRef.current = setTimeout(() => {
        setActiveItem(item)
      }, MOUSE_DELAY)
    }
  }

  const handleMouseLeaveDropdown = () => {
    timeoutRef.current = setTimeout(() => {
      setActiveItem(null)
    }, MOUSE_DELAY)
  }

  const cancelTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  const trackClick = (item: NavItem) => {
    const leafCategory = !item.children
    tracker.trackEvent({
      event: track.storeCategoryClicked,
      storeCategoryName: item.title,
      isLeafCategory: leafCategory,
      storeCategorySlug: item.urlSlug,
      eventLocation: track.EventLocation.SHOP_MINUS_FLYOUT_NAV,
    })
  }

  const handleClick = (item: NavItem) => {
    trackClick(item)
    setActiveItem(null)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <div className={css.Wrapper}>
      <ContentContainer>
        <Search />
        <nav
          aria-label="Store Navigation"
          className={css.Navbar}
          ref={navRef}
          role="navigation"
        >
          <ul className={css.Navbar__list}>
            {navItems.map((navItem: NavItem) => {
              const linkAction = inReactRouter6Context
                ? { url: storeCategoryPath(navItem.urlSlug) }
                : { to: storeCategoryPath(navItem.urlSlug) }
              return (
                <li className={css.Navbar__item} key={navItem.urlSlug}>
                  <Link {...linkAction} className={css['Navbar__link-wrapper']}>
                    <button
                      aria-expanded={activeItem?.urlSlug === navItem.urlSlug}
                      aria-haspopup="true"
                      className={classnames(css.Navbar__link, {
                        [css['Navbar__link--active']]:
                          activeItem?.urlSlug === navItem.urlSlug,
                      })}
                      onClick={() => handleClick(navItem)}
                      onMouseEnter={() => handleShowDropdown(navItem)}
                      onMouseLeave={cancelTimeout}
                    >
                      {navItem.title}
                    </button>
                  </Link>
                </li>
              )
            })}
          </ul>
          {activeItem && (
            <div
              onMouseEnter={cancelTimeout}
              onMouseLeave={handleMouseLeaveDropdown}
            >
              <NavbarDropdown navItem={activeItem} onClick={handleClick} />
            </div>
          )}
        </nav>
      </ContentContainer>
    </div>
  )
}
