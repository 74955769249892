import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image, Modal, TextLink } from 'baby-design'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Offer } from 'src/types/offer'
import { RegItem } from 'src/types/regItem'
import useGroupGiftUnredeemedFundsModal from 'src/routes/(registry)/list/[slug]/hooks/useGroupGiftUnredeemedFundsModal'
import { Product } from '../../../RegItemCard.types'
import { EditItemDetailsBuyingOptions } from '../../../../BuyingOptionsWithAddButton'
import AddSecondaryLinkDrawer from '../../../../AddSecondaryLinkDrawer/AddSecondaryLinkDrawer'
import EditSecondaryLinkDrawer from '../../../../EditSecondaryLinkDrawer/EditSecondaryLinkDrawer'

import css from './MobileBuyingSheet.styles.scss'

interface MobileBuyingSheetProps {
  product: Product
  regItem: RegItem
}

const MobileBuyingSheet: FC<MobileBuyingSheetProps> = ({
  product,
  regItem,
}) => {
  const modal = useModal()
  const { openUnredeemedFundsModalWithDelay } =
    useGroupGiftUnredeemedFundsModal(regItem)

  const openAddSecondaryLinkDrawer = async () => {
    modal.hide()
    const response: RegItem | undefined = await NiceModal.show(
      AddSecondaryLinkDrawer,
      { regItem }
    )

    modal.show({ product, regItem: response || regItem })
  }

  const openEditSecondaryLinkDrawer = async (offer: Offer) => {
    modal.hide()
    const response: RegItem | undefined = await NiceModal.show(
      EditSecondaryLinkDrawer,
      {
        secondaryOffer: offer,
        regItem,
      }
    )

    modal.show({ product, regItem: response || regItem })
  }

  const toastOverrideImage = (
    <div className={css.MobileBuyingSheet__atcToastImageContainer}>
      <img
        alt={regItem.title}
        className={css.MobileBuyingSheet__atcToastImage}
        src={regItem.imgUrl}
      />
    </div>
  )

  const handleCartItemUpdateSuccess = (updatedRegItem: RegItem) => {
    modal.show({ product, regItem: updatedRegItem })
  }

  return (
    <Modal
      showCloseButton
      className={css.MobileBuyingSheet__modalRoot}
      handleClose={modal.hide}
      isOpen={modal.visible}
    >
      <div className={css.MobileBuyingSheet}>
        <div className={css.MobileBuyingSheet__productInfo}>
          {product.image && (
            <Image
              alt={product.heading}
              className={css.MobileBuyingSheet__image}
              height={80}
              src={product.image}
              width={80}
            />
          )}
          <div className={css.MobileBuyingSheet__titleAndLink}>
            <h2 className={css.MobileBuyingSheet__title}>{product.heading}</h2>
            <TextLink
              underline
              className={css.MobileBuyingSheet__link}
              href={product.url}
              rel="noreferrer"
              size="xs"
              target="_blank"
              weight="regular"
            >
              View details
            </TextLink>
          </div>
        </div>
        <EditItemDetailsBuyingOptions
          selfContained
          openAddSecondaryLinkDrawer={openAddSecondaryLinkDrawer}
          openEditSecondaryLinkDrawer={openEditSecondaryLinkDrawer}
          regItem={regItem}
          toastProductImageOverride={toastOverrideImage}
          unredeemedFundsCallback={openUnredeemedFundsModalWithDelay}
          onAddToCartSuccess={modal.hide}
          onCartItemUpdateSuccess={handleCartItemUpdateSuccess}
        />
      </div>
    </Modal>
  )
}

export default NiceModal.create(MobileBuyingSheet)
