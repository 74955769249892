import { Category } from 'src/routes/(shop)/api/products'
import { Fragment } from 'react'
import { Item } from '../Item/Item'

interface ExpandedCrumbsProps {
  categoryBreadcrumbs: Category[]
  isMobile?: boolean
  productName: string
  productSlug: string
  genericProductId: string
  productId: string | undefined
}
export const ExpandedCrumbs = (props: ExpandedCrumbsProps) => {
  const {
    categoryBreadcrumbs,
    isMobile,
    productName,
    productSlug,
    genericProductId,
    productId,
  } = props
  return (
    <>
      {categoryBreadcrumbs.map((category: Category, index: number) => {
        const isLast = index === categoryBreadcrumbs.length - 1
        return (
          <Fragment key={`expanded-crumb-${category.title}`}>
            {isMobile && isLast ? (
              <Item
                isLast
                index={index}
                slug={category.urlSlug}
                title={category.title}
              />
            ) : (
              <Item
                index={index}
                slug={category.urlSlug}
                title={category.title}
              />
            )}
          </Fragment>
        )
      })}
      {isMobile ? (
        <Item
          hidden
          genericProductId={genericProductId}
          productId={productId}
          slug={productSlug}
          title={productName}
        />
      ) : (
        <Item
          isLast
          genericProductId={genericProductId}
          productId={productId}
          slug={productSlug}
          title={productName}
        />
      )}
    </>
  )
}
