import React from 'react'
import { PurchaseDetailProps } from './PurchaseDetail.types'
import css from './PurchaseDetail.styles.scss'

const PurchaseDetail: React.FC<PurchaseDetailProps> = ({ label, value }) => (
  <div className={css.PurchaseDetail}>
    <span className={css.PurchaseDetail__label}>{label}</span>
    <span className={css.PurchaseDetail__value}>{value}</span>
  </div>
)

export default PurchaseDetail
