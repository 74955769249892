import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { aboutImpactPath, absoluteUrl } from 'lib/urls'
import AboutImpact from './components/AboutImpact'

const AboutImpactRoute: FC = () => {
  const title = 'Impact | Babylist Inc.'
  const description =
    "Babylist's vision is to support every baby and the people who love them—this means staying committed to inclusivity. Learn more."

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(aboutImpactPath)} rel="canonical" />
      </Helmet>
      <AboutImpact />
    </div>
  )
}

export default AboutImpactRoute
