import { useDispatch } from 'react-redux'
import { openGiftTrackerModal } from 'registry/modals/actions'
import { OnDeleteReservationFunc } from './useGetOnDeleteReservation/useGetOnDeleteReservation'

export const useOpenGiftTrackerModal = (
  onDeleteReservation?: OnDeleteReservationFunc
) => {
  // Because we are using the old modal flows for this, we need to use redux to manage the state and maintain compatibility.
  // Any calls to this function must be made within the context of a redux provider.
  const dispatch = useDispatch()

  return () => dispatch(openGiftTrackerModal(onDeleteReservation))
}
