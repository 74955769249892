import fetch from 'lib/fetch'
import {
  apiV3AboutLeadersPath,
  apiV3AboutCareersPath,
  apiV3AboutPressUpdatesPath,
  apiV3AboutCompanyStatsPath,
  apiV3AboutFeaturedNewsPath,
} from 'lib/urls'

export const FETCH_LEADERS_KEY = 'FETCH_LEADERS'
export const fetchLeaders = async () => {
  const response = await fetch(apiV3AboutLeadersPath, {
    method: 'GET',
  })

  return response.leaders
}

export const FETCH_PRESS_UPDATES_KEY = 'FETCH_PRESS_UPDATES'
export const fetchPressUpdates = async () => {
  const response = await fetch(apiV3AboutPressUpdatesPath, {
    method: 'GET',
  })

  return response.featuredNews
}

export const FETCH_FEATURED_NEWS_KEY = 'FETCH_FEATURED_NEWS'
export const fetchFeaturedNews = async () => {
  const response = await fetch(apiV3AboutFeaturedNewsPath, {
    method: 'GET',
  })

  return response.featuredNews
}

export const FETCH_CAREERS_KEY = 'FETCH_CAREERS'
export const fetchCareers = async () => {
  const response = await fetch(apiV3AboutCareersPath, {
    method: 'GET',
  })

  return response.departments
}

export const FETCH_COMPANY_STATS_KEY = 'FETCH_COMPANY_STATS'
export const fetchCompanyStats = async () => {
  const response = await fetch(apiV3AboutCompanyStatsPath, {
    method: 'GET',
  })

  return response.companyStats
}
