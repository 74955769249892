import { useEffect } from 'react'

interface InjectScriptOptions {
  name: string // Unique name for the script
  code?: string // Script code to inject
  location?: 'head' | 'footer' // Location to inject the script
  removeOnUnmount?: boolean // Remove the script when the component unmounts
  attributes?: { [key: string]: string } // Additional attributes for the script element
  shouldInject?: boolean // Condition for when to inject the script
}

const useInjectScript = ({
  name,
  code,
  location = 'head',
  removeOnUnmount = false,
  attributes = {},
  shouldInject = true,
}: InjectScriptOptions): void => {
  useEffect(() => {
    if (!shouldInject || !name) return undefined

    // Return undefined if a script with the same name already exists
    const existingNamedScript = document.querySelector(
      `script[data-name="${name}"]`
    )
    if (existingNamedScript) return undefined

    // Return undefined if a script with the same src already exists
    if (Object.keys(attributes).includes('src')) {
      const existingSrcScript = document.querySelector(
        `script[src="${attributes.src}"]`
      )
      if (existingSrcScript) return undefined
    }

    const script = document.createElement('script')
    script.setAttribute('data-name', name)
    script.setAttribute('data-testid', 'script-container')
    script.type = 'text/javascript'

    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value)
    })

    if (code) {
      script.textContent = code
    }

    const target = location === 'head' ? document.head : document.body
    target.appendChild(script)

    return () => {
      if (removeOnUnmount) {
        target.removeChild(script)
      }
    }
  }, [name, code, location, removeOnUnmount, attributes, shouldInject])
}

export default useInjectScript
