import React from 'react'
import { useReservationCardContext } from '../../context'
import { CancelReservation, Return, Review, TrackPackage } from './components'
import css from './ReservationActions.styles.scss'

const ReservationActions: React.FC = () => {
  const { isPurchased, regItem, trackingInfos } = useReservationCardContext()

  if (!regItem) return null

  const showCancelReservation = regItem.isActive && !isPurchased
  const showTrackPackage = !!trackingInfos?.length

  return (
    <div className={css.ReservationActions}>
      {showCancelReservation && <CancelReservation />}
      {showTrackPackage && <TrackPackage />}
      {isPurchased && <Return />}
      {isPurchased && <Review sku={regItem.turntoSku} />}
    </div>
  )
}

export default ReservationActions
