import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import fetch from 'lib/fetch'
import { FEED_STORES_DATA_API_URL } from 'registry/constants'
import { track } from 'lib/analytics'
import ShopCell from '../ShopCell/ShopCell'

import css from './BrowseByStores.styles.scss'

import { Store, BrowseByStoresProps } from './BrowseByStores.types'

const BrowseByStores: React.FC<BrowseByStoresProps> = ({ className }) => {
  const maxTileCount = 12
  const BrowseByStoresClasses = classNames(css.BrowseByStores, className)

  const [isFetching, setIsFetching] = useState(true)
  const [stores, setStores] = useState<Store[]>([])

  useEffect(() => {
    fetch(FEED_STORES_DATA_API_URL)
      .then((data) => {
        setIsFetching(false)
        setStores(data.stores || [])
      })
      .catch((error) => {
        console.error(error)
        setIsFetching(false)
      })
  }, [])

  const renderLoadingTiles = () => {
    const loadingTiles = []
    for (let i = 0; i < maxTileCount; i++) {
      loadingTiles.push(
        <div className={css.BrowseByStores__loadingShimmer} key={i} />
      )
    }
    return loadingTiles
  }

  return (
    <div className={BrowseByStoresClasses}>
      <h2>Browse by popular stores</h2>
      <div className={css.BrowseByStores__stores}>
        {isFetching && renderLoadingTiles()}
        {!isFetching &&
          stores.map((store, index) => (
            <div
              className={css.BrowseByStores__store}
              key={index}
              onClick={() => {
                track.storeTileClicked({
                  eventLocation: track.EventLocation.ADD_ITEMS,
                  store: store.name,
                })
              }}
            >
              <ShopCell store={store} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default BrowseByStores
