import { useEffect, useState } from 'react'
import { SectionHeading } from 'components/headings'
import { Col, Row } from 'react-bootstrap'
import { ProgressRing } from 'baby-ui/compounds/ProgressRing'
import { useRegistryChecklistCategories } from '../../api/hooks'
import css from './styles.scss'
import { DismissableChecklistCompletionModal } from '../ChecklistCompletionModal/DismissableChecklistCompletionModal'
import { PdfLink } from './PdfLink/PdfLink'

interface ChecklistOverviewProps {
  registryId: number
}

const percent = (numerator: number, denominator: number) =>
  denominator === 0 ? 0 : Math.round((numerator / denominator) * 100)

export const ChecklistOverview = ({ registryId }: ChecklistOverviewProps) => {
  const { isLoading, isFetching, data } =
    useRegistryChecklistCategories(registryId)
  const [percentEssentialsCompleted, setPercentEssentialsCompleted] =
    useState(0)
  const [percentEverythingCompleted, setPercentEverythingCompleted] =
    useState(0)
  const {
    completedCategoriesCount,
    completedEssentialsCategoryCount,
    totalCategoriesCount,
    totalEssentialsCategoryCount,
  } = data

  useEffect(() => {
    if (!isFetching && data) {
      setPercentEssentialsCompleted(
        percent(completedEssentialsCategoryCount, totalEssentialsCategoryCount)
      )
      setPercentEverythingCompleted(
        percent(completedCategoriesCount, totalCategoriesCount)
      )
    }
  }, [
    data,
    isFetching,
    completedEssentialsCategoryCount,
    completedCategoriesCount,
    totalCategoriesCount,
    totalEssentialsCategoryCount,
  ])

  const [showEverythingCompletionModal, setShowEverythingCompletionModal] =
    useState(false)
  const [showEssentialsCompletionModal, setShowEssentialsCompletionModal] =
    useState(false)

  useEffect(() => {
    if (percentEverythingCompleted === 100) {
      setShowEverythingCompletionModal(true)
    } else if (percentEssentialsCompleted === 100) {
      setShowEssentialsCompletionModal(true)
    }
  }, [completedCategoriesCount])

  return (
    <>
      {showEverythingCompletionModal && (
        <DismissableChecklistCompletionModal
          registryId={registryId}
          type="everything"
          onHide={() => setShowEverythingCompletionModal(false)}
        />
      )}
      {showEssentialsCompletionModal && (
        <DismissableChecklistCompletionModal
          registryId={registryId}
          type="essentials"
          onHide={() => setShowEssentialsCompletionModal(false)}
        />
      )}
      <Row className="mtm text-right visible-xs">
        <PdfLink />
      </Row>
      <Row className="mts-xs mbl-sm">
        <Col md={8} sm={6}>
          <SectionHeading className="mbs mtn text-center-xs text-center-sm">
            Checklist
          </SectionHeading>
          <span className="h6 hidden-xs">
            Add any item from any store to automatically update your checklist!
          </span>
          <div className="hidden-xs mts">
            <PdfLink displayText />
          </div>
        </Col>
        <Col md={4} sm={6}>
          <div className={css.progressContainer}>
            <div>
              {isLoading ? (
                <div className={css.progressRingPlaceholder} />
              ) : (
                <ProgressRing
                  label="Essentials"
                  progress={percentEssentialsCompleted}
                />
              )}
            </div>
            <div className="mlxl">
              {isLoading ? (
                <div className={css.progressRingPlaceholder} />
              ) : (
                <ProgressRing
                  label="Everything"
                  progress={percentEverythingCompleted}
                  variant="secondary"
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}
