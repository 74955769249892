import React, { useState } from 'react'
import classNames from 'classnames'
import { useOnClickOutside, useWindowSize } from 'usehooks-ts'
import { createPortal } from 'react-dom'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Filter } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Chevron } from 'baby-design/icons'
import { BREAKPOINT_TABLET } from 'src/constants'
import ScrollLockModal from 'shared/ScrollLockModal'
import { PopoverModalButtonProps } from './PopoverModalButton.types'
import css from './PopoverModalButton.styles.scss'

const PopoverModalButton: React.FC<PopoverModalButtonProps> = ({
  align = 'left',
  className,
  icon,
  isActive,
  renderContent,
  title,
  mobileTitle,
}: PopoverModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < BREAKPOINT_TABLET

  const popoverModalRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(popoverModalRef, () => {
    if (!isMobile) setIsOpen(false)
  })

  const PopoverModalButtonClasses = classNames(
    css.PopoverModalButton,
    className,
    {
      [css.PopoverModalButton__isOpen]: isOpen,
      [css.PopoverModalButton__alignLeft]: align === 'left',
      [css.PopoverModalButton__alignRight]: align === 'right',
      [css.PopoverModalButton__alignCenter]: align === 'center',
    }
  )
  const closePopover = () => setIsOpen(false)

  return (
    <div
      className={PopoverModalButtonClasses}
      data-testid="PopoverModalButton"
      ref={popoverModalRef}
    >
      <Filter
        LeftIcon={icon}
        RightIcon={<Chevron className={css.PopoverModalButton__Chevron} />}
        isPressed={isOpen || isActive}
        size="lg"
        type="secondary"
        variant="outline"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </Filter>

      {isOpen && !isMobile && (
        <div className={css.PopoverModalButton__Popover}>
          {renderContent(closePopover)}
        </div>
      )}

      {isOpen &&
        isMobile &&
        typeof document !== 'undefined' &&
        createPortal(
          <ScrollLockModal
            showCloseButton
            className={css.PopoverModalButton__Modal}
            handleClose={closePopover}
            isOpen={isOpen}
            mobileVariant="basic"
            title={mobileTitle || title}
          >
            {renderContent(closePopover)}
          </ScrollLockModal>,
          document.body
        )}
    </div>
  )
}

export default PopoverModalButton
