import { Item, Shipment } from 'src/types/shipments'
import { ShipmentStatusBadgeProps } from 'src/routes/(registry)/shipments/components/ShipmentCard/ShipmentCard.types'
import {
  CONSOLIDATION_TYPE_SIMPLE_SHIPPING,
  SHIP_NOW_ACTION,
  STATUS_DELIVERED,
  STATUS_PREPARING,
  STATUS_PROCESSING,
  STATUS_SHIPPED,
} from 'src/routes/(registry)/shipments/util/constants'
import { BadgeFill } from 'baby-design/components/Badge/Badge.types'
import { QuantifiableThumbnailProps } from 'baby-design/components/ThumbnailRow/ThumbnailRow.types'

const statusBadgeColorMap = new Map<string | null | undefined, BadgeFill>([
  [STATUS_PROCESSING, 'gray'],
  [STATUS_PREPARING, 'eggplant'],
  [STATUS_SHIPPED, 'blue'],
  [STATUS_DELIVERED, 'green'],
])

export const shipNowEligible = (shipment: Shipment): boolean =>
  shipment.eligibleActions.some((action) => action.action === SHIP_NOW_ACTION)

export const simpleShippingBundle = (shipment: Shipment): boolean =>
  shipment.consolidationStatus?.type === CONSOLIDATION_TYPE_SIMPLE_SHIPPING

export const currentUserBundle = (shipment: Shipment): boolean =>
  simpleShippingBundle(shipment) && shipment.purchasedForRequestingUser

export const itemsLabel = (shipment: Shipment): string => {
  const itemLabel = shipment.itemsCount === 1 ? 'item' : 'items'
  return `${shipment.itemsCount} ${itemLabel}`
}

export const shipmentCardSubtitle = (shipment: Shipment): string => {
  const recipient = shipment.address.name.split(' ')[0]
  return `${itemsLabel(shipment)} • Shipping to ${recipient}`
}

export const shipmentStatusBadge = (
  shipment: Shipment
): ShipmentStatusBadgeProps | undefined => {
  const state = shipment.shipmentStatus?.currentState
  if (currentUserBundle(shipment) && state === STATUS_PROCESSING) {
    return undefined
  }

  const fill = statusBadgeColorMap.get(state)
  if (!fill) return undefined

  return {
    fill,
    label: shipment.shipmentStatus?.title,
  }
}

export const shipmentDetailsStatusBadge = (
  shipment: Shipment
): ShipmentStatusBadgeProps | undefined => {
  const badge = shipmentStatusBadge(shipment)
  if (!badge) return undefined

  const { fill, label } = badge
  return {
    fill,
    label:
      shipment.shipmentStatus?.currentState === STATUS_DELIVERED
        ? 'Delivered'
        : label,
  }
}

export const itemsAndOrdersLabel = (shipment: Shipment) => {
  let label = itemsLabel(shipment)
  if (shipment.ordersCount > 1) {
    label += ` from ${shipment.ordersCount} orders`
  }
  return label
}

export const shipmentItems = (shipment: Shipment): Item[] =>
  shipment.orders.flatMap((o) => o.items)

export const itemsToThumbnails = (
  items: Item[]
): QuantifiableThumbnailProps[] =>
  items.map((item) => ({
    imageSrc: item.imageUrl,
    imageAlt: item.title,
    quantity: item.quantity,
  }))

export const shipmentItemsToThumbnails = (shipment: Shipment) =>
  itemsToThumbnails(shipmentItems(shipment))

export const shipNowNewShipDate = (shipment: Shipment): string | undefined =>
  shipment.eligibleActions.find((action) => action.action === SHIP_NOW_ACTION)
    ?.additionalData?.estimatedShippingDate

// sorting and filtering shipments
const compareBoolean = (
  s1: Shipment,
  s2: Shipment,
  predicate: (s: Shipment) => boolean
): number => {
  const b1 = predicate(s1)
  const b2 = predicate(s2)
  if (b1 !== b2) {
    return b1 ? -1 : 1
  }
  return 0
}

const compareShipNowEligibility = (s1: Shipment, s2: Shipment): number =>
  compareBoolean(s1, s2, shipNowEligible)

const compareConsolidationStatus = (s1: Shipment, s2: Shipment): number =>
  compareBoolean(s1, s2, simpleShippingBundle)

const sortChronologically = (
  s1: Shipment,
  s2: Shipment,
  dateStringField: (s: Shipment) => string | null | undefined
): number => {
  const s1Date = dateStringField(s1)
  const s2Date = dateStringField(s2)

  if (s1Date === s2Date) return 0
  if (!s1Date) return 1
  if (!s2Date) return -1
  return Date.parse(s1Date) - Date.parse(s2Date)
}

const sortReverseChronologically = (
  s1: Shipment,
  s2: Shipment,
  dateStringField: (s: Shipment) => string | null | undefined
): number => sortChronologically(s2, s1, dateStringField)

export const upcomingShipments = (
  shipments: Shipment[] | undefined
): Shipment[] => {
  const upcoming = (shipments || []).filter(
    (shipment: Shipment) =>
      shipment.shipmentStatus?.currentState === STATUS_PROCESSING ||
      shipment.shipmentStatus?.currentState === STATUS_PREPARING
  )

  return upcoming.sort((s1, s2) => {
    if (compareShipNowEligibility(s1, s2) !== 0) {
      return compareShipNowEligibility(s1, s2)
    }

    if (compareConsolidationStatus(s1, s2) !== 0) {
      return compareConsolidationStatus(s1, s2)
    }

    const estimatedShippingDate = (s: Shipment) =>
      s.shipmentStatus?.estimatedShippingDate

    return sortChronologically(s1, s2, estimatedShippingDate)
  })
}

export const shippedShipments = (
  shipments: Shipment[] | undefined
): Shipment[] => {
  const shipped = (shipments || []).filter(
    (shipment: Shipment) =>
      shipment.shipmentStatus?.currentState === STATUS_SHIPPED
  )

  const estimatedDeliveryDate = (s: Shipment) =>
    s.shipmentStatus?.estimatedDeliveryDate.minDate

  return shipped.sort((s1, s2) =>
    sortChronologically(s1, s2, estimatedDeliveryDate)
  )
}

export const deliveredShipments = (
  shipments: Shipment[] | undefined
): Shipment[] => {
  const delivered = (shipments || []).filter(
    (shipment: Shipment) =>
      shipment.shipmentStatus?.currentState === STATUS_DELIVERED
  )

  const deliveryDate = (s: Shipment) =>
    s.shipmentStatus?.deliveredAt ||
    s.shipmentStatus?.estimatedDeliveryDate.maxDate ||
    s.shipmentStatus?.estimatedDeliveryDate.minDate

  return delivered.sort((s1, s2) =>
    sortReverseChronologically(s1, s2, deliveryDate)
  )
}
