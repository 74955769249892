import React from 'react'
import classNames from 'classnames'

import { track, useTracking } from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { RegItemMinimalCardProps } from './RegItemMinimalCard.types'
import RegItemMinimalCardImage from './components/RegItemMinimalCardImage/RegItemMinimalCardImage'
import RegItemMinimalCardDetails from './components/RegItemMinimalCardDetails/RegItemMinimalCardDetails'
import css from './RegItemMinimalCard.styles.scss'
import { useGetHandleRegItemClick } from './RegItemMinimalCard.utils'
import { useOpenGiftTrackerModal } from '../../hooks/useOpenGiftTrackerModal'
import { useOpenRecoverReservationModal } from '../../hooks/useOpenRecoverReservationModal'
import { useGetOnDeleteReservation } from '../../hooks/useGetOnDeleteReservation/useGetOnDeleteReservation'

const RegItemMinimalCard: React.FC<RegItemMinimalCardProps> = ({
  registry,
  regItem,
  reservation,
  variant = 'Default',
}: RegItemMinimalCardProps) => {
  const [currentUser] = useCurrentUser()
  const handleRegItemClick = useGetHandleRegItemClick(
    regItem,
    registry,
    reservation
  )

  const handleOnDeleteReservation = useGetOnDeleteReservation()
  const handleEditPurchaseClick = useOpenGiftTrackerModal(
    handleOnDeleteReservation
  )
  const handleRecoverReservationClick = useOpenRecoverReservationModal(
    regItem.id
  )
  const tracker = useTracking()
  const buttonCTA = `View buying options for ${regItem.title}`

  const handleClick = () => {
    if (reservation) {
      if (reservation.isVisitorReservation) {
        handleEditPurchaseClick()
      } else {
        handleRecoverReservationClick()
      }
    } else {
      handleRegItemClick()
    }

    tracker.trackEvent({
      event: track.guestViewRegistryItemClicked,
      eventCta: buttonCTA,
      eventType: track.EventType.CLICK,
      ...trackingRegistryProperties({ currentUser, registry }),
      isMustHave: regItem.isFavorite,
      regItemIds: [regItem.id],
    })
  }

  const regItemCardClasses = classNames(css.RegItemMinimalCard, {
    [css.RegItemMinimalCard__Default]: variant === 'Default',
    [css.RegItemMinimalCard__Wide]: variant === 'Wide',
  })

  const renderOverlayButton = () => (
    <button
      aria-label={buttonCTA}
      className={css.RegItemMinimalCard__OverlayButton}
      data-testid="OverlayButton"
      type="button"
      onClick={handleClick}
    />
  )

  return (
    <div className={regItemCardClasses} data-testid="RegItemMinimalCard">
      <div className={css.RegItemMinimalCard__Image}>
        <RegItemMinimalCardImage
          regItem={regItem}
          reservation={reservation}
          variant={variant}
        />
        {renderOverlayButton()}
      </div>
      <div className={css.RegItemMinimalCard__Details}>
        <RegItemMinimalCardDetails
          regItem={regItem}
          registry={registry}
          reservation={reservation}
        />
        {renderOverlayButton()}
      </div>
    </div>
  )
}

export default RegItemMinimalCard
