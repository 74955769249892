import React from 'react'
import { WishlistHeaderLayout } from 'registry/components/GuestRegistryHeader/WishlistHeaderLayout'
import { HolidayWishlistHeaderLayoutProps } from './HolidayWishlistHeaderLayout.types'
import { getCurrentAgeStr } from './HolidayWishlistHeaderLayout.utils'

const HolidayWishlistHeaderLayout: React.FC<
  HolidayWishlistHeaderLayoutProps
> = ({ registry }) => {
  const {
    birthdate = '',
    clothingSize = [],
    shoeSize = [],
    favoriteColor = [],
  } = registry.attributeData || {}

  return (
    <WishlistHeaderLayout
      registry={registry}
      stats={{
        currentAge: getCurrentAgeStr(birthdate),
        clothingSize,
        shoeSize,
        favoriteColor,
      }}
    />
  )
}

export default HolidayWishlistHeaderLayout
