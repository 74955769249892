import PostCardRow from 'hello_baby/components/post-card-row'
import * as blUrls from 'lib/urls'
import ProductContentSection from './product-content-section'

const ProductGuides = ({ guides }) => (
  <ProductContentSection title="Guides">
    <PostCardRow
      className="mtxl"
      columns={3}
      navigateWithRouter={false}
      posts={guides}
    />
  </ProductContentSection>
)

export default ProductGuides
