import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { useDispatch } from 'react-redux'
import { useModal } from '@ebay/nice-modal-react'

import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { Registry } from 'src/types/registry'
import { TrackingMethods, useTracking } from 'lib/analytics'
import { Reservation } from 'src/types/reservation'
import useEZAddToCart, { AddRegItemToCartProps } from 'src/hooks/useEZAddToCart'
import { openAddToCartModal } from 'registry/modals/actions'
import { RegItem } from 'src/types/regItem'
import { showCartItemAddedToast } from 'shared/CartItemAddedToast/showCartItemAddedToast'
import {
  getTrackedCreateReservation,
  useGetAfterSubmitReservation,
} from '../useOpenCreateEditReservationModal'
import { CurrentUser } from '../../../../../../../global'

export interface BabylistOfferClickHandlerProps {
  registry: Registry
  currentUser: CurrentUser
  regItem: RegItem
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

export const useGetBabylistOfferClickHandler = ({
  registry,
  currentUser,
  regItem,
  setIsLoading,
}: BabylistOfferClickHandlerProps) => {
  const { isItemEnabledForEZAddToCart, addRegItemToCart } = useEZAddToCart()
  const dispatch = useDispatch()
  const tracker = useTracking()
  const modal = useModal()
  const afterSubmit = useGetAfterSubmitReservation(regItem.registryId)

  return handleBabylistOfferClick({
    regItem,
    isItemEnabledForEZAddToCart,
    addRegItemToCart,
    tracker,
    dispatch,
    setIsLoading,
    closeModal: () => modal.hide(),
    afterSubmit,
    registry,
    currentUser,
  })
}

interface HandleBabylistOfferClickArgs {
  regItem: RegItem
  isItemEnabledForEZAddToCart: (ri: RegItem) => boolean
  addRegItemToCart: (i: AddRegItemToCartProps) => void
  tracker: TrackingMethods
  dispatch: Dispatch<AnyAction>
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  closeModal: () => void
  afterSubmit?: (newReservation: Reservation) => void
  registry: Registry
  currentUser: CurrentUser
}

const handleBabylistOfferClick =
  ({
    regItem,
    isItemEnabledForEZAddToCart,
    addRegItemToCart,
    tracker,
    dispatch,
    setIsLoading,
    closeModal,
    afterSubmit,
    registry,
    currentUser,
  }: HandleBabylistOfferClickArgs) =>
  () => {
    if (isItemEnabledForEZAddToCart(regItem)) {
      if (setIsLoading) setIsLoading(true)
      addRegItemToCart({
        regItem,
        onSuccess: ({ regItem: ri }) => {
          showCartItemAddedToast({
            product: {
              name: ri.title,
              images: [
                {
                  url: (ri as RegItem).imgUrl,
                  thumbnailUrl: (ri as RegItem).imgUrl,
                },
              ],
            },
            timeout: 3000,
          })
          if (setIsLoading) setIsLoading(false)
          closeModal()
        },
        quantity: 1,
        shouldShowConfirmation: false,
        additionalTrackingProps: trackingRegistryProperties({
          registry,
          currentUser,
        }),
      })
    } else {
      const trackedCreateReservation = getTrackedCreateReservation(
        tracker,
        dispatch,
        afterSubmit
      )
      dispatch(openAddToCartModal(regItem, trackedCreateReservation))
      closeModal()
    }
  }
