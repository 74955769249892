import {
  PDP_TYPE_CASH_FUND,
  PDP_TYPE_FAVOR,
  PDP_TYPE_GIFT_CARD,
  PDP_TYPE_PRODUCT,
} from 'shared/constants'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { CashFund, Favor, GiftCard, Product } from './index'
import { GiftGiver } from './GiftGiver/GiftGiver'
import { getRegistryItemId, getRegistryId } from '../GiftGiverActions'

interface PDPPageProps {
  pdpType?: string
}

const isGiftGiverPDP = (searchParams: URLSearchParams) =>
  !!getRegistryItemId(searchParams) && !!getRegistryId(searchParams)

export const PDPPage = ({ pdpType = PDP_TYPE_PRODUCT }: PDPPageProps) => {
  const [searchParams] = useSearchParams()

  if (isGiftGiverPDP(searchParams)) {
    return <GiftGiver />
  }

  switch (pdpType) {
    case PDP_TYPE_CASH_FUND:
      return <CashFund />
    case PDP_TYPE_FAVOR:
      return <Favor />
    case PDP_TYPE_GIFT_CARD:
      return <GiftCard />
    default:
      return <Product />
  }
}
