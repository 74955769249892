import { FC } from 'react'

import { Reservation } from 'src/types/reservation'
import { GiftGiver } from 'src/routes/(registry)/registry/(gift-tracker-revamp)/context/GiftGiversProvider.types'
import GiftDateAndCount from './GiftDateAndCount'
import GiftMessage from './GiftMessage'
import GiftProduct from './GiftProduct'
import GiftGiverInfo from './GiftGiverInfo'
import css from './GiftTrackerListItem.styles.scss'

const GiftProducts: FC<{ reservations: Reservation[] }> = ({
  reservations,
}) => (
  <div
    className={css.GiftTrackerListItem__GiftProducts}
    data-testid="GiftProducts"
  >
    {reservations.map((reservation) => (
      <GiftProduct key={reservation.id} reservation={reservation} />
    ))}
  </div>
)

const GiftTrackerListItem: FC<{ giftGiver: GiftGiver }> = ({ giftGiver }) => (
  <div className={css.GiftTrackerListItem}>
    <GiftGiverInfo giftGiver={giftGiver} />
    <GiftDateAndCount giftGiver={giftGiver} />
    <GiftMessage giftMessage={giftGiver.giftMessage} />
    <GiftProducts reservations={giftGiver.reservations} />
  </div>
)

export default GiftTrackerListItem
