const ARIA_LIVE_CLEAR_TIMEOUT = 5000
const ARIA_LIVE_ELEMENT_ID = 'babylist-aria-live'
let ariaLiveClearTimeoutId: number | null = null

type AriaLiveType = 'off' | 'polite' | 'assertive'

const createAriaLiveElement = () => {
  const newAriaLiveElement = document.createElement('div')
  newAriaLiveElement.id = ARIA_LIVE_ELEMENT_ID
  newAriaLiveElement.setAttribute('aria-live', 'off')
  newAriaLiveElement.setAttribute('aria-atomic', 'true')
  newAriaLiveElement.classList.add('visually-hidden')

  document.body.prepend(newAriaLiveElement)

  return newAriaLiveElement
}

export const initializeAriaLiveElement = () => {
  const ariaLiveElement = document.getElementById(ARIA_LIVE_ELEMENT_ID)
  if (ariaLiveElement) return ariaLiveElement

  return createAriaLiveElement()
}

export const screenReaderAnnounce = (
  message: string,
  type: AriaLiveType = 'polite',
  clearMessageTimeout: number = ARIA_LIVE_CLEAR_TIMEOUT
) => {
  if (typeof window === 'undefined') return

  const ariaLiveElement = initializeAriaLiveElement()

  ariaLiveElement.setAttribute('aria-live', type)
  ariaLiveElement.textContent = message

  if (ariaLiveClearTimeoutId) clearTimeout(ariaLiveClearTimeoutId)
  ariaLiveClearTimeoutId = window.setTimeout(() => {
    if (ariaLiveElement) ariaLiveElement.textContent = ''
  }, clearMessageTimeout)
}

if (typeof window !== 'undefined') {
  initializeAriaLiveElement()
}
