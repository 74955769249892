import {
  faqPath,
  freightAndDeliveryHelpPath,
  returnPolicyPath,
  furnitureShippingSurchargeGuidePath,
} from 'lib/urls'

// freeShipping and extendedDeliveryTime messages are set on the backend in the product presenter
export const MerchandisingMessageConfig = {
  freeShipping: {
    tooltipContent: (
      <>
        <div>
          We gladly accept returns on items purchased directly from the Babylist
          Shop within 9 months of purchase date. Returned items must be in the
          same condition received, unopened and in the original packaging.
        </div>
        <br />
        <div>
          For more details, view our{' '}
          <a href={returnPolicyPath}>full return policy.</a>
        </div>
      </>
    ),
    type: 'free-shipping',
  },
  deliverSurcharge: {
    tooltipContent: (
      <div>
        Select items include a shipping surcharge due to their size and weight.
        To learn more about these fees,{' '}
        <a href={furnitureShippingSurchargeGuidePath}>read our guide</a>.
        Exclusions apply.
      </div>
    ),
    type: 'deliver-surcharge',
  },
  extendedDeliveryTime: {
    tooltipContent: (
      <div>
        Orders that ship directly from a vendor can take additional time to
        process. Tracking information on these orders can take two to three more
        business days to update. To learn more about our shipping time frames
        visit our <a href={freightAndDeliveryHelpPath}>help center</a>.
      </div>
    ),
    type: 'extended-delivery',
  },
  virtualBundle: {
    message: 'Items in this order may ship separately.',
    tooltipContent: (
      <>
        <div>Split shipments</div>
        <br />
        <div>
          We do our best to ship all of your items together, however select
          bundles require separate shipments. If that happens, you'll get a
          separate tracking number for every package. To learn more about
          tracking your shipments, visit our <a href={faqPath}>help center</a>.
        </div>
      </>
    ),
    type: 'virtual-bundle',
  },
}
