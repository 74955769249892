import React, { useState } from 'react'
import SimpleShippingGiftImage from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalSuccess/components/SimpleShippingGiftImage/SimpleShippingGiftImage.svg'
import Confetti from 'react-confetti'
import css from './ShipNowModalSuccess.styles.scss'

const ShipNowModalSuccess: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(true)

  return (
    <>
      {showConfetti && (
        <Confetti
          className={css.ShipNowModalSuccess__Confetti}
          numberOfPieces={200}
          recycle={false}
          onConfettiComplete={() => {
            setShowConfetti(false)
          }}
        />
      )}
      <div className={css.ShipNowModalSuccess}>
        <SimpleShippingGiftImage className={css.ShipNowModalSuccess__Image} />
        <div className={css.ShipNowModalSuccess__TextContent}>
          <h1 className={css.ShipNowModalSuccess__Title}>We're on it!</h1>
          <p className={css.ShipNowModalSuccess__Subtitle}>
            We'll start preparing your items and you will see them ship soon.
          </p>
        </div>
      </div>
    </>
  )
}

export default ShipNowModalSuccess
