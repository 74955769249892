import React from 'react'
import { Button } from 'baby-design'
import { SHOW_UNRESERVE_MODAL } from 'shared/constants'
import { useReservationCardContext } from '../../../../context'
import css from '../ActionButton.styles.scss'

const CancelReservation: React.FC = () => {
  const reservation = useReservationCardContext()

  const handleOpenUnreserveModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    PubSub.publish(SHOW_UNRESERVE_MODAL, {
      reservation,
    })
  }

  return (
    <>
      <Button
        block
        className={css['ActionButton--desktop']}
        size="sm"
        variant="tertiary"
        onClick={handleOpenUnreserveModal}
      >
        Cancel reservation
      </Button>
      <Button
        block
        className={css['ActionButton--mobile']}
        size="md"
        variant="tertiary"
        onClick={handleOpenUnreserveModal}
      >
        Cancel reservation
      </Button>
    </>
  )
}

export default CancelReservation
