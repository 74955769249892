import React from 'react'
import { registryAddItemsPath } from 'lib/urls'
import { REGISTRY_ADD_ITEM_SCREEN_VIEW_EVENT } from 'registry/constants'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Button } from '../../../../../../../../../baby-design'
import { Plus } from '../../../../../../../../../baby-design/icons'

const AddItemsButton: React.FC = () => {
  const navigate = useNavigate()

  const handleClickAddItem = () => {
    navigate(
      `${registryAddItemsPath}?entry_point=${REGISTRY_ADD_ITEM_SCREEN_VIEW_EVENT}`
    )
  }

  return (
    <Button
      icon={{ side: 'left', icon: <Plus /> }}
      size="md"
      variant="primary"
      onClick={handleClickAddItem}
    >
      Add item
    </Button>
  )
}

export default AddItemsButton
