import React from 'react'
import classNames from 'classnames'
import { Button } from 'baby-design'
import { Link } from 'baby-design/icons'
import { registryExternalPath } from 'lib/urls'
import { track } from 'lib/analytics'

import css from './TransferItems.styles.scss'

import { TransferItemsProps } from './TransferItems.types'

const TransferItems: React.FC<TransferItemsProps> = ({ className }) => {
  const TransferItemsClasses = classNames(css.TransferItems, className)

  const openRegistryExternal = () => {
    track.addItemsScreenClicked({
      eventLocation: track.EventLocation.ADD_ITEMS,
      eventCta: 'Transfer',
    })
    window.open(registryExternalPath, '_blank')
  }

  return (
    <div className={TransferItemsClasses}>
      <div className={css.TransferItems__icon}>
        <Link />
      </div>
      <div className={css.TransferItems__content}>
        <h2>Have an existing baby registry?</h2>
        <p>
          Transfer items or link another registry to conveniently manage
          everything you need in one place.
        </p>
      </div>
      <Button
        className={css.ctaButton}
        size="md"
        variant="tertiary"
        onClick={openRegistryExternal}
      >
        Transfer&nbsp;items
      </Button>
    </div>
  )
}

export default TransferItems
