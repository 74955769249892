import React from 'react'

import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { RegItemBuyingOptionsProps } from '../../RegItemBuyingOptionsModal.types'
import css from './ProductBuyingOptions.styles.scss'
import RegItemOffer from '../../../RegItemOffer/RegItemOffer'
import RegItemMarkPurchasedCheckbox from '../../../RegItemMarkPurchasedCheckbox/RegItemMarkPurchasedCheckbox'
import { sortProductOffersByPrice } from '../../RegItemBuyingOptionsModal.utils'

const ProductBuyingOptions: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
  registry,
}) => {
  const sortedOffers = sortProductOffersByPrice(regItem.offers)
  const [currentUser] = useCurrentUser()

  return (
    <>
      <div
        className={css.ProductBuyingOptions}
        data-testid="ProductBuyingOptions"
      >
        <div className={css.ProductBuyingOptions__Offers}>
          {sortedOffers.map((offer) => (
            <RegItemOffer
              currentUser={currentUser}
              key={`${offer.storeName}-${offer.id}`}
              offer={offer}
              regItem={regItem}
              registry={registry}
            />
          ))}
        </div>
      </div>
      <div className={css.ProductBuyingOptions__MarkPurchased}>
        <RegItemMarkPurchasedCheckbox regItem={regItem} registry={registry} />
      </div>
    </>
  )
}

export default ProductBuyingOptions
