import React from 'react'
import Card from 'components/card/card'
import { ReservationCardProvider } from './context'
import {
  ConsolidatedShippingCallout,
  GiftDetails,
  PurchaseDetails,
  RegItemImage,
  ReservationActions,
} from './components'
import { ReservationCardContextProps } from './ReservationCard.types'
import css from './ReservationCard.styles.scss'

const ReservationCard: React.FC<ReservationCardContextProps> = ({
  reservation,
}) => (
  <ReservationCardProvider value={reservation}>
    <Card className={css.ReservationCard}>
      <div className={css.ReservationCard__giftInfo}>
        <RegItemImage />
        <GiftDetails />
      </div>
      {reservation.consolidationMessage && (
        <ConsolidatedShippingCallout variant="mobile" />
      )}
      <div className={css.ReservationCard__purchaseDetailsAndActions}>
        <PurchaseDetails />
        <ReservationActions />
      </div>
    </Card>
  </ReservationCardProvider>
)

export default ReservationCard
