import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Toast from 'baby-design/components/Toast/Toast'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CheckCircle } from 'baby-design/icons'
import { ReminderCreatedToastProps } from './ReminderCreatedToast.types'
import css from './ReminderCreatedToast.styles.scss'

const ReminderCreatedToast: React.FC<ReminderCreatedToastProps> = ({
  isOpen,
}) => (
  <Toast
    imageProps={{
      image: <CheckCircle filled className={css.ReminderCreatedToast__Image} />,
    }}
    isOpen={isOpen}
    text="We'll send you a reminder to purchase a gift for this registry."
    variant="basic"
  />
)

export default ReminderCreatedToast
