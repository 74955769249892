import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import { RegItemBuyingOptionsProps } from '../../RegItemBuyingOptionsModal.types'
import { useGetHandleContributeClick } from './CashFundBuyingOptions.utils'
import css from './CashFundBuyingOptions.styles.scss'

const CashFundBuyingOptions: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
}) => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < BREAKPOINT_TABLET
  const handleContributeClick = useGetHandleContributeClick(regItem)

  return (
    <div
      className={css.CashFundBuyingOptions}
      data-testid="CashFundBuyingOptions"
    >
      <Button
        block
        data-testid="ContributeButton"
        size={isMobile ? 'md' : 'lg'}
        variant="primary"
        onClick={handleContributeClick}
      >
        Contribute
      </Button>
    </div>
  )
}

export default CashFundBuyingOptions
