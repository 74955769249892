import React from 'react'
// eslint-disable-next-line
import { Button, Meter } from 'baby-design'
import { COMPONENT_NAME } from './GroupGiftGiftingOptions.constants'
import {
  useGetHandleContributeClick,
  meterPropsFrom,
} from './GroupGiftGiftingOptions.utils'
import BuyItOrMarkAsPurchased from './BuyItOrMarkAsPurchased'
import { RegItemBuyingOptionsProps } from '../../RegItemBuyingOptionsModal.types'
import css from './GroupGiftGiftingOptions.styles.scss'

const GroupGiftGiftingOptions: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
  registry,
}) => {
  const meterProps = meterPropsFrom(regItem)
  const handleContributeClicked = useGetHandleContributeClick(regItem)

  return (
    <div className={css[COMPONENT_NAME]} data-testid={COMPONENT_NAME}>
      <div className={css[`${COMPONENT_NAME}__contribute`]}>
        <Meter
          fillClass={css[`${COMPONENT_NAME}__contribute__meterFill`]}
          {...meterProps}
        />
        <Button
          className={css[`${COMPONENT_NAME}__contribute__button`]}
          size="sm"
          variant="primary"
          onClick={handleContributeClicked}
        >
          Contribute
        </Button>
      </div>
      <BuyItOrMarkAsPurchased regItem={regItem} registry={registry} />
    </div>
  )
}

export default GroupGiftGiftingOptions
