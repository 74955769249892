import { Component } from 'react'
import PropTypes from 'prop-types'
import ToggleCollapseAllCategoriesLink from './toggle-collapse-all-categories-link'

export default class GuestViewLinks extends Component {
  static propTypes = {
    openMobileFiltersModal: PropTypes.func.isRequired,
  }

  state = {
    allCollapsed: false,
  }

  render() {
    return (
      <div className="flex row flex-grow justify-end">
        <ToggleCollapseAllCategoriesLink />
      </div>
    )
  }
}
