import React from 'react'
import classNames from 'classnames'
import { helloBabyPostPath } from 'lib/urls'
import { TextLink } from 'baby-design'

import { track } from 'lib/analytics'
import css from './Topic.styles.scss'

import { TopicProps, Post } from './Topic.types'

const Topic: React.FC<TopicProps> = ({ className, topic }) => {
  const TopicClasses = classNames(css.Topic, className)

  const handleTopicClick = (post: Post) => {
    track.addItemsScreenClicked({
      eventLocation: track.EventLocation.ADD_ITEMS,
      eventCta: 'Guide',
      postId: post.id,
      postType: post.type,
      postSlug: post.slug,
      postRevision: post.publishedVersion,
    })
    window.open(helloBabyPostPath(post.slug), '_blank')
  }

  return (
    <div className={TopicClasses}>
      <div className={css.Topic__icon}>
        <img alt={topic.name} src={topic.imageUrl} />
      </div>
      <h1 className={classNames(css.Topic__title)}>{topic.name}</h1>
      <ul className={css.Topic__list}>
        {topic.guides.map((post: any, index: number) => (
          <li key={index}>
            <TextLink
              target="_blank"
              weight="regular"
              onClick={() => handleTopicClick(post)}
            >
              {post.title}
            </TextLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Topic
