import React from 'react'
import { Button } from 'baby-design'
import { Note } from 'baby-design/icons'
import {
  TurnToSnippet,
  updateSku,
} from 'store/components/product-reviews/turnto-snippet'
import { ReviewProps } from './Review.types'
import css from '../ActionButton.styles.scss'

const Review: React.FC<ReviewProps> = ({ sku }) => {
  if (!sku) return null

  const handleClick = () => {
    updateSku(sku)
    window.TurnToCmd('reviewsList.writeReview')
  }

  return (
    <>
      <TurnToSnippet pageId="gift-tracker-review-submission" sku="" />
      <Button
        block
        className={css['ActionButton--desktop']}
        icon={{ side: 'left', icon: <Note /> }}
        size="sm"
        variant="primary"
        onClick={handleClick}
      >
        Write a review
      </Button>
      <Button
        block
        className={css['ActionButton--mobile']}
        icon={{ side: 'left', icon: <Note /> }}
        size="md"
        variant="primary"
        onClick={handleClick}
      >
        Write a review
      </Button>
    </>
  )
}

export default Review
