import { useParams } from 'react-router-dom-v5-compat'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { adminProductPath } from 'src/routes/(shop)/lib/productUtils'

export const AdminLink = () => {
  const [currentUser] = useCurrentUser()
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!currentUser?.admin || !genericProduct) return null

  return (
    <div>
      <hr className="mvm" />
      <a
        className="small"
        href={adminProductPath({
          genericProductId: genericProduct.id?.toString() || '',
          activeProductId: genericProduct.activeProductId?.toString() || '',
        })}
        rel="noreferrer"
        target="_blank"
      >
        Admin Page
      </a>
      <hr className="mvm" />
    </div>
  )
}
