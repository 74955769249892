import React from 'react'
import ConsolidatedShippingCallout from '../ConsolidatedShippingCallout'
import { RegItemRemovedMessage, SurpriseMessage } from './components'
import { useReservationCardContext } from '../../context'
import css from './GiftDetails.styles.scss'

const GiftDetails: React.FC = () => {
  const { consolidationMessage, displayTitle, isSurprise, regItem } =
    useReservationCardContext()
  const showRegItemRemovedMessage = regItem && !regItem.isActive

  return (
    <div className={css.GiftDetails}>
      <div className={css.GiftDetails__title}>{displayTitle}</div>
      <div className={css.GiftDetails__messages}>
        {showRegItemRemovedMessage && <RegItemRemovedMessage />}
        {isSurprise && <SurpriseMessage />}
        {consolidationMessage && (
          <ConsolidatedShippingCallout variant="desktop" />
        )}
      </div>
    </div>
  )
}

export default GiftDetails
