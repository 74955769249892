export interface RouteConfig {
  path: string
  element: React.ReactElement
}

export type SubdomainContext = {
  routes: __WebpackModuleApi.RequireContext
  layouts: __WebpackModuleApi.RequireContext
}

export type SubdomainContexts = {
  [key: string]: SubdomainContext
}

export const DEFAULT_SUBDOMAIN = 'www'

export const generateDynamicPath = (path: string): string => {
  // Replace empty string with base slash
  if (path === '') return '/'

  return path
    .replace('./', '/') // Remove leading dot slash
    .replace(/\(.*?\)/g, '') // Ignore folders name within parentheses
    .replace('.tsx', '') // Remove .tsx file extension
    .replace(/\[(.*?)\]/g, ':$1') // Convert brackets into route parameters
    .replace(/\/+/g, '/') // Replace multiple slashes with a single slash
    .replace('/Route', '') // Remove /Route
    .replace('/Layout', '') // Remove /Layout
}

export const generateRoutes = (
  context: __WebpackModuleApi.RequireContext
): RouteConfig[] =>
  context.keys().map((key: string) => {
    const routePath = generateDynamicPath(key)
    const Component = context(key).default
    return { path: routePath, element: <Component /> }
  })

export const groupRoutesByLayout = (
  routesConfig: RouteConfig[],
  layoutsConfig: RouteConfig[]
) => {
  // Sort layoutConfig by entry.path where entries with the most path parts come first
  layoutsConfig.sort(
    (a, b) => b.path.split('/').length - a.path.split('/').length
  )

  // Group routesConfig entries by layout path
  const assignedRoutes = new Set()
  const groupedRoutes = layoutsConfig.map((layout) => {
    const layoutPath = layout.path
    const routes = routesConfig.filter((route) => {
      if (
        route.path.startsWith(layoutPath) &&
        !assignedRoutes.has(route.path)
      ) {
        assignedRoutes.add(route.path)
        return true
      }
      return false
    })
    return { layout, routes }
  })

  const ungroupedRoutes = routesConfig.filter(
    (route) => !assignedRoutes.has(route.path)
  )

  return { groupedRoutes, ungroupedRoutes }
}

export const getSubdomainKey = (
  subdomain: string,
  subdomainContexts: SubdomainContexts
): string => {
  const subdomainKeys = Object.keys(subdomainContexts)

  // if the subdomain is a key in the subdomainContexts object, return it
  if (subdomainKeys.includes(subdomain)) return subdomain

  // if the subdomain starts with they key, return the matching key-
  // this is for staging subdomains like `my-staging6`
  const matchingKeyPrefix = subdomainKeys.find((key) =>
    subdomain.startsWith(key)
  )
  if (matchingKeyPrefix) return matchingKeyPrefix

  // If no key is found return the default subdomain. This accounts for any
  // un-prefixed staging subdomains such as `staging6`
  return DEFAULT_SUBDOMAIN
}
