import { FC } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { IconButton } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RightChevron } from 'baby-design/icons'

import { Reservation } from 'src/types/reservation'
import css from './GiftProduct.styles.scss'
import ShipmentStatusBadge from './ShipmentStatusBadge'
import { showGiftDetailsDrawer } from '../../../GiftDetailsDrawer'
import ImageAndQuantity from '../../../ImageAndQuantity'

const NameAndDeliveryBadge: FC<{ reservation: Reservation }> = ({
  reservation,
}) => (
  <div className={css.GiftProduct__NameAndDeliveryBadge}>
    <span>{reservation.displayTitle}</span>
    <ShipmentStatusBadge
      {...{ shipmentStatusSummary: reservation.shipmentStatusSummary }}
    />
  </div>
)

const MoreDetailsButton: FC<{ reservation: Reservation }> = ({
  reservation,
}) => (
  <div className={css.GiftProduct__MoreDetailsButton}>
    <IconButton
      size="md"
      variant="standard-default"
      onClick={() => showGiftDetailsDrawer({ reservation })}
    >
      <RightChevron />
    </IconButton>
  </div>
)

const GiftProduct: FC<{ reservation: Reservation }> = ({ reservation }) => (
  <div className={css.GiftProduct}>
    <ImageAndQuantity {...{ reservation }} />
    <NameAndDeliveryBadge {...{ reservation }} />
    <MoreDetailsButton {...{ reservation }} />
  </div>
)

export default GiftProduct
