import { useState, useEffect } from 'react'
import { RegItemMinimal } from 'src/types/regItem'
import { Registry } from 'src/types/registry'
import {
  getSearchId,
  REGISTRY_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import { track, useTracking } from 'lib/analytics'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import useOnCurrentUserLoaded from 'shared/hooks/useOnCurrentUserLoaded/useOnCurrentUserLoaded'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { GIFT_GIVER_PAGE_VISIT_LOCAL_STORAGE_KEY } from './RegistryGiftGiverPage'
import { AppliedSortsAndFiltersContextProvider } from '../../contexts/AppliedSortsAndFiltersContext'
import { AppliedSortsAndFilters } from './RegistryGiftGiverPage.types'
import { getPricingData } from '../RegItemPricingLockup/RegItemPricingLockup.utils'

export const defaultAppliedSortsAndFilters: AppliedSortsAndFilters = {
  filters: {
    category: 'All',
    price: undefined,
    stores: undefined,
  },
  sort: 'category',
}

export const mergeSortsAndFilters = (
  changes: Partial<AppliedSortsAndFilters>,
  currentSortsAndFilters: AppliedSortsAndFilters
) => {
  const { filters } = changes
  const { filters: prevFilters, sort: prevSort } = currentSortsAndFilters

  return {
    filters: {
      category: applyChangeIfPresent(filters, 'category', prevFilters.category),
      price: applyChangeIfPresent(filters, 'price', prevFilters.price),
      stores: applyChangeIfPresent(filters, 'stores', prevFilters.stores),
    },
    sort: applyChangeIfPresent(changes, 'sort', prevSort),
  }
}

const applyChangeIfPresent = (
  changes: Record<string, any> | undefined,
  property: string,
  fallback: any
) => {
  if (!changes) return fallback

  return Object.prototype.hasOwnProperty.call(changes, property)
    ? changes[property]
    : fallback
}

export const sortAndFilterRegItems = (
  regItems: RegItemMinimal[],
  appliedSortsAndFilters: AppliedSortsAndFilters
) => {
  const { filters, sort } = appliedSortsAndFilters

  return sortRegItems(filterRegItems(regItems, filters), sort)
}

export const filterRegItems = (
  regItems: RegItemMinimal[],
  filters: AppliedSortsAndFilters['filters']
) => {
  let filteredRegItems = [...regItems]

  if (
    filters.category !== undefined &&
    filters.category !== defaultAppliedSortsAndFilters.filters.category
  ) {
    filteredRegItems = regItems.filter((regItem) => {
      if (Number.isInteger(filters.category)) {
        return regItem.categoryId === filters.category
      }

      if (filters.category === 'Most wanted') {
        return regItem.isFavorite
      }

      // Always return false when purchased is selected. It will be populated
      // by the reservations section so we only need to hide all un-purchased reg items.
      if (filters.category === 'Purchased') {
        return false
      }

      return true
    })
  }

  if (filters.price) {
    filteredRegItems = filteredRegItems.filter((regItem) => {
      const price = getRegItemPriceForComparison(regItem)

      return (
        filters.price &&
        price >= filters.price.min &&
        price <= filters.price.max
      )
    })
  }

  if (filters.stores && filters.stores.length > 0) {
    filteredRegItems = filteredRegItems.filter((regItem) => {
      if (!regItem.offers) return false

      return regItem.offers.some(
        (offer) =>
          filters.stores && filters.stores.includes(offer.storeDisplayName)
      )
    })
  }

  return filteredRegItems
}

const sortRegItems = (regItems: RegItemMinimal[], sort: string | undefined) => {
  switch (sort) {
    case 'priceLowToHigh':
      return regItems.sort((a, b) => {
        const aPrice = getRegItemPriceForComparison(a)
        const bPrice = getRegItemPriceForComparison(b)

        return aPrice - bPrice
      })
    case 'priceHighToLow':
      return regItems.sort((a, b) => {
        const aPrice = getRegItemPriceForComparison(a)
        const bPrice = getRegItemPriceForComparison(b)

        return bPrice - aPrice
      })
    default:
      return regItems.sort((a, b) => b.position - a.position)
  }
}

const getRegItemPriceForComparison = (regItem: RegItemMinimal) => {
  let price: number

  if (regItem.priceDetails) {
    const pricingData = getPricingData(regItem)
    price = pricingData.price
  } else {
    price = Number(regItem.price)
  }

  return price || 0
}

const previewFilterResults = (
  regItems: RegItemMinimal[],
  changedSortsAndFilters: Partial<AppliedSortsAndFilters>,
  appliedSortsAndFilters: AppliedSortsAndFilters
) => {
  const mergedPreviewSortAndFilters = mergeSortsAndFilters(
    changedSortsAndFilters,
    appliedSortsAndFilters
  )

  const filteredRegItems = filterRegItems(
    regItems,
    mergedPreviewSortAndFilters.filters
  )

  return { count: filteredRegItems.length }
}

export const getPreviewFilterResultsClosure =
  (regItems: RegItemMinimal[]) =>
  (
    changedSortsAndFilters: Partial<AppliedSortsAndFilters>,
    appliedSortsAndFilters: AppliedSortsAndFilters
  ) =>
    previewFilterResults(
      regItems,
      changedSortsAndFilters,
      appliedSortsAndFilters
    )

export const withProviders = (Component: React.FC) => () => (
  <AppliedSortsAndFiltersContextProvider>
    <Component />
  </AppliedSortsAndFiltersContextProvider>
)

export const useSetLocalStoragePageVisitCount = (
  isToggleEnabled: boolean | null,
  isRegistryOwner: boolean
) => {
  const [pageVisitCount, setPageVisitCount] = useState<number>(() =>
    typeof window !== 'undefined'
      ? parseInt(
          localStorage.getItem(GIFT_GIVER_PAGE_VISIT_LOCAL_STORAGE_KEY) || '0'
        )
      : 0
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && isToggleEnabled && isRegistryOwner) {
      setPageVisitCount((prevCount) => {
        const newCount = prevCount + 1
        localStorage.setItem(
          GIFT_GIVER_PAGE_VISIT_LOCAL_STORAGE_KEY,
          newCount.toString()
        )
        return newCount
      })
    }
  }, [isToggleEnabled, isRegistryOwner])

  return pageVisitCount
}

export const useTrackRegistryViewed = (registry: Registry) => {
  const tracker = useTracking()
  const searchId = getSearchId(REGISTRY_SEARCH_INDEX_KEY) || undefined
  const searchPreviewIds = getSearchPreviewIds()
  const currentUrl =
    typeof window !== 'undefined' ? window.location.href : undefined
  useOnCurrentUserLoaded((currentUser) => {
    if (registry.ownerId !== currentUser?.id) {
      tracker.trackEvent({
        event: track.registryViewed,
        eventUrl: currentUrl,
        searchId,
        searchPreviewIds,
        ...trackingRegistryProperties({ registry, currentUser }),
      })
    }
  })
}
