// eligible action names
export const SHIP_NOW_ACTION = 'SHIP_NOW'

// shipment statuses
export const STATUS_PROCESSING = 'processing'
export const STATUS_PREPARING = 'preparing'
export const STATUS_SHIPPED = 'shipped'
export const STATUS_DELIVERED = 'delivered'
export const STATUS_CANCELLED = 'cancelled'
export const STATUS_UNKNOWN = 'unknown'

// consolidation types
export const CONSOLIDATION_TYPE_DEFAULT = 'DEFAULT'
export const CONSOLIDATION_TYPE_SIMPLE_SHIPPING = 'SIMPLE_SHIPPING'

// notice types
export const NOTICE_TYPE_MULTI_ORDER_SHIPMENT = 'multi_order_shipment'
export const NOTICE_TYPE_MULTI_ORDER_BUNDLE = 'multi_order_bundle'
export const NOTICE_TYPE_CURRENT_USER_BUNDLE =
  'current_user_simple_shipping_bundle'
export const NOTICE_TYPE_OTHER_USER_BUNDLE = 'other_user_simple_shipping_bundle'
