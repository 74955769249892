import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import { RegistryCategoriesProps } from './RegistryCategories.types'
import css from './RegistryCategories.styles.scss'
import useScrollToElement from '../../hooks/useScrollToElement/useScrollToElement'
import { useAppliedSortsAndFiltersContext } from '../../contexts'
import { defaultAppliedSortsAndFilters } from '../RegistryGiftGiverPage/RegistryGiftGiverPage.utils'
import {
  getCategorizedRegItems,
  mergeVisitorReservationsWithReservations,
  MOST_WANTED_TITLE,
  useGetReduxSyncClosure,
} from './RegistryCategories.utils'
import RegistryCategoriesEmptyState from './components/RegistryCategoriesEmptyState/RegistryCategoriesEmptyState'
import RegItemMinimalCard from '../RegItemMinimalCard/RegItemMinimalCard'
import RegistryCategoriesSkeleton from './RegistryCategories.skeleton'

const RegistryCategories: React.FC<RegistryCategoriesProps> = ({
  glowupEnabled,
  isLoadingComplete,
  registry,
  regItems,
  reservations,
  visitorReservations,
}: RegistryCategoriesProps) => {
  const purchasedItemsRef = useRef<HTMLDivElement>(null)
  const { appliedSortsAndFilters } = useAppliedSortsAndFiltersContext()

  useScrollToElement({
    isEnabled: isLoadingComplete,
    ref: purchasedItemsRef,
    queryParam: { param: 'showGiftTracker', value: 'true' },
  })

  const mergedReservations = mergeVisitorReservationsWithReservations(
    reservations,
    visitorReservations
  )

  const setReduxReservationsAndRegItemsClosure = useGetReduxSyncClosure()

  useEffect(() => {
    if (isLoadingComplete) {
      setReduxReservationsAndRegItemsClosure(mergedReservations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingComplete])

  if (!isLoadingComplete) return <RegistryCategoriesSkeleton />

  const areResultsCategorized =
    appliedSortsAndFilters.sort === defaultAppliedSortsAndFilters.sort

  const isPurchasedCategoryVisible =
    reservations.length > 0 &&
    (appliedSortsAndFilters.filters?.category === 'Purchased' ||
      appliedSortsAndFilters.filters?.category === 'All')

  const isMostWantedSectionVisible =
    appliedSortsAndFilters.filters?.category === 'All'

  const regItemCategoryGroups = getCategorizedRegItems({
    categories: registry.categories,
    regItems,
    appliedSortsAndFilters,
  })

  const registryCategoryClasses = classNames(css.RegistryCategories, {
    [css.glowupEnabled]: glowupEnabled,
  })

  return (
    <div className={registryCategoryClasses} data-testid="RegistryCategories">
      <RegistryCategoriesEmptyState
        regItems={regItems}
        reservations={mergedReservations}
      />

      {!areResultsCategorized && (
        <div
          className={css.RegistryCategories__UncategorizedList}
          data-testid="UncategorizedList"
        >
          <div className={css.RegistryCategories__Grid}>
            {regItems.map((regItem) => (
              <RegItemMinimalCard
                key={`reg-item-uncategorized-${regItem.id}`}
                regItem={regItem}
                registry={registry}
              />
            ))}
          </div>
        </div>
      )}

      {areResultsCategorized && (
        <div
          className={css.RegistryCategories__CategorizedList}
          data-testid="CategorizedList"
        >
          {regItemCategoryGroups.map(({ category, items }, index) => (
            <div
              className={css.RegistryCategories__Category}
              data-testid={`Category-${category.title}`}
              key={`category-${category.id}-${category.title}`}
            >
              <h2
                className={classNames(css.RegistryCategories__CategoryTitle, {
                  [css[`RegistryCategories__CategoryTitle--First`]]:
                    index === 0,
                })}
                data-testid={`CategoryTitle-${category.title}`}
              >
                {`${category.title} `}
                <span
                  aria-hidden="true"
                  className={css.RegistryCategories__CategoryItemCount}
                >
                  ({items.length})
                </span>
              </h2>
              <div
                className={classNames(css.RegistryCategories__Grid, {
                  [css.RegistryCategories__Grid__MostWanted]:
                    isMostWantedSectionVisible &&
                    category.title === MOST_WANTED_TITLE,
                })}
              >
                {items.map((regItem) => {
                  const shouldRenderWideCard =
                    isMostWantedSectionVisible &&
                    category.title === MOST_WANTED_TITLE &&
                    regItem.isFavorite

                  return (
                    <RegItemMinimalCard
                      key={`reg-item-categorized-${regItem.id}`}
                      regItem={regItem}
                      registry={registry}
                      variant={shouldRenderWideCard ? 'Wide' : 'Default'}
                    />
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      )}

      {isPurchasedCategoryVisible && (
        <div className={css.RegistryCategories__Category}>
          <h2
            className={css.RegistryCategories__CategoryTitle}
            data-testid="CategoryTitle-Purchased"
            ref={purchasedItemsRef}
          >
            Purchased{' '}
            <span
              aria-hidden="true"
              className={css.RegistryCategories__CategoryItemCount}
            >
              ({mergedReservations.length})
            </span>
          </h2>
          <div className={css.RegistryCategories__Grid}>
            {mergedReservations.map(
              (reservation) =>
                reservation.regItem && (
                  <RegItemMinimalCard
                    key={`reservation-${reservation.id}`}
                    regItem={reservation.regItem}
                    registry={registry}
                    reservation={reservation}
                  />
                )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default RegistryCategories
