import { useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom-v5-compat'

export const ScrollToTop = () => {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (typeof window !== 'undefined' && navigationType !== 'REPLACE') {
      window.scrollTo(0, 0)
    }
  }, [location.pathname])

  return null
}
