import React from 'react'
import { OrderCardHeaderProps } from 'src/routes/(registry)/shipments/[shipmentId]/components/OrderCard/components/OrderCardHeader/OrderCardHeader.types'
import { useNavigate } from 'react-router-dom-v5-compat'
import { TextLink } from '../../../../../../../../../baby-design'
import css from './OrderCardHeader.styles.scss'

interface OrderCardLinkProps {
  isGift: boolean
  orderUrl: string
  registryId: number
}

const OrderCardLink: React.FC<OrderCardLinkProps> = ({
  isGift,
  orderUrl,
  registryId,
}) => {
  const navigate = useNavigate()

  if (isGift) {
    return (
      <TextLink
        underline
        className={css.OrderCardHeader__Link}
        size="sm"
        onClick={() => navigate(`/registry/${registryId}/gift-tracker`)}
      >
        View gift tracker
      </TextLink>
    )
  }

  return (
    <TextLink
      underline
      className={css.OrderCardHeader__Link}
      href={orderUrl}
      size="sm"
    >
      View order
    </TextLink>
  )
}

const OrderCardHeader: React.FC<OrderCardHeaderProps> = ({
  giftGiverName,
  isGift,
  isSurprise,
  isRevealed,
  itemCount,
  orderNumber,
  orderUrl,
  registryId,
}) => {
  const headerText = (() => {
    if (!giftGiverName || !isGift) {
      return 'Your Order'
    }

    if (isSurprise && !isRevealed) {
      return 'A Surprise Gift'
    }

    return `A gift from ${giftGiverName}`
  })()

  const subtitle = (() => {
    if (!isSurprise) return null

    const text = 'This person requested to keep this a surprise.'
    if (isRevealed) {
      return (
        <span>
          Reminder! 🤫
          <br />
          {text}
        </span>
      )
    }

    return `🤫 ${text}`
  })()

  const itemsLabel = itemCount === 1 ? 'item' : 'items'
  const orderItemsLabel = `${itemCount} ${itemsLabel} • ${orderNumber}`

  return (
    <div className={css.OrderCardHeader}>
      <div className={css.OrderCardHeader__TitleContainer}>
        <p className={css.OrderCardHeader__Title}>{headerText}</p>
        <span className="MobileOnly">
          <OrderCardLink
            isGift={isGift}
            orderUrl={orderUrl}
            registryId={registryId}
          />
        </span>
      </div>
      {subtitle && (
        <p
          className={css.OrderCardHeader__Subtitle}
          data-testid="order-card-header-subtitle"
        >
          {subtitle}
        </p>
      )}
      <p className={css.OrderCardHeader__ItemsLabel}>{orderItemsLabel}</p>
      <span className="HiddenMobile">
        <OrderCardLink
          isGift={isGift}
          orderUrl={orderUrl}
          registryId={registryId}
        />
      </span>
    </div>
  )
}

export default OrderCardHeader
