import { FC, Fragment } from 'react'
import className from 'classnames'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Copy, Location, Message } from 'baby-design/icons'
import {
  GiftGiver,
  ThankYouNoteAddress,
} from 'src/routes/(registry)/registry/(gift-tracker-revamp)/context/GiftGiversProvider.types'

import css from './ContactInfo.styles.scss'

const EmailAddress: FC<{ email: string }> = ({ email }) => (
  <div className={css.ContactInfo__EmailAddress}>
    <span className={css.ContactInfo__EmailAddress__icon}>
      <Message />
    </span>
    <div className={css.ContactInfo__EmailAddress__text}>{email}</div>
  </div>
)

const CopyButton: FC<{ address: string }> = ({ address }) => (
  <div className={css.ContactInfo__CopyButton}>
    <Button
      icon={{
        side: 'left',
        icon: <Copy className={css.ContactInfo__CopyButton__icon} />,
        size: 'sm',
      }}
      size="sm"
      variant="tertiary"
      onClick={async () => {
        await navigator.clipboard.writeText(address)
      }}
    >
      Copy
    </Button>
  </div>
)

const MailingAddress: FC<{
  thankYouNoteAddress: ThankYouNoteAddress
}> = ({
  thankYouNoteAddress: { name, address1, address2, city, state, zip },
}) => {
  const data = [name, address1, address2, `${city}, ${state} ${zip}`].filter(
    (datum) => !!datum
  )
  const address = data.join('\n')

  const rows = data.map((datum) => (
    <Fragment key={datum}>
      {datum}
      <br />
    </Fragment>
  ))

  return (
    <div className={css.ContactInfo__MailingAddress}>
      <span className={css.ContactInfo__MailingAddress__icon}>
        <Location />
      </span>
      <div className={css.ContactInfo__MailingAddress__text}>{rows}</div>
      <CopyButton {...{ address }} />
    </div>
  )
}

const ContactInfo: FC<{ giftGiver: GiftGiver; isOpen: boolean }> = ({
  giftGiver: { email, thankYouNoteAddress },
  isOpen,
}) => (
  <div
    className={className({
      [css.ContactInfo__expandable_outer__closed]: !isOpen,
      [css.ContactInfo__expandable_outer__open]: isOpen,
    })}
    data-testid="ContactInfo"
  >
    <div className={css.ContactInfo__expandable_inner}>
      <div className={css.ContactInfo}>
        <EmailAddress email={email} />
        {thankYouNoteAddress && (
          <MailingAddress thankYouNoteAddress={thankYouNoteAddress} />
        )}
      </div>
    </div>
  </div>
)

export default ContactInfo
