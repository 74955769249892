import { FC, ReactNode } from 'react'

import css from './LabelAndInfo.styles.scss'

export type Variant = 'standard' | 'header' | 'deemphasized'

export type LabelAndInfoProps = {
  label: string
  info: ReactNode
  variant?: Variant
}

const infoClassName = (variant: Variant) =>
  ({
    standard: css.LabelAndInfo__info_standard,
    header: css.LabelAndInfo__info_header,
    deemphasized: css.LabelAndInfo__info_deemphasized,
  })[variant]

const LabelAndInfo: FC<LabelAndInfoProps> = ({
  label,
  info,
  variant = 'standard',
}) => (
  <div className={css.LabelAndInfo}>
    <div className={css.LabelAndInfo__label}>{label}</div>
    <div className={infoClassName(variant)} data-testid="LabelAndInfo__info">
      {info}
    </div>
  </div>
)

export default LabelAndInfo
