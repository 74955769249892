import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { absoluteUrl, ourStoryPath } from 'lib/urls'
import OurStory from './components/OurStory'

const OurStoryPage: FC = () => {
  const title = 'Our Story | Babylist Inc.'
  const description =
    'Our mission is to take care of growing families so they can be confident, connected, happy, and healthy. Learn more.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(ourStoryPath)} rel="canonical" />
      </Helmet>
      <OurStory />
    </div>
  )
}

export default OurStoryPage
