import { useSearchParams } from 'react-router-dom-v5-compat'

import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const useIsParamEnabled = () => {
  const [searchParams] = useSearchParams()
  const urlParamEnabled = searchParams.get('gt_revamp')
  return !!urlParamEnabled
}

const useGiftTrackerRevampFeature = () => {
  const isEnabledViaUrlParam = useIsParamEnabled()

  const { flagValue, isReady } = useFeatureFlag(
    '24f_ff_gift_tracker_revamp_web',
    false
  )

  return isEnabledViaUrlParam || (isReady && !!flagValue)
}

export default useGiftTrackerRevampFeature
