import { CashFundPaymentPreference, Registry } from 'src/types/registry'

export const getCashFundPaymentPreferences = (registry: Registry) => {
  if (!registry) return []

  let paymentOptions: CashFundPaymentPreference[] = []

  if (registry?.cashFundPreferences) {
    const paymentPreferences = registry?.cashFundPreferences?.paymentPreferences
    if (!paymentPreferences) return []

    paymentOptions = paymentPreferences.filter(
      (paymentPreference: CashFundPaymentPreference) =>
        paymentPreference.selected
    )
  }

  return paymentOptions
}

export const getCashFundPaymentPreferencesString = (registry: Registry) => {
  const paymentOptions = getCashFundPaymentPreferences(registry)
  const paymentOptionsNames = paymentOptions.map(
    (paymentOption: CashFundPaymentPreference) => paymentOption.name
  )

  return paymentOptionsNames.length > 0 ? paymentOptionsNames.join(', ') : ''
}
