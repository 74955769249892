// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { useTracking } from 'lib/analytics'
import { useState } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useUIContext } from 'src/contexts/UIContext'
import { RegItemAddSuccessModal } from '../../../../../store/components/product-details-page/product-actions/reg-item-add-success-modal'
import { ProductsAPI } from '../../api/products'
import { RegistryItemsAPI } from '../../api/registryItems'
import { callOrRedirectToLogin } from '../../lib/redirects'
import { addRegItem } from './addRegItem'

interface AddToBabylistButtonProps {
  children?: (...args: any[]) => any
  showSuccessMessage?: boolean
  quantity: number
  onClick?: (...args: any[]) => any
}

const shouldShowRecommendationModal = (addNextRecommendationData: {
  [key: string]: any
}) => Object.keys(addNextRecommendationData).length > 0

export const AddToBabylistButton = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  children = () => {},
  showSuccessMessage = true,
  quantity,
  onClick,
}: AddToBabylistButtonProps) => {
  const [currentUser] = useCurrentUser()
  const { productId } = useParams()
  const { isMobile } = useUIContext()

  const [addingToRegistry, updateAddingToRegistry] = useState(false)
  const [addNextRecommendation, updateAddNextRecommendation] = useState({})
  const tracking = useTracking()

  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { mutateAsync: createRegistryItem } = RegistryItemsAPI.useCreate()
  const { activeProduct } = genericProduct

  if (!activeProduct?.approved) return null

  const addRegItemWithArguments = addRegItem({
    activeProduct,
    showSuccessMessage,
    createRegistryItem,
    currentUser,
    quantity,
    updateAddingToRegistry,
    updateAddNextRecommendation,
    tracking,
  })

  return (
    <>
      <Button
        block
        disabled={addingToRegistry}
        loading={addingToRegistry}
        size={isMobile ? 'md' : 'lg'}
        variant="primary"
        onClick={callOrRedirectToLogin({
          currentUser,
          callback: onClick || addRegItemWithArguments,
        })}
      >
        Add to Babylist
      </Button>
      {shouldShowRecommendationModal(addNextRecommendation) && (
        <RegItemAddSuccessModal
          ProductClickedEventProps={null}
          ProductViewedEventProps={null}
          addNextRecommendation={addNextRecommendation}
          openInNewWindow={false}
          show={shouldShowRecommendationModal(addNextRecommendation)}
          showHeaderToast={false}
          onHide={() => updateAddNextRecommendation({})}
        />
      )}
      {children({
        addRegItem: addRegItemWithArguments,
        isLoading: addingToRegistry,
      })}
    </>
  )
}
