import { Modal } from 'components/modal'
import { ModalBody, ModalHeader } from 'react-bootstrap'
import Image from 'components/image'
import { InfoButton } from 'components/buttons'

export interface ChecklistCompletionModalProps {
  type: 'essentials' | 'everything'
  onHide(): void
}

export const ChecklistCompletionModal = ({
  type,
  onHide,
}: ChecklistCompletionModalProps) => {
  const completionIconUrl =
    '//images.babylist.com/image/upload/v1676325316/checklist/completed.png'

  const titleText =
    type === 'essentials' ? 'Essentials = Done!' : 'Checklist = Complete!'
  const bodyText =
    type === 'essentials'
      ? "You've done the hard part. Now on to the fun stuff!"
      : "You're already crushing this parenting thing."

  return (
    <Modal show>
      <ModalHeader closeButton className="pbn with-title" onHide={onHide} />
      <ModalBody className="text-center">
        <Image alt="Checklist Completion Icon" src={completionIconUrl} />
        <div className="h2 text-muted text-bold">{titleText}</div>
        <div className="h5 mbl text-muted text-bold">{bodyText}</div>
        <InfoButton pill className="phxl" onClick={onHide}>
          Yay!
        </InfoButton>
      </ModalBody>
    </Modal>
  )
}
