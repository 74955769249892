import React from 'react'
import NiceModal from '@ebay/nice-modal-react'

import { TextLink } from 'baby-design'
import { SimpleShipping } from 'baby-design/icons'

import { useUIContext } from 'src/contexts/UIContext'
import ConsolidatedShippingExplainerModal from 'src/components/ConsolidatedShippingExplainerModal'

import css from './ShipmentsTitle.styles.scss'
import { ShipmentsTitleProps } from './ShipmentsTitle.types'

const showModal = async () => {
  await NiceModal.show(ConsolidatedShippingExplainerModal)
}

const BundledShippingNotice: React.FC = () => (
  <div className={css.BundledShippingNotice}>
    <SimpleShipping className={css.BundledShippingNotice__Icon} filled={true} />
    <span>You're getting free bundled shipping.</span>
    <TextLink
      className={css.BundledShippingNotice__TextLink}
      size="md"
      underline={true}
      onClick={showModal}
    >
      Learn more
    </TextLink>
  </div>
)

const ShipmentsTitle: React.FC<ShipmentsTitleProps> = ({
  hasAddress = false,
  isBundledShippingEnabled = false,
}) => {
  const { appWebView } = useUIContext()

  return (
    <div className={css.ShipmentsTitle}>
      {!appWebView ? <h1>Shipments</h1> : null}
      <p>Track orders and gifts purchased from the Babylist Shop</p>
      {hasAddress && isBundledShippingEnabled ? (
        <BundledShippingNotice />
      ) : null}
    </div>
  )
}

export default ShipmentsTitle
