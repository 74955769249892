import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalBody } from 'components/modal'
import { Checkbox, Form, Input, FormButtonGroup } from 'components/forms'
import {
  createRegistryCategory,
  fetchCategorizedRegItems,
} from 'registry/actions/reg-items-actions'

class NewCategoryModal extends PureComponent {
  state = {
    title: '',
    isPrivate: false,
    error: null,
    submitting: false,
  }

  handleCreateCategory = (e) => {
    const { createRegistryCategory, fetchCategorizedRegItems } = this.props

    e.preventDefault()
    this.setState({ submitting: true })

    createRegistryCategory(this.state)
      .then(() => {
        fetchCategorizedRegItems()
        this.hideModal()
      })
      .catch((error) => this.setState({ error: error }))
  }

  handleCategoryTitleChange = (e) => {
    this.setState({ title: e.target.value })
  }

  handlePrivacyToggle = (e) => {
    this.setState({ isPrivate: e.target.checked })
  }

  hideModal = () => {
    this.setState({
      title: '',
      isPrivate: false,
      error: null,
      submitting: false,
    })
    this.props.onHide()
  }

  render() {
    const { title, isPrivate } = this.state

    return (
      <Modal
        onHide={this.hideModal}
        show={this.props.show}
        title="Create a New Category"
      >
        <ModalBody>
          <Form onSubmit={this.handleCreateCategory}>
            <Input
              error={this.state.error}
              type="text"
              groupClassName="mvl"
              label="New Category"
              placeholder="Enter a name for your new category"
              value={this.state.title}
              autoFocus={true}
              onChange={this.handleCategoryTitleChange}
            />
            <Checkbox
              checked={isPrivate}
              onChange={this.handlePrivacyToggle}
              label="Make it private"
            />
            <FormButtonGroup
              onClickSecondary={this.hideModal}
              primaryButtonDisabled={!title}
              submitting={this.state.submitting}
              textPrimary="Create Category"
              textSecondary="Cancel"
            />
          </Form>
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(undefined, {
  createRegistryCategory,
  fetchCategorizedRegItems,
})(NewCategoryModal)
