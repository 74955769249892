import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { PostRegItemAddModal } from 'store/components/post-reg-item-add-modal'
import { ProductPreviewCard } from 'components/card'
import { track, tracking, useTracking } from 'lib/analytics'
import { SOURCE_ADD_NEXT } from 'shared/constants'

const ProductShape = {
  id: PropTypes.number,
  brand: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  name: PropTypes.string,
  rating: PropTypes.number,
  reviewCount: PropTypes.number,
  babylistUrl: PropTypes.string,
}

const RecommendedProductCard = ({
  eventLocation,
  product,
  openInNewWindow,
  isSponsored,
  showCategoryToast = true,
}) => (
  <ProductPreviewCard
    __forcePageLoad
    QuickAddButtonComponent={
      <ProductPreviewCard.QuickAddRegItem
        eventLocation={eventLocation}
        product={product}
        source={SOURCE_ADD_NEXT}
        showCategoryToast={showCategoryToast}
      />
    }
    brand={product.brand}
    imageUrl={product.image?.url}
    isSponsored={isSponsored}
    key={product.id}
    layout="responsive"
    name={product.name}
    openInNewWindow={openInNewWindow}
    price={product.price}
    productId={product.id}
    productUrl={product.storePath}
    rating={product.rating}
    reviewCount={product.reviewCount}
  />
)

RecommendedProductCard.propTypes = {
  eventLocation: PropTypes.string,
  product: PropTypes.shape(ProductShape).isRequired,
  openInNewWindow: PropTypes.bool,
  isSponsored: PropTypes.bool,
  showCategoryToast: PropTypes.bool,
}

RecommendedProductCard.defaultProps = {
  eventLocation: track.EventLocation.PDP,
  openInNewWindow: false,
  isSponsored: false,
  showCategoryToast: true,
}

export const RegItemAddSuccessModal = ({
  addNextRecommendation,
  onHide,
  openInNewWindow,
  ProductClickedEventProps,
  ProductViewedEventProps,
  show,
  showHeaderToast = true,
  ...rest
}) => {
  const PostRegItemAddModalWithTracking = tracking({
    eventLocation: track.EventLocation.NEXT_ADD_RECOMMENDER,
  })(PostRegItemAddModal)

  const productIds = addNextRecommendation?.recommendedProducts?.map(
    ({ product }) => product.id
  )
  const tracker = useTracking()
  useEffect(() => {
    if (addNextRecommendation) {
      tracker.trackEvent({
        event: track.nextAddViewed,
        eventLocation: track.EventLocation.PDP,
        productIds,
        ...ProductViewedEventProps,
      })
      if (
        addNextRecommendation?.recommendedProducts.some((rp) => rp.isSponsored)
      ) {
        tracker.trackEvent({
          event: track.sponsoredProductViewed,
          productIds: addNextRecommendation.recommendedProducts
            .filter((recommendedProduct) => recommendedProduct.isSponsored)
            .map((recommendedProduct) => recommendedProduct.product.id),
          eventLocation: track.EventLocation.NEXT_ADD_RECOMMENDER,
          registryId: addNextRecommendation.metadata.registry.id,
        })
      }
    }
  }, [
    addNextRecommendation?.recommendedProducts
      ?.map((rp) => rp.product.id)
      .join(),
  ])
  return (
    <PostRegItemAddModalWithTracking
      registryId={addNextRecommendation?.metadata?.registry?.id}
      registrySlug={addNextRecommendation?.metadata?.registry?.urlSlug}
      registryTitle={addNextRecommendation?.metadata?.registry?.title}
      show={show}
      showHeaderToast={showHeaderToast}
      onHide={() => {
        onHide()
      }}
      {...rest}
    >
      <PostRegItemAddModal.RecommendedProductsBody>
        {addNextRecommendation?.recommendedProducts?.map(
          ({ product, isSponsored }, index) => {
            const RecommendedProductCardWithTracking = tracking({
              eventLocation: track.EventLocation.NEXT_ADD_RECOMMENDER,
              event: track.productClicked,
              positionInList: index + 1,
              productIds: [product.id],
              productPrice: product.price.current,
              isSponsoredProduct: isSponsored,
              ...ProductClickedEventProps,
            })(RecommendedProductCard)
            return (
              <RecommendedProductCardWithTracking
                eventLocation={track.EventLocation.NEXT_ADD_RECOMMENDER}
                isSponsored={isSponsored}
                key={product.id}
                openInNewWindow={openInNewWindow}
                product={product}
                showCategoryToast={!showHeaderToast}
              />
            )
          }
        )}
      </PostRegItemAddModal.RecommendedProductsBody>
    </PostRegItemAddModalWithTracking>
  )
}

RegItemAddSuccessModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  openInNewWindow: PropTypes.bool,
  ProductClickedEventProps: PropTypes.object,
  ProductViewedEventProps: PropTypes.object,
  show: PropTypes.bool,
  showHeaderToast: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  addNextRecommendation: PropTypes.shape({
    metadata: PropTypes.shape({
      modelVersion: PropTypes.string.isRequired,
      sourceProductId: PropTypes.number.isRequired,
      registry: {
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
      },
    }),
    recommendedProducts: PropTypes.arrayOf(
      PropTypes.shape({
        product: PropTypes.shape(ProductShape),
        score: PropTypes.number,
        isSponsored: PropTypes.bool,
      })
    ),
  }),
}

RegItemAddSuccessModal.defaultProps = {
  openInNewWindow: false,
  ProductClickedEventProps: {},
  ProductViewedEventProps: {},
  show: false,
  showHeaderToast: true,
}
