import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'

import { openRedirectModal } from 'registry/modals/actions'
import { Offer } from 'src/types/offer'
import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import RegItemBuyingOptionsModal from '../../components/RegItemBuyingOptionsModal/RegItemBuyingOptionsModal'

export const useGetHandleExternalOfferClick = (
  offer: Offer,
  registry: Registry,
  regItem?: RegItem
) => {
  const dispatch = useDispatch()
  const modal = useModal()

  return () => {
    if (!regItem) return

    handleExternalOfferClick({
      regItem,
      registry,
      offer,
      dispatch,
      closeModal: () => modal.hide(),
    })
  }
}

interface HandleExternalOfferClickArgs {
  regItem: RegItem
  registry: Registry
  offer: Offer
  dispatch: Dispatch<AnyAction>
  closeModal: () => void
}

const handleExternalOfferClick = ({
  regItem,
  registry,
  offer,
  dispatch,
  closeModal,
}: HandleExternalOfferClickArgs) => {
  closeModal()
  dispatch(
    openRedirectModal({
      url: offer.url,
      storeDisplayName: offer.storeDisplayName,
      onRedirectSuccess: () => {
        NiceModal.show(RegItemBuyingOptionsModal, {
          regItemId: regItem.id,
          registry,
          variantOverride: 'DidYouPurchaseOptions',
        })
      },
      isShopRegistryView: false,
    })
  )
}
