import React from 'react'
import { ShipmentsLayoutProps } from 'src/routes/(registry)/shipments/components/ShipmentsLayout/ShipmentsLayout.types'
import css from './ShipmentsLayout.styles.scss'

const ShipmentsLayout: React.FC<ShipmentsLayoutProps> = ({ children }) => (
  <div className={css.ShipmentsLayout__Container}>
    <div className={css.ShipmentsLayout}>{children}</div>
  </div>
)

export default ShipmentsLayout
