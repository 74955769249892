import { Button, LoadingHeart } from 'baby-design'
import { GroupGiftModal } from 'components/modal'
import { registryViewAsGuestPathFromId } from 'lib/urls'
import { useState } from 'react'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom-v5-compat'
import { GroupGiftReserveForm } from 'registry/components/group-gift-reserve-form/group-gift-reserve-form'
import { useUIContext } from 'src/contexts/UIContext'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { RegistryItemsAPI } from 'src/routes/(shop)/api/registryItems'
import useEZAddToCart from 'src/hooks/useEZAddToCart'
import { submitReservation } from '../../../../../../../../cart/actions'
import { AddToCartModal } from '../../../../../../../../cart/components/add-to-cart-modal/add-to-cart-modal'
import buyBoxCss from './BuyBox/BuyBox.styles.scss'
import css from './GiftGiverActions.styles.scss'

export const getRegistryItemId = (searchParams: URLSearchParams) =>
  searchParams.get('reg_item_id')
export const getRegistryId = (searchParams: URLSearchParams) =>
  searchParams.get('registry_id')

const showFundThisGiftAction = (registryItem: any) =>
  registryItem?.isGroupGift && !registryItem?.quantityPurchased

const showGiveThisGiftAction = (registryItem: any) =>
  !!registryItem &&
  !registryItem.disableReservations &&
  (!registryItem.isGroupGift ||
    (registryItem.isGroupGift &&
      !registryItem.fundedAmount &&
      registryItem.isAvailableOnBabylist))

export const GiftGiverActions = () => {
  const { productId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { isMobile } = useUIContext()

  const [showAddToCartModal, setShowAddToCartModal] = useState(false)
  const [showFundingModal, setShowFundingModal] = useState(false)
  const [addToCartButtonLoading, setAddToCartButtonLoading] = useState(false)
  const [fundingButtonLoading, setFundingButtonLoading] = useState(false)

  const registryId = getRegistryId(searchParams)
  const registryItemId = getRegistryItemId(searchParams)
  if (!registryId || !registryItemId) return null
  const { isItemEnabledForEZAddToCart, addRegItemToCart } = useEZAddToCart()

  const { data: genericProduct, isLoading: isProductLoading } =
    ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct?.approved) return null

  const {
    isLoading: isRegistryItemLoading,
    isError,
    data: registryItem,
  } = RegistryItemsAPI.useShow({
    registryId,
    registryItemId,
  })
  if (isProductLoading || isRegistryItemLoading)
    return <LoadingHeart className={css.GiftGiverActions__Loader} />
  if (!registryItem) return null

  const handleAddToCartClick = () => {
    setAddToCartButtonLoading(true)
    if (isItemEnabledForEZAddToCart(registryItem)) {
      addRegItemToCart({
        regItem: registryItem,
        onSuccess: () => setAddToCartButtonLoading(false),
      })
    } else {
      setShowAddToCartModal(true)
    }
  }

  return (
    <div>
      {showAddToCartModal && (
        <AddToCartModal
          regItem={registryItem}
          onHide={() => {
            setAddToCartButtonLoading(false)
            setShowAddToCartModal(false)
          }}
          onSubmit={submitReservation}
        />
      )}
      {showFundThisGiftAction(registryItem) && (
        <GroupGiftModal
          show={showFundingModal}
          title="Fund This Group Gift"
          onHide={() => {
            setFundingButtonLoading(false)
            setShowFundingModal(false)
          }}
        >
          <GroupGiftReserveForm
            regItem={registryItem}
            onHide={() => {
              setFundingButtonLoading(false)
              setShowFundingModal(false)
            }}
          />
        </GroupGiftModal>
      )}
      <div
        className={buyBoxCss.BuyBox__actionsContainer}
        style={{ minHeight: '32 px', position: 'relative' }}
      >
        {showFundThisGiftAction(registryItem) && (
          <div>
            <Button
              block
              loading={fundingButtonLoading}
              size={isMobile ? 'md' : 'lg'}
              variant="primary"
              onClick={() => {
                setFundingButtonLoading(true)
                setShowFundingModal(true)
              }}
            >
              Fund This Gift
            </Button>
          </div>
        )}
        {showGiveThisGiftAction(registryItem) && (
          <div>
            <Button
              block
              disabled={!registryItem.isAvailableOnBabylist}
              loading={addToCartButtonLoading}
              size={isMobile ? 'md' : 'lg'}
              variant="primary"
              onClick={handleAddToCartClick}
            >
              Add to Cart
            </Button>
            {!registryItem.isAvailableOnBabylist && (
              <small className="block text-center xsmall pam">
                Out of Stock
              </small>
            )}
          </div>
        )}
        {isError && (
          <Button
            block
            size={isMobile ? 'md' : 'lg'}
            variant="secondary"
            onClick={() => navigate(registryViewAsGuestPathFromId(registryId))}
          >
            Return to Registry
          </Button>
        )}
      </div>
    </div>
  )
}
