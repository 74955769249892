import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { FixedButtonTransitionProps } from './FixedButtonTransition.types'
import css from './FixedButtonTransition.styles.scss'

const CLASSES = {
  enter: css['FixedButtonTransition--enter'],
  enterDone: css['FixedButtonTransition--enter-done'],
  exit: css['FixedButtonTransition--exit'],
  exitDone: css['FixedButtonTransition--exit-done'],
}

const FixedButtonTransition: React.FC<FixedButtonTransitionProps> = ({
  children,
  in: isOpen,
  timeout = 200,
}) => {
  /*
    Handling the transition class with callbacks allows persistence of the value for
    stateClass between renders. This is necessary to avoid the class being reset when
    viewing dimensions change.
  */
  const [stateClass, setStateClass] = useState(CLASSES.exitDone)
  const nodeRef = useRef<HTMLDivElement>(null)
  const transitionClasses = classNames(css.FixedButtonTransition, stateClass)

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear={isOpen}
      in={isOpen}
      nodeRef={nodeRef}
      timeout={timeout}
      onEnter={() => setStateClass(CLASSES.enter)}
      onEntered={() => setStateClass(CLASSES.enterDone)}
      onExit={() => setStateClass(CLASSES.exit)}
      onExited={() => setStateClass(CLASSES.exitDone)}
    >
      <div className={transitionClasses} ref={nodeRef}>
        {children}
      </div>
    </CSSTransition>
  )
}

export default FixedButtonTransition
