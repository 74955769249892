import RegistryGiftGiverHeader from 'src/routes/(subdomains)/(my)/[slug]/components/RegistryGiftGiverHeader'
import HolidayWishlistHeaderLayout from 'registry/components/GuestRegistryHeader/components/HolidayWishlistHeaderLayout/HolidayWishlistHeaderLayout'
import { BabyRegistryHeaderLayout } from './BabyRegistryHeaderLayout'
import BirthdayWishlistHeaderLayout from './components/BirthdayWishlistHeaderLayout/BirthdayWishlistHeaderLayout'

export interface GuestRegistryHeaderProps {
  arrivalDate?: string
  note?: string | null
  photoUrl?: string | null
  registry: any
  title: string
  waveColor?: string
  config?: string[]
}

export const GuestRegistryHeader = (props: GuestRegistryHeaderProps) => {
  const { registry } = props
  const { glowupEnabled } = registry.headerDetails

  if (glowupEnabled) {
    return <RegistryGiftGiverHeader registry={registry} />
  }

  if (registry.type === 'holiday') {
    return <HolidayWishlistHeaderLayout registry={registry} />
  }

  if (registry.type === 'birthday') {
    return <BirthdayWishlistHeaderLayout registry={registry} />
  }

  return <BabyRegistryHeaderLayout {...props} />
}
