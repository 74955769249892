import { Children, cloneElement } from 'react'
import classNames from 'classnames'

import { WhiteCheckmarkIcon } from 'components/icons'
import { BubbleModal } from 'components/modal'

import Link from 'components/link'
import css from './post-reg-item-add-modal.scss'

/**
 * Mean to encapsulate the layout of ProductPreviewCards (or any content if needed).
 * This allows callers to pass in props to ProductPreviewCards (or whatever content)
 * without needed to add bespoke props here.
 */
const RecommendedProductsBody = ({ children }) => {
  const mappedChildren = Children.map(children, (child) => (
    <div className={classNames(css.bodyItem, `flex grow`)}>
      {cloneElement(child, { layout: 'responsive' })}
    </div>
  ))

  return (
    <>
      {/*
        Use flex-row-md over row and wrap to match up stacking whitespace.
        e.g. roughly col -> mt-sm & flex-row-md -> ml-lg
      */}
      <div className="flex col flex-row-md justify-center">
        {mappedChildren}
      </div>
    </>
  )
}

export const PostRegItemAddModal = ({
  title = 'Babylist users who added this item also loved:',
  children,
  ...props
}) => (
  <BubbleModal {...props}>
    <BubbleModal.Header className="pal">
      <div className="flex center">
        <WhiteCheckmarkIcon
          className="mrm"
          height={30}
          style={{ marginBottom: '-2px' }}
          width={30}
        />{' '}
        Item Added to&nbsp;
        <Link newWindow url={`/list/${props.registrySlug}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: props.registryTitle
                ? props.registryTitle
                : 'your registry',
            }}
          />
        </Link>
        !
      </div>
    </BubbleModal.Header>
    <BubbleModal.Body className="pvl phs phl-md" title={title}>
      {children}
    </BubbleModal.Body>
  </BubbleModal>
)

PostRegItemAddModal.RecommendedProductsBody = RecommendedProductsBody
