import React from 'react'
import { OrderItemProps } from 'src/routes/(registry)/shipments/[shipmentId]/components/OrderCard/components/OrderItem/OrderItem.types'
import Link from 'components/link'
import { Badge, Image } from '../../../../../../../../../baby-design'
import css from './OrderItem.styles.scss'

const OrderItem: React.FC<OrderItemProps> = ({
  image,
  title,
  url,
  quantity,
}) => (
  <div className={css.OrderItem}>
    <div className={css.OrderItem__ImageContainer}>
      <Image
        alt={title}
        className={css.OrderItem__Image}
        role="presentation"
        src={image}
      />
      {quantity > 1 && (
        <Badge
          dangerouslyOverrideClassName={css.OrderItem__Badge}
          fill="default"
          quantity={quantity}
          size="small"
          variant="quantity"
        />
      )}
    </div>
    <Link className={css.OrderItem__Link} url={url}>
      <span className={css.OrderItem__Title}>{title}</span>
    </Link>
  </div>
)

export default OrderItem
