import React from 'react'
import { useWindowSize } from 'usehooks-ts'
import { Badge } from 'baby-design'
import Gift from 'baby-design/icons/Gift.svg'

import { BREAKPOINT_TABLET } from 'src/constants'
import { PurchasedBadgeProps } from './PurchasedBadge.types'
import css from './PurchasedBadge.styles.scss'
import { getReservationBadgeText } from './PurchasedBadge.utils'

const PurchasedBadge: React.FC<PurchasedBadgeProps> = ({
  regItemType,
  reservation,
}) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const badgeText: string = getReservationBadgeText(reservation, regItemType)

  return (
    <div className={css.PurchasedBadge} data-testid="PurchasedBadge">
      <Badge
        fill="standard-grey"
        icon={reservation.isVisitorReservation ? <Gift filled /> : undefined}
        label={badgeText}
        size={isMobile ? 'small' : 'medium'}
        variant="promo"
      />
    </div>
  )
}

export default PurchasedBadge
