import React, { useContext } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { shipNowEligible } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import ShipNowContext from 'src/contexts/shipNow'
import ShipNowBanner from 'src/routes/(registry)/shipments/components/ShipNowBanner/ShipNowBanner'
import ShipNowModal from 'src/routes/(registry)/shipments/components/ShipNowModal/ShipNowModal'

import GetHelpBanner from '../GetHelpBanner'
import DecoratedOrderCard from '../DecoratedOrderCard/DecoratedOrderCard'
import ShipmentDetailsMetadata from '../ShipmentDetailsMetadata/ShipmentDetailsMetadata'
import ShipmentDetailsNotificationBanner from '../ShipmentDetailsNotificationBanner'

import css from './ShipmentDetailsContent.styles.scss'
import { ShipmentDetailsContentProps } from './ShipmentDetailsContent.types'
import { useTrackShipNowStartedClick } from '../../../hooks/useTrackClick'

const ShipmentDetailsContent: React.FC<ShipmentDetailsContentProps> = ({
  shipment,
}) => {
  const { onShipNowComplete } = useContext(ShipNowContext)
  const trackShipNowStartedClick = useTrackShipNowStartedClick({ shipment })

  const handleShipNow = async () => {
    trackShipNowStartedClick()
    const completed = await NiceModal.show(ShipNowModal, { shipment })
    if (completed && onShipNowComplete) {
      onShipNowComplete()
    }
  }

  return (
    <div className={css.ShipmentDetailsContent}>
      <ShipmentDetailsNotificationBanner shipment={shipment} />
      <ShipmentDetailsMetadata shipment={shipment} />
      <div className={css.ShipmentDetailsContent__Orders}>
        {shipment?.orders?.map((order) => <DecoratedOrderCard order={order} />)}
      </div>
      {shipment && shipNowEligible(shipment) && (
        <ShipNowBanner onShipNow={handleShipNow} />
      )}
      {shipment?.shipmentStatus?.overdue && <GetHelpBanner />}
    </div>
  )
}

export default ShipmentDetailsContent
