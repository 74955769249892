import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import fetch from 'lib/fetch'
import { FEED_BRANDS_DATA_API_URL } from 'registry/constants'
import { track } from 'lib/analytics'
import ShopCell from '../ShopCell/ShopCell'

import css from './BrowseByBrands.styles.scss'

import { Brand, BrowseByBrandsProps } from './BrowseByBrands.types'

const BrowseByBrands: React.FC<BrowseByBrandsProps> = ({ className }) => {
  const maxTileCount = 16
  const BrowseByBrandsClasses = classNames(css.BrowseByBrands, className)

  const [isFetching, setIsFetching] = useState(true)
  const [brands, setBrands] = useState<Brand[]>([])

  useEffect(() => {
    fetch(`${FEED_BRANDS_DATA_API_URL}?country=united-states`)
      .then((data) => {
        setIsFetching(false)
        setBrands(data.data || [])
      })
      .catch((error) => {
        console.error(error)
        setIsFetching(false)
      })
  }, [])

  const renderLoadingTiles = () => {
    const loadingTiles = []
    for (let i = 0; i < maxTileCount; i++) {
      loadingTiles.push(
        <div className={css.BrowseByBrands__loadingShimmer} key={i} />
      )
    }
    return loadingTiles
  }

  return (
    <div className={BrowseByBrandsClasses}>
      <h2>Browse by popular brands</h2>
      <div className={css.BrowseByBrands__brands}>
        {isFetching && renderLoadingTiles()}
        {!isFetching &&
          brands.slice(0).map((brand, index) => (
            <div
              className={css.BrowseByBrands__brand}
              key={index}
              onClick={() => {
                track.brandTileClicked({
                  eventLocation: track.EventLocation.ADD_ITEMS,
                  brand: brand.name,
                })
              }}
            >
              <ShopCell store={brand} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default BrowseByBrands
