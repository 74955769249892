import { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalBody, ModalFooter } from 'components/modal'
import { Input, FormButtonGroup } from 'components/forms'
import getHintText from './helpers'

const AddFavorModal = ({ favor, onConfirm, onHide, isLoading = null }) => {
  const [description, setDescription] = useState('')

  const handleConfirm = () => {
    onConfirm(description)
  }

  const handleCancel = () => {
    onConfirm()
  }

  return (
    <Modal show onHide={onHide} title="Add a note to help your gift givers!">
      <ModalBody>
        <ModalFooter>
          <Input
            type="textarea"
            name="description"
            aria-label="Add a note"
            style={{ minHeight: '200px' }}
            placeholder={getHintText(favor)}
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormButtonGroup
            textSecondary="Not Now"
            onClickPrimary={handleConfirm}
            textPrimary="Add registry note"
            onClickSecondary={handleCancel}
            submitting={isLoading}
          />
        </ModalFooter>
      </ModalBody>
    </Modal>
  )
}

AddFavorModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  favor: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default AddFavorModal
