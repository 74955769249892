import { ContentContainer } from 'src/routes/(shop)/components/ContentContainer'
import {
  AdminLink,
  BuyBoxContainer,
  MerchandisingMessageContainer,
  WithoutTooltip,
  FreeShippingAndReturns,
  DeliverySurcharge,
  VirtualBundle,
  PDPHeadline,
  ProductCallouts,
} from '../../BuyBox'
import { FavorActions } from '../../FavorActions'
import { Column, StickyColumn } from '../../Layout/Column'
import { Row } from '../../Layout/Row'
import { ProductDetailsSection } from '../../ProductDetailsSection/ProductDetailsSection'
import { ProductImages } from '../../ProductImages/ProductImages'
import { Content } from '../../Content'
import { BackToTopButton } from '../../BackToTop/Button'
import { WaypointWrapper } from '../../BackToTop/Waypoint/Wrapper'

export const Favor = () => (
  <ContentContainer>
    <BackToTopButton />
    <Row>
      <Column width="58%">
        <StickyColumn>
          <ProductImages />
        </StickyColumn>
      </Column>
      <Column width="42%">
        <BuyBoxContainer>
          <PDPHeadline />
          <MerchandisingMessageContainer>
            <WithoutTooltip />
            <FreeShippingAndReturns />
            <DeliverySurcharge />
            <VirtualBundle />
          </MerchandisingMessageContainer>
          <ProductCallouts />
          <WaypointWrapper />
          <FavorActions />
          <Content />
          <AdminLink />
        </BuyBoxContainer>
      </Column>
    </Row>
    <ProductDetailsSection />
  </ContentContainer>
)
