import React, { useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { useWindowSize } from 'usehooks-ts'

import useBlinkyTitleBar from 'src/hooks/useBlinkyTitleBar/useBlinkyTitleBar'
import { BREAKPOINT_TABLET } from 'src/constants'
import { RegItemBuyingOptionsProps } from '../../RegItemBuyingOptionsModal.types'
import css from './DidYouPurchaseOptions.styles.scss'
import {
  getNoButtonHandler,
  useGetYesButtonHandler,
} from './DidYouPurchaseOptions.utils'
import ShippingAddress from './components/ShippingAddress/ShippingAddress'
import { useShippingAddressButtonContext } from '../../../../contexts'

const DidYouPurchaseOptions: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
  registry,
}) => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < BREAKPOINT_TABLET
  const handleNoButtonClick = getNoButtonHandler(regItem, registry)
  const handleYesButtonClick = useGetYesButtonHandler(regItem, registry)
  const { setIsShippingAddressButtonVisible } =
    useShippingAddressButtonContext()

  useBlinkyTitleBar({
    altTitle: 'Did you purchase it?',
  })

  useEffect(() => {
    setIsShippingAddressButtonVisible(true)
  }, [setIsShippingAddressButtonVisible])

  return (
    <div
      className={css.DidYouPurchaseOptions}
      data-testid="DidYouPurchaseOptions"
    >
      <div className={css.DidYouPurchaseOptions__AddressCard}>
        <ShippingAddress registry={registry} />
      </div>

      <div className={css.DidYouPurchaseOptions__CTA}>
        <Button
          block
          data-testid="NoButton"
          size={isMobile ? 'md' : 'lg'}
          variant="secondary"
          onClick={handleNoButtonClick}
        >
          No
        </Button>
        <Button
          block
          data-testid="YesButton"
          size={isMobile ? 'md' : 'lg'}
          variant="primary"
          onClick={handleYesButtonClick}
        >
          Yes, I purchased it
        </Button>
      </div>
    </div>
  )
}

export default DidYouPurchaseOptions
