import React from 'react'
import {
  ShipmentCardProps,
  ShipmentStatusBadgeProps,
} from 'src/routes/(registry)/shipments/components/ShipmentCard/ShipmentCard.types'
import { itemsToThumbnails } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import { useNavigate } from 'react-router-dom-v5-compat'
import ShipNowBanner from 'src/routes/(registry)/shipments/components/ShipNowBanner/ShipNowBanner'
import css from './ShipmentCard.styles.scss'
import { TextLink } from '../../../../../../baby-design'
import Badge from '../../../../../../baby-design/components/Badge/Badge'
import ThumbnailRow from '../../../../../../baby-design/components/ThumbnailRow/ThumbnailRow'

const ShipmentStatusBadge: React.FC<ShipmentStatusBadgeProps> = (props) => (
  <>
    <div className="HiddenMobile">
      <Badge
        dangerouslyOverrideClassName={css.ShipmentCard__StatusBadge}
        size="medium"
        variant="status"
        {...props}
      />
    </div>
    <div className="MobileOnly">
      <Badge
        dangerouslyOverrideClassName={css.ShipmentCard__StatusBadge}
        size="small"
        variant="status"
        {...props}
      />
    </div>
  </>
)

const ShipmentCard: React.FC<ShipmentCardProps> = ({
  badge,
  headline,
  items,
  shipmentUrl,
  shipNowEligible = false,
  subtitle,
  onShipNowClick,
}) => {
  const navigate = useNavigate()

  const handleShipNowClick = () => onShipNowClick && onShipNowClick()

  return (
    <div className={css.ShipmentCard}>
      <div className={css.ShipmentCard__ContentContainer}>
        <div className={css.ShipmentCard__Content}>
          {badge && <ShipmentStatusBadge {...badge} />}
          <div className={css.ShipmentCard__TextContent}>
            {headline && (
              <p className={css.ShipmentCard__Headline}>{headline}</p>
            )}
            {subtitle && (
              <p className={css.ShipmentCard__Subtitle}>{subtitle}</p>
            )}
            <TextLink
              underline
              className={css.ShipmentCard__Link}
              onClick={() => navigate(shipmentUrl)}
            >
              <span className={css.MobileScreenReaderOnly}>View details</span>
            </TextLink>
          </div>
        </div>
        <div className={css.ShipmentCard__Thumbnails}>
          <ThumbnailRow
            responsive
            maxVisible={5}
            selectable={false}
            thumbnails={itemsToThumbnails(items)}
          />
        </div>
      </div>
      {shipNowEligible && (
        <ShipNowBanner
          className={css.ShipmentCard__Banner}
          onShipNow={handleShipNowClick}
        />
      )}
    </div>
  )
}

export default ShipmentCard
