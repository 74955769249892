import { FC, MouseEventHandler } from 'react'
import { InfoButton } from 'components/buttons'
import Image from 'components/image'
import { addItemsPath } from 'lib/urls'
import ChecklistCTA from './ChecklistCTA'

interface BabyRegistryLayoutProps {
  isContributorView: boolean
  registryId: number
}

const BabyRegistryLayout: FC<BabyRegistryLayoutProps> = ({
  isContributorView,
  registryId,
}) => (
  <>
    <span className="text-center text-muted">
      {isContributorView ? 'Your' : 'This'} registry is empty.
    </span>
    <Image
      src="//images.babylist.com/image/upload/f_auto,q_auto/v1684341391/Checklist_FV_playing_gray_nzhmko.png"
      width={80}
    />
    {isContributorView && <ChecklistCTA registryId={registryId} />}
  </>
)

interface WishlistLayoutProps {
  isContributorView: boolean
}

const WishlistLayout: FC<WishlistLayoutProps> = ({ isContributorView }) => (
  <>
    <span className="text-center text-muted">
      {isContributorView ? 'Your' : 'This'} wishlist is empty.
    </span>
    <Image
      src="//images.babylist.com/image/upload/v1696540851/first-must-have_pxdbev.svg"
      width={80}
    />
    {isContributorView && (
      <InfoButton pill href={addItemsPath}>
        Get the Babylist Button
      </InfoButton>
    )}
  </>
)

interface ClearFiltersLayoutProps {
  clearFilters: MouseEventHandler<HTMLButtonElement>
}

const ClearFiltersLayout: FC<ClearFiltersLayoutProps> = ({ clearFilters }) => (
  <>
    <span className="text-center text-muted">
      No gifts matched the filters you selected.
    </span>
    <InfoButton empty pill className="mtm" size="small" onClick={clearFilters}>
      Clear Filters
    </InfoButton>
  </>
)

interface NoRegItemsMessageProps {
  isContributorView: boolean
  clearFilters: MouseEventHandler<HTMLButtonElement>
  totalRegItemsCount: number
  filteredRegItemsCount: number
  registryId: number
  registryType: string | null
}

const NoRegItemsMessage: FC<NoRegItemsMessageProps> = ({
  isContributorView = true,
  clearFilters,
  totalRegItemsCount,
  filteredRegItemsCount,
  registryId,
  registryType,
}) => {
  let state: 'NONE' | 'EMPTY_BABY' | 'EMPTY_WISHLIST' | 'EMPTY_FILTER' = 'NONE'

  if (totalRegItemsCount === 0) {
    state =
      registryType === 'baby' || registryType === null
        ? 'EMPTY_BABY'
        : 'EMPTY_WISHLIST'
  } else if (filteredRegItemsCount === 0) {
    state = 'EMPTY_FILTER'
  }

  const layouts: Record<string, JSX.Element> = {
    EMPTY_BABY: (
      <BabyRegistryLayout
        isContributorView={isContributorView}
        registryId={registryId}
      />
    ),
    EMPTY_WISHLIST: <WishlistLayout isContributorView={isContributorView} />,
    EMPTY_FILTER: <ClearFiltersLayout clearFilters={clearFilters} />,
  }

  return state === 'NONE' ? null : (
    <div className="well flex col center paxl" style={{ minHeight: 300 }}>
      {layouts[state]}
    </div>
  )
}

export default NoRegItemsMessage
