import { useContext } from 'react'

import { track, useTracking } from 'lib/analytics'
import { Registry } from 'src/types/registry'
import { Shipment } from 'src/types/shipments'

import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { useRegistry } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../../../../global'

import EventLocationContext from '../contexts/EventLocationContext'

const ShipmentActionCTAs = {
  SHIP_NOW_STARTED: 'Ship Now Started',
  SHIP_NOW_CONFIRMED: 'Ship Now Confirmed',
  VIEW_GIFT_TRACKER: 'View Gift Tracker',
}

interface ShipmentActionClickedEventArgs {
  eventCta: string
  eventLocation: string
  registry: Registry
  currentUser: CurrentUser
  shipment?: Shipment
}
const shipmentActionClickedEvent = ({
  eventCta,
  eventLocation,
  registry,
  currentUser,
  shipment,
}: ShipmentActionClickedEventArgs) => ({
  event: track.shipmentActionClicked,
  eventCta,
  eventLocation,
  shipmentId: shipment?.id,
  productIds: shipment?.orders
    ?.flatMap((order) => order.items)
    ?.map((item) => item.productId),
  ...trackingRegistryProperties({ registry, currentUser }),
})

const useTrackClick = ({
  eventCta,
  shipment,
}: {
  eventCta: string
  shipment: Shipment | undefined
}): (() => void) => {
  const [currentUser] = useCurrentUser()
  const registry = useRegistry()
  const eventLocation = useContext(EventLocationContext)
  const tracking = useTracking()

  return () => {
    if (!!currentUser && !!registry && !!eventLocation) {
      const event = shipmentActionClickedEvent({
        eventCta,
        eventLocation,
        currentUser,
        registry,
        shipment,
      })
      tracking.trackEvent(event)
    }
  }
}

export const useTrackShipNowStartedClick = ({
  shipment,
}: {
  shipment: Shipment | undefined
}) =>
  useTrackClick({
    eventCta: ShipmentActionCTAs.SHIP_NOW_STARTED,
    shipment,
  })

export const useTrackShipNowConfirmedClick = ({
  shipment,
}: {
  shipment: Shipment
}) =>
  useTrackClick({
    eventCta: ShipmentActionCTAs.SHIP_NOW_CONFIRMED,
    shipment,
  })
