import React from 'react'
import { DecoratedOrderCardProps } from 'src/routes/(registry)/shipments/[shipmentId]/components/DecoratedOrderCard/DecoratedOrderCard.types'
import OrderCard from 'src/routes/(registry)/shipments/[shipmentId]/components/OrderCard/OrderCard'

const DecoratedOrderCard: React.FC<DecoratedOrderCardProps> = ({ order }) => {
  const itemCount = order.items.reduce((sum, item) => sum + item.quantity, 0)

  return (
    <OrderCard
      giftGiverName={order.giftGiver?.name}
      isGift={order.gift}
      isRevealed={!!order.giftGiver?.isSurpriseRevealed}
      isSurprise={!!order.giftGiver?.isSurprise}
      itemCount={itemCount}
      items={order.items}
      orderNumber={order.reference}
      orderUrl={order.shopifyStatusUrl}
      registryId={order.registryId}
    />
  )
}

export default DecoratedOrderCard
