import { Category } from 'src/routes/(shop)/api/products'
import { Fragment } from 'react'
import { Item } from '../Item/Item'
import css from './TruncatedCrumbs.styles.scss'

interface TruncatedCrumbsProps {
  listOfCategoriesToDisplay: Category[]
  listOfTruncatedCategories: Category[]
  onClick: () => void
  productName: string
  productSlug: string
  genericProductId: string
  productId: string | undefined
}

export const TruncatedCrumbs = (props: TruncatedCrumbsProps) => {
  const {
    listOfCategoriesToDisplay,
    listOfTruncatedCategories,
    onClick,
    productName,
    productSlug,
    genericProductId,
    productId,
  } = props
  const handleExpansion = () => {
    onClick()
  }
  return (
    <>
      <li key={`ellipsis-${productId}`} onClick={handleExpansion}>
        ...
        <span
          aria-hidden="true"
          className={css.TruncatedCrumbs__ellipsisSeparator}
        >
          /
        </span>
      </li>
      {listOfTruncatedCategories.map((category: Category, index: number) => (
        <Item
          hidden
          index={index}
          key={`truncated-${category.title}`}
          slug={category.urlSlug}
          title={category.title}
        />
      ))}
      {listOfCategoriesToDisplay.map((category: Category, index: number) => {
        const isLastCategoryIndex =
          index === listOfCategoriesToDisplay.length - 1
        return (
          <Fragment key={`displayed-${category.title}`}>
            {isLastCategoryIndex ? (
              <Item
                isLast
                index={index}
                slug={category.urlSlug}
                title={category.title}
              />
            ) : (
              <Item
                index={index}
                slug={category.urlSlug}
                title={category.title}
              />
            )}
          </Fragment>
        )
      })}
      <Item
        hidden
        genericProductId={genericProductId}
        productId={productId}
        slug={productSlug}
        title={productName}
      />
    </>
  )
}
