import React, { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge, Button, QuantityPicker, StoreIcon } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ShoppingCartEmpty } from 'baby-design/icons'
import classNames from 'classnames'

import { BabylistRegItemOfferProps } from './BabylistRegItemOffer.types'
import RegItemPricingLockup from '../../../RegItemPricingLockup/RegItemPricingLockup'
import { useGetBabylistOfferClickHandler } from '../../../../hooks/useGetBabylistOfferClickHandler/useGetBabylistOfferClickHandler'
import css from './BabylistRegItemOffer.styles.scss'
import baseCss from '../../RegItemOffer.styles.scss'
import useGetCart from '../../../../hooks/useGetCart'
import {
  getCartedQuantity,
  getCartedType,
} from '../../../../utils/cartedRegItemHelpers/cartedRegItemHelpers'
import useUpdateCartedRegItem from '../../../../hooks/useUpdateCartItems/useUpdateCartedRegItem'
import { useTrackRegistryProductOfferClicked } from '../../../../hooks/useTrackRegistryProductOfferClicked/useTrackRegistryProductOfferClicked'
import { useTrackProductClicked } from '../../../../hooks/useTrackProductClicked/useTrackProductClicked'

// eslint-disable-next-line complexity
const BabylistRegItemOffer: React.FC<BabylistRegItemOfferProps> = ({
  offer,
  regItem,
  currentUser,
  registry,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [displayQuantity, setDisplayQuantity] = React.useState(0)
  const handleBabylistOfferClick = useGetBabylistOfferClickHandler({
    registry,
    currentUser,
    regItem,
    setIsLoading,
  })
  const trackRegistryProductOfferClicked = useTrackRegistryProductOfferClicked(
    registry,
    regItem,
    currentUser,
    offer
  )
  const trackProductClicked = useTrackProductClicked(
    regItem,
    registry,
    currentUser
  )
  const handleAddButtonClick = () => {
    trackRegistryProductOfferClicked()
    trackProductClicked()
    handleBabylistOfferClick()
  }
  const { cart } = useGetCart()
  const quantityInCart = getCartedQuantity(regItem, cart)
  const cartedType = getCartedType(regItem, cart)
  const {
    handleDecreaseQuantity,
    handleIncreaseQuantity,
    handleRemoveFromCart,
  } = useUpdateCartedRegItem(regItem, cart)

  useEffect(() => {
    setDisplayQuantity(quantityInCart)
  }, [quantityInCart])

  const isInCart = quantityInCart > 0
  const buttonAccessibleText = 'Add to Babylist cart'
  const wrapperClasses = classNames(
    baseCss.RegItemOffer,
    css.BabylistRegItemOffer
  )

  let estimateSuffix
  if (regItem?.deliveryEstimate?.arrivesBeforeShower) {
    estimateSuffix = 'before the shower'
  } else if (regItem?.deliveryEstimate?.arrivesBeforeArrivalDate) {
    estimateSuffix = "before baby's arrival"
  }

  return (
    <div className={wrapperClasses} data-testid="BabylistRegItemOffer">
      <div
        className={classNames(
          baseCss.RegItemOffer__Offer,
          css.BabylistRegItemOffer__Offer
        )}
      >
        <div className={baseCss.RegItemOffer__Details}>
          <StoreIcon
            className={baseCss.RegItemOffer__StoreIcon}
            store={{ name: offer.storeIconName }}
          />
          <div>
            <div className={baseCss.RegItemOffer__StoreName}>Babylist Shop</div>
            <div className={baseCss.RegItemOffer__Price}>
              {regItem.isAvailableOnBabylist ? (
                <RegItemPricingLockup
                  regItem={{
                    ...regItem,
                    offers: [offer], // Only pass babylist offer to get special sale treatments
                  }}
                  size="small"
                />
              ) : (
                <div className={css.BabylistRegItemOffer__OutOfStockBadge}>
                  <Badge
                    fill="gray"
                    label="Out of stock"
                    size="small"
                    variant="promo"
                  />
                </div>
              )}
            </div>
          </div>
          {regItem.isAvailableOnBabylist && !isInCart && (
            <button
              aria-label={buttonAccessibleText}
              className={baseCss.RegItemOffer__OverlayButton}
              data-testid="OverlayButton"
              type="button"
              onClick={handleAddButtonClick}
            />
          )}
        </div>
        <div className={baseCss.RegItemOffer__CTA}>
          {isInCart && cartedType === 'CartItem' && (
            <QuantityPicker
              className={css.InCartBadge__QuantityPicker}
              max={regItem.quantityNeeded || 10}
              size="sm"
              type="cart"
              value={displayQuantity}
              variant="fill"
              onMinusClick={() => {
                setDisplayQuantity(displayQuantity - 1)
                handleDecreaseQuantity(displayQuantity - 1)
              }}
              onPlusClick={() => {
                setDisplayQuantity(displayQuantity + 1)
                handleIncreaseQuantity(displayQuantity + 1)
              }}
              onTrashClick={handleRemoveFromCart}
            />
          )}
          {!isInCart && regItem.isAvailableOnBabylist && (
            <Button
              disabled={isLoading}
              icon={{
                side: 'right',
                size: 'xl',
                icon: <ShoppingCartEmpty />,
              }}
              size="sm"
              title={buttonAccessibleText}
              variant="primary"
              onClick={handleAddButtonClick}
            >
              {isLoading ? 'Adding...' : 'Add'}
            </Button>
          )}
        </div>
      </div>

      {regItem.isAvailableOnBabylist && (
        <div className={css.BabylistRegItemOffer__BabylistDetails}>
          {regItem.deliveryEstimate && (
            <div className={css.BabylistRegItemOffer__DeliveryEstimate}>
              <div className={css.BabylistRegItemOffer__DeliveryEstimateLabel}>
                Estimated delivery {estimateSuffix}
              </div>
              <div className={css.BabylistRegItemOffer__DeliveryEstimateValue}>
                {regItem.deliveryEstimate.message}
              </div>
            </div>
          )}
          <div className={css.BabylistRegItemOffer__FreeShipping}>
            Free shipping over $45 and free returns
          </div>
        </div>
      )}
    </div>
  )
}

export default BabylistRegItemOffer
