import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchReservations, FETCH_RESERVATIONS_KEY } from 'src/api/queries'
import { Reservation } from 'src/types/reservation'

const useGetReservations = (registryId: number | undefined) => {
  const reservationsQuery = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_RESERVATIONS_KEY, registryId],
    queryFn: () => fetchReservations(registryId),
  })
  let reservations: Reservation[] = []

  if (reservationsQuery.data) {
    reservations = Object.values(reservationsQuery.data)
  }

  return { reservations, reservationsQuery }
}

export const useInvalidateReservations = (registryId: number) => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries([FETCH_RESERVATIONS_KEY, registryId])
}

export const useOptimisticAddReservation = () => {
  const queryClient = useQueryClient()

  return (registryId: number, newReservation: Reservation) => {
    queryClient.setQueryData(
      [FETCH_RESERVATIONS_KEY, registryId],
      (currentReservations?: Record<number, Reservation>) => ({
        ...(currentReservations || {}),
        [newReservation.id]: newReservation,
      })
    )
  }
}

export const useOptimisticDeleteReservation = () => {
  const queryClient = useQueryClient()

  return (registryId: number, reservationId: number) => {
    queryClient.setQueryData(
      [FETCH_RESERVATIONS_KEY, registryId],
      (currentReservations?: Record<number, Reservation>) => {
        if (!currentReservations) return undefined

        const newReservations = { ...currentReservations }
        delete newReservations[reservationId]

        return newReservations
      }
    )
  }
}

export default useGetReservations
