import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchMinimalRegItems,
  FETCH_MINIMAL_REG_ITEMS_KEY,
} from 'src/api/queries'
import { RegItemMinimal } from 'src/types/regItem'

const useGetRegItemsMinimal = (registryId: number | undefined) => {
  const regItemsMinimalQuery = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_MINIMAL_REG_ITEMS_KEY, registryId],
    queryFn: () => fetchMinimalRegItems(registryId),
  })
  const regItems: RegItemMinimal[] = regItemsMinimalQuery.data || []

  return { regItems, regItemsMinimalQuery }
}

export const useInvalidateRegItemsMinimal = (registryId: number) => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries([FETCH_MINIMAL_REG_ITEMS_KEY, registryId])
}

export const useOptimisticAddRegItemMinimal = () => {
  const queryClient = useQueryClient()

  return (registryId: number, newRegItem: RegItemMinimal) => {
    queryClient.setQueryData(
      [FETCH_MINIMAL_REG_ITEMS_KEY, registryId],
      (currentRegItems?: RegItemMinimal[]) => [
        ...(currentRegItems || []),
        newRegItem,
      ]
    )
  }
}

export const useOptimisticDeleteRegItemMinimal = () => {
  const queryClient = useQueryClient()

  return (registryId: number, regItemId: number) => {
    queryClient.setQueryData(
      [FETCH_MINIMAL_REG_ITEMS_KEY, registryId],
      (currentRegItems?: RegItemMinimal[]) => {
        if (!currentRegItems) return undefined

        return currentRegItems.filter((ri) => ri.id !== regItemId)
      }
    )
  }
}

export default useGetRegItemsMinimal
