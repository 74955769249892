import RetailerReturn from './retailer-return'
import { RETURNS_ETSY_URL } from 'shared/constants'

const EtsyReturn = ({ orderNum, giftGiverName, giftGiverEmail }) => {
  return (
    <RetailerReturn
      storeName="Etsy"
      returnUrl={RETURNS_ETSY_URL}
      orderNum={orderNum}
      giftGiverName={giftGiverName}
      giftGiverEmail={giftGiverEmail}
    />
  )
}

export default EtsyReturn
