import React from 'react'
import classNames from 'classnames'

import css from './Header.styles.scss'

import { HeaderProps } from './Header.types'

const Header: React.FC<HeaderProps> = ({ className }) => {
  const HeaderClasses = classNames(css.Header, className)

  return (
    <div className={HeaderClasses}>
      <h1 className={classNames(css.Header__title)}>
        Add items to your registry
      </h1>
      <h2 className={classNames(css.Header__subtitle)}>
        Put anything from any store (big or small) onto your registry.
      </h2>
    </div>
  )
}

export default Header
