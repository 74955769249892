import React from 'react'

interface TikTokProps {
  className?: string
}

const TikTok: React.FC<TikTokProps> = ({ className }: TikTokProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>TikTok Icon</title>
    <path
      d="M11.5165 2C11.5165 1.58579 11.8523 1.25 12.2665 1.25H15.7044C15.9042 1.25 16.0957 1.32971 16.2365 1.47144C16.3773 1.61318 16.4557 1.80523 16.4544 2.005C16.4527 2.24873 16.4735 2.49209 16.5163 2.73202C16.6173 3.26869 16.8265 3.7792 17.1311 4.23245C17.4362 4.68653 17.8308 5.0736 18.2906 5.37002L18.2976 5.37453C18.9514 5.8063 19.7177 6.03624 20.5011 6.03563C20.7002 6.03547 20.8911 6.11442 21.0318 6.25509C21.1726 6.39576 21.2517 6.58661 21.2517 6.78563V10.2C21.2517 10.6136 20.9169 10.9491 20.5033 10.95C19.0908 10.953 17.7044 10.6221 16.4544 9.99245V15.6498C16.4539 17.0962 16.0119 18.5082 15.1873 19.6966C14.3627 20.8849 13.195 21.7932 11.8403 22.2999C10.4855 22.8066 9.00834 22.8876 7.60631 22.5321C6.20429 22.1765 4.94424 21.4013 3.99471 20.3103C3.04517 19.2192 2.4514 17.8642 2.29282 16.4265C2.13424 14.9888 2.41841 13.5369 3.10731 12.2651C3.79622 10.9933 4.85702 9.96217 6.14785 9.30958C7.43867 8.65699 8.89798 8.41408 10.3306 8.61334C10.7013 8.66491 10.9773 8.9819 10.9773 9.35619V12.8683C10.9773 13.1071 10.8635 13.3317 10.6709 13.473C10.4783 13.6143 10.2299 13.6554 10.0021 13.5836C9.56696 13.4467 9.0997 13.4507 8.667 13.5951C8.23429 13.7395 7.85828 14.0169 7.59265 14.3878C7.32702 14.7586 7.18536 15.2039 7.18788 15.6601C7.1904 16.1162 7.33698 16.5599 7.6067 16.9278C7.87641 17.2957 8.25546 17.569 8.68973 17.7086C9.12401 17.8482 9.59129 17.8471 10.0249 17.7053C10.4584 17.5635 10.8361 17.2884 11.104 16.9192C11.3719 16.5499 11.5163 16.1055 11.5165 15.6494V2Z"
      fill="#4A2143"
    />
  </svg>
)

export default TikTok
