import { useParams } from 'react-router-dom-v5-compat'
import { withContextualizedTracking, track } from 'lib/analytics'
import { useGuidesConfig } from '../../../utils/useQueries'
import PostViewAdapter from '../../../components/PostViewAdapter/PostViewAdapter'

const EmbeddedSlugRouter: React.FC = () => {
  const { slug } = useParams()
  const { emailUrls } = useGuidesConfig()

  return <>{slug && <PostViewAdapter emailUrls={emailUrls} slug={slug} />}</>
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.GUIDE,
})(EmbeddedSlugRouter)
