import { RegItem } from 'src/types/regItem'
import { Lock } from 'baby-design/icons'
import { RegItemCategory } from 'src/types/regItemCategory'
import { RegItemBadge } from '../components/RegItemCard/RegItemCard.types'

export const PRIVATE_ITEM_BADGE: RegItemBadge = {
  variant: 'general',
  fill: 'theme-sand',
  label: 'Private',
  icon: <Lock />,
}

export const getRegItemBadges = (
  regItem: RegItem,
  category?: RegItemCategory
) => {
  const badges = []
  if (regItem.private && category && !category.isPrivate) {
    badges.push(PRIVATE_ITEM_BADGE)
  }
  return badges
}
