import { TextLink } from 'baby-design'
import { bowserFlags } from 'lib/bowser'
import {
  InstallInstructionCopyByBrowser,
  InstallInstructionsByBrowserProps,
} from './InstallInstructionsByBrowser.types'
import AlternateInstructions from '../AlternateInstructions/AlternateInstructions'
import css from './InstallInstructionsByBrowser.styles.scss'

const CLOUDINARY_BASE_URL = '//res.cloudinary.com/babylist/image/upload/q_auto'

const firefoxWindowsInstructions = (
  <span>
    At the top right of your Firefox browser, click the menu icon{' '}
    <img
      alt="Menu"
      height="18"
      src={`${CLOUDINARY_BASE_URL}/v1619102794/icons/firefox_hamburger_menu_button.png`}
      width="18"
    />{' '}
    and choose <b>Bookmarks</b> If it says "Hide bookmarks toolbar" you're all
    set.
  </span>
)
const firefoxMacInstructions = (
  <span>
    In Firefox's menu, go to{' '}
    <b>
      View {'>'} Toolbars {'>'} Bookmarks Toolbar
    </b>{' '}
    and make sure there's a checkmark next to <b>Always Show</b>.
  </span>
)
const safariInstructions = (
  <span>
    In Safari's menu, go to <b>View</b> (if it says "Hide Favorites Bar" you're
    all set).
  </span>
)
const edgeInstructions = (
  <span>
    In Edge's menu, go to <b>View</b> and make sure there's a checkmark next to{' '}
    <b>Always Show Favorites Bar</b>
  </span>
)
const defaultInstructions = (
  <span>Check that your browser&apos;s Favorites bar is showing.</span>
)

const firefoxInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-firefox-2023`
const safariInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-safari-2023`
const edgeInstallationGif = `${CLOUDINARY_BASE_URL}/install-bookmarklet/install-bookmarklet-edge-2023`

const INSTALL_INSTRUCTION_COPY_BY_BROWSER: InstallInstructionCopyByBrowser = {
  Firefox: {
    bookmarksBarTitle: 'Bookmarks',
    instructions: bowserFlags.windows
      ? firefoxWindowsInstructions
      : firefoxMacInstructions,
    installationGif: firefoxInstallationGif,
  },
  Safari: {
    bookmarksBarTitle: 'Favorites',
    instructions: safariInstructions,
    installationGif: safariInstallationGif,
  },
  'Microsoft Edge': {
    bookmarksBarTitle: 'Favorites',
    instructions: edgeInstructions,
    installationGif: edgeInstallationGif,
  },
  Default: {
    bookmarksBarTitle: 'Bookmarks',
    instructions: defaultInstructions,
    installationGif: firefoxInstallationGif,
  },
}

const InstallInstructionsByBrowser: React.FC<
  InstallInstructionsByBrowserProps
> = ({ browser, setBrowser }) => {
  const browserKey = Object.keys(INSTALL_INSTRUCTION_COPY_BY_BROWSER).includes(
    browser
  )
    ? browser
    : 'Default'
  return (
    <>
      <div className={css.installInstructions}>
        <div className={css.instructionItem}>
          <div className={css.instructionHeading}>
            <div className={css.instructionNumber}>1</div>
            <div className={css.instructionHeadingText}>
              Make sure your{' '}
              {
                INSTALL_INSTRUCTION_COPY_BY_BROWSER[browserKey]
                  .bookmarksBarTitle
              }{' '}
              bar is visible
            </div>
          </div>
          <p className={css.instructionBody}>
            {INSTALL_INSTRUCTION_COPY_BY_BROWSER[browserKey].instructions}
          </p>
        </div>

        <div className={css.instructionItem}>
          <div className={css.instructionHeading}>
            <div className={css.instructionNumber}>2</div>
            <div className={css.instructionHeadingText}>
              Click and drag the Add to Babylist button to your{' '}
              {
                INSTALL_INSTRUCTION_COPY_BY_BROWSER[browserKey]
                  .bookmarksBarTitle
              }{' '}
              bar
            </div>
          </div>
          <div className={css.instructionBody}>
            <img
              alt="Instructions for browser"
              className={css.instructionsGif}
              src={
                INSTALL_INSTRUCTION_COPY_BY_BROWSER[browserKey].installationGif
              }
            />
          </div>
        </div>

        <div className={css.instructionItem}>
          <div className={css.instructionHeading}>
            <div className={css.instructionNumber}>3</div>
            <div className={css.instructionHeadingText}>Now try it out!</div>
          </div>
          <p className={css.instructionBody}>
            Visit Amazon to add the{' '}
            <TextLink
              underline
              size="lg"
              onClick={() =>
                window.open(
                  'https://www.amazon.com/Vulli-616324-Sophie-la-Girafe/dp/B000IDSLOG?tag=babyli-20'
                )
              }
            >
              Sophie the Giraffe Teether
            </TextLink>{' '}
            to your registry using your new Babylist button. You can easily
            remove this later if you choose.
          </p>
        </div>
      </div>
      {browserKey === 'Default' && (
        <AlternateInstructions setBrowser={setBrowser} />
      )}
    </>
  )
}

export default InstallInstructionsByBrowser
