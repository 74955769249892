import PropTypes from 'prop-types'
import { Input } from 'components/forms'

const RegistryInfoFieldset = ({ className, title, urlSlug, description }) => (
  <fieldset className={className}>
    <Input
      {...title}
      id="registry_title"
      label="Registry Title"
      type="text"
      error={title.touched ? title.error : null}
    />

    <Input
      {...urlSlug}
      style={{ paddingLeft: 0, marginLeft: 0 }}
      id="registry_url"
      label="Registry Url"
      addonBefore="babylist.com/list/"
      addonStyle={{ paddingRight: 0 }}
      type="text"
      error={urlSlug.touched ? urlSlug.error : null}
    />

    <Input
      {...description}
      style={{ minHeight: 140 }}
      id="registry_greeting"
      label="Registry Greeting"
      placeholder="Use this space to write a note to your friends and family."
      type="textarea"
      error={description.touched ? description.error : null}
    />
  </fieldset>
)

RegistryInfoFieldset.propTypes = {
  title: PropTypes.object.isRequired,
  urlSlug: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
}

export default RegistryInfoFieldset
