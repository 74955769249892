import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { MetaTags as Component } from './MetaTags'

export const MetaTags = () => {
  const { productId } = useParams()
  const { data: genericProductData } = ProductsAPI.useShow(productId)

  return <Component genericProduct={genericProductData} />
}
