import { RegItem } from 'src/types/regItem'
import { Reservation } from 'src/types/reservation'
import { useOptimisticAddRegItemMinimal } from '../useGetRegItemsMinimal'
import { useOptimisticDeleteReservation } from '../useGetReservations'
import { useOptimisticDeleteVisitorReservation } from '../useGetVisitorReservations'

export type OnDeleteReservationFunc = (
  reservation: Reservation,
  regItem: RegItem
) => void

export const useGetOnDeleteReservation = () => {
  const optimisticDeleteReservation = useOptimisticDeleteReservation()
  const optimisticDeleteVisitorReservation =
    useOptimisticDeleteVisitorReservation()
  const optimisticAddRegItemMinimal = useOptimisticAddRegItemMinimal()

  const onDeleteReservation: OnDeleteReservationFunc = (
    reservation: Reservation,
    regItem: RegItem
  ) => {
    if (reservation) {
      if (regItem.quantityNeeded === 0) {
        optimisticAddRegItemMinimal(regItem.registryId, regItem)
      }
      optimisticDeleteReservation(regItem.registryId, reservation.id)
      optimisticDeleteVisitorReservation(regItem.registryId, reservation.id)
    }
  }

  return onDeleteReservation
}
