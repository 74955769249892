import classNames from 'classnames'
import React, { useEffect } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { QuantityPicker } from 'baby-design'

import useUpdateCartedRegItem from 'src/routes/(subdomains)/(my)/[slug]/hooks/useUpdateCartItems/useUpdateCartedRegItem'
import {
  getCartedQuantity,
  getCartedType,
} from 'src/routes/(subdomains)/(my)/[slug]/utils/cartedRegItemHelpers/cartedRegItemHelpers'
import useGetCart from 'src/routes/(subdomains)/(my)/[slug]/hooks/useGetCart'
import { InCartBadgeProps } from './InCartBadge.types'
import css from './InCartBadge.styles.scss'

const InCartBadge: React.FC<InCartBadgeProps> = ({ regItem }) => {
  const [displayQuantity, setDisplayQuantity] = React.useState(0)
  const { cart } = useGetCart()
  const quantityInCart = getCartedQuantity(regItem, cart)
  const cartedType = getCartedType(regItem, cart)
  const {
    handleDecreaseQuantity,
    handleIncreaseQuantity,
    handleRemoveFromCart,
  } = useUpdateCartedRegItem(regItem, cart)

  useEffect(() => {
    setDisplayQuantity(quantityInCart)
  }, [quantityInCart])

  if (quantityInCart < 1) return null

  const WrapperClassNames = classNames({
    [css.InCartBadge]: true,
    [css.InCartBadge__ReservationBadge]: cartedType === 'Reservation',
  })

  return (
    <div className={WrapperClassNames} data-testid="InCartBadge">
      {cartedType === 'Reservation' && (
        <>
          <span className="sr-only">
            {`You have ${quantityInCart} reserved`}
          </span>
          <div aria-hidden="true" className={css.InCartBadge__ReservationBadge}>
            {quantityInCart}
          </div>
        </>
      )}
      {cartedType === 'CartItem' && (
        <>
          <span className="sr-only">
            {`You have ${quantityInCart} in your cart`}
          </span>
          <QuantityPicker
            className={css.InCartBadge__QuantityPicker}
            max={regItem.quantityNeeded || 10}
            size="sm"
            type="cart"
            value={displayQuantity}
            variant="outline"
            onMinusClick={() => {
              setDisplayQuantity(displayQuantity - 1)
              handleDecreaseQuantity(displayQuantity - 1)
            }}
            onPlusClick={() => {
              setDisplayQuantity(displayQuantity + 1)
              handleIncreaseQuantity(displayQuantity + 1)
            }}
            onTrashClick={handleRemoveFromCart}
          />
        </>
      )}
    </div>
  )
}

export default InCartBadge
