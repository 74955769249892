import RetailerReturn from './retailer-return'
import { RETURNS_AMAZON_URL } from 'shared/constants'

const validAmazonOrderNumber = (orderNum) => {
  return orderNum && /\d{3}-?\d{7}-?\d{7}/.test(orderNum)
}

const AmazonReturn = ({ orderNum, giftGiverName, giftGiverEmail }) => {
  return (
    <RetailerReturn
      storeName="Amazon"
      returnUrl={
        validAmazonOrderNumber(orderNum)
          ? `https://www.amazon.com/spr/returns/cart?_encoding=UTF8&orderId=${orderNum}`
          : RETURNS_AMAZON_URL
      }
      orderNum={orderNum}
      giftGiverName={giftGiverName}
      giftGiverEmail={giftGiverEmail}
    />
  )
}

export default AmazonReturn
