import { useEffect } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import classNames from 'classnames'
import { GuestRegistryHeader } from 'registry/components/GuestRegistryHeader/GuestRegistryHeader'
import { useDisplayRegistryFeedbackModal } from 'shared/RegistryFeedbackModal/RegistryFeedbackModal.utils'
import { track } from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import RegistryHeaderImage from 'src/components/RegistryHeaderImage'
import { datadogRumAsync } from 'src/utils/datadogRumAsync/datadogRumAsync'
import { DD_RUM_CUSTOM_TIMINGS } from 'src/constants'
import RegistryGiftGiverPageSeo from '../RegistryGiftGiverPageSeo/RegistryGiftGiverPageSeo'
import RegistryBackBannerPortal from '../RegistryBackBannerPortal/RegistryBackBannerPortal'
import useGetRegistry from '../../hooks/useGetRegistry'
import useGetRegItemsMinimal from '../../hooks/useGetRegItemsMinimal'
import css from './RegistryGiftGiverPage.styles.scss'
import useGetSortFilterOptions from '../../hooks/useGetSortFilterOptions'
import useGetReservations from '../../hooks/useGetReservations'
import { useAppliedSortsAndFiltersContext } from '../../contexts'
import {
  getPreviewFilterResultsClosure,
  sortAndFilterRegItems,
  useSetLocalStoragePageVisitCount,
  useTrackRegistryViewed,
} from './RegistryGiftGiverPage.utils'
import RegistryStickyControlsBar from '../RegistryStickyControlsBar/RegistryStickyControlsBar'
import RegistryCategories from '../RegistryCategories/RegistryCategories'
import RegistryBackToTop from '../RegistryBackToTop'
import useGetVisitorReservations from '../../hooks/useGetVisitorReservations'
import { RegistryGiftGiverPageLegacyAdapter } from './RegistryGiftGiverPageLegacyAdapter'
import useGiftGiverReminders from '../../hooks/useGiftGiverReminders/useGiftGiverReminders'
import ShippingAddressButton from '../ShippingAddressButton/ShippingAddressButton'
import useGetCart from '../../hooks/useGetCart'

export const GIFT_GIVER_PAGE_VISIT_LOCAL_STORAGE_KEY =
  'GiftGiverPageRegistryOwnerVisitCount'

const RegistryGiftGiverPage = () => {
  const { slug } = useParams()
  const cleanedSlug = slug?.toLowerCase()
  const { registry, registryQuery } = useGetRegistry(cleanedSlug)
  const { regItems, regItemsMinimalQuery } = useGetRegItemsMinimal(registry.id)
  const { sortFilterOptions } = useGetSortFilterOptions(registry.id)
  const { reservations, reservationsQuery } = useGetReservations(registry.id)
  const { visitorReservations, visitorReservationsQuery } =
    useGetVisitorReservations(registry.id)
  const { appliedSortsAndFilters } = useAppliedSortsAndFiltersContext()
  const previewFilterResults = getPreviewFilterResultsClosure(regItems)
  const { cart } = useGetCart()
  const anyCartItems = Boolean(cart?.cart?.items?.length)
  useGiftGiverReminders({
    registry,
    anyVisitorReservations: visitorReservations.length > 0,
    anyCartItems,
  })
  const sortedAndFilteredRegItems = sortAndFilterRegItems(
    regItems,
    appliedSortsAndFilters
  )
  const [currentUser] = useCurrentUser()
  const isRegistryOwner = registry.ownerId === currentUser?.id
  const currentCategoryIsAll =
    appliedSortsAndFilters.filters?.category === 'All'
  const { flagValue: isRegistryFeedbackModalEnabled } = useFeatureFlag(
    '24f_ff_regex_ggx_registry_feedback_modal_web',
    false
  )

  const pageVisitCount = useSetLocalStoragePageVisitCount(
    isRegistryFeedbackModalEnabled,
    isRegistryOwner
  )

  const shouldShowModal =
    !!isRegistryFeedbackModalEnabled && isRegistryOwner && pageVisitCount === 2

  useDisplayRegistryFeedbackModal(
    registryQuery.data,
    shouldShowModal,
    track.EventLocation.REGISTRY_MINUS_GIFT_GIVER_VIEW
  )

  useTrackRegistryViewed(registry)

  const { glowupEnabled } = registry.headerDetails

  const registryGiftGiverPageClasses = classNames(css.RegistryGiftGiverPage, {
    [css.RegistryGiftGiverPage__glowup]: glowupEnabled,
  })
  const headerAndContentClasses = classNames({
    [css.RegistryGiftGiverPage__HeaderAndContent__glowup]: glowupEnabled,
  })
  const contentClasses = classNames(css.RegistryGiftGiverPage__Content, {
    [css.RegistryGiftGiverPage__Content__glowup]: glowupEnabled,
  })

  const isLoadingComplete =
    !reservationsQuery.isLoading &&
    !regItemsMinimalQuery.isLoading &&
    !visitorReservationsQuery.isLoading

  // Add custom timings for datadog RUM
  useEffect(() => {
    if (isLoadingComplete) {
      datadogRumAsync({
        callback: (DD_RUM) => {
          DD_RUM.addTiming(
            DD_RUM_CUSTOM_TIMINGS.PageContentAppearsLoaded,
            Date.now()
          )
          DD_RUM.addTiming(
            DD_RUM_CUSTOM_TIMINGS.PageContentFullyLoaded,
            Date.now()
          )
        },
      })
    }
  }, [isLoadingComplete])

  return (
    <RegistryGiftGiverPageLegacyAdapter registry={registry}>
      <div
        className={registryGiftGiverPageClasses}
        data-testid="RegistryGiftGiverPage"
      >
        <RegistryBackBannerPortal registry={registry} />

        <RegistryGiftGiverPageSeo registry={registry} slug={cleanedSlug} />

        {glowupEnabled && <RegistryHeaderImage registry={registry} />}

        <div className={headerAndContentClasses}>
          {registry.title && (
            <div className={css.RegistryGiftGiverPage__Header}>
              <GuestRegistryHeader
                arrivalDate={registry.family?.arrivalDate}
                note={registry.description}
                photoUrl={registry.photoUrl}
                registry={registry}
                title={registry.title}
              />
            </div>
          )}

          <div className={contentClasses}>
            <RegistryStickyControlsBar
              firstNames={registry.firstNames}
              previewFilterResults={previewFilterResults}
              regItems={regItems}
              reservations={reservations}
              sortFilterOptions={sortFilterOptions}
            />

            <RegistryCategories
              glowupEnabled={glowupEnabled}
              isLoadingComplete={isLoadingComplete}
              regItems={sortedAndFilteredRegItems}
              registry={registry}
              reservations={reservations}
              visitorReservations={visitorReservations}
            />
          </div>
        </div>

        {currentCategoryIsAll && <RegistryBackToTop variant="bottom" />}

        <RegistryBackToTop variant="floating" />

        <ShippingAddressButton registry={registry} />
      </div>
    </RegistryGiftGiverPageLegacyAdapter>
  )
}

export default RegistryGiftGiverPage
