/* eslint-disable */
import { Component } from 'react'
import { connect } from 'react-redux'
import { datadogRumAsync } from 'src/utils/datadogRumAsync/datadogRumAsync'
import { DD_RUM_CUSTOM_TIMINGS } from 'src/constants'
import {
  addFilter,
  fetchCategorizedRegItems,
  fetchCategories,
  fetchReservedRegItems,
  setFilters,
  setIsOrganizing,
} from '../actions'
import { REG_ITEM_RESERVATION_STATUS_AVAILABLE } from '../actions/reg-items-actions'
import {
  getCategorizedRegItems,
  getCategorizedRegItemsCount,
  getDistinctStores,
  getIsAllCategoriesCollapsed,
  isPageReady,
  getGroupGiftRegItemsCount,
  getIsContributorView,
  getIsFetchingCategorizedRegItems,
  getIsPartialSetOfRegItems,
  getIsOrganizing,
  getIsRegistryDiscountView,
  getPopulatedCategories,
  getRegItemsCount,
  getReservedRegItems,
  getInitialRegItemsCount,
  getFavoriteRegItemsCount,
  getReservationsByCurrentVisitor,
  getFilters,
  getRegistryCategories,
  getReservedRegItemsByCurrentVisitor,
  getIsMobile,
  getIsShippingAddressButtonVisible,
} from '../reducers'
import {
  openContactInfoModal,
  openMobileFiltersModal,
  openGiftTrackerModal,
} from '../modals/actions'

// registryPageDataFetcher unmounts and remounts several times during its lifecycle and this happens
// while some API calls are actively in flight. As a result, using ref or state within the component to
// track whether the custom timings have been fired will not work as expected. Instead, we use a global
// object to track the state of the custom timings. This isn't ideal, but it's cleaner than pushing this
// into the redux store considering its a temporary one-off situation.
const CustomTimingsState = {
  pageContentAppearsLoadedFired: false,
  pageContentFullyLoadedFired: false,
}

export const registryPageDataFetcher = (WrappedComponent) => {
  class RegistryPageDataFetcher extends Component {
    componentDidMount() {
      const {
        isFetchingCategorizedRegItems,
        fetchCategorizedRegItems,
        fetchReservedRegItems,
        isContributorView,
      } = this.props
      if (!isFetchingCategorizedRegItems) {
        if (!isContributorView) {
          // Fetching for available items to match the filter set in GuestFilterBar useEffect
          fetchCategorizedRegItems({ fetchAvailableOnly: true, limit: 3 }).then(
            () => {
              if (!CustomTimingsState.pageContentAppearsLoadedFired) {
                datadogRumAsync({
                  callback: (DD_RUM) => {
                    DD_RUM.addTiming(
                      DD_RUM_CUSTOM_TIMINGS.PageContentAppearsLoaded,
                      Date.now()
                    )
                    CustomTimingsState.pageContentAppearsLoadedFired = true
                  },
                })
              }

              fetchCategorizedRegItems({ onlyRegItems: true })
            }
          )
          fetchReservedRegItems({ isGuestView: true })
        } else {
          fetchCategorizedRegItems()
        }
      }
    }

    componentDidUpdate(prevProps) {
      if (
        !CustomTimingsState.pageContentFullyLoadedFired &&
        prevProps.isPartialSetOfRegItems &&
        !this.props.isPartialSetOfRegItems
      ) {
        datadogRumAsync({
          callback: (DD_RUM) => {
            DD_RUM.addTiming(
              DD_RUM_CUSTOM_TIMINGS.PageContentFullyLoaded,
              Date.now()
            )
            CustomTimingsState.pageContentFullyLoadedFired = true
          },
        })
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const isRegistryDiscountView = getIsRegistryDiscountView(state)

    return {
      categories: getRegistryCategories(state),
      categorizedRegItems: getCategorizedRegItems(
        state,
        isRegistryDiscountView
      ),
      filters: getFilters(state),
      filteredRegItemsCount: getCategorizedRegItemsCount(state),
      isAllCategoriesCollapsed: getIsAllCategoriesCollapsed(state),
      isContributorView: getIsContributorView(state),
      isFetchingCategorizedRegItems: getIsFetchingCategorizedRegItems(state),
      isPartialSetOfRegItems: getIsPartialSetOfRegItems(state),
      isOrganizing: getIsOrganizing(state),
      isPageReady: isPageReady(state),
      isRegistryDiscountView,
      populatedCategories: getPopulatedCategories(state),
      regItemsCount: getRegItemsCount(state) || getInitialRegItemsCount(state),
      reservedRegItems: getReservedRegItems(state),
      favoriteRegItemsCount: getFavoriteRegItemsCount(state),
      groupGiftsRegItemsCount: getGroupGiftRegItemsCount(state),
      reservationsByCurrentVisitor: getReservationsByCurrentVisitor(state),
      reservedRegItemsByCurrentVisitor:
        getReservedRegItemsByCurrentVisitor(state),
      stores: getDistinctStores(state),
      isMobile: getIsMobile(state),
      isShippingAddressButtonVisible: getIsShippingAddressButtonVisible(state),
      registry: state.registry,
    }
  }

  return connect(mapStateToProps, {
    fetchCategorizedRegItems,
    fetchCategories,
    fetchReservedRegItems,
    openMobileFiltersModal,
    addFilter,
    setFilters,
    setIsOrganizing,
    openContactInfoModal,
    openGiftTrackerModal,
  })(RegistryPageDataFetcher)
}
