import { filterPostsByTopicSlug } from 'hello_baby/lib'
import TopicSection from 'hello_baby/components/TopicSection'
import { usePosts, useTopic } from '../../utils/useQueries'

interface ParentingViewProps {
  slug: string
}

const NUM_VISIBLE_POSTS = 4

const ParentingView = ({ slug }: ParentingViewProps) => {
  const subTopics = useTopic(slug)?.children || []

  const posts = usePosts()

  return (
    <>
      {subTopics.map((topic: Topic) => {
        const filteredPosts = filterPostsByTopicSlug(posts, topic.slug)
        return (
          <TopicSection
            key={`topic_section_${topic.slug}`}
            posts={filteredPosts.slice(0, NUM_VISIBLE_POSTS)}
            showAllButton={filteredPosts.length > NUM_VISIBLE_POSTS}
            showTopics={false}
            topic={{ name: topic.name, slug: topic.slug }}
          />
        )
      })}
    </>
  )
}

export default ParentingView
