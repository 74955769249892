import { PureComponent } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { InfoButton } from 'components/buttons'
import { registryPath } from 'lib/urls'
import NewCategoryModal from '../organize/new-category-modal'

export default class OrganizeHeader extends PureComponent {
  state = {
    showNewCategoryModal: false,
  }

  showNewCategoryModal = () => this.setState({ showNewCategoryModal: true })

  hideNewCategoryModal = () => this.setState({ showNewCategoryModal: false })

  render() {
    const { registryUrlSlug } = this.props

    return (
      <>
        <div className={classNames('mtl mtxxl-md mbl flex center')}>
          <div className="hidden-xs h6 pan man">
            <strong>Drag to reorder items</strong> within a category.
            <br /> <strong>Use the dropdown menu to move items</strong> to a new
            category.
          </div>
          <div className="flex row justify-end center flex-grow">
            <InfoButton
              empty
              pill
              className="mrm"
              size="small"
              onClick={this.showNewCategoryModal}
            >
              + New Category
            </InfoButton>
            <Link to={registryPath(registryUrlSlug)}>
              <InfoButton empty pill size="small">
                Done Organizing
              </InfoButton>
            </Link>
          </div>
        </div>
        <NewCategoryModal
          show={this.state.showNewCategoryModal}
          onHide={this.hideNewCategoryModal}
        />
      </>
    )
  }
}
