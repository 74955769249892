import { FC } from 'react'
import classNames from 'classnames'
import { Button, Image } from 'baby-design'

import css from './ExperienceCardFull.styles.scss'

interface ExperienceCardFullProps {
  className?: string
  image: {
    url: string
    altText: string
  }
  heading: string
  subheading: string
  description: string
  ctaHref: string
}

const ExperienceCardFull: FC<ExperienceCardFullProps> = ({
  className,
  image: { url, altText },
  subheading,
  heading,
  description,
  ctaHref,
}) => (
  <div className={classNames(css.ExperienceCardFull, className)}>
    <div className={css.ExperienceCardFull__imageContainer}>
      <Image
        alt={altText}
        className={css.ExperienceCardFull__image}
        src={url}
      />
    </div>
    <div className={css.ExperienceCardFull__content}>
      <div className={css.ExperienceCardFull__textContainer}>
        <div className={css.ExperienceCardFull__headingContainer}>
          <p className={css.ExperienceCardFull__subheading}>{subheading}</p>
          <h3 className={css.ExperienceCardFull__heading}>{heading}</h3>
        </div>
        <p className={css.ExperienceCardFull__description}>{description}</p>
      </div>
      <Button
        className={css.ExperienceCardFull__ctaMobile}
        href={ctaHref}
        size="sm"
        target="_blank"
        variant="primary"
      >
        Visit now
      </Button>
      <Button
        className={css.ExperienceCardFull__ctaDesktop}
        href={ctaHref}
        size="md"
        target="_blank"
        variant="primary"
      >
        Visit now
      </Button>
    </div>
  </div>
)

export default ExperienceCardFull
