import { REG_ITEM_TYPE_CROWDFUND } from 'src/constants'
import { RegItem, RegItemMinimal, RegItemType } from 'src/types/regItem'

export type RegItemTypeAugmented = RegItemType | typeof REG_ITEM_TYPE_CROWDFUND

const getRegItemType = (
  regItem?: RegItemMinimal | RegItem
): RegItemTypeAugmented | undefined => {
  if (!regItem) return undefined

  let regItemType: RegItemTypeAugmented | undefined = regItem.itemType

  // Cash funds are deprecated, we now use the term crowdfunds internally but the
  // itemType is still technically a product because that is how we associate cash funds
  // with reg items.
  //
  // This util can be removed if we clean up the backend to send the correct
  // itemType value for crowdfunds.
  if (regItem.isCrowdfund) {
    regItemType = REG_ITEM_TYPE_CROWDFUND
  }

  return regItemType
}

export default getRegItemType
