// eslint-disable-next-line no-redeclare
/* global PubSub:true */
import { Component } from 'react'
import { PropTypes } from 'prop-types'

import { SHOW_GIFT_RETURN_MODAL } from 'shared/constants'

import { TwoColumnResponsiveLayout } from 'components/presentation'
import { Modal, ModalBody } from 'components/modal'
import { contactUsPath } from 'lib/urls'

import RegItem from 'cart/components/reg-item'
import UnknownReturn from './components/retailer-returns/unknown-return'
import BabylistReturnRequestForm from './components/babylist-return-request-form'
import BabylistUnacceptableReturn from './components/babylist-unacceptable-return'
import AmazonReturn from './components/retailer-returns/amazon-return'
import WalmartReturn from './components/retailer-returns/walmart-return'
import TargetReturn from './components/retailer-returns/target-return'
import BuyBuyBabyReturn from './components/retailer-returns/buybuybaby-return'
import EtsyReturn from './components/retailer-returns/etsy-return'

const ReturnComponentForStore = ({
  reservationToken,
  storeName,
  orderNum,
  daysSincePurchase,
  giftGiverName,
  giftGiverEmail,
  onSuccess,
  onFailure,
}) => {
  switch (storeName) {
    case 'Babylist':
      if (daysSincePurchase <= 90) {
        return (
          <BabylistReturnRequestForm
            onSuccess={onSuccess}
            onFailure={onFailure}
            reservationToken={reservationToken}
          />
        )
      }
      return (
        <BabylistUnacceptableReturn daysSincePurchase={daysSincePurchase} />
      )

    case 'Amazon':
      return (
        <AmazonReturn
          orderNum={orderNum}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )

    case 'Walmart':
      return (
        <WalmartReturn
          orderNum={orderNum}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )

    case 'Target':
      return (
        <TargetReturn
          orderNum={orderNum}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )

    case 'Etsy':
      return (
        <EtsyReturn
          orderNum={orderNum}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )

    case ((storeName && storeName.match(/buy\s*buy\s*baby/i)) || {}).input:
      return (
        <BuyBuyBabyReturn
          orderNum={orderNum}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )

    default:
      return (
        <UnknownReturn
          orderNum={orderNum}
          storeName={storeName}
          giftGiverName={giftGiverName}
          giftGiverEmail={giftGiverEmail}
        />
      )
  }
}

ReturnComponentForStore.propTypes = {
  reservationToken: PropTypes.string,
  storeName: PropTypes.string.isRequired,
  orderNum: PropTypes.string.isRequired,
  daysSincePurchase: PropTypes.number,
  giftGiverName: PropTypes.string.isRequired,
  giftGiverEmail: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
}

ReturnComponentForStore.defaultProps = {
  reservationToken: null,
  daysSincePurchase: null,
}

export default class GiftReturnModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      reservationToken: null,
      storeName: null,
      orderNum: null,
    }
  }

  componentDidMount() {
    PubSub.subscribe(
      SHOW_GIFT_RETURN_MODAL,
      ({
        reservationToken,
        storeName,
        orderNum,
        regItem,
        giftGiverName,
        giftGiverEmail,
        daysSincePurchase,
      }) => {
        this.setState({
          show: true,
          reservationToken,
          storeName,
          orderNum,
          regItem,
          giftGiverName,
          giftGiverEmail,
          daysSincePurchase,
        })
      }
    )
  }

  onSuccess = () => {
    PubSub.publish('flashMessage', {
      variant: 'success',
      message:
        "We've received your return request! A Happiness Hero will get back to you shortly!",
    })
    this.hideModal()
  }

  onFailure = () => {
    PubSub.publish('flashMessage', {
      variant: 'error',
      message: `We were unable to process your request. You can try again or <a href=${contactUsPath}>contact a Happiness Hero directly</a>!`,
    })
    this.hideModal()
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  render() {
    const {
      show,
      regItem,
      reservationToken,
      storeName,
      orderNum,
      daysSincePurchase,
      giftGiverEmail,
      giftGiverName,
    } = this.state

    if (!show) return null
    return (
      <Modal
        size="large"
        onHide={this.hideModal}
        show={show}
        title="Returning a gift? Let us help!"
      >
        <ModalBody>
          <TwoColumnResponsiveLayout key="GiftReturnModal">
            <RegItem className="mbl" regItem={regItem} />
            <ReturnComponentForStore
              onSuccess={this.onSuccess}
              onFailure={this.onFailure}
              reservationToken={reservationToken}
              storeName={storeName}
              orderNum={orderNum}
              daysSincePurchase={daysSincePurchase}
              giftGiverEmail={giftGiverEmail}
              giftGiverName={giftGiverName}
            />
          </TwoColumnResponsiveLayout>
        </ModalBody>
      </Modal>
    )
  }
}
