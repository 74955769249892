import React from 'react'
import { RegistryHeaderImageProps } from './RegistryHeaderImage.types'
import styles from './RegistryHeaderImage.styles.scss'

const RegistryHeaderImage: React.FC<RegistryHeaderImageProps> = ({
  registry,
}) => {
  const { headerImageUrl } = registry.headerDetails
  return (
    <div
      className={styles.RegistryHeaderImage}
      data-testid="RegistryHeaderImage"
      style={{
        backgroundImage: `url(${headerImageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}

export default RegistryHeaderImage
