import { Offer } from 'src/types/offer'
import { RegItemMinimal } from 'src/types/regItem'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { RegItemPricingLockupProps } from './RegItemPricingLockup.types'

const getOfferBoundaries = (
  offers: Offer[]
): {
  lowest: Offer
  highest: Offer
} => {
  let lowest: Offer = offers[0]
  let highest: Offer = offers[0]

  offers
    .sort((a) => (a.isBabylist ? -1 : 1))
    .forEach((offer) => {
      const priceAsNumber = Number(offer.price)
      const lowestPriceAsNumber = Number(lowest.price)
      const highestPriceAsNumber = Number(highest.price)

      if (priceAsNumber < lowestPriceAsNumber) {
        lowest = offer
      }

      if (priceAsNumber > highestPriceAsNumber) {
        highest = offer
      }
    })

  return {
    lowest,
    highest,
  }
}

// eslint-disable-next-line complexity
export const getPricingData = (regItem: RegItemMinimal) => {
  if (!regItem.offers || regItem.offers.length < 1) {
    // If we don't have and offers we fall back to the price on the regItem
    return {
      price: Number(regItem.price || 0),
    }
  }

  // Get the lowest and highest offers
  const { lowest, highest } = getOfferBoundaries(regItem.offers)

  // use getPriceTagPropsFromPriceDetails to handle sale data and price range data logic
  // For most external offers this isn't needed but it handles the babylist offers and is
  // a good fallback for any edge cases.
  const {
    currentPrice: lowestCurrentPrice,
    minPrice: lowestPriceRangeMin,
    msrp: lowestMsrp,
  } = getPriceTagPropsFromPriceDetails(lowest.priceDetails)
  const { msrp: highestMsrp } = getPriceTagPropsFromPriceDetails(
    highest.priceDetails
  )

  // Priority order for values:
  // 1. Price range (If a price range is present we want to use that first)
  // 2. Current price (uses sale prices if present otherwise falls back to list price)
  // 3. Offer base price value (not a price range)
  const min = lowestPriceRangeMin || lowestCurrentPrice || lowest.price

  // Use the highest msrp if it exists, otherwise use the lowest msrp if it exists.
  const msrp = highestMsrp || lowestMsrp

  // Check to see if babylist has the item in stock and is the lowest price
  const isBabyListTheLowestPrice =
    regItem.isAvailableOnBabylist && lowest.isBabylist
  return {
    strikePrice: msrp ? Number(msrp) : undefined,
    price: Number(min),
    isBabyListTheLowestPrice,
  }
}

export const isRegItemOnSale = (regItem: RegItemMinimal) =>
  regItem.priceDetails?.saleAttributes?.saleType === 'active_sale'

export const isRegItemOnPromoCodeSale = (regItem: RegItemMinimal) =>
  regItem.priceDetails?.saleAttributes?.saleType === 'promo_code_sale'

export const getSaleBadgeLabel = (regItem: RegItemMinimal) => {
  const { salePercentOff } = regItem.priceDetails?.saleAttributes || {}

  if (!salePercentOff?.percentInt || salePercentOff.percentInt < 10) {
    return 'Sale'
  }

  return `${salePercentOff.percentInt}% off`
}

export const getPromoMessages = (regItem: RegItemMinimal) => {
  const promoMessages: string[] = []

  if (!regItem) return promoMessages

  const firstOffer = regItem.offers?.[0]

  if (regItem.offers?.length === 1 && firstOffer.isBabylist) {
    // If there is only one offer and the offer is babylist, we display the promo message from the offer.
    // This is not always the same content as the promo message from the regItem itself!
    firstOffer.priceDetails?.saleAttributes?.saleCallouts?.forEach(
      ({ type, text }) => {
        if (type !== 'text') return
        promoMessages.push(text)
      }
    )
  } else {
    // Otherwise we display the promo message from the regItem itself because its going to have a "summary" message
    // that is more likely to be relevant to the user.
    regItem.priceDetails?.saleAttributes?.saleCallouts?.forEach(
      ({ type, text }) => {
        if (type !== 'text') return
        promoMessages.push(text)
      }
    )
  }

  return promoMessages
}

export const getPricingTreatmentSize = (
  isMobile: boolean,
  size: RegItemPricingLockupProps['size']
) => {
  if (size === 'small') {
    return 'sm'
  }

  return isMobile ? 'md' : 'lg'
}

export const getBadgeSize = (
  isMobile: boolean,
  size: RegItemPricingLockupProps['size']
) => {
  if (size === 'small') {
    return 'small'
  }

  return isMobile ? 'small' : 'medium'
}

export const getPromoMessageSize = (
  isMobile: boolean,
  size: RegItemPricingLockupProps['size']
) => {
  if (size === 'small') {
    return 'xs'
  }

  return isMobile ? 'xs' : 'sm'
}
