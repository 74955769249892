import React from 'react'
import { RegistryProfileIcon } from 'components/icons'
import css from './RegistryImage.styles.scss'
import { RegistryImageProps } from './RegistryImage.types'

const RegistryImage: React.FC<RegistryImageProps> = ({
  currentRegistry,
  onClick,
}) => {
  const { photoUrl } = currentRegistry || {}
  return (
    <button
      aria-label="Edit Registry Details"
      className={css.RegistryViewHeader__avatar}
      type="button"
      onClick={onClick}
    >
      {photoUrl ? (
        <img alt="Profile Avatar" src={photoUrl} />
      ) : (
        <RegistryProfileIcon height={80} width={80} />
      )}
    </button>
  )
}

export default RegistryImage
