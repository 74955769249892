import { Component } from 'react'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import { contactUsPath, returnPolicyPath } from 'lib/urls'

class BabylistUnacceptableReturn extends Component {
  static propTypes = {
    daysSincePurchase: PropTypes.number.isRequired,
  }

  purchasedDateString() {
    const purchasedOn = new Date()
    purchasedOn.setDate(purchasedOn.getDate() - this.props.daysSincePurchase)
    return dateFormat(purchasedOn, 'shortDate', true)
  }

  render() {
    const purchasedOnDateString = this.purchasedDateString()

    return (
      <div>
        <p>
          This gift was purchased from Babylist on {purchasedOnDateString} which
          is outside of our <a href={returnPolicyPath}>90 day return policy</a>.
        </p>
        <p>
          If you have any questions, please{' '}
          <a href={contactUsPath}>contact a Babylist Happiness Hero</a>.
        </p>
      </div>
    )
  }
}

export default BabylistUnacceptableReturn
