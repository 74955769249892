import { FC, ReactNode } from 'react'
import GiftGiverWrapper from './gift-giver-wrapper'
import { Reservation } from '../GiftTracker.types'

interface GiftGiverListProps {
  emails: string[]
  reservationsByGiftGiver: {
    [key: string]: Reservation[]
  }
  show: boolean
}

const GiftGiverList: FC<GiftGiverListProps> = ({
  emails,
  reservationsByGiftGiver,
  show,
}) => {
  const giftGiverList: ReactNode[] = []

  emails.map((email) => {
    giftGiverList.push(
      <GiftGiverWrapper
        key={email}
        reservations={reservationsByGiftGiver[email]}
        show={show}
      />
    )
  })

  return <>{giftGiverList}</>
}

export default GiftGiverList
