// eslint-disable-next-line import/no-extraneous-dependencies
import { Meter, Tag } from 'baby-design'
import classNames from 'classnames'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import useSecondhandItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useSecondhandItemsEnabled'
import { SECONDHAND_COPY } from 'registry/constants'
import RegItemMinimalTagLayout from 'src/routes/(subdomains)/(my)/[slug]/components/RegItemMinimalCard/components/RegItemMinimalCardDetails/components/RegItemMinimalTagLayout/RegItemMinimalTagLayout'
import css from '../RegItemMinimalCardDetails.styles.scss'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'

const GroupGiftDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  regItem,
}: RegItemMinimalCardDetailsProps) => {
  const secondhandItemsEnabled = useSecondhandItemsEnabled()
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const { title } = regItem
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.GroupGiftDetails
  )

  const stillNeeds = regItem.goalAmount - regItem.fundedAmount
  const stillNeedsFormatted = stillNeeds.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const completionPercentage = (regItem.fundedAmount / regItem.goalAmount) * 100
  const fundedText =
    stillNeeds <= 0 ? 'Fully Funded' : `Still needs ${stillNeedsFormatted}`

  return (
    <div className={wrapperClasses} data-testid="GroupGiftDetails">
      <div className={css.Title}>{title}</div>
      <div className={css.Funded}>{fundedText}</div>
      <Meter
        fillClass={css.Meter}
        percentage={completionPercentage}
        size={isMobile ? 'xs' : 'sm'}
      />
      <RegItemMinimalTagLayout>
        <Tag size={isMobile ? 'sm' : 'md'} type="primary" variant="normal">
          Group Gift
        </Tag>
        {secondhandItemsEnabled && regItem.prelovedAllowed && (
          <Tag size={isMobile ? 'sm' : 'md'} type="primary" variant="normal">
            {SECONDHAND_COPY}
          </Tag>
        )}
      </RegItemMinimalTagLayout>
    </div>
  )
}

export default GroupGiftDetails
