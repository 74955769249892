import Button from 'baby-design/components/Button/Button'
import LinkOut from 'baby-design/icons/LinkOut.svg'
import { healthPath, healthPartsPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { useEffect } from 'react'
import css from './BabylistHealthCTA.styles.scss'

export const BabylistHealthCTA = ({
  babylistHealthCtaProductType,
}: {
  babylistHealthCtaProductType: 'parts' | 'milk_bags' | 'breast_pump'
}) => {
  const partsAttributes = {
    eventType: track.EventType.PARTS,
    header: 'Let insurance pay for your pump replacement parts.',
    body: 'Insurance will cover the cost of pump replacement parts and milk storage bags from Babylist Health.',
    path: healthPartsPath,
  }
  const milkBagsAttributes = {
    eventType: track.EventType.MILK_BAGS,
    header: 'Let insurance pay for your milk storage bags.',
    body: 'Insurance will cover the cost of pump replacement parts and milk storage bags from Babylist Health.',
    path: healthPartsPath,
  }
  const breastPumpAttributes = {
    eventType: track.EventType.BREAST_PUMP,
    header: 'Let insurance pay for your pump.',
    body: 'Select an eligible breast pump from Babylist Health and receive free milk storage bags. Insurance will cover up to 100% of the cost.',
    path: healthPath,
  }
  const getAttributes = (babylistHealthCtaProductType: string) =>
    babylistHealthCtaProductType === 'parts'
      ? partsAttributes
      : babylistHealthCtaProductType === 'milk_bags'
        ? milkBagsAttributes
        : breastPumpAttributes
  const ctaAttributes = getAttributes(babylistHealthCtaProductType)
  const tracker = useTracking()
  const fireHealthBannerViewedEvent = () => {
    tracker.trackEvent({
      event: track.healthBannerViewed,
      eventCta: 'Check Your Eligibility',
      eventLocation: track.EventLocation.PDP,
      eventType: ctaAttributes.eventType,
    })
  }
  useEffect(() => {
    fireHealthBannerViewedEvent()
  }, [])
  const fireHealthBannerClickedEvent = () => {
    tracker.trackEvent({
      event: track.healthBannerClicked,
      eventCta: 'Check Your Eligibility',
      eventLocation: track.EventLocation.PDP,
      eventType: ctaAttributes.eventType,
    })
  }
  return (
    <div className={css.BabylistHealthCTA}>
      <div className={css.BabylistHealthCTA__heading}>
        {ctaAttributes.header}
      </div>
      <div className={css.BabylistHealthCTA__body}>{ctaAttributes.body}</div>
      <Button
        className={css.BabylistHealthCTA__button}
        href={ctaAttributes.path}
        icon={{ icon: <LinkOut />, side: 'right' }}
        size="sm"
        target="_blank"
        variant="inverted"
        onClick={fireHealthBannerClickedEvent}
      >
        Check your eligibility
      </Button>
    </div>
  )
}
