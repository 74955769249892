import { Dropdown, IconButton } from 'baby-design'
import { EditPencil, GarbageBin } from 'baby-design/icons'
import css from './Controls.styles.scss'
import { ProductCategory } from '../../RegItemCard.types'

interface RegItemControlsProps {
  size: 'desktop' | 'tablet' | 'phone'
  defaultCategory?: ProductCategory | null
  categories: Array<ProductCategory>
  onCategoryChange: (category: ProductCategory | null) => void
  onEditClick: (e: React.MouseEvent<HTMLElement>) => void
  onDeleteClick: (e: React.MouseEvent<HTMLElement>) => void
}

export const RegItemControls = ({
  size,
  defaultCategory = null,
  categories,
  onCategoryChange,
  onEditClick,
  onDeleteClick,
}: RegItemControlsProps) => {
  const EditButton = (
    <IconButton
      aria-label="Edit"
      size="sm"
      variant={size === 'desktop' ? 'fill-tertiary' : 'fill-secondary'}
      onClick={onEditClick}
    >
      <EditPencil />
    </IconButton>
  )

  const DeleteButton = (
    <IconButton
      aria-label="Delete"
      size="sm"
      variant={size === 'desktop' ? 'fill-tertiary' : 'fill-secondary'}
      onClick={onDeleteClick}
    >
      <GarbageBin />
    </IconButton>
  )

  if (size === 'desktop') {
    return (
      <div className={css.controls__desktop}>
        <Dropdown
          defaultValue={defaultCategory}
          options={categories}
          placeholder={<>Select&nbsp;Category...</>}
          selectClassName={css.controls__dropdown}
          size="sm"
          onChange={onCategoryChange}
        />
        {EditButton}
        {DeleteButton}
      </div>
    )
  }
  return <div className={css.controls__tablet}>{EditButton}</div>
}
