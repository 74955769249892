import { useMemo } from 'react'
import { SearchSuggestion } from 'src/types/searchSuggestion'
import css from './Search.styles.scss'

interface SearchSuggestionsProps {
  suggestions: SearchSuggestion[]
  activeIndex: number
  onSearch: (searchTerm: string) => void
  displayTerm: string
}

const highlightMatch = (text: string, term: string): React.ReactNode => {
  const regex = new RegExp(`(${term})`, 'gi')
  return text
    .split(regex)
    .map((part, index) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <strong key={index}>{part}</strong>
      ) : (
        part
      )
    )
}

export const SearchSuggestions = ({
  suggestions,
  activeIndex,
  onSearch,
  displayTerm,
}: SearchSuggestionsProps) => {
  const highlightedSuggestions = useMemo(
    () =>
      suggestions.map((suggestion) => ({
        ...suggestion,
        highlighted: highlightMatch(suggestion.searchTerm, displayTerm),
      })),
    [suggestions]
  )

  return (
    <ul className={css.Search__suggestions}>
      {highlightedSuggestions.map((suggestion, index) => (
        <li
          className={`${css.Search__suggestions__item} ${
            index === activeIndex
              ? css['Search__suggestions__item--active']
              : ''
          }`}
          key={index}
          onClick={() => onSearch(suggestion.searchTerm)}
        >
          {suggestion.highlighted}
        </li>
      ))}
    </ul>
  )
}
