import React from 'react'

interface YouTubeProps {
  className?: string
}

const YouTube: React.FC<YouTubeProps> = ({ className }: YouTubeProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>YouTube Icon</title>
    <path
      clipRule="evenodd"
      d="M6.52147 2.54078C7.94527 2.31166 9.74786 2.25 12 2.25C14.2521 2.25 16.0547 2.31166 17.4785 2.54078C18.9034 2.77007 20.0377 3.17979 20.8795 3.94505C21.7307 4.71887 22.1881 5.76906 22.4396 7.07713C22.6888 8.3728 22.75 9.99883 22.75 12C22.75 14.0012 22.6888 15.6273 22.4396 16.9229C22.1881 18.231 21.7307 19.2812 20.8795 20.055C20.0377 20.8203 18.9034 21.23 17.4785 21.4593C16.0547 21.6884 14.2521 21.75 12 21.75C9.74786 21.75 7.94527 21.6884 6.52147 21.4593C5.09658 21.23 3.96228 20.8203 3.1205 20.055C2.26929 19.2812 1.81192 18.231 1.56037 16.9229C1.3112 15.6273 1.25 14.0012 1.25 12C1.25 9.99883 1.3112 8.3728 1.56037 7.07713C1.81192 5.76906 2.26929 4.71887 3.12049 3.94505C3.96228 3.17979 5.09658 2.77007 6.52147 2.54078ZM11.3297 9.50623L13.9957 11.1058C14.1754 11.2136 14.325 11.3034 14.4366 11.3851C14.5497 11.4678 14.6565 11.5648 14.7154 11.6995C14.7991 11.8911 14.7991 12.1088 14.7154 12.3004C14.6565 12.435 14.5497 12.5321 14.4366 12.6148C14.325 12.6965 14.1754 12.7863 13.9957 12.8941L11.3297 14.4937C11.1404 14.6073 10.9835 14.7014 10.8536 14.764C10.7227 14.8271 10.5814 14.8777 10.4304 14.8636C10.217 14.8437 10.0223 14.7335 9.89547 14.5607C9.80569 14.4385 9.77644 14.2913 9.76317 14.1466C9.74999 14.003 9.75 13.8201 9.75 13.5993V10.4006C9.75 10.1799 9.74999 9.99687 9.76317 9.85326C9.77644 9.7086 9.80569 9.56141 9.89547 9.43915C10.0223 9.2664 10.217 9.15617 10.4304 9.13628C10.5814 9.1222 10.7227 9.17284 10.8536 9.23589C10.9835 9.29848 11.1404 9.39263 11.3297 9.50623Z"
      fill="#4A2143"
      fillRule="evenodd"
    />
  </svg>
)

export default YouTube
