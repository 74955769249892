import PropTypes from 'prop-types'
import { ReservedRegItemCard } from '../reg-item-card/reserved-reg-item-card'
import { CategoryHeader } from '../reg-item-category/reg-item-category'

interface Props {
  // Todo define regItem interface
  reservations: object[]
  reservedRegItemsByCurrentVisitor: any[]
}

interface ContextProps {
  isMobile: boolean
}

export const ReservedRegItemCategory = (
  { reservations, reservedRegItemsByCurrentVisitor }: Props,
  { isMobile }: ContextProps
) => {
  const renderGGRegItems = () => (
    <>
      {Object.keys(reservedRegItemsByCurrentVisitor).map((id: any) => {
        const reservation = reservedRegItemsByCurrentVisitor[id]

        return !reservation?.regItem ? null : (
          <li className={`reg-item ${isMobile ? 'mvl' : ''}`} key={id}>
            <ReservedRegItemCard
              regItem={reservation.regItem}
              reservationByCurrentVisitor={reservation}
            />
          </li>
        )
      })}
    </>
  )

  const renderOtherRegItems = () => (
    <>
      {reservations.map((reservation: any) => {
        if (
          reservedRegItemsByCurrentVisitor[reservation?.regItem?.id] ||
          reservation?.regItem?.isGiftCard ||
          reservation?.regItem?.isFavor ||
          reservation?.isGroupGiftContribution ||
          reservation?.regItem?.isCrowdfund
        ) {
          return false
        }
        return (
          <li
            className={`reg-item ${isMobile ? 'mvl' : ''}`}
            key={reservation.id}
          >
            <ReservedRegItemCard
              regItem={reservation.regItem}
              reservation={reservation}
            />
          </li>
        )
      })}
    </>
  )

  if (
    Object.keys(reservedRegItemsByCurrentVisitor).length ||
    reservations.length > 0
  ) {
    return (
      <div className="reg-category well animated animate-250ms fadeIn">
        <CategoryHeader>All Purchased Gifts</CategoryHeader>
        <ul className={isMobile ? 'list-unstyled' : 'list-bordered'}>
          {renderGGRegItems()}
          {renderOtherRegItems()}
        </ul>
      </div>
    )
  }
  return false
}

ReservedRegItemCategory.contextTypes = {
  isMobile: PropTypes.bool,
}
