import { FC } from 'react'
import { Button, Image, TextLink } from 'baby-design'

import css from './OurStory.styles.scss'
import CurvedHeader from '../../components/CurvedHeader/CurvedHeader'

interface NewsSource {
  name: string
  logo: string
  width: number
}

const OurStory: FC = () => {
  const newsSources: NewsSource[] = [
    {
      name: 'People',
      logo: 'https://images.babylist.com/image/upload/v1718919066/Consumer%20About%20Pages/OurStory_PeopleLogo.png',
      width: 46,
    },
    {
      name: 'Good Morning America',
      logo: 'https://images.babylist.com/image/upload/v1718919065/Consumer%20About%20Pages/OurStory_GoodMorningAmericaLogo.png',
      width: 56,
    },
    {
      name: 'Time',
      logo: 'https://images.babylist.com/image/upload/v1718919066/Consumer%20About%20Pages/OurStory_TimeLogo.png',
      width: 80,
    },
    {
      name: 'Parents',
      logo: 'https://images.babylist.com/image/upload/v1718919067/Consumer%20About%20Pages/OurStory_ParentsLogo.png',
      width: 80,
    },
    {
      name: 'E',
      logo: 'https://images.babylist.com/image/upload/v1718919064/Consumer%20About%20Pages/OurStory_ELogo.png',
      width: 80,
    },
    {
      name: 'The Cut',
      logo: 'https://images.babylist.com/image/upload/v1718919064/Consumer%20About%20Pages/OurStory_TheCutLogo.png',
      width: 62.5,
    },
    {
      name: 'Wirecutter',
      logo: 'https://images.babylist.com/image/upload/v1718919063/Consumer%20About%20Pages/OurStory_WirecutterLogo.png',
      width: 76,
    },
    {
      name: 'Elle',
      logo: 'https://images.babylist.com/image/upload/v1718919063/Consumer%20About%20Pages/OurStory_ElleLogo.png',
      width: 80,
    },
    {
      name: 'Good Housekeeping',
      logo: 'https://images.babylist.com/image/upload/v1718919062/Consumer%20About%20Pages/OurStory_GoodHousekeepingLogo.png',
      width: 85,
    },
  ]

  return (
    <div className={css.OurStory}>
      <CurvedHeader
        className={css.OurStory__curvedHeaderBackground}
        heading={
          <span>
            Find and register for everything baby,
            <br />
            all in one place
          </span>
        }
      />
      <section className={css.OurStory__storySection}>
        <div className={css.OurStory__mainContentContainer}>
          <h2 className={css.OurStory__storyHeading}>Our Story</h2>
          <div className={css.OurStory__storyText}>
            <p>
              In 2011, I was pregnant with my first son and ready to create a
              registry. As a former software engineer at Amazon, I expected ease
              and simplicity.
            </p>
            <p>What I found was the opposite.</p>
            <p>
              I spent hours researching products without confidence I was making
              the right decisions and couldn't register for everything in one
              place or add services I wanted like frozen meal deliveries, diaper
              delivery or pet sitting!
            </p>
            <p>I knew there had to be a better way.</p>
            <p>
              Determined to make life easier for expecting parents, Babylist was
              born just two weeks before my son Max.
            </p>
            <p>
              Today, I'm thrilled to see Babylist thrive, with over 50% of all
              first-time parents choosing us for their registry needs.
            </p>
            <p>
              From a pregnancy project to a community of over 200 employees, our
              marketplace helped more than nine million people make purchase
              decisions last year alone.
            </p>
            <p>Our mission is simple:</p>
            <ul>
              <li>Ensure expecting parents get exactly what they need.</li>
              <li>Help parents celebrate every moment & milestone.</li>
              <li>Make finding and registering for essentials a breeze.</li>
              <li>Provide honest, reliable and unbiased information.</li>
              <li>Offer support, connection and a sense of belonging.</li>
            </ul>
            <p>
              Whether you're celebrating a positive pregnancy test, awaiting a
              call from the adoption agency or navigating years of trying, we're
              here to smooth the journey.
            </p>
            <p>Here's to happy parenting,</p>
          </div>
          <Image
            alt="Natalie's signature"
            src="https://images.babylist.com/image/upload/v1718917081/Consumer%20About%20Pages/OurStory_NatalieSignature.png"
            width={225.5}
          />
          <div className={css.OurStory__NatalieDetails}>
            <Image
              alt="Natalie's professional headshot"
              height={50}
              src="https://images.babylist.com/image/upload/v1718917241/Consumer%20About%20Pages/OurStory_NatalieProfessionalHeadshot.png"
            />
            <p>
              <strong>Natalie Gordon,</strong> Founder & CEO
              <br />
              on behalf of the Babylist team
            </p>
          </div>
          <Button
            className={css.OurStory__storyButtonMobile}
            href="/about"
            size="md"
            variant="primary"
          >
            About Babylist, Inc
          </Button>
          <Button
            className={css.OurStory__storyButtonDesktop}
            href="/about"
            size="lg"
            variant="primary"
          >
            About Babylist, Inc
          </Button>
        </div>
        <div className={css.OurStory__storyImageContainer}>
          <Image
            alt="Natalie smiling with bright shapes around her portait"
            className={css.OurStory__storyImage}
            src="https://images.babylist.com/image/upload/v1718916602/Consumer%20About%20Pages/OurStory_mainImage_desktop.png"
          />
        </div>
      </section>
      <section className={css.OurStory__newsSection}>
        <h2 className={css.OurStory__newsHeading}>Babylist in the news</h2>
        <div className={css.OurStory__newsSources}>
          {newsSources.map(({ name, logo, width }) => (
            <div className={css.OurStory__newsSource}>
              <Image
                alt={`${name} logo`}
                className={css.OurStory__newsSourceLogo}
                src={logo}
                width={width}
              />
            </div>
          ))}
        </div>
      </section>
      <section className={css.OurStory__careersSection}>
        <div className={css.OurStory__careersMainContentContainer}>
          <h2 className={css.OurStory__careersHeading}>Careers</h2>
          <Image
            alt="Happy employees working together in a bright office space"
            className={css.OurStory__careersImage}
            src="https://images.babylist.com/image/upload/v1718922386/Consumer%20About%20Pages/OurStory_CareersImage.png"
          />
          <div className={css.OurStory__glassdoorImageContainer}>
            <Image
              alt="Glassdoor Review, score of 4.3"
              height={66}
              src="https://images.babylist.com/image/upload/v1718922081/Consumer%20About%20Pages/OurStory_Glassdoor.png"
            />
          </div>
          <div className={css.OurStory__careersText}>
            <p>
              Babylist is a remote-first company with team members located
              across the United States, Canada, Mexico and the Philippines. Work
              from home or our HQ in Emeryville, CA, whichever works best for
              you.
            </p>
            <h3>Why join us?</h3>
            <ul>
              <li>We&rsquo;re remote-first.</li>
              <li>
                We&rsquo;re profitable, established and growing in a $320B
                global baby products industry.
              </li>
              <li>
                We believe in the power of good management and provide ample
                development opportunities and support.
              </li>
              <li>
                We operate at a large scale—9M people purchase from Babylist
                annually.
              </li>
              <li>We have a lot of data to inform our customer journey.</li>
              <li>
                We&rsquo;re an equal opportunity employer and highly value
                diversity, equity, inclusion and belonging.
              </li>
            </ul>
          </div>
          <Button
            className={css.OurStory__careersButtonMobile}
            href="/about/careers"
            size="md"
            variant="primary"
          >
            See all job openings
          </Button>
          <Button
            className={css.OurStory__careersButtonDesktop}
            href="/about/careers"
            size="lg"
            variant="primary"
          >
            See all job openings
          </Button>
        </div>
        <div className={css.OurStory__careersImageContainer}>
          <Image
            alt="Happy employees working together in a bright office space"
            className={css.OurStory__careersImage}
            src="https://images.babylist.com/image/upload/v1718922386/Consumer%20About%20Pages/OurStory_CareersImage.png"
            width={384}
          />
          <div className={css.OurStory__glassdoorImageContainer}>
            <Image
              alt="Glassdoor Review, score of 4.3"
              height={66}
              src="https://images.babylist.com/image/upload/v1718922081/Consumer%20About%20Pages/OurStory_Glassdoor.png"
            />
          </div>
        </div>
      </section>
      <section className={css.OurStory__AnitasHouseSection}>
        <div className={css.OurStory__textBlock}>
          <h2 className={css.OurStory__textBlockHeading}>
            Looking for Babylist London?
          </h2>
          <p className={css.OurStory__textBlockBody}>
            For over 21 years, Anita&rsquo;s House has been helping new parents
            choose everything they need. They are the experts at finding the
            best of the best for you and your baby through a one-to-one private
            consultation.
          </p>
        </div>
        <Button
          className={css.OurStory__button}
          href="https://anitaslist.com"
          rel="noreferer"
          size="md"
          target="_blank"
          variant="inverted"
        >
          Visit Anita&rsquo;s House
        </Button>
        <TextLink
          underline
          className={css.OurStory__link}
          href="https://anitaslist.com"
          rel="noreferer"
          size="sm"
          target="_blank"
        >
          Visit Anita&rsquo;s House
        </TextLink>
      </section>
    </div>
  )
}

export default OurStory
