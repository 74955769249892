import { Dispatch, SetStateAction, createContext, useState } from 'react'

interface PDPContextProps {
  isPastWaypointLine: boolean
  setIsPastWaypointLine: Dispatch<SetStateAction<boolean>>
}

const PDPContextDefaults: PDPContextProps = {
  isPastWaypointLine: false,
  setIsPastWaypointLine: () => {},
}

const PDPContext = createContext(PDPContextDefaults)

const PDPProvider = ({ children }: any) => {
  const [isPastWaypointLine, setIsPastWaypointLine] = useState(false)

  return (
    <PDPContext.Provider value={{ isPastWaypointLine, setIsPastWaypointLine }}>
      {children}
    </PDPContext.Provider>
  )
}

export { PDPContext, PDPProvider }
