import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Image } from 'baby-design'
import classNames from 'classnames'
import CustomPlayer from '../CustomPlayer/CustomPlayer'

import css from './ReelsPhone.styles.scss'

interface ReelsPhoneProps {
  className?: string
  muted: boolean
}

const ReelsPhone: FC<ReelsPhoneProps> = ({ className, muted: parentMuted }) => {
  const [activeReelIndex, setActiveReelIndex] = useState(0)
  const [reelsHaveBeenClicked, setReelsHaveBeenClicked] = useState(false)
  const [parentMutedHasChanged, setParentMutedHasChanged] = useState(false)
  const [reelsAreMuted, setReelsAreMuted] = useState(true)

  const reel0 = useRef<HTMLVideoElement>(null)
  const reel1 = useRef<HTMLVideoElement>(null)
  const reel2 = useRef<HTMLVideoElement>(null)
  const reel3 = useRef<HTMLVideoElement>(null)
  const reel4 = useRef<HTMLVideoElement>(null)
  const reel5 = useRef<HTMLVideoElement>(null)
  const reel6 = useRef<HTMLVideoElement>(null)
  const reel7 = useRef<HTMLVideoElement>(null)
  const reel8 = useRef<HTMLVideoElement>(null)
  const reel9 = useRef<HTMLVideoElement>(null)

  const reels = useMemo(
    () => [
      {
        ref: reel0,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212395/Corporate%20About%20Pages/Hero%20Videos/reel1.mp4',
      },
      {
        ref: reel1,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212397/Corporate%20About%20Pages/Hero%20Videos/reel2.mp4',
      },
      {
        ref: reel2,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212398/Corporate%20About%20Pages/Hero%20Videos/reel3.mp4',
      },
      {
        ref: reel3,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212397/Corporate%20About%20Pages/Hero%20Videos/reel4.mp4',
      },
      {
        ref: reel4,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212396/Corporate%20About%20Pages/Hero%20Videos/reel5.mp4',
      },
      {
        ref: reel5,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212396/Corporate%20About%20Pages/Hero%20Videos/reel6.mp4',
      },
      {
        ref: reel6,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212396/Corporate%20About%20Pages/Hero%20Videos/reel7.mp4',
      },
      {
        ref: reel7,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212398/Corporate%20About%20Pages/Hero%20Videos/reel8.mp4',
      },
      {
        ref: reel8,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212395/Corporate%20About%20Pages/Hero%20Videos/reel9.mp4',
      },
      {
        ref: reel9,
        src: 'https://images.babylist.com/video/upload/q_auto/v1720212395/Corporate%20About%20Pages/Hero%20Videos/reel10.mp4',
      },
    ],
    []
  )

  useEffect(() => {
    let timeout: NodeJS.Timer

    const currentReel = reels[activeReelIndex]
    const currentVideoElement = currentReel.ref.current
    if (currentVideoElement) {
      const promise = currentVideoElement.play()
      if (promise) {
        promise.catch((error) => {
          console.error(`Error playing video ${activeReelIndex}:`, error)
        })
      }
    }

    const previousReelIndex = activeReelIndex - 1
    const reel = reels[previousReelIndex] || reels[reels.length - 1]
    const videoElement = reel.ref.current
    if (videoElement) {
      timeout = setTimeout(() => {
        videoElement.pause()
        videoElement.currentTime = 0
      }, 1000)
    }
    return () => clearTimeout(timeout)
  }, [activeReelIndex, reels])

  useEffect(() => {
    let interval: NodeJS.Timer
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        clearInterval(interval)
      } else if (!reelsHaveBeenClicked) {
        startInterval()
      }
    }

    const startInterval = () => {
      interval = setInterval(() => {
        const nextIndex = activeReelIndex + 1
        if (nextIndex === reels.length) {
          setActiveReelIndex(0)
        } else {
          setActiveReelIndex(nextIndex)
        }
      }, 7000)
    }

    if (!reelsHaveBeenClicked) {
      startInterval()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(interval)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [reelsHaveBeenClicked, activeReelIndex, reels.length])

  useEffect(() => {
    if (parentMutedHasChanged) {
      handleReelsButtonClicked()
    } else if (!parentMuted) {
      setParentMutedHasChanged(true)
      handleReelsButtonClicked()
    }
  }, [parentMuted])

  const onEnded = (currentIndex: number) => {
    const nextIndex = currentIndex + 1
    if (nextIndex === reels.length) {
      setActiveReelIndex(0)
    } else {
      setActiveReelIndex(nextIndex)
    }
  }

  const handleReelsButtonClicked = () => {
    if (!reelsHaveBeenClicked) {
      setReelsHaveBeenClicked(true)
    }
    setReelsAreMuted((prevValue) => !prevValue)
  }

  return (
    <div className={classNames(className, css.ReelsPhone__container)}>
      <Image
        className={css.ReelsPhone__phoneBase}
        src="https://images.babylist.com/image/upload/q_auto/v1720215704/Corporate%20About%20Pages/Animated/Phone%20Pieces/PhoneBase.png"
      />
      <Image
        className={css.ReelsPhone__phoneBorder}
        src="https://images.babylist.com/image/upload/q_auto/v1720215721/Corporate%20About%20Pages/Animated/Phone%20Pieces/BlackBorder.png"
      />
      <div
        className={classNames(
          css.ReelsPhone__reelsContainer,
          css[`ReelsPhone__reelsContainer--${activeReelIndex}`]
        )}
      >
        {reels.map(({ ref, src }, index) => (
          <CustomPlayer
            className={css.ReelsPhone__reel}
            key={src}
            muted={reelsAreMuted || index !== activeReelIndex}
            ref={ref}
            src={src}
            onEnded={() => onEnded(index)}
          />
        ))}
        <button
          className={classNames(css.ReelsPhone__reelsButton, 'sr-only')}
          type="button"
          onClick={handleReelsButtonClicked}
        >
          Toggle video mute
        </button>
      </div>
      <div className={css.ReelsPhone__cameraCutout} />
    </div>
  )
}

export default ReelsPhone
