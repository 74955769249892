import { FC } from 'react'
import classNames from 'classnames'
import ReactOnScreenTrackVisibility from 'react-on-screen'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Image } from 'baby-design'
import { useNavigate } from 'react-router-dom-v5-compat'

import css from './LeadershipSection.styles.scss'

const LeadershipSection: FC = () => {
  const navigate = useNavigate()

  const leaderImages = [
    {
      alt: `Natalie Gordon's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630130/Corporate%20About%20Pages/Animated/home_leadership_image0.png',
    },
    {
      alt: `Lee Anne Grant's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630130/Corporate%20About%20Pages/Animated/home_leadership_image1.png',
    },
    {
      alt: `Chris Mills' headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630130/Corporate%20About%20Pages/Animated/home_leadership_image2.png',
    },
    {
      alt: `Jocelene Kwan's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630130/Corporate%20About%20Pages/Animated/home_leadership_image3.png',
    },
    {
      alt: `Jennifer Gosselin's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630130/Corporate%20About%20Pages/Animated/home_leadership_image4.png',
    },
    {
      alt: `Liz Primm's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630118/Corporate%20About%20Pages/Animated/home_leadership_image5.png',
    },
    {
      alt: `TJ Moretto's headshot in a small circle`,
      src: 'https://images.babylist.com/image/upload/q_auto/v1720630104/Corporate%20About%20Pages/Animated/home_leadership_image6.png',
    },
  ]

  return (
    <section className={css.LeadershipSection}>
      <div className={css.LeadershipSection__content}>
        <div className={css.LeadershipSection__textBlock}>
          <h2 className={css.LeadershipSection__textBlockHeading}>
            Leadership team
          </h2>
          <p className={css.LeadershipSection__textBlockBody}>
            Our executive leadership team includes industry experts in our core
            business areas (engineering, product development, media, marketing,
            merchandising and logistics,) and have backgrounds leading teams at
            companies like Accenture, Amazon, Athleta, Chewy, eBay, Google,
            McKinsey, Netflix and Twitter.
          </p>
        </div>
        <Button
          href="/about/leadership"
          size="md"
          variant="primary"
          onClick={(e) => {
            e.preventDefault()
            navigate('/about/leadership')
          }}
        >
          Meet the leadership team
        </Button>
      </div>
      <ReactOnScreenTrackVisibility
        once
        className={css.LeadershipSection__imageContainer}
        offset={100}
      >
        {({ isVisible }) =>
          leaderImages.map((leader, index) => (
            <Image
              alt={leader.alt}
              className={classNames(
                css.LeadershipSection__image,
                css[`LeadershipSection__image--${index}`],
                {
                  [css['LeadershipSection__image--preAnimation']]: !isVisible,
                }
              )}
              key={leader.src}
              src={leader.src}
            />
          ))
        }
      </ReactOnScreenTrackVisibility>
    </section>
  )
}

export default LeadershipSection
