import { filterPostsByTopicSlug } from 'hello_baby/lib'
import TopicSection from 'hello_baby/components/TopicSection'
import { useParams } from 'react-router-dom-v5-compat'
import { usePosts, useTopic } from '../../utils/useQueries'

const SampleRegistriesView: React.FC = () => {
  const posts = usePosts()
  const { slug } = useParams()

  const subTopics = useTopic(slug)?.children || []

  return (
    <>
      {subTopics.map((childTopic: Topic) => (
        <TopicSection
          key={`topic_section_${childTopic.slug}`}
          posts={filterPostsByTopicSlug(posts, childTopic.slug)}
          showAllButton={false}
          showTopics={false}
          topic={{ name: childTopic.name, slug: childTopic.slug }}
        />
      ))}
    </>
  )
}

export default SampleRegistriesView
