import MultiItemSlider, {
  MultiItemSliderProps,
} from 'components/MultiItemSlider'
import { ProductCardData } from '../../global'
import { track, useTracking } from '../../lib/analytics'
import ProductCardV2 from '../../store/components/ProductCardV2'

export interface ProductSliderProps extends MultiItemSliderProps {
  /**
   * A  list of products containing the data to render a `<ProductCard />`.
   */
  products: ProductCardData[]
  /**
   * Any shared tracking metadata. This will be passed to the viewed and clicked event.
   * @default {}
   */
  trackingData?: any
  /**
   * The title of the section. This will be displayed above the slider.
   */
  title: string
  isMobile?: boolean
}

export default function ProductSlider({
  products,
  title,
  trackingData = {},
  isMobile,
  ...rest
}: ProductSliderProps) {
  const tracker = useTracking()

  function trackClickedProduct(index: number) {
    tracker.trackEvent({
      event: track.productRecommendationClicked,
      productIds: [products[index].gpId],
      ...trackingData,
    })
  }

  function trackViewedProducts(indexes: number[]) {
    const viewedProducts = products.slice(
      Math.min(...indexes),
      Math.max(...indexes) + 1
    )

    tracker.trackEvent({
      event: track.productRecommendationsViewed,
      productIds: viewedProducts.map((product) => product.gpId),
      ...trackingData,
    })
  }

  return (
    <MultiItemSlider
      aria-label={title}
      columnClassName="col-sm-3 col-xs-10"
      title={title}
      onItemClicked={trackClickedProduct}
      onItemsViewed={trackViewedProducts}
      {...rest}
    >
      {products.map((product, idx) => (
        <ProductCardV2
          key={product.gpId}
          {...product}
          __forcePageLoad
          isMobile={isMobile}
          position={idx + 1}
          slugSet={null}
        />
      ))}
    </MultiItemSlider>
  )
}
