import React, { createContext, useContext, useState } from 'react'
import {
  defaultAppliedSortsAndFilters,
  mergeSortsAndFilters,
} from '../components/RegistryGiftGiverPage/RegistryGiftGiverPage.utils'
import { AppliedSortsAndFilters } from '../components/RegistryGiftGiverPage/RegistryGiftGiverPage.types'

export interface AppliedSortsAndFiltersContextProps {
  appliedSortsAndFilters: AppliedSortsAndFilters
  onSortFilterChange: (appliedSortsAndFilters: AppliedSortsAndFilters) => void
}

export const AppliedSortsAndFiltersContext =
  createContext<AppliedSortsAndFiltersContextProps>({
    appliedSortsAndFilters: { filters: {} },
    onSortFilterChange: (_) => undefined,
  })

interface ProviderProps {
  children: React.ReactNode
}

export const AppliedSortsAndFiltersContextProvider: React.FC<ProviderProps> = ({
  children,
}) => {
  const [appliedSortsAndFilters, setAppliedSortsAndFilters] =
    useState<AppliedSortsAndFilters>(defaultAppliedSortsAndFilters)

  const onSortFilterChange = (changes: Partial<AppliedSortsAndFilters>) => {
    setAppliedSortsAndFilters((prev: AppliedSortsAndFilters) =>
      mergeSortsAndFilters(changes, prev)
    )
  }

  return (
    <AppliedSortsAndFiltersContext.Provider
      value={{ appliedSortsAndFilters, onSortFilterChange }}
    >
      {children}
    </AppliedSortsAndFiltersContext.Provider>
  )
}

export const useAppliedSortsAndFiltersContext = () =>
  useContext(AppliedSortsAndFiltersContext)
