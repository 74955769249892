import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import LegacyProductImages from '../../../../../../../../../store/components/product-details-page/product-images'
import css from './ProductImages.styles.scss'

export const ProductImages = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  return (
    <div className={css.ProductImages}>
      <LegacyProductImages
        images={genericProduct?.activeProduct?.images.slice(0, 7)}
        video={genericProduct.videos ? genericProduct.videos[0] : []}
      />
    </div>
  )
}
