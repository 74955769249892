import React from 'react'
import { DragHandle } from 'baby-design/icons'
import classNames from 'classnames'

import css from './RegItemCard.styles.scss'

const RegItemCardSkeleton: React.FC<{ size: string; className?: string }> = ({
  size,
  className,
}) => {
  const RegItemCardClasses = classNames(
    css.RegItemCard,
    css.RegItemCard__shimmer,
    {
      [css.reg_item_card__desktop]: size === 'desktop',
      [css.reg_item_card__tablet]: size === 'tablet',
      [css.reg_item_card__phone]: size === 'phone',
    },
    className
  )
  if (size === 'desktop') {
    return (
      <div className={RegItemCardClasses}>
        <div className={css.reg_item_card__content}>
          <div className={css.reg_item_card__image} />
          <div className={css.reg_item_card__description}>
            <div />
            <div />
            <div />
          </div>
          <div className={css.reg_item_card__options}>
            <div />
          </div>
        </div>
        <button
          aria-label="Drag Handle"
          className={css.reg_item_card__drag_handle}
        >
          <DragHandle />
        </button>
      </div>
    )
  }
  return (
    <div className={RegItemCardClasses} tabIndex={-1}>
      <div className={css.reg_item_card__content}>
        <div className={css.reg_item_card__image} />
        <div className={css.reg_item_card__description}>
          <div />
          <div />
        </div>
        <div className={css.reg_item_card__notes} />
      </div>
    </div>
  )
}

export default RegItemCardSkeleton
