import {
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_FAVOR,
  REG_ITEM_TYPE_GIFT_CARD,
  REG_ITEM_TYPE_GROUP_GIFT,
  REG_ITEM_TYPE_PRODUCT,
} from 'src/constants'
import { RegItemTypeAugmented } from 'src/routes/(subdomains)/(my)/[slug]/utils/getRegItemType/getRegItemType'
import { ReservationWithVisitor } from '../../../../../RegistryCategories/RegistryCategories.types'

export const getReservationBadgeText = (
  reservation: ReservationWithVisitor,
  regItemType?: RegItemTypeAugmented
): string => {
  switch (regItemType) {
    case REG_ITEM_TYPE_CROWDFUND:
      return `Contribution${getProductBadgeSuffix(reservation)}`
    case REG_ITEM_TYPE_FAVOR:
      return `Gifted${getProductBadgeSuffix(reservation, 'by')}`
    case REG_ITEM_TYPE_GROUP_GIFT:
      return getGroupGiftBadgeText(reservation)
    case REG_ITEM_TYPE_GIFT_CARD:
    case REG_ITEM_TYPE_PRODUCT:
      return `${getProductBadgeText(reservation)}${getProductBadgeSuffix(reservation, 'by')}`
    default:
      return 'Purchased'
  }
}

const getProductBadgeSuffix = (
  reservation: ReservationWithVisitor,
  preposition = 'from'
): string => {
  if (reservation.isVisitorReservation) {
    return ` ${preposition} you`
  }

  return ''
}

const getProductBadgeText = (reservation: ReservationWithVisitor): string =>
  reservation.isPurchased ? 'Purchased' : 'On hold'

const getGroupGiftBadgeText = (reservation: ReservationWithVisitor): string => {
  if (reservation.isPurchased) {
    return `Purchased${getProductBadgeSuffix(reservation, 'by')}`
  }

  return `Contribution${getProductBadgeSuffix(reservation)}`
}
