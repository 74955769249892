import { Component } from 'react'
import PropTypes from 'prop-types'

import { InfoButton } from 'components/buttons'
import { Radio, RadioGroup, Input } from 'components/forms'

import { createGiftReturnRequest } from '../api'

class BabylistReturnRequestForm extends Component {
  state = {
    submitting: false,
    productUsed: '',
    packagingOpened: '',
    refundTo: '',
    reasonForReturn: '',
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    reservationToken: PropTypes.string.isRequired,
  }

  handleSubmit = () => {
    this.setState({ submitting: true }, () => {
      createGiftReturnRequest({
        reservationToken: this.props.reservationToken,
        isProductUsed: this.state.productUsed,
        isInOriginalPackaging: this.state.packagingOpened,
        refundTo: this.state.refundTo,
        reasonsForReturn: this.state.reasonForReturn,
      })
        .then(this.props.onSuccess)
        .catch(this.props.onFailure)
    })
  }

  handleChange = (key, event) => {
    this.setState({ [key]: event.target.value })
  }

  render() {
    return (
      <div>
        <p className="mbn">
          We need a few pieces of information to start the return.
        </p>
        <p>
          <em className="h6">(Your gift giver won't be notified!)</em>
        </p>

        <RadioGroup title="The product is...">
          <Radio
            onChange={this.handleChange.bind(this, 'productUsed')}
            name="used"
            value="used"
            label="used"
          />
          <Radio
            onChange={this.handleChange.bind(this, 'productUsed')}
            name="used"
            value="unused"
            label="unused"
          />
        </RadioGroup>

        <RadioGroup title="The packaging is...">
          <Radio
            onChange={this.handleChange.bind(this, 'packagingOpened')}
            name="packaging"
            value="opened"
            label="opened"
          />
          <Radio
            onChange={this.handleChange.bind(this, 'packagingOpened')}
            name="packaging"
            value="unopened"
            label="unopened"
          />
        </RadioGroup>

        <RadioGroup title="I would like the refund...">
          <Radio
            onChange={this.handleChange.bind(this, 'refundTo')}
            name="refundTo"
            value="as Babylist shop credit"
            label="as Babylist shop credit"
          />
          <Radio
            onChange={this.handleChange.bind(this, 'refundTo')}
            name="refundTo"
            value="refunded to purchaser"
            label="refunded to purchaser"
          />
        </RadioGroup>

        <Input
          onChange={this.handleChange.bind(this, 'reasonForReturn')}
          label="Reason for return"
          placeholder="(e.g. I received a duplicate of this gift...)"
          type="textarea"
        />

        <InfoButton
          submitting={this.state.submitting}
          onClick={this.handleSubmit}
        >
          Request Return
        </InfoButton>
      </div>
    )
  }
}

export default BabylistReturnRequestForm
