import { Button } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import css from './DeleteCategoryInfoModal.styles.scss'

interface DeleteCategoryInfoModalProps {
  categoryName: string
}

export default NiceModal.create(
  ({ categoryName }: DeleteCategoryInfoModalProps) => {
    const modal = useModal()

    const handleClose = () => {
      modal.resolve(false)
      modal.remove()
    }

    const primaryButton = (
      <Button size="md" variant="primary" onClick={handleClose}>
        Got It
      </Button>
    )

    return (
      <ScrollLockModal
        alignButton="right"
        handleClose={handleClose}
        isOpen={modal.visible}
        primaryButton={primaryButton}
      >
        <div className={css.DeleteCategoryInfoModal}>
          <h2>Unable to Delete</h2>
          <p>
            Sorry <b>{categoryName || ''}</b> category cannot be removed from
            Registry.
          </p>
        </div>
      </ScrollLockModal>
    )
  }
)
