import { useParams } from 'react-router-dom-v5-compat'
import { withContextualizedTracking, track } from 'lib/analytics'
import GuidesNav from '../../components/GuidesNav'
import {
  useGuidesConfig,
  usePost,
  useTopic,
  useTopicNavigation,
} from '../../utils/useQueries'
import TopicView from '../../components/TopicView'
import PostViewAdapter from '../../components/PostViewAdapter/PostViewAdapter'
import PostListView from '../../components/PostListView'

const SlugRouter: React.FC = () => {
  const { slug } = useParams() as Record<string, string>
  const { emailUrls } = useGuidesConfig()
  const topicNavigation = useTopicNavigation()
  const topicRoutes: string[] = []

  topicNavigation.items.forEach((topic) => {
    topicRoutes.push(topic.slug)
    topic.children?.forEach((child) => {
      topicRoutes.push(child.slug)
    })
  })

  const GuidesTopicView: React.FC = () => {
    const topic = useTopic(slug)

    return (
      <>
        <GuidesNav topic={topic} />
        <PostListView>
          <TopicView slug={slug} />
        </PostListView>
      </>
    )
  }

  const GuidesPostView: React.FC = () => {
    const post = usePost(slug)
    const topic = post?.meta?.topics[0]

    return (
      <>
        <GuidesNav topic={topic} />
        <PostViewAdapter emailUrls={emailUrls} slug={slug} />
      </>
    )
  }

  return (
    <>{topicRoutes.includes(slug) ? <GuidesTopicView /> : <GuidesPostView />}</>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.GUIDE,
})(SlugRouter)
