// eslint-disable-next-line import/no-extraneous-dependencies
import { formatPrice } from 'baby-design/components/PricingTreatment/PricingTreatment.utils'
import {
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_GIFT_CARD,
  REG_ITEM_TYPE_FAVOR,
  REG_ITEM_TYPE_GROUP_GIFT,
  REG_ITEM_TYPE_PRODUCT,
} from 'src/constants'
import { RegItem } from 'src/types/regItem'
import { Registry } from 'src/types/registry'
import { Offer } from 'src/types/offer'
import getRegItemType from '../../utils/getRegItemType/getRegItemType'
import {
  CashFundBuyingOptions,
  DidYouPurchaseOptions,
  GiftCardBuyingOptions,
  GroupGiftGiftingOptions,
  HelpAndFavorsBuyingOptions,
  ProductBuyingOptions,
} from './variants'
import { CurrentUser } from '../../../../../../../global'
import { useGetHandleExternalOfferClick } from '../../hooks/useGetExternalOfferClickHandler/useGetExternalOfferClickHandler'
import { useTrackRegistryProductOfferClicked } from '../../hooks/useTrackRegistryProductOfferClicked/useTrackRegistryProductOfferClicked'
import { useTrackProductClicked } from '../../hooks/useTrackProductClicked/useTrackProductClicked'

export const getRegItemBuyingOptionsComponent = (
  regItem: RegItem | undefined,
  variantOverride?: 'DidYouPurchaseOptions'
) => {
  if (!regItem) return () => null

  if (variantOverride === 'DidYouPurchaseOptions') return DidYouPurchaseOptions

  const regItemType = getRegItemType(regItem)

  switch (regItemType) {
    case REG_ITEM_TYPE_CROWDFUND:
      return CashFundBuyingOptions
    case REG_ITEM_TYPE_GIFT_CARD:
      return GiftCardBuyingOptions
    case REG_ITEM_TYPE_FAVOR:
      return HelpAndFavorsBuyingOptions
    case REG_ITEM_TYPE_GROUP_GIFT:
      return GroupGiftGiftingOptions
    case REG_ITEM_TYPE_PRODUCT:
      return ProductBuyingOptions
    default:
      return ProductBuyingOptions
  }
}

export const getIsRegItemDetailsVisible = (
  isQuantityVisible: boolean,
  regItem?: RegItem
) => {
  if (!regItem) return false

  return isQuantityVisible || regItem.description
}

export const getRegItemBuyingOptionsTitle = (
  regItem: RegItem | undefined,
  variantOverride?: 'DidYouPurchaseOptions'
) => {
  if (!regItem) return null

  if (variantOverride === 'DidYouPurchaseOptions')
    return 'Did you purchase this item?'

  const regItemType = getRegItemType(regItem)

  switch (regItemType) {
    case REG_ITEM_TYPE_CROWDFUND:
      return 'Cash fund'
    case REG_ITEM_TYPE_FAVOR:
      return 'Help & favor'
    case REG_ITEM_TYPE_GROUP_GIFT:
      return 'Group gift'
    case REG_ITEM_TYPE_PRODUCT:
      return 'Where to buy'
    default:
      return null
  }
}

export const getGiftCardPriceRange = (regItem: RegItem) => {
  const priceRange = regItem.offers?.[0]?.priceDetails?.listPrice?.priceRange

  if (!priceRange) return ''

  const minPrice = formatPrice(Number(priceRange.minPrice), 0)
  const maxPrice = formatPrice(Number(priceRange.maxPrice), 0)
  return `${minPrice} - ${maxPrice}`
}

export const getShouldShowDetailsLink = (regItem?: RegItem) => {
  if (!regItem) return false

  const regItemType = getRegItemType(regItem) || ''

  return [REG_ITEM_TYPE_PRODUCT, REG_ITEM_TYPE_GROUP_GIFT].includes(regItemType)
}

export const useGetHandleDetailsLinkClick = (
  registry: Registry,
  regItem: RegItem | undefined,
  currentUser: CurrentUser | undefined,
  sortedOffers: Offer[]
) => {
  const firstOffer = sortedOffers[0]

  const handleExternalOfferClick = useGetHandleExternalOfferClick(
    firstOffer,
    registry,
    regItem
  )
  const trackRegistryProductOfferClicked = useTrackRegistryProductOfferClicked(
    registry,
    regItem,
    currentUser,
    firstOffer
  )
  const trackProductClicked = useTrackProductClicked(
    regItem,
    registry,
    currentUser
  )

  return () => {
    if (firstOffer?.isBabylist) {
      trackProductClicked()
    } else {
      trackRegistryProductOfferClicked()
      handleExternalOfferClick()
    }
  }
}

export const sortProductOffersByPrice = (offers?: Offer[]): Offer[] => {
  if (!offers || !offers.length) {
    return []
  }

  return offers.sort((a: Offer, b: Offer) => {
    // Babylist offers should always be first
    if (a.isBabylist) {
      return -1
    }
    if (b.isBabylist) {
      return 1
    }

    // Sort by price
    return Number(a.price) - Number(b.price)
  })
}

export const getDetailsUrl = (regItem?: RegItem, sortedOffers?: Offer[]) => {
  if (!regItem || !sortedOffers || !sortedOffers.length) return undefined

  const firstOffer = sortedOffers[0]
  if (firstOffer?.url && firstOffer?.isBabylist) {
    return firstOffer.url
  }

  // Return no URL for external offers, those will trigger a modal to open instead of an HREF naviagtion
  return undefined
}
