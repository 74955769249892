import NiceModal from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'
import { AnyAction, Dispatch } from 'redux'
import { openRecoverReservationModal } from 'registry/modals/actions'

import {
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_EXTERNAL_REGISTRY,
  REG_ITEM_TYPE_FAVOR,
  REG_ITEM_TYPE_GIFT_CARD,
  REG_ITEM_TYPE_GROUP_GIFT,
  REG_ITEM_TYPE_PRODUCT,
} from 'src/constants'
import { Registry } from 'src/types/registry'
import { RegItemMinimal } from 'src/types/regItem'
import { Reservation } from 'src/types/reservation'
import getRegItemType from '../../utils/getRegItemType/getRegItemType'
import RegItemBuyingOptionsModal from '../RegItemBuyingOptionsModal/RegItemBuyingOptionsModal'

export const useGetHandleRegItemClick = (
  regItem: RegItemMinimal,
  registry: Registry,
  reservation?: Reservation
) => {
  const regItemType = getRegItemType(regItem)
  const dispatch = useDispatch()

  switch (regItemType) {
    case REG_ITEM_TYPE_CROWDFUND:
      return handleBuyingOptionsModalClick(regItem, registry, reservation)
    case REG_ITEM_TYPE_GIFT_CARD:
      return handleBuyingOptionsModalClick(regItem, registry, reservation)
    case REG_ITEM_TYPE_EXTERNAL_REGISTRY:
      return handleExternalRegistryClick(regItem)
    case REG_ITEM_TYPE_FAVOR:
      return handleBuyingOptionsModalClick(regItem, registry, reservation)
    case REG_ITEM_TYPE_GROUP_GIFT:
      return handleGroupGiftClick(regItem, registry, dispatch, reservation)
    case REG_ITEM_TYPE_PRODUCT:
      return handleBuyingOptionsModalClick(regItem, registry, reservation)
    default:
      return () => undefined
  }
}

const handleExternalRegistryClick = (regItem: RegItemMinimal) => () => {
  if (typeof window === 'undefined') return

  const offerUrl = regItem.offers?.[0]?.url

  if (offerUrl && offerUrl !== '') {
    window.open(offerUrl, '_blank')
  }
}

const handleBuyingOptionsModalClick =
  (regItem: RegItemMinimal, registry: Registry, reservation?: Reservation) =>
  () =>
    NiceModal.show(RegItemBuyingOptionsModal, {
      regItemId: regItem.id,
      registry,
      reservation,
    })

const handleGroupGiftClick =
  (
    regItem: RegItemMinimal,
    registry: Registry,
    dispatch: Dispatch<AnyAction>,
    reservation?: Reservation
  ) =>
  () => {
    if (regItem.goalAmount <= regItem.fundedAmount) {
      dispatch(openRecoverReservationModal(regItem.id))
    } else {
      handleBuyingOptionsModalClick(regItem, registry, reservation)()
    }
  }
