import fetch from 'lib/fetch'
import { apiV3AboutHABPath } from 'lib/urls'

export const FETCH_HAB_KEY = 'FETCH_HAB'
export const fetchHealthAdvisoryBoardContent = async () => {
  const response = await fetch(apiV3AboutHABPath, {
    method: 'GET',
  })

  return response.boardMembers
}
