import classNames from 'classnames'
import { Component } from 'react'
import PropTypes from 'prop-types'

import { savePhoto } from 'registry/api'

import Image from 'components/image'
import Link from 'components/link'
import { UploadPhotoHoverIcon, UploadPhotoIcon } from 'components/icons'
import Spinner from 'shared/spinner'
import { REGISTRY_PROFILE_IMG_TRANSFORM } from 'shared/constants'

import css from './photo-uploader.scss'

export const Photo = ({ photo }) => {
  return (
    <div>
      <div className={css.localPhotoMask} />
      <Image
        alt="Registry profile image"
        className={css.photo}
        src={photo}
        lazyLoad={false}
      />
    </div>
  )
}

Photo.propTypes = {
  photo: PropTypes.string.isRequired,
}

export const UploadPhotoPreview = ({ photo, uploading }) => {
  return (
    <div className={css.uploadPhotoContainer}>
      {photo ? (
        <Photo photo={photo} />
      ) : uploading ? (
        <div className={css.uploadSpinnerContainer}>
          <Spinner className="center-xy" />
        </div>
      ) : (
        <UploadPhotoIcon className={css.uploadPhotoIcon} />
      )}
    </div>
  )
}

UploadPhotoPreview.propTypes = {
  photo: PropTypes.string,
  uploading: PropTypes.bool,
}

class PhotoUploader extends Component {
  static propTypes = {
    className: PropTypes.string,
    helpText: PropTypes.node,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }

  state = {
    uploading: false,
  }

  _handleFileInputChange(event) {
    const reader = new FileReader()
    const image = event.target.files[0]

    reader.onload = this._readLocalImage.bind(this)

    if (image instanceof Blob) {
      reader.readAsDataURL(image)
    }
  }

  _readLocalImage(event) {
    this.setState({ uploading: true })
    savePhoto(event.target.result)
      .then((resp) => {
        this.props.onChange(
          resp.secure_url.replace(
            'upload',
            `upload/${REGISTRY_PROFILE_IMG_TRANSFORM}`
          )
        )
      })
      .catch((err) => this.props.onChange(this.props.value))
      .then(() => this.setState({ uploading: false }))
  }

  render() {
    let fileComponent

    return (
      <div className={this.props.className}>
        <div className="control-label mbm">{this.props.label}</div>

        <div className={css.photoWrapper}>
          <div className={css.uploadPhotoContainer}>
            <UploadPhotoPreview
              photo={this.props.value}
              uploading={this.state.uploading}
            />
            <input
              ref={(c) => {
                fileComponent = c
              }}
              accept="image/*"
              className={css.fileUploader}
              id="fileUploadInput"
              type="file"
              onChange={this._handleFileInputChange.bind(this)}
            />
          </div>

          <div className={css.helpTextContainer}>{this.props.helpText}</div>

          {this.props.value ? (
            <div
              onClick={() => {
                fileComponent.value = ''
                this.props.onChange(null)
              }}
            >
              <i className={classNames('fa fa-trash-o', css.removePhotoIcon)} />
              <Link className={css.removePhotoLinkText}>Remove Photo</Link>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

export default PhotoUploader
