import React from 'react'
import css from './ContentContainer.styles.scss'

interface ContentContainerProps {
  children?: React.ReactNode
}

export const ContentContainer = ({ children }: ContentContainerProps) => (
  <div className={css.ContentContainer}>{children}</div>
)
