/* eslint-disable import/no-extraneous-dependencies */
import { FC, useState } from 'react'
import { Button, Filter, Image, TextLink } from 'baby-design'
import { RightArrow } from 'baby-design/icons'
import { useQuery } from '@tanstack/react-query'
import dateformat from 'dateformat'
import Calendar from '../../(home)/components/components/FeaturedNewsSection/icons/Calendar.svg'
import {
  FETCH_PRESS_UPDATES_KEY,
  fetchPressUpdates,
} from '../../../api/queries'

import css from './AboutPress.styles.scss'
import FileDownload from './icons/FileDownload.svg'

type NewsType = 'award' | 'news' | 'press release'

interface PressUpdate {
  id: string | number
  publishedDate: string
  url: string
  title: string
  date: string
  description: string
  newsType: NewsType
  isFeaturedArticle: boolean
  featuredImageUrl: string
  source: string
  logo: string
}
interface FeaturedNews {
  featuredArticle: PressUpdate
  secondaryArticles: PressUpdate[]
}

const AboutPress: FC = () => {
  const pressUpdatesQuery = useQuery<FeaturedNews>({
    queryKey: [FETCH_PRESS_UPDATES_KEY],
    queryFn: fetchPressUpdates,
  })

  const featuredArticle = pressUpdatesQuery?.data
    ?.featuredArticle as PressUpdate

  const [selectedFilter, setSelectedFilter] = useState<'all' | NewsType>('all')

  const dateFormatString = 'mmm d, yyyy'

  return (
    <div className={css.AboutPress}>
      <section className={css.AboutPress__heroSection}>
        <h1 className={css.AboutPress__heroHeading}>Press</h1>
        <div className={css.AboutPress__heroContentContainer}>
          <div className={css.AboutPress__heroDescriptionPositioningContainer}>
            <div className={css.AboutPress__heroDescriptionContainer}>
              <Image
                className={css.AboutPress__heroCompanyLogo}
                src={featuredArticle?.logo as string}
              />
              <h2 className={css.AboutPress__heroArticleTitle}>
                {featuredArticle?.title as string}
              </h2>
              <TextLink
                className={css.AboutPress__heroReadMoreLink}
                href={featuredArticle?.url as string}
                icon={{ side: 'right', icon: <RightArrow /> }}
                innerContentClassName={css.AboutPress__heroReadMoreLinkInner}
                size="lg"
                weight="medium"
              >
                Read More
              </TextLink>
            </div>
          </div>
          <div className={css.AboutPress__heroImageContainer}>
            <Image
              className={css.AboutPress__heroImage}
              src={featuredArticle?.featuredImageUrl as string}
            />
          </div>
        </div>
      </section>
      <section className={css.AboutPress__latestNewsSection}>
        <div className={css.AboutPress__latestNewsContentContainer}>
          <div className={css.AboutPress__latestNewsHeadingContainer}>
            <h2 className={css.AboutPress__latestNewsHeading}>Latest news</h2>
            <div className={css.AboutPress__latestNewsFilters}>
              <Filter
                isPressed={selectedFilter === 'all'}
                size="lg"
                type="secondary"
                variant={selectedFilter === 'all' ? 'fill' : 'outline'}
                onClick={() => setSelectedFilter('all')}
              >
                All
              </Filter>
              <Filter
                isPressed={selectedFilter === 'news'}
                size="lg"
                type="secondary"
                variant={selectedFilter === 'news' ? 'fill' : 'outline'}
                onClick={() => setSelectedFilter('news')}
              >
                News
              </Filter>
              <Filter
                isPressed={selectedFilter === 'award'}
                size="lg"
                type="secondary"
                variant={selectedFilter === 'award' ? 'fill' : 'outline'}
                onClick={() => setSelectedFilter('award')}
              >
                Awards
              </Filter>
              <Filter
                isPressed={selectedFilter === 'press release'}
                size="lg"
                type="secondary"
                variant={
                  selectedFilter === 'press release' ? 'fill' : 'outline'
                }
                onClick={() => setSelectedFilter('press release')}
              >
                Press Releases
              </Filter>
            </div>
          </div>
          <div className={css.AboutPress__latestNewsEntries}>
            {pressUpdatesQuery?.data?.secondaryArticles
              ?.filter(
                (pressUpdate: PressUpdate) =>
                  pressUpdate.newsType === selectedFilter ||
                  selectedFilter === 'all'
              )
              .map((pressUpdate: PressUpdate) => (
                <div className={css.AboutPress__entry} key={pressUpdate.id}>
                  <div className={css.AboutPress__entryImageWrapper}>
                    <Image
                      className={css.AboutPress__entryImage}
                      src={pressUpdate.logo}
                      width={193}
                    />
                  </div>
                  <div className={css.AboutPress__entryDetails}>
                    <p className={css.AboutPress__entryDateWrapper}>
                      <span className={css.AboutPress__entryDate}>
                        <Calendar className={css.AboutPress__calendarIcon} />
                        {dateformat(pressUpdate.date, dateFormatString)}
                      </span>
                      <span className={css.AboutPress__entrySource}>
                        {pressUpdate.source}
                      </span>
                    </p>
                    <h3 className={css.AboutPress__entryTitle}>
                      {pressUpdate.title}
                    </h3>
                    <p className={css.AboutPress__entryDescription}>
                      {pressUpdate.description}
                    </p>
                    <TextLink
                      className={css.AboutPress__entryReadMoreLink}
                      href={pressUpdate.url}
                      icon={{ side: 'right', icon: <RightArrow /> }}
                      innerContentClassName={
                        css.AboutPress__entryReadMoreLinkInner
                      }
                      size="lg"
                    >
                      Go to article
                    </TextLink>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <section className={css.AboutPress__pressResourcesSection}>
        <div className={css.AboutPress__pressResourcesHeadingContainer}>
          <h2 className={css.AboutPress__pressResourcesHeading}>
            Press resources
          </h2>
          <p className={css.AboutPress__pressResourcesDescription}>
            Writing a story about Babylist? Get in touch with our press team at{' '}
            <span className={css.AboutPress__pressResourcesEmail}>
              press@babylist.com
            </span>
            .
          </p>
        </div>
        <Button
          download="babylist-presskit-logos"
          href="/images/press/babylist-presskit-logos.zip"
          icon={{
            side: 'left',
            icon: <FileDownload className={css.AboutPress__downloadIcon} />,
          }}
          size="md"
          variant="de-emphasized"
        >
          Download media assets
        </Button>
      </section>
    </div>
  )
}

export default AboutPress
