import { RegItemMinimalCardDetailsProps } from './RegItemMinimalCardDetails.types'
import { getRegItemDetailsComponent } from './RegItemMinimalCardDetails.utils'

const RegItemMinimalCardDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  registry,
  regItem,
  reservation,
}: RegItemMinimalCardDetailsProps) => {
  const RegItemMinimalCardDetailsVariant = getRegItemDetailsComponent(
    regItem,
    reservation
  )

  return (
    <RegItemMinimalCardDetailsVariant
      regItem={regItem}
      registry={registry}
      reservation={reservation}
    />
  )
}

export default RegItemMinimalCardDetails
