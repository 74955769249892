import RetailerReturn from './retailer-return'
import {
  RETURNS_BUY_BUY_BABY_URL,
  RETURNS_BUY_BUY_BABY_PHONE,
} from 'shared/constants'

const BuyBuyBabyReturn = ({ orderNum, giftGiverName, giftGiverEmail }) => {
  return (
    <RetailerReturn
      storeName="Buy Buy Baby"
      phoneNumber={RETURNS_BUY_BUY_BABY_PHONE}
      returnUrl={RETURNS_BUY_BUY_BABY_URL}
      orderNum={orderNum}
      giftGiverName={giftGiverName}
      giftGiverEmail={giftGiverEmail}
    />
  )
}

export default BuyBuyBabyReturn
