import { FC } from 'react'
import { Modal, ModalBody } from 'components/modal'
import { useQueryClient } from '@tanstack/react-query'
import { FETCH_REGISTRY_KEY } from 'src/api/queries'
import RegistryVisibilityForm from './RegistryVisibilityForm/RegistryVisibilityForm'
import { ExtendedRegistry } from '../../../RegistryViewHeader.types'

interface PrivacySettingsEditModalProps {
  currentRegistry?: ExtendedRegistry
  handleClose: () => void
  isOpen: boolean
}

const PrivacySettingsEditModal: FC<PrivacySettingsEditModalProps> = ({
  currentRegistry,
  handleClose,
  isOpen,
}) => {
  const queryClient = useQueryClient()

  const onSubmitSuccess = () => {
    queryClient.invalidateQueries([FETCH_REGISTRY_KEY, currentRegistry?.id])
    handleClose()
  }

  return (
    <Modal
      showCloseButton
      show={isOpen}
      title="Visibility And Privacy Settings"
      onHide={handleClose}
    >
      <ModalBody>
        <RegistryVisibilityForm
          registry={currentRegistry}
          onSubmitSuccess={onSubmitSuccess}
        />
      </ModalBody>
    </Modal>
  )
}

export default PrivacySettingsEditModal
