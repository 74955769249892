import PropTypes from 'prop-types'
import Link from 'components/link'
import { CollapseIcon, ExpandIcon } from 'components/icons'
import { setIsAllCategoriesCollapsed } from '../../actions'
import { getIsAllCategoriesCollapsed } from '../../reducers'
import { connect } from 'react-redux'

const ToggleCollapseAllCategoriesLink = ({
  setIsAllCategoriesCollapsed,
  isAllCollapsed,
  ...props
}) => (
  <Link
    {...props}
    style={{ width: 24 }}
    onClick={() => setIsAllCategoriesCollapsed(!isAllCollapsed)}
  >
    {isAllCollapsed ? <ExpandIcon /> : <CollapseIcon />}
  </Link>
)

ToggleCollapseAllCategoriesLink.propTypes = {
  setIsAllCategoriesCollapsed: PropTypes.func.isRequired,
  isAllCollapsed: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isAllCollapsed: getIsAllCategoriesCollapsed(state),
})

export default connect(mapStateToProps, { setIsAllCategoriesCollapsed })(
  ToggleCollapseAllCategoriesLink
)
