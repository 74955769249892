import { FC } from 'react'
import { Image, NavLink, TextLink } from 'baby-design'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import {
  facebookUrl,
  indexPath,
  instagramUrl,
  linkedInUrl,
  privacyPolicyPath,
  registryHomePath,
  termsOfUsePath,
  twitterUrl,
  youtubeUrl,
  tiktokUrl,
} from 'lib/urls'
import TikTok from '../icons/TikTok.svg'
import Facebook from '../icons/Facebook.svg'
import X from '../icons/X.svg'
import Instagram from '../icons/Instagram.svg'
import LinkedIn from '../icons/LinkedIn.svg'
import YouTube from '../icons/YouTube.svg'
import { navLinks } from '../Navbar/Navbar'

import css from './Footer.styles.scss'

const Footer: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [currentUser] = useCurrentUser()
  const currentYear = new Date().getFullYear()

  const isCareersPage = pathname === '/careers'

  return (
    <footer className={css.Footer}>
      <div className={css.Footer__contentContainer}>
        <TextLink
          className={css.Footer__logoLink}
          href={currentUser ? registryHomePath : indexPath}
          innerContentClassName={css.Footer__logoLinkLabel}
        >
          <Image
            alt="Babylist Logo"
            className={css.Footer__logo}
            height={24}
            src="https://images.babylist.com/image/upload/f_auto,q_auto/v1717187527/Corporate%20About%20Pages/BL_logo.png"
          />
          <span className="sr-only">Navigate to Babylist's home page.</span>
        </TextLink>
        <nav className={css.Footer__links}>
          {navLinks.map(({ ariaLabel, id, label, path }) => (
            <NavLink
              aria-label={ariaLabel}
              className={classNames(css.Footer__link, {
                [css['Footer__link--careers']]: path === '/about/careers',
                [css['Footer__link--press']]: path === '/about/press',
              })}
              href={path}
              key={id}
              size="lg"
              onClick={(e) => {
                e.preventDefault()
                navigate(path)
              }}
            >
              {label}
            </NavLink>
          ))}
        </nav>
        <div className={css.Footer__socialLinks}>
          <TextLink
            className={css.Footer__socialLink}
            href={tiktokUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <TikTok className={css.Footer__socialIcon} />
            <span className="sr-only">Navigate to Babylist's TikTok page.</span>
          </TextLink>
          <TextLink
            className={css.Footer__socialLink}
            href={facebookUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <Facebook className={css.Footer__socialIcon} />
            <span className="sr-only">
              Navigate to Babylist's Facebook page.
            </span>
          </TextLink>
          <TextLink
            className={css.Footer__socialLink}
            href={twitterUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <X className={css.Footer__socialIcon} />
            <span className="sr-only">Navigate to Babylist's X page.</span>
          </TextLink>
          <TextLink
            className={css.Footer__socialLink}
            href={instagramUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <Instagram className={css.Footer__socialIcon} />
            <span className="sr-only">
              Navigate to Babylist's Instagram page.
            </span>
          </TextLink>
          <TextLink
            className={css.Footer__socialLink}
            href={linkedInUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <LinkedIn className={css.Footer__socialIcon} />
            <span className="sr-only">
              Navigate to Babylist's LinkedIn page.
            </span>
          </TextLink>
          <TextLink
            className={css.Footer__socialLink}
            href={youtubeUrl}
            innerContentClassName={css.Footer__socialLinkLabel}
            target="_blank"
          >
            <YouTube className={css.Footer__socialIcon} />
            <span className="sr-only">
              Navigate to Babylist's YouTube page.
            </span>
          </TextLink>
        </div>
      </div>
      <div className={css.Footer__legal}>
        <div className={css.Footer__legalRow}>
          ©{currentYear} Babylist, Inc. All rights reserved.
        </div>
        <span className={css.Footer__responsiveDivider}>|</span>
        <div className={css.Footer__legalRow}>
          <TextLink
            className={css.Footer__legalLink}
            href={termsOfUsePath}
            size="sm"
            weight="regular"
          >
            Terms and Conditions
          </TextLink>
          <span>|</span>
          <TextLink
            className={css.Footer__legalLink}
            href={privacyPolicyPath}
            size="sm"
            weight="regular"
          >
            Privacy Policy
          </TextLink>
        </div>
      </div>
      {isCareersPage && (
        <div className={css.Footer__FortuneDisclaimer}>
          <p className={css.Footer__FortuneDisclaimerText}>
            From <i>Fortune</i>. ©2024 <i>Fortune</i> Media IP Limited. All
            rights reserved. Used under license. <i>Fortune</i> and{' '}
            <i>Fortune</i> Media IP Limited are not affiliated with, and do not
            endorse the products or services of, Babylist.
          </p>
        </div>
      )}
    </footer>
  )
}

export default Footer
