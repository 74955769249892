import React, { memo } from 'react'
import classNames from 'classnames'
import Image from 'components/image'
import { CONFIG } from 'shared/constants'
import css from './StatsBar.styles.scss'

interface StatProps {
  stat?: string | string[] | number
  heading: string
  icnUrl?: string
  style?: React.CSSProperties
}

const Stat = ({ stat, heading, icnUrl, style }: StatProps) => {
  if (!stat) return null

  const content = Array.isArray(stat) ? stat.join(', ') : stat.toString()
  const key = `${heading}-${content}`

  return (
    <div className={css.stat} key={key}>
      <div className={css.heading}>{heading}</div>
      <div className={css.wrapper}>
        {icnUrl && (
          <Image className={css.icn} role="presentation" src={icnUrl} />
        )}
        <div aria-label={content} className={css.content} style={style}>
          {!style && content}
        </div>
      </div>
    </div>
  )
}

export interface StatsBarProps {
  stats: {
    clothingSize?: string[]
    shoeSize?: {
      group: string
      size: string
    }[]
    favoriteColor?: string
    nextAge?: number
    currentAge?: string | number
  }
  glowupEnabled?: boolean
}

const statIcons = {
  clothingSize:
    '//images.babylist.com/image/upload/v1692141821/icn_stat_shirt_qacn6u.svg',
  shoeSize:
    '//images.babylist.com/image/upload/v1692141821/icn_stat_foot_thv6az.svg',
  favoriteColor:
    '//images.babylist.com/image/upload/v1692141821/icn_stat_palette_f12cam.svg',
  nextAge:
    '//images.babylist.com/image/upload/v1692141821/icn_stat_cupcake_pmg3le.svg',
  currentAge: undefined,
}

const colorNameToHexMapping: { [key: string]: string | string[] } =
  CONFIG.registryAttributeDataOptions?.favoriteColorOptions.reduce<{
    [key: string]: string | string[]
  }>((acc, colorOption) => {
    acc[colorOption.name] = colorOption.hex
    return acc
  }, {}) || {}

const getFavoriteColorStyle = (color: string): React.CSSProperties => {
  const baseStyle: React.CSSProperties = {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
  }

  const hexValue = colorNameToHexMapping[color]
  if (typeof hexValue === 'string') {
    baseStyle.backgroundColor = `#${hexValue}`
  } else if (Array.isArray(hexValue)) {
    baseStyle.background = `linear-gradient(-45deg, ${hexValue
      .map((hex) => `#${hex}`)
      .join(', ')})`
  }
  if (color === 'white') {
    baseStyle.border = '1px solid #a5a5a5'
  }

  return baseStyle
}

const StatsBar = ({ glowupEnabled = false, stats }: StatsBarProps) => {
  const renderStat = (statKey: keyof StatsBarProps['stats']) => {
    const icnUrl = statIcons[statKey]

    switch (statKey) {
      case 'clothingSize':
        return (
          stats.clothingSize && (
            <Stat
              heading={glowupEnabled ? 'Clothing' : 'Clothing Size'}
              icnUrl={icnUrl}
              stat={stats.clothingSize.join(', ')}
            />
          )
        )
      case 'shoeSize':
        const firstShoe = stats.shoeSize?.[0]
        return (
          firstShoe && (
            <Stat
              heading={firstShoe.group}
              icnUrl={icnUrl}
              stat={firstShoe.size}
            />
          )
        )
      case 'favoriteColor':
        if (stats.favoriteColor) {
          const style = getFavoriteColorStyle(stats.favoriteColor)
          return (
            <Stat
              heading="Favorite"
              icnUrl={icnUrl}
              stat={stats.favoriteColor}
              style={style}
            />
          )
        }
        return null
      case 'nextAge':
        return (
          stats.nextAge !== undefined && (
            <Stat heading="Turning" icnUrl={icnUrl} stat={stats.nextAge} />
          )
        )
      case 'currentAge':
        return (
          stats.currentAge !== undefined && (
            <Stat heading="Age" icnUrl={icnUrl} stat={stats.currentAge} />
          )
        )
      default:
        return null
    }
  }

  const rootClasses = classNames(css.root, {
    [css.glowupEnabled]: glowupEnabled,
  })

  return (
    <div className={rootClasses} data-testid="StatsBar">
      {Object.keys(stats).map((statKey) =>
        renderStat(statKey as keyof StatsBarProps['stats'])
      )}
    </div>
  )
}

export default memo(StatsBar)
