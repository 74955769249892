import React, { FC } from 'react'
import { shipmentViewPath } from 'lib/urls'
import { useUIContext } from 'src/contexts/UIContext'
import { Badge, TextLink } from 'baby-design'
import { LeftChevron } from 'baby-design/icons'
import {
  currentUserBundle,
  itemsAndOrdersLabel,
  shipmentDetailsStatusBadge,
} from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import css from './ShipmentDetailsTitle.styles.scss'
import { ShipmentDetailsTitleProps } from './ShipmentDetailsTitle.types'

interface LoadingSkeletonProps {
  children: React.ReactNode
  isLoading: boolean
}

const LinkBackToShipments: FC = () => (
  <TextLink
    href={shipmentViewPath}
    icon={{
      side: 'left',
      size: 'lg',
      icon: <LeftChevron filled />,
    }}
    size="md"
    weight="regular"
  >
    Shipments
  </TextLink>
)

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  children,
  isLoading,
}) => <>{isLoading ? <div className={css.shimmer} /> : children}</>

const ShipmentDetailsTitle: React.FC<ShipmentDetailsTitleProps> = ({
  shipment,
}) => {
  const { appWebView } = useUIContext()

  if (appWebView) {
    return null
  }

  const badge = shipment && shipmentDetailsStatusBadge(shipment)
  return (
    <div
      className={css.ShipmentDetailsTitle}
      data-testid="shipment-details-title"
    >
      <LinkBackToShipments />
      <div className={css.ShipmentDetailsTitle__TitleContainer}>
        <LoadingSkeleton isLoading={!shipment}>
          <h1>
            {shipment && currentUserBundle(shipment) ? 'Bundle' : 'Shipment'}{' '}
            Details
          </h1>
          {badge && <Badge size="medium" variant="status" {...badge} />}
        </LoadingSkeleton>
      </div>
      <LoadingSkeleton isLoading={!shipment}>
        <p>{shipment && itemsAndOrdersLabel(shipment)}</p>
      </LoadingSkeleton>
    </div>
  )
}

export default ShipmentDetailsTitle
