import fetch from 'lib/fetch'
import { useQuery } from '@tanstack/react-query'

export const MINUMUM_SEARCH_TERM_LENGTH = 3
const CACHE_KEY = 'search_suggestions'
const API_PATH = '/store/api/search'

export interface SearchSuggestion {
  searchTerm: string
  path: string
}

const fetchSuggestions = async (
  searchTerm: string
): Promise<SearchSuggestion[]> =>
  await fetch(`${API_PATH}/suggestions?q=${searchTerm}`)

const useSuggestions = (searchTerm: string | undefined) => {
  if (searchTerm === undefined) {
    throw new Error('searchTerm must be defined')
  }
  return useQuery({
    queryKey: [CACHE_KEY, searchTerm],
    queryFn: () => fetchSuggestions(searchTerm),
    enabled: searchTerm.length >= MINUMUM_SEARCH_TERM_LENGTH,
  })
}

export const SearchAPI = {
  useSuggestions,
}
