import { apiV3CreateGiftGiverReminderPath } from 'lib/urls'
import { Registry } from 'src/types/registry'
import fetch from 'lib/fetch'
import { track, useTracking } from 'lib/analytics'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useCallback, useEffect, useRef } from 'react'

type SubmitReminderProps = {
  registry: Registry
  email: string
  token: string
}

export const submitReminder = ({
  registry,
  email,
  token,
}: SubmitReminderProps) =>
  fetch(apiV3CreateGiftGiverReminderPath(registry.id), {
    method: 'POST',
    body: JSON.stringify({ email, token }),
  })

export const registryShowerDateKnown = (registry: Registry): boolean =>
  registry.showerInfo?.showers?.some((shower) => shower.date !== null) ?? false

export const useGiftGiverReminderModalTracking = (registry: Registry) => {
  const [currentUser] = useCurrentUser()
  const hasTrackedView = useRef(false)
  const tracker = useTracking()
  const sharedTrackingProps = trackingRegistryProperties({
    registry,
    currentUser,
  })

  const trackModalViewed = useCallback(() => {
    if (!hasTrackedView.current) {
      tracker.trackEvent({
        event: track.giftGiverReminderModalViewed,
        ...sharedTrackingProps,
      })
      hasTrackedView.current = true
    }
  }, [tracker, sharedTrackingProps])

  const trackModalSubmitted = useCallback(
    ({ eventCta, success }: { eventCta: string; success: boolean }) => {
      tracker.trackEvent({
        event: track.giftGiverReminderModalSubmitted,
        eventCta,
        eventType: success
          ? track.EventType.SUCCESSFUL
          : track.EventType.SUBMISSION_FAILED,
        ...sharedTrackingProps,
      })
    },
    [tracker, sharedTrackingProps]
  )

  const trackModalDismissed = useCallback(
    ({ eventCta }: { eventCta: string }) => {
      tracker.trackEvent({
        event: track.giftGiverReminderModalDismissed,
        eventCta,
        ...sharedTrackingProps,
      })
    },
    [tracker, sharedTrackingProps]
  )

  useEffect(() => trackModalViewed(), [trackModalViewed])
  return { trackModalSubmitted, trackModalDismissed }
}
