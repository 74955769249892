import React, { useState } from 'react'
import classNames from 'classnames'
// eslint-disable-next-line
import { TextLink } from 'baby-design'
import RegItemMarkPurchasedCheckbox from '../../../../RegItemMarkPurchasedCheckbox/RegItemMarkPurchasedCheckbox'
import RegItemOffer from '../../../../RegItemOffer/RegItemOffer'
import { RegItemBuyingOptionsProps } from '../../../RegItemBuyingOptionsModal.types'
import { COMPONENT_NAME } from './BuyItOrMarkAsPurchased.constants'
import { findBabylistOfferFor } from './BuyItOrMarkAsPurchased.utils'
import css from './BuyItOrMarkAsPurchased.styles.scss'

const BuyItOrMarkAsPurchased: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
  registry,
}) => {
  const [isShowingOptions, setIsShowingOptions] = useState(false)
  const wrapperClasses = classNames(css[COMPONENT_NAME], {
    [css[`${COMPONENT_NAME}--isShowingOptions`]]: isShowingOptions,
  })
  const babylistOffer = findBabylistOfferFor(regItem)

  if (!babylistOffer) return null

  return (
    <div className={wrapperClasses} data-testid={COMPONENT_NAME}>
      {isShowingOptions ? (
        <>
          <div className={css[`${COMPONENT_NAME}__regItemOffer`]}>
            <RegItemOffer
              offer={babylistOffer}
              regItem={regItem}
              registry={registry}
            />
          </div>
          <RegItemMarkPurchasedCheckbox regItem={regItem} registry={registry} />
        </>
      ) : (
        <TextLink underline size="md" onClick={() => setIsShowingOptions(true)}>
          Buy it or mark as purchased
        </TextLink>
      )}
    </div>
  )
}

export default BuyItOrMarkAsPurchased
