import { storeSearchResultsPath } from 'lib/urls'
import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import css from './BuyBox.styles.scss'

export const BrandHeader = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { brand, brandPageUrl } = genericProduct

  return (
    <div className={css.BuyBox__brand} data-testid="brand">
      <a href={brandPageUrl || storeSearchResultsPath(brand)}>{brand}</a>
    </div>
  )
}
