import { BackBanner } from 'components/banners'
import { registryPath } from 'lib/urls'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { Registry } from 'src/types/registry'
import { isRegistryContributor } from '../../utils/isRegistryContributor/isRegistryContributor'

interface RegistryBackBannerPortalProps {
  registry: Registry
}

const RegistryBackBannerPortal = ({
  registry,
}: RegistryBackBannerPortalProps) => {
  const [currentUser] = useCurrentUser()
  const [portalContainer, setPortalContainer] = useState<
    HTMLElement | undefined
  >()

  useEffect(() => {
    if (typeof document === 'undefined') return

    const headerEl = document.getElementById('header')

    if (headerEl) {
      const newElement = document.createElement('div')
      headerEl.prepend(newElement)
      setPortalContainer(newElement)
    }
  }, [])

  if (!portalContainer || !isRegistryContributor(currentUser, registry.urlSlug))
    return null

  return createPortal(
    <BackBanner
      backText="Back to edit registry"
      backUrl={registryPath(registry.urlSlug)}
      fixed={false}
      helpText="This is what your registry looks like to a guest."
    />,
    portalContainer
  )
}

export default RegistryBackBannerPortal
