const Chevron = ({ className }: { className?: string }) => (
  <svg
    className={className}
    fill="none"
    height="8"
    viewBox="0 0 12 8"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M0.41107 0.744078C0.736507 0.418641 1.26414 0.418641 1.58958 0.744078L6.00033 5.15482L10.4111 0.744078C10.7365 0.418641 11.2641 0.418641 11.5896 0.744078C11.915 1.06951 11.915 1.59715 11.5896 1.92259L6.58958 6.92259C6.26414 7.24803 5.73651 7.24803 5.41107 6.92259L0.41107 1.92259C0.0856329 1.59715 0.0856329 1.06951 0.41107 0.744078Z"
      fill="#6E3264"
      fillRule="evenodd"
    />
  </svg>
)

export default Chevron
