import React from 'react'
import { ShipNowBannerProps } from 'src/routes/(registry)/shipments/components/ShipNowBanner/ShipNowBanner.types'
import Banner from '../../../../../../baby-design/components/Banner/Banner'

const ShipNowBanner: React.FC<ShipNowBannerProps> = ({
  className,
  onShipNow,
}) => (
  <Banner
    bodyCopy="We'll ship your bundle now."
    button={{
      buttonText: 'Ship now',
      buttonVariant: 'primary',
      onClick: onShipNow,
    }}
    className={className}
    color="green"
    dismissable={false}
    headline="Get your items sooner"
    variant="ultra-compact"
  />
)

export default ShipNowBanner
