import { ReactNode } from 'react'
import css from './Layout.styles.scss'

interface ColumnProps {
  children: ReactNode
  width: string
}

interface ColumnStyleProps {
  width: string
}

interface ColumnStyle {
  flexBasis?: string | undefined
}

const columnStyle = ({ width }: ColumnStyleProps) => {
  const style: ColumnStyle = {}

  if (width) style.flexBasis = width

  return style
}

export const Column = ({ width, children }: ColumnProps) => (
  <div className={css.LayoutColumn} style={columnStyle({ width })}>
    {children}
  </div>
)

export const StickyColumn = ({ children }: { children: ReactNode }) => (
  <div className={css.LayoutStickyContainer}>
    <div className={css.LayoutStickyElement}>{children}</div>
  </div>
)
