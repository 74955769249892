import React, { useLayoutEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CopyAndIcon } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BabyGear, Parenting2 } from 'baby-design/icons'
import { BREAKPOINT_TABLET } from 'src/constants'
import RegistryDescription from './components/RegistryDescription'
import RegistrantLocation from './components/RegistrantLocation'
import { RegistryGiftGiverHeaderProps } from '../RegistryGiftGiverHeader.types'
import styles from './AdditionalHeaderDetails.styles.scss'

const AdditionalHeaderDetails: React.FC<RegistryGiftGiverHeaderProps> = ({
  registry,
}) => {
  const { description, headerDetails } = registry
  const { arrivalDateDisplay, location, names } = headerDetails

  const [windowFormat, setWindowFormat] = useState<'desktop' | 'mobile'>(
    'desktop'
  )
  const size = windowFormat === 'desktop' ? 'md' : 'sm'
  const { width } = useWindowSize()

  useLayoutEffect(() => {
    if (width < BREAKPOINT_TABLET) {
      setWindowFormat('mobile')
    } else {
      setWindowFormat('desktop')
    }
  }, [width])

  return (
    <div
      className={styles.AdditionalHeaderDetails}
      data-testid="AdditionalHeaderDetails"
    >
      <CopyAndIcon copy={names} icon={<Parenting2 />} size={size} />

      {location && (
        <RegistrantLocation registry={registry} windowFormat={windowFormat} />
      )}

      {arrivalDateDisplay && (
        <div data-testid="AdditionalHeaderDetails-arrival-date-display">
          <CopyAndIcon
            copy={arrivalDateDisplay}
            icon={<BabyGear />}
            size={size}
          />
        </div>
      )}

      {description && (
        <RegistryDescription
          description={description}
          windowFormat={windowFormat}
        />
      )}
    </div>
  )
}

export default AdditionalHeaderDetails
