import { RegItemMinimal } from 'src/types/regItem'
import { Reservation } from 'src/types/reservation'
import { MOST_WANTED_TITLE } from '../RegistryCategories/RegistryCategories.utils'
import { AppliedSortsAndFilters } from '../RegistryGiftGiverPage/RegistryGiftGiverPage.types'
import {
  filterRegItems,
  mergeSortsAndFilters,
} from '../RegistryGiftGiverPage/RegistryGiftGiverPage.utils'

export const isLeftArrowVisible = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  targetScrollLeft: number
): boolean => {
  if (wrapperRef.current) {
    return isContentOverflowing(wrapperRef) && targetScrollLeft > 0
  }

  return false
}

export const isRightArrowVisible = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  targetScrollLeft: number
): boolean => {
  if (wrapperRef.current) {
    return (
      isContentOverflowing(wrapperRef) &&
      targetScrollLeft <
        wrapperRef.current?.scrollWidth - wrapperRef.current?.clientWidth
    )
  }

  return false
}

const isContentOverflowing = (wrapperRef: React.RefObject<HTMLDivElement>) =>
  !!(
    wrapperRef.current &&
    wrapperRef.current?.scrollWidth > wrapperRef.current?.clientWidth
  )

interface HandleScrollClickProps {
  direction: 'left' | 'right'
  wrapperRef: React.RefObject<HTMLDivElement>
}

export const handleScrollClick = ({
  direction,
  wrapperRef,
}: HandleScrollClickProps) => {
  if (wrapperRef.current) {
    const wrapperEl = wrapperRef.current
    const { scrollWidth, clientWidth, scrollLeft } = wrapperEl
    let left: number

    if (direction === 'left') {
      left = Math.max(scrollLeft - clientWidth, 0)
    } else {
      left = Math.min(scrollLeft + clientWidth, scrollWidth - clientWidth)
    }
    wrapperEl.scrollTo({ left, behavior: 'smooth' })

    return left
  }

  return 0
}

export const getCategoryCounts = (
  regItems: RegItemMinimal[],
  reservations: Reservation[],
  appliedSortsAndFilters: AppliedSortsAndFilters
) => {
  const categoryCounts: Record<number | string, number> = { All: 0 }

  const sortsAndFiltersWithoutCategory = mergeSortsAndFilters(
    { filters: { category: 'All' } },
    appliedSortsAndFilters
  )

  const filteredRegItems = filterRegItems(
    regItems,
    sortsAndFiltersWithoutCategory.filters
  )

  filteredRegItems.forEach((regItem) => {
    const categoryId = regItem.categoryId || 0

    const existingCategoryCount = categoryCounts[categoryId] || 0
    categoryCounts[categoryId] = existingCategoryCount + 1

    if (regItem.isFavorite) {
      const existingMostWantedCount = categoryCounts[MOST_WANTED_TITLE] || 0
      categoryCounts[MOST_WANTED_TITLE] = existingMostWantedCount + 1
    }

    categoryCounts.All += 1
  })

  categoryCounts.Purchased = reservations.length

  return categoryCounts
}
