import React from 'react'
import ChecklistButton from 'src/routes/(registry)/list/[slug]/components/RegistryItemsList/EmptyRegistryItemsList/components/ChecklistButton'
import AddItemsButton from 'src/routes/(registry)/list/[slug]/components/RegistryItemsList/EmptyRegistryItemsList/components/AddItemsButton'
import { track, withContextualizedTracking } from 'lib/analytics'
import css from './EmptyRegistryItemsList.styles.scss'

interface EmptyRegistryItemsListProps {
  listType?: string | null
}

const EmptyRegistryItemsList: React.FC<EmptyRegistryItemsListProps> = ({
  listType,
}) => {
  const isBabyRegistry = listType === null || listType === 'baby'

  return (
    <div className={css.RegistryItemsList__noItems}>
      <div className={css.RegistryItemsList__noItemsTextContainer}>
        <h3 className={css.RegistryItemsList__noItemsHeading}>
          You haven't added anything yet!
        </h3>
        {isBabyRegistry && (
          <p className={css.RegistryItemsList__noItemsText}>
            Explore our checklist to start building your registry.
          </p>
        )}
      </div>
      {isBabyRegistry ? <ChecklistButton /> : <AddItemsButton />}
    </div>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY_EMPTY_STATE,
})(EmptyRegistryItemsList)
