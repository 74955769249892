import { RegistrySortFilterOptions } from 'src/types/registrySortFilterOptions'

export const getPriceTitle = (
  price: { min: number; max: number } | undefined
): string => {
  if (!price) return 'Price'

  return `${price.min}-${price.max}`
}

export const getStoreTitle = (
  stores: string[] | undefined,
  sortFilterOptions: RegistrySortFilterOptions
): string => {
  if (stores?.length && stores?.length > 1) return `Store (${stores?.length})`
  if (stores?.length === 1) {
    const store = stores[0]
    const storeOption = sortFilterOptions.filters.store.options.find(
      (option) => option.value === store
    )
    return storeOption?.name || ''
  }

  return 'Store'
}

export const getSortTitle = (
  sort: string | undefined,
  sortFilterOptions: RegistrySortFilterOptions,
  isMobile: boolean
): string => {
  if (!sort || isMobile) return 'Sort'

  const sortOption = Object.values(sortFilterOptions.sorts).find(
    (option) => option.key === sort
  )

  return `Sort: ${sortOption?.title || ''}`
}
