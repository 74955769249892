// This is a temporary file to replace the legacy Post container which wraps
// the existing PostView component. This will allow us to switch to folder-based
// routing while keeping the existing PostView in place for legacy support. Once
// the fbr migration is complete, this file can be consolidated with the PostView.
import PostView from 'hello_baby/components/post-view'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingHeart } from 'baby-design'
import {
  useFeaturedPosts,
  useGuidesConfig,
  usePost,
  usePostByIds,
} from '../../utils/useQueries'

interface PostViewAdapterProps {
  emailUrls: Record<string, string>
  slug: string
}

const MIN_RELATED_POSTS_COUNT = 3

const PostViewAdapter = ({ emailUrls, slug }: PostViewAdapterProps) => {
  const post = usePost(slug)
  const { minimalView } = useGuidesConfig()

  const featuredPosts = useFeaturedPosts()?.filter((p) => p.slug !== slug)
  const relatedPosts = usePostByIds(post?.relatedPostIds || [])
  const relevantPosts =
    relatedPosts?.length < MIN_RELATED_POSTS_COUNT
      ? relatedPosts.concat(
          featuredPosts.slice(0, MIN_RELATED_POSTS_COUNT - relatedPosts.length)
        )
      : relatedPosts

  const nextPost = usePostByIds([post?.nextPostId || ''])[0]
  const previousPost = usePostByIds([post?.previousPostId || ''])[0]

  return post ? (
    <PostView
      emailUrls={emailUrls}
      minimalView={minimalView}
      nextPost={nextPost}
      post={post}
      previousPost={previousPost}
      relatedPosts={relevantPosts}
    />
  ) : (
    <LoadingHeart className="center-xy" />
  )
}

export default PostViewAdapter
