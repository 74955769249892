import { FC } from 'react'

import { useGiftGivers } from '../../context/GiftGiversProvider'
import GiftTrackerListItem from './GiftTrackerListItem'
import css from './GiftTrackerList.styles.scss'

const GiftTrackerList: FC = () => {
  const { giftGivers = [] } = useGiftGivers()

  const giftTrackerListItems = giftGivers.map((giftGiver) => (
    <GiftTrackerListItem giftGiver={giftGiver} key={giftGiver.id} />
  ))

  return (
    <div className={css.GiftTrackerList} data-testid="GiftTrackerList">
      {giftTrackerListItems}
    </div>
  )
}

export default GiftTrackerList
