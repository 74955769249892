import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { storePath } from 'lib/urls'
import { useAppliedSortsAndFiltersContext } from '../../../../contexts'
import { RegistryCategoriesEmptyStateProps } from './RegistryCategoriesEmptyState.types'
import css from './RegistryCategoriesEmptyState.styles.scss'
import { defaultAppliedSortsAndFilters } from '../../../RegistryGiftGiverPage/RegistryGiftGiverPage.utils'

const RegistryCategoriesEmptyState: React.FC<
  RegistryCategoriesEmptyStateProps
> = ({ regItems, reservations }: RegistryCategoriesEmptyStateProps) => {
  const { appliedSortsAndFilters, onSortFilterChange } =
    useAppliedSortsAndFiltersContext()
  const selectedCategory = appliedSortsAndFilters.filters?.category

  if (selectedCategory !== 'Purchased' && regItems.length > 0) {
    return null
  }

  if (selectedCategory === 'Purchased' && reservations.length > 0) {
    return null
  }

  const isEverythingPurchased =
    selectedCategory === 'All' &&
    regItems.length === 0 &&
    reservations.length > 0

  let title = 'No gifts found for your selected filters.'
  let subTitle = 'Try adjusting your selection or reset all.'
  let buttonCTA = 'Reset filters'

  if (selectedCategory === 'Purchased') {
    title = 'Nothing has been purchased, yet.'
    subTitle = 'Be the first to purchase something from this registry!'
    buttonCTA = 'Browse registry'
  }

  if (isEverythingPurchased) {
    title = 'Everything on this registry has been purchased.'
    subTitle = 'Find the perfect surprise gift in our Shop.'
    buttonCTA = 'Browse our Shop'
  }

  return (
    <div
      className={css.RegistryCategoriesEmptyState}
      data-testid="RegistryCategoriesEmptyState"
    >
      <div className={css.RegistryCategoriesEmptyState__Title}>{title}</div>

      <div className={css.RegistryCategoriesEmptyState__SubTitle}>
        {subTitle}
      </div>

      <Button
        className={css.RegistryCategoriesEmptyState__ResetFilters}
        size="md"
        variant="tertiary"
        onClick={() => {
          if (isEverythingPurchased) {
            window.location.href = storePath
            return
          }

          onSortFilterChange(defaultAppliedSortsAndFilters)
        }}
      >
        {buttonCTA}
      </Button>
    </div>
  )
}

export default RegistryCategoriesEmptyState
