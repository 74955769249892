import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSwipe from 'react-swipe'
import Tappable from 'react-tappable'
import { DEFAULT_PRODUCT_IMAGE_PATH } from 'shared/constants'
import { last, map, range } from 'lodash'
import classNames from 'classnames'
import { Thumbnail } from 'components/thumbnail-selector'
import Image from 'components/image'

import css from './product-images.scss'
import { tracking } from 'lib/analytics'
import {
  trackImageSwipe,
  trackImageThumbnailClick,
} from 'src/routes/(shop)/lib/tracking'

const VIDEO_IMG_SRC =
  'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%3E%3Cg%20fill%3D%22%234D4D4D%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M19.625%201C9.355%201%201%209.355%201%2019.625S9.355%2038.25%2019.625%2038.25%2038.25%2029.895%2038.25%2019.625%2029.895%201%2019.625%201m0%2038.25C8.804%2039.25%200%2030.446%200%2019.625S8.804%200%2019.625%200%2039.25%208.804%2039.25%2019.625%2030.446%2039.25%2019.625%2039.25%22%2F%3E%3Cpath%20d%3D%22M14.027%2013.095v13.06l13.195-6.53z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E'

const EXPAND_IMG_SRC =
  'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2230%22%20height%3D%2230%22%20viewBox%3D%220%200%2030%2030%22%3E%3Cg%20fill%3D%22%234D4D4D%22%20fill-rule%3D%22evenodd%22%20opacity%3D%22.35%22%3E%3Cpath%20d%3D%22M.625%208.75A.625.625%200%200%201%200%208.125v-7.5C0%20.28.28%200%20.625%200h7.5a.625.625%200%201%201%200%201.25H1.25v6.875c0%20.345-.28.625-.625.625M8.125%2030h-7.5A.625.625%200%200%201%200%2029.375v-7.5a.625.625%200%201%201%201.25%200v6.875h6.875a.625.625%200%201%201%200%201.25M29.375%208.75a.625.625%200%200%201-.625-.625V1.25h-6.875a.625.625%200%201%201%200-1.25h7.5c.346%200%20.625.28.625.625v7.5c0%20.345-.28.625-.625.625M29.375%2030h-7.5a.625.625%200%201%201%200-1.25h6.875v-6.875a.625.625%200%201%201%201.25%200v7.5c0%20.345-.28.625-.625.625%22%2F%3E%3Cpath%20d%3D%22M10.942%2011.568a.623.623%200%200%201-.441-.183L.808%201.692a.625.625%200%200%201%20.884-.884l9.692%209.693a.624.624%200%200%201-.442%201.067M28.442%2029.068a.623.623%200%200%201-.442-.183l-9.692-9.693a.625.625%200%200%201%20.884-.884l9.692%209.693a.624.624%200%200%201-.442%201.067M19.058%2011.568a.623.623%200%200%201-.442-1.067L28.308.808a.624.624%200%201%201%20.884.884L19.5%2011.385a.623.623%200%200%201-.442.183M1.558%2029.068A.623.623%200%200%201%201.116%2028l9.692-9.693a.624.624%200%201%201%20.884.884l-9.693%209.693a.623.623%200%200%201-.441.183%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A'

const PRODUCT_IMAGE_TEXT = 'Product Image '
const SELECTED_TEXT = '- selected'

const getValueText = (idx, isSelected) => {
  const text = `${PRODUCT_IMAGE_TEXT}${Number(idx) + 1}` // add 1 to the index so we start at 1
  if (isSelected) {
    return `${text}${SELECTED_TEXT}`
  }
  return text
}

const getIndexFromValue = (text) =>
  Number(text.replace(PRODUCT_IMAGE_TEXT, '').replace(SELECTED_TEXT, '')) - 1

@tracking()
class ProductImages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex: 0,
      shownIndex: 0,
      loadedImages: this.nextLoadedImages([], 0),
    }

    this.handleTapDot = this.handleTapDot.bind(this)
    this.handleMouseOverThumbnail = this.handleMouseOverThumbnail.bind(this)
    this.handleMouseOutThumbnail = this.handleMouseOutThumbnail.bind(this)
    this.handleClickThumbnail = this.handleClickThumbnail.bind(this)
    this.handleClickVideo = this.handleClickVideo.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { shownIndex, selectedIndex } = this.state
    const { images } = this.props

    return (
      nextState.shownIndex !== shownIndex ||
      nextState.selectedIndex !== selectedIndex ||
      nextProps.images !== images
    )
  }

  componentDidUpdate(prevProps) {
    const { images } = this.props
    if (prevProps.images !== images) {
      this.setState({ selectedIndex: 0, shownIndex: 0 })
    }
  }

  handleMouseOverThumbnail(idx) {
    this.setState({ shownIndex: idx })
  }

  handleMouseOutThumbnail() {
    const { selectedIndex } = this.state
    this.setState({ shownIndex: selectedIndex })
  }

  handleClickThumbnail(value) {
    const idx = getIndexFromValue(value)
    this.setSelectedIndex(idx)
    trackImageThumbnailClick(this.props.tracking.trackEvent)
  }

  handleClickVideo() {
    this.setSelectedIndex(this.videoIndex())
  }

  handleSlideChange(idx) {
    this.setSelectedIndex(idx)
    trackImageSwipe(this.props.tracking.trackEvent)
  }

  handleTapDot(idx) {
    this.slider.slide(idx)
  }

  setSelectedIndex(idx) {
    // safeguard against react-swipe bug where you can swipe past max index
    const nextIndex = idx % this.numSlides()
    this.setState({
      selectedIndex: nextIndex,
      shownIndex: nextIndex,
      loadedImages: this.nextLoadedImages(this.state.loadedImages, nextIndex),
    })
  }

  numSlides() {
    const { images, video } = this.props
    return images.length + (video ? 1 : 0)
  }

  // set the loaded images based on an index, wrap around if at the 0th index
  nextLoadedImages(currentLoadedImages, nextIndex) {
    const nextLoaded = new Set(currentLoadedImages)
    if (nextIndex == 0) {
      nextLoaded.add(this.numSlides() - 1)
    } else {
      nextLoaded.add(nextIndex - 1)
    }
    nextLoaded.add(nextIndex)
    nextLoaded.add(nextIndex + 1)
    return nextLoaded
  }

  isVideoIndex(index) {
    const { video } = this.props
    return video && index === this.videoIndex()
  }

  videoIndex() {
    const { images } = this.props
    return images.length
  }

  shownImageProps() {
    const { shownIndex } = this.state
    const { images } = this.props

    if (!images.length) {
      return { src: DEFAULT_PRODUCT_IMAGE_PATH, alt: 'Default product image' }
    }
    let shownImage = images[shownIndex]
    if (!shownImage) {
      shownImage = last(images)
    }
    return { src: shownImage.largeUrl, alt: shownImage.title }
  }

  renderMobile() {
    const { images, video } = this.props
    const { selectedIndex, loadedImages } = this.state

    return (
      <div className={css.mobileWrapper}>
        <ReactSwipe
          id="product-image-slider"
          className={css.imageSlider}
          key={images.length}
          ref={(c) => {
            this.slider = c
          }}
          swipeOptions={{
            callback: this.handleSlideChange,
            startSlide: selectedIndex,
          }}
        >
          {map(images, (image, idx) => (
            <Image
              alt={image.title}
              className="img-responsive"
              key={idx}
              lazyLoad={false}
              role="presentation"
              src={loadedImages.has(idx) ? image.largeUrl : ''}
            />
          ))}
          {video ? (
            <div>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  allowFullScreen
                  loading="lazy"
                  src={video.url}
                  title="Product Video"
                />
              </div>
            </div>
          ) : null}
        </ReactSwipe>
        {images.length > 1 || video ? (
          <Dots
            numDots={images.length + (video ? 1 : 0)}
            selectedIndex={selectedIndex}
            videoIndex={video ? this.videoIndex() : null}
            onTapDot={this.handleTapDot}
          />
        ) : null}
      </div>
    )
  }

  renderDesktop() {
    const { selectedIndex, shownIndex } = this.state
    const { images, video } = this.props
    return (
      <div className={css.wrapper}>
        {images.length > 1 || video ? (
          <ul
            className={classNames(css.thumbnails, 'hidden-xs')}
            id="product-thumbnails"
          >
            {map(images, (image, idx) => (
              <li
                className="mbs"
                key={idx}
                onMouseOut={() => this.handleMouseOutThumbnail(idx)}
                onMouseOver={() => this.handleMouseOverThumbnail(idx)}
              >
                <Thumbnail
                  active={idx === selectedIndex}
                  activeClass={css.active}
                  className={css.thumbnail}
                  url={image.thumbnailUrl}
                  value={getValueText(idx, idx === selectedIndex)}
                  onClick={this.handleClickThumbnail}
                />
              </li>
            ))}
            {video ? (
              <li className={css.videoButton} onClick={this.handleClickVideo}>
                <Image
                  className="img-responsive"
                  lazyLoad={false}
                  role="presentation"
                  src={VIDEO_IMG_SRC}
                />
                <span>Watch</span>
              </li>
            ) : null}
          </ul>
        ) : null}
        {
          /* hiding expand button for now */
          false && (
            <div className={css.expandButton}>
              <Image
                alt="expand-icon"
                className="img-responsive"
                lazyLoad={false}
                src={EXPAND_IMG_SRC}
              />
            </div>
          )
        }
        <div className={css.mediaContainer}>
          {this.isVideoIndex(shownIndex) ? (
            <div
              className={classNames(
                'embed-responsive embed-responsive-16by9',
                css.video
              )}
            >
              <iframe
                allowFullScreen
                loading="lazy"
                src={video.url}
                title="Product Video"
              />
            </div>
          ) : (
            <Image
              className={css.image}
              lazyLoad={false}
              role="presentation"
              {...this.shownImageProps()}
            />
          )}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div aria-hidden="true">
        <div className="visible-xs">{this.renderMobile()}</div>
        <div className="hidden-xs">{this.renderDesktop()}</div>
      </div>
    )
  }
}

ProductImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      largeUrl: PropTypes.string,
      url: PropTypes.string,
      thumbnailUrl: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  video: PropTypes.shape({
    url: PropTypes.string,
  }),
}

ProductImages.defaultProps = {
  images: [],
  video: null,
}

const Dots = ({ numDots, videoIndex, selectedIndex, onTapDot }) => (
  <div className={css.dotsWrapper}>
    <ul className={css.dots}>
      {map(range(numDots), (idx) => (
        <li
          className={classNames({ [css.dotSelected]: idx === selectedIndex })}
          key={idx}
        >
          <Tappable
            className="btn-unstyled"
            component="button"
            onTap={() => onTapDot(idx)}
          >
            <i className={`fa fa-${idx === videoIndex ? 'play' : 'circle'}`} />
          </Tappable>
        </li>
      ))}
    </ul>
  </div>
)

Dots.propTypes = {
  numDots: PropTypes.number.isRequired,
  videoIndex: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onTapDot: PropTypes.func.isRequired,
}

export default ProductImages
