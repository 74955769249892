import { FC } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import NiceModal from '@ebay/nice-modal-react'

import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'

import { useRegistry } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import { GiftGiversProvider, useGiftGivers } from './context/GiftGiversProvider'
import RegistryLayout from '../../components/RegistryLayout/RegistryLayout'

import GiftTrackerList from './components/GiftTrackerList'

const GiftGiverDebug: FC = () => {
  const { isLoading, isFetched, giftGivers } = useGiftGivers()

  const [searchParams] = useSearchParams()
  const urlParamEnabled = searchParams.get('gt_debug')

  const content = `
    isLoading: ${isLoading}
    isFetched: ${isFetched}
    # giftGivers: ${Object.keys(giftGivers || {}).length}
    giftGivers: ${JSON.stringify(giftGivers, null, 2)}
  `
  return urlParamEnabled ? <pre>{content}</pre> : null
}

const GiftTrackerRevampRoute: FC = () => {
  const currentRegistry = useRegistry()
  const [currentUser] = useCurrentUser()
  const registries = currentUser?.registries || []

  return (
    <NiceModal.Provider>
      <GiftGiversProvider registryId={currentRegistry?.id}>
        <RegistryLayout
          {...{ currentRegistry, registries, noSidePaddingOnMobile: true }}
        >
          <GiftTrackerList />
          <GiftGiverDebug />
        </RegistryLayout>
      </GiftGiversProvider>
    </NiceModal.Provider>
  )
}

export default GiftTrackerRevampRoute
