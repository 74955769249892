import React, { FC, ReactNode } from 'react'
// @ts-ignore
import { Droppable, Draggable } from '@hello-pangea/dnd'
import { RegItemCategory } from 'src/types/regItemCategory'
import { Badge } from 'baby-design'
import { getRegItemBadges } from 'src/routes/(registry)/list/[slug]/utils/regItemBadgeHelpers'
import css from './RegistryItemsList.styles.scss'
import { RegItem } from '../../../../../../types/regItem'
import RegItemCard from '../RegItemCard/RegItemCard'
import { getProductFromRegItem } from '../../utils/productHelpers'
import { ProductCategory } from '../RegItemCard/RegItemCard.types'

interface DroppableCategoryListProps {
  categories: ProductCategory[]
  productCategory: any
  regItems: RegItem[]
  regItemCategory?: RegItemCategory
  handleRegItemCategoryChange: (
    regItem: RegItem,
    index: number
  ) => (productCategory: ProductCategory) => void
  handleRegItemEdit: (regItem: RegItem) => (event: any) => void
  handleRegItemDelete: (regItem: RegItem) => (event: any) => void
  handleBuyingOptionClick: (regItem: RegItem) => (event: any) => void
}

const ImageBadge: FC<{ label: string }> = ({ label }) => (
  <Badge fill="standard-grey" label={label} size="small" variant="general" />
)

const DroppableCategoryList: React.FC<DroppableCategoryListProps> = ({
  categories,
  productCategory,
  regItemCategory,
  regItems,
  handleBuyingOptionClick,
  handleRegItemCategoryChange,
  handleRegItemEdit,
  handleRegItemDelete,
}) => {
  if (!regItems) return null

  return (
    <Droppable droppableId={`registry-items_${productCategory.value}`}>
      {(provided: any, snapshot: any) => (
        <div
          style={{
            border: snapshot.isDraggingOver
              ? '1px dotted #e9e8e8'
              : '1px solid transparent',
            height: snapshot.isDraggingOver
              ? `${(regItems.length + 1) * 220}px`
              : 'auto',
          }}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {regItems.map((item: RegItem, index: number) => {
            const quantityInCart = item.cartInfo?.quantity
            let badge: ReactNode
            if (quantityInCart && quantityInCart > 0) {
              badge = <ImageBadge label={`${quantityInCart} in cart`} />
            } else if (item.hasAtLeastOnePurchase && !item.isCrowdfund) {
              badge = <ImageBadge label="Purchased" />
            }
            return (
              <div key={item.id} className={css.RegistryItemsList__item}>
                <Draggable
                  key={item.id}
                  draggableId={`${item.id}-${productCategory.label}`}
                  index={index}
                >
                  {(provided: any) => (
                    <>
                      <RegItemCard
                        draggable
                        addedByText={item.addedByText}
                        badge={badge}
                        categories={categories}
                        dragHandleProps={provided.dragHandleProps}
                        draggableProps={provided.draggableProps}
                        innerRef={provided.innerRef}
                        key={item.id}
                        product={getProductFromRegItem(item, categories)}
                        regItem={item}
                        regItemBadges={getRegItemBadges(item, regItemCategory)}
                        onBuyingOptionClick={handleBuyingOptionClick(item)}
                        onCategoryChange={handleRegItemCategoryChange(
                          item,
                          index
                        )}
                        onDeleteClick={handleRegItemDelete(item)}
                        onEditClick={handleRegItemEdit(item)}
                      />
                    </>
                  )}
                </Draggable>
              </div>
            )
          })}
        </div>
      )}
    </Droppable>
  )
}

export default DroppableCategoryList
