import MobileSearchBar from 'shared/mobile-search-bar'
import SubNav from 'hello_baby/components/sub-nav'
import { helloBabySearchPath } from 'lib/urls'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useEffect } from 'react'
import { useGuidesConfig, useTopicNavigation } from '../../utils/useQueries'

interface GuidesNavProps {
  topic?: Topic
}

const GuidesNav: React.FC<GuidesNavProps> = ({ topic }: GuidesNavProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { minimalView } = useGuidesConfig()
  const topicNavigation = useTopicNavigation()

  const searchTerm = new URLSearchParams(location.search).get('search_term')

  const topLevelTopicSlug = topicNavigation?.items.find(
    (t) =>
      t.slug === topic?.slug ||
      t.children?.some((child) => child.slug === topic?.slug)
  )?.slug

  useEffect(() => {
    // Listen for navigation from the sitewide mobile nav
    PubSub.subscribe(BLConstants.ROUTE_CHANGE, (href: string) => {
      navigate(href)
    })
  }, [navigate])

  return (
    <div>
      {!minimalView && topicNavigation && (
        <>
          <SubNav
            className="hidden-xs"
            searchTerm={searchTerm}
            topicSlug={topLevelTopicSlug}
            topics={topicNavigation.items}
          />
          <MobileSearchBar
            placeholder="Search guides"
            submitPath={helloBabySearchPath}
          />
        </>
      )}
    </div>
  )
}

export default GuidesNav
