import { pdpPath, blAdminProductPath } from 'lib/urls'
import { useNavigate } from 'react-router-dom-v5-compat'

export const productPath =
  ({ id, slug }: { id?: number | undefined; slug?: string | undefined }) =>
  (productId: string) =>
    pdpPath(slug, id, productId)

export const adminProductPath = ({
  genericProductId,
  activeProductId,
}: {
  genericProductId: string
  activeProductId: string
}) => blAdminProductPath(genericProductId, activeProductId)

export const handleProductNavigationChange = ({
  activeProductId,
  genericProductId,
  genericProductSlug,
}: {
  activeProductId: number | undefined
  genericProductId: number | undefined
  genericProductSlug: string | undefined
}) => {
  const navigate = useNavigate()

  return (newProductId: string) => {
    if (activeProductId && activeProductId !== Number(newProductId)) {
      navigate(pdpPath(genericProductSlug, genericProductId, newProductId), {
        replace: true,
      })
    }
  }
}

export const normalizeAttribute = (attribute: string | number): string =>
  attribute?.toString().trim().toLowerCase().replace(/\W/g, '') ?? ''

export const isActiveAttribute = (
  selectedAttribute: string | number | null,
  attributeValue: string | number
): boolean =>
  normalizeAttribute(selectedAttribute ?? '') ===
  normalizeAttribute(attributeValue)

/**
 * Determines the product ID for the selected attributes.
 *
 * @param {Record<string, string>} selectedAttributes - The selected attributes.
 * @param {Record<string, Record<string, number[]>>} productAttributeMap - The product attribute map.
 * @returns {number | null} - The product ID or null if no matching product is found.
 */
export const productIdForSelectedAttributes = (
  selectedAttributes: Record<string, string>,
  productAttributeMap: Record<string, Record<string | number, number[]>>
): number | null => {
  const potentialProducts = Object.entries(selectedAttributes).map(
    ([name, value]) => {
      const normalizedKey = normalizeAttribute(value)
      const attributeMap = productAttributeMap[name]

      if (!attributeMap) return new Set<number>()

      const normalizedVariantMap = Object.entries(attributeMap).reduce(
        (acc, [key, ids]) => {
          acc[normalizeAttribute(key)] = ids
          return acc
        },
        {} as Record<string, number[]>
      )

      return new Set(normalizedVariantMap[normalizedKey] || [])
    }
  )

  if (potentialProducts.length === 0) return null

  const [firstSet, ...restSets] = potentialProducts
  const intersection = Array.from(firstSet).filter((id) =>
    restSets.every((set) => set.has(id))
  )

  return intersection.length > 0 ? intersection[0] : null
}

/**
 * Checks if an attribute value is available based on the selected attributes.
 *
 * @param {Record<string, string | null>} selectedAttributes - The selected attributes.
 * @param {Record<string, Record<string, number[]>>} productAttributeMap - The product attribute map.
 * @param {string} attributeName - The attribute name to check.
 * @param {string} attributeValue - The attribute value to check.
 * @returns {boolean} - True if the attribute value is available, otherwise false.
 */
export const checkAttributeAvailability = (
  selectedAttributes: Record<string, string | null>,
  productAttributeMap: Record<string, Record<string, number[]>>,
  attributeName: string,
  attributeValue: string
): boolean => {
  // Add the new attribute to the selected attributes
  const tempAttributes = {
    ...selectedAttributes,
    [attributeName]: attributeValue,
  }
  // Remove any null values
  Object.keys(tempAttributes).forEach((key) => {
    if (tempAttributes[key] === null) {
      delete tempAttributes[key]
    }
  })
  // Get the product ID for the selected attributes
  const id = productIdForSelectedAttributes(
    tempAttributes as Record<string, string>,
    productAttributeMap
  )
  // Return true if the product ID is not null
  return id !== null
}

const sizeReplacements: { pattern: string; replacement: string }[] = [
  { pattern: 'Newborn', replacement: 'NB' },
  { pattern: 'months?', replacement: 'M' },
  { pattern: 'ounces?', replacement: ' OZ' },
  { pattern: 'grams?', replacement: ' G' },
  { pattern: 'x-small/+?', replacement: 'XS/' },
  { pattern: '/+small?', replacement: '/S' },
  { pattern: 'small/+?', replacement: 'S/' },
  { pattern: '/+medium?', replacement: '/M' },
  { pattern: 'medium/+?', replacement: 'M/' },
  { pattern: '/+xxx-large?', replacement: '/3XL' },
  { pattern: 'xxx-large/+?', replacement: '3XL/' },
  { pattern: '/+xx-large?', replacement: '/2XL' },
  { pattern: 'xx-large/+?', replacement: '2XL/' },
  { pattern: '/+x-large?', replacement: '/XL' },
  { pattern: 'x-large/+?', replacement: 'XL/' },
  { pattern: '/+large?', replacement: '/L' },
  { pattern: 'large/+?', replacement: 'L/' },
  { pattern: ' - ', replacement: '-' },
]

const sizeRegex = new RegExp(
  sizeReplacements.map((r) => r.pattern).join('|'),
  'gi'
)

export const shortSize = (size: string): string =>
  size.replace(sizeRegex, (match) => {
    const replacement = sizeReplacements.find((r) =>
      new RegExp(r.pattern, 'i').test(match)
    )
    return replacement ? replacement.replacement : match
  })
