import { filter, get, includes, map, orderBy, values } from 'lodash'
import * as BLConstants from 'shared/constants'

import { getPostBySlug, getTopics } from '../reducers'

export const filterPostsByTopicSlug = (
  posts,
  topicSlug,
  { sortBy = 'publishedAt', order = 'desc' } = {}
) =>
  values(
    orderBy(
      filter(posts, (post) =>
        includes(map(post.meta.topics, 'slug'), topicSlug)
      ),
      (post) => parseInt(post[sortBy]),
      order
    )
  )

export const filterPregnancyWeekByWeekPosts = (posts, topicSlug) => {
  const firstTrimesterPost = filterPostsByTopicSlug(
    posts,
    'first-trimester-of-pregnancy',
    { sortBy: 'title' }
  ).shift()
  const secondTrimesterPost = filterPostsByTopicSlug(
    posts,
    'second-trimester-of-pregnancy',
    { sortBy: 'title' }
  ).shift()
  const thirdTrimesterPost = filterPostsByTopicSlug(
    posts,
    'third-trimester-of-pregnancy',
    { sortBy: 'title' }
  ).shift()
  const results = filterPostsByTopicSlug(posts, topicSlug, {
    sortBy: 'title',
    order: 'asc',
  })
  results.splice(0, 0, firstTrimesterPost)
  results.splice(10, 0, secondTrimesterPost)
  results.splice(26, 0, thirdTrimesterPost)
  return results
}

export const filterTrimesterPosts = (posts, topicSlug) => {
  const results = filterPostsByTopicSlug(posts, topicSlug, {
    sortBy: 'title',
    order: 'asc',
  })
  results.unshift(results.pop())
  return results
}

export const getTopic = (post) => get(post, 'meta.topics[0]')

export const getTopicSlug = (post) => get(getTopic(post), 'slug')

export const isTopicPage = (slug, state) =>
  includes(map(getTopics(state), 'slug'), slug)

export const getTopLevelTopicSlugFromPath = (path, posts = {}, state) => {
  const slug = path.split('/').pop()
  let topicSlug = null
  if (isTopicPage(slug, state)) {
    map(
      state.collections[BLConstants.COLLECTION_SLUG_TOPIC_NAVIGATION].items,
      (topic) => {
        if (
          includes(map(topic.children, 'slug'), slug) ||
          topic.slug === slug
        ) {
          topicSlug = topic.slug
        }
      }
    )
  } else {
    const post = getPostBySlug(slug, posts)
    topicSlug = getTopicSlug(post)
    if (post && topicSlug) {
      topicSlug = getTopLevelTopicSlugFromPath(topicSlug, posts, state)
    }
  }
  return topicSlug
}

export const updateDOMMeta = (html) => {
  $('head .hello-baby-meta').remove()
  $('head').append(html)
}
