/* eslint-disable complexity */
import { useContext, useEffect, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { Col, Row } from 'react-bootstrap'
import { ShippingDisclaimer } from 'store/components/landing-page/disclaimers'
import { CircularProgress } from '@material-ui/core'
import GuestRegistryHeader from 'registry/components/GuestRegistryHeader'
import RegistryHeaderImage from 'src/components/RegistryHeaderImage'
import useSetDatadogRumViewName from 'src/hooks/useSetDatadogRumViewName/useSetDatadogRumViewName'
import { DD_RUM_VIEW_NAME } from 'src/constants'
import InProgressReservationContext from 'registry/contexts/inProgressReservation'
import useGiftGiverReminders from 'src/routes/(subdomains)/(my)/[slug]/hooks/useGiftGiverReminders/useGiftGiverReminders'
import useGetCart from 'src/routes/(subdomains)/(my)/[slug]/hooks/useGetCart'
import { categoryProps } from './reg-item-category/reg-item-category'
import RegItemCardGhostList from './reg-item-card-ghost'
import { RegItemsHeaderGuest } from './reg-items-headers/reg-items-header'
import { registryPageDataFetcher } from './registry-page-data-fetcher'
import RegItemCategoryList from './reg-item-category-list/reg-item-category-list'
import NoRegItemsMessage from './NoRegItemsMessage'
import ShippingAddressFab from './shipping-address-fab'
import { ReservedRegItemCategory } from './reserved-reg-item-category'
// Do NOT move openCrowdfundReservationModal up like ESLint suggests.
// It breaks group gifts.
// eslint-disable-next-line import/order
import { openCrowdfundReservationModal } from 'registry/modals/actions'
import styles from './registry-page-guest.scss'

const RegistryPageGuest = ({
  addFilter,
  categorizedRegItems,
  favoriteRegItemsCount,
  filteredRegItemsCount,
  filters,
  groupGiftsRegItemsCount,
  isAllCategoriesCollapsed,
  isPageReady,
  isShippingAddressButtonVisible,
  openContactInfoModal,
  openCrowdfundReservationModal,
  openGiftTrackerModal,
  openMobileFiltersModal,
  recipientFirstName,
  regItemsCount,
  registry,
  reservationsByCurrentVisitor,
  reservedRegItems,
  reservedRegItemsByCurrentVisitor,
  setFilters,
  stores,
  showGiftTracker,
  isPartialSetOfRegItems,
}) => {
  const anyCartItems = categorizedRegItems.some((category) =>
    category.regItems.some((item) => item.cartInfo?.quantity > 0)
  )
  useGiftGiverReminders({
    registry,
    anyVisitorReservations: reservedRegItemsByCurrentVisitor.length > 0,
    anyCartItems,
  })
  const purchasedSectionRef = useRef()
  const scrollToPurchasedSection = (ref) =>
    window.scrollTo(0, ref.current.offsetTop)

  const { reservation: inProgressReservation } = useContext(
    InProgressReservationContext
  )

  useEffect(() => {
    if (inProgressReservation) {
      openCrowdfundReservationModal(inProgressReservation.regItem)
    }
  }, [inProgressReservation])

  const maybeOpenGiftTrackerModal = () => {
    if (showGiftTracker && reservedRegItemsByCurrentVisitor.length > 0) {
      openGiftTrackerModal()
      scrollToPurchasedSection(purchasedSectionRef)
    }
  }

  useEffect(() => {
    maybeOpenGiftTrackerModal()
  }, [showGiftTracker])

  useSetDatadogRumViewName(DD_RUM_VIEW_NAME.RegistryGiftGiverLegacy)

  const { glowupEnabled } = registry.headerDetails

  const registryPageGuestClasses = classNames(styles.RegistryPageGuest, {
    [styles.RegistryPageGuest__glowup]: glowupEnabled,
  })
  const registryHeaderAndContentClasses = classNames(
    styles.registry_header_and_content,
    {
      [styles.registry_header_and_content__glowup]: glowupEnabled,
    }
  )
  const registryContainerClass = glowupEnabled
    ? styles.registry_container__glowup
    : 'container mtm mtl-sm pbxl'

  return (
    <div className={registryPageGuestClasses}>
      {registry && glowupEnabled && <RegistryHeaderImage registry={registry} />}
      <div className={registryHeaderAndContentClasses}>
        <GuestRegistryHeader />
        <div className={registryContainerClass} id="registry">
          <Row className="flex col center">
            <Col xs={12}>
              <RegItemsHeaderGuest
                addFilter={addFilter}
                favoriteRegItemsCount={favoriteRegItemsCount}
                filters={filters}
                groupGiftsRegItemsCount={groupGiftsRegItemsCount}
                openMobileFiltersModal={openMobileFiltersModal}
                regItemsCount={regItemsCount}
                setFilters={setFilters}
                stores={stores}
              />
              <div className="reg-items">
                {isPageReady && (
                  <NoRegItemsMessage
                    clearFilters={setFilters}
                    filteredRegItemsCount={filteredRegItemsCount}
                    isContributorView={false}
                    registryId={registry.id}
                    totalRegItemsCount={regItemsCount}
                  />
                )}

                <RegItemCategoryList
                  categories={categorizedRegItems}
                  filters={filters}
                  isAllCategoriesCollapsed={isAllCategoriesCollapsed}
                  reservationsByCurrentVisitor={reservationsByCurrentVisitor}
                  reservedRegItemsByCurrentVisitor={
                    reservedRegItemsByCurrentVisitor
                  }
                />

                {isPageReady &&
                  (reservedRegItems || reservedRegItemsByCurrentVisitor) && (
                    <div ref={purchasedSectionRef}>
                      <ReservedRegItemCategory
                        reservations={reservedRegItems}
                        reservedRegItemsByCurrentVisitor={
                          reservationsByCurrentVisitor
                        }
                      />
                    </div>
                  )}

                {!isPageReady &&
                  (isPartialSetOfRegItems ? (
                    <Row className="flex col center mbxl mtl">
                      <Col className="mbm mtm">
                        <CircularProgress size={16} />
                        <span className="mlm">
                          Sit tight! Loading remaining items...
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    <RegItemCardGhostList />
                  ))}
              </div>
            </Col>
          </Row>

          {isPageReady && (
            <ShippingAddressFab
              firstNames={recipientFirstName || registry.firstNames}
              show={
                isShippingAddressButtonVisible &&
                !registry.isShippingAddressPrivate
              }
              onClick={() => openContactInfoModal({ registry })}
            />
          )}
          <ShippingDisclaimer classname="mtm" />
        </div>
      </div>
    </div>
  )
}

RegistryPageGuest.propTypes = {
  regItemsCount: PropTypes.number.isRequired,
  favoriteRegItemsCount: PropTypes.number.isRequired,
  groupGiftsRegItemsCount: PropTypes.number.isRequired,
  addFilter: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  filteredRegItemsCount: PropTypes.number.isRequired,
  categorizedRegItems: PropTypes.arrayOf(PropTypes.shape(categoryProps)),
  isAllCategoriesCollapsed: PropTypes.bool,
  isPageReady: PropTypes.bool,
  isPartialSetOfRegItems: PropTypes.bool,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  reservationsByCurrentVisitor: PropTypes.object,
  reservedRegItems: PropTypes.array,
  reservedRegItemsByCurrentVisitor: PropTypes.array,
  openMobileFiltersModal: PropTypes.func.isRequired,
  openGiftTrackerModal: PropTypes.func.isRequired,
  filters: PropTypes.object,
  openContactInfoModal: PropTypes.func,
  openCrowdfundReservationModal: PropTypes.func,
  isShippingAddressButtonVisible: PropTypes.bool,
  registry: PropTypes.object.isRequired,
  showGiftTracker: PropTypes.string,
}

RegistryPageGuest.defaultProps = {
  categorizedRegItems: [],
  filters: {},
  isAllCategoriesCollapsed: false,
  isPageReady: false,
  isPartialSetOfRegItems: false,
  reservedRegItems: [],
  reservationsByCurrentVisitor: {},
  reservedRegItemsByCurrentVisitor: [],
  stores: [],
  openContactInfoModal: () => {},
  openCrowdfundReservationModal: () => {},
  isShippingAddressButtonVisible: false,
  showGiftTracker: false,
}

const mapStateToProps = (state, ownProps) => {
  const { showGiftTracker } = queryString.parse(ownProps.location.search)

  return {
    recipientFirstName: state.registry?.attributeData?.firstName,
    showGiftTracker,
  }
}

export default connect(mapStateToProps, {
  openCrowdfundReservationModal,
})(registryPageDataFetcher(RegistryPageGuest))
