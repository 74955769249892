import { track } from 'lib/analytics'

import { Registry } from 'src/types/registry'
import { CurrentUser } from '../../../../../global'

interface TrackingRegistryPropertiesArgs {
  registry: Registry
  currentUser?: CurrentUser
}

const trackingRegistryProperties = ({
  registry,
  currentUser,
}: TrackingRegistryPropertiesArgs) => ({
  isRegistryOwner: registry.ownerId === currentUser?.id,
  registryOwnerId: registry.ownerId,
  registryId: registry.id,
  listType:
    registry.type === null ? track.ListType.BABY_REGISTRY : registry.type,
})

export default trackingRegistryProperties
