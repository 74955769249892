import React, { useContext } from 'react'
import { DecoratedShipmentCardProps } from 'src/routes/(registry)/shipments/components/DecoratedShipmentCard/DecoratedShipmentCard.types'
import ShipmentCard from 'src/routes/(registry)/shipments/components/ShipmentCard/ShipmentCard'
import {
  shipmentCardSubtitle,
  shipmentItems,
  shipmentStatusBadge,
  shipNowEligible,
} from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import NiceModal from '@ebay/nice-modal-react'
import ShipNowModal from 'src/routes/(registry)/shipments/components/ShipNowModal/ShipNowModal'
import ShipNowContext from 'src/contexts/shipNow'
import { shipmentDetailViewPath } from 'lib/urls'

import { useTrackShipNowStartedClick } from '../../hooks/useTrackClick'

const DecoratedShipmentCard: React.FC<DecoratedShipmentCardProps> = ({
  shipment,
}) => {
  const { onShipNowComplete } = useContext(ShipNowContext)
  const trackShipNowStartedClick = useTrackShipNowStartedClick({ shipment })

  const handleShipNow = async () => {
    trackShipNowStartedClick()
    const completed = await NiceModal.show(ShipNowModal, { shipment })
    if (completed && onShipNowComplete) {
      onShipNowComplete()
    }
  }

  return (
    <ShipmentCard
      badge={shipmentStatusBadge(shipment)}
      headline={shipment.shipmentStatus?.description}
      items={shipmentItems(shipment)}
      key={shipment.id}
      shipNowEligible={shipNowEligible(shipment)}
      shipmentUrl={shipmentDetailViewPath(shipment.id)}
      subtitle={shipmentCardSubtitle(shipment)}
      onShipNowClick={handleShipNow}
    />
  )
}

export default DecoratedShipmentCard
