import React from 'react'
import { Badge } from 'baby-design'
import { RegItemBadgesProps } from './RegItemBadges.types'
import css from './RegItemBadges.styles.scss'

const RegItemBadges: React.FC<RegItemBadgesProps> = ({ badges, size }) => (
  <div className={css.RegItemBadges}>
    {/* @ts-ignore */}
    {badges.map((badge) => (
      // @ts-ignore
      <Badge size={size} {...badge} />
    ))}
  </div>
)

export default RegItemBadges
