import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import css from './BuyBox.styles.scss'

export const ProductHeader = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { shortName } = genericProduct

  return (
    <div className={css.BuyBox__name} data-testid="name">
      {shortName}
    </div>
  )
}
