import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  fetchVisitorReservations,
  FETCH_VISITOR_RESERVATIONS_KEY,
} from 'src/api/queries'
import { Reservation } from 'src/types/reservation'

const useGetVisitorReservations = (registryId: number | undefined) => {
  const visitorReservationsQuery = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_VISITOR_RESERVATIONS_KEY, registryId],
    queryFn: () => fetchVisitorReservations(registryId),
  })
  let visitorReservations: Reservation[] = []

  if (visitorReservationsQuery.data) {
    visitorReservations = Object.values(visitorReservationsQuery.data)
  }

  return { visitorReservations, visitorReservationsQuery }
}

export const useInvalidateVisitorReservations = (registryId: number) => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries([FETCH_VISITOR_RESERVATIONS_KEY, registryId])
}

export const useOptimisticAddVisitorReservation = () => {
  const queryClient = useQueryClient()

  return (registryId: number, newVisitorReservation: Reservation) => {
    queryClient.setQueryData(
      [FETCH_VISITOR_RESERVATIONS_KEY, registryId],
      (currentVisitorReservations?: Record<number, Reservation>) => ({
        ...(currentVisitorReservations || {}),
        [newVisitorReservation.id]: newVisitorReservation,
      })
    )
  }
}

export const useOptimisticDeleteVisitorReservation = () => {
  const queryClient = useQueryClient()

  return (registryId: number, reservationId: number) => {
    queryClient.setQueryData(
      [FETCH_VISITOR_RESERVATIONS_KEY, registryId],
      (currentVisitorReservations?: Record<number, Reservation>) => {
        if (!currentVisitorReservations) return undefined

        const newVisitorReservations = { ...currentVisitorReservations }
        delete newVisitorReservations[reservationId]

        return newVisitorReservations
      }
    )
  }
}

export default useGetVisitorReservations
