import React from 'react'
import classNames from 'classnames'

import css from './RegistryLayout.styles.scss'

import { RegistryLayoutProps } from './RegistryLayout.types'
import RegistryNavigation from '../RegistryNavigation/RegistryNavigation'
import NavigationBreadcrumbs from '../NavigationBreadcrumbs/NavigationBreadcrumbs'

const RegistryLayout: React.FC<RegistryLayoutProps> = ({
  children,
  currentRegistry,
  categories,
  noSidePaddingOnMobile = false,
}) => (
  <div className={css.RegistryPage}>
    <div className={css.RegistryLayout}>
      <div className={css.RegistryLayout__LeftNav}>
        <RegistryNavigation
          categories={categories}
          currentRegistry={currentRegistry}
        />
      </div>
      <div
        className={classNames(css.RegistryLayout__content, {
          [css.RegistryLayout__content__noSidePaddingOnMobile]:
            noSidePaddingOnMobile,
        })}
      >
        <NavigationBreadcrumbs currentRegistry={currentRegistry} />
        {children}
      </div>
    </div>
  </div>
)

export default RegistryLayout
