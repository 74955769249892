import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { withContextualizedTracking, track } from 'lib/analytics'
import GuidesNav from '../components/GuidesNav'
import HomeView from '../components/HomeView'

const GuidesApp: React.FC = () => (
  <GoogleReCaptchaProvider reCaptchaKey={BLConstants.RECAPTCHA_V3_SITE_KEY}>
    <GuidesNav />
    <HomeView />
  </GoogleReCaptchaProvider>
)

export default withContextualizedTracking({
  eventLocation: track.EventLocation.GUIDE,
})(GuidesApp)
