import { Category } from 'src/routes/(shop)/api/products'

export const getTruncatedCategories = (categoryBreadcrumbs: Category[]) => {
  const characterMax = 35
  let charTally = 0
  const truncatedCategories = []
  const totalCategories = categoryBreadcrumbs.length

  for (let i = totalCategories - 1; i >= 0; i--) {
    const category = categoryBreadcrumbs[i]
    const categoryLength = category.title.length
    charTally += categoryLength
    if (charTally > characterMax) {
      truncatedCategories.push(category)
    }
  }

  return truncatedCategories.reverse()
}

export const getListOfCategoriesToDisplay = (
  categoryBreadcrumbs: Category[],
  listOfTruncatedCategories: Category[]
) =>
  categoryBreadcrumbs.filter(
    ({ title: categoryTitle }: { title: string }) =>
      !listOfTruncatedCategories.some(
        ({ title: truncatedTitle }: { title: string }) =>
          truncatedTitle === categoryTitle
      )
  )
