import { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import ClipboardJS from 'clipboard'
import { InfoButton, SecondaryButton } from 'components/buttons'
import { Input } from 'components/forms'
import { CopyIcon } from 'components/icons'

const inputId = 'link-copier-input'
const buttonId = 'link-copier-button'

class LinkCopier extends Component {
  static propTypes = {
    link: PropTypes.string.isRequired,
  }

  state = {
    copySuccess: false,
  }

  componentDidMount() {
    const buttonIdSelector = `#${buttonId}`

    this.clipboard = new ClipboardJS(buttonIdSelector, {
      target: () => document.getElementById(inputId),
    })

    this.clipboard.on('success', (e) => {
      this.handleCopySuccess()
    })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  render() {
    const { link } = this.props

    let copyLink = this.state.copySuccess ? (
      <button className="input-group-addon--submit" id={buttonId}>
        Copied!
      </button>
    ) : (
      <button className="input-group-addon--submit" id={buttonId}>
        Copy
      </button>
    )

    return (
      <Row>
        <Col sm={8}>
          <Input
            label="Your Registry Link"
            id={inputId}
            onChange={() => {}}
            value={link}
            addonAfter={copyLink}
            readOnly
          />
        </Col>
      </Row>
    )
  }

  handleCopySuccess() {
    this.setState({ copySuccess: true })
  }
}

export default LinkCopier
