import PropTypes from 'prop-types'
import DefaultHeader from './default-header'
import DiscountHeader from './discount-header'
import OrganizeHeader from './organize-header'
import GuestViewLinks from './guest-view-links'
import { GuestFilterBar } from '../guest-filter-bar'

export const RegItemsHeaderContributor = ({
  addFilter,
  categories,
  filters,
  isOrganizing,
  openMobileFiltersModal,
  regItemsCount,
  registryUrlSlug,
  stores,
}) => {
  if (isOrganizing) {
    return <OrganizeHeader registryUrlSlug={registryUrlSlug} />
  }
  return (
    <DefaultHeader
      addFilter={addFilter}
      categories={categories}
      filters={filters}
      openMobileFiltersModal={openMobileFiltersModal}
      registryUrlSlug={registryUrlSlug}
      regItemsCount={regItemsCount}
      stores={stores}
    />
  )
}

RegItemsHeaderContributor.propTypes = {
  addFilter: PropTypes.func.isRequired,
  categories: PropTypes.object,
  filters: PropTypes.object,
  isOrganizing: PropTypes.bool.isRequired,
  openMobileFiltersModal: PropTypes.func.isRequired,
  regItemsCount: PropTypes.number.isRequired,
  registryUrlSlug: PropTypes.string.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

RegItemsHeaderContributor.defaultProps = {
  categories: {},
  filters: {},
  stores: [],
}

export const RegItemsHeaderDiscount = ({
  hasRegistryDiscountEligibleItems,
  isOwner,
  ownerName,
}) => (
  <>
    <DiscountHeader
      hasRegistryDiscountEligibleItems={hasRegistryDiscountEligibleItems}
      isOwner={isOwner}
      ownerName={ownerName}
    />
  </>
)

RegItemsHeaderDiscount.propTypes = {
  hasRegistryDiscountEligibleItems: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  ownerName: PropTypes.string.isRequired,
}

export const RegItemsHeaderGuest = ({
  addFilter,
  filters,
  regItemsCount,
  favoriteRegItemsCount,
  groupGiftsRegItemsCount,
  openMobileFiltersModal,
  stores,
}) => {
  return (
    <div className="flex-row mbl">
      <GuestFilterBar
        addFilter={addFilter}
        favoriteRegItemsCount={favoriteRegItemsCount}
        groupGiftsRegItemsCount={groupGiftsRegItemsCount}
        filters={filters}
        stores={stores}
      />
      {regItemsCount > 0 && (
        <GuestViewLinks openMobileFiltersModal={openMobileFiltersModal} />
      )}
    </div>
  )
}

RegItemsHeaderGuest.propTypes = {
  addFilter: PropTypes.func.isRequired,
  regItemsCount: PropTypes.number.isRequired,
  favoriteRegItemsCount: PropTypes.number.isRequired,
  groupGiftsRegItemsCount: PropTypes.number.isRequired,
  filters: PropTypes.object,
  openMobileFiltersModal: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}

RegItemsHeaderGuest.defaultProps = {
  filters: {},
  stores: [],
}
