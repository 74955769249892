import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useQuery } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'
import { track, withContextualizedTracking } from 'lib/analytics'
import { fetchRegistry } from '../../../../api/queries'
import RegistryLayout from '../../components/RegistryLayout/RegistryLayout'
import Checklist from './components/RegistryChecklistApp'

const RegistryChecklistPage = () => {
  const [currentUser] = useCurrentUser()
  const registryId = currentUser?.currentRegistry?.id

  const { isError, data, error } = useQuery({
    enabled: !!registryId,
    queryKey: [registryId],
    queryFn: () => fetchRegistry(registryId),
  })

  if (isError) {
    console.error(error)
  }

  return (
    <NiceModal.Provider>
      <RegistryLayout currentRegistry={data}>
        <Checklist registryId={data?.id} />
      </RegistryLayout>
    </NiceModal.Provider>
  )
}
export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY,
})(RegistryChecklistPage)
