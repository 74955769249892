import { forwardRef } from 'react'

interface CustomPlayerProps {
  className?: string
  src: string
  muted: boolean
  onEnded: () => void
}

const CustomPlayer = forwardRef<HTMLVideoElement, CustomPlayerProps>(
  ({ className, muted, onEnded, src }, ref) => (
    <div className={className || ''} style={{ height: '100%', width: 'auto' }}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        playsInline
        muted={muted}
        preload="none"
        ref={ref}
        src={src}
        style={{ height: '100%', width: 'auto' }}
        onEnded={onEnded}
      />
    </div>
  )
)

export default CustomPlayer
