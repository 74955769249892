import { useQuery } from '@tanstack/react-query'
import { fetchRegItem, FETCH_REG_ITEM_KEY } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'

const useGetRegItem = (
  regItemId: number,
  registryId: number,
  guestView = false
) => {
  const regItemQuery = useQuery({
    enabled: !!regItemId && !!registryId,
    queryKey: [FETCH_REG_ITEM_KEY, regItemId, registryId, guestView],
    queryFn: () => fetchRegItem(regItemId, registryId, guestView),
  })
  const regItem: RegItem | undefined = regItemQuery.data

  return { regItem, regItemQuery }
}

export default useGetRegItem
