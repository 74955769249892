import * as React from 'react'
import Image from 'components/image'
import css from './PreferencesDisplay.styles.scss'

export const LONG_TEXT_CHARACTER_COUNT = 20

interface Preference {
  affinity: 'loves' | 'needs' | 'please_no' | string
  name: string | null
}

export interface PreferencesDisplayProps {
  preferences: Preference[]
}

export const PreferencesDisplay: React.FC<PreferencesDisplayProps> = ({
  preferences = [],
}) => {
  const hasPreferences = Array.isArray(preferences) && preferences.length > 0
  const pleaseNoPreference = hasPreferences
    ? preferences.find((p) => p.affinity === 'please_no')
    : null

  const renderPreference = (preference: Preference) => {
    const isLongText =
      (preference.name?.length ?? 0) > LONG_TEXT_CHARACTER_COUNT
    const baseClass = `preferences__preference--${preference.affinity}`
    const contentClass = isLongText
      ? `preferences__preference__content--small`
      : `preferences__preference__content`

    switch (preference.affinity) {
      case 'loves':
        return (
          <div
            className={css[baseClass]}
            key={`${preference.affinity}--${preference.name}`}
          >
            <div className={css.preferences__preference__heading}>
              <Image
                role="presentation"
                src="//images.babylist.com/image/upload/v1692292680/icn_preference_heart_pwgbjr.svg"
              />
              {preference.affinity}
            </div>
            <div className={css[contentClass]}>{preference.name}</div>
          </div>
        )
      case 'needs':
        return (
          <div
            className={css[baseClass]}
            key={`${preference.affinity}--${preference.name}`}
          >
            <div className={css.preferences__preference__heading}>
              <Image
                role="presentation"
                src="//images.babylist.com/image/upload/v1692292680/icn_preference_star_rlk03g.svg"
              />
              {preference.affinity}
            </div>
            <div className={css[contentClass]}>{preference.name}</div>
          </div>
        )
      default:
        return ''
    }
  }

  return (
    <>
      {hasPreferences && (
        <>
          <div className={css.preferences__wrapper}>
            {preferences &&
              preferences.map(
                (preference) =>
                  preference.name &&
                  preference.affinity &&
                  renderPreference(preference)
              )}
          </div>
          {pleaseNoPreference?.name && pleaseNoPreference.name.length > 0 && (
            <div className={css['preferences__please-no']}>
              Please no {pleaseNoPreference.name}
            </div>
          )}
        </>
      )}
    </>
  )
}
