import { useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document, INLINES } from '@contentful/rich-text-types'
import { Image, Button, TextLink } from 'baby-design'
import { useQuery } from '@tanstack/react-query'
import { guidesPath } from 'lib/urls'
import classNames from 'classnames'
import ScrollLockModal from 'shared/ScrollLockModal'

import CurvedHeader from '../../components/CurvedHeader/CurvedHeader'
import {
  FETCH_HAB_KEY,
  fetchHealthAdvisoryBoardContent,
} from '../../api/queries'
import css from './HealthAdvisoryBoard.styles.scss'
import Website from './icons/Website.svg'
import Instagram from './icons/Instagram.svg'
import Facebook from './icons/Facebook.svg'
import YouTube from './icons/YouTube.svg'
import LinkedIn from './icons/LinkedIn.svg'
import X from './icons/X.svg'

interface SocialLink {
  id: string
  type: 'website' | 'instagram' | 'facebook' | 'youtube' | 'x' | 'linkedin'
  url: string
}

interface HABMember {
  id: string
  name: string
  title: string
  imageUrl: string
  bioText: Document
  roleDescription: string
  socialLinks: SocialLink[]
}

const HealthAdvisoryBoard: React.FC = () => {
  const [selectedMember, setSelectedMember] = useState<HABMember | null>(null)
  const habQuery = useQuery<HABMember[]>({
    queryKey: [FETCH_HAB_KEY],
    queryFn: fetchHealthAdvisoryBoardContent,
  })

  const renderSocialLinkContent = (link: SocialLink) => {
    if (selectedMember === null) return null

    switch (link.type) {
      case 'website':
        return (
          <>
            <Website className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type}.
            </span>
          </>
        )
      case 'instagram':
        return (
          <>
            <Instagram className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type} page.
            </span>
          </>
        )
      case 'facebook':
        return (
          <>
            <Facebook className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type} page.
            </span>
          </>
        )
      case 'youtube':
        return (
          <>
            <YouTube className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type} page.
            </span>
          </>
        )
      case 'linkedin':
        return (
          <>
            <LinkedIn className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type} page.
            </span>
          </>
        )
      case 'x':
        return (
          <>
            <X className={css.HealthAdvisoryBoard__socialIcon} />
            <span className="sr-only">
              Navigate to {selectedMember.name}'s {link.type} page.
            </span>
          </>
        )
      default:
        return null
    }
  }

  const bioTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data, content }: any) => (
        <a href={data.uri} rel="noreferrer" target="_blank">
          {content[0]?.value}
        </a>
      ),
    },
  }

  return (
    <div className={css.HealthAdvisoryBoard}>
      <CurvedHeader
        className={css.HealthAdvisoryBoard__curvedHeaderBackground}
        heading="Meet the Babylist Health Advisory Board"
      />
      <section className={css.HealthAdvisoryBoard__mainSection}>
        <div className={css.HealthAdvisoryBoard__mainContentContainer}>
          <h2 className={css.HealthAdvisoryBoard__mainHeading}>
            Meet our experts
          </h2>
          <div className={css.HealthAdvisoryBoard__mainText}>
            <p>
              We take care of growing families so they can be confident,
              connected, happy and healthy.
            </p>
            <p>
              That's not just the mission that inspired our universal baby
              registry experience—it's behind everything we do.
            </p>
            <p>
              Every time we recommend a product, write an article or send a
              newsletter, our goal is the same:
            </p>
            <p>
              <i>
                Give new and expecting parents the practical support and
                guidance they need to feel less overwhelmed and more confident
                during this exciting life stage.
              </i>
            </p>
            <p>
              We do this in collaboration with our team of baby gear and product
              experts, our community of parents and medical professionals.
            </p>
            <p>
              For our editorial content, we collaborate with the Babylist Health
              Advisory Board. This group of renowned doctors, researchers and
              health professionals draw on their expertise to inform content
              decisions and help our community of expecting and new parents feel
              empowered throughout the journey to parenthood and beyond.
            </p>
          </div>
        </div>
        <div className={css.HealthAdvisoryBoard__mainImageContainer}>
          <Image
            alt="A medical professional consulting with a patient"
            className={css.HealthAdvisoryBoard__mainImage}
            src="https://images.babylist.com/image/upload/v1718924602/Consumer%20About%20Pages/HealthAdvisoryBoard_mainImage.png"
          />
        </div>
      </section>
      <section className={css.HealthAdvisoryBoard__boardMemberSection}>
        <div className={css.HealthAdvisoryBoard__boardContainer}>
          <h3 className={css.HealthAdvisoryBoard__boardMembersHeading}>
            Babylist Health Advisory Board
          </h3>
          <div className={css.HealthAdvisoryBoard__members}>
            {habQuery.isLoading && <p>Loading...</p>}
            {habQuery.data?.map((member) => (
              <div
                className={css.HealthAdvisoryBoard__memberCard}
                key={member.id}
              >
                <Image
                  className={css.HealthAdvisoryBoard__memberImage}
                  src={member.imageUrl}
                />
                <div className={css.HealthAdvisoryBoard__memberInfo}>
                  <h4 className={css.HealthAdvisoryBoard__memberName}>
                    {member.name}
                  </h4>
                  <p className={css.HealthAdvisoryBoard__memberJobTitle}>
                    {member.roleDescription}
                  </p>
                </div>
                <button
                  className={classNames(
                    'sr-only',
                    css.HealthAdvisoryBoard__memberButton
                  )}
                  type="button"
                  onClick={() => setSelectedMember(member)}
                >
                  See more about {member.name}
                </button>
              </div>
            ))}
          </div>
        </div>
        <Button
          className={css.HealthAdvisoryBoard__mainButtonMobile}
          href={guidesPath}
          size="md"
          variant="primary"
        >
          View latest expert guides
        </Button>
        <Button
          className={css.HealthAdvisoryBoard__mainButtonDesktop}
          href={guidesPath}
          size="lg"
          variant="primary"
        >
          View latest expert guides
        </Button>
      </section>
      <ScrollLockModal
        showCloseButton
        className={css.HealthAdvisoryBoard__modal}
        handleClose={() => setSelectedMember(null)}
        isOpen={selectedMember !== null}
      >
        {selectedMember !== null && (
          <div className={css.HealthAdvisoryBoard__modalContent}>
            <div className={css.HealthAdvisoryBoard__modalImageWrapper}>
              <Image
                alt={`A professional headshot of ${selectedMember.name}`}
                className={css.HealthAdvisoryBoard__modalImage}
                src={selectedMember.imageUrl}
              />
            </div>
            <div className={css.HealthAdvisoryBoard__memberDetails}>
              <div className={css.HealthAdvisoryBoard__memberNameAndTitle}>
                <h2 className={css.HealthAdvisoryBoard__memberName}>
                  {selectedMember.name}
                </h2>
                <h3 className={css.HealthAdvisoryBoard__memberTitle}>
                  {selectedMember.title}
                </h3>
              </div>
              <div className={css.HealthAdvisoryBoard__memberBio}>
                {documentToReactComponents(
                  selectedMember.bioText,
                  bioTextOptions
                )}
              </div>
              <div className={css.HealthAdvisoryBoard__memberSocialLinks}>
                {selectedMember.socialLinks.map((link) => (
                  <TextLink
                    className={css.HealthAdvisoryBoard__socialLink}
                    href={link.url}
                    innerContentClassName={
                      css.HealthAdvisoryBoard__socialLinkLabel
                    }
                    target="_blank"
                  >
                    {renderSocialLinkContent(link)}
                  </TextLink>
                ))}
              </div>
            </div>
          </div>
        )}
      </ScrollLockModal>
    </div>
  )
}

export default HealthAdvisoryBoard
