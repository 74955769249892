import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
} from 'react'

interface UIContextProps {
  isMobile: boolean
  appWebView: boolean
}

const UIContext = createContext<UIContextProps | undefined>(undefined)

export const UIProvider: FC<{
  isMobileSSR: boolean
  appWebView?: boolean
  children: React.ReactNode
}> = ({ isMobileSSR, appWebView = false, children }) => {
  const [isMobile, setIsMobile] = useState(isMobileSSR)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 525)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <UIContext.Provider value={{ isMobile, appWebView }}>
      {children}
    </UIContext.Provider>
  )
}

export const useUIContext = (): UIContextProps => {
  const context = useContext(UIContext)
  if (!context) {
    throw new Error('useUIContext must be used within a UIProvider')
  }
  return context
}
