import classNames from 'classnames'

import { getCashFundPaymentPreferencesString } from 'src/routes/(subdomains)/(my)/[slug]/utils/getCashFundPaymentPreferences/getCashFundPaymentPreferences'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'
import css from '../RegItemMinimalCardDetails.styles.scss'

const CashFundDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  registry,
  regItem,
}: RegItemMinimalCardDetailsProps) => {
  const { title } = regItem
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.CashFundDetails
  )
  const cashFundPaymentPreferences =
    getCashFundPaymentPreferencesString(registry)

  return (
    <div className={wrapperClasses} data-testid="CashFundDetails">
      <div className={css.Title}>{title}</div>
      <div className={css.Price}>Any amount</div>
      <div className={css.PaymentOptions}>{cashFundPaymentPreferences}</div>
    </div>
  )
}

export default CashFundDetails
