import { useParams } from 'react-router-dom-v5-compat'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import InStockAlertLink from '../../../../../../../../../store/components/in-stock-alert-link'

export const InStockNotification = () => {
  const { productId } = useParams()
  const [currentUser] = useCurrentUser()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (
    !genericProduct?.activeProduct?.approved ||
    !genericProduct?.activeProduct?.allowAvailabilitySubscription ||
    !currentUser?.email
  )
    return null

  return <InStockAlertLink productId={genericProduct.activeProductId} />
}
