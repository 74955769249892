import { useEffect } from 'react'
import { isBot } from 'lib/bowser'
import { registryHomePath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { getQueryParam } from 'utils'
import { ChecklistTheme } from '../ChecklistTheme/ChecklistTheme'
import { ChecklistAdminBar } from '../ChecklistAdminBar'
import { useRegistryChecklistCategories } from '../../api/hooks'

import css from './ChecklistThemesList.styles.scss'

interface ChecklistThemesListProps {
  registryId?: number | false
}

export const ChecklistThemesList = ({
  registryId = false,
}: ChecklistThemesListProps) => {
  const { data, isLoading } = useRegistryChecklistCategories(registryId)
  const tracker = useTracking()
  useEffect(() => {
    if (registryId) {
      let eventLocation = track.EventLocation.HELLO_BABY_BOX_REQUIREMENTS
      const viewingFromHBBModal = getQueryParam('fromHBB') === 'true'
      if (!viewingFromHBBModal) {
        eventLocation = window.document.referrer.includes(registryHomePath)
          ? track.EventLocation.FEED
          : track.EventLocation.REGISTRY
      }

      tracker.trackEvent({
        checklistType: data?.themes[0]?.checklistType || 1,
        event: track.checklistViewed,
        eventLocation,
        registryId,
      })
    }
  }, [])

  if (isLoading) {
    return <div className={css.ChecklistThemesList__themeListPlaceholder} />
  }

  return (
    <>
      <div>
        {data.themes.map((theme) => (
          <ChecklistTheme
            key={theme.id}
            {...theme}
            defaultExpanded={isBot}
            registryId={registryId}
          />
        ))}
      </div>
      {registryId && <ChecklistAdminBar registryId={registryId} />}
    </>
  )
}
