import React from 'react'

interface LocationProps {
  className?: string
}

const Location: React.FC<LocationProps> = ({ className }: LocationProps) => (
  <svg
    className={className}
    fill="none"
    height="33"
    viewBox="0 0 33 33"
    width="33"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Location Icon</title>
    <path
      clipRule="evenodd"
      d="M16.5459 4.01172C11.2309 4.01172 6.87924 8.43807 6.87924 13.9519C6.87924 17.4056 8.31399 21.1414 10.3235 24.0215C11.3241 25.4556 12.4424 26.641 13.5502 27.4584C14.6705 28.285 15.7007 28.6784 16.5459 28.6784C17.3911 28.6784 18.4213 28.285 19.5416 27.4584C20.6495 26.641 21.7677 25.4556 22.7683 24.0215C24.7778 21.1414 26.2126 17.4056 26.2126 13.9519C26.2126 8.43807 21.8609 4.01172 16.5459 4.01172ZM4.87924 13.9519C4.87924 7.38153 10.0789 2.01172 16.5459 2.01172C23.0129 2.01172 28.2126 7.38153 28.2126 13.9519C28.2126 17.9077 26.5928 22.0353 24.4085 25.166C23.3121 26.7373 22.0485 28.0942 20.7291 29.0678C19.4221 30.0321 17.9795 30.6784 16.5459 30.6784C15.1123 30.6784 13.6697 30.0321 12.3627 29.0678C11.0433 28.0942 9.77969 26.7373 8.68333 25.166C6.49897 22.0353 4.87924 17.9077 4.87924 13.9519ZM16.5459 10.6784C14.8891 10.6784 13.5459 12.0215 13.5459 13.6784C13.5459 15.3352 14.8891 16.6784 16.5459 16.6784C18.2028 16.6784 19.5459 15.3352 19.5459 13.6784C19.5459 12.0215 18.2028 10.6784 16.5459 10.6784ZM11.5459 13.6784C11.5459 10.917 13.7845 8.67839 16.5459 8.67839C19.3073 8.67839 21.5459 10.917 21.5459 13.6784C21.5459 16.4398 19.3073 18.6784 16.5459 18.6784C13.7845 18.6784 11.5459 16.4398 11.5459 13.6784Z"
      fill="#4A2143"
      fillRule="evenodd"
    />
  </svg>
)

export default Location
