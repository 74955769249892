import { find } from 'lodash'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import EmailCallout from 'components/email-callout'
import TopicSection from 'hello_baby/components/TopicSection'
import { PREGNANCY_WEEK_BY_WEEK_SLUG } from 'hello_baby/constants'
import { useParams } from 'react-router-dom-v5-compat'
import PregnancyWeekSelect from '../PregnancyWeekSelect'
import css from './PregnancyView.styles.scss'
import { useGuidesConfig, usePosts, useTopic } from '../../utils/useQueries'

const NUM_VISIBLE_POSTS = 5

const PregnancyView = () => {
  const { slug } = useParams()
  const posts = usePosts()
  const { emailUrls } = useGuidesConfig()

  const topic = useTopic(slug)

  let subTopics = topic?.children || []

  const firstTrimester = find(posts, { slug: 'first-trimester' }) as Post
  const secondTrimester = find(posts, { slug: 'second-trimester' }) as Post
  const thirdTrimester = find(posts, { slug: 'third-trimester' }) as Post
  const pregnancyWeekByWeek = find(subTopics, {
    slug: PREGNANCY_WEEK_BY_WEEK_SLUG,
  })

  const weekByWeekFeaturedPost = {
    title: pregnancyWeekByWeek?.pageTitle,
    featuredImage: { url: pregnancyWeekByWeek?.image },
    slug: pregnancyWeekByWeek?.slug,
    teaser: pregnancyWeekByWeek?.description,
  } as Post

  const weekByWeekPosts = filterPostsByTopicSlug(
    posts,
    PREGNANCY_WEEK_BY_WEEK_SLUG,
    { sortBy: 'title', order: 'asc' }
  )

  const topicSlugsToNotShowArticles = [
    'first-trimester-of-pregnancy',
    'second-trimester-of-pregnancy',
    'third-trimester-of-pregnancy',
    PREGNANCY_WEEK_BY_WEEK_SLUG,
  ]

  subTopics = subTopics.filter(
    (t) => topicSlugsToNotShowArticles.indexOf(t.slug) < 0
  )

  return (
    <div className={css.pregnancyContainer}>
      <PregnancyWeekSelect
        className="inline-block mbn pull-right hidden-xs"
        posts={weekByWeekPosts}
      />
      <TopicSection
        showAllButton
        posts={[
          weekByWeekFeaturedPost,
          firstTrimester,
          secondTrimester,
          thirdTrimester,
        ]}
        showTopics={false}
        topic={{
          name: pregnancyWeekByWeek?.name || '',
          slug: pregnancyWeekByWeek?.slug || '',
        }}
      />
      {subTopics.map((t) => {
        const filteredPosts = filterPostsByTopicSlug(posts, t.slug)
        return (
          <TopicSection
            hideLastPostSm
            key={`topic_section_${t.slug}`}
            posts={filteredPosts.slice(0, NUM_VISIBLE_POSTS)}
            showAllButton={filteredPosts.length > NUM_VISIBLE_POSTS}
            showTopics={false}
            teaserText={t.description}
            topic={{ name: t.name, slug: t.slug }}
          />
        )
      })}
      <EmailCallout
        emailType={BLConstants.EMAIL_USER_PREGNANCY}
        emailUrlsByWeek={emailUrls}
      />
    </div>
  )
}

export default PregnancyView
