// TODO - https://babylist.atlassian.net/browse/HRT-8
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState } from 'react'
import classNames from 'classnames'

import css from './ThumbnailRow.styles.scss'

import { ExcessQuantityProps, ThumbnailRowProps } from './ThumbnailRow.types'

import { QuantifiableThumbnailProps } from '../QuantifiableThumbnail/QuantifiableThumbnail.types'
import QuantifiableThumbnail from '../QuantifiableThumbnail/QuantifiableThumbnail'

const ExcessQuantity: React.FC<ExcessQuantityProps> = ({
  excess,
  selectable = false,
}) => {
  const ExcessQuantityClasses = classNames(css.ExcessQuantity, {
    [css['ExcessQuantity--selectable']]: selectable,
  })

  return (
    <div
      className={ExcessQuantityClasses}
      tabIndex={selectable ? 0 : undefined}
    >
      <span>{excess > 99 ? '99+' : `+${excess}`}</span>
    </div>
  )
}

const ThumbnailRow: React.FC<ThumbnailRowProps> = ({
  className,
  thumbnails,
  maxVisible,
  onSelectionChanged,
  responsive = false,
  selectable = false,
  size,
}) => {
  const [selected, setSelected] = useState<QuantifiableThumbnailProps>()

  const ThumbnailRowClasses = classNames(css.ThumbnailRow, className, {
    [css['ThumbnailRow--small']]: size === 'sm',
    [css['ThumbnailRow--medium']]: size === 'md',
    [css['ThumbnailRow--responsive']]: responsive,
  })

  const thumbnailsToShow = maxVisible
    ? thumbnails.slice(0, maxVisible)
    : thumbnails

  const handleSelectionChange = (thumbnail: QuantifiableThumbnailProps) => {
    setSelected(thumbnail)
    if (onSelectionChanged) onSelectionChanged(thumbnail)
  }

  return (
    <div className={ThumbnailRowClasses}>
      {thumbnailsToShow.map((thumbnail) => (
        <QuantifiableThumbnail
          {...thumbnail}
          key={thumbnail.imageSrc}
          responsive={responsive}
          selectable={selectable}
          selected={selected === thumbnail}
          size={size}
          onClick={() => handleSelectionChange(thumbnail)}
        />
      ))}
      {maxVisible && thumbnails.length > maxVisible && (
        <ExcessQuantity
          excess={thumbnails.length - maxVisible}
          selectable={selectable}
        />
      )}
    </div>
  )
}

export default ThumbnailRow
/* eslint-enable jsx-a11y/no-noninteractive-tabindex */
