import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { IconButton } from 'baby-design' // eslint-disable-line import/no-extraneous-dependencies
import { ArrowTop } from 'baby-design/icons' // eslint-disable-line import/no-extraneous-dependencies
import FixedButtonTransition from '../FixedButtonTransition'
import { BackToTopFloatingButtonProps } from './BackToTopFloatingButton.types'
import css from './BackToTopFloatingButton.styles.scss'

const BackToTopFloatingButton: React.FC<BackToTopFloatingButtonProps> = ({
  onClick,
}) => {
  const [show, setShow] = useState(false)
  const handleClick = () => {
    setShow(false)
    onClick()
  }

  useEffect(() => {
    if (typeof document === 'undefined') return undefined

    // Using lodash debounce because our current usehooks-ts version is < 3.0.0
    const debouncedScroll = debounce(
      () => {
        if (typeof window === 'undefined') return

        setShow(window.scrollY > window.innerHeight + 100)
      },
      200,
      { trailing: true, leading: false }
    )

    document.addEventListener('scroll', debouncedScroll)

    return () => document.removeEventListener('scroll', debouncedScroll)
  }, [])

  return (
    <div
      className={css.BackToTopFloatingButton}
      data-testid="RegistryBackToTop-floating-button"
    >
      <FixedButtonTransition in={show}>
        <IconButton
          className={css.BackToTopFloatingButton__button}
          data-testid="RegistryBackToTop-floating-button-icon-button"
          size="md"
          variant="fill-inverted"
          onClick={handleClick}
        >
          <ArrowTop />
        </IconButton>
      </FixedButtonTransition>
    </div>
  )
}

export default BackToTopFloatingButton
