import { Button } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import css from './ConfirmCategoryDeleteModal.styles.scss'

interface ConfirmCategoryDeleteModalProps {}

export default NiceModal.create(({}: ConfirmCategoryDeleteModalProps) => {
  const modal = useModal()

  const handleClose = () => {
    modal.resolve(false)
    modal.remove()
  }

  const handleLeaveClick = () => {
    // close modal and drawer
    modal.resolve(true)
    modal.remove()
  }

  const primaryButton = (
    <Button size="md" variant="destructive" onClick={handleLeaveClick}>
      Delete
    </Button>
  )

  const cancelButton = (
    <Button size="md" variant="secondary" onClick={handleClose}>
      Cancel
    </Button>
  )

  return (
    <ScrollLockModal
      handleClose={handleClose}
      isOpen={modal.visible}
      primaryButton={primaryButton}
      secondaryButton={cancelButton}
    >
      <div className={css.ConfirmCategoryDeleteModal}>
        <h2>Are you sure?</h2>
        <p>Any items in this category will be moved to General.</p>
      </div>
    </ScrollLockModal>
  )
})
