import React from 'react'

interface StorkProps {
  className?: string
}

const Stork: React.FC<StorkProps> = ({ className }: StorkProps) => (
  <svg
    className={className}
    fill="none"
    height="29"
    viewBox="0 0 29 29"
    width="29"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Stork Icon</title>
    <path
      d="M14.4723 7.23396C18.868 7.53146 23.199 8.45289 27.3351 9.97055C27.5241 10.0503 27.6796 10.1929 27.7755 10.3742C27.8713 10.5556 27.9014 10.7644 27.8608 10.9655C27.8202 11.1665 27.7114 11.3473 27.5527 11.4772C27.394 11.6072 27.1953 11.6782 26.9902 11.6784H9.21245M9.21245 11.6784C10.2673 11.6784 11.2985 11.3656 12.1756 10.7796C13.0527 10.1935 13.7362 9.36057 14.1399 8.38603C14.5436 7.41149 14.6492 6.33914 14.4434 5.30457C14.2376 4.27001 13.7297 3.3197 12.9838 2.57382C12.2379 1.82794 11.2876 1.31999 10.253 1.1142C9.21847 0.908412 8.14612 1.01403 7.17158 1.4177C6.19704 1.82136 5.36408 2.50495 4.77805 3.38201C4.19202 4.25908 3.87922 5.29022 3.87922 6.34505V17.0117C3.87922 17.719 3.59827 18.3972 3.09817 18.8973C2.59808 19.3974 1.9198 19.6784 1.21255 19.6784M9.21245 11.6784C9.21245 11.6784 10.9903 19.3998 10.9903 22.345C10.9903 23.7595 10.4284 25.1161 9.42816 26.1163C8.42797 27.1165 7.07141 27.6784 5.65693 27.6784M23.7631 11.6784C25.5682 14.8689 27.8793 19.3619 27.8793 21.4561C27.8793 23.1064 27.2237 24.689 26.0568 25.8559C24.8899 27.0228 23.3073 27.6784 21.657 27.6784C20.0068 27.6784 18.4242 27.0228 17.2573 25.8559C16.0904 24.689 15.4348 23.1064 15.4348 21.4561C15.4348 19.3619 17.7459 14.8689 19.551 11.6784M25.2125 2.78941L21.6569 6.34497L18.1017 2.78941M9.21245 4.7154C9.33032 4.7154 9.44337 4.76223 9.52672 4.84558C9.61007 4.92893 9.65689 5.04197 9.65689 5.15985C9.65689 5.27772 9.61007 5.39073 9.52672 5.47408C9.44337 5.55743 9.33057 5.60425 9.2127 5.60425C9.09482 5.60425 8.98178 5.55743 8.89843 5.47408C8.81508 5.39073 8.76825 5.27772 8.76825 5.15985C8.76825 5.04197 8.81508 4.92893 8.89843 4.84558C8.98178 4.76223 9.09458 4.7154 9.21245 4.7154Z"
      stroke="#4A2143"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default Stork
