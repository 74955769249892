import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { OrganizeIcon, FilterIcon } from 'components/icons'
import Link from 'components/link'
import { organizeRegistryPath } from 'lib/urls'
import { FilterBar } from '../filter-bar'
import ToggleCollapseAllCategoriesLink from './toggle-collapse-all-categories-link'

export default class DefaultHeader extends PureComponent {
  static propTypes = {
    registryUrlSlug: PropTypes.string.isRequired,
    regItemsCount: PropTypes.number.isRequired,
    openMobileFiltersModal: PropTypes.func.isRequired,
  }

  state = {
    showAddGiftsModal: false,
  }

  showAddGiftsModal = () => this.setState({ showAddGiftsModal: true })

  hideAddGiftsModal = () => this.setState({ showAddGiftsModal: false })

  render() {
    const {
      addFilter,
      categories,
      filters,
      registryUrlSlug,
      regItemsCount,
      stores,
    } = this.props

    return (
      <>
        <div className="mtl mtxxl-md mbl flex center">
          <FilterBar
            isContributorView
            addFilter={addFilter}
            categories={categories}
            className="hidden-xs"
            filters={filters}
            stores={stores}
          />
          <div className="h4 inline-block mvn visible-xs">
            {regItemsCount} Registry {pluralize('Gift', regItemsCount)}
          </div>

          <div className="flex row justify-end center flex-grow">
            <ToggleCollapseAllCategoriesLink className="mrl hidden-xs" />
            <Link
              className="hidden-xs"
              routerVersion={4}
              to={organizeRegistryPath(registryUrlSlug)}
            >
              <OrganizeIcon />
            </Link>

            <Link
              className="visible-xs mrl"
              onClick={this.props.openMobileFiltersModal}
            >
              <FilterIcon />
            </Link>
            <Link
              className="mrl visible-xs"
              routerVersion={4}
              to={organizeRegistryPath(registryUrlSlug)}
            >
              <OrganizeIcon />
            </Link>
          </div>
        </div>
      </>
    )
  }
}
