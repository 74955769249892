import { createContext, useContext, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import fetch from 'lib/fetch'
import { apiV3RegistryGiftGiversPath } from 'lib/urls'

import {
  GiftGiver,
  GiftGiversProviderProps,
  GiftGiversContextType,
  GiftGiversSort,
} from './GiftGiversProvider.types'

const defaultSort: GiftGiversSort = {
  field: 'most_recent_reservation_at',
  direction: 'desc',
}

const GiftGiversContext = createContext<GiftGiversContextType>({
  isLoading: false,
  isFetched: false,
  giftGivers: [],
  search: null,
  sort: defaultSort,
  setSearch: (_search: string | null) => undefined,
  setSort: (_sort: GiftGiversSort) => undefined,
})

const shouldRequestFakeData = () => {
  const params = new URLSearchParams(window.location.search)
  return !!params.get('fake_data')
}

const fakeDataScenario = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('scenario')
}

interface QueryParamsParams {
  search: string | null
  sort: GiftGiversSort
  cursor: string | null
}
const queryParams = ({ search, sort, cursor }: QueryParamsParams) => {
  const urlSearchParams = new URLSearchParams({
    orderBy: sort.field,
    orderDirection: sort.direction,
  })

  if (cursor) {
    urlSearchParams.append('cursor', cursor)
  }

  if (search) {
    urlSearchParams.append('query', search)
  }

  if (shouldRequestFakeData()) {
    urlSearchParams.append('fake_data', 'true')

    const scenario = fakeDataScenario()
    if (scenario) {
      urlSearchParams.append('scenario', scenario)
    }
  }

  return urlSearchParams.toString()
}

interface QueryPathParams {
  registryId: string | number | undefined
  search: string | null
  sort: GiftGiversSort
  cursor: string | null
}
const queryPath = ({ registryId, search, sort, cursor }: QueryPathParams) =>
  `${apiV3RegistryGiftGiversPath(registryId)}?${queryParams({ search, sort, cursor })}`

interface GiftGiversApiResponse {
  giftGivers: GiftGiver[]
  paginationInfo: {
    hasMore: boolean
    cursor: string | null
  }
}
interface FetchAllGiftGiversParams {
  registryId: string | number | undefined
  search: string | null
  sort: GiftGiversSort
}
const fetchAllGiftGivers = async ({
  registryId,
  search,
  sort,
}: FetchAllGiftGiversParams): Promise<GiftGiver[]> => {
  let giftGivers: GiftGiver[] = []

  if (!registryId) {
    return giftGivers
  }

  let hasMore: boolean
  let cursor: string | null = null
  do {
    // eslint-disable-next-line no-await-in-loop
    const response: GiftGiversApiResponse = await fetch(
      queryPath({ registryId, search, sort, cursor }),
      {
        method: 'GET',
      }
    )

    const {
      giftGivers: nextGiftGivers,
      paginationInfo: { hasMore: nextHasMore, cursor: nextCursor },
    } = response

    hasMore = nextHasMore
    cursor = nextCursor
    giftGivers = [...giftGivers, ...nextGiftGivers]
  } while (hasMore)

  return giftGivers
}

export const GiftGiversProvider = ({
  children,
  registryId,
}: GiftGiversProviderProps) => {
  const [giftGivers, setGiftGivers] = useState<GiftGiver[]>([])
  const [sort, setSort] = useState<GiftGiversSort>(defaultSort)
  const [search, setSearch] = useState<string | null>(null)

  const {
    data: loadedGiftGivers,
    isLoading,
    isFetched,
  } = useQuery({
    enabled: !!registryId,
    queryKey: [
      'REGISTRY_GIFT_GIVERS',
      registryId,
      search,
      JSON.stringify(sort),
    ],
    queryFn: () => fetchAllGiftGivers({ registryId, search, sort }),
  })

  if (isFetched && !!loadedGiftGivers && giftGivers !== loadedGiftGivers) {
    setGiftGivers(loadedGiftGivers)
  }

  const value = {
    isLoading,
    isFetched,
    giftGivers,
    search,
    sort,
    setSearch,
    setSort,
  }

  return (
    <GiftGiversContext.Provider value={value}>
      {children}
    </GiftGiversContext.Provider>
  )
}

export const useGiftGivers = () => useContext(GiftGiversContext)
