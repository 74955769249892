/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable complexity */
import { PromoMessage, Tag, StoreIcons } from 'baby-design'
import classNames from 'classnames'
import {
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'
import { FAVORITE_COPY } from 'registry/constants'
import { SaleCallout, PriceDetails } from 'src/types/priceDetails'
import RegItemBadges from 'src/routes/(registry)/list/[slug]/components/RegItemCard/components/ProductDescription/components/RegItemBadges/RegItemBadges'
import css from './ProductDescription.styles.scss'
import { ProductNotes } from '../ProductNotes/ProductNotes'
import { Product, RegItemBadge } from '../../RegItemCard.types'
import { getPricePropsForRegItem } from '../../../../utils/productHelpers'

interface BaseProductDescriptionProps {
  badges: RegItemBadge[]
  product: Product
  isInSelfPurchaseExperiment?: boolean
}

type ProductDescriptionProps = BaseProductDescriptionProps & {
  size: 'desktop' | 'tablet' | 'phone'
}

export const ProductDescription = ({
  size,
  product,
  badges,
  isInSelfPurchaseExperiment = false,
}: ProductDescriptionProps) => {
  if (size === 'desktop') {
    return (
      <ProductDescriptionDesktop
        badges={badges}
        isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
        product={product}
      />
    )
  }
  if (size === 'tablet') {
    return (
      <ProductDescriptionTablet
        badges={badges}
        isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
        product={product}
      />
    )
  }
  return (
    <ProductDescriptionMobile
      badges={badges}
      isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
      product={product}
    />
  )
}

const Headings = ({
  heading,
  subheading,
  url,
}: {
  heading: string
  subheading?: string
  url: string
}) => (
  <>
    <a
      className={css.product_description__heading}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      {heading}
    </a>
    {subheading && (
      <div className={css.product_description__subheading}>{subheading}</div>
    )}
  </>
)

const Tags = ({
  tags,
  showVariant = false,
}: {
  tags: Array<string>
  showVariant?: boolean
}) => {
  const specialTags = new Set([FAVORITE_COPY])

  return (
    <div className={css.product_description__tags}>
      {tags.map((tag) => (
        <Tag
          key={tag}
          size="sm"
          type="primary"
          variant={showVariant && specialTags.has(tag) ? 'special' : 'normal'}
        >
          {tag}
        </Tag>
      ))}
    </div>
  )
}

const ProductDescriptionDesktop = ({
  badges,
  product,
  isInSelfPurchaseExperiment,
}: BaseProductDescriptionProps) => {
  const { currentPrice } = getPricePropsForRegItem(
    product.priceDetails as PriceDetails,
    product.offers?.length || 0
  )
  return (
    <div
      className={classNames(
        css.product_description,
        css.product_description__desktop
      )}
    >
      {badges.length > 0 && <RegItemBadges badges={badges} size="medium" />}
      <Headings
        heading={product.heading}
        subheading={product.subheading}
        url={product.url}
      />
      {product.tags && <Tags showVariant tags={product.tags} />}
      {product.isGiftCard && (
        <div className={css.product_description__price}>
          {`$${ELECTRONIC_GIFT_CARD_MIN} - $${ELECTRONIC_GIFT_CARD_MAX}`}
        </div>
      )}
      {!product.isGiftCard &&
        product.offers &&
        product.offers.length > 0 &&
        product.price && (
          <PriceDisplay
            currentPrice={currentPrice}
            isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
            product={product}
          />
        )}
      {product.notes && (
        <div className={css.product_description__notes}>
          <ProductNotes notes={product.notes} />
          <div className={css.product_description__notes_beak} />
        </div>
      )}
    </div>
  )
}

const ProductDescriptionTablet = ({
  badges,
  product,
  isInSelfPurchaseExperiment,
}: BaseProductDescriptionProps) => {
  const callouts = product.priceDetails?.saleAttributes?.saleCallouts || []
  const { msrp, currentPrice, onSale } = getPricePropsForRegItem(
    product.priceDetails as PriceDetails,
    product.offers?.length || 0
  )

  return (
    <div
      className={classNames(
        css.product_description,
        css.product_description__tablet
      )}
    >
      {badges.length > 0 && <RegItemBadges badges={badges} size="medium" />}
      <Headings
        heading={product.heading}
        subheading={product.subheading}
        url={product.url}
      />
      {product.isGiftCard && (
        <div className={css.product_description__price}>
          {`$${ELECTRONIC_GIFT_CARD_MIN} - $${ELECTRONIC_GIFT_CARD_MAX}`}
        </div>
      )}
      {!product.isGiftCard && product.offers && product.price && (
        <StoreIcons
          className={classNames(
            onSale ? css.product_description__sale_price : ''
          )}
          discount={msrp}
          price={
            <PriceDisplay
              currentPrice={currentPrice}
              isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
              product={product}
            />
          }
          size="medium"
          stores={product.offers.map((offer) => ({
            name: offer.storeIconName,
          }))}
        />
      )}
      {callouts?.length > 0 &&
        callouts.map((callout: SaleCallout) => (
          <PromoMessage
            className={css.product_description__callout}
            key={`callout-${callout.text}`}
            size="sm"
            text={callout.text}
          />
        ))}
      {product.tags && <Tags tags={product.tags} />}
    </div>
  )
}

const ProductDescriptionMobile = ({
  badges,
  product,
  isInSelfPurchaseExperiment,
}: BaseProductDescriptionProps) => {
  const callouts = product.priceDetails?.saleAttributes?.saleCallouts || []
  const { msrp, currentPrice, onSale } = getPricePropsForRegItem(
    product.priceDetails as PriceDetails,
    product.offers?.length || 0
  )
  return (
    <div
      className={classNames(
        css.product_description,
        css.product_description__mobile
      )}
    >
      {badges.length > 0 && <RegItemBadges badges={badges} size="small" />}
      <Headings
        heading={product.heading}
        subheading={product.subheading}
        url={product.url}
      />
      {product.isGiftCard && (
        <div className={css.product_description__price}>
          {`$${ELECTRONIC_GIFT_CARD_MIN} - $${ELECTRONIC_GIFT_CARD_MAX}`}
        </div>
      )}
      {!product.isGiftCard && product.offers && product.price && (
        <StoreIcons
          className={classNames(
            onSale ? css.product_description__sale_price : ''
          )}
          discount={msrp}
          price={
            <PriceDisplay
              currentPrice={currentPrice}
              isInSelfPurchaseExperiment={isInSelfPurchaseExperiment}
              product={product}
            />
          }
          size="small"
          stores={product.offers.map((offer) => ({
            name: offer.storeIconName,
          }))}
        />
      )}
      {callouts?.length > 0 &&
        callouts.map((callout: SaleCallout) => (
          <PromoMessage
            className={css.product_description__callout}
            key={`callout-${callout.text}`}
            size="xs"
            text={callout.text}
          />
        ))}
      {product.tags && <Tags tags={product.tags} />}
    </div>
  )
}

type RenderPriceProps = Pick<
  BaseProductDescriptionProps,
  'product' | 'isInSelfPurchaseExperiment'
> & {
  currentPrice: string | undefined
}

const PriceDisplay = ({
  product,
  currentPrice,
  isInSelfPurchaseExperiment,
}: RenderPriceProps) => {
  if (!product.offers || product.offers.length <= 1 || !currentPrice) {
    return (
      <span className={css.product_description__price}>{currentPrice}</span>
    )
  }

  return (
    <span
      className={classNames(css.product_description__price, {
        [css['product_description__price--regularWeightPrefix']]:
          isInSelfPurchaseExperiment,
      })}
    >
      <span className={css.product_description__price_prefix}>From </span>
      {currentPrice}
    </span>
  )
}
