import { useQuery } from '@tanstack/react-query'
import { fetchCart, FETCH_CART_KEY } from 'src/api/queries'
import { CartResponse } from 'src/api/types'

const useGetCart = () => {
  const cartQuery = useQuery({
    queryKey: [FETCH_CART_KEY],
    queryFn: () => fetchCart(),
  })
  const cart: CartResponse | undefined = cartQuery.data

  return { cart, cartQuery }
}

export default useGetCart
