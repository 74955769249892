import { FC } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge } from 'baby-design'

import css from './QuantityBadge.styles.scss'

const QuantityBadge: FC<{ quantity: number }> = ({ quantity }) => {
  if (quantity <= 1) {
    return null
  }

  return (
    <div className={css.QuantityBadge}>
      <Badge
        fill="standard-grey"
        quantity={quantity}
        size="medium"
        variant="quantity"
      />
    </div>
  )
}

export default QuantityBadge
