import { bowserFlags } from 'lib/bowser'
import { Col, Row } from 'react-bootstrap'
import { map } from 'lodash'
import classNames from 'classnames'
import * as blUrls from 'lib/urls'
import PageAnimation from 'components/page-animation'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import { SideNav } from 'components/side-nav'
import { getActiveSlugSet } from 'hello_baby/reducers'
import { track, useTracking } from 'lib/analytics'
import useOnCurrentUserLoaded from 'shared/hooks/useOnCurrentUserLoaded/useOnCurrentUserLoaded'
import {
  getSearchHistoryOfType,
  SEARCH_HISTORY_TYPE_GUIDES,
} from 'shared/utils/searchHistory/searchHistory'
import {
  GUIDE_SEARCH_INDEX_KEY,
  useSearchId,
} from 'shared/hooks/useSearchId/useSearchId'
import getSearchResultsPageEventType from 'shared/helpers/getSearchResultsPageEventType'
import { useParams } from 'react-router-dom-v5-compat'
import PregnancyWeekSelect from '../PregnancyWeekSelect'
import { SectionHeading } from '../../../../../components/headings'
import css from './PostListView.styles.scss'
import { usePosts, useTopic, useTopicNavigation } from '../../utils/useQueries'

interface PostListViewProps {
  children: React.ReactNode
  searchResults?: []
}

const PostListView: React.FC<PostListViewProps> = ({
  children,
  searchResults,
}: PostListViewProps) => {
  const { slug: topicSlug } = useParams()
  const unfilteredPosts = usePosts()
  const posts = filterPostsByTopicSlug(unfilteredPosts, topicSlug, {
    sortBy: 'title',
    order: 'asc',
  })

  const tracker = useTracking()
  const [, getSearchId] = useSearchId(GUIDE_SEARCH_INDEX_KEY)

  const topicNavigation = useTopicNavigation()
  const topic = useTopic(topicSlug)

  const { description, name: title } = topic || {}

  useOnCurrentUserLoaded((currentUser) => {
    // This page is used for category lists as well as search results. We don't want this event to fire for category lists.
    // Search results will be undefined if this is a category list, otherwise it will be an array.
    if (!Array.isArray(searchResults)) return

    // Remove the single quotes in the title string on search results.
    const cleanSearchTerm = title?.replace(/["']/g, '')
    const lastSearchTerm = getSearchHistoryOfType(SEARCH_HISTORY_TYPE_GUIDES)[0]

    tracker.trackEvent({
      event: track.searchResultsPageViewed,
      eventLocation: track.EventLocation.GUIDES,
      eventType: getSearchResultsPageEventType(track.EventType.GUIDES),
      eventUrl: window.location.href,
      registryOwnerId: currentUser?.currentRegistry?.ownerId,
      registryId: currentUser?.registryId,
      isRegistryOwner:
        currentUser &&
        currentUser?.currentRegistry?.ownerId === currentUser?.id,
      listType:
        currentUser?.currentRegistry?.type || track.ListType.BABY_REGISTRY,
      searchTerm: cleanSearchTerm,
      searchId: getSearchId(),
      resultCount: searchResults.length,
      top20Results: searchResults
        .slice(0, 20)
        .map((r: { slug: string }) => r.slug),
      executedSearch: lastSearchTerm === cleanSearchTerm || false,
    })
  })

  const navCategory = { title: 'Home', path: '/hello-baby', urlSlug: null }
  const sideNavTree = map(topicNavigation.items, (item) => ({
    title: item.name,
    urlSlug: item.slug,
    children: map(item.children, (child) => ({
      title: child.name,
      urlSlug: child.slug,
      path: blUrls.helloBabyPostPath(child.slug),
    })),
    path: blUrls.helloBabyPostPath(item.slug),
  }))

  return (
    <div className="container">
      <Row>
        <Col
          className={classNames(
            css.titleContainer,
            'flex-vertical-center-sm mvl'
          )}
          key={`${topicSlug}-header`}
          sm={12}
        >
          <div className="full-width text-center">
            <SectionHeading>{title || ''}</SectionHeading>
            {description ? <div className="h5">{description}</div> : null}
            {topicSlug === 'pregnancy-week-by-week' ? (
              <PregnancyWeekSelect className="inline-block" posts={posts} />
            ) : null}
          </div>
        </Col>
        {!bowserFlags.mobile && (
          <Col className={css.sidenav} sm={3}>
            <SideNav
              activeSlugSet={getActiveSlugSet(topicSlug, topicNavigation)}
              categoryTree={sideNavTree || []}
              navCategory={navCategory}
              sidebarTitle="Guides"
            />
          </Col>
        )}
        <Col className="ptm mts" sm={9}>
          <PageAnimation>
            <div className={css.contentContainer} key={topicSlug}>
              {children}
            </div>
          </PageAnimation>
        </Col>
      </Row>
    </div>
  )
}

export default PostListView
