import React from 'react'
import { ShipmentDetailsMetadataProps } from 'src/routes/(registry)/shipments/[shipmentId]/components/ShipmentDetailsMetadata/ShipmentDetailsMetadata.types'
import { Shipment, TrackingInfo } from 'src/types/shipments'
import { STATUS_DELIVERED } from 'src/routes/(registry)/shipments/util/constants'
import ShipmentDetailsMetadataSkeleton from 'src/routes/(registry)/shipments/[shipmentId]/components/ShipmentDetailsMetadata/ShipmentDetailsMetadata.skeleton'
import css from './ShipmentDetailsMetadata.styles.scss'
import { TextLink } from '../../../../../../../baby-design'
import LinkOut from '../../../../../../../baby-design/icons/LinkOut.svg'

interface MetadatumProps {
  children?: React.ReactNode
  title?: string
}

const Metadatum: React.FC<MetadatumProps> = ({ children, title }) => (
  <div className={css.ShipmentDetailsMetadata}>
    <p className={css.ShipmentDetailsMetadata__Title}>{title}</p>
    <div className={css.ShipmentDetailsMetadata__Content}>{children}</div>
  </div>
)

interface ShipmentMetadatumProps {
  shipment?: Shipment
}

const dateMetadataTitleFromShipment = (
  shipment: Shipment | undefined
): string | undefined => {
  switch (shipment?.shipmentStatus?.currentState) {
    case 'processing':
    case 'preparing':
      return 'Estimated to ship'
    case 'shipped':
      return 'Estimated delivery on'
    case 'delivered':
      return 'Delivered on'
    default:
      return undefined
  }
}

const dateValueFromShipment = (
  shipment: Shipment | undefined
): string | undefined => {
  switch (shipment?.shipmentStatus?.currentState) {
    case 'processing':
    case 'preparing':
      return shipment?.shipmentStatus?.estimatedShippingDate
    case 'shipped':
      return shipment?.shipmentStatus?.estimatedDeliveryDate?.minDate
    case 'delivered':
      return (
        shipment?.shipmentStatus?.deliveredAt ||
        shipment?.shipmentStatus?.estimatedDeliveryDate?.maxDate ||
        shipment?.shipmentStatus?.estimatedDeliveryDate?.minDate
      )
    default:
      return undefined
  }
}

const formatDate = (date: number): string => {
  const dateFormatOptions = {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  } as Intl.DateTimeFormatOptions

  const today = new Date()
  const dt = new Date(date)
  if (dt.getFullYear() !== today.getFullYear()) {
    dateFormatOptions.year = 'numeric'
  }

  return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(date)
}

const DateMetadatum: React.FC<ShipmentMetadatumProps> = ({ shipment }) => {
  let dateValue = dateValueFromShipment(shipment)
  if (dateValue) {
    dateValue = formatDate(Date.parse(dateValue))
  }

  return (
    <Metadatum title={dateMetadataTitleFromShipment(shipment)}>
      {dateValue}
    </Metadatum>
  )
}

const AddressMetadatum: React.FC<ShipmentMetadatumProps> = ({ shipment }) => {
  const address = shipment?.address
  if (!address) return null

  const title =
    shipment?.shipmentStatus?.currentState === STATUS_DELIVERED
      ? 'Shipped to'
      : 'Shipping to'

  return (
    <Metadatum title={title}>
      {address.name}
      <br />
      {address.address1}
      <br />
      {address.address2 && (
        <>
          {address.address2}
          <br />
        </>
      )}
      {address.city}, {address.state} {address.zip}
    </Metadatum>
  )
}

interface TrackingMetadatumProps {
  tracking: TrackingInfo
}

const TrackingMetadatum: React.FC<TrackingMetadatumProps> = ({ tracking }) => {
  const icon = <LinkOut className={css.ShipmentDetailsMetadata__TrackingIcon} />

  return (
    <Metadatum title="Tracking">
      <div className={css.ShipmentDetailsMetadata__Tracking}>
        <span>{tracking.number}</span>
        <TextLink href={tracking.url} icon={{ side: 'right', icon }}>
          <span className={css.ShipmentDetailsMetadata__TrackingLinkText}>
            Track package
          </span>
        </TextLink>
      </div>
    </Metadatum>
  )
}

const ShipmentDetailsMetadata: React.FC<ShipmentDetailsMetadataProps> = ({
  shipment,
}) => {
  if (!shipment) {
    return <ShipmentDetailsMetadataSkeleton />
  }

  return (
    <div className={css.ShipmentDetailsMetadata__Container}>
      <DateMetadatum shipment={shipment} />
      <hr />
      <AddressMetadatum shipment={shipment} />
      {shipment?.trackingInfos?.map((tracking) => (
        <>
          <hr />
          <TrackingMetadatum tracking={tracking} />
        </>
      ))}
    </div>
  )
}

export default ShipmentDetailsMetadata
