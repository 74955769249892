import classnames from 'classnames'
import { useParams } from 'react-router-dom-v5-compat'
import React from 'react'
import { trackBreadcrumbExpandClick } from 'src/routes/(shop)/lib/tracking'
import { useTracking } from 'lib/analytics'
import { TrackingHook } from 'react-tracking'
import { useUIContext } from 'src/contexts/UIContext'
import { ContentContainer } from '../../../../../../components/ContentContainer'
import css from './Breadcrumb.styles.scss'
import {
  getTruncatedCategories,
  getListOfCategoriesToDisplay,
} from './utils/helpers'
import { TruncatedCrumbs } from './TruncatedCrumbs/TruncatedCrumbs'
import { ExpandedCrumbs } from './ExpandedCrumbs/ExpandedCrumbs'

interface BreadcrumbProps {
  genericProduct: any
}

export const Breadcrumb = ({ genericProduct }: BreadcrumbProps) => {
  const {
    categoryBreadcrumbs,
    name,
    slug,
    id: genericProductId,
  } = genericProduct
  const { isMobile } = useUIContext()
  const { productId } = useParams()
  const breadcrumbRef = React.useRef(null)
  const { trackEvent } = useTracking()

  const [isExpanded, setIsExpanded] = React.useState(false)
  const handleExpansion = (trackEvent: TrackingHook['trackEvent']) => () => {
    setIsExpanded(!isExpanded)
    trackBreadcrumbExpandClick(trackEvent)
  }

  if (!categoryBreadcrumbs) return null

  const listOfTruncatedCategories = getTruncatedCategories(categoryBreadcrumbs)
  const listOfCategoriesToDisplay = getListOfCategoriesToDisplay(
    categoryBreadcrumbs,
    listOfTruncatedCategories
  )
  const hasTruncatedCategoriesToDisplay = listOfTruncatedCategories.length > 0

  return isMobile ? (
    <ContentContainer>
      <nav aria-label="Breadcrumb" data-testid="mobile" ref={breadcrumbRef}>
        <ol
          className={classnames(
            css.Breadcrumb,
            isExpanded
              ? css['Breadcrumb--expanded']
              : css['Breadcrumb--collapsed']
          )}
        >
          {!isExpanded && hasTruncatedCategoriesToDisplay ? (
            <>
              <TruncatedCrumbs
                genericProductId={genericProductId}
                listOfCategoriesToDisplay={listOfCategoriesToDisplay}
                listOfTruncatedCategories={listOfTruncatedCategories}
                productId={productId}
                productName={name}
                productSlug={slug}
                onClick={handleExpansion(trackEvent)}
              />
            </>
          ) : (
            <>
              <ExpandedCrumbs
                isMobile
                categoryBreadcrumbs={categoryBreadcrumbs}
                genericProductId={genericProductId}
                productId={productId}
                productName={name}
                productSlug={slug}
              />
            </>
          )}
        </ol>
      </nav>
    </ContentContainer>
  ) : (
    <ContentContainer>
      <nav aria-label="Breadcrumb">
        <ol className={classnames(css.Breadcrumb, css['Breadcrumb--desktop'])}>
          <ExpandedCrumbs
            categoryBreadcrumbs={categoryBreadcrumbs}
            genericProductId={genericProductId}
            productId={productId}
            productName={name}
            productSlug={slug}
          />
        </ol>
      </nav>
    </ContentContainer>
  )
}
