import TextLink from 'baby-design/components/TextLink/TextLink'
import { BookmarkButtonProps } from './BookmarkButton.types'
import css from './BookmarkButton.styles.scss'

const BookmarkButton: React.FC<BookmarkButtonProps> = ({ bookmarkletJS }) => {
  const handleButtonClick = () => {
    alert(
      "Almost! Simply drag and drop me to your bookmarks bar! (Don't click.)"
    )
  }

  return (
    <TextLink
      className={css.BookmarkletButton}
      href={bookmarkletJS}
      icon={{ side: 'left', icon: <i className="fa fa-heart mrs" /> }}
      onClick={handleButtonClick}
    >
      Add to Babylist
    </TextLink>
  )
}

export default BookmarkButton
