import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { MessageWithTooltip } from '../MessageWithTooltip'
import { MerchandisingMessageConfig } from './MerchandisingMessageConfig'

export const DeliverySurcharge = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct) return null

  const { activeProduct } = genericProduct

  const { merchandisingMessage, surchargeModalPrompt } = activeProduct

  const hasDeliverSurcharge =
    surchargeModalPrompt?.includes('delivery surcharge')

  if (!hasDeliverSurcharge) return null

  return (
    <MessageWithTooltip
      content={MerchandisingMessageConfig.deliverSurcharge.tooltipContent}
      message={merchandisingMessage}
      type={MerchandisingMessageConfig.deliverSurcharge.type}
    />
  )
}
