import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { PricingTreatment, StoreIcons } from 'baby-design'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'
import css from '../RegItemMinimalCardDetails.styles.scss'

const GiftCardDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  regItem,
}: RegItemMinimalCardDetailsProps) => {
  const { title } = regItem
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.GiftCardDetails
  )
  const storesIconsMap = (regItem.offers || []).map((offer) => ({
    name: offer.storeIconName,
  }))
  const priceRange = regItem.offers?.[0]?.priceDetails?.listPrice?.priceRange

  return (
    <div className={wrapperClasses} data-testid="GiftCardDetails">
      <div className={css.Title}>{title}</div>
      <StoreIcons
        size={isMobile ? 'small' : 'medium'}
        stores={storesIconsMap}
      />
      <PricingTreatment
        decimalPlaces={0}
        priceRange={{
          min: priceRange?.minPrice ? Number(priceRange.minPrice) : 0,
          max: priceRange?.maxPrice ? Number(priceRange.maxPrice) : 0,
        }}
        size={isMobile ? 'md' : 'lg'}
      />
    </div>
  )
}

export default GiftCardDetails
