import React from 'react'
import { IconButton } from 'baby-design'
import { EditPencil } from 'baby-design/icons'
import RegistryPrivacyStatus from '../../RegistryPrivacyStatus/RegistryPrivacyStatus'
import { DESKTOP, MOBILE } from '../../../../constants'
import css from './RegistryTitle.styles.scss'
import { RegistryTitleProps } from './RegistryTitle.types'

const RegistryTitle: React.FC<RegistryTitleProps> = ({
  currentRegistry,
  handleEdit,
  handlePrivacyStatusClick,
}) => {
  const { title } = currentRegistry || {}

  const desktopTitleAndEdit = (
    <div className={css[`RegistryTitle__titleAndEdit__${DESKTOP}`]}>
      <h1>
        {title}
        <IconButton
          aria-label="Edit Registry Details"
          size="sm"
          variant="standard-default"
          onClick={handleEdit}
        >
          <EditPencil />
        </IconButton>
      </h1>
    </div>
  )

  const mobileTitleAndEdit = (
    <div className={css[`RegistryTitle__titleAndEdit__${MOBILE}`]}>
      <h1>{title}</h1>
    </div>
  )

  return (
    <div className={css.RegistryTitle__titleContainer}>
      {title ? (
        <div className={css.RegistryTitle__titleAndEdit}>
          {desktopTitleAndEdit}
          {mobileTitleAndEdit}
        </div>
      ) : (
        <div className={css.RegistryTitle__shimmer} />
      )}
      <RegistryPrivacyStatus
        currentRegistry={currentRegistry}
        viewType={DESKTOP}
        onClick={handlePrivacyStatusClick}
      />
    </div>
  )
}

export default RegistryTitle
