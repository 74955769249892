import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { MessageWithTooltip } from '../MessageWithTooltip'
import { MerchandisingMessageConfig } from './MerchandisingMessageConfig'

export const ExtendedDelivery = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const [currentUser] = useCurrentUser()

  if (!genericProduct?.activeProduct) return null

  const { activeProduct } = genericProduct

  const { extendedDeliveryTimeMessage } = activeProduct

  const hideExtendedDeliveryMessage =
    genericProduct?.activeProduct?.approved &&
    genericProduct?.activeProduct?.allowAvailabilitySubscription &&
    currentUser?.email

  if (hideExtendedDeliveryMessage || !extendedDeliveryTimeMessage) return null

  return (
    <MessageWithTooltip
      content={MerchandisingMessageConfig.extendedDeliveryTime.tooltipContent}
      message={extendedDeliveryTimeMessage}
      type={MerchandisingMessageConfig.extendedDeliveryTime.type}
    />
  )
}
