import React from 'react'
import css from './EmptyState.styles.scss'

import { Button } from 'baby-design'

import { settingsShippingAddressPath } from 'lib/urls'

const title = (showAddressNudge: boolean): string => {
  if (showAddressNudge) {
    return 'Add an address to your registry'
  } else {
    return 'No shipments, yet.'
  }
}

const subtitle = (showAddressNudge: boolean): string => {
  if (showAddressNudge) {
    return 'To track shipments from your friends & family, update your address settings.'
  } else {
    return "Soon you'll be unboxing teeny, tiny, baby onesies! 😍"
  }
}

const AddAddressButton: React.FC = () => (
  <Button
    href={settingsShippingAddressPath}
    className={css.EmptyState__AddAddressButton}
    variant="primary"
    size="md"
  >
    Add address
  </Button>
)

const EmptyState: React.FC<{ hasAddress: boolean }> = ({ hasAddress }) => {
  const showAddressNudge = !hasAddress

  return (
    <div className={css.EmptyState}>
      <p className={css.EmptyState__title}>{title(showAddressNudge)}</p>
      <p className={css.EmptyState__subtitle}>{subtitle(showAddressNudge)}</p>

      {showAddressNudge ? <AddAddressButton /> : null}
    </div>
  )
}

export default EmptyState
