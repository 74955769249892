import { FC } from 'react'

const GiftMessageGlitter: FC = () => (
  <>
    <svg
      fill="none"
      height="18"
      style={{
        position: 'absolute',
        left: '8.884px',
        bottom: '6.382px',
        width: '20px',
        height: '12.867px',
      }}
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M15.8593 1.73704C10.0891 -1.1368 3.44735 1.008 0.608641 6.48778C0.376728 6.92875 0.579812 7.50241 1.03909 7.73739L5.71977 10.0678C6.05273 10.235 6.44251 10.1274 6.62582 9.81338C7.93496 7.53377 10.782 6.67506 13.2635 7.90826C15.6088 9.07354 16.8562 11.7253 16.3735 14.1779C16.2485 14.8038 16.5637 15.4604 17.139 15.7529L21.4665 17.9057C21.8699 18.1043 22.3303 17.9211 22.4743 17.4994C24.5134 11.5613 21.6699 4.63176 15.865 1.75241L15.8593 1.73704Z"
          fill="#56BB6C"
        />
      </g>
    </svg>
    <svg
      fill="none"
      height="15"
      style={{
        position: 'absolute',
        left: '42.699px',
        top: '6px',
        width: '9px',
        height: '9.295px',
      }}
      viewBox="0 0 14 15"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.36057 6.32127C2.3558 6.31253 2.35106 6.30376 2.34634 6.29497C1.4062 4.54111 2.00721 2.34519 3.68875 1.39025C5.37028 0.435312 8.17939 1.09389 9.11953 2.84775C9.12425 2.85655 8.4451 2.85443 8.44974 2.86324C8.45818 2.85836 8.46665 2.8535 8.47515 2.84867C10.1707 1.88578 12.301 2.51523 13.2334 4.25459C14.1658 5.99396 13.5471 8.18457 11.8516 9.14747C11.8457 9.15082 11.8398 9.15416 11.8338 9.15747C11.8312 9.15893 11.8286 9.16038 11.826 9.16183C11.8308 9.17057 11.8356 9.17934 11.8403 9.18813C12.7804 10.942 12.1794 13.1379 10.4979 14.0928C8.81633 15.0478 6.69104 14.4001 5.7509 12.6463C5.74618 12.6375 5.74151 12.6287 5.73687 12.6199C5.72843 12.6247 5.71996 12.6296 5.71146 12.6344C4.01592 13.5973 1.88557 12.9679 0.953196 11.2285C0.0208229 9.48914 0.639495 7.29853 2.33504 6.33563C2.34354 6.3308 2.35205 6.32601 2.36057 6.32127Z"
        fill="#FFD43E"
      />
    </svg>
    <svg
      fill="none"
      height="16"
      style={{
        position: 'absolute',
        right: '17.416px',
        top: '13px',
        width: '12px',
        height: '11.391px',
      }}
      viewBox="0 0 14 16"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4585 0.712394L1.07138 6.91405C0.291175 7.38084 0.212269 8.54268 0.930387 9.00604L10.8342 15.3963C11.5627 15.8663 12.5441 15.2545 12.5804 14.3077L13.0585 1.71243C13.0928 0.804795 12.2179 0.25644 11.4585 0.712394Z"
        fill="#A0DCFF"
      />
    </svg>
    <svg
      fill="none"
      height="16"
      style={{
        position: 'absolute',
        right: '125.984px',
        bottom: '15px',
        width: '13px',
        height: '12.513px',
      }}
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13.3641 12.9286C12.5676 12.951 11.841 12.6318 11.3265 12.1047C11.479 12.4295 11.57 12.7897 11.5836 13.171C11.6375 14.6801 10.4597 15.9459 8.95303 15.9983C7.44632 16.0507 6.18119 14.8698 6.12729 13.3607C6.11803 13.1016 6.14509 12.8496 6.20389 12.6098C5.74002 13.3896 4.89391 13.9207 3.91567 13.9429C2.40842 13.9771 1.15824 12.7901 1.12332 11.2916C1.09308 9.99415 1.98389 8.88859 3.20188 8.59316C1.79665 8.53001 0.656954 7.38899 0.615635 5.95815C0.572369 4.4599 1.7498 3.21123 3.24551 3.16917C4.121 3.14456 4.91148 3.5388 5.42518 4.17047C5.1832 3.77929 5.03654 3.32165 5.01895 2.82905C4.96505 1.31994 6.14278 0.0540786 7.64949 0.00167819C9.1562 -0.0507222 10.4213 1.13018 10.4752 2.63929C10.4852 2.91745 10.4533 3.18735 10.3852 3.44292C10.8461 2.66601 11.6842 2.13727 12.6529 2.11529C14.1488 2.08134 15.3898 3.26856 15.4247 4.76703C15.4565 6.13103 14.478 7.28275 13.1739 7.50407C13.1851 7.50362 13.1962 7.50324 13.2074 7.50293C14.7144 7.46055 15.9712 8.64078 16.0145 10.139C16.0577 11.6373 14.8711 12.8862 13.3641 12.9286Z"
        fill="#FED7FF"
        fillRule="evenodd"
      />
    </svg>
    <svg
      fill="none"
      height="14"
      style={{
        position: 'absolute',
        right: '2.627px',
        bottom: '62.708px',
        width: '10px',
        height: '9.626px',
      }}
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96297 2.2284C5.96752 2.21999 5.97211 2.2116 5.97674 2.20322C6.89991 0.531412 8.96308 -0.0309546 10.585 0.947137C12.2068 1.92523 12.7732 4.0734 11.8501 5.7452C11.8454 5.75358 11.8408 5.76194 11.8361 5.77026C11.8443 5.77513 11.8525 5.78003 11.8607 5.78498C13.4961 6.77122 14.0797 8.91479 13.1641 10.5728C12.2486 12.2308 10.1807 12.7753 8.54526 11.7891C8.53957 11.7856 8.53388 11.7822 8.52821 11.7787C8.52572 11.7772 8.52323 11.7757 8.52075 11.7742C8.51619 11.7826 8.5116 11.791 8.50697 11.7993C7.58381 13.4711 5.52064 14.0335 3.89877 13.0554C2.27689 12.0773 1.71048 9.92916 2.63364 8.25736C2.63827 8.24898 2.64293 8.24062 2.64761 8.2323C2.6394 8.22743 2.63119 8.22253 2.623 8.21758C0.987605 7.23134 0.40405 5.08777 1.31959 3.42978C2.23513 1.7718 4.30306 1.22724 5.93845 2.21348C5.94665 2.21843 5.95482 2.2234 5.96297 2.2284Z"
        fill="#FF6D4E"
      />
    </svg>
  </>
)

export default GiftMessageGlitter
