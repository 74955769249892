import React from 'react'
import dateformat from 'dateformat'
import { useReservationCardContext } from '../../../../context'
import PurchaseDetail from '../PurchaseDetail'

const AdditionalPurchaseDetails: React.FC = () => {
  const { orderNumber, shippingStatus, storeName } = useReservationCardContext()
  const { currentState, estimatedShipDate } = shippingStatus || {}

  const storeDisplay = storeName || 'Not yet provided'
  const orderNumberDisplay = orderNumber || 'Not yet provided'
  const isProcessing = currentState === 'processing'
  const isPreparing = currentState === 'preparing'
  const status =
    estimatedShipDate && (isProcessing || isPreparing)
      ? `Estimated to ship on ${dateformat(estimatedShipDate, 'mediumDate')}`
      : null

  return (
    <>
      <PurchaseDetail label="Store" value={storeDisplay} />
      <PurchaseDetail label="Order #" value={orderNumberDisplay} />
      {status && <PurchaseDetail label="Status" value={status} />}
    </>
  )
}

export default AdditionalPurchaseDetails
