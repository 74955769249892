import { Product } from 'src/types/product'
import { RegItem } from 'src/types/regItem'
import { showRegItemAddedToast } from 'shared/RegItemAddedToast/showRegItemAddedToast'
import { track } from 'lib/analytics'
import { CurrentUser } from '../../../../../global'
import { trackAddToRegistry } from '../../lib/tracking'
import {
  RegistryItemCreateArguments,
  RegistryItemCreateOptions,
} from '../../api/registryItems'

interface RegItemResponse {
  regItem: RegItem
}

interface CreateRegistryItemArguments {
  args: RegistryItemCreateArguments
  options: RegistryItemCreateOptions
}

type CreateRegistryItem = ({
  args,
  options,
}: CreateRegistryItemArguments) => Promise<RegItemResponse>

type AddRegItemClosure = ({
  activeProduct,
  showSuccessMessage,
  createRegistryItem,
  currentUser,
  quantity,
  updateAddingToRegistry,
  updateAddNextRecommendation,
  tracking,
}: AddRegItemClosureArguments) => AddRegItem

type AddRegItem = (args: AddRegItemArguments) => Promise<RegItemResponse | null>

interface AddRegItemClosureArguments {
  activeProduct: Product | undefined
  showSuccessMessage: boolean
  createRegistryItem: CreateRegistryItem
  currentUser: CurrentUser | undefined
  quantity: number
  updateAddingToRegistry: React.Dispatch<React.SetStateAction<boolean>>
  updateAddNextRecommendation: React.Dispatch<React.SetStateAction<any>>
  tracking: any
}

interface AddRegItemArguments {
  // eslint-disable-next-line camelcase
  category_id?: number | undefined
  description?: string | undefined
  title?: string | undefined
  onSuccess?: () => void
}

interface ShouldShowRecommendationsArguments {
  regItem: RegItem
}

type ShouldShowRecommendations = (
  args: ShouldShowRecommendationsArguments
) => boolean | undefined

const shouldShowRecommendations: ShouldShowRecommendations = ({ regItem }) => {
  const recommendedProducts =
    regItem?.addNextRecommendation?.recommendedProducts || []

  return recommendedProducts.length >= 5
}

export const addRegItem: AddRegItemClosure =
  ({
    activeProduct,
    showSuccessMessage,
    createRegistryItem,
    currentUser,
    quantity,
    updateAddingToRegistry,
    updateAddNextRecommendation,
    tracking,
  }) =>
  async (args: AddRegItemArguments | undefined) => {
    if (!currentUser?.registryId || !activeProduct?.id) return null

    // eslint-disable-next-line camelcase
    const { description, category_id, title, onSuccess } = args || {}

    updateAddingToRegistry(true)

    const result = await createRegistryItem({
      args: {
        registryId: currentUser.registryId,
        productId: activeProduct.id,
        quantity,
        description,
        title,
        category_id,
      },
      options: {
        includeRecommendation: currentUser,
      },
    })

    trackAddToRegistry({
      currentUser,
      product: activeProduct,
      regItem: result.regItem,
      quantity,
      tracking,
    })

    showRegItemAddedToast({
      currentUser,
      eventLocation: track.EventLocation.PDP,
      regItem: {
        ...result.regItem,
        // response doesn't include the product image, so we
        // use product image, falling back to the "no image"
        // image from the reg item response
        imgUrl: activeProduct.images[0]?.thumbnailUrl || result.regItem.imgUrl,
      },
      tracker: tracking,
    })

    if (
      result.regItem?.addNextRecommendation &&
      showSuccessMessage &&
      shouldShowRecommendations(result)
    ) {
      updateAddNextRecommendation(result.regItem.addNextRecommendation)
    }

    onSuccess?.()
    updateAddingToRegistry(false)

    return result
  }
