import fetch from 'lib/fetch'
import { apiV3CreateGiftReturnsPath } from 'lib/urls'

export const createGiftReturnRequest = ({
  reservationToken,
  isProductUsed,
  isInOriginalPackaging,
  refundTo,
  reasonsForReturn,
}) =>
  fetch(apiV3CreateGiftReturnsPath, {
    method: 'POST',
    body: JSON.stringify({
      reservationToken,
      isProductUsed,
      isInOriginalPackaging,
      refundTo,
      reasonsForReturn,
    }),
  })
