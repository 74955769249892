import { useEffect } from 'react'
import {
  SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
  SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT,
} from 'shared/constants'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { track } from 'lib/analytics'
import RegistryOrganizationFeedbackModal from './RegistryOrganizationFeedbackModal'
import { Responses } from '../../global'

const MIN_REG_ITEM_COUNT = 10

const REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY =
  'RegistryOrganizationFeedbackModalNotificationDismissed'

export const useDisplayRegistryOrganizationFeedbackModal = (
  registry: Responses.Registry,
  regItemCount: number,
  eventLocation: string = track.EventLocation.LIST_ADOPTION_MODAL_MINUS_REGISTRY
) => {
  const { flagValue: isRegistryFeedbackModalEnabled } = useFeatureFlag(
    '24c_ff_regex_registry_feedback_modal_web',
    false
  )

  useEffect(() => {
    if (isRegistryFeedbackModalEnabled && regItemCount >= MIN_REG_ITEM_COUNT) {
      displayRegistryOrganizationFeedbackModal(registry, eventLocation)
    }
  }, [registry, regItemCount, isRegistryFeedbackModalEnabled])
}

export const displayRegistryOrganizationFeedbackModal = (
  registry: Responses.Registry,
  eventLocation: string
) => {
  if (!window) return

  const isDismissed = localStorage.getItem(
    REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY
  )

  if (
    isDismissed ||
    registry?.type === 'holiday' ||
    registry?.type === 'birthday'
  )
    return

  // Delay opening of modal for 1 second
  setTimeout(() => {
    PubSub.publish(SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT, {
      renderContent: (cm: () => void) => (
        <RegistryOrganizationFeedbackModal
          closeModal={cm}
          eventLocation={eventLocation}
          registry={registry}
        />
      ),
      onModalOpen: () =>
        localStorage.setItem(REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY, 'true'),
    })
    PubSub.publish(SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT)
  }, 1_000)
}
