import { useQuery, useMutation } from '@tanstack/react-query'
import { RegItem } from 'src/types/regItem'
import { CurrentUser } from '../../../../global'
import { addToBabylist, getRegItem } from '../../../../store/api'

interface RegistryItemArguments {
  registryId: string
  registryItemId: string
}

export interface RegistryItemCreateOptions {
  includeRecommendation?: CurrentUser
}
export interface RegistryItemCreateArguments {
  registryId: number
  productId: number
  description?: string | undefined
  quantity: number
  title?: string | undefined
  category_id?: number | undefined
  source?: string
}

const cacheKey = ({ registryId, registryItemId }: RegistryItemArguments) => [
  'regItem',
  registryItemId,
  registryId,
]

const fetchShow = async ({
  registryId,
  registryItemId,
}: RegistryItemArguments): Promise<RegItem> =>
  await getRegItem(registryItemId, registryId)

const useShow = ({ registryId, registryItemId }: RegistryItemArguments) =>
  useQuery({
    queryKey: cacheKey({ registryId, registryItemId }),
    queryFn: () => fetchShow({ registryId, registryItemId }),
  })

const useCreate = () =>
  useMutation({
    mutationFn: ({
      args,
      options = {},
    }: {
      args: RegistryItemCreateArguments
      options?: RegistryItemCreateOptions
    }) => {
      const { productId, ...otherArgs } = args

      return addToBabylist(
        {
          product_id: productId,
          ...otherArgs,
        },
        {
          ...options,
        }
      )
    },
  })

export const RegistryItemsAPI = {
  useShow,
  useCreate,
}
