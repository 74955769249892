import { AddToBabylistButton } from 'src/routes/(shop)/components/AddToBabylistButton/AddToBabylistButton'
import { ContentContainer } from 'src/routes/(shop)/components/ContentContainer'
import {
  AdminLink,
  BuyBoxContainer,
  MerchandisingMessageContainer,
  WithoutTooltip,
  FreeShippingAndReturns,
  DeliverySurcharge,
  ExtendedDelivery,
  VirtualBundle,
  PDPHeadline,
  ProductCallouts,
} from '../../BuyBox'
import { Column, StickyColumn } from '../../Layout/Column'
import { Row } from '../../Layout/Row'
import { ProductDetailsSection } from '../../ProductDetailsSection/ProductDetailsSection'
import { ProductImages } from '../../ProductImages/ProductImages'
import { GiftCardPrice } from './GiftCardPrice'
import { AddGiftCardToCart } from './AddGiftCardToCart'
import { Content } from '../../Content'
import { BackToTopButton } from '../../BackToTop/Button'
import { WaypointWrapper } from '../../BackToTop/Waypoint/Wrapper'
import { VariantSelector } from '../../BuyBox/VariantSelector'
import { ActionsContainer } from '../ActionsContainer'

export const GiftCard = () => (
  <ContentContainer>
    <BackToTopButton />
    <Row>
      <Column width="58%">
        <StickyColumn>
          <ProductImages />
        </StickyColumn>
      </Column>
      <Column width="42%">
        <BuyBoxContainer>
          <PDPHeadline />
          <GiftCardPrice />
          <MerchandisingMessageContainer>
            <WithoutTooltip />
            <FreeShippingAndReturns />
            <DeliverySurcharge />
            <ExtendedDelivery />
            <VirtualBundle />
          </MerchandisingMessageContainer>
          <ProductCallouts />
          <VariantSelector />
          <WaypointWrapper />
          <ActionsContainer>
            <AddToBabylistButton quantity={1} />
            <AddGiftCardToCart />
          </ActionsContainer>
          <Content />
          <AdminLink />
        </BuyBoxContainer>
      </Column>
    </Row>
    <ProductDetailsSection />
  </ContentContainer>
)
