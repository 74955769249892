import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import css from './MerchandisingMessage.styles.scss'

export const WithoutTooltip = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct) return null

  const {
    approved,
    merchandisingMessage,
    surchargeModalPrompt,
    extendedDeliveryTimeMessage,
    productType,
  } = genericProduct.activeProduct

  const isVirtualBundle = productType === 'virtual_bundle'

  const hasFreeShippingAndReturns = merchandisingMessage.includes(
    'Free shipping & returns'
  )

  const hasDeliverSurcharge =
    surchargeModalPrompt?.includes('delivery surcharge')

  const displayWithTooltip =
    surchargeModalPrompt ||
    extendedDeliveryTimeMessage ||
    hasFreeShippingAndReturns ||
    hasDeliverSurcharge ||
    (isVirtualBundle && approved)

  if (displayWithTooltip) return null

  return (
    <div className={css.MerchandisingMessage__container}>
      {merchandisingMessage}
    </div>
  )
}
