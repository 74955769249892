import { InfoButton } from 'components/buttons'
import { storeFsaHsaCategoryPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import css from './ChecklistFsaHsaBanner.module.scss'

export const ChecklistFsaHsaBanner = () => {
  const { trackEvent } = useTracking()

  const handleFsaHsaBannerClick = () => {
    trackEvent({
      event: track.storeCategoryClicked,
      isLeafCategory: true,
      eventLocation: track.EventLocation.CHECKLIST_CATEGORY,
      storeCategoryName: 'FSA & HSA Eligible',
      storeCategorySlug: 'fsa-hsa-eligible',
    })
  }

  return (
    <a
      href={storeFsaHsaCategoryPath}
      rel="noopener"
      target="_blank"
      onClick={handleFsaHsaBannerClick}
    >
      <div className={css.Banner}>
        <div className={css.Banner__text}>
          <div className={css['Banner__text--title']}>
            FSA &amp; HSA Eligible Items
          </div>
          Did you know you can use your FSA &amp; HSA funds to buy baby items?
        </div>
        <InfoButton
          empty
          pill
          className={css['Banner__action-button']}
          href={storeFsaHsaCategoryPath}
          size="small"
          target="_blank"
        >
          See eligible products
        </InfoButton>
      </div>
    </a>
  )
}
