import { FC } from 'react'

import { format, parseISO } from 'date-fns'

// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge } from 'baby-design'
import { BadgeFill } from 'baby-design/components/Badge/Badge.types'

import { ShipmentStatusSummary, CurrentState } from 'src/types/reservation'

const badgeColors: Record<Exclude<CurrentState, 'unknown'>, BadgeFill> = {
  processing: 'gray',
  preparing: 'gray',
  shipped: 'blue',
  delivered: 'green',
  cancelled: 'discount-ruby',
}

const formatRelevantDate = (dateString: string) =>
  format(parseISO(dateString), 'MMMM d')

const formatCurrentState = (currentState: string) =>
  currentState.charAt(0).toUpperCase() + currentState.slice(1)

const badgeLabel = ({
  currentStateDisplay,
  relevantDate,
}: ShipmentStatusSummary) =>
  `${formatCurrentState(currentStateDisplay)} ${formatRelevantDate(relevantDate)}`

const ShipmentStatusBadge: FC<{
  shipmentStatusSummary: ShipmentStatusSummary | null | undefined
}> = ({ shipmentStatusSummary }) => {
  if (!shipmentStatusSummary) {
    return null
  }
  const { currentState } = shipmentStatusSummary

  if (currentState === 'unknown') {
    return null
  }

  return (
    <Badge
      fill={badgeColors[currentState]}
      label={badgeLabel(shipmentStatusSummary)}
      size="medium"
      variant="general"
    />
  )
}

export default ShipmentStatusBadge
