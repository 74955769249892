import {
  apiV3ShipmentPath,
  apiV3ShipmentsPath,
  apiV3ShipNowPath,
} from 'lib/urls'

import { useMutation, useQuery } from '@tanstack/react-query'
import { Shipment } from 'src/types/shipments'

const CACHE_KEY = 'shipments'

interface ShipmentsAPIOptions {
  fakeData?: boolean
  scenario?: string | null
}

interface ShipmentsResponse {
  shipments: Shipment[]
}

const buildPath = (pathPrefix: string, options: ShipmentsAPIOptions) => {
  let path = pathPrefix

  if (options.fakeData || options.scenario) {
    path += '?fake_data=true'

    if (options.scenario) {
      path += `&scenario=${options.scenario}`
    }
  }

  return path
}

const fetchIndex = (options: ShipmentsAPIOptions): Promise<ShipmentsResponse> =>
  fetch(buildPath(apiV3ShipmentsPath, options)).then(
    (response) => response.json() as Promise<ShipmentsResponse>
  )

/**
 * Fetch the shipments data
 */

export const useIndex = (options: ShipmentsAPIOptions) =>
  useQuery({
    queryKey: [CACHE_KEY, options],
    queryFn: () => fetchIndex(options),
    enabled: true,
  })

/**
 * Fetch an individual shipment
 */
const fetchShow = (
  shipmentId: string,
  options: ShipmentsAPIOptions
): Promise<Shipment | undefined> =>
  fetch(buildPath(apiV3ShipmentPath(shipmentId), options)).then((response) => {
    if (response.ok) {
      return response.json()
    }
    return undefined
  })

export const useShow = (shipmentId: string, options: ShipmentsAPIOptions) =>
  useQuery({
    queryKey: [CACHE_KEY, shipmentId, options],
    queryFn: () => fetchShow(shipmentId, options),
    enabled: true,
  })

/**
 * Ship now
 */
const postShipNow = (shipment: Shipment): Promise<any> =>
  fetch(apiV3ShipNowPath(shipment.id), { method: 'POST' }).then((response) => {
    if (!response.ok) {
      throw new Error('Could not ship now')
    }
  })

export const useShipNow = () =>
  useMutation({
    mutationFn: (shipment: Shipment) => postShipNow(shipment),
  })

export const ShipmentsAPI = {
  useIndex,
  useShipNow,
  useShow,
}
