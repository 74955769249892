import { Modal, ModalBody } from 'components/modal'
import { useState } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { AddToCartButton } from 'src/routes/(shop)/components/AddToCartButton'
import GiftCardForm from '../../../../../../../../../../store/components/forms/gift-card-form'

export const AddGiftCardToCart = () => {
  const [showAddGiftCardModal, updateShowAddGiftCardModal] = useState(false)
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { activeProduct } = genericProduct

  if (!activeProduct?.approved) return null

  return (
    <>
      <AddToCartButton
        loading={showAddGiftCardModal}
        product={activeProduct}
        onClick={() => updateShowAddGiftCardModal(true)}
        onSuccess={(_args) => updateShowAddGiftCardModal(false)}
      >
        {({ handleAddToCart, isLoading }) => (
          <Modal
            show={showAddGiftCardModal}
            title="Add Gift Card"
            onHide={() => updateShowAddGiftCardModal(false)}
          >
            <ModalBody>
              <GiftCardForm
                giftCardOptions={activeProduct.giftCardOptions}
                isLoading={isLoading}
                regItemId={null}
                submitText={null}
                onCancel={() => updateShowAddGiftCardModal(false)}
                onSubmit={({ amount, quantity, productId }) =>
                  handleAddToCart({
                    price: Number(amount),
                    quantity,
                    productId,
                  })
                }
              />
            </ModalBody>
          </Modal>
        )}
      </AddToCartButton>
    </>
  )
}
