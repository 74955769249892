import React from 'react'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image } from 'baby-design'
import { Preference as PreferenceType } from 'src/types/registry'
import {
  AFFINITY_ICONS,
  LONG_TEXT_CHARACTER_COUNT,
} from './Preference.constants'
import styles from './Preference.styles.scss'

interface Props {
  preference: PreferenceType
}

const Preference: React.FC<Props> = ({ preference }) => {
  const isLongText = (preference.name?.length ?? 0) > LONG_TEXT_CHARACTER_COUNT
  const preferenceClasses = classNames(
    styles.Preference,
    styles[preference.affinity]
  )
  const contentClasses = classNames(
    styles.content,
    isLongText && styles.content__small
  )

  return (
    <div
      className={preferenceClasses}
      data-testid="Preference"
      key={`${preference.affinity}-${preference.name}`}
    >
      <div className={styles.heading}>
        <Image role="presentation" src={AFFINITY_ICONS[preference.affinity]} />
        {preference.affinity}
      </div>
      <div className={contentClasses}>{preference.name}</div>
    </div>
  )
}

export default Preference
