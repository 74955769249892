/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { Filter, IconButton } from 'baby-design'
import { LeftArrow, MostWantedFilled, RightArrow } from 'baby-design/icons'
import { RegistryCategoryFilterOption } from 'src/types/registrySortFilterOptions'
import { useAppliedSortsAndFiltersContext } from '../../contexts'
import { RegistryCategoryFiltersProps } from './RegistryCategoryFilters.types'
import css from './RegistryCategoryFilters.styles.scss'
import RegistryCategoryFiltersSkeleton from './RegistryCategoryFilters.skeleton'
import {
  getCategoryCounts,
  handleScrollClick,
  isLeftArrowVisible as getIsLeftArrowVisible,
  isRightArrowVisible as getIsRightArrowVisible,
} from './RegistryCategoryFilters.utils'
import { MOST_WANTED_TITLE } from '../RegistryCategories/RegistryCategories.utils'
import { defaultAppliedSortsAndFilters } from '../RegistryGiftGiverPage/RegistryGiftGiverPage.utils'

const RegistryCategoryFilters: React.FC<RegistryCategoryFiltersProps> = ({
  regItems,
  reservations,
  sortFilterOptions,
}: RegistryCategoryFiltersProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false)
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(false)
  const { appliedSortsAndFilters, onSortFilterChange } =
    useAppliedSortsAndFiltersContext()
  const categoryCounts = getCategoryCounts(
    regItems,
    reservations,
    appliedSortsAndFilters
  )

  const handleArrowClick = (direction?: 'left' | 'right') => {
    let newScrollLeft: number = wrapperRef.current?.scrollLeft || 0
    if (direction) {
      newScrollLeft = handleScrollClick({
        direction,
        wrapperRef,
      })
    }
    setIsLeftArrowVisible(getIsLeftArrowVisible(wrapperRef, newScrollLeft))
    setIsRightArrowVisible(getIsRightArrowVisible(wrapperRef, newScrollLeft))
  }

  useEffect(
    () => handleArrowClick(),
    [wrapperRef, windowSize.width, sortFilterOptions]
  )

  if (!sortFilterOptions) return <RegistryCategoryFiltersSkeleton />

  const selectedCategory = appliedSortsAndFilters.filters?.category
  const filterSize = windowSize.width < 768 ? 'lg' : 'xl'

  return (
    <div
      className={css.RegistryCategoryFilters}
      data-testid="RegistryCategoryFilters"
    >
      {isLeftArrowVisible && (
        <>
          <IconButton
            aria-hidden="true"
            className={css.RegistryCategoryFilters__LeftArrow}
            data-testid="LeftArrow"
            size="md"
            variant="fill-secondary"
            onClick={() => handleArrowClick('left')}
          >
            <LeftArrow />
          </IconButton>
          <div className={css.RegistryCategoryFilters__LeftArrow__underlay} />
        </>
      )}
      <div className={css.RegistryCategoryFilters__Wrapper} ref={wrapperRef}>
        {sortFilterOptions &&
          sortFilterOptions?.filters?.category?.options.map(
            (categoryOption: RegistryCategoryFilterOption) => {
              const categoryValue =
                categoryOption.value !== null
                  ? categoryOption.value
                  : categoryOption.name
              return (
                <Filter
                  LeftIcon={
                    categoryOption.name === MOST_WANTED_TITLE ? (
                      <div
                        className={
                          css.RegistryCategoryFilters__MostWantedIconWrapper
                        }
                        data-testid="MostWantedIcon"
                      >
                        <MostWantedFilled
                          className={
                            css.RegistryCategoryFilters__MostWantedIcon
                          }
                        />
                      </div>
                    ) : undefined
                  }
                  className={css.RegistryCategoryFilters__CategoryFilter}
                  isPressed={selectedCategory === categoryValue}
                  key={`${categoryOption.value}-${categoryOption.name}`}
                  overrideIconColor={false}
                  size={filterSize}
                  type="secondary"
                  variant="fill"
                  onClick={() =>
                    onSortFilterChange({
                      filters: {
                        category:
                          selectedCategory !== categoryValue
                            ? categoryValue
                            : defaultAppliedSortsAndFilters.filters?.category,
                      },
                    })
                  }
                >
                  {`${categoryOption.name} (${categoryCounts[categoryValue] || 0})`}
                </Filter>
              )
            }
          )}
      </div>
      {isRightArrowVisible && (
        <>
          <IconButton
            aria-hidden="true"
            className={css.RegistryCategoryFilters__RightArrow}
            data-testid="RightArrow"
            size="md"
            variant="fill-secondary"
            onClick={() => handleArrowClick('right')}
          >
            <RightArrow />
          </IconButton>
          <div className={css.RegistryCategoryFilters__RightArrow__underlay} />
        </>
      )}
    </div>
  )
}

export default RegistryCategoryFilters
