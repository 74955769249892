/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import classNames from 'classnames'
import { StoreIcon, Button } from 'baby-design'
import { Check, Exclamation2 } from 'baby-design/icons'
import { FLASH_MESSAGE } from 'shared/constants'
import { parseErrorMessage } from 'lib/api-utils'
import { track } from 'lib/analytics/track'
import { addProductAvailabilitySubscription } from 'src/api/queries'
import { Offer } from 'src/types/offer'

import css from './OutOfStockOption.styles.scss'
import { showNotifyMeSuccessToast } from '../../../../../NotifyMeSuccessToast/showNotifyMeSuccessToast'

interface OutOfStockOptionProps {
  isBabylistProductDiscontinued: boolean
  option: Offer
  productId: number
}

export const OutOfStockOption = ({
  isBabylistProductDiscontinued,
  option,
  productId,
}: OutOfStockOptionProps) => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleNotifyMeClick = () => {
    setIsLoading(true)
    addProductAvailabilitySubscription(productId)
      .then(() => {
        setIsSubscribed(true)
        showNotifyMeSuccessToast()
        track.stockNotificationRequestClicked({
          eventLocation:
            track.EventLocation.REGISTRY_MINUS_BUYING_OPTION_REG_ITEM_CARD,
          eventUrl: window.location.href,
          productIds: [productId],
        })
      })
      .catch((resp) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className={css.OutOfStockOption}>
      <div className={css.OutOfStockOption__info}>
        <StoreIcon
          className={css.OutOfStockOption__icon}
          store={{ name: option.storeIconName }}
        />
        <div
          className={classNames(css.OutOfStockOption__nameAndStatus, {
            [css['OutOfStockOption__nameAndStatus--discontinued']]:
              isBabylistProductDiscontinued,
          })}
        >
          <div className={css.OutOfStockOption__name}>
            {option.storeDisplayName}
          </div>
          {isBabylistProductDiscontinued ? (
            <div className={css.OutOfStockOption__discontinuedStatus}>
              <Exclamation2 className={css.OutOfStockOption__statusIcon} />
              <span className={css.OutOfStockOption__statusText}>
                Item out of stock
              </span>
            </div>
          ) : (
            <div className={css.OutOfStockOption__statusText}>
              In stock soon
            </div>
          )}
        </div>
      </div>
      {!isBabylistProductDiscontinued &&
        (isSubscribed ? (
          <div className={css.OutOfStockOption__successMessage}>
            <Check className={css.OutOfStockOption__successIcon} />
            <span className={css.OutOfStockOption__successText}>
              We&rsquo;ll notify you
            </span>
          </div>
        ) : (
          <Button
            className={css.OutOfStockOption__notifyMeButton}
            loading={isLoading}
            size="sm"
            variant="de-emphasized"
            onClick={handleNotifyMeClick}
          >
            Notify me
          </Button>
        ))}
    </div>
  )
}
