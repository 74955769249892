import React from 'react'
import { Button } from 'baby-design'
import { PresentIcon } from 'components/icons'
import { settingsShippingAddressPath } from 'lib/urls'
import { EmptyStateProps } from './EmptyState.types'
import css from './EmptyState.styles.scss'

const EmptyState: React.FC<EmptyStateProps> = ({
  hasAddress,
  query,
  searching,
}) => (
  <div className={css.EmptyState}>
    {hasAddress ? (
      <div className={css.EmptyState__withAddress}>
        <PresentIcon />
        {searching ? (
          <div className={css.EmptyState__withAddress__noMatches}>
            No gifts matching "{query}"
          </div>
        ) : (
          <div className={css.EmptyState__withAddress__noGifts}>
            <span className={css.EmptyState__withAddress__noGifts__title}>
              No gifts yet!
            </span>
            <p className={css.EmptyState__withAddress__noGifts__text}>
              Once your friends and family start buying gifts, they'll appear
              here!
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className={css.EmptyState__noAddress}>
        <div className={css.EmptyState__noAddress__textBox}>
          <span className={css.EmptyState__noAddress__textBox__title}>
            Add an address to your registry
          </span>
          <span className={css.EmptyState__noAddress__textBox__text}>
            To track shipments from your friends & family, update your address
            settings.
          </span>
        </div>
        <Button
          className={css.EmptyState__noAddress__button}
          data-testid="empty-state-add-address-button"
          href={settingsShippingAddressPath}
          size="md"
          variant="primary"
        >
          Add address
        </Button>
      </div>
    )}
  </div>
)

export default EmptyState
