import fetch from 'lib/fetch'
import { useMutation, useQuery } from '@tanstack/react-query'

export const api = {}

api.coregistration = {
  route: '/api/v3/coregistration/registration',
  path: function path() {
    return this.route
  },
  register: function postRegistration(payload = null) {
    return fetch(this.path(), {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },
  useRegister: function useRegister(options) {
    return useMutation({ mutationFn: this.register.bind(this), ...options })
  },
}

api.productRecommendations = {
  route: '/api/v3/products/:id/recommendations',
  path: function path(productId) {
    return this.route.replace(':id', productId)
  },
  fetchProductRecommendations: function getProductRecommendations(productId) {
    return fetch(this.path(productId))
  },
  useProductRecommendationsQueryKey: function useProductRecommendationsQueryKey(
    productKey
  ) {
    const [gpId, productId] = productKey
    return ['product-recommendations', gpId, productId]
  },
  useProductRecommendations: function useProductRecommendations(
    productsKey,
    options = {
      refetchOnWindowFocus: false,
    }
  ) {
    const [key, gpId, productId] = this.useProductRecommendationsQueryKey.call(
      this,
      productsKey
    )

    return useQuery({
      queryKey: [key, gpId, productId],
      queryFn: () => this.fetchProductRecommendations(productId),
      ...options,
    })
  },
}
