import React from 'react'

interface FacebookProps {
  className?: string
}

const Facebook: React.FC<FacebookProps> = ({ className }: FacebookProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facebook Icon</title>
    <path
      d="M7 1.25C3.82436 1.25 1.25 3.82436 1.25 7V17C1.25 20.1756 3.82436 22.75 7 22.75H10.1528C11.1216 22.75 11.9061 21.9628 11.9028 20.9939L11.8809 14.6945H9.2168V12.6389H11.8835V10.9467C11.8835 9.8042 12.3373 8.7085 13.1452 7.90064C13.953 7.09277 15.0487 6.63892 16.1912 6.63892H17.4946V8.69447H16.1912C15.8955 8.69447 15.6026 8.75273 15.3294 8.86591C15.0561 8.9791 14.8078 9.14499 14.5987 9.35413C14.3895 9.56327 14.2236 9.81155 14.1105 10.0848C13.9973 10.3581 13.939 10.6509 13.939 10.9467V12.6389H16.7383C16.8857 12.6389 17.0011 12.7658 16.9872 12.9126L16.8394 14.4681C16.8272 14.5964 16.7194 14.6945 16.5905 14.6945H13.9356L13.9642 21.0079C13.9686 21.9713 14.7508 22.75 15.7142 22.75H17C20.1756 22.75 22.75 20.1756 22.75 17V7C22.75 3.82436 20.1756 1.25 17 1.25H7Z"
      fill="#4A2143"
    />
  </svg>
)

export default Facebook
