import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'baby-design'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { Form } from 'components/forms'
import RegistryInfoFieldset from 'registry/forms/registry-info-fieldset'
import { UPDATE_NAVBAR_USER_ICON } from 'registry/constants'
import { errorMessage, successMessage } from 'lib/flash-message'
import { apiV3RegistryPath, registryPath } from 'lib/urls'
import { parseErrors } from 'lib/api-utils'
import fetch from 'lib/fetch'
import PhotoUploader from './photo-uploader'
import {
  PersonalizeRegistryFormProps,
  PersonalizeRegistryFormFields,
  Registry,
} from './PersonalizeRegistryForm.types'

const PersonalizeRegistryForm: FC<PersonalizeRegistryFormProps> = ({
  registry,
  onSuccess,
}) => {
  const [currentUser, setCurrentUser] = useCurrentUser()
  const [submitting, setSubmitting] = useState(false)
  const [urlSlugServerError, setUrlSlugServerError] = useState<string>()
  const [photoUrl, setPhotoUrl] = useState(registry?.photoUrl)

  const initialValues = {
    registry: {
      description: registry?.description || '',
      title: registry?.title || '',
      urlSlug: registry?.urlSlug || '',
    },
  }

  const { register, handleSubmit, errors } =
    useForm<PersonalizeRegistryFormFields>({ defaultValues: initialValues })

  const _handleSubmit = (formData: PersonalizeRegistryFormFields) => {
    if (!currentUser) return

    setSubmitting(true)
    setUrlSlugServerError(undefined)

    const data = {
      registry: {
        ...formData.registry,
        id: registry?.id,
        urlSlug: formData.registry?.urlSlug?.toLowerCase(),
        photoUrl,
      },
    }

    fetch(apiV3RegistryPath(registry?.id), {
      body: JSON.stringify(data),
      method: 'PUT',
    })
      .then((resp: { registry: Registry }) => {
        PubSub.publish(UPDATE_NAVBAR_USER_ICON, {
          newUrl: resp.registry?.photoUrl,
        })
        const oldUrl = registry?.urlSlug
        if (oldUrl != formData.registry?.urlSlug) {
          window.location.href = registryPath(formData.registry?.urlSlug)
          return
        }
        successMessage(
          "Saved! Click 'Preview' to see how your changes look to your guests."
        )

        setCurrentUser({
          ...currentUser,
          currentRegistry: {
            ...currentUser.currentRegistry,
            title: formData.registry?.title,
            photoUrl: photoUrl || undefined,
          },
        })

        onSuccess()
        setSubmitting(false)
      })
      .catch((resp) => {
        setSubmitting(false)
        const errors = parseErrors(resp)
        console.error(errors)
        if (errors.registry?.urlSlug?.length > 0) {
          // Registry Url has several potential errors from server validation
          // We'll display one at a time if there are multiple
          const validationErrorMessage = `Registry Url ${errors.registry?.urlSlug[0]}.`
          errorMessage(validationErrorMessage)

          // Should be caught by frontend validation, but just in case
          setUrlSlugServerError(validationErrorMessage)
          return
        }
        errorMessage(
          'There was an error saving your registry. If this continues, please contact support.'
        )
      })
  }

  const helpBlock = (
    <div className="h6 mvn">
      Upload a photo that shares your excitement with your shower guests.
    </div>
  )

  const descriptionProps = {
    name: 'registry.description',
    inputRef: register,
    error: errors.registry?.description?.message || null,
    touched: errors.registry?.description || false,
  }

  const generateTitleErrorMessage = () => {
    if (errors.registry?.title?.message) {
      return errors.registry?.title.message
    }
    if (errors.registry?.title?.type === 'maxLength') {
      return 'Registry Title is too long. Please choose a shorter Babylist title.'
    }
    return null
  }
  const titleProps = {
    name: 'registry.title',
    inputRef: register({
      required: 'Registry Title is required',
      maxLength: 55,
    }),
    error: generateTitleErrorMessage() || null,
    touched: errors.registry?.title || false,
  }

  // Validation rules and message copied from the registry model
  const generateUrlSlugErrorMessage = () => {
    if (urlSlugServerError) return urlSlugServerError
    if (!errors.registry?.urlSlug) return null

    if (errors.registry?.urlSlug?.message) {
      return errors.registry?.urlSlug?.message
    }

    switch (errors.registry?.urlSlug?.type) {
      case 'minLength':
        return 'Registry Url is too short. It should have 4 or more characters.'
      case 'maxLength':
        return 'Registry Url is too long. It should have less than 40 characters.'
      case 'pattern':
        return `Registry Url must not contain special characters. Allowed Characters: a-z, 0-9, '-', '_'`
      default:
        return `Registry Url is invalid: ${errors.registry?.urlSlug?.type}`
    }
  }
  const urlSlugProps = {
    name: 'registry.urlSlug',
    inputRef: register({
      required: 'Registry Url is required',
      minLength: 4,
      maxLength: 40,
      pattern: /^[A-Za-z0-9\-_]+$/,
    }),
    error: generateUrlSlugErrorMessage() || null,
    touched: errors.registry?.urlSlug || urlSlugServerError || false,
  }

  return (
    <Form onSubmit={handleSubmit(_handleSubmit)}>
      <PhotoUploader
        className="pbl"
        helpText={helpBlock}
        label="Registry Photo"
        value={photoUrl}
        onChange={(url: string) => setPhotoUrl(url)}
      />

      <RegistryInfoFieldset
        className=""
        description={descriptionProps}
        title={titleProps}
        urlSlug={urlSlugProps}
      />

      <Button
        block
        loading={submitting}
        size="md"
        type="submit"
        variant="primary"
      >
        Save
      </Button>
    </Form>
  )
}

export default PersonalizeRegistryForm
