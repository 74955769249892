import { useModal } from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/analytics'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import { useOpenCreateEditReservationModal } from '../../hooks/useOpenCreateEditReservationModal'
import { REG_ITEM_PURCHASED_CHECKBOX_TEXT } from './RegItemMarkPurchasedCheckbox'

export const useGetHandlePurchasedCheckboxClicked = (
  regItem: RegItem,
  registry: Registry
) => {
  const [currentUser] = useCurrentUser()
  const tracker = useTracking()
  const modal = useModal()
  const openCreateEditReservationModal = useOpenCreateEditReservationModal({
    regItem,
    registry,
  })

  return () => {
    modal.hide()
    openCreateEditReservationModal()

    tracker.trackEvent({
      event: track.purchaseConfirmationClicked,
      eventCta: REG_ITEM_PURCHASED_CHECKBOX_TEXT,
      eventType: track.EventType.CLICK,
      ...trackingRegistryProperties({ currentUser, registry }),
      isMustHave: regItem.isFavorite,
      regItemIds: [regItem.id],
    })
  }
}
