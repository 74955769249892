import { useEffect, useState, Component, ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { combineReducers } from 'redux'
import { useWindowSize } from 'usehooks-ts'
import PropTypes from 'prop-types'

import registryReducer from 'registry/reducers'
import {
  createStoreWithMiddleware,
  initReduxDevTools,
} from 'registry/components/registry-guest-app'
import InProgressReservationContext from 'registry/contexts/inProgressReservation'
import useInProgressReservation from 'registry/hooks/useInProgressReservation'
import {
  AddToCartModal,
  ContactInfoModal,
  CrowdfundReservationModal,
  FundThisGiftModal,
  GiftMessageModal,
  GiftTrackerModal,
  RecoverReservationModal,
  RedirectModal,
  RegistryViewPurchaseInfoModal,
  ReservePurchasedGiftModal,
  ThankYouAddressModal,
  UnreserveModal,
} from 'registry/modals'
import { setIsMobile, setRegistry, setUser } from 'registry/actions'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { Registry } from 'src/types/registry'
import { BREAKPOINT_TABLET } from 'src/constants'

interface RegistryGiftGiverPageLegacyAdapterProps {
  children: React.ReactNode
  registry: Registry | undefined
}

let REGISTRY_GIFT_GIVER_PAGE_LEGACY_ADAPTER_REDUX_STORE: any
export const getRegistryGiftGiverPageLegacyAdapterStore = () => {
  if (!REGISTRY_GIFT_GIVER_PAGE_LEGACY_ADAPTER_REDUX_STORE) {
    REGISTRY_GIFT_GIVER_PAGE_LEGACY_ADAPTER_REDUX_STORE =
      createStoreWithMiddleware(
        combineReducers(registryReducer as any),
        initReduxDevTools()
      )
  }

  return REGISTRY_GIFT_GIVER_PAGE_LEGACY_ADAPTER_REDUX_STORE
}

export const JestReduxStoreCompatibility: React.FC = ({ children }) => {
  const reduxStore = getRegistryGiftGiverPageLegacyAdapterStore()

  return <ReduxProvider store={reduxStore}>{children}</ReduxProvider>
}

// Some of the sub components and modals in the legacy codebase use the legacy react context API in which
// context is accessed as the second parameter passed to the render function: MyComponent = (props, context) => {...}
// This additional wrapper provides the registry object to those components as well by defining the getChildContext method.
class RegistryLegacyContextProvider extends Component<{
  registry: Registry | undefined
  children: ReactNode
}> {
  // eslint-disable-next-line react/static-property-placement
  static childContextTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    registry: PropTypes.object,
  }

  getChildContext(): {
    registry: Registry | undefined
  } {
    const { registry } = this.props
    return { registry }
  }

  render() {
    const { children } = this.props
    return children as ReactNode
  }
}

// TODO: Remove this one we have redesigned all of the gift giver modals.
//
// This only exists as an adapter to allow the new page to load the old modals.
// We will not be using redux or the modal manager moving forward but need to support
// the old modals for this initial phase of the GGX revamp.
export const RegistryGiftGiverPageLegacyAdapter: React.FC<
  RegistryGiftGiverPageLegacyAdapterProps
> = ({ children, registry }) => {
  const { inProgressReservation } = useInProgressReservation()
  const [reservation, setReservation] = useState(inProgressReservation)
  const reduxStore = getRegistryGiftGiverPageLegacyAdapterStore()
  const [currentUser] = useCurrentUser()
  const windowSize = useWindowSize()

  useEffect(() => {
    reduxStore.dispatch(setUser(currentUser))
  }, [reduxStore, currentUser])

  useEffect(() => {
    reduxStore.dispatch(setRegistry(registry))
  }, [reduxStore, registry])

  useEffect(() => {
    reduxStore.dispatch(setIsMobile(windowSize.width < BREAKPOINT_TABLET))
  }, [reduxStore, windowSize])

  useEffect(() => {
    setReservation(inProgressReservation)
  }, [inProgressReservation])

  return (
    <ReduxProvider store={reduxStore}>
      <InProgressReservationContext.Provider
        value={{ reservation, setInProgressReservation: setReservation }}
      >
        <RegistryLegacyContextProvider registry={registry}>
          <AddToCartModal />
          <CrowdfundReservationModal />
          <FundThisGiftModal />
          <RegistryViewPurchaseInfoModal />
          <RedirectModal />
          <ReservePurchasedGiftModal />
          <RecoverReservationModal />
          <ContactInfoModal />
          <GiftMessageModal />
          <ThankYouAddressModal />
          <GiftTrackerModal />
          <UnreserveModal />
          {children}
        </RegistryLegacyContextProvider>
      </InProgressReservationContext.Provider>
    </ReduxProvider>
  )
}
