import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Badge, StoreIcons, Tag } from 'baby-design'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import useSecondhandItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useSecondhandItemsEnabled'
import { SECONDHAND_COPY } from 'registry/constants'
import RegItemMinimalTagLayout from '../components/RegItemMinimalTagLayout/RegItemMinimalTagLayout'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'
import css from '../RegItemMinimalCardDetails.styles.scss'
import { isRegItemOutOfStock } from '../RegItemMinimalCardDetails.utils'
import RegItemPricingLockup from '../../../../RegItemPricingLockup/RegItemPricingLockup'

const ProductDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  regItem,
}: RegItemMinimalCardDetailsProps) => {
  const secondhandItemsEnabled = useSecondhandItemsEnabled()
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const { title } = regItem
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.ProductDetails
  )
  const storesIconsMap = (regItem.offers || []).map((offer) => ({
    name: offer.storeIconName,
  }))
  const isOutOfStock = isRegItemOutOfStock(regItem)

  return (
    <div className={wrapperClasses} data-testid="ProductDetails">
      <div className={css.Title}>{title}</div>
      <StoreIcons
        size={isMobile ? 'small' : 'medium'}
        stores={storesIconsMap}
      />
      <div className={css.ProductDetails__Pricing}>
        <RegItemPricingLockup regItem={regItem} />
      </div>
      <RegItemMinimalTagLayout>
        {secondhandItemsEnabled && regItem.prelovedAllowed && (
          <Tag size={isMobile ? 'sm' : 'md'} type="primary" variant="normal">
            {SECONDHAND_COPY}
          </Tag>
        )}
      </RegItemMinimalTagLayout>
      {isOutOfStock && (
        <div>
          <Badge
            dangerouslyOverrideClassName={css.Badge}
            fill="gray"
            label="Out of stock"
            size={isMobile ? 'small' : 'medium'}
            variant="promo"
          />
        </div>
      )}
    </div>
  )
}

export default ProductDetails
