import { QuantityPicker } from 'baby-design'
import { useState } from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { QuantityLabel } from 'src/routes/(shop)/components/QuantityLabel/QuantityLabel'
import { AddToBabylistButton } from 'src/routes/(shop)/components/AddToBabylistButton/AddToBabylistButton'
import { useUIContext } from 'src/contexts/UIContext'
import AddFavorModal from '../../../../../../../../store/components/product-details-page/product-actions/favor-actions/addFavorModal'
import buyBoxCss from './BuyBox/BuyBox.styles.scss'

export const FavorActions = () => {
  const { productId } = useParams()
  const [quantity, setQuantity] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const { isMobile } = useUIContext()

  const { data: genericProduct } = ProductsAPI.useShow(productId)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div className={buyBoxCss.BuyBox__actionsContainer}>
      <div className={buyBoxCss.BuyBox__actionsContainer__quantity}>
        <QuantityLabel quantity={quantity} />
        <QuantityPicker
          min={1}
          size={isMobile ? 'sm' : 'md'}
          type="pdp"
          value={quantity}
          variant="fill"
          onMinusClick={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
          onPlusClick={() => setQuantity(quantity + 1)}
        />
      </div>
      <AddToBabylistButton quantity={quantity} onClick={openModal}>
        {({ addRegItem, isLoading }) =>
          showModal && (
            <AddFavorModal
              favor={genericProduct?.activeProduct?.name || ''}
              isLoading={isLoading}
              onConfirm={(description) =>
                addRegItem({ description, onSuccess: closeModal })
              }
              onHide={closeModal}
            />
          )
        }
      </AddToBabylistButton>
    </div>
  )
}
