import PropTypes from 'prop-types'
import { FabButton } from 'components/buttons'
import withBlinkyTitleBar from 'components/with-blinky-title-bar'
import css from './registry-page-guest.scss'

export const ShippingAddressFab = ({ firstNames, onClick, show }) => {
  return (
    <>
      {show && (
        <div className={css.fabShippingAddressButton}>
          <FabButton onClick={onClick}>
            <span className="hidden-xs">{firstNames}'s&nbsp;</span>
            Shipping Address
          </FabButton>
        </div>
      )}
    </>
  )
}

ShippingAddressFab.propTypes = {
  firstNames: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default withBlinkyTitleBar(ShippingAddressFab)
