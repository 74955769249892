import css from './RegistryCategoryFilters.skeleton.styles.scss'

const RegistryCategoryFiltersSkeleton = () => (
  <div
    className={css.RegistryCategoryFiltersSkeleton}
    data-testid="RegistryCategoryFiltersSkeleton"
  >
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
    <div className={css.RegistryCategoryFiltersSkeleton__Filter} />
  </div>
)

export default RegistryCategoryFiltersSkeleton
