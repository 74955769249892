import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useQuery } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'
import { track, withContextualizedTracking } from 'lib/analytics'
import { useParams } from 'react-router-dom-v5-compat'
import GiftTracker from './components/GiftTracker'
import RegistryLayout from '../../components/RegistryLayout/RegistryLayout'
import { FETCH_REGISTRY_KEY, fetchRegistry } from '../../../../api/queries'
import { useGiftTrackerRevampFeature } from './hooks'
import GiftTrackerRevampRoute from '../(gift-tracker-revamp)/Route'

const RegistryGiftTrackerPage = () => {
  const [currentUser] = useCurrentUser()
  const { id } = useParams()
  const registryId = id || currentUser?.currentRegistry?.id

  const shouldShowGiftTrackerRevamp: boolean = useGiftTrackerRevampFeature()

  const { isError, data, error } = useQuery({
    // @ts-ignore
    enabled: !!registryId,
    queryKey: [FETCH_REGISTRY_KEY, registryId],
    queryFn: () => fetchRegistry(registryId),
  })

  if (isError) {
    console.error(error)
  }

  if (shouldShowGiftTrackerRevamp) {
    return <GiftTrackerRevampRoute />
  }

  return (
    <NiceModal.Provider>
      <RegistryLayout
        currentRegistry={data}
        // @ts-ignore
        registries={currentUser?.registries}
      >
        <GiftTracker registry={data} />
      </RegistryLayout>
    </NiceModal.Provider>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY,
})(RegistryGiftTrackerPage)
