/* eslint-disable complexity */
import { filterPostsByTopicSlug, filterTrimesterPosts } from 'hello_baby/lib'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoadingHeart } from 'baby-design'
import { babylistUrl, helloBabyPostPath } from 'lib/urls'
import {
  GEAR_SLUG,
  PARENTING_SLUG,
  PREGNANCY_SLUG,
  PREGNANCY_WEEK_BY_WEEK_SLUG,
  SAMPLE_REGISTRIES_SLUG,
  FRIENDS_AND_FAMILY_SLUG,
  PREGNANCY_FIRST_TRIMESTER_SLUG,
  PREGNANCY_SECOND_TRIMESTER_SLUG,
  PREGNANCY_THIRD_TRIMESTER_SLUG,
  YOUR_BABY_WEEK_BY_WEEK_SLUG,
} from 'hello_baby/constants'
import HelloBabyMeta from 'hello_baby/components/HelloBabyMeta'
import GearView from '../GearView'
import PregnancyView from '../PregnancyView'
import PregnancyWeekByWeekView from '../PregnancyWeekByWeekView'
import PostTeaserList from '../PostTeaserList'
import FriendsAndFamilyView from '../FriendsAndFamilyView'
import ParentingView from '../ParentingView'
import SampleRegistriesView from '../SampleRegistriesView'
import { usePosts, useTopic } from '../../utils/useQueries'
import css from './TopicView.styles.scss'

interface TopicViewProps {
  slug: string
}

const TopicView = ({ slug }: TopicViewProps) => {
  const posts = usePosts()

  const topic = useTopic(slug)

  const { children: subTopics, description, pageTitle } = topic || {}

  return (
    <>
      {!posts ||
      !Object.values(posts).find((post: Post) =>
        post.meta.topics.map((t: Topic) => t.slug).includes(slug)
      ) ? (
        <LoadingHeart className={css.TopicView__Loading} />
      ) : (
        <>
          <HelloBabyMeta
            canonical={babylistUrl(helloBabyPostPath(slug))}
            seoDescription={description || ''}
            title={pageTitle || ''}
          />
          {slug === GEAR_SLUG && subTopics && (
            <GearView
              posts={filterPostsByTopicSlug(posts, slug)}
              subTopics={subTopics}
            />
          )}
          {slug === PARENTING_SLUG && <ParentingView slug={slug} />}
          {slug === PREGNANCY_SLUG && <PregnancyView />}
          {slug === FRIENDS_AND_FAMILY_SLUG && <FriendsAndFamilyView />}
          {slug === PREGNANCY_WEEK_BY_WEEK_SLUG && <PregnancyWeekByWeekView />}
          {slug === SAMPLE_REGISTRIES_SLUG && <SampleRegistriesView />}
          {slug === PREGNANCY_FIRST_TRIMESTER_SLUG && (
            <PostTeaserList posts={filterTrimesterPosts(posts, slug)} />
          )}
          {slug === PREGNANCY_SECOND_TRIMESTER_SLUG && (
            <PostTeaserList posts={filterTrimesterPosts(posts, slug)} />
          )}
          {slug === PREGNANCY_THIRD_TRIMESTER_SLUG && (
            <PostTeaserList posts={filterTrimesterPosts(posts, slug)} />
          )}
          {slug === YOUR_BABY_WEEK_BY_WEEK_SLUG && (
            <PostTeaserList
              posts={filterPostsByTopicSlug(posts, slug, {
                sortBy: 'slug',
                order: 'asc',
              })}
            />
          )}

          {[
            GEAR_SLUG,
            PARENTING_SLUG,
            PREGNANCY_SLUG,
            FRIENDS_AND_FAMILY_SLUG,
            PREGNANCY_WEEK_BY_WEEK_SLUG,
            SAMPLE_REGISTRIES_SLUG,
            PREGNANCY_FIRST_TRIMESTER_SLUG,
            PREGNANCY_SECOND_TRIMESTER_SLUG,
            PREGNANCY_THIRD_TRIMESTER_SLUG,
            YOUR_BABY_WEEK_BY_WEEK_SLUG,
          ].indexOf(slug) === -1 && (
            <PostTeaserList posts={filterPostsByTopicSlug(posts, slug)} />
          )}
        </>
      )}
    </>
  )
}

export default TopicView
