import { FC } from 'react'
import { Modal, ModalBody } from 'components/modal'
import { PersonalizeIcon } from 'components/icons'
import { useQueryClient } from '@tanstack/react-query'
import { FETCH_REGISTRY_KEY } from 'src/api/queries'
import PersonalizeRegistryForm from './PersonalizeRegistryForm/PersonalizeRegistryForm'
import { RegistryDetailsEditModalProps } from './RegistryDetailsEditModal.types'

const RegistryDetailsEditModal: FC<RegistryDetailsEditModalProps> = ({
  currentRegistry,
  handleClose,
  isOpen,
}) => {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    queryClient.invalidateQueries([FETCH_REGISTRY_KEY, currentRegistry?.id])
    handleClose()
  }

  return (
    <Modal
      affixedFooter
      showCloseButton
      icon={<PersonalizeIcon className="" />}
      show={isOpen}
      title="Add a photo and a greeting"
      onHide={handleClose}
    >
      <ModalBody>
        <PersonalizeRegistryForm
          registry={currentRegistry}
          onSuccess={onSuccess}
        />
      </ModalBody>
    </Modal>
  )
}

export default RegistryDetailsEditModal
