import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { MessageWithTooltip } from '../MessageWithTooltip'
import { MerchandisingMessageConfig } from './MerchandisingMessageConfig'

export const VirtualBundle = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct) return null

  const {
    activeProduct: { productType, approved },
  } = genericProduct

  if (productType !== 'virtual_bundle' || !approved) return null

  const { tooltipContent, message, type } =
    MerchandisingMessageConfig.virtualBundle

  return (
    <MessageWithTooltip
      content={tooltipContent}
      message={message}
      type={type}
    />
  )
}
