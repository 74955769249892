import { Factory } from 'rosie'
import { HeaderDetails } from 'src/types/registry'
import faker from 'faker'

const randomPreferences = (): { name: string; affinity: string }[] => [
  { name: faker.random.word(), affinity: 'loves' },
  { name: faker.random.word(), affinity: 'likes' },
  { name: faker.random.word(), affinity: 'please_no' },
]

const randomShoeSize = (): { name: number; group: number }[] => [
  { name: faker.datatype.number(1), group: faker.datatype.number(1) },
]

const DEFAULT_SHIPPING_CONSOLIDATION_CHOICE = 'none'
const DEFAULT_PLANNING_STAGE = 'pregnancy'
const DEFAULT_SEARCH_LEVEL = 'private'

const generateAttributeData = (registryType: string | null): {} | object => {
  if (registryType === 'birthday' || registryType === 'holiday') {
    return {
      birthdate: faker.date.past().toISOString(),
      clothingSize: [faker.datatype.number(1), faker.datatype.number(1)],
      favoriteColor: faker.commerce.color(),
      firstName: faker.name.firstName(),
      preferences: randomPreferences(),
      shoeSize: randomShoeSize(),
    }
  }
  return {}
}

const headerDetailsData = (): HeaderDetails => ({
  arrivalDateDisplay: 'December 16, 2020',
  avatarEmptyStateText: 'TF',
  avatarImageUrl: 'https://example.com/avatar.jpg',
  glowupEnabled: false,
  headerImageUrl: 'https://example.com/header.jpg',
  location: 'New York, NY',
  names: 'Teddy Fraczak',
})

const RegistryFactory = new Factory()
  .attr('type', null)
  .attr('addressId', () => faker.datatype.uuid())
  .attr('attributeData', ['type'], generateAttributeData)
  .attr('cashFundPreferences', null)
  .attr('categories', null)
  .attr('createdAt', () => faker.date.past().toISOString())
  .attr('description', null)
  .attr('experiments', null)
  .attr('family', () => ({
    arrivalDate: faker.date.future().toISOString(),
    expectingChildren: 1,
    hasPartner: false,
    isArrivalDateKnown: true,
    planningStage: DEFAULT_PLANNING_STAGE,
  }))
  .attr('firstNames', () => faker.name.findName())
  .attr('headerDetails', headerDetailsData)
  .attr('id', () => faker.datatype.number())
  .attr('isEligibleForGiftGiverReminders', false)
  .attr('isShippingAddressPrivate', false)
  .attr('ownerFirstName', () => faker.name.firstName())
  .attr('ownerId', () => faker.datatype.number())
  .attr('ownerName', () => faker.name.findName())
  .attr('photoUrl', null)
  .attr('regItemsBabylistCount', () => faker.datatype.number({ max: 10 }))
  .attr('regItemsCount', () => faker.datatype.number({ max: 20 }))
  .attr('regItemsOtherCount', () => faker.datatype.number({ max: 5 }))
  .attr('searchLevel', DEFAULT_SEARCH_LEVEL)
  .attr('shippingConsolidation', {
    consolidationChoice: DEFAULT_SHIPPING_CONSOLIDATION_CHOICE,
  })
  .attr('showerInfo', () => ({
    havingShower: false,
    showers: [],
  }))
  .attr('showMultipleStores', true)
  .attr('title', () => `${faker.name.firstName()}'s Baby Registry`)
  .attr('uiState', {})
  .attr('url', () => faker.internet.url())
  .attr('urlSlug', () => faker.internet.userName())

export default RegistryFactory
