import { FC } from 'react'
import { format, parseISO } from 'date-fns'

import { GiftGiver } from 'src/routes/(registry)/registry/(gift-tracker-revamp)/context/GiftGiversProvider.types'

import css from './GiftDateAndCount.styles.scss'

const formatReservationDate = (dateString: string) =>
  format(parseISO(dateString), 'MMM d')

const GiftDate: FC<{ mostRecentReservationAt: string }> = ({
  mostRecentReservationAt,
}) => (
  <div className={css.GiftDateAndCount__GiftDate}>
    {formatReservationDate(mostRecentReservationAt)}
  </div>
)

const DotSeparator: FC = () => (
  <div className={css.GiftDateAndCount__DotSeparator}>·</div>
)

const GiftCount: FC<{ reservationCount: number }> = ({ reservationCount }) => {
  const giftString = reservationCount === 1 ? 'gift' : 'gifts'

  return (
    <div className={css.GiftDateAndCount__GiftCount}>
      {reservationCount} {giftString}
    </div>
  )
}

const GiftDateAndCount: FC<{ giftGiver: GiftGiver }> = ({ giftGiver }) => (
  <div className={css.GiftDateAndCount}>
    <GiftDate mostRecentReservationAt={giftGiver.mostRecentReservationAt} />
    <DotSeparator />
    <GiftCount reservationCount={giftGiver.reservationCount} />
  </div>
)

export default GiftDateAndCount
