import RegistryAvatar from 'registry/components/registry-avatar'
import Image from 'components/image'
import HtmlContent from 'shared/html-content'
import { SectionHeading } from 'components/headings'
import StatsBar, { StatsBarProps } from 'registry/components/StatsBar/StatsBar'
import { PreferencesDisplay } from '../PreferencesDisplay/PreferencesDisplay'
import css from './WishlistHeaderLayout.styles.scss'

type WishlistHeaderStats = StatsBarProps['stats']

interface WishlistHeaderLayoutProps {
  registry: any
  stats: WishlistHeaderStats
}

export const WishlistHeaderLayout = ({
  registry,
  stats,
}: WishlistHeaderLayoutProps) => {
  const {
    attributeData: { preferences = [] } = {},
    title,
    description,
    photoUrl,
  } = registry
  const formattedDescription = description
    ? `${description.split('\n').join('</p><p>')}`
    : null

  return (
    <div className="flex col align-center">
      {photoUrl && (
        <RegistryAvatar
          altText={`${title} Photo`}
          className={css.avatar}
          url={photoUrl}
        />
      )}
      <SectionHeading className={css.title}>{title}</SectionHeading>
      {formattedDescription && (
        <HtmlContent
          as="div"
          className={css.description}
          html={formattedDescription}
        />
      )}
      <Image
        className="mvm"
        role="presentation"
        src="//images.babylist.com/image/upload/v1691688890/hr-squiggle_pbcj7o.svg"
      />
      <div className="mbl">
        <PreferencesDisplay preferences={preferences} />
      </div>
      <StatsBar stats={stats} />
    </div>
  )
}
