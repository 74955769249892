import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CopyAndIcon, TextLink } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'baby-design/icons'
import CopyContactInfoModal from '../../CopyContactInfoModal'
import { RegistryGiftGiverHeaderProps } from '../../RegistryGiftGiverHeader.types'
import styles from './RegistrantLocation.styles.scss'

interface Props extends RegistryGiftGiverHeaderProps {
  windowFormat: 'desktop' | 'mobile'
}

const RegistrantLocation: React.FC<Props> = ({ registry, windowFormat }) => {
  const { location } = registry.headerDetails
  const onClick = () => {
    NiceModal.show(CopyContactInfoModal, { registry })
  }

  return (
    <div data-testid="AdditionalHeaderDetails-location">
      <CopyAndIcon
        copy={
          <div className={styles.location_copy_container}>
            {location}
            <TextLink
              underline
              innerContentClassName={styles.view_shipping_address_link}
              size={windowFormat === 'desktop' ? 'sm' : 'xs'}
              weight="regular"
              onClick={onClick}
            >
              View shipping address
            </TextLink>
          </div>
        }
        icon={<Location />}
        size={windowFormat === 'desktop' ? 'md' : 'sm'}
      />
    </div>
  )
}

export default RegistrantLocation
