import React, { Suspense, useContext, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DragDropContext } from '@hello-pangea/dnd'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { successMessage } from 'lib/flash-message'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, LoadingDots } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Lock } from 'baby-design/icons'
import NiceModal from '@ebay/nice-modal-react'
import { track, useTracking } from 'lib/analytics/track'
import { Offer } from 'src/types/offer'
import EmptyRegistryItemsList from 'src/routes/(registry)/list/[slug]/components/RegistryItemsList/EmptyRegistryItemsList/EmptyRegistryItemsList'
import { trackRegistryItemDetailsViewed } from 'src/lib/tracking/registry/functions/registryItemDetailsViewed'
import { optimisticallyUpdateRegItem } from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'
import { useQueryClient } from '@tanstack/react-query'
import css from './RegistryItemsList.styles.scss'
import { RegistryItemsListProps } from './RegistryItemsList.types'
import RegistryContext from '../../../../../../contexts/registry'
import {
  getCategoriesAsKeyValue,
  getCategoryTabById,
} from '../../utils/productHelpers'
import { isMobile } from '../../../../../../utils/screensize'
import { ProductCategory } from '../RegItemCard/RegItemCard.types'
import RegItemCardSkeleton from '../RegItemCard/RegItemCard.skeleton'
import DroppableCategoryList from './DroppableCategoryList'
import { RegItem } from '../../../../../../types/regItem'
import EditRegItemDrawer from '../EditRegItemDrawer/EditRegItemDrawer'
import ConfirmRegItemDeleteModal from '../ConfirmRegItemDeleteModal/ConfirmRegItemDeleteModal'
import GroupGiftRedemptionModal from '../GroupGiftRedemptionModal/GroupGiftRedemptionModal'

const DEFAULT_CATEGORY_TAB = {
  label: 'General',
  value: 0,
}

const RegistryItemsList: React.FC<RegistryItemsListProps> = ({
  regItemsByCategory,
  categoriesWithItems,
  categories,
  listType,
  mutateRegItem,
  moveRegItem,
  noItemsMatchFilters,
  noItemsOnRegistry,
  isLoadingRegItems,
  openRegItemFilterDrawer,
}) => {
  const queryClient = useQueryClient()
  const tracker = useTracking()
  const RegistryItemsListClasses = classNames(css.RegistryItemsList)
  const { activeCategory, setActiveCategory } = useContext(RegistryContext)
  const [currentUser] = useCurrentUser()

  const categoryTabs = categoriesWithItems
    ? getCategoriesAsKeyValue(categoriesWithItems)
    : []
  const allCategories = categories ? getCategoriesAsKeyValue(categories) : []
  const categoryRefs = useRef<Record<string, HTMLElement | null>>({})

  const regItemCategoryForId = (categoryId: number) =>
    categories?.find((cat) => categoryId === cat.id)

  const handleBuyingOptionClick = (regItem: RegItem) => (option: Offer) => {
    const { url } = option
    if (!option.url) return

    tracker.trackEvent({
      event: track.registryProductOfferClicked,
      eventLocation:
        track.EventLocation.REGISTRY_MINUS_BUYING_OPTION_REG_ITEM_CARD,
      registryId: currentUser?.currentRegistry.id,
      registryOwnerId: currentUser?.currentRegistry.ownerId,
      listType: currentUser?.currentRegistry.type || 'baby_registry',
      isRegistryOwner: currentUser?.currentRegistry.currentUserRole === 'owner',
      store: option.storeDisplayName,
      regItemId: regItem.id,
      offerIds: regItem.offers.map(({ id }) => id),
    })
    window.open(url, '_blank')
  }

  const handleRegItemCategoryChange =
    (regItem: any, index: number) => (category: any) => {
      const sourceCategory =
        getCategoryTabById(categoryTabs, regItem.categoryId) ||
        DEFAULT_CATEGORY_TAB
      moveRegItem(sourceCategory.label, category.label, index, 0, regItem)
      const regItemCategory = regItemCategoryForId(category.value)
      const updateParams = {
        id: regItem.id,
        registry: currentUser?.currentRegistry,
        categoryId: category.value,
        private: regItem.private && !regItemCategory?.isPrivate,
      }
      mutateRegItem.mutate(updateParams)
      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: {
          ...regItem,
          ...updateParams,
        },
      })
      successMessage(`${regItem?.title} was moved to ${category.label}!`)
    }

  const handleRegItemEdit = (regItem: any) => () => {
    trackRegistryItemDetailsViewed({
      tracker,
      currentUser,
      regItem,
      eventLocation: track.EventLocation.REGISTRY,
    })
    NiceModal.show(EditRegItemDrawer, {
      regItem,
    })
  }

  const handleRegItemDelete = (regItem: any) => () => {
    if (regItem.isGroupGift) {
      NiceModal.show(GroupGiftRedemptionModal, {
        regItem,
        action: 'delete-reg-item',
      })
    } else {
      NiceModal.show(ConfirmRegItemDeleteModal, { regItem })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const categoryInView = categoryTabs.find((category: ProductCategory) => {
        const categoryRef = categoryRefs.current[category.label]
        if (categoryRef) {
          const { top, bottom } = categoryRef.getBoundingClientRect()
          return top <= 100 && bottom > 0
        }
        return false
      })

      if (categoryInView && categoryInView.label !== activeCategory.label) {
        setActiveCategory(categoryInView)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [activeCategory, setActiveCategory, categoryTabs, regItemsByCategory])

  const onDragStart = () => {
    document.documentElement.setAttribute('style', 'scroll-behavior: auto')
  }

  const onDragEnd = (event: any) => {
    document.documentElement.removeAttribute('style')

    // event.draggableId is in the format of 'regItemId-categoryLabel'
    // We use indexOf and substrings because sometimes category labels contain dashes.
    // We only want to split on the first dash to prevent mangling the category label.
    const firstDashIndex = event.draggableId.indexOf('-')
    const regItemId = event.draggableId.substring(0, firstDashIndex)
    const sourceCategoryLabel = event.draggableId.substring(firstDashIndex + 1)

    const destinationCategoryId = parseInt(
      event.destination.droppableId.split('_')[1]
    )
    const oldPosition = event?.source?.index
    const newPosition = event?.destination?.index

    if (newPosition === undefined) return

    const movedUp = oldPosition < newPosition
    const activeRegItem = regItemsByCategory[sourceCategoryLabel].find(
      (item: any) => item.id === parseInt(regItemId)
    )
    const category =
      getCategoryTabById(categoryTabs, destinationCategoryId) ||
      DEFAULT_CATEGORY_TAB

    moveRegItem(
      sourceCategoryLabel,
      category.label,
      oldPosition,
      newPosition,
      activeRegItem
    )

    const positionDifference = Math.abs(oldPosition - newPosition)

    if (movedUp && activeRegItem) {
      activeRegItem.position -= positionDifference
    } else if (activeRegItem) {
      activeRegItem.position += positionDifference
    }

    mutateRegItem.mutate({
      id: parseInt(regItemId),
      registry: currentUser?.currentRegistry,
      categoryId: destinationCategoryId,
      row: newPosition,
    })
  }

  if (noItemsOnRegistry) {
    return <EmptyRegistryItemsList listType={listType} />
  }

  if (noItemsMatchFilters) {
    return (
      <div className={css.RegistryItemsList__noItems}>
        <h3 className={css.RegistryItemsList__noItemsHeading}>
          No items match your filters!
        </h3>
        <p className={css.RegistryItemsList__noItemsText}>
          Update your selected filters to see the items on your registry.
        </p>
        <Button size="md" variant="primary" onClick={openRegItemFilterDrawer}>
          Open Filters
        </Button>
      </div>
    )
  }

  if (!regItemsByCategory || Object.keys(regItemsByCategory).length === 0) {
    const size = isMobile() ? 'phone' : 'desktop'
    return (
      <div className={css.RegistryItemsListSkeleton}>
        <div className={css.RegistryItemsList__items}>
          <RegItemCardSkeleton size={size} />
          <RegItemCardSkeleton size={size} />
          <RegItemCardSkeleton size={size} />
        </div>
      </div>
    )
  }

  return (
    <div className={RegistryItemsListClasses}>
      <Suspense fallback={<div />}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {categoryTabs.map((category: ProductCategory) => (
            <div
              id={`category-${category.value}`}
              key={category.value}
              ref={(ref) => {
                categoryRefs.current[category.label] = ref
              }}
            >
              <h3 className={css.RegistryItemsList__categoryLabel}>
                {category.label}{' '}
                {`(${regItemsByCategory[category.label].length})`}
                {categories &&
                  regItemCategoryForId(category.value)?.isPrivate && <Lock />}
              </h3>
              <DroppableCategoryList
                categories={allCategories}
                handleBuyingOptionClick={handleBuyingOptionClick}
                handleRegItemCategoryChange={handleRegItemCategoryChange}
                handleRegItemDelete={handleRegItemDelete}
                handleRegItemEdit={handleRegItemEdit}
                productCategory={category}
                regItemCategory={regItemCategoryForId(category.value)}
                regItems={regItemsByCategory[category.label]}
              />
              <div className={css.RegistryItemsList__items} />
            </div>
          ))}
        </DragDropContext>
      </Suspense>
      {isLoadingRegItems && (
        <LoadingDots className={css.RegistryItemsList__loadingDots} />
      )}
    </div>
  )
}

export default RegistryItemsList
