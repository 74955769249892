import React from 'react'
import OrderCardHeader from 'src/routes/(registry)/shipments/[shipmentId]/components/OrderCard/components/OrderCardHeader/OrderCardHeader'
import OrderItem from 'src/routes/(registry)/shipments/[shipmentId]/components/OrderCard/components/OrderItem/OrderItem'
import css from './OrderCard.styles.scss'
import { OrderCardProps } from './OrderCard.types'

const OrderItemDivider: React.FC = () => (
  <div className={css.OrderCard__Divider}>
    <hr />
  </div>
)

const OrderCard: React.FC<OrderCardProps> = ({
  giftGiverName,
  isGift,
  isSurprise,
  isRevealed,
  itemCount,
  items,
  orderNumber,
  orderUrl,
  registryId,
}) => (
  <div className={css.OrderCard}>
    <div className={css.OrderCard__Container}>
      <OrderCardHeader
        giftGiverName={giftGiverName}
        isGift={isGift}
        isRevealed={isRevealed}
        isSurprise={isSurprise}
        itemCount={itemCount}
        orderNumber={orderNumber}
        orderUrl={orderUrl}
        registryId={registryId}
      />
      <div>
        {items.map((item, index) => (
          <>
            <OrderItem
              image={item.imageUrl}
              quantity={item.quantity}
              title={item.title}
              url={item.productPageUrl}
            />
            {index !== items.length - 1 && <OrderItemDivider />}
          </>
        ))}
      </div>
    </div>
  </div>
)

export default OrderCard
