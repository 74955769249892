import { RegItem } from 'src/types/regItem'
import { MeterProps } from 'baby-design/components/Meter/Meter.types'
import { openFundThisGiftModal } from 'registry/modals/actions'
import { useDispatch } from 'react-redux'
import { useModal } from '@ebay/nice-modal-react'

export const useGetHandleContributeClick = (regItem: RegItem) => {
  const dispatch = useDispatch()
  const modal = useModal()

  return () => {
    dispatch(openFundThisGiftModal(regItem))
    modal.hide()
  }
}

export const meterPropsFrom = (regItem: RegItem): MeterProps => {
  const { fundedAmount, goalAmount } = regItem

  let groupGiftProgress = 0
  let stillNeeds = goalAmount

  if (fundedAmount && goalAmount) {
    groupGiftProgress = (fundedAmount / goalAmount) * 100
    stillNeeds = goalAmount - fundedAmount
  }

  return {
    label: `Still needs $${stillNeeds.toFixed(2)}`,
    percentage: groupGiftProgress,
    size: 'sm',
  }
}
