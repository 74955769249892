import { BrandHeader } from './BrandHeader'
import { ProductHeader } from './ProductHeader'
import css from './BuyBox.styles.scss'

export const PDPHeadline = () => (
  <div className={css.BuyBox__headline}>
    <h1>
      <BrandHeader />
      <ProductHeader />
    </h1>
  </div>
)
