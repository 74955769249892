import { Col, Row } from 'react-bootstrap'
import { map } from 'lodash'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import ProductContentSection from './product-content-section'

const ProductVideos = ({ videos }) => (
  <ProductContentSection title="Related videos">
    {map(videos, (video) => (
      <Row key={video.url}>
        <Col sm={8} smOffset={2}>
          <div className="mbxl embed-responsive embed-responsive-16by9">
            <iframe allowFullScreen loading="lazy" src={video.url} />
          </div>
        </Col>
      </Row>
    ))}
  </ProductContentSection>
)

export default ProductVideos
