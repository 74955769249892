import { FC } from 'react'

import { Reservation } from 'src/types/reservation'

import css from './GiftSummary.styles.scss'
import LabelAndInfo from '../LabelAndInfo'
import ImageAndQuantity from '../../ImageAndQuantity'

const GiftSummary: FC<{ reservation: Reservation }> = ({ reservation }) => (
  <div className={css.GiftSummary}>
    <div className={css.GiftSummary__ProductInfo}>
      <ImageAndQuantity reservation={reservation} />
      <div className={css.GiftSummary__ProductTitle}>
        {reservation.displayTitle}
      </div>
    </div>
    <LabelAndInfo
      info={reservation.name as string}
      label="Gift from"
      variant="header"
    />
  </div>
)

export default GiftSummary
