import { useLocation, useParams } from 'react-router-dom-v5-compat'
import { useQueryClient } from '@tanstack/react-query'
import {
  hydrateProductCache,
  ProductsAPI,
  PRODUCTS_STALE_TIME,
} from 'src/routes/(shop)/api/products'
import { Navbar } from 'src/routes/(shop)/components/Navbar'
import { track, useTracking, withContextualizedTracking } from 'lib/analytics'
import { useEffect } from 'react'
import {
  TrackPDPImpression,
  trackPDPPageChange,
  trackPDPPageView,
} from 'src/routes/(shop)/lib/tracking'
import { usePrefetchCurrentRegistry } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import NiceModal from '@ebay/nice-modal-react'
import PlacementsInterstitialModal from 'components/modal/PlacementsInterstitialModal'
import { PLACEMENT_INTERSTITIAL_UNIT } from 'lib/placements/units'
import { MetaTags } from './components/MetaTags'
import { Breadcrumb } from './components/Breadcrumb'
import { PDPPage } from './components/PDPPage'
import {
  setProductDataForBookmarklet,
  unsetProductDataForBookmarklet,
} from '../../../../../../../store/lib/bookmarklet'
import { PDPProvider } from './components/Context'

const ProductPage = () => {
  const { genericProductId, productId } = useParams()
  const queryClient = useQueryClient()
  const { data: genericProduct, isLoading } = ProductsAPI.useShow(productId, {
    staleTime: PRODUCTS_STALE_TIME,
  })
  const pdpType = genericProduct?.pdpType
  const activeProduct = genericProduct?.activeProduct
  const location = useLocation()

  usePrefetchCurrentRegistry()

  useEffect(() => {
    if (genericProduct) {
      hydrateProductCache({ data: genericProduct, productId, queryClient })
    }
  }, [genericProduct])

  useEffect(() => {
    trackPDPPageView(location)
  }, [genericProductId])

  useEffect(() => {
    trackPDPPageChange(activeProduct)
  }, [activeProduct?.id])

  useEffect(() => {
    if (activeProduct?.id) setProductDataForBookmarklet(activeProduct)
    return () => unsetProductDataForBookmarklet()
  }, [activeProduct?.id])

  const { Track } = useTracking({
    eventLocation: track.EventLocation.PDP,
    pdpType,
    product: activeProduct || {},
  })

  return (
    <NiceModal.Provider>
      <Track>
        <MetaTags />
        <Navbar />
        <Breadcrumb />
        {isLoading || !activeProduct ? null : (
          <TrackPDPImpression activeProduct={activeProduct}>
            <PDPProvider>
              <PDPPage pdpType={pdpType} />
            </PDPProvider>
          </TrackPDPImpression>
        )}
      </Track>
      <PlacementsInterstitialModal unitId={PLACEMENT_INTERSTITIAL_UNIT} />
    </NiceModal.Provider>
  )
}

// We still need to keep wrapping the ProductPage component
// with withContextualizedTracking() for now. We were seeing
// SSR errors when removing it. Shop team is investigating
// the root cause of this, but for now we need to keep this
// in here.
export default withContextualizedTracking()(ProductPage)
