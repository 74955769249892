import { PropTypes } from 'prop-types'

const UnknownReturn = (props) => {
  const { giftGiverName, giftGiverEmail, orderNum, storeName } = props

  return (
    <div>
      <div className="mbl">
        Returns for this product are handled directly by the store it was
        purchased from.
      </div>

      <div className="mbl">
        <div className="h6 mbm text-uppercase">
          <b>Purchase Info</b>
        </div>
        <div className="h6 mvm">
          Given by: {giftGiverName} ({giftGiverEmail})
        </div>
        {storeName && <div className="h6 mvm">Store: {storeName}</div>}
        {orderNum && <div className="h6 mvm">Order Number: {orderNum}</div>}
      </div>

      <p className="mbl">
        If you still need help, please contact a Babylist Happiness Hero.
      </p>
    </div>
  )
}

UnknownReturn.propTypes = {
  orderNum: PropTypes.string,
  storeName: PropTypes.string,
  giftGiverName: PropTypes.string.isRequired,
  giftGiverEmail: PropTypes.string.isRequired,
}

UnknownReturn.defaultProps = {
  orderNum: null,
  storeName: null,
}

export default UnknownReturn
