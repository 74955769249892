import React from 'react'
// eslint-disable-next-line
import { Checkbox } from 'baby-design'

import { RegItemMarkPurchasedCheckboxProps } from './RegItemMarkPurchasedCheckbox.types'
import { useGetHandlePurchasedCheckboxClicked } from './RegItemMarkPurchasedCheckbox.utils'
import css from './RegItemMarkPurchasedCheckbox.styles.scss'

const REG_ITEM_PURCHASED_CHECKBOX_ID = 'PurchasedCheckbox'
export const REG_ITEM_PURCHASED_CHECKBOX_TEXT = "I've purchased this"

const RegItemMarkPurchasedCheckbox: React.FC<
  RegItemMarkPurchasedCheckboxProps
> = ({ regItem, registry }) => {
  const handlePurchasedCheckboxClicked = useGetHandlePurchasedCheckboxClicked(
    regItem,
    registry
  )

  return (
    <label
      className={css.RegItemMarkPurchasedCheckbox}
      data-testid="RegItemMarkPurchasedCheckbox"
      htmlFor={REG_ITEM_PURCHASED_CHECKBOX_ID}
    >
      <Checkbox
        inputProps={{
          'data-testid': REG_ITEM_PURCHASED_CHECKBOX_ID,
          name: REG_ITEM_PURCHASED_CHECKBOX_ID,
          id: REG_ITEM_PURCHASED_CHECKBOX_ID,
          onChange: handlePurchasedCheckboxClicked,
        }}
        size="lg"
      />
      <span>{REG_ITEM_PURCHASED_CHECKBOX_TEXT}</span>
    </label>
  )
}

export default RegItemMarkPurchasedCheckbox
