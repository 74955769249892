import { apiV3ContentCollectionsPath } from 'lib/urls'
import {
  COLLECTION_SLUG_HOME_TOPICS,
  COLLECTION_SLUG_TOPIC_NAVIGATION,
} from 'shared/constants'
import { FEATURED_POSTS_COLLECTION_SLUG } from 'hello_baby/constants'

export const FETCH_FEATURED_POSTS_SLUGS = 'FETCH_FEATURED_POSTS'
export const fetchFeaturedPostsSlugs = async () => {
  const response = await fetch(
    apiV3ContentCollectionsPath(FEATURED_POSTS_COLLECTION_SLUG),
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Key-Inflection': 'camel',
      },
      method: 'GET',
    }
  )

  const jsonResponse = await response.json()

  return jsonResponse.collection
}

export const FETCH_HOME_TOPICS_KEY = 'FETCH_HOME_TOPICS'
export const fetchHomeTopics = async () => {
  const response = await fetch(
    apiV3ContentCollectionsPath(COLLECTION_SLUG_HOME_TOPICS),
    {
      method: 'GET',
    }
  )

  const jsonResponse = await response.json()

  return jsonResponse.collection
}

export const FETCH_TOPIC_NAVIGATION_KEY = 'FETCH_TOPIC_NAVIGATION'
export const fetchTopicNavigation = async () => {
  const response = await fetch(
    apiV3ContentCollectionsPath(COLLECTION_SLUG_TOPIC_NAVIGATION),
    {
      method: 'GET',
    }
  )

  const jsonResponse = await response.json()

  return jsonResponse.collection
}
