import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import ProductSlider from 'components/ProductSlider'
import { values } from 'lodash'
import { api } from 'shared/api'
import { GuidesAPI } from 'src/routes/(shop)/api/guides'
import { useUIContext } from 'src/contexts/UIContext'
import ProductGuides from '../../../../../../../../../store/components/product-details-page/product-guides'
import ProductVideos from '../../../../../../../../../store/components/product-details-page/product-videos'
import { mapHeroesToProductCards } from '../../../../../../../../../store/components/product-grid'
import { ProductReviews as _ProductReviews } from '../../../../../../../../../store/components/product-reviews'
import css from './ProductDetailsSection.styles.scss'

const ProductReviews =
  _ProductReviews as unknown as React.JSXElementConstructor<{
    productSkus: string[]
    sku: string | null | undefined
  }>

export const ProductDetailsSection = () => {
  const { productId } = useParams()
  const { data: genericProduct, isLoading } = ProductsAPI.useShow(productId)

  if (isLoading || !genericProduct) return null

  const {
    activeProduct,
    products,
    videos,
    id: genericProductId,
  } = genericProduct

  const productKey = [genericProductId, productId]

  const {
    data: recommendedProductsData,
    isLoading: recommendedProductsIsLoading,
  } = api.productRecommendations.useProductRecommendations(productKey)
  const { data: guidesData, isLoading: guidesIsLoading } =
    GuidesAPI.useIndex(genericProductId)
  const reviewSkus = values(products).map((p: any) => p.turntoSku)

  const { isMobile } = useUIContext()

  return (
    <div className={css.ProductDetailsSection__container}>
      <div className={css.ProductDetailsSection}>
        {videos && videos.length ? <ProductVideos videos={videos} /> : null}
        {!recommendedProductsIsLoading &&
          recommendedProductsData &&
          recommendedProductsData.recommendedProducts.map(
            (recommendation: any) =>
              recommendation.products.length ? (
                <ProductSlider
                  children={null}
                  isMobile={isMobile}
                  key={recommendation.title}
                  products={recommendation.products.map((p: any) =>
                    mapHeroesToProductCards(p)
                  )}
                  title={recommendation.title}
                  trackingData={{
                    productRecommenderModelVersion:
                      recommendation.productRecommenderModelVersion,
                  }}
                />
              ) : null
          )}
        <ProductReviews
          productSkus={reviewSkus}
          sku={activeProduct?.turntoSku}
        />
        {!guidesIsLoading && guidesData && guidesData.length > 0 && (
          <ProductGuides guides={guidesData.slice(0, 3)} />
        )}
      </div>
    </div>
  )
}
