import { FC } from 'react'
import { Outlet } from 'react-router-dom-v5-compat'

import css from './Layout.styles.scss'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'

const Layout: FC = () => (
  <div className={css.Layout}>
    <Navbar />
    <div className={css.Layout__content}>
      <Outlet />
    </div>
    <Footer />
  </div>
)

export default Layout
