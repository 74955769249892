import React from 'react'

interface XProps {
  className?: string
}

const X: React.FC<XProps> = ({ className }: XProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>X (Formerly Twitter) Icon</title>
    <path
      d="M20.0683 3.48941C20.3386 3.17553 20.3033 2.70197 19.9894 2.43169C19.6755 2.1614 19.202 2.19674 18.9317 2.51062L12.9873 9.41377L8.63172 2.59634C8.49392 2.38066 8.25564 2.25013 7.9997 2.25013H4.01244C3.9151 2.24838 3.81669 2.26553 3.72293 2.30285C3.67357 2.32246 3.62679 2.34716 3.58323 2.3763C3.46231 2.45694 3.37217 2.56696 3.31626 2.6908C3.28866 2.75168 3.26896 2.81691 3.25846 2.88517C3.23743 3.0204 3.25314 3.16177 3.30913 3.29322C3.32636 3.33378 3.34706 3.37251 3.37087 3.40904L9.66911 13.2671L3.43167 20.5106C3.16139 20.8245 3.19673 21.2981 3.51061 21.5683C3.82449 21.8386 4.29804 21.8033 4.56833 21.4894L10.5122 14.5868L14.8636 21.3977C14.8695 21.4071 14.8756 21.4164 14.8819 21.4255C14.9523 21.5279 15.044 21.6075 15.1472 21.6623C15.2387 21.7111 15.3414 21.7415 15.4505 21.7485C15.4703 21.7499 15.4902 21.7504 15.5102 21.7501H19.4997C19.7737 21.7501 20.0259 21.6007 20.1575 21.3605C20.2891 21.1202 20.2792 20.8272 20.1317 20.5963L13.8304 10.7334L20.0683 3.48941Z"
      fill="#4A2143"
    />
  </svg>
)

export default X
