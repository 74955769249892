import React from 'react'
import { Button } from 'baby-design'
import { SHOW_GIFT_RETURN_MODAL } from 'shared/constants'
import { useReservationCardContext } from '../../../../context'
import css from '../ActionButton.styles.scss'

interface UrlProps {
  href: string
  target: string
  rel: string
}

interface OnClickProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Return: React.FC = () => {
  const {
    email,
    daysSincePurchase,
    regItem,
    registryId,
    returnWebUrl,
    name,
    orderNumber,
    storeName,
    token,
  } = useReservationCardContext()

  let props: UrlProps | OnClickProps | null = null

  // Open link to returnly if the item is eligible
  if (returnWebUrl) {
    props = {
      href: returnWebUrl,
      target: '_blank',
      rel: 'norewrite',
    }
  } else if (regItem && !regItem.isDigitalItem) {
    props = {
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        PubSub.publish(SHOW_GIFT_RETURN_MODAL, {
          registryId,
          reservationToken: token,
          storeName,
          orderNum: orderNumber,
          regItem,
          imageUrl: regItem.imgUrl,
          title: regItem.title,
          giftGiverName: name,
          giftGiverEmail: email,
          daysSincePurchase,
        })
      },
    }
  }

  return (
    props && (
      <>
        <Button
          block
          className={css['ActionButton--desktop']}
          size="sm"
          variant="tertiary"
          {...props}
        >
          Return
        </Button>
        <Button
          block
          className={css['ActionButton--mobile']}
          size="md"
          variant="tertiary"
          {...props}
        >
          Return
        </Button>
      </>
    )
  )
}

export default Return
