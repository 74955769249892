import { useQuery } from '@tanstack/react-query'
import { apiV3CashFundContributionPath } from 'lib/urls'
import { useLocation } from 'react-router-dom-v5-compat'
import fetch from 'lib/fetch'

const fetchReservation = (token: string) =>
  fetch(apiV3CashFundContributionPath(token), { method: 'GET' })

const FETCH_RESERVATION_KEY = 'reservations'

const useInProgressReservation = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const reservationId = queryParams.get('reservation_id')

  const { data: inProgressReservation, isLoading: isReservationLoading } =
    useQuery({
      enabled: !!reservationId,
      queryKey: [FETCH_RESERVATION_KEY, reservationId],
      queryFn: () => fetchReservation(reservationId as string),
    })

  return {
    inProgressReservation,
    isReservationLoading,
  }
}

export default useInProgressReservation
