import {
  ageNextBirthday,
  currentAgeInYears,
  currentAgeInMonths,
  currentAgeInWeeks,
} from 'lib/date'
import { BIRTHDAY_REGISTRY_TYPE, HOLIDAY_REGISTRY_TYPE } from 'src/constants'
import { Registry } from 'src/types/registry'
import { StatsBarProps } from 'registry/components/StatsBar/StatsBar'

export const getCurrentAgeStr = (birthdate: string) => {
  const ageInYears = currentAgeInYears(birthdate)
  if (ageInYears >= 2) {
    return ageInYears
  }

  const ageInMonths = currentAgeInMonths(birthdate)
  if (ageInMonths >= 1) {
    return `${ageInMonths}M`
  }

  const ageInWeeks = currentAgeInWeeks(birthdate)
  if (ageInWeeks >= 0) {
    return `${Math.max(ageInWeeks, 1)}W`
  }

  const month = new Date(birthdate).toLocaleString('en-us', { month: 'short' })
  return `Due ${month}!`
}

export const buildStats = (registry: Registry): StatsBarProps['stats'] => {
  const { attributeData = {}, type } = registry
  const { birthdate, clothingSize, shoeSize, favoriteColor } = attributeData

  const stats = {
    clothingSize,
    shoeSize,
    favoriteColor,
  } as StatsBarProps['stats']

  if (type === HOLIDAY_REGISTRY_TYPE) {
    stats.currentAge = getCurrentAgeStr(birthdate as string)
  } else if (type === BIRTHDAY_REGISTRY_TYPE) {
    const nextAge = ageNextBirthday(birthdate)
    stats.nextAge = Math.max(nextAge, 1)
  }

  return stats
}
