import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import classNames from 'classnames'
import { SideNav } from 'components/side-nav'
import * as blUrls from 'lib/urls'
import HelloBabyMeta from 'hello_baby/components/HelloBabyMeta/HelloBabyMeta'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import PostCardRow from 'hello_baby/components/post-card-row'
import HeroPost from 'hello_baby/components/HeroPost'
import TopicSection from 'hello_baby/components/TopicSection'
import PlacementsInterstitialModal from 'components/modal/PlacementsInterstitialModal'
import { PLACEMENT_INTERSTITIAL_UNIT } from 'lib/placements/units'
import css from './HomeView.styles.scss'
import { SectionHeading } from '../../../../../components/headings'
import {
  useFeaturedPosts,
  useHomeTopics,
  usePosts,
  useTopicNavigation,
} from '../../utils/useQueries'

const HomeView: FC = () => {
  const posts = usePosts()
  const homeTopics = useHomeTopics()
  const featuredPosts = useFeaturedPosts()
  const topicNavigation = useTopicNavigation()

  const navCategory = { title: 'Home', path: '/hello-baby', urlSlug: null }
  const sideNavTree = topicNavigation?.items.map((topic: Topic) => ({
    title: topic.name,
    urlSlug: topic.slug,
    children: topic.children?.map((child: Topic) => ({
      title: child.name,
      urlSlug: child.slug,
      path: blUrls.helloBabyPostPath(child.slug),
    })),
    path: blUrls.helloBabyPostPath(topic.slug),
  }))

  if (!featuredPosts || !homeTopics || !posts || !topicNavigation) {
    return null // replace with loading state
  }

  return (
    <>
      <HelloBabyMeta
        canonical={blUrls.babylistUrl(blUrls.helloBabyPath)}
        keywords="baby product reviews, baby names, pregnancy tips, parenting advice, strollers, diapers, car seats, babies"
        seoDescription="Find advice, tips, and product reviews to help you handle pregnancy and early parenting. Hello Baby makes parenting easier."
        title="Hello Baby by Babylist - Pregnancy and Parenting Advice"
      />
      <div className="container mtm pts ptn-sm mtxl-sm">
        <div className="visible-xs">
          <SectionHeading className="h3 mvm">Featured Guides</SectionHeading>
          <hr className="mtm mbl" />
        </div>
        <HeroPost post={featuredPosts[0]} />
        <PostCardRow
          showTopics
          className="mtxl"
          columns={4}
          posts={featuredPosts.slice(1, 5)}
        />

        <Row className="mvl">
          <Col className={classNames(css.sideNavContainer, 'hidden-xs')} sm={3}>
            <SideNav
              categoryTree={sideNavTree}
              navCategory={navCategory}
              sidebarTitle="Guides"
            />
          </Col>
          <Col className="mtl" sm={9}>
            <div className={css.landingContainer}>
              {(homeTopics.items || [])
                .map((topic: Topic) => {
                  const topicPosts = filterPostsByTopicSlug(
                    posts,
                    topic.slug
                  ).slice(0, 5)
                  if (topicPosts && topicPosts.length) {
                    return (
                      <TopicSection
                        hideLastPostSm
                        key={`topic_section_${topic.slug}`}
                        posts={topicPosts}
                        showTopics={false}
                        topic={{ name: topic.name, slug: topic.slug }}
                      />
                    )
                  }

                  return null
                })
                .filter((topicSection: unknown) => topicSection)}
            </div>
          </Col>
        </Row>
      </div>
      <PlacementsInterstitialModal unitId={PLACEMENT_INTERSTITIAL_UNIT} />
    </>
  )
}

export default HomeView
