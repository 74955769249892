import React from 'react'
import MobileActions from './components/MobileActions/MobileActions'
import RegistryDetails from './components/RegistryDetails/RegistryDetails'
import css from './RegistryViewHeader.styles.scss'
import { RegistryViewHeaderProps } from './RegistryViewHeader.types'

const RegistryViewHeader: React.FC<RegistryViewHeaderProps> = ({
  currentRegistry,
}) => (
  <div className={css.RegistryViewHeader}>
    <div className={css.RegistryViewHeader__container}>
      <RegistryDetails currentRegistry={currentRegistry} />
      <MobileActions currentRegistry={currentRegistry} />
    </div>
  </div>
)

export default RegistryViewHeader
