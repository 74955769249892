import { useState } from 'react'
import { track, useTracking } from 'lib/analytics'
import { InfoButton } from 'components/buttons'
import Close from 'shared/svg/close.svg'
import { useForm } from 'react-hook-form'
import { RadioGroup, Radio, Input } from 'components/forms'
import CategoryChecklist from 'shared/svg/CategoryChecklist.svg'
import { Responses } from '../../global'
import css from './RegistryFeedbackModal.styles.scss'

interface RegistryFeedbackModalProps {
  closeModal: () => void
  eventLocation: string
  registry: Responses.Registry
}

const RegistryFeedbackModal = ({
  closeModal,
  eventLocation,
  registry,
}: RegistryFeedbackModalProps) => {
  const { trackEvent } = useTracking()
  const [submitting, setSubmitting] = useState(false)
  const { register, handleSubmit } = useForm()

  const handleCtaClick = (data: any) => {
    setSubmitting(true)
    trackEvent({
      event: track.feedbackModalSubmitted,
      eventLocation,
      featureName: 'registry_appearance',
      feedbackValue: data.reason,
      feedbackTextValue: data.freeText || 'None',
      registryId: registry.id,
      registryOwnerId: registry.ownerId,
    })
    closeModal()
  }

  const handleCloseModal = () => {
    trackEvent({
      event: track.feedbackModalDismissed,
      eventLocation,
      featureName: 'registry_appearance',
      registryId: registry.id,
      registryOwnerId: registry.ownerId,
    })
    closeModal()
  }

  trackEvent({
    event: track.feedbackModalViewed,
    eventLocation,
    featureName: 'registry_appearance',
    registryId: registry.id,
    registryOwnerId: registry.ownerId,
  })

  return (
    <div className={css.RegistryFeedbackModal}>
      <div className={css.RegistryFeedbackModal__contentWrapper}>
        <div className={css.RegistryFeedbackModal__checklistIconContainer}>
          <CategoryChecklist
            className={css.RegistryFeedbackModal__checklistIcon}
          />
        </div>
        <h4 className={css.RegistryFeedbackModal__questionTitle}>
          How satisfied are you with how your registry looks to your guests?
        </h4>
        <form onSubmit={handleSubmit(handleCtaClick)}>
          <RadioGroup className="mbl">
            <Radio
              inputRef={register}
              label="Extremely Satisfied"
              name="reason"
              value="Extremely Satisfied"
            />
            <Radio
              inputRef={register}
              label="Very Satisfied"
              name="reason"
              value="Very Satisfied"
            />
            <Radio
              inputRef={register}
              label="Moderately Satisfied"
              name="reason"
              value="Moderately Satisfied"
            />
            <Radio
              inputRef={register}
              label="Slightly Satisfied"
              name="reason"
              value="Slightly Satisfied"
            />
            <Radio
              inputRef={register}
              label="Not At All Satisfied"
              name="reason"
              value="Not At All Satisfied"
            />
          </RadioGroup>
          <Input
            className={css.RegistryFeedbackModal__freeText}
            inputRef={register}
            name="freeText"
            placeholder="Please describe why you gave this answer."
          />
          <InfoButton
            block
            className="mtxl"
            submitting={submitting}
            type="submit"
          >
            Submit
          </InfoButton>
        </form>

        <div className={css.RegistryFeedbackModal__details}>
          <button
            className={css.RegistryFeedbackModal__closeButton}
            data-testid="close-button"
            type="button"
            onClick={handleCloseModal}
          >
            <Close />
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegistryFeedbackModal
