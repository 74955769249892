import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import { useOpenCreateEditReservationModal } from '../../../../hooks/useOpenCreateEditReservationModal'
import RegItemBuyingOptionsModal from '../../RegItemBuyingOptionsModal'

export const getNoButtonHandler =
  (regItem: RegItem, registry: Registry) => () =>
    NiceModal.show(RegItemBuyingOptionsModal, {
      regItemId: regItem.id,
      registry,
    })

export const useGetYesButtonHandler = (
  regItem: RegItem,
  registry: Registry
) => {
  const modal = useModal()
  const openCreateEditReservationModal = useOpenCreateEditReservationModal({
    regItem,
    registry,
    initialStep: 2, // Setting initial step to 2 so we can skip the "Did you purchase this" modal
  })

  return () => {
    modal.hide()
    openCreateEditReservationModal()
  }
}
