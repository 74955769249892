import PriceTag from 'baby-design/components/PriceTag/PriceTag'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'

export const ProductPrice = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct?.price) return null

  const { msrp, currentPrice, currentlyInActiveSale, callouts } =
    getPriceTagPropsFromPriceDetails(genericProduct.activeProduct.priceDetails)
  return (
    <PriceTag
      callouts={callouts}
      currentPrice={currentPrice}
      currentlyInActiveSale={currentlyInActiveSale}
      msrp={msrp}
      size="md"
    />
  )
}
