import { ShipNowModalProps } from 'src/routes/(registry)/shipments/components/ShipNowModal/ShipNowModal.types'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ShipNowModalBody from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalBody/ShipNowModalBody'
import { MultiStepWizard } from 'components/presentation'
import { useState } from 'react'
import ShipNowModalSuccess from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalSuccess/ShipNowModalSuccess'
import { shipNowNewShipDate } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import { ShipmentsAPI } from 'src/routes/(registry)/shipments/api/shipments'
import { FLASH_MESSAGE } from 'shared/constants'
import { Button, Modal } from '../../../../../../baby-design'
import { useTrackShipNowConfirmedClick } from '../../hooks/useTrackClick'

const SHIP_NOW_CONFIRMATION_STATE = 1
const SHIP_NOW_SUCCESS_STATE = 2

export default NiceModal.create(({ shipment }: ShipNowModalProps) => {
  const modal = useModal()
  const [activeStep, setActiveStep] = useState<number>(
    SHIP_NOW_CONFIRMATION_STATE
  )
  const trackShipNowClick = useTrackShipNowConfirmedClick({ shipment })

  const { mutate: shipNow } = ShipmentsAPI.useShipNow()

  const shipNowButtonText = (): string => {
    if (activeStep === SHIP_NOW_SUCCESS_STATE) {
      return 'Back to shipments'
    }

    const date = shipNowNewShipDate(shipment)

    // fall back to `Ship Now` if no date found in additional data
    if (!date) return 'Ship now'

    const shipDate = Date.parse(date)
    const dateFormatOptions = {
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions

    const formattedDate = new Intl.DateTimeFormat(
      'en-US',
      dateFormatOptions
    ).format(new Date(shipDate))

    return `Ship on ${formattedDate}`
  }

  const handleClose = () => {
    modal.resolve(false)
    modal.remove()
  }

  const handlePrimaryCTAClicked = () => {
    if (activeStep === SHIP_NOW_CONFIRMATION_STATE) {
      trackShipNowClick()

      shipNow(shipment, {
        onSuccess: (_data, _variables, _context) => {
          setActiveStep(SHIP_NOW_SUCCESS_STATE)
        },
        onError: (_error, _variables, _context) => {
          PubSub.publish(FLASH_MESSAGE, {
            message: 'We could not ship your bundle now.',
            variant: 'danger',
          })
          handleClose()
        },
      })
    } else {
      modal.resolve(true)
      modal.remove()
    }
  }

  const shipNowButton = (
    <Button size="md" variant="primary" onClick={handlePrimaryCTAClicked}>
      {shipNowButtonText()}
    </Button>
  )

  const dismissButton = activeStep === SHIP_NOW_CONFIRMATION_STATE && (
    <Button size="md" variant="secondary" onClick={handleClose}>
      Nevermind, I'll wait
    </Button>
  )

  return (
    <Modal
      showCloseButton
      handleClose={handleClose}
      isOpen={modal.visible}
      primaryButton={shipNowButton}
      secondaryButton={dismissButton}
    >
      <MultiStepWizard activeStep={activeStep} animateInitialState={false}>
        {/* Step 1 */}
        <ShipNowModalBody shipment={shipment} />
        {/* Step 2 */}
        <ShipNowModalSuccess />
      </MultiStepWizard>
    </Modal>
  )
})
