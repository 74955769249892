import { numberToCurrency } from 'lib/money'
import { map } from 'lodash'

export const PRODUCT_DATA_GLOBAL_NAME = 'blProductDataForScraper'

export const setProductDataForBookmarklet = (product) => {
  window[PRODUCT_DATA_GLOBAL_NAME] = productDataForBookmarklet(product)
}

export const unsetProductDataForBookmarklet = () => {
  window[PRODUCT_DATA_GLOBAL_NAME] = null
}

export const productDataForBookmarklet = (product) => {
  return {
    title: product.name,
    price:
      product.price &&
      product.price.current &&
      numberToCurrency(product.price.current),
    images: map(product.images, 'url'),
  }
}
