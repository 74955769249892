import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { VariantSelector as Component } from './VariantSelector'

export const VariantSelector = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { activeProduct, attributes, productAttributeMap } = genericProduct
  return (
    <Component
      attributes={attributes}
      productAttributeMap={productAttributeMap}
      selectedAttributes={activeProduct?.attributes}
    />
  )
}
