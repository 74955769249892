import { Helmet } from 'react-helmet-async'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import useInjectScript from 'src/hooks/useInjectScript/useInjectScript'
import { Registry } from 'src/types/registry'

interface RegistryGiftGiverPageSeoProps {
  slug: string | undefined
  registry: Registry | undefined | Record<string, never>
}

const INJECT_SCRIPT_HELP_CENTER_WIDGET_SET_SUGGESTIONS =
  'help-center-web-widget-set-suggestions'
const INJECT_SCRIPT_REGISTRY_GIFT_GIVER_PAGE_GA = 'registry-gift-giver-page-ga'

const WISHLIST_REGISTRY_OG_IMAGE_HREF =
  'https://images.babylist.com/image/upload/f_auto,q_auto/v1690916817/babylist-wishlist-social-share_acrzjy.jpg'
const BABY_REGISTRY_OG_IMAGE_HREF =
  'https://images.babylist.com/image/upload/v1722439886/Digital_Registry_Card_Update_ng0enc.jpg'

const RegistryGiftGiverPageSeo = ({
  slug,
  registry,
}: RegistryGiftGiverPageSeoProps) => {
  const [currentUser] = useCurrentUser()
  const isCurrentUserRegistryContributor =
    currentUser && currentUser.registries?.some((reg) => reg.urlSlug === slug)

  let injectGaScript = ''
  if (isCurrentUserRegistryContributor) {
    injectGaScript = "ga('set', 'contentGroup3', 'Registry - Owner')"
  } else {
    injectGaScript =
      "ga('set', 'dimension2', '1'); ga('set', 'contentGroup3', 'Registry - Visitor');"
  }
  useInjectScript({
    name: INJECT_SCRIPT_REGISTRY_GIFT_GIVER_PAGE_GA,
    code: injectGaScript,
    location: 'footer',
    shouldInject: registry && registry.urlSlug !== 'example',
    removeOnUnmount: true,
  })

  useInjectScript({
    name: INJECT_SCRIPT_HELP_CENTER_WIDGET_SET_SUGGESTIONS,
    code: `if (window.zE) zE('webWidget', 'helpCenter:setSuggestions', { labels: ["'widget-registry'"] });`,
    location: 'footer',
    removeOnUnmount: true,
  })

  if (!registry || !registry.id) return null

  return (
    <Helmet prioritizeSeoTags>
      <title>{`${registry.seoTitle} at Babylist`}</title>
      <meta content={registry.title} property="og:title" />
      <link href={registry.canonicalUrl} rel="canonical" />

      {registry.isIndexableBySearchEngines && (
        <meta
          content={`Visit ${registry.seoTitle} on Babylist. To raise a child it takes a village. Thanks for being part of ours!`}
          name="description"
        />
      )}
      {!registry.isIndexableBySearchEngines && (
        <meta content="noindex" name="robots" />
      )}

      <meta content="630" property="og:image:height" />
      <meta content="1376" property="og:image:width" />
      <meta
        content={
          registry.type
            ? WISHLIST_REGISTRY_OG_IMAGE_HREF
            : BABY_REGISTRY_OG_IMAGE_HREF
        }
        property="og:image"
      />
    </Helmet>
  )
}

export default RegistryGiftGiverPageSeo
