import { useDispatch } from 'react-redux'
import { RegItem, RegItemMinimal } from 'src/types/regItem'
import { RegItemCategory } from 'src/types/regItemCategory'
import { Reservation } from 'src/types/reservation'
import {
  setReservationsByCurrentVisitor,
  setRegItems,
} from 'registry/actions/reg-items-actions'
import { AppliedSortsAndFilters } from '../RegistryGiftGiverPage/RegistryGiftGiverPage.types'
import {
  GetCategorizedRegItemsParams,
  RegItemCategoryGroup,
  ReservationWithVisitor,
} from './RegistryCategories.types'

export const MOST_WANTED_FAKE_CATEGORY_ID = Number.MIN_SAFE_INTEGER
export const MOST_WANTED_TITLE = 'Most wanted'

export const getCategorizedRegItems = ({
  categories = [],
  regItems = [],
  appliedSortsAndFilters,
}: GetCategorizedRegItemsParams): RegItemCategoryGroup[] => {
  const regItemsByCategoryId: Record<string, RegItem[]> =
    groupRegItemsByCategory(regItems, appliedSortsAndFilters)

  const categoriesWithFakeCategory = insertFakeCategories(categories)

  const sortedCategories = sortCategories(categoriesWithFakeCategory)

  return getRegItemCategoryGroups(
    sortedCategories,
    regItemsByCategoryId,
    appliedSortsAndFilters
  )
}

const insertFakeCategories = (categories: RegItemCategory[]) => [
  {
    id: MOST_WANTED_FAKE_CATEGORY_ID,
    title: MOST_WANTED_TITLE,
    position: MOST_WANTED_FAKE_CATEGORY_ID,
  },
  ...categories,
]

const groupRegItemsByCategory = (
  regItems: RegItemMinimal[],
  appliedSortsAndFilters: AppliedSortsAndFilters
) => {
  const categoryFilter = appliedSortsAndFilters.filters?.category
  const shouldIncludeMostWantedFakeCategory =
    categoryFilter === 'All' || categoryFilter === MOST_WANTED_TITLE

  // TODO: Remove ts-ignore once we are on newer version of TS (v5.4) which recognizes groupBy
  // @ts-ignore
  const regItemsByCategoryId = Object.groupBy(
    regItems,
    (regItem: RegItem) => regItem.categoryId
  )

  const regItemsByCategoryIncludingMostWanted = {
    ...regItemsByCategoryId,
    [MOST_WANTED_FAKE_CATEGORY_ID]: regItems.filter((regItem) =>
      shouldIncludeMostWantedFakeCategory ? regItem.isFavorite : false
    ),
  }

  return regItemsByCategoryIncludingMostWanted
}

const sortCategories = (categories: RegItemCategory[]) =>
  categories.sort((a, b) => a.position - b.position)

const getRegItemCategoryGroups = (
  sortedCategories: RegItemCategory[],
  regItemsByCategoryId: Record<string, RegItem[]>,
  appliedSortsAndFilters: AppliedSortsAndFilters
) =>
  sortedCategories
    .map((category) => ({
      category,
      items: regItemsByCategoryId[category.id || 0] || [],
    }))
    .filter(({ category, items }) => {
      if (items.length < 1) return false

      // If we are filtering by "Most wanted" category, we only want to show the fake category,
      // and not the actual categories the "most wanted" items belong to.
      if (
        appliedSortsAndFilters.filters?.category === MOST_WANTED_TITLE &&
        category.id !== MOST_WANTED_FAKE_CATEGORY_ID
      ) {
        return false
      }

      return true
    })

export const mergeVisitorReservationsWithReservations = (
  reservations: Reservation[],
  visitorReservations: Reservation[]
): ReservationWithVisitor[] => {
  // TODO: Remove ts-ignore once we are on newer version of TS (v5.4) which recognizes groupBy
  // @ts-ignore
  const visitorReservationsById = Object.groupBy(
    visitorReservations,
    (r: Reservation) => r.id
  )

  return reservations
    .map((reservation) => {
      const visitorReservation = (visitorReservationsById[reservation.id] ??
        [])[0]

      return {
        ...(visitorReservation || reservation),
        isVisitorReservation: !!visitorReservation,
        regItem: reservation.regItem,
      }
    })
    .sort(
      (a, b) =>
        (a.isVisitorReservation ? 0 : 1) - (b.isVisitorReservation ? 0 : 1)
    )
}

export const useGetReduxSyncClosure = () => {
  const dispatch = useDispatch()

  return (reservations: ReservationWithVisitor[]) => {
    const formattedReservations: { [key: number]: Reservation } = {}
    const regItems: { [key: number]: RegItem } = {}
    reservations.forEach((reservation) => {
      if (reservation.regItem && reservation.isVisitorReservation) {
        formattedReservations[reservation.regItem.id] = reservation
        regItems[reservation.regItem.id] = reservation.regItem
      }
    })
    dispatch(setReservationsByCurrentVisitor(formattedReservations))
    dispatch(setRegItems(regItems))
  }
}
