import NiceModal from '@ebay/nice-modal-react'
import { track, withContextualizedTracking } from 'lib/analytics'
import { DD_RUM_VIEW_NAME } from 'src/constants'
import useSetDatadogRumViewName from 'src/hooks/useSetDatadogRumViewName/useSetDatadogRumViewName'
import RegistryGiftGiverPage from './components/RegistryGiftGiverPage/RegistryGiftGiverPage'
import {
  AppliedSortsAndFiltersContextProvider,
  ShippingAddressButtonContextProvider,
} from './contexts'

const RegistryGiftGiverPageWrapper = () => {
  useSetDatadogRumViewName(DD_RUM_VIEW_NAME.RegistryGiftGiver)

  return (
    <AppliedSortsAndFiltersContextProvider>
      <ShippingAddressButtonContextProvider>
        <NiceModal.Provider>
          <RegistryGiftGiverPage />
        </NiceModal.Provider>
      </ShippingAddressButtonContextProvider>
    </AppliedSortsAndFiltersContextProvider>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY_MINUS_GIFT_GIVER_VIEW,
})(RegistryGiftGiverPageWrapper)
