import RegistryFactory from 'tests/factories/registry'
import { ExtendedRegistry } from '../RegistryViewHeader.types'

export const MOCKED_CURRENT_REGISTRY: ExtendedRegistry = RegistryFactory.build({
  id: 217,
  addressId: 'ABCD1234',
  address: {
    city: 'New York',
    country: 'US',
    id: 1234,
    avsConfirmed: false,
    userConfirmed: false,
    name: 'Home',
    needsConfirmation: false,
    phone: '2128675309',
    state: 'NY',
    streetAddress1: '123 Main St',
    streetAddress2: '1F',
    taxRate: 0.08875,
    validationMetadata: '1234567890',
    zip: '11111',
    privateAddress: 'true',
  },
  canonicalUrl: 'https://example.com/canonical_url',
  categories: [],
  currentUserRole: 'owner' as const,
  description: 'This is a babylist' as string,
  headerDetails: {
    arrivalDateDisplay: 'December 16, 2020',
    avatarEmptyStateText: 'TF',
    avatarImageUrl: 'https://example.com/avatar.jpg',
    headerImageUrl: 'https://example.com/header.jpg',
    location: 'New York, NY',
    names: 'Teddy Fraczak',
  },
  isEligibleForGiftGiverReminders: false,
  isIndexableBySearchEngines: true,
  isShippingAddressPrivate: false,
  searchLevel: 'babylist' as const,
  seoTitle: 'Jim and Jills babylist',
  ownerId: 19,
  photoUrl: 'https://example.com/photo.jpg',
  reservationCount: 1337,
  regItemsCount: 9001,
  shippingAddress: {
    privateAddress: false,
  },
  shippingConsolidation: {
    consolidationChoice: null,
  },
  title: 'Another babylist',
  type: undefined,
  url: 'https://babylist.com/list/baby-registry-fraczak',
  giftGiverUrl: 'https://babylist.com/baby-registry-fraczak',
  urlSlug: 'baby-registry-fraczak',
  visitorPassword: 'password',
})

export const MOCKED_CURRENT_USER = {
  id: 19,
  currentRegistry: MOCKED_CURRENT_REGISTRY,
  family: {
    arrivalDate: null,
  },
  firstName: 'Teddy',
}
