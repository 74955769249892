import { RegItemMinimal } from 'src/types/regItem'

import {
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_EXTERNAL_REGISTRY,
  REG_ITEM_TYPE_FAVOR,
  REG_ITEM_TYPE_GIFT_CARD,
  REG_ITEM_TYPE_GROUP_GIFT,
  REG_ITEM_TYPE_PRODUCT,
} from 'src/constants'
import getRegItemType from '../../../../utils/getRegItemType/getRegItemType'
import {
  CashFundDetails,
  ExternalRegistryDetails,
  GiftCardDetails,
  GroupGiftDetails,
  HelpAndFavorDetails,
  ProductDetails,
  ReservedDetails,
} from './variants'
import { ReservationWithVisitor } from '../../../RegistryCategories/RegistryCategories.types'

export const getRegItemDetailsComponent = (
  regItem: RegItemMinimal,
  reservation?: ReservationWithVisitor
) => {
  if (reservation) {
    return ReservedDetails
  }

  const regItemType = getRegItemType(regItem)

  switch (regItemType) {
    case REG_ITEM_TYPE_CROWDFUND:
      return CashFundDetails
    case REG_ITEM_TYPE_GIFT_CARD:
      return GiftCardDetails
    case REG_ITEM_TYPE_EXTERNAL_REGISTRY:
      return ExternalRegistryDetails
    case REG_ITEM_TYPE_FAVOR:
      return HelpAndFavorDetails
    case REG_ITEM_TYPE_GROUP_GIFT:
      return GroupGiftDetails
    case REG_ITEM_TYPE_PRODUCT:
      return ProductDetails
    default:
      return ProductDetails
  }
}

export const isRegItemOutOfStock = (regItem: RegItemMinimal) =>
  regItem.offers?.length === 1 &&
  regItem.offers[0].storeDisplayName === 'Babylist' &&
  !regItem.isAvailableOnBabylist
