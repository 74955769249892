import React from 'react'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SurpriseGift } from 'baby-design/icons'
import { EmptyAvatarProps } from './EmptyAvatar.types'
import styles from './EmptyAvatar.styles.scss'

const EmptyAvatar: React.FC<EmptyAvatarProps> = ({
  avatarEmptyStateText,
  className,
  isWishlist = false,
}) => {
  const emptyAvatarClasses = classNames(styles.EmptyAvatar, className)
  return (
    <div className={emptyAvatarClasses} data-testid="EmptyAvatar">
      <div className={styles.empty_plate}>
        {isWishlist ? (
          <div
            className={styles.icon_container}
            data-testid="surprise-gift-icon"
          >
            <SurpriseGift className={styles.wishlistIcon} />
          </div>
        ) : (
          <h1 className={styles.text}>{avatarEmptyStateText}</h1>
        )}
      </div>
    </div>
  )
}

export default EmptyAvatar
