import { useEffect, useRef, useState } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'

import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import RegistryContext, { RegistryContextType } from 'src/contexts/registry'
import {
  FETCH_REG_ITEMS_KEY,
  FETCH_REG_ITEMS_PAGE_SIZE,
  FETCH_REGISTRY_KEY,
  fetchRegistry,
  fetchRegItems,
  updateRegItem,
} from 'src/api/queries'
import { RegItemCategory } from 'src/types/regItemCategory'
import { track, useTracking, withContextualizedTracking } from 'lib/analytics'
import { useDisplayRegistryOrganizationFeedbackModal } from 'shared/RegistryOrganizationFeedbackModal/RegistryOrganizationFeedbackModal.utils'
import {
  hydrateReservationsCache,
  optimisticallyUpdateRegItems,
} from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'
import { RegItem } from 'src/types/regItem'
import RegistryLayout from '../../components/RegistryLayout/RegistryLayout'
import RegistryViewHeader from './components/RegistryViewHeader/RegistryViewHeader'
import RegistryItemsList from './components/RegistryItemsList/RegistryItemsList'
import {
  emptyFilter,
  RegItemFilters,
} from './components/RegItemFilterDrawer/RegItemFilterDrawer'
import RegistryItemsListHeader from './components/RegistryItemsListHeader/RegistryItemsListHeader'
import { RegItemsByCategory } from './components/RegistryItemsList/RegistryItemsList.types'
import {
  getAppliedFilterCount,
  getMoveRegItem,
  openRegItemFilterDrawer,
  setupRegistryFilters,
  setupRegistryListData,
} from './utils/routeHelpers'
import RegItemFilterChips from './components/RegItemFilterChips/RegItemFilterChips'

const RegistryPage = () => {
  const queryClient = useQueryClient()
  const [currentUser] = useCurrentUser()
  const [regItemFilters, setRegItemFilters] =
    useState<RegItemFilters>(emptyFilter)
  const tracker = useTracking()
  const [activeCategory, setActiveCategory] = useState({
    value: undefined,
    label: undefined,
  } as RegistryContextType['activeCategory'])
  const registryId = currentUser?.currentRegistry?.id
  const [regItemsByCategory, setRegItemsByCategory] =
    useState<RegItemsByCategory>({})
  const [categoriesWithItems, setCategoriesWithItems] = useState<
    RegItemCategory[]
  >([])
  const [regItemsCount, setRegItemsCount] = useState(0)
  const [noItemsOnRegistry, setNoItemsOnRegistry] = useState(false)
  const [noItemsMatchFilters, setNoItemsMatchFilters] = useState(false)
  const [isLoadingRegItems, setIsLoadingRegItems] = useState(true)
  const drawerFilterCountRef = useRef(0)

  const registryQuery = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_REGISTRY_KEY, registryId],
    queryFn: () => fetchRegistry(registryId),
  })

  const regItemsQuery = useInfiniteQuery({
    queryKey: [FETCH_REG_ITEMS_KEY, registryId],
    enabled: !!registryId,
    initialPageParam: 1,
    staleTime: 0,
    queryFn: async ({ pageParam }: { pageParam: any }) =>
      fetchRegItems(registryId, pageParam, FETCH_REG_ITEMS_PAGE_SIZE).then(
        (response) => {
          hydrateReservationsCache(queryClient, response)
          return response
        }
      ),
    getNextPageParam: (lastPage: any, allPages: any, _lastPageParam: any) => {
      if (Object.keys(lastPage.regItems).length === FETCH_REG_ITEMS_PAGE_SIZE) {
        return allPages.length + 1
      }

      return undefined
    },
  } as any)

  const mutateRegItem: ReturnType<typeof useMutation> = useMutation({
    mutationFn: async (regItem: any) => updateRegItem(regItem),
    onSuccess: () => {
      queryClient.invalidateQueries([FETCH_REG_ITEMS_KEY, registryId])
    },
  })

  // Fetch next page of regItems if there are more to fetch
  useEffect(() => {
    if (!regItemsQuery.isFetching && regItemsQuery.hasNextPage) {
      regItemsQuery.fetchNextPage()
      setIsLoadingRegItems(true)
    } else if (!regItemsQuery.isFetching && !regItemsQuery.hasNextPage) {
      setIsLoadingRegItems(false)
    }
  }, [regItemsQuery, regItemsQuery.isFetching, regItemsQuery.hasNextPage])

  // Parse and prepare reg items into categorized lists
  useEffect(() => {
    setupRegistryListData(
      regItemsQuery,
      registryQuery,
      regItemFilters,
      setRegItemsByCategory,
      setCategoriesWithItems,
      setRegItemsCount
    )
  }, [
    regItemsQuery.data,
    regItemsQuery.data?.pages,
    regItemsQuery.isSuccess,
    registryQuery.data,
    registryQuery.isSuccess,
    regItemFilters,
  ])

  // Sets the store filters based on the stores that are available in the regItems
  useEffect(() => {
    setupRegistryFilters(regItemsQuery, regItemFilters, setRegItemFilters)
  }, [regItemsQuery.data])

  // Sets UI flags for no items on registry or no items match filters
  useEffect(() => {
    if (!registryQuery.isSuccess || !regItemsQuery.isSuccess) return
    setNoItemsOnRegistry(regItemsQuery.isSuccess && regItemsCount === 0)
    setNoItemsMatchFilters(
      regItemsCount > 0 && Object.keys(regItemsByCategory).length === 0
    )
  }, [
    regItemsCount,
    regItemsQuery.isSuccess,
    regItemsByCategory,
    registryQuery.isSuccess,
  ])

  // Set the active category to the first category if it hasn't been set yet
  useEffect(() => {
    if (
      categoriesWithItems.length &&
      categoriesWithItems[0].id &&
      activeCategory.value === undefined
    ) {
      setActiveCategory({
        value: categoriesWithItems[0].id,
        label: categoriesWithItems[0].title,
      })
    }
  }, [categoriesWithItems, activeCategory])

  if (registryQuery.isError) {
    console.error(registryQuery.error)
  }

  useDisplayRegistryOrganizationFeedbackModal(registryQuery.data, regItemsCount)

  let sideBarCategories = categoriesWithItems
  // If regItems are still loading, use the populated categories from the registry query
  if (isLoadingRegItems && registryQuery.data?.categories) {
    sideBarCategories = registryQuery.data?.categories.filter(
      (category: RegItemCategory) => !category.isEmpty
    )
  }

  const handleCategoryUpdated = (category: RegItemCategory) => {
    if (registryId && category.isPrivate) {
      const privateItems = (regItemsByCategory[category.title] || []).filter(
        (item) => item.private
      )

      if (privateItems.length > 0) {
        optimisticallyUpdateRegItems({
          queryClient,
          updatedRegItems: privateItems.map((item: RegItem) => ({
            ...item,
            private: false,
          })),
          registryId,
        })
      }
    }
  }

  const defaultContext: RegistryContextType = {
    activeCategory,
    handleCategoryUpdated,
    setActiveCategory,
    registryId,
    registryOwnerId: registryQuery.data?.ownerId,
    isRegistryOwner: registryQuery.data?.ownerId === currentUser?.id,
  } as RegistryContextType

  const registry =
    registryQuery.data && Object.keys(registryQuery.data).length === 1
      ? registryQuery.data.registry
      : registryQuery.data

  const filteredRegItemCount = Object.keys(regItemsByCategory).reduce(
    (acc, key) => acc + regItemsByCategory[key].length,
    0
  )
  drawerFilterCountRef.current = filteredRegItemCount

  const appliedFilterCount = getAppliedFilterCount(regItemFilters)

  const handleOpenRegItemFilterDrawer = openRegItemFilterDrawer(
    registry,
    regItemFilters,
    setRegItemFilters,
    drawerFilterCountRef,
    tracker,
    track
  )

  return (
    <RegistryContext.Provider value={defaultContext}>
      <NiceModal.Provider>
        <RegistryLayout
          categories={sideBarCategories}
          currentRegistry={registry}
        >
          <RegistryViewHeader currentRegistry={registry} />
          <RegistryItemsListHeader
            appliedFilterCount={appliedFilterCount}
            openRegItemFilterDrawer={handleOpenRegItemFilterDrawer}
            regItemsCount={
              isLoadingRegItems && registry
                ? registry.regItemsCount
                : filteredRegItemCount
            }
            regItemsHaveLoaded={
              regItemsQuery.isSuccess && registryQuery.isSuccess
            }
          />
          <RegItemFilterChips
            regItemFilters={regItemFilters}
            setRegItemFilters={setRegItemFilters}
          />
          <RegistryItemsList
            categories={registryQuery?.data?.categories}
            categoriesWithItems={categoriesWithItems}
            isLoadingRegItems={isLoadingRegItems}
            listType={registry?.type}
            moveRegItem={getMoveRegItem(
              regItemsByCategory,
              registryQuery?.data,
              setRegItemsByCategory,
              setCategoriesWithItems
            )}
            mutateRegItem={mutateRegItem}
            noItemsMatchFilters={noItemsMatchFilters}
            noItemsOnRegistry={noItemsOnRegistry}
            openRegItemFilterDrawer={handleOpenRegItemFilterDrawer}
            regItemsByCategory={regItemsByCategory}
          />
        </RegistryLayout>
      </NiceModal.Provider>
    </RegistryContext.Provider>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY,
})(RegistryPage)
