import { useContext } from 'react'
import { NavLink, RightDrawer, IconButton } from 'baby-design'
// @ts-ignore
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import {
  Check,
  DragHandle,
  EditPencil,
  GarbageBin,
  Exclamation,
} from 'baby-design/icons'
import { RegItemCategory } from 'src/types/regItemCategory'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  FETCH_REGISTRY_KEY,
  deleteCategory,
  updateCategory,
} from 'src/api/queries'
import RegistryContext from 'src/contexts/registry'
import { successMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Registry } from 'src/types/registry'
import { useRegistry } from '../../utils/useQueries'
import css from './CategoryOrganizeDrawer.styles.scss'
import AddUpdateCategoryDrawer from '../AddUpdateCategoryDrawer/AddUpdateCategoryDrawer'
import ConfirmDeleteCategoryModal from '../ConfirmCategoryDeleteModal/ConfirmCategoryDeleteModal'
import DeleteCategoryInfoModal from '../DeleteCategoryInfoModal/DeleteCategoryInfoModal'

interface CategoryOrganizeDrawerProps {
  registry: Registry
}

function CategoryOrganizeDrawer({ registry }: CategoryOrganizeDrawerProps) {
  const modal = useModal()
  const queryClient = useQueryClient()
  const { setActiveCategory } = useContext(RegistryContext)

  const mutateCategory = useMutation({
    mutationFn: async (category: RegItemCategory) => {
      setActiveCategory({
        value: undefined,
        label: undefined,
      })
      return await updateCategory(registry.id, category)
    },
    onSuccess: async () => {
      successMessage(`Categories successfully updated!`)
      await queryClient.invalidateQueries([FETCH_REGISTRY_KEY, registry.id])
      setActiveCategory({
        value: registry.categories[0].id,
        label: registry.categories[0].title,
      })
    },
  })

  const removeCategory = useMutation({
    mutationFn: async (category: RegItemCategory) => {
      setActiveCategory({
        value: undefined,
        label: undefined,
      })

      return await deleteCategory(registry.id, category.id as number)
    },
    onSuccess: async () => {
      successMessage('Category deleted successfully!')
      await queryClient.invalidateQueries([FETCH_REGISTRY_KEY, registry.id])
      setActiveCategory({
        value: registry.categories[0].id,
        label: registry.categories[0].title,
      })
    },
  })

  const onSaveClick = (category: RegItemCategory) => {
    if (category) mutateCategory.mutate(category)
  }

  const onEditClick = (category: RegItemCategory) => {
    NiceModal.show(AddUpdateCategoryDrawer, { category })
  }

  const onDeleteClick = async (category: RegItemCategory) => {
    const shouldDeleteCategory = await NiceModal.show(
      ConfirmDeleteCategoryModal
    )

    if (!shouldDeleteCategory) return

    queryClient.setQueryData([FETCH_REGISTRY_KEY, registry.id], {
      ...registry,
      categories: registry.categories.filter(
        (c: RegItemCategory) => c.id !== category.id
      ),
    })
    removeCategory.mutate({ ...category })
  }

  const onDeleteInfoClick = async (category: RegItemCategory) => {
    NiceModal.show(DeleteCategoryInfoModal, { categoryName: category.title })
  }

  const SaveButton = (category: RegItemCategory) => (
    <IconButton
      aria-label="Edit"
      size="sm"
      variant="fill-secondary"
      onClick={() => onSaveClick(category)}
    >
      <Check />
    </IconButton>
  )

  const EditButton = (category: RegItemCategory) => (
    <IconButton
      aria-label="Edit"
      size="sm"
      variant="fill-secondary"
      onClick={() => onEditClick(category)}
    >
      <EditPencil />
    </IconButton>
  )

  const DeleteButton = (category: RegItemCategory) => (
    <IconButton
      aria-label="Delete"
      size="sm"
      variant="fill-secondary"
      onClick={() => onDeleteClick(category)}
    >
      <GarbageBin />
    </IconButton>
  )

  const DeleteInfoButton = (category: RegItemCategory) => (
    <IconButton
      aria-label="Delete"
      size="sm"
      variant="fill-secondary"
      onClick={() => onDeleteInfoClick(category)}
    >
      <GarbageBin />
      <Exclamation filled className={css.DeleteInfoIcon} />
    </IconButton>
  )

  const handleClose = () => {
    modal.hide()
  }

  const handleAddCategoryClick = () => {
    NiceModal.show(AddUpdateCategoryDrawer)
  }

  const onDragEnd = (event: any) => {
    if (!event.destination) {
      return
    }

    if (event.source.index === event.destination.index) {
      return
    }

    const sourceIndex = event.source.index
    const destinationIndex = event.destination.index

    const newCategories = [...registry.categories]
    const [removed] = newCategories.splice(sourceIndex, 1)
    removed.position = destinationIndex
    newCategories.splice(destinationIndex, 0, removed)
    queryClient.setQueryData([FETCH_REGISTRY_KEY, registry.id], {
      ...registry,
      categories: newCategories,
    })
    mutateCategory.mutate({ ...removed, position: destinationIndex })
  }

  if (registry.categories.length === 0) {
    return <></>
  }

  return (
    <RightDrawer
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title="Edit Categories"
    >
      <div className={css.CategoryOrganizeDrawer}>
        <div className={css.CategoryOrganizeDrawer__header}>
          <div className={css.CategoryOrganizeDrawer__headerTitle}>
            Categories
          </div>
          <NavLink
            size="md"
            variant="eggplant"
            onClick={handleAddCategoryClick}
          >
            Add
          </NavLink>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="category-organize">
            {(provided: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {registry.categories?.map(
                  (item: RegItemCategory, index: number) => (
                    <Draggable
                      draggableId={`category-${item.id}`}
                      index={index}
                      key={item.id}
                    >
                      {(provided: any, snapshot: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            borderBottom: snapshot.isDragging
                              ? '0px'
                              : '1px solid #F5F5F5',
                            boxShadow: snapshot.isDragging
                              ? '0 24px 48px -8px #4946471f, 0 2px 4px 0 #4946470a' // elevation-md
                              : '',
                          }}
                        >
                          <div className={css.CategoryOrganizeDrawer__item}>
                            <div
                              className={css.CategoryOrganizeDrawer__itemTitle}
                            >
                              <DragHandle />
                              <span>{item.title}</span>
                            </div>
                            <div
                              className={
                                css.CategoryOrganizeDrawer__itemActions
                              }
                            >
                              {item.isEditing && SaveButton(item)}
                              {item.isEditing ? null : EditButton(item)}
                              {item.isEditing || !item.isDeletable
                                ? DeleteInfoButton(item)
                                : DeleteButton(item)}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </RightDrawer>
  )
}

export default NiceModal.create(() => {
  const registry = useRegistry()

  if (!registry) {
    return <></>
  }

  return <CategoryOrganizeDrawer registry={registry} />
})
