import { Col, Row } from 'react-bootstrap'
import { chunk, map } from 'lodash'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import { StyledSubHeading } from 'components/headings'
import { Link } from 'react-router-dom-v5-compat'
import { helloBabyPostPath } from 'lib/urls'
import classNames from 'classnames'
import css from './GearView.styles.scss'

const NUM_COLUMNS = 2
const HEADER_ICONS: Record<string, string> = {
  strollers:
    '//images.babylist.com/image/upload/v1529687758/gear-icons/stroller.svg',
  'car-seat':
    '//images.babylist.com/image/upload/v1529687797/gear-icons/car.svg',
  carriers:
    '//images.babylist.com/image/upload/v1529687920/gear-icons/carrier.svg',
  'feeding-gear':
    '//images.babylist.com/image/upload/v1529687903/gear-icons/bottle.svg',
  'sleep-gear':
    '//images.babylist.com/image/upload/v1529687166/gear-icons/moon.svg',
  diapering:
    '//images.babylist.com/image/upload/v1529687901/gear-icons/diaper.svg',
  'baby-clothes':
    '//images.babylist.com/image/upload/v1529687899/gear-icons/clothes.svg',
  playroom:
    '//images.babylist.com/image/upload/v1529687897/gear-icons/rattle.svg',
  seasonal: '//images.babylist.com/image/upload/v1529687895/gear-icons/sun.svg',
  toys: '//images.babylist.com/image/upload/v1529687166/gear-icons/train.svg',
  nursery:
    '//images.babylist.com/image/upload/v1529687166/gear-icons/crib_new.svg',
  bathtime:
    '//images.babylist.com/image/upload/v1529687166/gear-icons/duck.svg',
  sponsored:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/sponsored.png',
  childproofing:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/childproof-outlet.png',
  holiday:
    '//images.babylist.com/image/upload/v1607649367/gear-icons/holiday-bow.png',
}

interface GearViewProps {
  posts: Post[]
  subTopics: Topic[]
}

const GearView = ({ posts, subTopics }: GearViewProps) => (
  <>
    {map(chunk(subTopics, NUM_COLUMNS), (subTopicsRow) => (
      <Row>
        {map(subTopicsRow, (subTopic) => (
          <Col
            className={css.postColumn}
            key={subTopic.slug}
            sm={12 / NUM_COLUMNS}
          >
            <StyledSubHeading className="h4 text-bold mtn mbm">
              <span
                className={classNames(css.headerIcon, 'display-inline')}
                style={{
                  backgroundImage: `url(${HEADER_ICONS[subTopic.slug]})`,
                }}
              />
              {subTopic.name}
            </StyledSubHeading>
            <ul className="list-unstyled">
              {filterPostsByTopicSlug(posts, subTopic.slug)
                .slice(0, 5)
                .map((post) => (
                  <li className="small mvm" key={post.id}>
                    <Link to={helloBabyPostPath(post.slug)}>{post.title}</Link>
                  </li>
                ))}
            </ul>
            <Link
              className="h7 text-uppercase text-bold"
              key="view-more"
              to={helloBabyPostPath(subTopic.slug)}
            >
              View All
            </Link>
          </Col>
        ))}
      </Row>
    ))}
  </>
)

export default GearView
