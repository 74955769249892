import { OfferList as LegacyOfferList } from 'components/offer-list/offer-list'
import { useParams } from 'react-router-dom-v5-compat'
import css from 'store/components/product-details-page/product-details-page.scss'
import { ProductsAPI } from '../../api/products'

export const OfferList = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { activeProduct } = genericProduct

  if (
    activeProduct?.availableOnBabylist ||
    !activeProduct?.otherBuyingOptions ||
    activeProduct.otherBuyingOptions.length < 1
  )
    return null

  return (
    <LegacyOfferList
      isContributorView
      className={css.offersContainer}
      limit={3}
      offers={activeProduct.otherBuyingOptions}
    />
  )
}
