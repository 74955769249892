import React from 'react'
import { BirthdayWishlistHeaderLayoutProps } from './BirthdayWishlistHeaderLayout.types'
import { WishlistHeaderLayout } from 'registry/components/GuestRegistryHeader/WishlistHeaderLayout'
import useMaxWishlistBirthdate from 'registry/modules/registry-builder/hooks/useMaxWishlistBirthdate'
import { ageNextBirthday } from 'lib/date'

const BirthdayWishlistHeaderLayout: React.FC<
  BirthdayWishlistHeaderLayoutProps
> = ({ registry }) => {
  const { allowFutureBirthdates } = useMaxWishlistBirthdate()
  const {
    birthdate = '',
    clothingSize = [],
    shoeSize = [],
    favoriteColor = [],
  } = registry.attributeData || {}

  let nextAge = ageNextBirthday(birthdate)
  if (allowFutureBirthdates) {
    nextAge = Math.max(nextAge, 1)
  }

  return (
    <WishlistHeaderLayout
      registry={registry}
      stats={{ nextAge, clothingSize, shoeSize, favoriteColor }}
    />
  )
}

export default BirthdayWishlistHeaderLayout
