import { track, useTracking } from 'lib/analytics'
import { errorMessage } from 'lib/flash-message'
import { UPDATE_CART_SUMMARY } from 'shared/constants'
import { Reservation } from 'src/types/reservation'
import { CartItem } from 'src/types/cart'
import { CartItemsAPI } from 'src/routes/(shop)/api/cartItems'
import { CartResponse } from 'src/api/types'
import { RegItem, RegItemMinimal } from 'src/types/regItem'
import { removeCartItem } from '../../../../../../../cart/api'

export default (regItem: RegItemMinimal, cart?: CartResponse) => ({
  handleDecreaseQuantity: useGetHandleDecreaseQuantity(regItem, cart),
  handleIncreaseQuantity: useGetHandleIncreaseQuantity(regItem, cart),
  handleRemoveFromCart: useGetHandleRemoveFromCart(regItem, cart),
})

export const useGetHandleDecreaseQuantity = (
  regItem: RegItemMinimal,
  cart?: CartResponse
) => {
  const handleAdjustQuantity = useGetHandleAdjustRegItemQuantity(regItem, cart)
  return (newQuantity: number) => handleAdjustQuantity(newQuantity)
}

export const useGetHandleIncreaseQuantity = (
  regItem: RegItemMinimal,
  cart?: CartResponse
) => {
  const handleAdjustQuantity = useGetHandleAdjustRegItemQuantity(regItem, cart)
  return (newQuantity: number) => handleAdjustQuantity(newQuantity)
}

export const useGetHandleRemoveFromCart = (
  regItem: RegItemMinimal,
  cart?: CartResponse
) => {
  const tracker = useTracking()
  const handleCancelReservation = useGetHandleCancelReservation()

  return () => {
    const cartItem = getCartedCartItemByRegItem(regItem, cart)
    const reservation = getCartedReservationByRegItem(regItem, cart)

    if (cartItem) {
      tracker.trackEvent({
        cartItem,
        eventType: cartItem.isOutOfStock
          ? track.EventType.ITEM_REMOVED_MINUS_OUT_OF_STOCK
          : track.EventType.ITEM_REMOVED,
      })

      removeCartItem(cartItem)
        .then(() => PubSub.publish(UPDATE_CART_SUMMARY))
        .catch(() => {
          errorMessage('An error occurred while removing item from the cart.')
        })
    } else if (reservation) {
      handleCancelReservation(reservation)
    }
  }
}

const useGetHandleAdjustRegItemQuantity = (
  regItem: RegItemMinimal,
  cart?: CartResponse
) => {
  const handleUpdateCartItem = useGetHandleUpdateCartItem({
    onError: (error) => {
      const { message } = error as { message: string; title: string }
      errorMessage(message)
    },
  })
  const handleUpdateReservation = useGetHandleUpdateReservation()

  return (newQuantity: number) => {
    const cartItem = getCartedCartItemByRegItem(regItem, cart)
    const reservation = getCartedReservationByRegItem(regItem, cart)

    if (cartItem) {
      handleUpdateCartItem(cartItem, newQuantity)
    } else if (reservation) {
      handleUpdateReservation(reservation, newQuantity)
    }
  }
}

const useGetHandleUpdateReservation =
  () => (reservation: Reservation, newQuantity: number) => {
    // TODO: Implement
    console.log('Updating reservation', reservation, newQuantity)
  }

const useGetHandleCancelReservation = () => (reservation: Reservation) => {
  // TODO: Implement
  console.log('Cancelling reservation', reservation)
}

const useGetHandleUpdateCartItem = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    data: unknown,
    variables: unknown,
    context: void | undefined
  ) => unknown
  onError?: (
    error: unknown,
    variables: unknown,
    context: void | undefined
  ) => unknown
}) => {
  const tracker = useTracking()
  const { mutate: updateCartItem } = CartItemsAPI.useUpdateMutation({
    flashMessageEnabled: false,
  })

  const handleUpdateCartItem = (cartItem: CartItem, newQuantity: number) => {
    const updatedCartItem = {
      ...cartItem,
      quantity: newQuantity,
    }

    tracker.trackEvent({
      event: track.cartModified,
      eventType: track.EventType.ITEM_QUANTITY_UPDATED,
      cartItem: updatedCartItem,
    })

    updateCartItem(updatedCartItem, { onSuccess, onError })
  }

  return handleUpdateCartItem
}

const getCartedCartItemByRegItem = (
  regItem: RegItemMinimal | RegItem,
  cart?: CartResponse
) => {
  if (!cart) return undefined

  return cart.cart?.items?.find(
    (cartItem) => cartItem.regItem?.id === regItem.id
  )
}

const getCartedReservationByRegItem = (
  regItem: RegItemMinimal | RegItem,
  cart?: CartResponse
) => {
  if (!cart) return undefined

  return cart.reservations?.find(
    (reservation) => reservation.regItemId === regItem.id
  )
}
