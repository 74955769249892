import React, { useState } from 'react'
import classNames from 'classnames'
import { browserName } from 'lib/bowser'
import {
  ADD_BABYLIST_BUTTON_EVENT,
  INSTALLED_BABYLIST_EXTENSION_EVENT,
} from 'registry/constants'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import HowToUseModal from '../HowToUseModal/HowToUseModal'

import css from './ShopCell.styles.scss'

import { ShopCellProps } from './ShopCell.types'

const ShopCell: React.FC<ShopCellProps> = ({ className, store }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentUser] = useCurrentUser()

  const ShopCellClasses = classNames(css.ShopCell, className)

  const installedBabylistExtension = currentUser?.events?.some(
    (event) => event.name === INSTALLED_BABYLIST_EXTENSION_EVENT
  )

  const installedBabylistButton = currentUser?.events?.some(
    (event) => event.name === ADD_BABYLIST_BUTTON_EVENT
  )

  const chromeUser = browserName === 'Chrome'

  const handleOpen = () => {
    if (typeof window === 'undefined') return
    if (
      (chromeUser && installedBabylistExtension) ||
      (!chromeUser && installedBabylistButton)
    ) {
      window.open(store.url, '_blank')
      return
    }

    setIsOpen(true)
  }
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <div className={ShopCellClasses} onClick={handleOpen}>
        <img
          alt={store.name}
          className={css.ShopCell__image}
          src={store.logoImageUrl}
        />
      </div>
      <HowToUseModal showCallout handleClose={handleClose} isOpen={isOpen} />
    </>
  )
}

export default ShopCell
