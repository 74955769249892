import React from 'react'

import css from './ShipmentsContent.styles.scss'

const NUM_SKELETON_ITEMS = 3

interface ShipmentSkeletonBlockProps {
  height: string
  width: string
}
const ShipmentSkeletonBlock: React.FC<ShipmentSkeletonBlockProps> = ({
  height,
  width,
}) => <div className={css.ShipmentsSkeleton__Block} style={{ height, width }} />

const ShipmentsSkeletonCard: React.FC = () => (
  <div className={css.ShipmentsSkeleton__Card}>
    <div className={css.ShipmentsSkeleton__Row}>
      <div className={css.ShipmentsSkeleton__Left}>
        <ShipmentSkeletonBlock height="1.5rem" width="5rem" />
        <ShipmentSkeletonBlock height="1.5rem" width="15rem" />
        <ShipmentSkeletonBlock height="1rem" width="7rem" />
      </div>
      <div className={css.ShipmentsSkeleton__Right}>
        <ShipmentSkeletonBlock height="1rem" width="6.5rem" />
        <div className={css.ShipmentsSkeleton__RightBottom}>
          <ShipmentSkeletonBlock height="3rem" width="3rem" />
          <ShipmentSkeletonBlock height="3rem" width="3rem" />
          <ShipmentSkeletonBlock height="3rem" width="3rem" />
        </div>
      </div>
    </div>
  </div>
)

const ShipmentsSkeletonCardMobile: React.FC = () => (
  <div className={css.ShipmentsSkeleton__CardMobile}>
    <div className={css.ShipmentsSkeleton__Left}>
      <ShipmentSkeletonBlock height="1rem" width="6.5rem" />
      <ShipmentSkeletonBlock height="1.5rem" width="15rem" />
      <div className={css.ShipmentsSkeleton__MobileBottom}>
        <ShipmentSkeletonBlock height="3rem" width="3rem" />
        <ShipmentSkeletonBlock height="3rem" width="3rem" />
        <ShipmentSkeletonBlock height="3rem" width="3rem" />
      </div>
    </div>
  </div>
)

const ShipmentsContentSkeleton: React.FC = () => (
  <div
    className={css.ShipmentsSkeleton}
    data-testid="shipments-content-skeleton"
  >
    {[...Array(NUM_SKELETON_ITEMS)].map((_, i) => (
      <div key={i}>
        <ShipmentsSkeletonCard />
        <ShipmentsSkeletonCardMobile />
      </div>
    ))}
  </div>
)

export default ShipmentsContentSkeleton
