import React from 'react'
import { ShipmentItemsThumbnailRowProps } from 'src/routes/(registry)/shipments/components/ShipmentItemsThumbnailRow/ShipmentItemsThumbnailRow.types'
import { shipmentItemsToThumbnails } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import { ThumbnailRowProps } from 'baby-design/components/ThumbnailRow/ThumbnailRow.types'
import ThumbnailRow from '../../../../../../baby-design/components/ThumbnailRow/ThumbnailRow'

const ShipmentItemsThumbnailRow: React.FC<ShipmentItemsThumbnailRowProps> = ({
  shipment,
  ...thumbnailRowProps
}) => {
  const props = {
    ...thumbnailRowProps,
    thumbnails: shipmentItemsToThumbnails(shipment),
  } as ThumbnailRowProps

  return <ThumbnailRow {...props} />
}

export default ShipmentItemsThumbnailRow
