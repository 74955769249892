import classNames from 'classnames'
import { useWindowSize } from 'usehooks-ts'
// eslint-disable-next-line import/no-extraneous-dependencies
import { IconButton, PricingTreatment, Tag } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EditPencil } from 'baby-design/icons'

import { BREAKPOINT_TABLET } from 'src/constants'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'
import css from '../RegItemMinimalCardDetails.styles.scss'

const ReservedDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  regItem,
  reservation,
}: RegItemMinimalCardDetailsProps) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth < BREAKPOINT_TABLET
  const { title } = regItem
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.ReservedDetails
  )

  const price = reservation?.giftAmount

  return (
    <div className={wrapperClasses} data-testid="ReservedDetails">
      <div className={css.Title}>{title}</div>
      <div className={css.EditBlock}>
        <div className={css.ReservationDetails}>
          {price && (
            <PricingTreatment
              decimalPlaces={0}
              price={price}
              size={isMobile ? 'md' : 'lg'}
            />
          )}
          {reservation?.isVisitorReservation && (
            <>
              {reservation?.storeName && (
                <div className={css.StoreName}>{reservation.storeName}</div>
              )}
              {reservation?.orderNumber && (
                <div className={css.OrderNumber}>
                  Order #: {reservation.orderNumber}
                </div>
              )}
            </>
          )}
          {regItem.isGroupGift && (
            <Tag size={isMobile ? 'sm' : 'md'} type="primary" variant="normal">
              Group Gift
            </Tag>
          )}
        </div>
        {reservation?.isVisitorReservation && (
          <div className={css.EditButton} data-testid="EditButton">
            <IconButton
              size="sm"
              variant="fill-secondary"
              onClick={() => undefined}
            >
              <EditPencil />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReservedDetails
