import { useState } from 'react'
import { QuantityPicker } from 'baby-design'
import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { AddToCartButton } from 'src/routes/(shop)/components/AddToCartButton'
import { QuantityLabel } from 'src/routes/(shop)/components/QuantityLabel/QuantityLabel'
import { AddToBabylistButton } from 'src/routes/(shop)/components/AddToBabylistButton/AddToBabylistButton'
import { useUIContext } from 'src/contexts/UIContext'
import buyBoxCss from '../../BuyBox/BuyBox.styles.scss'
import { BabylistHealthCTA } from '../../BabylistHealthCTA'

export const ProductActions = () => {
  const [quantity, updateQuantity] = useState(1)
  const { productId } = useParams()
  const { isMobile } = useUIContext()

  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct?.approved) return null

  const { activeProduct, showBabylistHealthCta, babylistHealthCtaProductType } =
    genericProduct
  const maxQuantity = activeProduct?.showPurchaseQuantityLimit
    ? activeProduct.purchaseQuantityLimit
    : undefined

  return (
    <div className={buyBoxCss.BuyBox__actionsContainer}>
      <div className={buyBoxCss.BuyBox__actionsContainer__quantity}>
        <QuantityLabel max={maxQuantity} quantity={quantity} />
        <QuantityPicker
          max={maxQuantity}
          min={1}
          size={isMobile ? 'sm' : 'md'}
          type="pdp"
          value={quantity}
          variant="fill"
          onMinusClick={() =>
            quantity > 1 ? updateQuantity(quantity - 1) : null
          }
          onPlusClick={() => updateQuantity(quantity + 1)}
        />
      </div>
      {showBabylistHealthCta && (
        <BabylistHealthCTA
          babylistHealthCtaProductType={babylistHealthCtaProductType}
        />
      )}
      <AddToBabylistButton quantity={quantity} />
      <AddToCartButton product={activeProduct} quantity={quantity} />
    </div>
  )
}
