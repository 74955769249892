import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextLink } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { PriceRange, Sort, Store } from 'baby-design/icons'
import { useWindowSize } from 'usehooks-ts'

import { BREAKPOINT_TABLET } from 'src/constants'
import PopoverModalButton from 'src/components/PopoverModalButton/PopoverModalButton'
import { useAppliedSortsAndFiltersContext } from '../../contexts'
import { RegistrySortFilterDropdownsProps } from './RegistrySortFilterDropdowns.types'
import { defaultAppliedSortsAndFilters } from '../RegistryGiftGiverPage/RegistryGiftGiverPage.utils'
import {
  getPriceTitle,
  getSortTitle,
  getStoreTitle,
} from './RegistrySortFilterDropdowns.utils'
import RegistrySortFilterDropdownsSkeleton from './RegistrySortFilterDropdowns.skeleton'
import css from './RegistrySortFilterDropdowns.styles.scss'
import PriceRangeFilter from './components/PriceRangeFilter/PriceRangeFilter'
import SortOrder from './components/SortOrder/SortOrder'
import StoreFilter from './components/StoreFilter/StoreFilter'

const RegistrySortFilterDropdowns: React.FC<
  RegistrySortFilterDropdownsProps
> = ({
  firstNames,
  sortFilterOptions,
  previewFilterResults,
}: RegistrySortFilterDropdownsProps) => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < BREAKPOINT_TABLET
  const {
    appliedSortsAndFilters: { filters, sort },
    onSortFilterChange,
  } = useAppliedSortsAndFiltersContext()

  if (!sortFilterOptions) return <RegistrySortFilterDropdownsSkeleton />

  const resetFilters = () => onSortFilterChange(defaultAppliedSortsAndFilters)
  const { price, stores } = filters
  const priceTitle = getPriceTitle(price)
  const storeTitle = getStoreTitle(stores, sortFilterOptions)
  const sortTitle = getSortTitle(sort, sortFilterOptions, isMobile)
  const isDefaultSort = sort === defaultAppliedSortsAndFilters.sort
  const isResetVisible = price || stores || !isDefaultSort

  return (
    <>
      <div
        className={css.RegistrySortFilterDropdowns}
        data-testid="RegistrySortFilterDropdowns"
      >
        <PopoverModalButton
          icon={<PriceRange />}
          isActive={!!price}
          mobileTitle="Price"
          renderContent={(closePopover) => (
            <PriceRangeFilter
              closePopover={closePopover}
              previewFilterResults={previewFilterResults}
              sortFilterOptions={sortFilterOptions}
            />
          )}
          title={priceTitle}
        />
        <PopoverModalButton
          icon={<Store />}
          isActive={!!stores}
          mobileTitle="Store"
          renderContent={(closePopover) => (
            <StoreFilter
              closePopover={closePopover}
              firstNames={firstNames}
              previewFilterResults={previewFilterResults}
              sortFilterOptions={sortFilterOptions}
            />
          )}
          title={storeTitle}
        />

        {isResetVisible && !isMobile && (
          <TextLink
            underline
            className={css[`RegistrySortFilterDropdowns__Reset--Desktop`]}
            size="sm"
            variant="default"
            onClick={resetFilters}
          >
            Reset all
          </TextLink>
        )}

        <PopoverModalButton
          align="right"
          className={css.RegistrySortFilterDropdowns__Sort}
          icon={<Sort />}
          isActive={!isDefaultSort}
          mobileTitle="Sort"
          renderContent={(closePopover) => (
            <SortOrder
              closePopover={closePopover}
              sortFilterOptions={sortFilterOptions}
            />
          )}
          title={sortTitle}
        />
      </div>
      {isResetVisible && isMobile && (
        <div className={css.RegistrySortFilterDropdowns__MobileResetWrapper}>
          <TextLink
            underline
            className={css[`RegistrySortFilterDropdowns__Reset--Mobile`]}
            size="xs"
            variant="default"
            onClick={resetFilters}
          >
            Reset all
          </TextLink>
        </div>
      )}
    </>
  )
}

export default RegistrySortFilterDropdowns
