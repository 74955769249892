import { useQuery } from '@tanstack/react-query'
import {
  fetchRegistrySortFilterOptions,
  FETCH_REGISTRY_SORT_FILTER_OPTIONS_KEY,
} from 'src/api/queries'
import { RegistrySortFilterOptions } from 'src/types/registrySortFilterOptions'

const useGetSortFilterOptions = (registryId: number | undefined) => {
  const sortFilterOptionsQuery = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_REGISTRY_SORT_FILTER_OPTIONS_KEY, registryId],
    queryFn: () => fetchRegistrySortFilterOptions(registryId),
  })
  const sortFilterOptions: RegistrySortFilterOptions | undefined =
    sortFilterOptionsQuery.data

  return { sortFilterOptions, sortFilterOptionsQuery }
}

export default useGetSortFilterOptions
