import { FC } from 'react'

import { useNavigate, useLocation } from 'react-router-dom-v5-compat'
import { Chevron } from 'baby-design/icons'
import { TextLink } from 'baby-design'
import { Registry } from 'src/types/registry'
import css from './NavigationBreadcrumbs.styles.scss'

interface NavigationBreadcrumbsProps {
  currentRegistry?: Registry
}

const NavigationBreadcrumbs: FC<NavigationBreadcrumbsProps> = ({
  currentRegistry,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (!currentRegistry) {
    return (
      <div className={css.NavigationBreadcrumbs}>
        <div className={css.loadingShimmer} />
      </div>
    )
  }

  if (pathname === '/registry/home' || pathname.includes('/list/')) {
    // We don't display breadcrumbs on home page or registry page
    return null
  }

  const { urlSlug: currentRegistrySlug } = currentRegistry

  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  const convertKebabCaseToTitleCase = (kebabCase: string) =>
    kebabCase
      .split('-')
      .map((word) => capitalize(word))
      .join(' ')

  const pathParts = pathname.split('/').filter((part) => part !== '')
  const registryPathPart = 'registry'
  const breadcrumbs = pathParts.includes('list')
    ? [registryPathPart]
    : pathParts

  return (
    <div className={css.NavigationBreadcrumbs}>
      {breadcrumbs.map((part, index) => {
        const isLast = index === breadcrumbs.length - 1
        if (isLast) {
          return (
            <span className={css.NavigationBreadcrumbs__lastItem} key={part}>
              {convertKebabCaseToTitleCase(part)}
            </span>
          )
        }

        return (
          <span className={css.NavigationBreadcrumbs__middleItem} key={part}>
            <TextLink
              size="sm"
              weight="regular"
              onClick={() => {
                if (part === registryPathPart) {
                  navigate(`/list/${currentRegistrySlug}`)
                } else {
                  // We shouldn't get here with our current possible routes, but just in case
                  navigate(breadcrumbs.slice(0, index + 1).join('/'))
                }
              }}
            >
              {convertKebabCaseToTitleCase(part)}
            </TextLink>
            <Chevron />
          </span>
        )
      })}
    </div>
  )
}

export default NavigationBreadcrumbs
