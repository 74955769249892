import { useDispatch } from 'react-redux'

import { track, TrackingMethods, useTracking } from 'lib/analytics'
import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import { Reservation } from 'src/types/reservation'
import { createReservation } from 'registry/actions'
import { openReservePurchasedGiftModal } from 'registry/modals/actions'
import {
  useInvalidateRegItemsMinimal,
  useOptimisticDeleteRegItemMinimal,
} from './useGetRegItemsMinimal'
import {
  useInvalidateVisitorReservations,
  useOptimisticAddVisitorReservation,
} from './useGetVisitorReservations'
import {
  useInvalidateReservations,
  useOptimisticAddReservation,
} from './useGetReservations'
import { useGetOnDeleteReservation } from './useGetOnDeleteReservation/useGetOnDeleteReservation'

export interface CreateEditReservationModalsProps {
  regItem: RegItem
  registry: Registry
  existingReservation?: Reservation
  initialStep?: number
}

export const useOpenCreateEditReservationModal = ({
  regItem,
  registry,
  existingReservation,
  initialStep,
}: CreateEditReservationModalsProps) => {
  // Because we are using the old modal flows for this, we need to use redux to manage the state and maintain compatibility.
  // Any calls to this function must be made within the context of a redux provider.
  const dispatch = useDispatch()
  const tracker = useTracking()
  const afterSubmit = useGetAfterSubmitReservation(registry.id)
  const onDeleteReservation = useGetOnDeleteReservation()

  return () =>
    dispatch(
      openReservePurchasedGiftModal(
        regItem,
        registry,
        getTrackedCreateReservation(tracker, dispatch, afterSubmit),
        existingReservation,
        initialStep,
        onDeleteReservation
      )
    )
}

// Prepare react-query store invalidation to reload cards after reservation creation
export const useGetAfterSubmitReservation = (registryId: number) => {
  const invalidateRegItemsMinimal = useInvalidateRegItemsMinimal(registryId)
  const invalidateVisitorReservations =
    useInvalidateVisitorReservations(registryId)
  const invalidateReservations = useInvalidateReservations(registryId)
  const optimisticAddReservation = useOptimisticAddReservation()
  const optimisticAddVisitorReservation = useOptimisticAddVisitorReservation()
  const optimisticDeleteRegItemMinimal = useOptimisticDeleteRegItemMinimal()

  return (newReservation: Reservation) => {
    // Optimistically Update for immediate feedback
    optimisticAddReservation(registryId, newReservation)
    optimisticAddVisitorReservation(registryId, newReservation)
    if (!newReservation.regItem?.quantityNeeded) {
      optimisticDeleteRegItemMinimal(registryId, newReservation.regItemId)
    }

    // Invalidate Queries for eventual consistency
    invalidateRegItemsMinimal()
    invalidateVisitorReservations()
    invalidateReservations()
  }
}

export const getTrackedCreateReservation =
  (
    tracker: TrackingMethods,
    dispatch: unknown,
    afterSubmit?: (newReservation: Reservation) => void
  ) =>
  (...args: unknown[]) =>
    // @ts-ignore-next-line
    dispatch(createReservation(...args)).then((newReservation: Reservation) => {
      const { email, userId } = newReservation
      tracker.trackEvent({
        event: track.emailCaptured,
        eventLocation: track.EventLocation.REGISTRY_MINUS_GIFT_GIVER_VIEW,
        eventUrl: window.location.href,
        email,
        userId,
      })

      if (afterSubmit) afterSubmit(newReservation)

      return newReservation
    })
