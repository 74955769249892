import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { ProductCallouts as Component } from './ProductCallouts'

export const ProductCallouts = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const callouts = genericProduct?.activeProduct?.callouts
  if (!callouts) return null
  return <Component callouts={callouts} />
}
