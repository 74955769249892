import { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Image from 'components/image'
import { Input } from 'components/forms'
import CashFundPreferencesForm from 'registry/forms/cash-fund-preferences-form'
import { successMessage } from 'lib/flash-message'
import { CATEGORY_ID_CASH_AND_GIFT } from 'shared/constants'

export default class CrowdfundForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.title,
      description: null,
    }
  }

  static propTypes = {
    title: PropTypes.string,
    cashFundPreferences: PropTypes.object,
    imageUrl: PropTypes.string,
    onSubmit: PropTypes.func,
    currentRegistryTitle: PropTypes.string,
    currentRegistryUrlSlug: PropTypes.string,
  }

  static defaultProps = {
    title: 'Cash Fund',
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (preferences) => {
    this.props.onSaveCashFundPreferences(preferences)
    return this.props
      .onSubmit({
        title: this.state.title,
        description: this.state.description,
        quantity: -1,
        category_id: CATEGORY_ID_CASH_AND_GIFT,
      })
      .then(() => {
        this.props.onHide()
        const successMessageText = `${this.state.title} added to <a href="/${
          this.props.currentRegistryUrlSlug
        }">${this.props.currentRegistryTitle || 'your registry'}</a>!`
        successMessage(successMessageText)
      })
  }

  render() {
    const { imageUrl, onHide, isRegistryOwner, cashFundPreferences } =
      this.props
    return (
      <Row>
        <Col sm={4}>
          <Image
            src={imageUrl}
            className="center-block img-responsive mbl"
            alt="Add a Cash Fund"
          />
        </Col>
        <Col sm={8}>
          <Input
            label="Title"
            name="title"
            value={this.state.title}
            onChange={this.handleChange}
            disabled={!isRegistryOwner}
          />

          <Input
            label="Notes for friends and family"
            name="description"
            placeholder="List a goal amount or the price of the item you are creating the fund for."
            rows={4}
            type="textarea"
            value={this.state.description}
            onChange={this.handleChange}
            disabled={!isRegistryOwner}
          />
          <CashFundPreferencesForm
            preferences={cashFundPreferences}
            isEditable={isRegistryOwner}
            handleSubmit={this.handleSubmit}
          />
          {!isRegistryOwner && (
            <small className="text-muted mbm">
              Only the registry owner can add and manage cash funds.
            </small>
          )}
        </Col>
      </Row>
    )
  }
}
