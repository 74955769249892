import { FC } from 'react'
import { Image, TextLink } from 'baby-design'
import { useQuery } from '@tanstack/react-query'
import ClipboardWithCheck from './icons/ClipboardWithCheck.svg'
import BrokenChainLink from './icons/BrokenChainLink.svg'
import Discount from './icons/Discount.svg'
import Location from './icons/Location.svg'
import CashBag from './icons/CashBag.svg'
import Stork from './icons/Stork.svg'
import { FETCH_CAREERS_KEY, fetchCareers } from '../../../api/queries'

import css from './AboutCareers.styles.scss'

export const values = [
  {
    title: 'We love our users',
    description:
      'This is our superpower. We listen to our users relentlessly to earn their trust and find better and better ways to support them as they take on parenthood.',
  },
  {
    title: 'Commit, act, deliver!',
    description:
      'We take ownership and are accountable for everything we do. We commit to the work, act with purpose and deliver results with impact.',
  },
  {
    title: 'Context & clarity',
    description:
      'We strive to bring clarity and context to all we do at every level. Clarity is key to effective collaboration and efficiency, while context helps us make impactful decisions.',
  },
  {
    title: 'Intentionally focused',
    description:
      "We focus on what matters. We're intentional about aligning our work with our goals, making sure we're focusing our time and energy on what truly moves the needle.",
  },
  {
    title: 'Progress, not perfection',
    description:
      'We have a Growth Mindset—as individuals, teams and as a company. We embrace challenging work and seek out problems that push us to grow. We recognize that we are better than we were yesterday and not yet as great as we will be tomorrow.',
  },
]

interface Job {
  absoluteUrl: string
  title: string
  location: string
}

interface AvailablePosition {
  name: string
  jobs: Job[]
}

const AboutCareers: FC = () => {
  const careerQuery = useQuery<AvailablePosition[]>({
    queryKey: [FETCH_CAREERS_KEY],
    queryFn: fetchCareers,
  })

  const benefits = [
    {
      icon: <Location className={css.AboutCareers__benefitIcon} />,
      title: 'Remote-first',
      description:
        'Work from home or our HQ in Emeryville, CA—whichever works best for you. We offer a home-office stipend and host company-wide offsites every year so we see each other IRL too.',
    },
    {
      icon: <CashBag className={css.AboutCareers__benefitIcon} />,
      title: 'Competitive compensation',
      description:
        'We offer competitive pay and meaningful opportunities for career advancement, as well as equity, 401(k) matching, flexible spending accounts and financial planning perks.',
    },
    {
      icon: <BrokenChainLink className={css.AboutCareers__benefitIcon} />,
      title: 'Paid time off',
      description:
        'On top of vacation days, we offer nine wellness days and ten paid holidays, including one floating holiday for you to recharge.',
    },
    {
      icon: <ClipboardWithCheck className={css.AboutCareers__benefitIcon} />,
      title: 'Health & wellness',
      description:
        'Wellness benefits include 100% paid coverage for medical, vision and dental for full-time employees and support services for mental health, parenting and childcare.',
    },
    {
      icon: <Stork className={css.AboutCareers__benefitIcon} />,
      title: 'Family & fertility',
      description:
        'We offer fertility coverage with no infertility diagnosis required and a $5,000 reproductive care stipend to support our employees in their journey to parenthood.',
    },
    {
      icon: <Discount className={css.AboutCareers__benefitIcon} />,
      title: 'Babylist Shop discount',
      description:
        'All full-time, US employees receive a generous Babylist Shop discount.',
    },
  ]

  return (
    <div className={css.AboutCareers}>
      <section className={css.AboutCareers__introSection}>
        <h1 className={css.AboutCareers__introHeading}>Careers</h1>
        <div className={css.AboutCareers__introContent}>
          <div className={css.AboutCareers__introContentText}>
            <h2 className={css.AboutCareers__introContentTextHeading}>
              Our people and focus are our superpowers
            </h2>
            <p className={css.AboutCareers__introContentTextDescription}>
              Babylist is the trusted platform for millions of growing families.
              For over a decade, Babylist has been the go-to technology solution
              for expecting parents and their supportive communities. Expanding
              from a baby registry into a comprehensive platform, Babylist takes
              care of growing families so they can be confident, connected,
              happy and healthy.
              <br />
              <br />
              Our success stems from the combination of talented individuals and
              focusing on the right initiatives. By hiring smart, pragmatic and
              accountable team members, we sustainably achieve strong business
              results. We prioritize doing a few things exceptionally well and
              we're deliberate about our commitments. We take immense pride in
              our team, fostering a culture of mutual learning, support,
              authenticity and respect. At Babylist, we embrace a
              high-performance culture that values direct feedback and a growth
              mindset.
              <br />
              <br />
              Diversity and inclusion are crucial to our innovation and
              decision-making processes. As a remote-first company, we&rsquo;re
              proud to have team members across the US, Canada, Mexico and the
              Philippines. We value passion and transferable skills—if you're
              enthusiastic about an opening and your experience aligns, we
              encourage you to apply. We're excited to welcome new perspectives
              to our team.
            </p>
          </div>
          <div className={css.AboutCareers__introImageWrapper}>
            <Image
              className={css.AboutCareers__introImage}
              src="https://images.babylist.com/image/upload/q_auto/v1719872382/Corporate%20About%20Pages/Non-Animated/careers_intro_image_2.jpg"
            />
          </div>
        </div>
        <div className={css.AboutCareers__topPlacesToWork}>
          <h2 className={css.AboutCareers__topPlacesToWorkHeading}>
            Honored to be a top workplace
          </h2>
          <div className={css.AboutCareers__topPlacesToWorkContent}>
            <Image
              alt="Fortune Best Medium Workplaces 2024 Badge"
              height={120}
              src="https://images.babylist.com/image/upload/q_auto,c_scale,w_400/v1727274540/Corporate%20About%20Pages/Non-Animated/GPTW_2024_Medium_Workplaces.png"
            />
            <Image
              alt="Fortune Best Workplaces In The Bay Area 2024 Badge"
              height={120}
              src="https://images.babylist.com/image/upload/q_auto/v1720019971/Corporate%20About%20Pages/Non-Animated/GPTW_2024_Bay_Area.png"
            />
            <Image
              alt="Glassdoor Rating of 4.3 Badge"
              className={css['AboutCareers__topPlacesToWorkImage--mobile1']}
              src="https://images.babylist.com/image/upload/q_auto/v1719607581/Corporate%20About%20Pages/Non-Animated/glassdoor_4-3.png"
              width={150}
            />
            <Image
              alt="Built In 2023 Best Places To Work Badge"
              src="https://images.babylist.com/image/upload/q_auto/v1718128473/Corporate%20About%20Pages/Non-Animated/builtin_best_places_to_work_2023.png"
              width={90}
            />
            <Image
              alt="Inc Best Workplaces 2024 Badge"
              className={css['AboutCareers__topPlacesToWorkImage--mobile2']}
              height={60}
              src="https://images.babylist.com/image/upload/q_auto/v1720019550/Corporate%20About%20Pages/Non-Animated/IncBestPlaceToWork2024.png"
            />
            <Image
              alt="Great Place To Work Certified April 2023-April 2024 Badge"
              height={120}
              src="https://images.babylist.com/image/upload/q_auto/v1718128473/Corporate%20About%20Pages/Non-Animated/great_place_to_work_2023-2024.png"
            />
          </div>
        </div>
      </section>
      <section className={css.AboutCareers__valuesSection}>
        <div className={css.AboutCareers__valuesContainer}>
          <div className={css.AboutCareers__valuesHeadingContent}>
            <h2 className={css.AboutCareers__valuesHeading}>Our values</h2>
            <p className={css.AboutCareers__valuesHeadingDescription}>
              We&rsquo;ve built a strong culture that&rsquo;s led to strong
              business results and high-performing people. Below are the values
              that helped us achieve this success:
            </p>
          </div>
          <div className={css.AboutCareers__valuesContent}>
            {values.map((value) => (
              <div className={css.AboutCareers__value} key={value.title}>
                <h3 className={css.AboutCareers__valueHeading}>
                  {value.title}
                </h3>
                <p className={css.AboutCareers__valueDescription}>
                  {value.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={css.AboutCareers__benefitsSection}>
        <h2 className={css.AboutCareers__benefitsHeading}>Benefits & perks</h2>
        <div className={css.AboutCareers__benefitsContent}>
          {benefits.map((benefit) => (
            <div className={css.AboutCareers__benefit} key={benefit.title}>
              {benefit.icon}
              <div className={css.AboutCareers__benefitContent}>
                <h3 className={css.AboutCareers__benefitTitle}>
                  {benefit.title}
                </h3>
                <p className={css.AboutCareers__benefitDescription}>
                  {benefit.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className={css.AboutCareers__openPositionsSection}>
        <div className={css.AboutCareers__openPositionsContentContainer}>
          <h2 className={css.AboutCareers__openPositionsHeading}>
            Job openings
          </h2>
          <div className={css.AboutCareers__availablePositions}>
            {careerQuery.data?.length === 0 && (
              <p className={css.AboutCareers__noOpenings}>
                There are no openings at this time.
              </p>
            )}
            {careerQuery.data?.map((department) => (
              <div
                className={css.AboutCareers__department}
                key={department.name}
              >
                <h3 className={css.AboutCareers__departmentHeading}>
                  {department.name}
                </h3>
                <div className={css.AboutCareers__jobs}>
                  {department.jobs.map((job) => (
                    <div className={css.AboutCareers__job} key={job.title}>
                      <TextLink
                        underline
                        className={css.AboutCareers__jobLink}
                        href={job.absoluteUrl}
                        target="_blank"
                        variant="eggplant"
                        weight="medium"
                      >
                        {job.title}
                      </TextLink>
                      <p className={css.AboutCareers__jobLocation}>
                        {job.location}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutCareers
