/* eslint-disable import/no-extraneous-dependencies */
import { FC } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom-v5-compat'
import { Button, Image } from 'baby-design'
import { UserOutline } from 'baby-design/icons'
import { useQuery } from '@tanstack/react-query'
import FeaturedNewsSection from './components/FeaturedNewsSection/FeaturedNewsSection'
import HeroSection from './components/HeroSection/HeroSection'
import LeadershipSection from './components/LeadershipSection/LeadershipSection'
import ShoppingCartEmpty from './icons/ShoppingCartEmpty.svg'
import Purchased from './icons/Purchased.svg'
import Store from './icons/Store.svg'
import {
  FETCH_COMPANY_STATS_KEY,
  fetchCompanyStats,
} from '../../../api/queries'

import css from './AboutHome.styles.scss'

interface CompanyStats {
  year: string
  gmv: string
  registryGiftsPurchased: string
  purchasersLastYear: string
  socialMediaFollowers: string
}

const AboutHome: FC = () => {
  const companyStatsQuery = useQuery<CompanyStats>({
    queryKey: [FETCH_COMPANY_STATS_KEY],
    queryFn: fetchCompanyStats,
  })
  const navigate = useNavigate()

  return (
    <div className={css.AboutHome}>
      <HeroSection />
      <section className={css.AboutHome__recentFactsSection}>
        <h2 className={css.AboutHome__recentFactsSectionHeading}>
          Recent facts
        </h2>
        <div className={css.AboutHome__recentFacts}>
          <div className={css.AboutHome__recentFactEntry}>
            <ShoppingCartEmpty className={css.AboutHome__recentFactIcon} />
            <div className={css.AboutHome__recentFact}>
              <p className={css.AboutHome__recentFactValue}>
                {companyStatsQuery.data?.gmv}
              </p>
              <h3 className={css.AboutHome__recentFactHeading}>
                {companyStatsQuery.data?.year} GMV
              </h3>
            </div>
          </div>
          <div className={css.AboutHome__recentFactEntry}>
            <Purchased className={css.AboutHome__recentFactIcon} />
            <div className={css.AboutHome__recentFact}>
              <p className={css.AboutHome__recentFactValue}>
                {companyStatsQuery.data?.registryGiftsPurchased}
              </p>
              <h3 className={css.AboutHome__recentFactHeading}>
                registry gifts purchased
              </h3>
            </div>
          </div>
          <div className={css.AboutHome__recentFactEntry}>
            <Store className={css.AboutHome__recentFactIcon} />
            <div className={css.AboutHome__recentFact}>
              <p className={css.AboutHome__recentFactValue}>
                {companyStatsQuery.data?.purchasersLastYear}
              </p>
              <h3 className={css.AboutHome__recentFactHeading}>
                purchasers in {companyStatsQuery.data?.year}
              </h3>
            </div>
          </div>
          <div className={css.AboutHome__recentFactEntry}>
            <UserOutline className={css.AboutHome__recentFactIcon} />
            <div className={css.AboutHome__recentFact}>
              <p className={css.AboutHome__recentFactValue}>
                {companyStatsQuery.data?.socialMediaFollowers}
              </p>
              <h3 className={css.AboutHome__recentFactHeading}>
                social media followers
              </h3>
            </div>
          </div>
        </div>
        <p className={css.AboutHome__recentFactsContactInfo}>
          Email our partnership team at:{' '}
          <span className={css.AboutHome__recentFactsContactEmail}>
            partners@babylist.com
          </span>
        </p>
      </section>
      <LeadershipSection />
      <FeaturedNewsSection />
      <section className={css.AboutHome__impactSection}>
        <div className={css.AboutHome__impactImageWrapper}>
          <Image
            className={classNames(
              css.AboutHome__impactImage,
              css['AboutHome__impactImage--desktop']
            )}
            src="https://images.babylist.com/image/upload/q_auto/v1717776313/Corporate%20About%20Pages/Non-Animated/home_impact.png"
          />
        </div>
        <div className={css.AboutHome__impactContent}>
          <div className={css.AboutHome__impactTextContainer}>
            <h2 className={css.AboutHome__impactHeading}>
              We&rsquo;re intentionally focused & committed to inclusivity
            </h2>
            <p className={css.AboutHome__impactBody}>
              We&rsquo;re deeply committed to fostering an environment of
              inclusivity for all parents and our employees. We believe that
              everyone, regardless of their background or identity, deserves to
              feel valued, respected and supported. By embracing diversity and
              promoting inclusiveness, we create a community where different
              perspectives are celebrated. Our dedication to these principles
              drives us to continuously improve and set intentional goals,
              ensuring that our practices reflect our unwavering commitment.
              <br />
              <br />
              From thoughtful expansion into new business models and unique
              experiences to world-class employment benefits and innovative
              partnerships, we prioritize initiatives based on our values and
              the impact to our customers, investors and employees.
            </p>
          </div>
          <Button
            href="/about/impact"
            size="md"
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              navigate('/about/impact')
            }}
          >
            Our impact
          </Button>
        </div>
        <Image
          className={classNames(
            css.AboutHome__impactImage,
            css['AboutHome__impactImage--mobile']
          )}
          src="https://images.babylist.com/image/upload/q_auto/v1717777399/Corporate%20About%20Pages/Non-Animated/mobile_home_impact.png"
        />
      </section>

      <section className={css.AboutHome__careersSection}>
        <div className={css.AboutHome__careersTextBlock}>
          <h2 className={css.AboutHome__careersTextBlockHeading}>
            Join our growing team
          </h2>
          <p className={css.AboutHome__careersTextBlockBody}>
            Babylist puts people at the center of every decision — starting with
            our team.
          </p>
        </div>
        <Button
          className={css.AboutHome__careersButton}
          href="/about/careers"
          size="md"
          variant="inverted"
          onClick={(e) => {
            e.preventDefault()
            navigate('/about/careers')
          }}
        >
          Explore careers
        </Button>
      </section>
    </div>
  )
}

export default AboutHome
