import RetailerReturn from './retailer-return'
import { RETURNS_WALMART_URL, RETURNS_WALMART_PHONE } from 'shared/constants'

const WalmartReturn = ({ orderNum, giftGiverName, giftGiverEmail }) => {
  return (
    <RetailerReturn
      storeName="Walmart"
      phoneNumber={RETURNS_WALMART_PHONE}
      returnUrl={RETURNS_WALMART_URL}
      orderNum={orderNum}
      giftGiverName={giftGiverName}
      giftGiverEmail={giftGiverEmail}
    />
  )
}

export default WalmartReturn
