import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoDropdownButton } from 'components/buttons'
import ReservationFilter from '../reg-item-filters/reservation-filters'
import PriceFilter from '../reg-item-filters/price-filter'
import StoreFilters from '../reg-item-filters/store-filters'
import FavoriteFilters from '../reg-item-filters/favorite-filters'
import GroupGiftFilter from '../reg-item-filters/group-gift-filter'
import {
  REG_ITEM_RESERVATION_STATUS_AVAILABLE,
  REG_ITEM_RESERVATION_STATUS_RESERVED,
} from '../../actions/reg-items-actions'

const dropdownTitle = (label, numSelected) =>
  numSelected > 0 ? `${label} (${numSelected})` : label

export const FilterBar = ({ addFilter, className, filters, stores }) => {
  let viewTitle = 'All Gifts'
  switch (filters.giftView) {
    case REG_ITEM_RESERVATION_STATUS_AVAILABLE:
      viewTitle = 'Available'
      break
    case REG_ITEM_RESERVATION_STATUS_RESERVED:
      viewTitle = 'Reserved & Purchased'
      break
    default:
      break
  }

  return (
    <>
      <div className={classNames(className, 'flex-row')}>
        <ReservationFilter
          addFilter={addFilter}
          className="hidden-xs mbn phm"
          decorator={(children) => (
            <InfoDropdownButton
              id="reservation-filters-dropdown"
              className="mrm mrl-md"
              title={viewTitle}
              size="small"
              selected={!!filters.giftView}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.giftView}
        />
        <PriceFilter
          addFilter={addFilter}
          className="mbn phm"
          decorator={(children) => (
            <InfoDropdownButton
              id="price-filters-dropdown"
              className="mrm mrl-md"
              title={dropdownTitle(
                'Price',
                filters.priceRange && filters.priceRange.length
              )}
              size="small"
              selected={!!filters.priceRange && filters.priceRange.length}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.priceRange}
        />
        <StoreFilters
          addFilter={addFilter}
          className="mbn phm"
          decorator={(children) => (
            <InfoDropdownButton
              id="store-filters-dropdown"
              className="mrm mrl-md"
              title={dropdownTitle(
                'Stores',
                filters.storeNames && filters.storeNames.length
              )}
              size="small"
              selected={!!filters.storeNames && filters.storeNames.length}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.storeNames}
          stores={stores}
        />
        <FavoriteFilters
          addFilter={addFilter}
          className="mrm mrl-md"
          selected={filters.favoritesView}
        />
        <GroupGiftFilter
          addFilter={addFilter}
          className="mrm"
          selected={filters.groupGiftView}
        />
      </div>
    </>
  )
}

FilterBar.propTypes = {
  addFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  filters: PropTypes.object,
  stores: PropTypes.arrayOf({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }),
}

FilterBar.defaultProps = {
  className: undefined,
  filters: {},
  stores: [],
}
