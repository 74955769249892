import { useForm, FieldError } from 'react-hook-form'
import { email as isEmail } from 'lib/form-validation'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, TextInput } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Calendar } from 'baby-design/icons'
import ScrollLockModal from 'shared/ScrollLockModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useMutation } from '@tanstack/react-query'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useCallback } from 'react'
import { parseErrorMessage } from 'lib/api-utils'
import { errorMessage } from 'lib/flash-message'
import { GiftGiverReminderModalProps } from './GiftGiverReminderModal.types'
import { showReminderCreatedToast } from './ReminderCreatedToast/showReminderCreatedToast'
import {
  registryShowerDateKnown,
  submitReminder,
  useGiftGiverReminderModalTracking,
} from './GiftGiverReminderModal.utils'
import css from './GiftGiverReminderModal.styles.scss'

export const SUBMIT_CTA = 'Send me a reminder'
export const SECONDARY_CTA = 'No thanks'

const GiftGiverReminderModal = NiceModal.create(
  ({ registry }: GiftGiverReminderModalProps) => {
    const modal = useModal()
    const { trackModalSubmitted, trackModalDismissed } =
      useGiftGiverReminderModalTracking(registry)
    const { register, handleSubmit, errors } = useForm<{ email: string }>()
    const { executeRecaptcha } = useGoogleReCaptcha()

    const formId = 'gift-giver-reminder-form'

    const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
        // eslint-disable-next-line no-console
        console.error('Execute recaptcha not yet available')
        return null
      }

      return executeRecaptcha('gift_giver_reminder')
    }, [executeRecaptcha])

    const { mutate: submitReminderMutation, isLoading: submitting } =
      useMutation({
        mutationFn: async (data: { email: string }) => {
          const token = await handleReCaptchaVerify()
          if (!token) {
            throw new Error('ReCaptcha verification failed')
          }
          return submitReminder({ registry, email: data.email, token })
        },
        onSuccess: () => {
          modal.hide()
          showReminderCreatedToast()
        },
        onError: (resp) => {
          errorMessage(parseErrorMessage(resp))
        },
        onSettled: (_data, error) =>
          trackModalSubmitted({ eventCta: SUBMIT_CTA, success: !error }),
      })

    const onSubmit = (data: { email: string }) => {
      submitReminderMutation(data)
    }

    const getStatus = (fieldError: FieldError | undefined) => {
      if (fieldError) {
        return { type: 'error' as const, message: fieldError.message as string }
      }
      return undefined
    }

    const primaryButton = (
      <Button
        form={formId}
        loading={submitting}
        size="lg"
        type="submit"
        variant="primary"
      >
        {SUBMIT_CTA}
      </Button>
    )

    const dismissModal = (eventCta: string) => () => {
      modal.hide()
      trackModalDismissed({ eventCta })
    }
    const secondaryButton = (
      <Button
        size="lg"
        variant="tertiary"
        onClick={dismissModal(SECONDARY_CTA)}
      >
        {SECONDARY_CTA}
      </Button>
    )

    const beforeString = registryShowerDateKnown(registry)
      ? 'the baby shower'
      : 'baby arrives'
    const subtitle = `Enter your email below and we'll remind you to get a gift before ${beforeString}.`
    return (
      <ScrollLockModal
        showCloseButton
        handleClose={dismissModal('Dismissed')}
        icon={
          <span className={css.GiftGiverReminderModal__IconContainer}>
            <Calendar />
          </span>
        }
        isOpen={modal.visible}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        subTitle={subtitle}
        title="Give yourself time to decide"
      >
        <form
          className={css.GiftGiverReminderModal__Form}
          id={formId}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            inputProps={{
              name: 'email',
              placeholder: 'Your email address',
              ref: register({
                validate: isEmail,
              }),
            }}
            label="Your email"
            size="md"
            status={getStatus(errors?.email)}
          />
        </form>
      </ScrollLockModal>
    )
  }
)

export default GiftGiverReminderModal
