import Button from 'baby-design/components/Button/Button'
import { RightArrow } from 'baby-design/icons'
import { useTracking } from 'lib/analytics'
import { trackBackToTopClick } from 'src/routes/(shop)/lib/tracking'
import { TrackingHook } from 'react-tracking'
import { useUIContext } from 'src/contexts/UIContext'
import css from './Button.styles.scss'

interface HandleClickArguments {
  isDisplayed: boolean
  trackEvent: TrackingHook['trackEvent']
}

const handleClick = ({ isDisplayed, trackEvent }: HandleClickArguments) => {
  if (isDisplayed) window.scrollTo({ top: 0, behavior: 'smooth' })

  trackBackToTopClick(trackEvent)
}

export const BackToTopButton = ({ isDisplayed }: { isDisplayed: boolean }) => {
  const { trackEvent } = useTracking()
  const { isMobile } = useUIContext()

  return (
    <Button
      className={css.Button}
      icon={{ icon: <RightArrow className={css.Button__icon} />, side: 'left' }}
      size={isMobile ? 'sm' : 'md'}
      variant="inverted"
      onClick={() => handleClick({ isDisplayed, trackEvent })}
    >
      Back to top
    </Button>
  )
}
