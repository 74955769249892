import { ContentContainer } from 'src/routes/(shop)/components/ContentContainer'
import NiceModal from '@ebay/nice-modal-react'
import {
  AdminLink,
  BuyBoxContainer,
  MerchandisingMessageContainer,
  WithoutTooltip,
  FreeShippingAndReturns,
  DeliverySurcharge,
  VirtualBundle,
  PDPHeadline,
  ProductCallouts,
} from '../../BuyBox'
import { Column, StickyColumn } from '../../Layout/Column'
import { Row } from '../../Layout/Row'
import { ProductDetailsSection } from '../../ProductDetailsSection/ProductDetailsSection'
import { ProductImages } from '../../ProductImages/ProductImages'
import { AddToBabylist } from './AddToBabylist'
import { Content } from '../../Content'
import { BackToTopButton } from '../../BackToTop/Button'
import { WaypointWrapper } from '../../BackToTop/Waypoint/Wrapper'
import { VariantSelector } from '../../BuyBox/VariantSelector'
import { ActionsContainer } from '../ActionsContainer'

export const CashFund = () => (
  <NiceModal.Provider>
    <ContentContainer>
      <BackToTopButton />
      <Row>
        <Column width="58%">
          <StickyColumn>
            <ProductImages />
          </StickyColumn>
        </Column>
        <Column width="42%">
          <BuyBoxContainer>
            <PDPHeadline />
            <MerchandisingMessageContainer>
              <WithoutTooltip />
              <FreeShippingAndReturns />
              <DeliverySurcharge />
              <VirtualBundle />
            </MerchandisingMessageContainer>
            <ProductCallouts />
            <VariantSelector />
            <WaypointWrapper />
            <ActionsContainer>
              <AddToBabylist />
            </ActionsContainer>
            <Content />
            <AdminLink />
          </BuyBoxContainer>
        </Column>
      </Row>
      <ProductDetailsSection />
    </ContentContainer>
  </NiceModal.Provider>
)
