import { apiV3GuidesConfigPath } from 'lib/urls'

export const FETCH_GUIDES_CONFIG_KEY = 'FETCH_GUIDES_CONFIG'
export const fetchGuidesConfig = async () => {
  const response = await fetch(apiV3GuidesConfigPath, {
    method: 'GET',
  })

  return response.json()
}
