import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useResetRegistryChecklistCategories } from '../api/hooks'

interface Props {
  registryId: number
}

export const ChecklistAdminBar = ({ registryId }: Props) => {
  const { mutate: reset } = useResetRegistryChecklistCategories(registryId)
  const [currentUser] = useCurrentUser()

  const handleReset = () => {
    reset()
    window.scrollTo(0, 0)
  }

  if (!currentUser?.admin) {
    return null
  }

  return (
    <div className="mvl text-muted h6">
      <h6>Admin Tools</h6>
      <button
        className="btn btn-primary xsmall btn-pill"
        type="button"
        onClick={handleReset}
      >
        Reset Checklist
      </button>
    </div>
  )
}
