/* eslint-disable complexity, import/no-extraneous-dependencies */
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { MostWantedFilled } from 'baby-design/icons'
import { ExpandableTextBox, Tag, TextLink } from 'baby-design'
import { Provider as ReduxProvider } from 'react-redux'
import classNames from 'classnames'

import ScrollLockModal from 'shared/ScrollLockModal'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { SECONDHAND_COPY } from 'registry/constants'
import useSecondhandItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useSecondhandItemsEnabled'
import { getRegistryGiftGiverPageLegacyAdapterStore } from '../RegistryGiftGiverPage/RegistryGiftGiverPageLegacyAdapter'
import { RegItemBuyingOptionsModalProps } from './RegItemBuyingOptionsModal.types'
import css from './RegItemBuyingOptionsModal.styles.scss'
import useGetRegItem from '../../hooks/useGetRegItem'
import RegItemBuyingOptionsModalSkeleton from './RegItemBuyingOptionsModal.skeleton'
import {
  getDetailsUrl,
  getGiftCardPriceRange,
  getIsRegItemDetailsVisible,
  getRegItemBuyingOptionsComponent,
  getRegItemBuyingOptionsTitle,
  getShouldShowDetailsLink,
  sortProductOffersByPrice,
  useGetHandleDetailsLinkClick,
} from './RegItemBuyingOptionsModal.utils'
import { getCashFundPaymentPreferencesString } from '../../utils/getCashFundPaymentPreferences/getCashFundPaymentPreferences'
import {
  getQuantityText,
  shouldDisplayQuantity,
} from '../../utils/regItemQuantityHelper/regItemQuantityHelper'
import getRegItemType from '../../utils/getRegItemType/getRegItemType'

const RegItemBuyingOptionsModal = ({
  regItemId,
  registry,
  variantOverride,
  reservation,
}: RegItemBuyingOptionsModalProps) => {
  const modal = useModal()
  const [currentUser] = useCurrentUser()
  const secondhandItemsEnabled = useSecondhandItemsEnabled()
  const { regItem, regItemQuery } = useGetRegItem(regItemId, registry.id, true)
  const RegItemBuyingOptions = getRegItemBuyingOptionsComponent(
    regItem,
    variantOverride
  )
  const isQuantityVisible = shouldDisplayQuantity(regItem, reservation)
  const { prefix: qtyPrefix, value: qtyValue } = getQuantityText({
    regItem,
    reservation,
    isShortForm: false,
  })
  const isRegItemDetailsVisible = getIsRegItemDetailsVisible(
    isQuantityVisible,
    regItem
  )
  const title = getRegItemBuyingOptionsTitle(regItem, variantOverride)
  const shouldShowDetailsLink = getShouldShowDetailsLink(regItem)
  const cashFundPaymentPreferences =
    getCashFundPaymentPreferencesString(registry)

  const sortedOffers = sortProductOffersByPrice(regItem?.offers)
  const handleDetailsLinkClick = useGetHandleDetailsLinkClick(
    registry,
    regItem,
    currentUser,
    sortedOffers
  )

  const regItemType = variantOverride || getRegItemType(regItem)
  const wrapperClasses = classNames(
    css.RegItemBuyingOptionsModal,
    css[`RegItemBuyingOptionsModal__${regItemType}`]
  )
  const detailsUrl = getDetailsUrl(regItem, sortedOffers)

  return (
    <ScrollLockModal
      showCloseButton
      className={css.RegItemBuyingOptionsModal__ModalOverride}
      handleClose={modal.hide}
      isOpen={modal.visible}
      mobileVariant="basic"
      title={title}
    >
      <div className={wrapperClasses} data-testid="RegItemBuyingOptionsModal">
        {regItemQuery.isLoading && <RegItemBuyingOptionsModalSkeleton />}
        {regItem && (
          <>
            <div className={css.RegItemBuyingOptionsModal__ProductDetails}>
              <div className={css.RegItemBuyingOptionsModal__ProductImage}>
                <img alt={regItem.title} src={regItem.imgUrl} />
              </div>

              <div className={css.RegItemBuyingOptionsModal__ProductInfo}>
                <div className={css.RegItemBuyingOptionsModal__ProductTitle}>
                  {regItem.title}
                </div>
                {shouldShowDetailsLink && (
                  <TextLink
                    underline
                    href={detailsUrl}
                    size="sm"
                    target={detailsUrl ? '_blank' : undefined}
                    weight="regular"
                    onClick={handleDetailsLinkClick}
                  >
                    View details
                  </TextLink>
                )}
                {regItem.isElectronicGiftCard && (
                  <div
                    className={
                      css.RegItemBuyingOptionsModal__ElectronicGiftCard
                    }
                  >
                    {getGiftCardPriceRange(regItem)}
                  </div>
                )}
                {regItem.isCrowdfund && cashFundPaymentPreferences !== '' && (
                  <div
                    className={css.RegItemBuyingOptionsModal__PaymentMethods}
                  >
                    {cashFundPaymentPreferences}
                  </div>
                )}
                <div className={css.RegItemBuyingOptionsModal__ProductTags}>
                  {regItem.isFavorite && (
                    <Tag
                      LeftIcon={
                        <MostWantedFilled
                          className={
                            css.RegItemBuyingOptionsModal__MostWantedIcon
                          }
                        />
                      }
                      size="md"
                      type="primary"
                      variant="normal"
                    >
                      Most Wanted
                    </Tag>
                  )}
                  {secondhandItemsEnabled && regItem.prelovedAllowed && (
                    <Tag size="md" type="primary" variant="normal">
                      {SECONDHAND_COPY}
                    </Tag>
                  )}
                </div>
              </div>
            </div>

            {isRegItemDetailsVisible && (
              <div className={css.RegItemBuyingOptionsModal__RegItemDetails}>
                {isQuantityVisible && (
                  <div
                    className={css.RegItemBuyingOptionsModal__RegItemQuantity}
                  >
                    <span className={css.RegItemBuyingOptionsModal__SubTitle}>
                      {qtyPrefix}:{' '}
                    </span>
                    {qtyValue}
                  </div>
                )}
                {regItem.description && (
                  <ExpandableTextBox
                    copy={regItem.description}
                    size="sm"
                    title={`Note from ${registry.firstNames}:`}
                  />
                )}
              </div>
            )}

            <RegItemBuyingOptions
              currentUser={currentUser}
              regItem={regItem}
              registry={registry}
            />
          </>
        )}
      </div>
    </ScrollLockModal>
  )
}

/*
  TODO: Remove redux once we rewrite all old purchase flow modals.
  Redux provider is only needed for loading the old modals from this context because NiceModal
  components are loaded outside of the parent provider context.
*/
const ReduxProvidedComponent = NiceModal.create(
  (props: RegItemBuyingOptionsModalProps) => (
    <ReduxProvider store={getRegistryGiftGiverPageLegacyAdapterStore()}>
      <RegItemBuyingOptionsModal {...props} />
    </ReduxProvider>
  )
)

export default ReduxProvidedComponent
