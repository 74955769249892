import { compact, filter, find, get, map, sortBy, values } from 'lodash'
import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import * as BLConstants from 'shared/constants'
import collections from './collections-reducer'
import posts from './posts-reducer'
import postsLookup, * as fromPostsLookup from './posts-lookup-reducer'

const helloBabyReducer = combineReducers({
  collections,
  form: formReducer,
  posts,
  postsLookup,
})

export const getNextPost = (state, post) => {
  let nextPost = null
  if (post.nextPostId) {
    nextPost = getPostById(post.nextPostId, state)
  } else if (post.relatedPostSlugs) {
    // use the first related post if it exists. getPreviousPost uses the second related post
    nextPost = getPostBySlug(post.relatedPostIds[0], state)
  }
  return nextPost
}
export const getPreviousPost = (state, post) => {
  let previousPost = null
  if (post.previousPostId) {
    previousPost = getPostById(post.previousPostId, state)
  }
  return previousPost
}

export const getPostSlugsNotInPosts = (postSlugsOrIds, state) =>
  (postSlugsOrIds || []).filter(
    (slugOrId) => !(state.posts[slugOrId] || getPostSlugById(state, slugOrId))
  )

export const filterPostsByType = (posts, postType) => {
  return values(
    sortBy(filter(posts, { type: postType }), 'publishedAt').reverse() // reverse it so the sort is desc
  )
}

export const getTopics = (state) => {
  const topics = {}
  map(
    state.collections[BLConstants.COLLECTION_SLUG_TOPIC_NAVIGATION].items,
    (topic) => {
      topics[topic.slug] = {
        slug: topic.slug,
        name: topic.name,
        description: topic.description,
        pageTitle: topic.pageTitle,
      }
      map(topic.children, (child) => {
        topics[child.slug] = {
          slug: child.slug,
          name: child.name,
          description: child.description,
          pageTitle: child.pageTitle,
        }
      })
    }
  )
  return topics
}

export const getSubTopics = (state, topicSlug) => {
  let subTopics = null
  subTopics = find(
    state.collections[BLConstants.COLLECTION_SLUG_TOPIC_NAVIGATION].items,
    { slug: topicSlug }
  )
  if (subTopics) {
    return subTopics.children
  }
  return null
}

export const getPosts = (state) => {
  return state.posts
}

export const getTopicNameForSlug = (topicSlug, state) => {
  const topics = getTopics(state)
  return get(topics[topicSlug], 'name')
}

export const getTopicDescriptionForSlug = (topicSlug, state) => {
  const topics = getTopics(state)
  return get(topics[topicSlug], 'description')
}

export const getTopicPageTitleForSlug = (topicSlug, state) => {
  const topics = getTopics(state)
  return get(topics[topicSlug], 'pageTitle')
}

export const getPostSlugById = (state, slugOrId) =>
  fromPostsLookup.getPostSlugById(state.postsLookup, slugOrId)

export const getPostBySlug = (slug, posts) => posts[slug]

export const getPostsBySlugs = (postSlugs, state) =>
  compact(postSlugs.map((slug) => getPostBySlug(slug, state.posts)))

export const getPostById = (id, state) =>
  getPostBySlug(getPostSlugById(state, id), state.posts)

export const getPostsByIds = (postIds, state) =>
  compact(postIds.map((id) => getPostById(id, state)))

export const getActiveSlugSet = (slug, topicNavigation) => {
  const activeSlugSet = []
  map(topicNavigation.items, (topic) => {
    if (activeSlugSet.length < 2) activeSlugSet[0] = topic.slug
    map(topic.children, (child) => {
      if (child.slug === slug) {
        activeSlugSet['1'] = child.slug
      }
    })
  })
  if (activeSlugSet.length < 2) activeSlugSet[0] = slug
  return activeSlugSet
}

export default helloBabyReducer
