import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { absoluteUrl, healthAdvisoryBoardPath } from 'lib/urls'
import HealthAdvisoryBoard from './components/HealthAdvisoryBoard'

const HealthAdvisoryBoardPage: FC = () => {
  const title = 'Health Advisory Board | Babylist Inc.'
  const description =
    'Meet the Babylist Health Advisory Board, a group of renowned doctors, researchers and health professionals that Babylist consults for expertise.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(healthAdvisoryBoardPath)} rel="canonical" />
      </Helmet>
      <HealthAdvisoryBoard />
    </div>
  )
}

export default HealthAdvisoryBoardPage
