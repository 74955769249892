import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Image } from 'baby-design'
import CurvedHeader from '../../components/CurvedHeader/CurvedHeader'

import css from './Locations.styles.scss'
import ExperienceCardFull from './components/ExperienceCardFull/ExperienceCardFull'

const Locations: FC = () => (
  <div className={css.Locations}>
    <CurvedHeader
      className={css.Locations__curvedHeaderBackground}
      heading="Celebrate the moment"
      subheading={
        <span>
          Discover the best selection of baby products in Los Angeles or online,
          <br />
          from the comfort of home.
        </span>
      }
    />
    <section className={css.Locations__primarySection}>
      <div className={css.Locations__textAndImageContainer}>
        <div className={css.Locations__mainContentContainer}>
          <h2 className={css.Locations__primaryHeading}>Our locations</h2>
          <div className={css.Locations__primaryText}>
            <p>
              We blend in-person, digital and virtual experiences to help you
              prepare for a new baby whenever and however is best for you.
            </p>
            <p>
              Our guided real-life and online showrooms display baby gear and
              products in places where you'll actually use them.
            </p>
            <p>
              So instead of wading through boxes at a retail store, you can test
              drive gear and see firsthand how different bassinets fit next to a
              bed, how a stroller rolls on different terrain and how much
              kitchen space is needed for all those bottles.
            </p>
            <p>
              Simply click or scan for instant access to editorial guides,
              real-parent reviews or social media posts to help you learn even
              more about choosing the best product for you.
            </p>
            <p>
              Test drive the best selection of strollers, find your perfect
              nursery aesthetic and get hands on with all the baby gear you
              need. Stop by and say hi if you&rsquo;re in Los Angeles, or visit
              a virtual showroom from the comfort of your home.
            </p>
          </div>
        </div>
        <div className={css.Locations__primaryImageContainer}>
          <Image
            alt="A bright image of Babylist's Los Angeles Showroom with colorful shapes around it."
            className={css.Locations__primaryImage}
            src="https://images.babylist.com/image/upload/v1719257773/Consumer%20About%20Pages/Locations_mainImage.png"
          />
        </div>
      </div>
      <div className={css.Locations__primaryActionsContainer}>
        <Button href="#irl_section" size="lg" variant="primary">
          See IRL locations
        </Button>
      </div>
    </section>
    <section className={css.Locations__irlSection} id="irl_section">
      <div className={css.Locations__irlContentContainer}>
        <div className={css.Location__irlHeadingContainer}>
          <h2 className={css.Locations__irlHeading}>IRL experiences</h2>
          <p className={css.Locations__irlSubheading}>
            Bring the grandparents, new aunties, best friends & other loved
            ones. <br className={css.Location__irlSubheadingLineBreak} />
            It's registry building, shopping and community like never before.
          </p>
        </div>
        <div className={css.Locations__irlExperiences}>
          <ExperienceCardFull
            className={css.Locations__irlExperience}
            ctaHref="https://www.google.com/maps/place/Babylist+Beverly+Hills+Showroom+%26+Shop/@34.0644711,-118.3994688,15z/data=!4m2!3m1!1s0x0:0x6017aca0d6551910?sa=X&ved=1t:2428&ictx=111"
            description="Discover, test and select the products that are right for your growing family in our 18,000 square-foot immersive experience."
            heading="Babylist Beverly Hills"
            image={{
              altText: 'Babylist Beverly Hills',
              url: '//images.babylist.com/image/upload/v1700505579/experience-page/BABYLIST-BEV-HILLS-41.webp',
            }}
            subheading="Los Angeles | Now open"
          />
        </div>
      </div>
    </section>
    <section className={css.Locations__pastExperiencesSection}>
      <div className={css.Locations__pastContentContainer}>
        <h2 className={css.Locations__pastHeading}>Past experiences</h2>
        <div className={css.Locations__pastExperiences}>
          <div className={css.Locations__pastExperience}>
            <div className={css.Location__pastExperienceImageContainer}>
              <Image
                alt="Babylist's past popup shop in LA"
                className={css.Locations__pastExperienceImage}
                src="https://images.babylist.com/image/upload/v1676306876/experience-page/experiences-cribs_2x-optimized.webp"
              />
            </div>
            <div className={css.Locations__pastExperienceContent}>
              <div className={css.Location__pastExperienceNameAndDate}>
                <p className={css.Location__pastExperienceDate}>January 2022</p>
                <h3 className={css.Location__pastExperienceName}>
                  Babylist Pop-Up, Los Angeles
                </h3>
              </div>
              <Button
                href="https://www.youtube.com/watch?v=w8viE7CdSik"
                size="sm"
                target="_blank"
                variant="primary"
              >
                Watch video
              </Button>
            </div>
          </div>
          <div className={css.Locations__pastExperience}>
            <div className={css.Location__pastExperienceImageContainer}>
              <Image
                alt="Babylist's past popup shop in NYC"
                className={css.Locations__pastExperienceImage}
                src="https://images.babylist.com/image/upload/v1676314001/experience-page/experiences-cribsny_2x-optimized.webp"
              />
            </div>
            <div className={css.Locations__pastExperienceContent}>
              <div className={css.Location__pastExperienceNameAndDate}>
                <p className={css.Location__pastExperienceDate}>May 2022</p>
                <h3 className={css.Location__pastExperienceName}>
                  Babylist Pop-Up, New York City
                </h3>
              </div>
              <Button
                href="https://www.tiktok.com/@babylist/video/7096576852805848366?lang=en"
                size="sm"
                target="_blank"
                variant="primary"
              >
                Watch video
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default Locations
