import { ShipmentsAPI } from 'src/routes/(registry)/shipments/api/shipments'

import { useFakeData } from './useFakeData'

export const useShipments = () => {
  const fakeData = useFakeData()

  const {
    data: { shipments } = { shipments: [] },
    refetch,
    isLoading,
  } = ShipmentsAPI.useIndex({ fakeData })

  return {
    isLoading,
    refetch,
    shipments,
  }
}
