import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Button } from 'baby-design'
import { Share, Eye } from 'baby-design/icons'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { track, useTracking } from 'lib/analytics/track'
import { registryViewAsGuestPath } from 'lib/urls'
import ShareYourRegistryDrawer from './ShareYourRegistryDrawer/ShareYourRegistryDrawer'
import css from './RegistryActions.styles.scss'
import { RegistryActionsProps } from './RegistryActions.types'

const RegistryActions: React.FC<RegistryActionsProps> = ({
  currentRegistry,
}) => {
  const tracker = useTracking()
  const [currentUser] = useCurrentUser()
  const registryShareUrl =
    currentRegistry?.giftGiverUrl?.match(/https?:\/\/(.*)/)?.[1] || ''

  const handleClickPreview = () => {
    tracker.trackEvent({
      event: track.guestViewClicked,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: currentRegistry?.id,
      registryOwnerId: currentRegistry?.ownerId,
      listType: currentRegistry?.type || 'baby_registry',
      isRegistryOwner: currentRegistry?.ownerId === currentUser?.id,
    })

    window.open(registryViewAsGuestPath(currentRegistry?.urlSlug), '_blank')
  }

  const handleClickShare = () => {
    NiceModal.show(ShareYourRegistryDrawer, {
      onPreviewClick: handleClickPreview,
      registryLink: registryShareUrl,
    })
  }

  return (
    <div className={css.buttonContainer}>
      <div className={css.buttonRow}>
        <Button
          className={css.buttonRowTextSecondaryButton}
          icon={{ side: 'left', icon: <Share /> }}
          size="sm"
          variant="text-secondary"
          onClick={handleClickShare}
        >
          Share
        </Button>
        <Button
          className={css.buttonRowTextSecondaryButton}
          icon={{ side: 'left', icon: <Eye /> }}
          size="sm"
          variant="text-secondary"
          onClick={handleClickPreview}
        >
          Guest View
        </Button>
      </div>
    </div>
  )
}

export default RegistryActions
