import { useEffect, useState } from 'react'
import classNames from 'classnames'
import fetch from 'lib/fetch'
import { BOOKMARKLET_URL } from 'registry/constants'
import { browserName } from 'lib/bowser'
import css from './RegistryAddItems.styles.scss'

import Header from '../Header/Header'
import AddExtension from '../AddExtension/AddExtension'
import TransferItems from '../TransferItems/TransferItems'
import BrowseByStores from '../BrowseByStores/BrowseByStores'
import BrowseByBrands from '../BrowseByBrands/BrowseByBrands'
import CashGifts from '../CashGifts/CashGifts'
import PopularGuides from '../PopularGuides/PopularGuides'
import AppDownload from '../AppDownload/AppDownload'
import FrequentlyAskedQuestions from '../FrequentlyAskedQuestions/FrequentlyAskedQuestions'

import { RegistryAddItemsProps } from './RegistryAddItems.types'

const RegistryAddItems: React.FC<RegistryAddItemsProps> = () => {
  const RegistryAddItemsClasses = classNames(css.RegistryAddItems)

  const [bookmarkletJS, setBookmarkletJS] = useState('')

  useEffect(() => {
    if (browserName === 'Chrome') return

    fetch(BOOKMARKLET_URL).then((data: any) => {
      setBookmarkletJS(data.bookmarkletJs || '')
    })
  }, [])

  return (
    <div className={RegistryAddItemsClasses}>
      <Header className={css.RegistryAddItems__module} />
      <AddExtension
        bookmarkletJS={bookmarkletJS}
        className={css.RegistryAddItems__module}
      />
      <TransferItems className={css.RegistryAddItems__module} />
      <BrowseByStores className={css.RegistryAddItems__module} />
      <BrowseByBrands className={css.RegistryAddItems__module} />
      <CashGifts className={css.RegistryAddItems__module} />
      <PopularGuides className={css.RegistryAddItems__module} />
      <AppDownload className={css.RegistryAddItems__module} />
      <FrequentlyAskedQuestions className={css.RegistryAddItems__module} />
    </div>
  )
}

export default RegistryAddItems
