import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextLink } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'baby-design/icons'

import { useContactInfoAddress } from 'registry/components/modals/ContactInfoModal'
import { ShippingAddressProps } from './ShippingAddress.types'
import css from './ShippingAddress.styles.scss'
import {
  getName,
  getShouldLoadAddress,
  getHasPublicAddress,
  getShouldShowPrivateAddressDisclaimer,
  getShouldShowAddress,
  getShouldShowAskForAddress,
} from './ShippingAddress.utils'

const ShippingAddress: React.FC<ShippingAddressProps> = ({ registry }) => {
  const [showAddress, setShowAddress] = useState(false)
  const hasPublicAddress = getHasPublicAddress(registry)
  const shouldLoadAddress = getShouldLoadAddress(registry, showAddress)
  const name = getName(registry)

  const { data: addressData, isFetching: isAddressFetching } =
    useContactInfoAddress({
      addressId: String(registry.addressId),
      enabled: shouldLoadAddress,
      reCaptchaAction: 'redirect_modal',
    })

  const shouldShowPrivateAddressDisclaimer =
    getShouldShowPrivateAddressDisclaimer(
      hasPublicAddress,
      showAddress,
      isAddressFetching,
      addressData
    )

  const shouldShowAddress = getShouldShowAddress(
    hasPublicAddress,
    showAddress,
    isAddressFetching,
    addressData
  )

  const shouldAskForAddress = getShouldShowAskForAddress(
    hasPublicAddress,
    showAddress,
    isAddressFetching
  )

  return (
    <div className={css.ShippingAddress} data-testid="ShippingAddress">
      <div>
        <div className={css.ShippingAddress__AddressIcon}>
          <Location />
        </div>
      </div>
      <div className={css.ShippingAddress__AddressWrapper}>
        {shouldAskForAddress && (
          <>
            <div>Need {name}'s shipping address?</div>
            <TextLink
              className={css.ShippingAddress__AddressLink}
              disabled={isAddressFetching}
              size="md"
              onClick={() => setShowAddress(true)}
            >
              {isAddressFetching ? 'Loading...' : 'Show Me'}
            </TextLink>
          </>
        )}

        {shouldShowAddress && (
          <>
            <div className={css.ShippingAddress__Address} data-testid="Address">
              <div>{addressData?.fullName}</div>
              <div>
                {addressData?.address.streetAddress1}{' '}
                {addressData?.address.streetAddress2 ? (
                  <span>, {addressData?.address.streetAddress2}</span>
                ) : null}
              </div>
              <div>
                {addressData?.address.city}, {addressData?.address.state}{' '}
                {addressData?.address.postalCode}
              </div>
            </div>
            <div className={css.ShippingAddress__Disclaimer}>
              Address does not transfer automatically!
            </div>
          </>
        )}

        {shouldShowPrivateAddressDisclaimer && (
          <>
            <div className={css.ShippingAddress__PrivateAddress}>
              {name}'s shipping address is private.
            </div>
            <div className={css.ShippingAddress__Disclaimer}>
              We recommend reaching out to them directly to request it.
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ShippingAddress
