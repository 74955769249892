import { useParams } from 'react-router-dom-v5-compat'
import TopicSection from 'hello_baby/components/TopicSection'
import { filterPostsByTopicSlug } from 'hello_baby/lib'
import { usePosts, useTopic } from '../../utils/useQueries'

const NUM_VISIBLE_POSTS = 4

const FriendsAndFamilyView = () => {
  const { slug } = useParams()

  const posts = usePosts()
  const subTopics = useTopic(slug)?.children || []

  return (
    <>
      {subTopics.map((childTopic: Topic) => (
        <TopicSection
          key={`topic_section_${childTopic.slug}`}
          posts={filterPostsByTopicSlug(posts, childTopic.slug).slice(
            0,
            NUM_VISIBLE_POSTS
          )}
          showTopics={false}
          topic={{ name: childTopic.name, slug: childTopic.slug }}
        />
      ))}
    </>
  )
}

export default FriendsAndFamilyView
