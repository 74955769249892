// eslint-disable-next-line import/no-extraneous-dependencies
import { StoreIcon, PriceTag } from 'baby-design'
import classNames from 'classnames'
import { Offer } from 'src/types/offer'
import {
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import css from './BuyingOption.styles.scss'
import { formatDisplayPrice } from '../../../../../../utils/productHelpers'

interface BuyingOptionProps {
  option: Offer
  onBuyingOptionClick: (offer: Offer) => void
  isGiftCard: boolean
}

const LegacyPriceTag = ({
  price,
  isGiftCard,
}: {
  price: string | number | undefined
  isGiftCard: boolean
}) => (
  <div className={css.buying_option__price}>
    {isGiftCard ? '$25 – $1,000' : formatDisplayPrice(price)}
  </div>
)

export const BuyingOption = ({
  option,
  onBuyingOptionClick,
  isGiftCard,
}: BuyingOptionProps) => {
  let priceTagProps = {}
  if (isGiftCard) {
    priceTagProps = {
      minPrice: ELECTRONIC_GIFT_CARD_MIN,
      maxPrice: ELECTRONIC_GIFT_CARD_MAX,
      truncateZeroCents: true,
    }
  } else if (option.priceDetails) {
    priceTagProps = getPriceTagPropsFromPriceDetails(option.priceDetails)
  }

  return (
    <a
      className={classNames(
        css.buying_option,
        option.priceDetails?.saleAttributes?.saleType &&
          css['buying_option--on-sale']
      )}
      onClick={() => onBuyingOptionClick(option)}
    >
      <div className={css.buying_option__info}>
        <StoreIcon
          className={css.buying_option__icon}
          store={{ name: option.storeIconName }}
        />
        <div className={css.buying_option__name}>{option.storeDisplayName}</div>
      </div>
      <div className={css.buying_option__price}>
        {option.priceDetails ? (
          <div className={css.buying_option__pricetag}>
            <PriceTag {...priceTagProps} size="2xs" variant="compact" />
          </div>
        ) : (
          <LegacyPriceTag isGiftCard={isGiftCard} price={option.price} />
        )}
      </div>
    </a>
  )
}
