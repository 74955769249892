import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, RangeSlider, TextLink } from 'baby-design'
import { useAppliedSortsAndFiltersContext } from '../../../../contexts'
import { PriceRangeFilterProps } from './PriceRangeFilter.types'
import css from './PriceRangeFilter.styles.scss'

const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({
  sortFilterOptions,
  closePopover,
  previewFilterResults,
}: PriceRangeFilterProps) => {
  const { appliedSortsAndFilters, onSortFilterChange } =
    useAppliedSortsAndFiltersContext()
  const { filters } = sortFilterOptions
  const { price } = filters

  const lowerBound = price.options.min
  const upperBound = price.options.max

  const [previewMinPrice, setPreviewMinPrice] = React.useState<number>(
    Number(appliedSortsAndFilters.filters?.price?.min || lowerBound)
  )
  const [previewMaxPrice, setPreviewMaxPrice] = React.useState<number>(
    Number(appliedSortsAndFilters.filters?.price?.max || upperBound)
  )

  const previewSortFiltersDiff = {
    filters: {
      price: {
        min: previewMinPrice,
        max: previewMaxPrice,
      },
    },
  }

  const { count: previewCount } = previewFilterResults(
    previewSortFiltersDiff,
    appliedSortsAndFilters
  )

  const priceRangeFilterRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    priceRangeFilterRef.current?.focus()
  }, [priceRangeFilterRef])

  return (
    <div
      aria-label="Filter by price range"
      className={css.PriceRangeFilter}
      data-testid="PriceRangeFilter"
      ref={priceRangeFilterRef}
      tabIndex={-1}
    >
      <RangeSlider
        openEndedMax
        lowerBound={lowerBound}
        type="currency"
        upperBound={upperBound}
        values={{
          lesser: previewMinPrice,
          greater: previewMaxPrice,
        }}
        onChange={(lesser, greater) => {
          setPreviewMinPrice(lesser)
          setPreviewMaxPrice(greater)
        }}
      />

      <div className={css.PriceRangeFilter__Actions}>
        <TextLink
          underline
          size="md"
          variant="default"
          onClick={() => {
            onSortFilterChange({ filters: { price: undefined } })
            closePopover()
          }}
        >
          Reset
        </TextLink>

        <Button
          disabled={previewCount === 0}
          size="sm"
          variant="primary"
          onClick={() => {
            onSortFilterChange({
              filters: {
                price: {
                  min: previewMinPrice,
                  max: previewMaxPrice,
                },
              },
            })
            closePopover()
          }}
        >
          {previewCount === 0
            ? 'No items available'
            : `Show ${previewCount} available items`}
        </Button>
      </div>
    </div>
  )
}

export default PriceRangeFilter
