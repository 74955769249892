import { useEffect, RefObject, useRef } from 'react'
import { useLocation } from 'react-router-dom-v5-compat'

interface QueryParamConfig {
  param: string
  value: string
}

interface UseScrollToElementParams {
  isEnabled: boolean
  ref: RefObject<HTMLElement>
  queryParam?: QueryParamConfig
  delay?: number
}

const useScrollToElement = ({
  queryParam,
  ref,
  isEnabled,
  delay,
}: UseScrollToElementParams) => {
  const location = useLocation()
  const hasScrolled = useRef(false)
  const isServer = typeof window === 'undefined'

  useEffect(() => {
    if (isServer || !isEnabled || !ref.current || hasScrolled.current) return

    if (queryParam) {
      const queryParams = new URLSearchParams(location.search)
      const param = queryParams.get(queryParam.param)
      if (!param || param !== queryParam.value) return
    }

    const scrollToLocation = () => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
        hasScrolled.current = true
      }
    }

    if (delay === undefined) {
      scrollToLocation()
    } else {
      setTimeout(scrollToLocation, delay)
    }
  }, [location, queryParam, ref, isEnabled, isServer, delay])
}

export default useScrollToElement
