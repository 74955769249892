import css from './QuantityLabel.styles.scss'

interface QuantityLabelProps {
  quantity: number
  max?: number | undefined
}

export const QuantityLabel = ({ quantity, max }: QuantityLabelProps) => (
  <label aria-label={`Quantity: ${quantity}`} className={css.QuantityLabel}>
    <span className={css.QuantityLabel__header}>Quantity</span>{' '}
    <span className={css.QuantityLabel__quantity}>{quantity}</span>
    {max && <p className="h7">Limit of {max} per customer</p>}
  </label>
)
