import { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'

import { openCrowdfundReservationModal } from 'registry/modals/actions'
import { RegItem } from 'src/types/regItem'

export const useGetHandleContributeClick = (regItem: RegItem) => {
  const modal = useModal()
  const dispatch = useDispatch()

  return () => {
    modal.hide()
    dispatch(openCrowdfundReservationModal(regItem))
  }
}
