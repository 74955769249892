import React, { useState } from 'react'
import DecoratedShipmentCard from 'src/routes/(registry)/shipments/components/DecoratedShipmentCard/DecoratedShipmentCard'
import { ShipmentsSectionProps } from './ShipmentsSection.types'
import css from './ShipmentsSection.styles.scss'
import { Button } from '../../../../../../baby-design'

const PAGE_SIZE = 5
const INITIAL_VISIBLE_SHIPMENTS_COUNT = 3

const ShipmentsSection: React.FC<ShipmentsSectionProps> = ({
  shipments,
  title,
}) => {
  const [visibleShipments, setVisibleShipments] = useState<number>(
    INITIAL_VISIBLE_SHIPMENTS_COUNT
  )

  if (shipments.length === 0) {
    return null
  }

  const handleSeeMore = () => setVisibleShipments(visibleShipments + PAGE_SIZE)

  return (
    <div className={css.ShipmentsSection}>
      <h2 className={css.ShipmentsSection__Title}>{title}</h2>
      <div className={css.ShipmentsSection__Shipments}>
        {shipments.slice(0, visibleShipments).map((shipment) => (
          <DecoratedShipmentCard key={shipment.id} shipment={shipment} />
        ))}
        {visibleShipments < shipments.length && (
          <Button
            className={css.ShipmentsSection__SeeMoreButton}
            size="md"
            variant="tertiary"
            onClick={handleSeeMore}
          >
            See more
          </Button>
        )}
      </div>
    </div>
  )
}

export default ShipmentsSection
