import {
  MessageWithTooltip as Component,
  TooltipProps,
} from './MessageWithTooltip'

export const MessageWithTooltip = ({
  message,
  content,
  type,
  onClick,
}: TooltipProps) => (
  <Component
    content={content}
    message={message}
    type={type}
    onClick={onClick}
  />
)
