import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import HtmlContent from 'shared/html-content'
import RegistryAvatar from 'registry/components/registry-avatar'
import { Wave } from 'components/curves'
import css from './GuestRegistryHeader.scss'
import {
  SectionHeading,
  SubHeading as SectionSubHeading,
} from '../../../components/headings'

export interface BabyRegistryHeaderLayoutProps {
  arrivalDate?: string
  note?: string | null
  photoUrl?: string | null
  title: string
  waveColor?: string
}

export const BabyRegistryHeaderLayout = ({
  arrivalDate,
  photoUrl,
  note,
  title,
  waveColor = '#fff',
}: BabyRegistryHeaderLayoutProps) => {
  let formattedArrivalDate: string | null = null
  formattedArrivalDate = arrivalDate
    ? format(parseISO(arrivalDate), 'MMMM d, yyyy')
    : null

  const formattedNote = note ? `${note.split('\n').join('</p><p>')}` : null

  return (
    <>
      <div className={css.registryHeaderContainer}>
        <div className="container">
          <SectionHeading className={css.title}>{title}</SectionHeading>

          {formattedArrivalDate && (
            <SectionSubHeading
              className={classNames('arrival-date h5', css.date)}
            >
              {'Arrival Date '}
              <strong>{formattedArrivalDate}</strong>
            </SectionSubHeading>
          )}

          <div className={css.note}>
            {formattedNote && (
              <HtmlContent as="div" html={`<p>${formattedNote}</p>`} />
            )}
          </div>

          {photoUrl && (
            <RegistryAvatar
              altText={`${title} Photo`}
              className={classNames(css.avatar, 'img-responsive')}
              url={photoUrl}
            />
          )}
        </div>
        <div
          className={classNames(
            css.waveWrapper,
            !photoUrl ? css.noPhoto : null
          )}
        >
          <Wave color={waveColor} />
        </div>
      </div>
    </>
  )
}
