import { Helmet } from 'react-helmet-async'
import { babylistUrl, pdpPath } from 'lib/urls'

interface MetaTagsProps {
  genericProduct: any
}

export const MetaTags = ({ genericProduct }: MetaTagsProps) => {
  const { activeProduct } = genericProduct

  if (!activeProduct) return null

  const title = activeProduct?.seoTitle
  const description = activeProduct?.seoDescription
  const url = babylistUrl(
    pdpPath(genericProduct.slug, genericProduct.id, activeProduct.id)
  )

  return (
    <Helmet prioritizeSeoTags>
      {title && <title>{title}</title>}
      {title && <meta content={title} property="og:title" />}
      {description && <meta content={description} name="description" />}
      {description && <meta content={description} property="og:description" />}
      <link href={url} rel="canonical" />
      <meta content="product" property="og:type" />
    </Helmet>
  )
}
