import React, { useContext } from 'react'
import { track, useTracking } from 'lib/analytics'
import { registryChecklistPath } from 'lib/urls'
import { useNavigate } from 'react-router-dom-v5-compat'
import RegistryContext from 'src/contexts/registry'
import { Button } from '../../../../../../../../../baby-design'

const ChecklistButton: React.FC = () => {
  const tracker = useTracking()
  const navigate = useNavigate()
  const { registryId, registryOwnerId, isRegistryOwner } =
    useContext(RegistryContext)

  const handleEmptyRegistryChecklistClick = () => {
    tracker.trackEvent({
      checklistType: 1,
      event: track.checklistViewed,
      registryId,
      registryOwnerId,
      isRegistryOwner,
      listType: track.ListType.BABY_REGISTRY, // this button is only shown for baby registries
    })
    navigate(registryChecklistPath)
  }

  return (
    <Button
      size="md"
      variant="primary"
      onClick={() => handleEmptyRegistryChecklistClick()}
    >
      Go to checklist
    </Button>
  )
}

export default ChecklistButton
