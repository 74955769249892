import React from 'react'
import dateFormat from 'dateformat'
import { Banner } from 'baby-design'
import {
  NOTICE_TYPE_MULTI_ORDER_SHIPMENT,
  STATUS_DELIVERED,
  STATUS_PROCESSING,
  STATUS_PREPARING,
  STATUS_SHIPPED,
} from 'src/routes/(registry)/shipments/util/constants'
import { ConsolidatedShippingCallout } from 'src/components'
import { Shipment, ShipmentStatus } from 'src/types/shipments'
import { simpleShippingBundle } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import { ShipmentDetailsNotificationBannerProps } from './ShipmentDetailsNotificationBanner.types'
import css from './ShipmentDetailsNotificationBanner.styles.scss'

const shouldNotRenderFor = (shipment: Shipment) => {
  const currentState = shipment.shipmentStatus?.currentState
  if (!currentState || currentState === STATUS_DELIVERED) return true

  return false
}

const ComponentWrapper: React.FC = ({ children }) => (
  <div data-testid="shipment-details-notification-banner">{children}</div>
)

const ShipmentDetailsNotificationBanner: React.FC<
  ShipmentDetailsNotificationBannerProps
> = ({ shipment }) => {
  if (!shipment || shouldNotRenderFor(shipment)) return null

  const { currentState } = shipment.shipmentStatus as ShipmentStatus

  if (shipment.noticeType === NOTICE_TYPE_MULTI_ORDER_SHIPMENT) {
    return (
      <ComponentWrapper>
        <Banner
          bodyCopy="Contains items from multiple orders."
          color="primary"
          dismissable={false}
          variant="ultra-compact"
        />
      </ComponentWrapper>
    )
  }

  const isPreparingOrShipped = [STATUS_PREPARING, STATUS_SHIPPED].some(
    (state) => state === currentState
  )
  if (simpleShippingBundle(shipment) && isPreparingOrShipped) {
    return (
      <ComponentWrapper>
        <ConsolidatedShippingCallout text="This shipment may contain multiple orders and have extended shipping timelines." />
      </ComponentWrapper>
    )
  }

  if (simpleShippingBundle(shipment) && currentState === STATUS_PROCESSING) {
    let text: string | React.ReactNode =
      'This shipment may contain multiple orders and have extended shipping timelines.'

    const releaseDate = shipment.shipmentStatus?.releaseDate
    if (releaseDate) {
      const releaseDateFormatted = dateFormat(releaseDate, 'mmm d')
      text = (
        <span className={css.ShipmentDetailsNotificationBanner__text}>
          Items purchased from the Babylist Shop will be added to this bundle
          until <strong>{releaseDateFormatted}</strong>.
        </span>
      )
    }

    return (
      <ComponentWrapper>
        <ConsolidatedShippingCallout text={text} />
      </ComponentWrapper>
    )
  }

  return null
}

export default ShipmentDetailsNotificationBanner
