import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { aboutCareersPath, absoluteUrl } from 'lib/urls'
import AboutCareers from './components/AboutCareers'

const AboutCareersRoute: FC = () => {
  const title = 'Careers | Babylist Inc.'
  const description =
    'Check out job openings at Babylist! Learn about our core values, employee benefits, why you should join the Babylist Team.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(aboutCareersPath)} rel="canonical" />
      </Helmet>
      <AboutCareers />
    </div>
  )
}

export default AboutCareersRoute
