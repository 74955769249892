export const FEATURED_POSTS_COLLECTION_SLUG = 'featured-posts'
export const GEAR_SLUG = 'gear'
export const PREGNANCY_SLUG = 'pregnancy'
export const PREGNANCY_WEEK_BY_WEEK_SLUG = 'pregnancy-week-by-week'
export const PARENTING_SLUG = 'parenting'
export const SAMPLE_REGISTRIES_SLUG = 'sample-registries'
export const FRIENDS_AND_FAMILY_SLUG = 'friends-and-family'
export const PREGNANCY_FIRST_TRIMESTER_SLUG = 'first-trimester-of-pregnancy'
export const PREGNANCY_SECOND_TRIMESTER_SLUG = 'second-trimester-of-pregnancy'
export const PREGNANCY_THIRD_TRIMESTER_SLUG = 'third-trimester-of-pregnancy'
export const PREGNANCY_WBW_FIRST_TRIMESTER_SLUG = 'first-trimester'
export const PREGNANCY_WBW_FIRST_TRIMESTER_TEASER =
  "Congratulations, you're pregnant! A lot happens during the first trimester of pregnancy. From pregnancy symptoms to baby's development, here's what to know about the first trimester weeks, from 4 weeks to 12 weeks pregnant."
export const PREGNANCY_WBW_SECOND_TRIMESTER_SLUG = 'second-trimester'
export const PREGNANCY_WBW_SECOND_TRIMESTER_TEASER =
  "You're in the second trimester of pregnancy! These guides for 13 weeks pregnant to 27 weeks pregnant will help you get ready for this stage of pregnancy. From ultrasounds to development, here's what's happening with you and baby during the second trimester weeks."
export const PREGNANCY_WBW_THIRD_TRIMESTER_SLUG = 'third-trimester'
export const PREGNANCY_WBW_THIRD_TRIMESTER_TEASER =
  "It's the third trimester now, and your baby will be here soon. As you prepare, find out what's happening with you and baby during these third trimester weeks. Here's what to know about this pregnancy stage, from contractions to birth."
export const YOUR_BABY_WEEK_BY_WEEK_SLUG = 'your-baby-week-by-week'
