import { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InfoDropdownButton } from 'components/buttons'
import ReservationFilter from '../reg-item-filters/reservation-filters'
import PriceFilter from '../reg-item-filters/price-filter'
import StoreFilters from '../reg-item-filters/store-filters'
import FavoriteFilters from '../reg-item-filters/favorite-filters'
import GroupGiftFilter from '../reg-item-filters/group-gift-filter'
import {
  REG_ITEM_RESERVATION_STATUS_AVAILABLE,
  REG_ITEM_RESERVATION_STATUS_RESERVED,
} from '../../actions/reg-items-actions'

const dropdownTitle = (label, numSelected) =>
  numSelected > 0 ? `${label} (${numSelected})` : label

export const GuestFilterBar = ({
  addFilter,
  className,
  favoriteRegItemsCount,
  groupGiftsRegItemsCount,
  filters,
  stores,
}) => {
  useEffect(() => {
    // the logic this filter applies is duplicated in /api/v3/registries/:registry_id/reg_items
    // if this initial filter changes, the filter logic on the backend should too
    addFilter({ giftView: REG_ITEM_RESERVATION_STATUS_AVAILABLE })
  }, [])

  function getViewTitle(giftView) {
    if (giftView === REG_ITEM_RESERVATION_STATUS_AVAILABLE) {
      return 'Available Gifts'
    }
    if (giftView === REG_ITEM_RESERVATION_STATUS_RESERVED) {
      return 'Reserved & Purchased'
    }
    return 'All Gifts'
  }
  return (
    <>
      <div className={classNames(className, 'flex')}>
        <ReservationFilter
          addFilter={addFilter}
          className="mbn phm"
          decorator={(children) => (
            <InfoDropdownButton
              id="reservation-filters-dropdown"
              className="mrm mrl-md"
              title={getViewTitle(filters.giftView)}
              size="small"
              selected={!!filters.giftView}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.giftView}
        />
        <PriceFilter
          addFilter={addFilter}
          className="mbn phm"
          expanded
          decorator={(children) => (
            <InfoDropdownButton
              id="price-filters-dropdown"
              className="visible-xs mrm mrl-md"
              title={dropdownTitle(
                'Price',
                filters.priceRange && filters.priceRange.length
              )}
              size="small"
              selected={!!filters.priceRange && filters.priceRange.length}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.priceRange}
        />
        <StoreFilters
          addFilter={addFilter}
          className="mbn phm"
          decorator={(children) => (
            <InfoDropdownButton
              id="store-filters-dropdown"
              className="mrm mrl-md"
              title={dropdownTitle(
                'Stores',
                filters.storeNames && filters.storeNames.length
              )}
              size="small"
              selected={!!filters.storeNames && filters.storeNames.length}
            >
              {children}
            </InfoDropdownButton>
          )}
          selected={filters.storeNames}
          stores={stores}
        />
        {favoriteRegItemsCount > 0 && (
          <FavoriteFilters
            addFilter={addFilter}
            className="mrm mrl-md hidden-xs"
            selected={filters.favoritesView}
          />
        )}
        {groupGiftsRegItemsCount > 0 && (
          <GroupGiftFilter
            addFilter={addFilter}
            className="hidden-xs mrm"
            selected={filters.groupGiftView}
          />
        )}
      </div>
    </>
  )
}

GuestFilterBar.propTypes = {
  addFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  favoriteRegItemsCount: PropTypes.number.isRequired,
  groupGiftsRegItemsCount: PropTypes.number.isRequired,
  filters: PropTypes.object,
  stores: PropTypes.arrayOf({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }),
}

GuestFilterBar.defaultProps = {
  className: undefined,
  filters: {},
  stores: [],
}
