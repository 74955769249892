import { CurrentUser } from '../../../../../../../global'

export const isRegistryContributor = (
  currentUser?: CurrentUser,
  registrySlug?: string
) =>
  !!(
    currentUser &&
    registrySlug &&
    currentUser.registries?.some(
      (registry) => registry.urlSlug === registrySlug
    )
  )
