import { FC, useState } from 'react'
import { IconButton, Image, NavLink, TextLink } from 'baby-design'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import classNames from 'classnames'
import { Close } from 'baby-design/icons'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import {
  facebookUrl,
  indexPath,
  instagramUrl,
  linkedInUrl,
  registryHomePath,
  twitterUrl,
  youtubeUrl,
} from 'lib/urls'
import MobileNavMenu from './icons/MobileNavMenu.svg'
import Facebook from '../icons/Facebook.svg'
import LinkedIn from '../icons/LinkedIn.svg'
import YouTube from '../icons/YouTube.svg'
import Instagram from '../icons/Instagram.svg'
import X from '../icons/X.svg'

import css from './Navbar.styles.scss'

export const navLinks = [
  {
    ariaLabel: 'Navigate to About page',
    id: 1,
    label: 'About',
    path: '/about',
  },
  {
    ariaLabel: 'Navigate to Careers page',
    id: 2,
    label: 'Careers',
    path: '/about/careers',
  },
  {
    ariaLabel: 'Navigate to Impact page',
    id: 3,
    label: 'Impact',
    path: '/about/impact',
  },
  {
    ariaLabel: 'Navigate to Leadership page',
    id: 4,
    label: 'Leadership',
    path: '/about/leadership',
  },
  {
    ariaLabel: 'Navigate to Press page',
    id: 5,
    label: 'Press',
    path: '/about/press',
  },
]

const Navbar: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [currentUser] = useCurrentUser()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const handleNavigate = (path: string) => {
    navigate(path)
    if (isMobileNavOpen) setIsMobileNavOpen(false)
  }

  return (
    <>
      <div className={css.Navbar}>
        <div className={css.Navbar__contentContainer}>
          <TextLink
            className={css.Navbar__logoLink}
            href={currentUser ? registryHomePath : indexPath}
            innerContentClassName={css.Navbar__logoLinkLabel}
          >
            <Image
              alt="Babylist Logo"
              className={css.Navbar__logo}
              height={24}
              src="https://images.babylist.com/image/upload/f_auto,q_auto/v1717187527/Corporate%20About%20Pages/BL_logo.png"
            />
            <span className="sr-only">Navigate to Babylist's home page.</span>
          </TextLink>
          <div
            className={classNames(css.Navbar__links, {
              [css['Navbar__links--open']]: isMobileNavOpen,
            })}
          >
            {navLinks.map(({ ariaLabel, id, label, path }) => (
              <NavLink
                aria-label={ariaLabel}
                className={classNames(css.Navbar__link, {
                  [css['Navbar__link--active']]: pathname === path,
                })}
                href={path}
                key={id}
                size="lg"
                onClick={(e) => {
                  e.preventDefault()
                  handleNavigate(path)
                }}
              >
                {label}
              </NavLink>
            ))}
            <div className={css.Navbar__socialLinks}>
              <TextLink
                className={css.Navbar__socialLink}
                href={facebookUrl}
                innerContentClassName={css.Navbar__socialLinkLabel}
                target="_blank"
              >
                <Facebook className={css.Navbar__socialIcon} />
                <span className="sr-only">
                  Navigate to Babylist's Facebook page.
                </span>
              </TextLink>
              <TextLink
                className={css.Navbar__socialLink}
                href={twitterUrl}
                innerContentClassName={css.Navbar__socialLinkLabel}
                target="_blank"
              >
                <X className={css.Navbar__socialIcon} />
                <span className="sr-only">Navigate to Babylist's X page.</span>
              </TextLink>
              <TextLink
                className={css.Navbar__socialLink}
                href={instagramUrl}
                innerContentClassName={css.Navbar__socialLinkLabel}
                target="_blank"
              >
                <Instagram className={css.Navbar__socialIcon} />
                <span className="sr-only">
                  Navigate to Babylist's Instagram page.
                </span>
              </TextLink>
              <TextLink
                className={css.Navbar__socialLink}
                href={linkedInUrl}
                innerContentClassName={css.Navbar__socialLinkLabel}
                target="_blank"
              >
                <LinkedIn className={css.Navbar__socialIcon} />
                <span className="sr-only">
                  Navigate to Babylist's LinkedIn page.
                </span>
              </TextLink>
              <TextLink
                className={css.Navbar__socialLink}
                href={youtubeUrl}
                innerContentClassName={css.Navbar__socialLinkLabel}
                target="_blank"
              >
                <YouTube className={css.Navbar__socialIcon} />
                <span className="sr-only">
                  Navigate to Babylist's YouTube page.
                </span>
              </TextLink>
            </div>
          </div>
          <IconButton
            aria-label="Open Mobile Navigation Menu"
            className={css.Navbar__mobileNavMenuButton}
            size="xs"
            type="button"
            variant="standard-default"
            onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
          >
            {isMobileNavOpen ? <Close /> : <MobileNavMenu />}
          </IconButton>
        </div>
      </div>
      {isMobileNavOpen && (
        <div
          className={css.Navbar__mobileNavOverlay}
          onClick={() => setIsMobileNavOpen(false)}
        />
      )}
    </>
  )
}

export default Navbar
