import { FC } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { RightDrawer } from 'baby-design'

import { Reservation } from 'src/types/reservation'
import GiftSummary from './GiftSummary'

import css from './GiftDetailsDrawer.styles.scss'

export const Contents: FC<{
  reservation: Reservation
}> = ({ reservation }) => (
  <div className={css.GiftDetailsDrawer}>
    <GiftSummary reservation={reservation} />
  </div>
)

export const ContentsInDrawer: FC<{ reservation: Reservation }> = ({
  reservation,
}) => {
  const modal = useModal()

  return (
    <RightDrawer
      handleClose={modal.hide}
      isOpen={modal.visible}
      title="Gift details"
    >
      <Contents reservation={reservation} />
    </RightDrawer>
  )
}

const GiftDetailsDrawer = NiceModal.create(ContentsInDrawer)

export const showGiftDetailsDrawer = ({
  reservation,
}: {
  reservation: Reservation
}) => NiceModal.show(GiftDetailsDrawer, { reservation })

export default GiftDetailsDrawer
