import React from 'react'
import { Button, Callout } from 'baby-design'
import { settingsShippingAddressPath } from 'lib/urls'
import css from './AddressNudgeCallout.styles.scss'

const CALLOUT_DISMISSED_LOCAL_STORAGE_KEY = 'ADDRESS_NUDGE_DISMISSED'
const CALLOUT_DISMISSAL_EXPIRATION = 86400000 * 7 // 7 days

const AddressNudgeCallout: React.FC = () => {
  const userDismissedCallout = (): boolean => {
    const data = localStorage.getItem(CALLOUT_DISMISSED_LOCAL_STORAGE_KEY)
    if (!data) return false

    const parsedData = JSON.parse(data)

    if (!parsedData.expires || parsedData.expires < Date.now()) {
      localStorage.removeItem(CALLOUT_DISMISSED_LOCAL_STORAGE_KEY)
      return false
    }

    return true
  }

  const onCalloutClose = () => {
    const expires = Date.now() + CALLOUT_DISMISSAL_EXPIRATION
    localStorage.setItem(
      CALLOUT_DISMISSED_LOCAL_STORAGE_KEY,
      JSON.stringify({ expires })
    )
  }

  if (userDismissedCallout()) return null

  return (
    <Callout
      showCloseButton
      backgroundColor="eggplant"
      className={css.AddressNudgeCallout}
      cta={
        <Button href={settingsShippingAddressPath} size="sm" variant="primary">
          Add address
        </Button>
      }
      text="To track shipments from your friends & family, update your address settings."
      title="Add an address to your registry"
      onClose={onCalloutClose}
    />
  )
}

export default AddressNudgeCallout
