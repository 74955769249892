import classnames from 'classnames'
import css from './SelectorControls.styles.scss'

interface OptionProps {
  href: string
  isActive: boolean
  isDisabled: boolean
  option: any
  onClick: () => void
}

export const ThumbnailControl = ({
  href,
  isActive,
  isDisabled,
  option,
  onClick,
}: OptionProps) => (
  <a
    aria-disabled={isDisabled}
    aria-label={`Select variant ${option.value}`}
    aria-selected={isActive}
    className={classnames(
      css.ThumbnailControl,
      isActive && css['ThumbnailControl--active'],
      isDisabled && css['ThumbnailControl--disabled']
    )}
    href={href}
    role="link"
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    <img
      alt={`Thumbnail of ${option.value}`}
      className={css.ThumbnailControl__thumbnail}
      src={option.thumbnailUrl}
    />
  </a>
)

export const TextControl = ({
  href,
  isActive,
  isDisabled,
  option,
  onClick,
}: OptionProps) => (
  <a
    aria-disabled={isDisabled}
    aria-label={`Select variant ${option}`}
    aria-selected={isActive}
    className={classnames(
      css.TextControl,
      isActive && css['TextControl--active'],
      isDisabled && css['TextControl--disabled']
    )}
    href={href}
    role="link"
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    {option}
  </a>
)
