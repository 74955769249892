import React from 'react'

import { Banner } from 'baby-design'
import { whereIsMyOrderPath } from 'lib/urls'

export const HEADLINE = 'Have an issue?'
export const BODY_COPY = 'Our Happiness Heroes can help.'
export const BUTTON_TEXT = 'Get help'

const GetHelpBanner: React.FC = () => (
  <Banner
    bodyCopy={BODY_COPY}
    button={{
      buttonText: BUTTON_TEXT,
      buttonVariant: 'primary',
      href: whereIsMyOrderPath,
    }}
    color="default"
    dismissable={false}
    headline={HEADLINE}
    variant="ultra-compact"
  />
)

export default GetHelpBanner
