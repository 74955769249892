import { connect } from 'react-redux'
import { GuestRegistryHeader } from './GuestRegistryHeader'
import {
  getArrivalDate,
  getRegistryDescription,
  getRegistryPhoto,
  getRegistryTitle,
} from '../../reducers'

const mapStateToProps = (state: any) => ({
  arrivalDate: getArrivalDate(state),
  note: getRegistryDescription(state),
  photoUrl: getRegistryPhoto(state),
  title: getRegistryTitle(state),
  registry: state.registry,
})

export default connect(mapStateToProps)(GuestRegistryHeader)
