import { useEffect } from 'react'

const RegistryHome = () => {
  useEffect(() => {
    window.location.reload()
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    />
  )
}

export default RegistryHome
