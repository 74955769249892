import { FC, useEffect, useState } from 'react'
import TrackVisibility from 'react-on-screen'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image, TextLink } from 'baby-design'
import { babylistHealth, expectfulUrl, storePath } from 'lib/urls'
import ReelsPhone from '../ReelsPhone/ReelsPhone'

import css from './HeroSection.styles.scss'

const HeroSection: FC = () => {
  const [videosAreMuted, setVideosAreMuted] = useState(true)
  const [loadAnimations, setLoadAnimations] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoadAnimations(true)
    }, 100)
  }, [])

  return (
    <section className={css.HeroSection}>
      <div className={css.HeroSection__primaryTextBlock}>
        <h1 className={css.HeroSection__primaryTextBlockHeading}>
          We take care of growing families
        </h1>
        <p className={css.HeroSection__primaryTextBlockBody}>
          At Babylist, our mission is to take care of growing families so they
          can be confident, connected, happy, and healthy.
        </p>
      </div>
      <div className={css.HeroSection__desktopImage1Container}>
        <Image
          className={css.HeroSection__desktopImage}
          src="https://images.babylist.com/image/upload/q_auto/v1720463370/Corporate%20About%20Pages/Animated/home_hero_desktop1_background.png"
        />
        <Image
          className={classNames(css.HeroSection__instagramImage1, {
            [css['HeroSection__instagramImage1--preAnimation']]:
              !loadAnimations,
          })}
          src="https://images.babylist.com/image/upload/q_auto/v1720474155/Corporate%20About%20Pages/Animated/insta-gabby_desktop.png"
        />
        <Image
          className={classNames(css.HeroSection__instagramImage2, {
            [css['HeroSection__instagramImage2--preAnimation']]:
              !loadAnimations,
          })}
          src="https://images.babylist.com/image/upload/q_auto/v1720474151/Corporate%20About%20Pages/Animated/insta-steph_desktop.png"
        />
      </div>
      <div className={css.HeroSection__desktopImage2AndMobileContainer}>
        <Image
          className={css.HeroSection__mobileImage}
          src="https://images.babylist.com/image/upload/q_auto/v1720535687/Corporate%20About%20Pages/Animated/home_hero_background_mobile.png"
        />
        <Image
          className={css.HeroSection__desktopImage}
          src="https://images.babylist.com/image/upload/q_auto/v1720460757/Corporate%20About%20Pages/Animated/home_hero_desktop2_background.png"
        />
        <Image
          className={classNames(css.HeroSection__instagramImage1)}
          src="https://images.babylist.com/image/upload/q_auto/v1720474153/Corporate%20About%20Pages/Animated/insta-gabby_mobile.png"
        />
        <Image
          className={classNames(css.HeroSection__instagramImage2)}
          src="https://images.babylist.com/image/upload/q_auto/v1720474150/Corporate%20About%20Pages/Animated/insta-steph_mobile.png"
        />
        <button
          className={classNames(css.HeroSection__tapForSoundMobile)}
          type="button"
          onClick={() => setVideosAreMuted((prevValue) => !prevValue)}
        >
          <Image
            className={css.HeroSection__tapForSoundImage}
            src="https://images.babylist.com/image/upload/q_auto/v1720535802/Corporate%20About%20Pages/Animated/tapForSound_mobile.png"
          />
          <span className="sr-only">Tap to toggle sound for videos</span>
        </button>
        <button
          className={classNames(css.HeroSection__tapForSoundDesktop, {
            [css['HeroSection__tapForSoundDesktop--preAnimation']]:
              !loadAnimations,
          })}
          type="button"
          onClick={() => setVideosAreMuted((prevValue) => !prevValue)}
        >
          <Image
            className={css.HeroSection__tapForSoundImage}
            src="https://images.babylist.com/image/upload/q_auto/v1720461037/Corporate%20About%20Pages/Animated/home_hero_tapForSound_desktop.png"
          />
          <Image
            className={css.HeroSection__tapForSoundArrow}
            src="https://images.babylist.com/image/upload/q_auto/v1720461037/Corporate%20About%20Pages/Animated/arrow_desktop.png"
          />
        </button>
        <ReelsPhone
          className={classNames(css.HeroSection__reelsPhone, {
            [css['HeroSection__reelsPhone--preAnimation']]: !loadAnimations,
          })}
          muted={videosAreMuted}
        />
        <TrackVisibility once>
          {({ isVisible }) =>
            isVisible && (
              <>
                <Image
                  className={classNames(
                    css.HeroSection__comment,
                    css.HeroSection__comment1
                  )}
                  src="https://images.babylist.com/image/upload/q_auto,fl_sanitize/v1730318650/Corporate%20About%20Pages/Animated/comment_1_svg.svg"
                />
                <Image
                  className={classNames(
                    css.HeroSection__comment,
                    css.HeroSection__comment2
                  )}
                  src="https://images.babylist.com/image/upload/q_auto,fl_sanitize/v1730318650/Corporate%20About%20Pages/Animated/comment_2_svg.svg"
                />
                <Image
                  className={classNames(
                    css.HeroSection__comment,
                    css.HeroSection__comment3
                  )}
                  src="https://images.babylist.com/image/upload/q_auto,fl_sanitize/v1730318650/Corporate%20About%20Pages/Animated/comment_3_svg.svg"
                />
                <Image
                  className={classNames(
                    css.HeroSection__comment,
                    css.HeroSection__comment4
                  )}
                  src="https://images.babylist.com/image/upload/q_auto,fl_sanitize/v1730318650/Corporate%20About%20Pages/Animated/comment_4_svg.svg"
                />
              </>
            )
          }
        </TrackVisibility>
      </div>
      <div className={css.HeroSection__secondaryTextBlock}>
        <h2 className={css.HeroSection__secondaryTextBlockHeading}>
          We&rsquo;re the leading digital destination for growing families
        </h2>
        <p className={css.HeroSection__secondaryTextBlockBody}>
          Our mission is behind everything we do. We started as a universal
          registry and the Babylist ecosystem now includes{' '}
          <TextLink
            underline
            href={babylistHealth}
            innerContentClassName={css.HeroSection__link}
            size="lg"
          >
            Babylist Health
          </TextLink>
          , which provides products and services including insurance-covered
          breast pumps,{' '}
          <TextLink
            underline
            href={expectfulUrl}
            innerContentClassName={css.HeroSection__link}
            size="lg"
          >
            Expectful
          </TextLink>
          , a new voice in health and wellness for pre-pregnancy through
          postpartum care, and the{' '}
          <TextLink
            underline
            href={storePath}
            innerContentClassName={css.HeroSection__link}
            size="lg"
          >
            Babylist Shop
          </TextLink>
          , which offers the best baby gear that&rsquo;s expert-selected and
          real-parent tested.
          <br />
          <br />
          Our vision is to support every baby and the people who love them—from
          hoping and dreaming, through kindergarten and beyond.
        </p>
      </div>
    </section>
  )
}

export default HeroSection
