import React, { createContext, useContext, useState } from 'react'

export interface ShippingAddressButtonContextProps {
  isShippingAddressButtonVisible: boolean
  setIsShippingAddressButtonVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

export const ShippingAddressButtonContext =
  createContext<ShippingAddressButtonContextProps>({
    isShippingAddressButtonVisible: false,
    setIsShippingAddressButtonVisible: () => false,
  })

export const ShippingAddressButtonContextProvider: React.FC = ({
  children,
}) => {
  const [isShippingAddressButtonVisible, setIsShippingAddressButtonVisible] =
    useState(false)

  return (
    <ShippingAddressButtonContext.Provider
      value={{
        isShippingAddressButtonVisible,
        setIsShippingAddressButtonVisible,
      }}
    >
      {children}
    </ShippingAddressButtonContext.Provider>
  )
}

export const useShippingAddressButtonContext = () =>
  useContext(ShippingAddressButtonContext)
