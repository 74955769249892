import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'

import { BREAKPOINT_TABLET } from 'src/constants'
import { useWindowSize } from 'usehooks-ts'
import { useGetBabylistOfferClickHandler } from '../../../../hooks/useGetBabylistOfferClickHandler/useGetBabylistOfferClickHandler'
import { RegItemBuyingOptionsProps } from '../../RegItemBuyingOptionsModal.types'
import { useTrackRegistryProductOfferClicked } from '../../../../hooks/useTrackRegistryProductOfferClicked/useTrackRegistryProductOfferClicked'

const GiftCardBuyingOptions: React.FC<RegItemBuyingOptionsProps> = ({
  regItem,
  registry,
  currentUser,
}) => {
  const [loading, setIsLoading] = useState(false)
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < BREAKPOINT_TABLET
  const handleBabylistOfferClick = useGetBabylistOfferClickHandler({
    registry,
    currentUser,
    regItem,
    setIsLoading,
  })
  const handleTrackRegistryProductOfferClicked =
    useTrackRegistryProductOfferClicked(registry, regItem, currentUser)

  const handleClick = () => {
    handleTrackRegistryProductOfferClicked()
    handleBabylistOfferClick()
  }

  return (
    <div data-testid="GiftCardBuyingOptions">
      <Button
        block
        data-testid="GiveGiftButton"
        size={isMobile ? 'md' : 'lg'}
        variant="primary"
        onClick={handleClick}
      >
        {loading ? 'Loading...' : 'Give this gift'}
      </Button>
    </div>
  )
}

export default GiftCardBuyingOptions
