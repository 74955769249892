import { FC } from 'react'

import { Reservation } from 'src/types/reservation'

import QuantityBadge from './QuantityBadge'
import css from './ImageAndQuantity.styles.scss'

const ProductImage: FC<{ reservation: Reservation }> = ({ reservation }) => (
  <img
    alt={reservation.displayTitle}
    className={css.ImageAndQuantity__ProductImage}
    loading="lazy"
    src={reservation.regItem?.imgUrl}
  />
)

const ImageAndQuantity: FC<{ reservation: Reservation }> = ({
  reservation,
}) => (
  <div className={css.ImageAndQuantity}>
    <ProductImage reservation={reservation} />
    <QuantityBadge quantity={reservation.quantity} />
  </div>
)

export default ImageAndQuantity
