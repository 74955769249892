import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Note } from 'baby-design/icons'
import { TextLink } from 'baby-design'
import css from './ProductNotes.styles.scss'

interface ProductNotesProps {
  notes: string
}

export const ProductNotes = ({ notes }: ProductNotesProps) => {
  const [expanded, setExpanded] = useState(false)
  const [showControls, setShowControls] = useState(true)

  const notesWrapperRef = useRef<HTMLDivElement>(null)
  const notesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const notesElement = notesRef.current
    if (notesElement && notesElement.scrollHeight > notesElement.clientHeight) {
      setShowControls(true)
    } else {
      setShowControls(false)
    }
  }, [])

  return (
    <div
      className={classNames(css.product_notes__wrapper, {
        [css['product_notes--expanded']]: expanded,
      })}
      ref={notesWrapperRef}
    >
      <Note className={css.product_notes__icon} />
      <div className={css.product_notes__info}>
        <div
          className={css.product_notes__note}
          ref={notesRef}
        >{`${notes}`}</div>
        {(showControls || expanded) && (
          <TextLink
            size="sm"
            variant="default"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Less' : 'More'}
          </TextLink>
        )}
      </div>
    </div>
  )
}
