import * as React from 'react'
import StatsBar from 'registry/components/StatsBar/StatsBar'
import { buildStats } from './WishlistInfo.utils'
import Preference from './Preference/Preference'
import { RegistryGiftGiverHeaderProps } from '../RegistryGiftGiverHeader.types'
import styles from './WishlistInfo.styles.scss'

const WishlistInfo: React.FC<RegistryGiftGiverHeaderProps> = ({ registry }) => {
  const { attributeData = {} } = registry
  const { preferences = [] } = attributeData
  const stats = buildStats(registry)

  const hasPreferences = preferences.length > 0
  const lovesOrNeedsPreferences = preferences.filter(
    (p) => ['loves', 'needs'].includes(p.affinity) && p.name
  )
  const pleaseNoPreference = preferences.find((p) => p.affinity === 'please_no')

  return (
    <div className={styles.WishlistInfo} data-testid="WishlistInfo">
      {hasPreferences && (
        <div className={styles.preferences}>
          {lovesOrNeedsPreferences.map((preference) => (
            <Preference
              key={`${preference.affinity}--${preference.name}`}
              preference={preference}
            />
          ))}
        </div>
      )}
      <StatsBar glowupEnabled stats={stats} />
      {pleaseNoPreference?.name && (
        <span className={styles.preferences__please_no}>
          Please no {pleaseNoPreference.name}.
        </span>
      )}
    </div>
  )
}

export default WishlistInfo
