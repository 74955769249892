import { FC, useState } from 'react'
import className from 'classnames'

// eslint-disable-next-line import/no-extraneous-dependencies
import { TextLink } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Chevron } from 'baby-design/icons'

import { GiftGiver } from 'src/routes/(registry)/registry/(gift-tracker-revamp)/context/GiftGiversProvider.types'

import ContactInfo from './ContactInfo'
import css from './GiftGiverInfo.styles.scss'

const Header: FC<{
  giftGiver: GiftGiver
  isOpen: boolean
  toggleOpen: () => void
}> = ({ giftGiver, isOpen, toggleOpen }) => {
  const icon = (
    <div
      className={className({
        [css.GiftGiverInfo__Header__button_icon_open]: isOpen,
      })}
    >
      <Chevron />
    </div>
  )

  return (
    <div className={css.GiftGiverInfo__Header}>
      <div className={css.GiftGiverInfo__Header__name}>{giftGiver.name}</div>
      <TextLink
        icon={{
          icon,
          side: 'right',
          size: 'lg',
        }}
        weight="regular"
        onClick={toggleOpen}
      >
        Contact Info
      </TextLink>
    </div>
  )
}

const GiftGiverInfo: FC<{ giftGiver: GiftGiver }> = ({ giftGiver }) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)

  return (
    <>
      <Header {...{ giftGiver, isOpen, toggleOpen }} />
      <ContactInfo {...{ giftGiver, isOpen }} />
    </>
  )
}

export default GiftGiverInfo
