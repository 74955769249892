import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { debounce } from 'lodash'
import {
  SearchAPI,
  MINUMUM_SEARCH_TERM_LENGTH,
} from 'src/routes/(shop)/api/search'
import { SearchSuggestion } from 'src/types/searchSuggestion'
import { storeSearchResultsPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { useInRouterContext } from 'react-router-dom-v5-compat'
import { browserHistory } from 'react-router'
import TextInput from 'baby-design/components/input/TextInput/TextInput'
import SearchMagnifyingGlass from 'shared/svg/search-magnifying-glass.svg'
import Close from 'shared/svg/close.svg'
import { SearchSuggestions } from './SearchSuggestions'
import css from './Search.styles.scss'

interface SearchProps {
  onFocus?: () => void
  onBlur?: () => void
}

export const Search = ({ onFocus, onBlur }: SearchProps) => {
  const inReactRouter6Context = useInRouterContext()

  const tracker = useTracking()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [displayTerm, setDisplayTerm] = useState<string>('')
  const { data } = SearchAPI.useSuggestions(searchTerm)
  const [searchSuggestions, setSearchSuggestions] = useState<
    SearchSuggestion[]
  >([])
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const searchRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const searchHistory = sessionStorage.getItem('storeSearchHistory')
    if (searchHistory) {
      const parsedHistory = JSON.parse(searchHistory)
      const mostRecentSearch = parsedHistory[parsedHistory.length - 1]
      setDisplayTerm(mostRecentSearch)
      setSearchTerm('')
      setSearchSuggestions([])
    }
  }, [])

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => setSearchTerm(value), 300),
    [setSearchTerm]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setDisplayTerm(value)
    if (value.length >= MINUMUM_SEARCH_TERM_LENGTH) {
      debouncedSetSearchTerm(value)
    } else {
      setSearchTerm('')
      setSearchSuggestions([])
    }
  }

  useEffect(() => {
    if (data) {
      setSearchSuggestions(data)
      setActiveIndex(-1)
    }
  }, [data])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setSearchSuggestions([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      setActiveIndex((prevIndex) => (prevIndex + 1) % searchSuggestions.length)
      setDisplayTerm(
        searchSuggestions[(activeIndex + 1) % searchSuggestions.length]
          .searchTerm
      )
    } else if (event.key === 'ArrowUp') {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? searchSuggestions.length - 1 : prevIndex - 1
      )
      setDisplayTerm(
        searchSuggestions[
          activeIndex === 0 ? searchSuggestions.length - 1 : activeIndex - 1
        ].searchTerm
      )
    } else if (event.key === 'Enter') {
      if (activeIndex >= 0) {
        handleSearch(searchSuggestions[activeIndex].searchTerm)
      } else {
        handleSearch(displayTerm)
      }
    }
  }

  const handleClickSearchSuggestion = (text: string) => {
    setDisplayTerm(text)
    setSearchTerm(text)
    handleSearch(text)
  }

  const handleSearch = (text: string) => {
    tracker.trackEvent({
      event: track.searchExecuted,
      searchTerm: text,
      eventType: track.EventType.SHOP,
    })

    const searchHistory = sessionStorage.getItem('storeSearchHistory')
    if (searchHistory) {
      const parsedHistory = JSON.parse(searchHistory)
      parsedHistory.push(text)
      sessionStorage.setItem(
        'storeSearchHistory',
        JSON.stringify(parsedHistory.slice(-10))
      )
    } else {
      sessionStorage.setItem('storeSearchHistory', JSON.stringify([text]))
    }

    setSearchSuggestions([])

    if (inReactRouter6Context) {
      window.location.href = storeSearchResultsPath(text)
    } else {
      browserHistory.push(storeSearchResultsPath(text))
    }
  }

  const suggestionsComponent = useMemo(
    () => (
      <SearchSuggestions
        activeIndex={activeIndex}
        displayTerm={displayTerm}
        suggestions={searchSuggestions}
        onSearch={handleClickSearchSuggestion}
      />
    ),
    [searchSuggestions, activeIndex]
  )

  const handleClear = () => {
    setDisplayTerm('')
    setSearchTerm('')
    setSearchSuggestions([])
  }

  const ClearButton = () =>
    displayTerm.length > 0 ? (
      <button
        aria-label="Clear search"
        className={css['Search__clear-btn']}
        type="button"
        onClick={handleClear}
      >
        <Close />
      </button>
    ) : null

  return (
    <div className={css.Search} ref={searchRef}>
      <TextInput
        LeftIcon={<SearchMagnifyingGlass />}
        RightIcon={<ClearButton />}
        controlClassName={css.Search__input__control}
        inputProps={{
          'aria-activedescendant': `suggestion-${activeIndex}`,
          'aria-controls': 'search-suggestions-list',
          placeholder: 'What are you looking for?',
          value: displayTerm,
          onBlur,
          onChange: handleInputChange,
          onFocus,
          onKeyDown: handleKeyDown,
        }}
        size="md"
        variant="inverted"
      />
      {searchSuggestions.length > 0 && (
        <ul id="search-suggestions-list" role="listbox">
          {suggestionsComponent}
        </ul>
      )}
    </div>
  )
}
