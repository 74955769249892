import { LoadingHeart } from 'baby-design'
import css from './RegItemBuyingOptionsModal.skeleton.styles.scss'

const RegItemBuyingOptionsModalSkeleton = () => (
  <div className={css.RegItemBuyingOptionsModalSkeleton}>
    <LoadingHeart />
  </div>
)

export default RegItemBuyingOptionsModalSkeleton
