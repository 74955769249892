import React from 'react'
import { ShipNowModalBodyProps } from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalBody/ShipNowModalBody.types'
import ShipmentItemsThumbnailRow from 'src/routes/(registry)/shipments/components/ShipmentItemsThumbnailRow/ShipmentItemsThumbnailRow'
import EstimatedShipDates from 'src/routes/(registry)/shipments/components/ShipNowModal/components/ShipNowModalBody/components/EstimatedShipDates/EstimatedShipDates'
import { itemsAndOrdersLabel } from 'src/routes/(registry)/shipments/util/shipmentHelpers'
import css from './ShipNowModalBody.styles.scss'

const ShipNowModalBody: React.FC<ShipNowModalBodyProps> = ({ shipment }) => (
  <div className={css.ShipNowModal}>
    <div>
      <h1 className={css.ShipNowModal__Heading}>Ship your bundle</h1>
      <p className={css.ShipNowModal__ItemsLabel}>
        {itemsAndOrdersLabel(shipment)}
      </p>
    </div>
    <div className={css.ShipNowModal__Content}>
      <ShipmentItemsThumbnailRow
        responsive
        maxVisible={5}
        selectable={false}
        shipment={shipment}
      />
      <EstimatedShipDates shipment={shipment} />
    </div>
  </div>
)

export default ShipNowModalBody
