import fetch from 'lib/fetch'
import { QueryClient, useQuery } from '@tanstack/react-query'
import { GenericProduct } from 'src/types/genericProduct'
import { Product } from 'src/types/product'

export interface Category {
  title: string
  urlSlug: string
}

const cacheKey = (productId: string) => [CACHE_KEY, productId]

const CACHE_KEY = 'products'
const API_PATH = '/store/api/products'
export const PRODUCTS_STALE_TIME = 600000

export interface ActiveProduct extends Product {
  brand: string
}

interface ProductApiResponse extends GenericProduct {
  activeProduct: ActiveProduct
}

export const hydrateProductCache = ({
  data,
  productId,
  queryClient,
}: {
  data: GenericProduct
  productId: string | undefined
  queryClient: QueryClient
}) => {
  const allProducts = Object.keys(data.products ?? {})
    .filter((pid) => pid !== productId ?? null)
    .map((pid) => data.products[pid])

  allProducts.forEach((product) => {
    queryClient.setQueryData(cacheKey(product.id.toString()), {
      ...data,
      activeProductId: product.id,
      price: {
        msrp: product.price.msrp,
        current: product.price.current,
      },
    })
  })
}
const fetchShow = async (productId: string): Promise<GenericProduct> =>
  await fetch(`${API_PATH}/${productId}`)

const useShow = (productId: string | undefined, options = {}) => {
  if (productId === undefined) {
    throw new Error('productId must be defined')
  }
  const query = useQuery({
    queryKey: cacheKey(productId),
    queryFn: () => fetchShow(productId),
    ...options,
  })

  const activeProduct = query.data?.products[query.data.activeProductId]
    ? {
        brand: query.data?.brand,
        ...query.data?.products[query.data.activeProductId],
      }
    : null

  return {
    ...query,
    data: {
      ...query.data,
      activeProduct,
    } as ProductApiResponse,
  }
}

export const ProductsAPI = {
  useShow,
}
