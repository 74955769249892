import React from 'react'
import Image from 'components/image'
import { useReservationCardContext } from '../../context'
import css from './RegItemImage.styles.scss'

const RegItemImage: React.FC = () => {
  const { regItem } = useReservationCardContext()

  return (
    <div className={css.RegItemImage}>
      <Image
        className={css.RegItemImage__image}
        role="presentation"
        src={regItem?.imgUrl}
        width="100%"
      />
    </div>
  )
}

export default RegItemImage
