import React from 'react'
import classNames from 'classnames'
import { GooglePlayLogo, AppleLogo } from 'baby-design/icons'

import { QRCode } from 'react-qr-svg'
import { mobileAppDownloadPath } from 'lib/urls'
import BabylistHeartWithBackground from 'baby-design/icons/BabylistHeartWithBackground.svg'
import { track } from 'lib/analytics'
import { AppDownloadProps } from './AppDownload.types'
import QRCodeContainer from './QRCodeContainer.svg'
import css from './AppDownload.styles.scss'

const AppDownload: React.FC<AppDownloadProps> = ({ className }) => {
  const AppDownloadClasses = classNames(css.AppDownload, className)

  const appDownloadLink = `https://www.babylist.com/${mobileAppDownloadPath()}`

  return (
    <div className={AppDownloadClasses}>
      <div
        className={classNames(css.AppDownload__module, css.AppDownload__phone)}
      >
        <img
          alt="Babylist App"
          height="169"
          src="https://images.babylist.com/image/upload/f_auto,q_auto/v1711648844/AddChromeExtension/AppExample2x.png"
          width="200"
        />
      </div>
      <div
        className={classNames(css.AppDownload__module, css.AppDownload__copy)}
      >
        <h1 className={css.AppDownload__title}>
          Build your registry on the go!
        </h1>
        <p className={css.AppDownload__bodyText}>
          Add items from any store with the Babylist app.
        </p>
        <p className={css.AppDownload__bodyText}>
          Scan the QR code to download.
        </p>
        <div className={css.AppDownload__app_links}>
          <div
            className={css.AppDownload__linkContainer}
            onClick={() => {
              track.addItemsScreenClicked({
                eventLocation: track.EventLocation.ADD_ITEMS,
                eventCta: 'Download App',
              })
              window.open(mobileAppDownloadPath('', 'apple'), '_blank')
            }}
          >
            <AppleLogo className={css.AppDownload__apple_logo} />
            <p>App Store</p>
          </div>
          <div
            className={css.AppDownload__linkContainer}
            onClick={() => {
              track.addItemsScreenClicked({
                eventLocation: track.EventLocation.ADD_ITEMS,
                eventCta: 'Download App',
              })
              window.open(mobileAppDownloadPath('', 'google'), '_blank')
            }}
          >
            <GooglePlayLogo className={css.AppDownload__google_logo} />
            <p>Google Play</p>
          </div>
        </div>
      </div>
      <div className={css.AppDownload__codeContainer}>
        <QRCodeContainer />
        <QRCode className={css.AppDownload__code} value={appDownloadLink} />
        <BabylistHeartWithBackground
          className={css.AppDownload__babylistHeart}
        />
      </div>
    </div>
  )
}

export default AppDownload
