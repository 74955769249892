import React from 'react'

interface WebsiteProps {
  className?: string
}

const Website: React.FC<WebsiteProps> = ({ className }: WebsiteProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Website Icon</title>
    <path
      clipRule="evenodd"
      d="M9.96644 1.25H14.0336C15.4053 1.25 16.4807 1.24999 17.3451 1.32061C18.2252 1.39252 18.9523 1.54138 19.6104 1.87671C20.6924 2.42798 21.572 3.30762 22.1233 4.38956C22.4586 5.04768 22.6075 5.77479 22.6794 6.65494C22.75 7.51926 22.75 8.59471 22.75 9.96637V14.0336C22.75 15.4053 22.75 16.4807 22.6794 17.3451C22.6075 18.2252 22.4586 18.9523 22.1233 19.6104C21.572 20.6924 20.6924 21.572 19.6104 22.1233C18.9523 22.4586 18.2252 22.6075 17.3451 22.6794C16.4807 22.75 15.4053 22.75 14.0336 22.75H9.96637C8.59471 22.75 7.51926 22.75 6.65494 22.6794C5.77479 22.6075 5.04768 22.4586 4.38956 22.1233C3.30762 21.572 2.42798 20.6924 1.87671 19.6104C1.54138 18.9523 1.39252 18.2252 1.32061 17.3451C1.24999 16.4807 1.25 15.4053 1.25 14.0336V9.96644C1.25 8.59472 1.24999 7.51929 1.32061 6.65494C1.39252 5.77479 1.54138 5.04768 1.87671 4.38955C2.42798 3.30762 3.30762 2.42798 4.38955 1.87671C5.04769 1.54138 5.77479 1.39252 6.65494 1.32061C7.51929 1.24999 8.59472 1.25 9.96644 1.25Z"
      fill="#252324"
      fillRule="evenodd"
    />
    <g clipPath="url(#clip0_3325_1764)">
      <g clipPath="url(#clip1_3325_1764)">
        <path
          d="M12.2651 18.2657C12.1771 18.2693 12.0887 18.2712 11.9998 18.2712C11.8655 18.2712 11.7322 18.2669 11.6 18.2586C11.582 18.2401 11.5637 18.2209 11.5453 18.2011C11.311 17.9489 11.0931 17.6393 10.9668 17.4339C10.9632 17.428 10.9595 17.4223 10.9556 17.4166C10.6424 16.9573 10.3669 16.3185 10.1555 15.5489H13.8441C13.6353 16.3091 13.3639 16.9416 13.0556 17.3995C12.8745 17.616 12.5977 17.9308 12.34 18.191C12.3145 18.2168 12.2895 18.2417 12.2651 18.2657Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M13.65 18.0518C15.1001 17.6573 16.3411 16.7555 17.1707 15.5489H14.7488C14.5099 16.4988 14.1752 17.3108 13.7669 17.9095C13.759 17.9211 13.7506 17.9323 13.7416 17.9431C13.7134 17.977 13.6827 18.0134 13.65 18.0518Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M14.9347 14.6739H17.6738C17.9977 13.9877 18.2008 13.2331 18.2556 12.4378H15.1537C15.1332 13.2211 15.0578 13.9744 14.9347 14.6739Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M14.2784 12.4378H9.72114C9.74324 13.2386 9.82542 13.9921 9.95428 14.6739H14.0453C14.1742 13.9921 14.2563 13.2386 14.2784 12.4378Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M14.2784 11.5628H9.72114C9.74324 10.7621 9.82542 10.0085 9.95428 9.32671H14.0453C14.1742 10.0085 14.2563 10.7621 14.2784 11.5628Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M15.1537 11.5628H18.2556C18.2008 10.7675 17.9977 10.013 17.6738 9.32671H14.9347C15.0578 10.0262 15.1332 10.7796 15.1537 11.5628Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M14.7488 8.45171H17.1707C16.3437 7.24889 15.1078 6.34903 13.6637 5.95261C13.703 6.00282 13.7364 6.04763 13.7632 6.08578L13.7669 6.09111C14.1752 6.68984 14.5099 7.50185 14.7488 8.45171Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M11.6 5.74203C11.7322 5.73371 11.8655 5.72949 11.9998 5.72949C12.0808 5.72949 12.1614 5.73103 12.2416 5.73407C12.6414 6.07807 12.9332 6.4274 13.0459 6.58684C13.3582 7.04588 13.6332 7.68365 13.8441 8.45171H10.1555C10.3669 7.68211 10.6424 7.04332 10.9556 6.58407C10.9595 6.57838 10.9632 6.57261 10.9668 6.56675C11.0931 6.36138 11.311 6.05178 11.5453 5.79956C11.5637 5.77973 11.582 5.76054 11.6 5.74203Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M10.3179 5.95762C8.88149 6.35657 7.65252 7.25393 6.82894 8.45171H9.25077C9.48853 7.50636 9.82124 6.69755 10.2269 6.09965C10.254 6.05576 10.2845 6.00808 10.3179 5.95762Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M9.0649 9.32671H6.32588C6.00193 10.013 5.79883 10.7675 5.74402 11.5628H8.84585C8.86638 10.7796 8.94177 10.0262 9.0649 9.32671Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M8.84585 12.4378H5.74402C5.79883 13.2331 6.00193 13.9877 6.32588 14.6739H9.0649C8.94177 13.9744 8.86638 13.2211 8.84585 12.4378Z"
          style={{ fill: 'white' }}
        />
        <path
          d="M9.25077 15.5489H6.82894C7.65252 16.7467 8.88148 17.6441 10.3179 18.043C10.2845 17.9926 10.254 17.9449 10.2269 17.901C9.82123 17.3031 9.48853 16.4943 9.25077 15.5489Z"
          style={{ fill: 'white' }}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3325_1764">
        <rect fill="white" height="14" transform="translate(5 5)" width="14" />
      </clipPath>
      <clipPath id="clip1_3325_1764">
        <rect fill="white" height="14" transform="translate(5 5)" width="14" />
      </clipPath>
    </defs>
  </svg>
)

export default Website
