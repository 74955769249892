import { InfoButton } from 'components/buttons'
import { contactUsPath } from 'lib/urls'
import { PropTypes } from 'prop-types'

const RetailerReturn = ({
  storeName,
  returnUrl,
  phoneNumber,
  orderNum,
  giftGiverName,
  giftGiverEmail,
}) => (
  <>
    <div className="mbl">
      This item was purchased at <b>{storeName}</b>. Returns and exchanges will
      be handled directly via {storeName}.
    </div>

    <div className="mvxl">
      <div className="h6 mbs">
        <b>Purchase Details</b>
      </div>
      <div className="h6 mvs">
        Given by: {giftGiverName} ({giftGiverEmail})
      </div>
      {orderNum && <div className="h6 mvs">Order Number: {orderNum}</div>}
    </div>

    {returnUrl && (
      <InfoButton block empty newWindow href={returnUrl}>
        View Return Instructions
      </InfoButton>
    )}
    {phoneNumber && (
      <InfoButton block empty newWindow href={`tel://${phoneNumber}`}>
        {storeName} Customer Service <br />
        {phoneNumber}
      </InfoButton>
    )}
    <InfoButton block empty newWindow href={contactUsPath}>
      Contact Babylist Support
    </InfoButton>
  </>
)

RetailerReturn.propTypes = {
  storeName: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  orderNum: PropTypes.string,
  giftGiverName: PropTypes.string,
  giftGiverEmail: PropTypes.string,
}

export default RetailerReturn
