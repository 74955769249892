import { FC, ReactNode } from 'react'
import classNames from 'classnames'

import css from './CurvedHeader.styles.scss'

interface CurvedHeaderProps {
  className?: string
  heading: ReactNode
  subheading?: ReactNode
}

const CurvedHeader: FC<CurvedHeaderProps> = ({
  className,
  heading,
  subheading,
}) => (
  <div className={classNames(css.CurvedHeader, className)}>
    <div className={css.CurvedHeader__contentContainer}>
      <h1 className={css.CurvedHeader__heading}>{heading}</h1>
      {subheading && (
        <h2 className={css.CurvedHeader__subheading}>{subheading}</h2>
      )}
    </div>
  </div>
)

export default CurvedHeader
