import classNames from 'classnames'
import { RegItemMinimalCardDetailsProps } from '../RegItemMinimalCardDetails.types'
import css from '../RegItemMinimalCardDetails.styles.scss'

const ExternalRegistryDetails: React.FC<RegItemMinimalCardDetailsProps> = ({
  regItem,
}: RegItemMinimalCardDetailsProps) => {
  const { title } = regItem
  const wrapperClasses = classNames(
    css.RegItemMinimalCardDetails,
    css.ExternalRegistryDetails
  )

  return (
    <div className={wrapperClasses} data-testid="ExternalRegistryDetails">
      <div className={css.Title}>{title}</div>
    </div>
  )
}

export default ExternalRegistryDetails
