import { createContext } from 'react'

export interface ShipNowContextType {
  onShipNowComplete?: () => void
}

const ShipNowContext = createContext<ShipNowContextType>(
  {} as ShipNowContextType
)

export default ShipNowContext
