import css from './Section.styles.scss'

interface SectionProps {
  title?: string | object
  content: string
}

export const Section = ({ title, content }: SectionProps) => (
  <>
    <h3 className={css.Section__header} data-testid={`${title}-section-header`}>
      {title}
    </h3>
    <span
      className={css.Section__body}
      dangerouslySetInnerHTML={{ __html: content }}
      data-testid={`${title}-section-body`}
    />
  </>
)
