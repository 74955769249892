import { keyBy, values, merge } from 'lodash'

import fetch from '../../lib/fetch'
import { apiV3PostsPath } from '../../lib/urls'

import { FEATURED_POSTS_COLLECTION_SLUG } from '../constants'
import { getPostSlugsNotInPosts } from '../reducers'

export const SET_COLLECTIONS = 'SET_COLLECTIONS'
export const SET_POSTS = 'SET_POSTS'

export const FETCH_POSTS = 'FETCH_POSTS'
export const fetchPosts = ({
  postSlugs = [],
  excludeBody = false,
  type = 'id',
} = {}) => {
  return (dispatch, getState) => {
    const { posts: prevPosts } = getState()
    let url = `${apiV3PostsPath}?exclude_body=${excludeBody}`
    if (postSlugs.length) {
      const key = type === 'id' ? 'ids' : 'slugs'
      url += `&${key}[]=${postSlugs.join(`&${key}[]=`)}`
    }

    fetch(url).then((resp) => {
      let posts = {}
      posts = merge(prevPosts, keyBy(resp.posts, 'slug'))
      dispatch({
        type: SET_POSTS,
        posts,
      })
    })
  }
}

export const MAYBE_FETCH_POSTS = 'MAYBE_FETCH_POSTS'
export const maybeFetchPosts = (postSlugs = [], type = 'id') => {
  return (dispatch, getState) => {
    const state = getState()
    let postSlugsToFetch = []
    let post

    postSlugs.forEach((postSlug) => {
      post = state.posts[postSlug]
      if (!post || !post.body) {
        postSlugsToFetch.push(postSlug)
      }

      if (post) {
        // Fetch any related posts that have not yet been loaded
        postSlugsToFetch = postSlugsToFetch.concat(
          getPostSlugsNotInPosts(post.relatedPostIds, state)
        )
      }
    })

    // if there are popular posts that we don't have, load those now too
    postSlugsToFetch = postSlugsToFetch.concat(
      getPostSlugsNotInPosts(
        state.collections[FEATURED_POSTS_COLLECTION_SLUG].itemIds,
        state
      )
    )
    if (postSlugsToFetch.length > 0) {
      dispatch(fetchPosts({ postSlugs: postSlugsToFetch, type }))
    }
  }
}
