/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image } from 'baby-design'
import { useQuery } from '@tanstack/react-query'
import { FETCH_LEADERS_KEY, fetchLeaders } from '../../../api/queries'

import css from './AboutLeadership.styles.scss'

interface TeamMember {
  id: string
  name: string
  title: string
  primaryImageUrl: string
  secretImageUrl: string
}

const AboutLeadership: FC = () => {
  const [allowToggle, setAllowToggle] = useState<boolean>(true)
  const [showSecretImageFor, setShowSecretImageFor] = useState<string | null>()

  const leadersQuery = useQuery<TeamMember[]>({
    queryKey: [FETCH_LEADERS_KEY],
    queryFn: fetchLeaders,
  })

  useEffect(() => {
    if (showSecretImageFor === null) return

    // Prevent mobile click from immediately changing this to null
    setAllowToggle(false)
    const timeoutId = setTimeout(() => {
      setAllowToggle(true)
    }, 50)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [showSecretImageFor])

  const toggleSecretImage = (leaderId: string) => {
    if (!allowToggle) return

    if (showSecretImageFor === leaderId) {
      setShowSecretImageFor(null)
    } else {
      setShowSecretImageFor(leaderId)
    }
  }

  return (
    <div className={css.AboutLeadership}>
      <section className={css.AboutLeadership__pageHeaderSection}>
        <h1 className={css.AboutLeadership__heading}>Leadership</h1>
        <p className={css.AboutLeadership__description}>
          Our executive leadership team includes industry experts in our core
          business areas (engineering, product development, media, marketing,
          merchandising and logistics,) and have backgrounds leading teams at
          companies like Accenture, Amazon, Athleta, Chewy, eBay, Google,
          McKinsey, Netflix and Twitter.
        </p>
      </section>
      <section className={css.AboutLeadership__leadershipTeamSection}>
        <h2 className={css.AboutLeadership__leadershipTeamSectionHeading}>
          Babylist executive leadership team
        </h2>
        <div className={css.AboutLeadership__leadershipGrid}>
          {leadersQuery?.data?.map((leader: TeamMember) => (
            <div
              className={css.AboutLeadership__leadershipCard}
              key={leader.id}
              onClick={() => toggleSecretImage(leader.id)}
              onMouseEnter={() => setShowSecretImageFor(leader.id)}
              onMouseLeave={() => setShowSecretImageFor(null)}
            >
              <Image
                className={classNames(
                  css.AboutLeadership__leadershipCardImage,
                  {
                    [css['AboutLeadership__leadershipCardImage--hide']]:
                      showSecretImageFor === leader.id,
                  }
                )}
                src={leader.primaryImageUrl}
              />
              <Image
                className={classNames(
                  css.AboutLeadership__leadershipCardImage,
                  {
                    [css['AboutLeadership__leadershipCardImage--hide']]:
                      showSecretImageFor !== leader.id,
                  }
                )}
                src={leader.secretImageUrl}
              />
              <div className={css.AboutLeadership__leadershipCardDetails}>
                <h3 className={css.AboutLeadership__leadershipCardName}>
                  {leader.name}
                </h3>
                <p className={css.AboutLeadership__leadershipCardTitle}>
                  {leader.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default AboutLeadership
