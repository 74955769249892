import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import { track, useTracking } from 'lib/analytics'
import {
  getSearchId,
  REGISTRY_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import trackingRegistryProperties from 'src/lib/tracking/shared/bundles/trackingRegistryProperties'
import { Registry } from 'src/types/registry'
import { RegItem } from 'src/types/regItem'
import { CurrentUser } from '../../../../../../../global'

export const useTrackProductClicked = (
  regItem: RegItem | undefined,
  registry: Registry,
  currentUser?: CurrentUser | undefined
) => {
  const tracker = useTracking()

  return () => {
    const productSaleType =
      regItem?.priceDetails?.saleAttributes?.saleType === 'active_sale'
        ? 'price_sale'
        : regItem?.priceDetails?.saleAttributes?.saleType
    const searchId = getSearchId(REGISTRY_SEARCH_INDEX_KEY) || undefined
    const searchPreviewIds = getSearchPreviewIds()

    tracker.trackEvent({
      event: track.productClicked,
      eventType: track.EventType.CLICK,
      productIds: [regItem?.productId],
      productPrice: Number(regItem?.priceDetails?.listPrice.price) || 0,
      productType: track.ProductType.PRODUCT,
      searchId,
      searchPreviewIds,
      ...(productSaleType && { saleType: [productSaleType] }),
      ...trackingRegistryProperties({
        registry,
        currentUser,
      }),
    })
  }
}
