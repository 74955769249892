import React, { useState } from 'react'
import { saveUserEvent } from 'registry/api'
import { ADD_BABYLIST_BUTTON_EVENT } from 'registry/constants'
import { contactUsPath } from 'lib/urls'
import classNames from 'classnames'
import { browserName } from 'lib/bowser'
import { Button, Checkbox, TextLink } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'

import DraggableBookmarkButton from './DraggableBookmarkButton/DraggableBookmarkButton'
import InstallInstructionsByBrowser from './InstallInstructionsByBrowser/InstallInstructionsByBrowser'
import { AddButtonModalProps } from './AddButtonModal.types'
import css from './AddButtonModal.styles.scss'

const AddButtonModal: React.FC<AddButtonModalProps> = ({
  isOpen,
  handleClose,
  bookmarkletJS,
  className,
}) => {
  const [browser, setBrowser] = useState<string>(browserName || 'Default')
  const [userCheckedInstalled, setUserCheckedInstalled] =
    useState<boolean>(false)
  const AddButtonModalClasses = classNames(css.AddButtonModal, className)

  const installBabylistButton = () => {
    if (userCheckedInstalled) {
      saveUserEvent(ADD_BABYLIST_BUTTON_EVENT, true)
    }
    handleClose()
  }

  const additionalLink = (
    <div className={css.checkboxContainer}>
      <Checkbox
        inputProps={{
          onChange: () => setUserCheckedInstalled(!userCheckedInstalled),
        }}
        size="md"
        type="primary"
      />
      <p>I've added this already</p>
    </div>
  )

  const primaryButton = (
    <Button size="md" variant="primary" onClick={installBabylistButton}>
      {userCheckedInstalled ? 'Save' : 'Close'}
    </Button>
  )

  return (
    <ScrollLockModal
      scrollToTopOnContentChange
      showCloseButton
      additionalLink={additionalLink}
      className="modal-container"
      handleClose={handleClose}
      isOpen={isOpen}
      primaryButton={primaryButton}
    >
      <div className={AddButtonModalClasses}>
        <h1>Add items from any store</h1>
        <p>The Babylist button lets you add items from any store.</p>
        <p>Drag the button below to your Bookmarks bar to get started.</p>
        <div className={css.bookmarkletJSContainer}>
          <DraggableBookmarkButton
            bookmarkletJS={bookmarkletJS}
            modalClassName="modal-container"
          />
        </div>

        <hr className={css.lineDivider} />

        <InstallInstructionsByBrowser
          browser={browser}
          setBrowser={setBrowser}
        />

        <p>
          Need help?{' '}
          <TextLink underline href={contactUsPath} size="lg" target="_blank">
            Email us
          </TextLink>
        </p>
      </div>
    </ScrollLockModal>
  )
}

export default AddButtonModal
