import { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom-v5-compat'
import {
  checkAttributeAvailability,
  productIdForSelectedAttributes,
  shortSize,
  isActiveAttribute,
} from 'src/routes/(shop)/lib/productUtils'
import { AttributeValue } from 'src/types/genericProduct'
import { pdpPath } from 'lib/urls'
import { TextControl, ThumbnailControl } from './SelectorControls'
import css from './VariantSelector.styles.scss'

interface VariantSelectorProps {
  attributes: {
    amount: string[]
    color: AttributeValue[]
    count: string[]
    size: string[]
    style: AttributeValue[]
    stage: string[]
    scent: string[]
  }
  productAttributeMap: {
    amount?: Record<string, number[]>
    color?: Record<string, number[]>
    count?: Record<string, number[]>
    size?: Record<string, number[]>
    style?: Record<string, number[]>
    stage?: Record<string, number[]>
    scent?: Record<string, number[]>
  }
  selectedAttributes: {
    amount?: string
    color?: string
    count?: string
    size?: string
    style?: string
    stage?: string
    scent?: string
  }
}

type SelectedAttributesState = Record<string, string | null>

const ATTRIBUTE_COMPONENT_MAP: Record<string, React.FC<any> | null> = {
  color: ThumbnailControl,
  style: ThumbnailControl,
  amount: TextControl,
  count: TextControl,
  size: TextControl,
  stage: TextControl,
  scent: TextControl,
}

export const VariantSelector = ({
  attributes,
  productAttributeMap,
  selectedAttributes,
}: VariantSelectorProps) => {
  const { slug, genericProductId, productId } = useParams<{
    slug: string
    genericProductId: string
    productId: string
  }>()
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedAttributesState, setSelectedAttributesState] =
    useState<SelectedAttributesState>(() => ({
      color: selectedAttributes.color || null,
      size: selectedAttributes.size || null,
      amount: selectedAttributes.amount || null,
      count: selectedAttributes.count || null,
      style: selectedAttributes.style || null,
      stage: selectedAttributes.stage || null,
      scent: selectedAttributes.scent || null,
    }))

  const navigateToValidProduct = useCallback(() => {
    const validAttributes = Object.fromEntries(
      Object.entries(selectedAttributesState).filter(
        ([, value]) => value !== null
      )
    ) as Record<string, string>
    const id = productIdForSelectedAttributes(
      validAttributes,
      productAttributeMap
    )
    if (id && id.toString() !== productId) {
      const searchParams = new URLSearchParams(location.search)

      // Remove registry params from Gift Giver PDPs when user
      // navigates to different variants. This retains parity
      // with the existing PDP behavior, and will bump the user
      // out of the 'Gift Giver' PDP and onto a 'Product' PDP.
      searchParams.delete('reg_item_id')
      searchParams.delete('registry_id')

      navigate(
        `${pdpPath(
          slug,
          genericProductId,
          id.toString()
        )}?${searchParams.toString()}`,
        {
          replace: true,
        }
      )
    }
  }, [
    selectedAttributesState,
    productAttributeMap,
    slug,
    genericProductId,
    navigate,
  ])

  useEffect(() => {
    navigateToValidProduct()
  }, [selectedAttributesState, productAttributeMap, navigateToValidProduct])

  const mappedProductId = (
    attr: string,
    value: string,
    selectedAttributes: Record<string, string | null>,
    productAttributeMap: Record<string, Record<string, number[]>>
  ): { id: number | null; attributes: Record<string, string | null> } => {
    const tempAttributes = { ...selectedAttributes, [attr]: value }

    let validProductId = productIdForSelectedAttributes(
      tempAttributes as Record<string, string>,
      productAttributeMap
    )

    if (validProductId === null) {
      // Try removing each attribute one by one from the end until a valid combination is found
      const attributes = { ...tempAttributes }
      const attributeKeys = Object.keys(tempAttributes).reverse()
      for (const key of attributeKeys) {
        if (key !== attr) {
          delete attributes[key]
          validProductId = productIdForSelectedAttributes(
            attributes as Record<string, string>,
            productAttributeMap
          )
          if (validProductId !== null) {
            break
          }
        }
      }
    }

    if (validProductId !== null) {
      const newAttributes: Record<string, string | null> = {}
      for (const [attributeName, attributeMap] of Object.entries(
        productAttributeMap
      )) {
        for (const [attributeValue, ids] of Object.entries(attributeMap)) {
          if (ids.includes(validProductId)) {
            newAttributes[attributeName] = attributeValue
            break
          }
        }
      }
      return { id: validProductId, attributes: newAttributes }
    }

    return { id: null, attributes: tempAttributes }
  }

  const handleSelectAttribute = (attributeName: string, value: string) => {
    const { id: newProductId, attributes: newAttributes } = mappedProductId(
      attributeName,
      value,
      selectedAttributesState,
      productAttributeMap
    )
    if (newProductId !== null) {
      setSelectedAttributesState(newAttributes)
    } else {
      setSelectedAttributesState((prev) => ({
        ...prev,
        [attributeName]: value,
      }))
    }
  }

  const generateHref = useCallback(
    (attr: string, value: string): string => {
      let updatedAttributes = { ...selectedAttributesState, [attr]: value }
      let validAttributes = Object.fromEntries(
        Object.entries(updatedAttributes).filter(([, v]) => v !== null)
      ) as Record<string, string>

      let mappedProductId = productIdForSelectedAttributes(
        validAttributes,
        productAttributeMap
      )

      const maxAttempts = Object.keys(validAttributes).length
      let attempts = 0

      while (mappedProductId == null && attempts < maxAttempts) {
        const attrDiff = Object.keys(validAttributes).filter(
          (key) => key !== attr
        )
        if (attrDiff.length === 0) {
          updatedAttributes = {}
          break
        } else {
          const attributeToReset = attrDiff.pop()
          if (attributeToReset) {
            delete updatedAttributes[attributeToReset]
            validAttributes = Object.fromEntries(
              Object.entries(updatedAttributes).filter(([, v]) => v !== null)
            ) as Record<string, string>
          }
        }
        mappedProductId = productIdForSelectedAttributes(
          validAttributes,
          productAttributeMap
        )
        attempts += 1
      }
      // This will link to the generic product if no valid product is found
      const finalProductId = mappedProductId || ''

      return pdpPath(slug, genericProductId, finalProductId)
    },
    [selectedAttributesState, productAttributeMap, genericProductId, slug]
  )

  const attributeKeys = useMemo(() => Object.keys(attributes), [attributes])

  return (
    <div className={css.VariantSelector}>
      {attributeKeys.map((attributeName) => {
        const SelectorComponent = ATTRIBUTE_COMPONENT_MAP[attributeName]

        if (!SelectorComponent) {
          console.warn(
            `No variant selector component found for attribute: ${attributeName}`
          )
          return null
        }

        const dependencyAttributes: Record<string, string | null> =
          attributeKeys.reduce(
            (acc, attr) => {
              if (attr !== attributeName) {
                acc[attr] = selectedAttributesState[attr]
              }
              return acc
            },
            {} as Record<string, string | null>
          )

        return (
          <div className={css.VariantSelector__attribute} key={attributeName}>
            <div className={css['VariantSelector__grid-header']}>
              <div className={css['VariantSelector__grid-header__label']}>
                {attributeName}
              </div>
              <div className={css['VariantSelector__grid-header__selected']}>
                {selectedAttributesState[attributeName]}
              </div>
            </div>
            <div
              className={
                ATTRIBUTE_COMPONENT_MAP[attributeName] === ThumbnailControl
                  ? css['VariantSelector__thumbnail-row']
                  : css['VariantSelector__flex-row']
              }
            >
              {attributes[
                attributeName as keyof VariantSelectorProps['attributes']
              ].map((attributeValue) => {
                const value =
                  typeof attributeValue === 'string' ||
                  typeof attributeValue === 'number'
                    ? attributeValue
                    : attributeValue.value

                const isDisabled = !checkAttributeAvailability(
                  dependencyAttributes,
                  productAttributeMap,
                  attributeName,
                  value
                )

                let option = attributeValue
                if (
                  typeof attributeValue === 'string' &&
                  attributeName === 'size'
                ) {
                  option = shortSize(attributeValue)
                }

                const href = generateHref(attributeName, value)

                return (
                  <SelectorComponent
                    href={href}
                    isActive={isActiveAttribute(
                      selectedAttributesState[attributeName],
                      value
                    )}
                    isDisabled={isDisabled}
                    key={value}
                    option={option}
                    onClick={() => handleSelectAttribute(attributeName, value)}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
