import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'baby-design'
import fetch from 'lib/fetch'
import { apiV3AddItemsGuidesPath, helloBabyProductsAndGearPath } from 'lib/urls'
import { track } from 'lib/analytics'

import css from './PopularGuides.styles.scss'

import { PopularGuidesProps } from './PopularGuides.types'
import Topic from '../Topic/Topic'

const PopularGuides: React.FC<PopularGuidesProps> = ({ className }) => {
  const PopularGuidesClasses = classNames(css.PopularGuides, className)

  const [isFetching, setIsFetching] = useState(true)
  const [topics, setTopics] = useState([])

  const viewAllCTA = 'View all guides'
  const visitGuides = () => {
    track.addItemsScreenClicked({
      eventLocation: track.EventLocation.ADD_ITEMS,
      eventCta: viewAllCTA,
    })
    // open in a new window the link https://www.babylist.com/hello-baby/gear
    window && window.open(helloBabyProductsAndGearPath, '_blank')
  }

  useEffect(() => {
    fetch(apiV3AddItemsGuidesPath)
      .then((data) => {
        setIsFetching(false)
        setTopics(data.guides || [])
      })
      .catch((error) => {
        console.error('Error fetching guides', error)
        setIsFetching(false)
      })
  }, [])

  const renderLoadingTiles = () => {
    const loadingTiles = []
    for (let i = 0; i < 6; i++) {
      loadingTiles.push(
        <div className={css.PopularGuides__loadingShimmer} key={i} />
      )
    }
    return loadingTiles
  }

  return (
    <div className={PopularGuidesClasses}>
      <h2>Explore product guides</h2>
      <div className={css.PopularGuides__topics}>
        {isFetching && renderLoadingTiles()}
        {!isFetching &&
          topics.map((topic, index) => <Topic key={index} topic={topic} />)}
        <div className={css.PopularGuides__button}>
          <Button size="md" variant="tertiary" onClick={visitGuides}>
            {viewAllCTA}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PopularGuides
