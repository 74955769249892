import Cookies from 'js-cookie'

import { CartResponse } from 'src/api/types'
import { RegItem, RegItemMinimal } from 'src/types/regItem'

let CART_QUANTITY_BY_REG_ITEM_CACHE_KEY: string
let CART_QUANTITY_BY_REG_ITEM = new Map()

export const getIsInCart = (
  regItem: RegItemMinimal | RegItem,
  cart?: CartResponse
) => {
  if (!cart) return false
  return getCartedQuantity(regItem, cart) > 0
}

export const getCartedQuantity = (
  regItem: RegItemMinimal | RegItem,
  cart?: CartResponse
) => {
  if (!cart) return 0

  const cartQuantityByRegItem = getCartQuantityByRegItem(cart)
  return cartQuantityByRegItem.get(regItem.id)?.quantity || 0
}

export const getCartedType = (
  regItem: RegItemMinimal | RegItem,
  cart?: CartResponse
) => {
  if (!cart) return undefined

  const cartQuantityByRegItem = getCartQuantityByRegItem(cart)
  return cartQuantityByRegItem.get(regItem.id)?.type
}

export const generateCartQuantityByRegItem = (cart: CartResponse) => {
  const newCartQuantityByRegItem = new Map()

  cart.reservations?.forEach((reservation) => {
    if (Cookies.get('email') !== reservation.email) return

    setOrIncrementMapValue(
      newCartQuantityByRegItem,
      reservation.regItemId,
      reservation.quantity,
      'Reservation'
    )
  })

  cart.cart?.items?.forEach((cartItem) => {
    if (!cartItem.regItem) return

    setOrIncrementMapValue(
      newCartQuantityByRegItem,
      cartItem.regItem?.id,
      cartItem.quantity,
      'CartItem'
    )
  })

  return newCartQuantityByRegItem
}

export const resetCartCache = () => {
  CART_QUANTITY_BY_REG_ITEM_CACHE_KEY = ''
  CART_QUANTITY_BY_REG_ITEM = new Map()
}

const getCartQuantityByRegItem = (cart: CartResponse) => {
  const cacheKey = generateCacheKey(cart)

  if (CART_QUANTITY_BY_REG_ITEM_CACHE_KEY !== cacheKey) {
    CART_QUANTITY_BY_REG_ITEM = generateCartQuantityByRegItem(cart)
    CART_QUANTITY_BY_REG_ITEM_CACHE_KEY = cacheKey
  }

  return CART_QUANTITY_BY_REG_ITEM
}

const generateCacheKey = (cart: CartResponse) => {
  const reservationKeys = cart.reservations?.map(
    (r) => `${r.regItemId}${r.quantity}`
  )
  const cartItemKeys = cart.cart?.items?.map(
    (i) => `${i.regItem?.id}${i.quantity}`
  )

  return [...reservationKeys, ...cartItemKeys].join('')
}

const setOrIncrementMapValue = (
  map: Map<number, { quantity: number; type: 'CartItem' | 'Reservation' }>,
  key: number,
  value: number,
  type: 'CartItem' | 'Reservation'
) => {
  const existingValue = map.get(key)

  if (existingValue) {
    map.set(key, {
      quantity: existingValue.quantity + value,
      type,
    })
  } else {
    map.set(key, {
      quantity: value,
      type,
    })
  }
}
