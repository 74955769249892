import { InfoButton } from 'components/buttons'
import { track, useTracking } from 'lib/analytics/track'
import { registryChecklistPath } from 'lib/urls'

interface ChecklistCTAProps {
  registryId: number
}

const ChecklistCTA = ({ registryId }: ChecklistCTAProps) => {
  const tracker = useTracking()

  const handleClick = () => {
    tracker.trackEvent({
      checklistType: 1,
      event: track.checklistViewed,
      eventLocation: track.EventLocation.REGISTRY_EMPTY_STATE,
      registryId,
    })
  }

  return (
    <div className="text-center">
      <div className="h4 text-brand-info text-bold mtn phm lh-md">
        Start your registry with our checklist.
      </div>
      <InfoButton
        pill
        className="phxl mtl"
        href={registryChecklistPath}
        onClick={handleClick}
      >
        Go to checklist
      </InfoButton>
    </div>
  )
}

export default ChecklistCTA
