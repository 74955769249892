import React, { useContext } from 'react'
import classNames from 'classnames'

import { ScrollTabs, IconButton } from 'baby-design'
import { Tab } from 'baby-design/components/ScrollTabs/ScrollTabs.types'
import { EditPencil } from 'baby-design/icons'
import { useNavigate } from 'react-router-dom-v5-compat'
import NiceModal from '@ebay/nice-modal-react'
import { track, useTracking, withContextualizedTracking } from 'lib/analytics'
import { registryChecklistPath, registryGiftTrackerPath } from 'lib/urls'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import {
  BIRTHDAY_REGISTRY_TYPE,
  HOLIDAY_REGISTRY_TYPE,
} from 'registry/constants'
import ChecklistCard from 'src/routes/(registry)/components/ChecklistCard'
import GiftTrackerCard from 'src/routes/(registry)/components/GiftTrackerCard'
import CategoryOrganizeDrawer from '../../list/[slug]/components/CategoryOrganizeDrawer/CategoryOrganizeDrawer'
import RegistryContext from '../../../../contexts/registry'
import RegistrySwitcher from '../RegistrySwitcher/RegistrySwitcher'
import { RegistryNavigationProps } from './RegistryNavigation.types'
import css from './RegistryNavigation.styles.scss'

const RegistryNavigation: React.FC<RegistryNavigationProps> = ({
  currentRegistry,
  categories,
}) => {
  const tracker = useTracking()
  const navigate = useNavigate()
  const [currentUser] = useCurrentUser()
  const RegistryNavigationClasses = classNames(css.RegistryNavigation)
  const { activeCategory, setActiveCategory } = useContext(RegistryContext)
  const listType = currentRegistry?.type || 'baby_registry'
  const baseTrackingData = {
    listType,
    registryId: currentRegistry?.id,
    registryOwnerId: currentRegistry?.ownerId,
    isRegistryOwner: currentRegistry?.ownerId === currentUser?.id,
    eventLocation: track.EventLocation.REGISTRY,
  }
  let categoryTabs: Tab<KeyType>[] = []

  if (categories && categories?.length > 0) {
    categoryTabs = categories
      .sort((a: any, b: any) => a.position - b.position)
      .map((category: any) => ({
        key: JSON.stringify(category.id),
        label: category.title,
      })) as Tab<KeyType>[]
  }

  const smoothScrollTo = (targetSelector: string) => {
    const target = document.querySelector(targetSelector)
    if (target) {
      const topOffset = target.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      })
    }
  }

  const handleCategoryChange = (categoryId: string) => {
    const category = currentRegistry?.categories.find(
      (category: any) => JSON.stringify(category.id) === categoryId
    )
    console.log('category', category)
    tracker.trackEvent({
      ...baseTrackingData,
      event: track.registryCategoryNavClicked,
      positionInList: category?.position,
      categoryTitle: category?.title,
      categoryId: category?.id,
    })

    smoothScrollTo(`#category-${category.id}`)
    setTimeout(() => {
      setActiveCategory({
        value: category.id,
        label: category.title,
      })
    }, 500)
  }

  const handleOrganizeCategoriesClick = () => {
    tracker.trackEvent({
      ...baseTrackingData,
      event: track.registryOrganizeClicked,
    })
    NiceModal.show(CategoryOrganizeDrawer)
  }

  const handleChecklistClick = () => {
    tracker.trackEvent({
      ...baseTrackingData,
      checklistType: 1,
      event: track.checklistViewed,
    })
    navigate(registryChecklistPath)
  }

  const handleGiftTrackerClick = () => {
    tracker.trackEvent({
      ...baseTrackingData,
      event: track.giftTrackerClicked,
    })
    navigate(registryGiftTrackerPath)
  }

  const shouldShowChecklistCard = ![
    BIRTHDAY_REGISTRY_TYPE,
    HOLIDAY_REGISTRY_TYPE,
  ].includes(listType)

  return (
    <div className={RegistryNavigationClasses}>
      <div className={css.RegistryNavigation__heading}>
        <div
          className={css.RegistryNavigation__title}
          onClick={() => navigate(`/list/${currentRegistry?.urlSlug}`)}
        >
          Your Registry{' '}
          {currentRegistry && (
            <span className="sr-only">{currentRegistry.title}</span>
          )}
        </div>
        <div className={css.RegistryNavigation__switcher}>
          <RegistrySwitcher />
        </div>
      </div>
      <div className={css.RegistryNavigation__actions}>
        {shouldShowChecklistCard && (
          <ChecklistCard
            currentRegistry={currentRegistry}
            variant="desktop"
            onClick={handleChecklistClick}
          />
        )}
        <GiftTrackerCard
          currentRegistry={currentRegistry}
          variant="desktop"
          onClick={handleGiftTrackerClick}
        />
      </div>
      {categoryTabs.length > 0 && (
        <div className={css.RegistryNavigation__categories}>
          <div className={css.RegistryNavigation__categoriesTitle}>
            <h3>Categories</h3>
            <IconButton
              size="sm"
              variant="fill-secondary"
              onClick={() => handleOrganizeCategoriesClick()}
            >
              <EditPencil />
            </IconButton>
          </div>
          <ScrollTabs
            selectedTabKey={JSON.stringify(activeCategory.value)}
            tabs={categoryTabs}
            type="primary"
            variant="vertical"
            onClick={({ clickedTabKey }) => handleCategoryChange(clickedTabKey)}
          />
        </div>
      )}
    </div>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY,
})(RegistryNavigation)
