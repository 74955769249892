import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useIntersectionObserver, useWindowSize } from 'usehooks-ts'
import { useScrollDirection } from 'react-use-scroll-direction'

import { RegistryStickyControlsBarProps } from './RegistryStickyControlsBar.types'
import css from './RegistryStickyControlsBar.styles.scss'
import RegistryCategoryFilters from '../RegistryCategoryFilters/RegistryCategoryFilters'
import RegistrySortFilterDropdowns from '../RegistrySortFilterDropdowns/RegistrySortFilterDropdowns'
import { useAppliedSortsAndFiltersContext } from '../../contexts'

const RegistryStickyControlsBar: React.FC<RegistryStickyControlsBarProps> = ({
  firstNames,
  previewFilterResults,
  regItems,
  reservations,
  sortFilterOptions,
}: RegistryStickyControlsBarProps) => {
  const [isSortAndFilterSticky, setIsSortAndFilterSticky] = useState(false)
  const [navigationOffset, setNavigationOffset] = useState(0)
  const stickyControlsBarRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()
  const { appliedSortsAndFilters } = useAppliedSortsAndFiltersContext()

  useEffect(() => {
    if (typeof document === 'undefined') return

    setNavigationOffset(document?.querySelector('#header')?.clientHeight || 0)
  }, [windowSize?.width])

  const intersectionEntry = useIntersectionObserver(stickyControlsBarRef, {
    rootMargin: `-${navigationOffset}px 0px 0px 0px`,
    threshold: [0, 1],
  })

  const { isScrollingUp, isScrollingDown } = useScrollDirection()

  useEffect(() => {
    const intersectionRatio = intersectionEntry?.intersectionRatio || 0
    if (intersectionRatio === 1) {
      setIsSortAndFilterSticky(false)
    }
    if (intersectionRatio < 1 && isScrollingUp) {
      setIsSortAndFilterSticky(true)
    }
    if (intersectionRatio < 1 && isScrollingDown) {
      setIsSortAndFilterSticky(false)
    }
  }, [isScrollingDown, isScrollingUp, intersectionEntry?.intersectionRatio])

  const stickyBarClassnames = classNames(
    css.RegistryStickyControlsBar__StickyBar,
    {
      [css.RegistryStickyControlsBar__Sticky]: isSortAndFilterSticky,
    }
  )

  useEffect(() => {
    const intersectionRatio = intersectionEntry?.intersectionRatio || 0
    if (!intersectionRatio && intersectionEntry && intersectionEntry.time > 0) {
      stickyControlsBarRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [appliedSortsAndFilters])

  return (
    <div
      className={css.RegistryStickyControlsBar}
      data-testid="RegistryStickyControlsBar"
      ref={stickyControlsBarRef}
    >
      <div
        className={stickyBarClassnames}
        data-testid="StickyBar"
        style={{ top: `${isSortAndFilterSticky ? navigationOffset : 0}px` }}
      >
        <div className={css.RegistryStickyControlsBar__Categories}>
          <div className={css.RegistryStickyControlsBar__MaxWidthWrapper}>
            <RegistryCategoryFilters
              regItems={regItems}
              reservations={reservations}
              sortFilterOptions={sortFilterOptions}
            />
          </div>
        </div>

        <div className={css.RegistryStickyControlsBar__Dropdowns}>
          <div className={css.RegistryStickyControlsBar__MaxWidthWrapper}>
            <RegistrySortFilterDropdowns
              firstNames={firstNames}
              previewFilterResults={previewFilterResults}
              sortFilterOptions={sortFilterOptions}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistryStickyControlsBar
