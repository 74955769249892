import { apiV3ReservedRegItemsPath, unreserveReservationPath } from 'lib/urls'
import fetch from 'lib/fetch'

export const getReservedRegItems = (registryId: number) =>
  fetch(apiV3ReservedRegItemsPath(registryId), {
    method: 'GET',
  })

export const removeReservation = (reservationToken: string) =>
  fetch(unreserveReservationPath(reservationToken), {
    method: 'POST',
  })
