import React from 'react'

interface ShoppingCartEmptyProps {
  className?: string
}

const ShoppingCartEmpty: React.FC<ShoppingCartEmptyProps> = ({
  className,
}: ShoppingCartEmptyProps) => (
  <svg
    className={className}
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Empty Shopping Cart Icon</title>
    <path
      clipRule="evenodd"
      d="M2.5 4C2.5 3.17157 3.17157 2.5 4 2.5C8.35273 2.5 12.1781 5.38548 13.3739 9.57074L14.1123 12.1552H39.1503C42.6077 12.1552 45.2077 15.3075 44.5498 18.7017L42.8587 27.4271C42.1752 30.9534 39.0876 33.5 35.4957 33.5H22.748C19.3994 33.5 16.4565 31.2802 15.5366 28.0604L10.4893 10.3949C9.6615 7.49755 7.01329 5.5 4 5.5C3.17157 5.5 2.5 4.82843 2.5 4ZM14.9694 15.1552L18.4212 27.2362C18.9731 29.1681 20.7389 30.5 22.748 30.5H35.4957C37.6508 30.5 39.5034 28.972 39.9135 26.8562L41.6046 18.1309C41.9036 16.588 40.7218 15.1552 39.1503 15.1552H14.9694ZM16.5 41C16.5 38.5147 18.5147 36.5 21 36.5C23.4853 36.5 25.5 38.5147 25.5 41C25.5 43.4853 23.4853 45.5 21 45.5C18.5147 45.5 16.5 43.4853 16.5 41ZM21 39.5C20.1716 39.5 19.5 40.1716 19.5 41C19.5 41.8284 20.1716 42.5 21 42.5C21.8284 42.5 22.5 41.8284 22.5 41C22.5 40.1716 21.8284 39.5 21 39.5ZM32.5 41C32.5 38.5147 34.5147 36.5 37 36.5C39.4853 36.5 41.5 38.5147 41.5 41C41.5 43.4853 39.4853 45.5 37 45.5C34.5147 45.5 32.5 43.4853 32.5 41ZM37 39.5C36.1716 39.5 35.5 40.1716 35.5 41C35.5 41.8284 36.1716 42.5 37 42.5C37.8284 42.5 38.5 41.8284 38.5 41C38.5 40.1716 37.8284 39.5 37 39.5Z"
      fill="white"
      fillRule="evenodd"
    />
  </svg>
)

export default ShoppingCartEmpty
