import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { absoluteUrl, locationsPath } from 'lib/urls'
import Locations from './components/Locations'

const LocationsPage: FC = () => {
  const title = 'Locations | Babylist Inc.'
  const description =
    'Discover the best selection of baby products at Babylist Beverly Hills in Los Angeles or online from the comfort of home.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(locationsPath)} rel="canonical" />
      </Helmet>
      <Locations />
    </div>
  )
}

export default LocationsPage
