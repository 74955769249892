import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, PricingTreatment, StoreIcon } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LinkOut } from 'baby-design/icons'

import { ExternalRegItemOfferProps } from './ExternalRegItemOffer.types'
import css from '../../RegItemOffer.styles.scss'
import { useGetHandleExternalOfferClick } from '../../../../hooks/useGetExternalOfferClickHandler/useGetExternalOfferClickHandler'
import { useTrackRegistryProductOfferClicked } from '../../../../hooks/useTrackRegistryProductOfferClicked/useTrackRegistryProductOfferClicked'

const ExternalRegItemOffer: React.FC<ExternalRegItemOfferProps> = ({
  offer,
  regItem,
  registry,
  currentUser,
}) => {
  const handleExternalOfferClick = useGetHandleExternalOfferClick(
    offer,
    registry,
    regItem
  )

  const trackRegistryProductOfferClicked = useTrackRegistryProductOfferClicked(
    registry,
    regItem,
    currentUser,
    offer
  )

  const handleClick = () => {
    trackRegistryProductOfferClicked()
    handleExternalOfferClick()
  }

  const buttonAccessibleText = 'Buy from store'

  return (
    <div className={css.RegItemOffer} data-testid="ExternalRegItemOffer">
      <div className={css.RegItemOffer__Offer}>
        <div className={css.RegItemOffer__Details}>
          <StoreIcon
            className={css.RegItemOffer__StoreIcon}
            store={{ name: offer.storeIconName }}
          />
          <div>
            <div className={css.RegItemOffer__StoreName}>
              {offer.storeDisplayName}
            </div>
            <div className={css.RegItemOffer__Price}>
              <PricingTreatment price={Number(offer.price)} size="sm" />
            </div>
          </div>
          <button
            aria-label={buttonAccessibleText}
            className={css.RegItemOffer__OverlayButton}
            data-testid="OverlayButton"
            type="button"
            onClick={handleClick}
          />
        </div>
        <div className={css.RegItemOffer__CTA}>
          <Button
            icon={{
              side: 'right',
              size: 'md',
              icon: <LinkOut />,
            }}
            size="sm"
            title={buttonAccessibleText}
            variant="tertiary"
            onClick={handleClick}
          >
            Buy
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ExternalRegItemOffer
