const favors = {
  BABYSITTING: 'Babysitting',
  DOG_WALKING: 'Dog Walking',
  HOUSE_CLEANING: 'House Cleaning',
  PRELOVED_BABY_BOOKS: 'Baby Books',
  HOME_COOKED_MEALS: 'Home-Cooked Meal',
  PRELOVED_BABY_CLOTHES: 'Preloved Baby Clothes',
}

const getHintText = (favor) => {
  switch (favor) {
    case favors.PRELOVED_BABY_CLOTHES:
      return "Tell your friends and family that you'd be happy to take some of the sweet clothes their kids have outgrown."
    case favors.HOUSE_CLEANING:
      return 'Tell your friends and family how they can help with house cleaning or laundry, or add a link to your favorite local service where they can buy a gift card.'
    case favors.BABYSITTING:
      return "Tell your friends and family how they can help with babysitting, like if you'd like an afternoon or evening off, or just a few hours here and there."
    case favors.HOME_COOKED_MEALS:
      return "Tell your friends and family what you're hungry for, whether it's bringing dinner over one night, some frozen meals or supplies for a few breakfasts."
    case favors.DOG_WALKING:
      return 'Tell your friends and family how they can help you with walking your dog (and a little bit about your pup!) or add a link to your favorite local service where they can buy a gift card.'
    case favors.PRELOVED_BABY_BOOKS:
      return 'Tell your friends and family to pass along some of their family reading favorites to your little one.'
    default:
      return 'Let your Gift Givers know how they can help!'
  }
}

export default getHintText
