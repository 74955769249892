import { useEffect } from 'react'
import { datadogRumAsync } from 'src/utils/datadogRumAsync/datadogRumAsync'

export const DATADOG_RUM_READY_ATTEMPT_LIMIT = 10

const useSetDatadogRumViewName = (viewName: string) => {
  useEffect(() => {
    datadogRumAsync({
      callback: (DD_RUM) => DD_RUM.setViewName(viewName),
      maxAttempts: DATADOG_RUM_READY_ATTEMPT_LIMIT,
    })
  }, [viewName])
}

export default useSetDatadogRumViewName
