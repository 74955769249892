import React from 'react'
import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image, MostWantedFilled } from 'baby-design/icons'

import { RegItemMinimalCardImageProps } from './RegItemMinimalCardImage.types'
import css from './RegItemMinimalCardImage.styles.scss'
import { shouldDisplayMissingImage } from './RegItemMinimalCardImage.utils'
import PurchasedBadge from './components/PurchasedBadge/PurchasedBadge'
import getRegItemType from '../../../../utils/getRegItemType/getRegItemType'
import InCartBadge from './components/InCartBadge/InCartBadge'
import {
  getQuantityText,
  shouldDisplayQuantity,
} from '../../../../utils/regItemQuantityHelper/regItemQuantityHelper'

const RegItemMinimalCardImage: React.FC<RegItemMinimalCardImageProps> = ({
  regItem,
  reservation,
  variant,
}: RegItemMinimalCardImageProps) => {
  const regItemImageClasses = classNames(css.RegItemMinimalCardImage, {
    [css.RegItemMinimalCardImage__Default]: variant === 'Default',
    [css.RegItemMinimalCardImage__Wide]: variant === 'Wide',
  })
  const isQuantityVisible = shouldDisplayQuantity(regItem, reservation)
  const { prefix: qtyPrefix, value: qtyValue } = getQuantityText({
    regItem,
    reservation,
    isShortForm: true,
  })
  const isMissingImageVisible = shouldDisplayMissingImage(regItem)
  // In this scope reservation will only be passed in for items which show in the "purchased" category
  // which means we can use the presence of reservation to toggle the "Purchased" vs "Unpurchased" treatments
  const isPurchased = !!reservation

  return (
    <div className={regItemImageClasses} data-testid="RegItemMinimalCardImage">
      {isMissingImageVisible && (
        <div
          aria-hidden="true"
          className={css.RegItemMinimalCardImage__MissingImage}
          data-testid="MissingImage"
        >
          <Image
            dangerouslyOverrideClassName={
              css.RegItemMinimalCardImage__MissingImageIcon
            }
          />
        </div>
      )}
      {!isMissingImageVisible && (
        <img
          alt={regItem.title}
          className={css.RegItemMinimalCardImage__Image}
          data-testid="Image"
          src={regItem.imgUrl}
        />
      )}
      {isQuantityVisible && (
        <div className={css.RegItemMinimalCardImage__Quantity}>
          {qtyPrefix}: {qtyValue}
        </div>
      )}

      {!isPurchased && (
        <>
          {regItem.isFavorite && (
            <>
              <span aria-hidden="true">
                <MostWantedFilled
                  className={css.RegItemMinimalCardImage__MostWantedIcon}
                  data-testid="MostWantedIcon"
                />
              </span>
              <span className="sr-only">Most Wanted</span>
            </>
          )}
          <InCartBadge regItem={regItem} />
        </>
      )}
      {isPurchased && (
        <div className={css.RegItemMinimalCardImage__PurchasedBadge}>
          <PurchasedBadge
            regItemType={getRegItemType(regItem)}
            reservation={reservation}
          />
        </div>
      )}
    </div>
  )
}

export default RegItemMinimalCardImage
