import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Checkbox, StoreIcon, TextLink } from 'baby-design'
import { RegistryStoreFilterOption } from 'src/types/registrySortFilterOptions'
import { useAppliedSortsAndFiltersContext } from '../../../../contexts'
import { StoreFilterProps } from './StoreFilter.types'
import css from './StoreFilter.styles.scss'

const storeOptionsIdPrefix = 'RegistryStores'

const StoreFilter: React.FC<StoreFilterProps> = ({
  firstNames,
  sortFilterOptions,
  closePopover,
  previewFilterResults,
}: StoreFilterProps) => {
  const storeFilterRef = useRef<HTMLDivElement>(null)
  const { appliedSortsAndFilters, onSortFilterChange } =
    useAppliedSortsAndFiltersContext()
  const [selectedStores, setSelectedStores] = React.useState<string[]>(
    appliedSortsAndFilters.filters?.stores || []
  )
  const previewSortFiltersDiff = { filters: { stores: selectedStores } }
  const { count: previewCount } = previewFilterResults(
    previewSortFiltersDiff,
    appliedSortsAndFilters
  )

  useEffect(() => {
    storeFilterRef.current?.focus()
  }, [storeFilterRef])

  return (
    <div
      aria-label="Filter by store"
      className={css.StoreFilter}
      data-testid="StoreFilter"
      ref={storeFilterRef}
      tabIndex={-1}
    >
      <div className={css.StoreFilter__Stores}>
        {sortFilterOptions.filters.store.options.map(
          (store: RegistryStoreFilterOption) => {
            const radioId = `${storeOptionsIdPrefix}-${store.value}`

            return (
              <div key={`${store.value}-${store.name}`}>
                <label className={css.StoreFilter__Label} htmlFor={radioId}>
                  <StoreIcon
                    className={css.StoreFilter__Icon}
                    store={{ name: store.storeIconName }}
                  />
                  <span className={css.StoreFilter__StoreName}>
                    {store.name}
                  </span>
                  <Checkbox
                    className={css.StoreFilter__Checkbox}
                    inputProps={{
                      checked: selectedStores.includes(store.value),
                      name: store.value,
                      value: store.value,
                      id: radioId,
                      onChange: () => {
                        if (selectedStores.includes(store.value)) {
                          setSelectedStores(
                            selectedStores.filter(
                              (selectedStore) => selectedStore !== store.value
                            )
                          )
                        } else {
                          setSelectedStores([...selectedStores, store.value])
                        }
                      },
                    }}
                    size="lg"
                    type="secondary"
                  />
                </label>
                {store.value === 'Babylist' && (
                  <ul className={css.StoreFilter__BabylistFeatureList}>
                    <li className={css.StoreFilter__BabylistFeatureListItem}>
                      FREE shipping over $45
                    </li>
                    <li className={css.StoreFilter__BabylistFeatureListItem}>
                      Free & easy exchanges{firstNames && ` for ${firstNames}`}
                    </li>
                  </ul>
                )}
              </div>
            )
          }
        )}
      </div>
      <div className={css.StoreFilter__Actions}>
        <TextLink
          underline
          size="md"
          variant="default"
          onClick={() => {
            onSortFilterChange({ filters: { stores: undefined } })
            closePopover()
          }}
        >
          Reset
        </TextLink>

        <Button
          disabled={previewCount === 0}
          size="sm"
          variant="primary"
          onClick={() => {
            onSortFilterChange({
              filters: { stores: selectedStores },
            })
            closePopover()
          }}
        >
          {previewCount === 0
            ? 'No items available'
            : `Show ${previewCount} available items`}
        </Button>
      </div>
    </div>
  )
}

export default StoreFilter
