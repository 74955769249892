import { useEffect } from 'react'

import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useQuery } from '@tanstack/react-query'
import { track } from 'lib/analytics'
import { getQueryParam } from 'utils'
import NiceModal from '@ebay/nice-modal-react'
import { fetchRegistry, FETCH_REGISTRY_KEY } from 'src/api/queries'
import RegistryAddItems from './components/RegistryAddItems/RegistryAddItems'
import RegistryLayout from '../../components/RegistryLayout/RegistryLayout'

const RegistryAddItemsPage = () => {
  const [currentUser] = useCurrentUser()
  const registryId = currentUser?.currentRegistry?.id

  const {
    isError,
    data: registry,
    error,
    refetch,
  } = useQuery({
    enabled: !!registryId,
    queryKey: [FETCH_REGISTRY_KEY, registryId],
    queryFn: () => fetchRegistry(registryId),
  })

  const getEntryPoint = () => {
    const entryPoint = getQueryParam('entry_point')
    return entryPoint || undefined
  }

  useEffect(() => {
    track.addItemsScreenViewed({
      eventLocation: track.EventLocation.ADD_ITEMS,
      entryPoint: getEntryPoint(),
    })
  }, [])

  useEffect(() => {
    if (registryId) {
      refetch()
    }
  }, [registryId, refetch])

  if (isError) {
    console.error(error)
  }
  return (
    <NiceModal.Provider>
      <RegistryLayout currentRegistry={registry}>
        <RegistryAddItems />
      </RegistryLayout>
    </NiceModal.Provider>
  )
}
export default RegistryAddItemsPage
