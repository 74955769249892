import { apiV3PostsPath } from 'lib/urls'

interface fetchPostsOptions {
  excludeBody?: boolean
  ids?: string[]
  limit?: number
  skip?: number
  slugs?: string[]
  topicSlugs?: string[]
}

export const FETCH_POSTS_KEY = 'FETCH_POSTS'
export const fetchPosts = async (options: fetchPostsOptions) => {
  const params = new URLSearchParams()
  params.set('exclude_body', (options.excludeBody ?? true).toString())

  if (options.limit) params.set('limit', options.limit.toString())
  if (options.skip) params.set('skip', options.skip.toString())

  if (options.ids) {
    options.ids.forEach((id) => {
      params.append('ids[]', id)
    })
  }

  if (options.slugs) {
    options.slugs.forEach((slug) => {
      params.append('slugs[]', slug)
    })
  }

  if (options.topicSlugs) {
    options.topicSlugs.forEach((slug) => {
      params.append('topic_slugs[]', slug)
    })
  }

  const response = await fetch(`${apiV3PostsPath}?${params.toString()}`, {
    method: 'GET',
  })

  const postsRaw = (await response.json()).posts
  const posts: { [slug: string]: Post } = {}

  postsRaw.forEach((post: Post) => {
    posts[post.slug] = post
  })

  return posts
}
