import React from 'react'
import { Button } from 'baby-design'
import { useReservationCardContext } from '../../../../context'
import css from './TrackPackage.styles.scss'
import buttonCss from '../ActionButton.styles.scss'

const TrackPackage: React.FC = () => {
  const { trackingInfos } = useReservationCardContext()
  if (!trackingInfos?.length) return null

  return (
    <div className={css.TrackPackage}>
      {trackingInfos.map(({ number, url }) => {
        if (!url) return null

        const linkProps = {
          href: url,
          target: '_blank',
          rel: 'noopener,noreferrer',
        }

        return (
          <>
            <Button
              block
              className={buttonCss['ActionButton--desktop']}
              key={number}
              size="sm"
              variant="tertiary"
              {...linkProps}
            >
              Track package
            </Button>
            <Button
              block
              className={buttonCss['ActionButton--mobile']}
              key={number}
              size="md"
              variant="tertiary"
              {...linkProps}
            >
              Track package
            </Button>
          </>
        )
      })}
    </div>
  )
}

export default TrackPackage
