import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { UseInfiniteQueryResult, UseQueryResult } from '@tanstack/react-query'
import NiceModal from '@ebay/nice-modal-react'

import { Registry } from 'src/types/registry'
import { RegItemCategory } from 'src/types/regItemCategory'
import { RegItem } from 'src/types/regItem'
import { ProductCategory } from '../components/RegItemCard/RegItemCard.types'
import RegItemFilterDrawer, {
  ShowFilterOption,
  ItemSettingsFilter,
  PriceFilterOption,
  RegItemFilters,
  StoreFilterData,
} from '../components/RegItemFilterDrawer/RegItemFilterDrawer'
import { DEFAULT_CATEGORY_TAB, getCategoriesAsKeyValue } from './productHelpers'
import { RegItemsByCategory } from '../components/RegistryItemsList/RegistryItemsList.types'

// Returns an object with the category name as the key and the category object as the value
// and only contains the categories that have reg items
export const getCategoriesWithItems = (
  regItemsByCategory: { [key: string]: any[] },
  registry: Registry
) =>
  Object.keys(regItemsByCategory)
    .map((categoryName) =>
      registry?.categories.find(
        (c: RegItemCategory) => c.title === categoryName
      )
    )
    .filter(
      (category: RegItemCategory | undefined): category is RegItemCategory =>
        category !== undefined
    )
    .sort((a: RegItemCategory, b: RegItemCategory) => {
      if (a === undefined || b === undefined) {
        return 0
      }
      return a.position - b.position
    }) as RegItemCategory[]

// Formats the regItems into an object with the category name as the key and the regItems as the value
// so that the regItems can be displayed by category
export const getRegItemsByCategory = (
  regItems: RegItem[],
  categories: ProductCategory[],
  filters: RegItemFilters
) =>
  Object.values(regItems as RegItem[])
    .filter((regItem) => regItemFilter(regItem, filters, categories))
    .reduce(
      (acc, item: RegItem) => {
        const category = categories.find(
          (cat: any) => cat.value === item.categoryId
        )

        if (!category) {
          return acc
        }
        if (!acc[category.label]) {
          acc[category.label] = []
        }

        acc[category.label].push(item)
        acc[category.label] = acc[category.label]
          .sort((a: any, b: any) => a.position - b.position)
          .reverse()
        return acc
      },
      {} as { [key: string]: any[] }
    )

const showFilter = (regItem: RegItem, show: ShowFilterOption) => {
  switch (show) {
    case 'notPurchased':
      return regItem.quantityNeeded > 0
    case 'purchased':
      return regItem.isReserved || regItem.quantityPurchased > 0
    case 'all':
    default:
      return true
  }
}

const itemSettingsFilter = (
  regItem: RegItem,
  filter: ItemSettingsFilter,
  categories: Record<number, ProductCategory>
) => {
  const { mostWanteds, groupGifts, privates, eligibleForRegistryDiscount } =
    filter
  if (mostWanteds && !regItem.isFavorite) {
    return false
  }
  if (groupGifts && !regItem.isGroupGift) {
    return false
  }

  if (
    privates &&
    !(regItem.private || categories[regItem.categoryId]?.isPrivate)
  ) {
    return false
  }

  if (eligibleForRegistryDiscount && !regItem.eligibleForRegistryDiscount) {
    return false
  }

  return true
}

export const PRICE_FILTER_MIN = 0
export const PRICE_FILTER_MAX = 2000
const priceFilter = (regItem: RegItem, price: PriceFilterOption) => {
  const { min, max } = price
  if (min === PRICE_FILTER_MIN && max === PRICE_FILTER_MAX) return true

  const regItemPrice =
    typeof regItem.price === 'string'
      ? parseFloat(regItem.price)
      : regItem.price

  const minPrice = min === PRICE_FILTER_MIN ? -Infinity : min
  const maxPrice = max === PRICE_FILTER_MAX ? Infinity : max
  if (regItemPrice < minPrice || regItemPrice > maxPrice) {
    return false
  }

  return true
}

const storeFilter = (
  regItem: RegItem,
  stores: { [key: string]: StoreFilterData }
) => {
  const showAllStores = Object.values(stores).every((store) => store?.show)
  if (!showAllStores) {
    const storeNames = regItem.offers.map((offer) => offer.storeDisplayName)
    const showItem = storeNames.some((storeName) => stores[storeName]?.show)
    if (!showItem) {
      return false
    }
  }

  return true
}

const getIndexedCategories = (categories: ProductCategory[]) =>
  categories.reduce(
    (acc, cat) => {
      acc[cat.value] = cat
      return acc
    },
    {} as Record<number, ProductCategory>
  )

const regItemFilter = (
  regItem: RegItem,
  regItemFilters: RegItemFilters,
  categories: ProductCategory[]
) => {
  const { show, itemSettings, price, stores } = regItemFilters

  return (
    showFilter(regItem, show) &&
    itemSettingsFilter(
      regItem,
      itemSettings,
      getIndexedCategories(categories)
    ) &&
    priceFilter(regItem, price) &&
    storeFilter(regItem, stores)
  )
}

export const getMoveRegItem =
  (
    regItemsByCategory: RegItemsByCategory,
    registry: Registry,
    setRegItemsByCategory: Dispatch<SetStateAction<RegItemsByCategory>>,
    setCategoriesWithItems: Dispatch<SetStateAction<RegItemCategory[]>>
  ) =>
  (
    sourceCategoryLabel: string,
    destinationCategoryLabel: string,
    sourceIndex: number,
    destinationIndex: number,
    regItem: RegItem
  ) => {
    if (!sourceCategoryLabel || !destinationCategoryLabel) return
    const isMovingCategories = sourceCategoryLabel !== destinationCategoryLabel
    const oldCategoryList = [...regItemsByCategory[sourceCategoryLabel]]
    const newCategoryList = isMovingCategories
      ? [...(regItemsByCategory[destinationCategoryLabel] || [])]
      : oldCategoryList

    const newCategory = registry.categories.find(
      (c: RegItemCategory) => c.title === destinationCategoryLabel
    )
    regItem.categoryId = newCategory?.id || DEFAULT_CATEGORY_TAB.value

    oldCategoryList.splice(sourceIndex, 1)
    newCategoryList.splice(destinationIndex, 0, regItem)

    const updatedRegItemsByCategory = {
      ...regItemsByCategory,
    }

    if (oldCategoryList.length > 0) {
      updatedRegItemsByCategory[sourceCategoryLabel] = oldCategoryList
    } else {
      delete updatedRegItemsByCategory[sourceCategoryLabel]
    }

    updatedRegItemsByCategory[destinationCategoryLabel] = newCategoryList

    setRegItemsByCategory(updatedRegItemsByCategory)
    setCategoriesWithItems(
      getCategoriesWithItems(updatedRegItemsByCategory, registry)
    )
  }

export const getRegItemsFromQuery = (data: any): RegItem[] =>
  data.pages.reduce(
    (acc: RegItem[], page: any) => [...acc, ...Object.values(page.regItems)],
    []
  )

export const setupRegistryListData = (
  regItemsQuery: UseInfiniteQueryResult<any, unknown>,
  registryQuery: UseQueryResult<any, unknown>,
  regItemFilters: RegItemFilters,
  setRegItemsByCategory: Dispatch<SetStateAction<RegItemsByCategory>>,
  setCategoriesWithItems: Dispatch<SetStateAction<RegItemCategory[]>>,
  setRegItemsCount: Dispatch<SetStateAction<number>>
) => {
  if (regItemsQuery.isSuccess && registryQuery.isSuccess) {
    const regItems = getRegItemsFromQuery(regItemsQuery.data)
    const registry = registryQuery.data

    setRegItemsCount(Object.keys(regItems).length)
    const categories = registry?.categories
      ? getCategoriesAsKeyValue(registry?.categories)
      : []
    const regItemsByCategory = getRegItemsByCategory(
      regItems,
      categories,
      regItemFilters
    )
    setRegItemsByCategory(regItemsByCategory)
    setCategoriesWithItems(getCategoriesWithItems(regItemsByCategory, registry))
  }
}

export const setupRegistryFilters = (
  regItemsQuery: UseInfiniteQueryResult<any, unknown>,
  regItemFilters: RegItemFilters,
  setRegItemFilters: Dispatch<SetStateAction<RegItemFilters>>
) => {
  if (!regItemsQuery.isSuccess) return

  const oldStoreFilter = regItemFilters.stores
  const newStoreFilter: Record<string, StoreFilterData> = {}

  const regItems = getRegItemsFromQuery(regItemsQuery.data) as Record<
    number,
    RegItem
  >

  Object.values(regItems).forEach((item) => {
    item.offers.forEach((offer) => {
      if (newStoreFilter[offer.storeDisplayName] === undefined) {
        if (oldStoreFilter[offer.storeDisplayName] !== undefined) {
          newStoreFilter[offer.storeDisplayName] = {
            ...oldStoreFilter[offer.storeDisplayName],
          }
        } else {
          newStoreFilter[offer.storeDisplayName] = {
            storeIconName: offer.storeIconName,
            show: true,
          }
        }
      }
    })
  })

  setRegItemFilters({
    ...regItemFilters,
    stores: newStoreFilter,
  })
}

export const openRegItemFilterDrawer =
  (
    registry: Registry,
    regItemFilters: RegItemFilters,
    setRegItemFilters: Dispatch<SetStateAction<RegItemFilters>>,
    filterCountRef: MutableRefObject<number>,
    tracker: any,
    track: any
  ) =>
  () => {
    tracker.trackEvent({
      event: track.registryFilterClicked,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: registry?.id,
      registryOwnerId: registry?.ownerId,
      listType: registry?.type || 'baby_registry',
      isRegistryOwner: registry?.ownerId === currentUser?.id,
    })

    const showRegistryDiscountEligible = registry?.isEligibleForRegistryDiscount

    const setFilters = (filters: RegItemFilters) => {
      setRegItemFilters(filters)
      NiceModal.show(RegItemFilterDrawer, {
        filters,
        setFilters,
        filterCountRef,
        showRegistryDiscountEligible,
      })
    }

    NiceModal.show(RegItemFilterDrawer, {
      filters: regItemFilters,
      setFilters,
      filterCountRef,
      showRegistryDiscountEligible,
    })
  }

export const getAppliedFilterCount = (filters: RegItemFilters) => {
  const { show, itemSettings, price, stores } = filters
  let count = 0

  if (show !== 'all') count += 1

  if (itemSettings.mostWanteds) count += 1
  if (itemSettings.groupGifts) count += 1
  if (itemSettings.privates) count += 1
  if (itemSettings.eligibleForRegistryDiscount) count += 1

  if (price.min !== PRICE_FILTER_MIN || price.max !== PRICE_FILTER_MAX) {
    count += 1
  }

  // Only count store filters if they aren't all selected
  const storeValues = Object.values(stores)
  if (storeValues.some((store) => !store.show)) {
    count += storeValues.filter((store) => store.show).length
  }

  return count
}
