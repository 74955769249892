import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { aboutPressPath, absoluteUrl } from 'lib/urls'
import AboutPress from './components/AboutPress'

const AboutPressRoute: FC = () => {
  const title = 'Press | Babylist Inc.'
  const description =
    'Babylist is a generational brand leading the $88B baby product industry. Read our latest news or contact our press team.'

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
        <meta content={description} property="og:description" />
        <link href={absoluteUrl(aboutPressPath)} rel="canonical" />
      </Helmet>
      <AboutPress />
    </div>
  )
}

export default AboutPressRoute
