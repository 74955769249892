import Button from 'baby-design/components/Button/Button'
import { useTracking } from 'lib/analytics'
import { showCartItemAddedToast } from 'shared/CartItemAddedToast/showCartItemAddedToast'
import { useUIContext } from 'src/contexts/UIContext'
import { CartItemsAPI } from 'src/routes/(shop)/api/cartItems'
import { Product } from 'src/types/product'
import { screenReaderAnnounce } from 'src/utils/screenReaderAnnounce/screenReaderAnnounce'
import { trackAddToCart } from '../../lib/tracking'
import css from './AddToCartButton.styles.scss'

interface HandleAddToCartArgs {
  quantity?: number | undefined
  price?: number | undefined
  productId?: number | undefined
}

interface AddToCartButtonProps {
  children?: (...args: any[]) => any
  loading?: boolean | undefined
  onClick?: (...args: any[]) => any | undefined
  onSuccess?: (params: { productId: number; quantity: number }) => void
  product: Product | undefined
  quantity?: number
}

export const AddToCartButton = ({
  loading,
  product,
  quantity = 1,
  onClick,
  onSuccess = () => {},
  children = () => {},
}: AddToCartButtonProps) => {
  const { mutate: addToCart, isLoading } = CartItemsAPI.useCreateMutation()
  const tracking = useTracking()
  const { isMobile } = useUIContext()

  if (!product?.approved || !product?.availableOnBabylist) return null

  const handleAddToCart = (args: HandleAddToCartArgs) => {
    const localQuantity = args.quantity || quantity
    const { price } = args
    const localProductId = args.productId || product.id

    const callbackArgs = {
      productId: localProductId,
      quantity: localQuantity,
      price,
    }

    addToCart(callbackArgs, {
      onSuccess: (_data, _variables, _context) => {
        screenReaderAnnounce('Added to cart')
        trackAddToCart({ price, product, quantity: localQuantity, tracking })
        onSuccess(callbackArgs)
        showCartItemAddedToast({ product })
      },
    })
  }

  return (
    <>
      <Button
        block
        className={css.AddToCartButton}
        loading={typeof loading !== 'undefined' ? loading : isLoading}
        rel="nofollow"
        size={isMobile ? 'md' : 'lg'}
        variant="de-emphasized"
        onClick={onClick || handleAddToCart}
      >
        Add to Cart
      </Button>
      {children({ handleAddToCart, isLoading })}
    </>
  )
}
