import React, { useState } from 'react'
import classNames from 'classnames'
import { Button, TextLink } from 'baby-design'
import {
  ADD_BABYLIST_BUTTON_EVENT,
  INSTALLED_BABYLIST_EXTENSION_EVENT,
} from 'registry/constants'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { BABYLIST_EXTENSION_URL } from 'shared/constants'
import { browserName } from 'lib/bowser'
import { track } from 'lib/analytics'
import { saveUserEvent } from 'registry/api'
import AddButtonModal from '../AddButtonModal/AddButtonModal'
import HowToUseModal from '../HowToUseModal/HowToUseModal'

import css from './AddExtension.styles.scss'

import { AddExtensionProps } from './AddExtension.types'

const AddExtension: React.FC<AddExtensionProps> = ({
  bookmarkletJS,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentUser] = useCurrentUser()

  const chromeUser = browserName === 'Chrome'

  const AddExtensionClasses = classNames(css.AddExtension, className)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const installedBabylistExtension = currentUser?.events?.some(
    (event) => event.name === INSTALLED_BABYLIST_EXTENSION_EVENT
  )

  const installedBabylistButton = currentUser?.events?.some(
    (event) => event.name === ADD_BABYLIST_BUTTON_EVENT
  )

  const buttonCopy =
    (chromeUser && installedBabylistExtension) ||
    (!chromeUser && installedBabylistButton)
      ? 'Show me how'
      : 'Get it now'
  const textLinkCopy = 'Learn more'
  const showTextLink = chromeUser && !installedBabylistExtension

  const handleButtonClick = () => {
    // fire event if the button was to install the extension or babylist button
    if (chromeUser && !installedBabylistExtension) {
      track.addItemsScreenClicked({
        eventLocation: track.EventLocation.ADD_ITEMS,
        eventCta: 'Install Extension',
      })
    } else if (!chromeUser && !installedBabylistButton) {
      track.addItemsScreenClicked({
        eventLocation: track.EventLocation.ADD_ITEMS,
        eventCta: 'Add Button',
      })
    }
    // The primary button sends the user to the extension install page
    // only if the user is on Chrome and has not yet installed the extension
    // In all other cases, it opens up a modal
    if (chromeUser && !installedBabylistExtension) {
      saveUserEvent(INSTALLED_BABYLIST_EXTENSION_EVENT, true)
      window.open(BABYLIST_EXTENSION_URL, '_blank')
    } else {
      handleOpen()
    }
  }

  return (
    <>
      <div className={AddExtensionClasses}>
        <div className={css.AddExtension__module}>
          <h2 className={css.AddExtension__title}>
            The fastest way to add baby gear to your registry
          </h2>
          <p className={css.AddExtension__subtitle}>
            {chromeUser && installedBabylistExtension && 'Use'}{' '}
            {!installedBabylistExtension && 'Install'} our quick-save button to
            add items from any website&mdash;no copy and paste required!
          </p>
          <div className={css.AddExtension__call_to_action}>
            <Button size="md" variant="primary" onClick={handleButtonClick}>
              {buttonCopy}
            </Button>
            {showTextLink && (
              <TextLink
                underline
                size="md"
                variant="default"
                onClick={handleOpen}
              >
                {textLinkCopy}
              </TextLink>
            )}
          </div>
        </div>
        <div className={css.AddExtension__image}>
          <img
            alt="Install the Babylist Button"
            height="190"
            src={
              chromeUser
                ? 'https://images.babylist.com/image/upload/f_auto,q_auto/v1712065920/AddChromeExtension/InstallExtension.png'
                : 'https://images.babylist.com/image/upload/f_auto,q_auto/v1712859967/AddChromeExtension/install-button-2x.png'
            }
            width="346"
          />
        </div>
      </div>
      {(chromeUser || installedBabylistButton) && (
        <HowToUseModal handleClose={handleClose} isOpen={isOpen} />
      )}
      {!chromeUser && !installedBabylistButton && (
        <AddButtonModal
          bookmarkletJS={bookmarkletJS}
          handleClose={handleClose}
          isOpen={isOpen}
        />
      )}
    </>
  )
}

export default AddExtension
