import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ScrollLockModal from 'shared/ScrollLockModal'
import { TwoColumnResponsiveLayout } from 'components/presentation'
import { DangerButton, DefaultButton } from 'components/buttons'
import RegItem from 'cart/components/reg-item'
import { SHOW_UNRESERVE_MODAL } from 'shared/constants'

const UnreserveModal = ({ onUnreserve }) => {
  const [res, setRes] = useState()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const handleShowModal = ({ reservation }) => {
      setRes(reservation)
      setShowModal(true)
    }
    PubSub.subscribe(SHOW_UNRESERVE_MODAL, handleShowModal)
    return () => PubSub.unsubscribe(SHOW_UNRESERVE_MODAL, handleShowModal)
  }, [])

  const handleUnreserve = () => {
    onUnreserve(res).then(() => setShowModal(false))
  }

  if (!res) return null

  return (
    <ScrollLockModal
      handleClose={() => setShowModal(false)}
      isOpen={showModal}
      title="Cancel Reservation?"
    >
      <TwoColumnResponsiveLayout>
        <RegItem className="mbl" regItem={res.regItem} />
        <div className="h6">
          <p>
            <strong>Are you sure you want to cancel this reservation?</strong>
          </p>
          <p>
            This gift will be made available for purchase again and no longer be
            reserved for {res.name}.
          </p>
          <p>
            <strong>
              Cancelling the reservation may result in duplicate gifts
            </strong>{' '}
            if the original Gift Giver did purchase this item.
          </p>
          <p>
            (Don't worry! We won't notify the Gift Giver of the cancellation.)
          </p>

          <DangerButton
            block
            empty
            pill
            className="mbm"
            onClick={handleUnreserve}
          >
            Cancel Reservation
          </DangerButton>
          <DefaultButton block empty pill onClick={() => setShowModal(false)}>
            Nevermind
          </DefaultButton>
        </div>
      </TwoColumnResponsiveLayout>
    </ScrollLockModal>
  )
}

UnreserveModal.propTypes = {
  onUnreserve: PropTypes.func.isRequired,
}

export default UnreserveModal
