import React from 'react'
import { TextLink } from 'baby-design'
import { Lock, LockOpen } from 'baby-design/icons'
import { MOBILE } from '../../../constants'
import css from './RegistryPrivacyStatus.styles.scss'
import { RegistryPrivacyStatusProps } from './RegistryPrivacyStatus.types'

const RegistryPrivacyStatus: React.FC<RegistryPrivacyStatusProps> = ({
  viewType,
  currentRegistry,
  onClick,
}) => {
  const { searchLevel } = currentRegistry || {}
  const isPrivate = searchLevel === 'private'
  const displayText = isPrivate ? 'private' : 'public'
  const containerClassName =
    css[`RegistryViewHeader__privacyStatus${viewType ? `__${viewType}` : ''}`]

  return (
    <div className={containerClassName}>
      {isPrivate ? (
        <Lock className={css.RegistryViewHeader__privacyStatusIcon} />
      ) : (
        <LockOpen className={css.RegistryViewHeader__privacyStatusIcon} />
      )}
      <p>
        Your registry is <span className="sr-only">{displayText}.</span>
        <TextLink
          aria-label="Edit Registry Visibility Settings."
          className={css.RegistryViewHeader__privacyStatusTextLink}
          role="button"
          size="sm"
          variant="default"
          weight={viewType === MOBILE ? 'regular' : 'medium'}
          onClick={onClick}
        >
          {displayText}
        </TextLink>
      </p>
    </div>
  )
}

export default RegistryPrivacyStatus
