import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'baby-design/icons'

import { ShippingAddressButtonProps } from './ShippingAddressButton.types'
import css from './ShippingAddressButton.styles.scss'
import { useGetOpenContactInfoModal } from './ShippingAddressButton.utils'
import { useShippingAddressButtonContext } from '../../contexts'

const ShippingAddressButton: React.FC<ShippingAddressButtonProps> = ({
  registry,
}) => {
  const { isShippingAddressButtonVisible, setIsShippingAddressButtonVisible } =
    useShippingAddressButtonContext()
  const openContactInfoModal = useGetOpenContactInfoModal(
    registry,
    setIsShippingAddressButtonVisible
  )

  if (!isShippingAddressButtonVisible) {
    return null
  }

  return (
    <div
      className={css.ShippingAddressButton}
      data-testid="ShippingAddressButton"
    >
      <Button
        icon={{
          side: 'left',
          icon: (
            <Location
              dangerouslyOverrideClassName={
                css.ShippingAddressButton__LocationIcon
              }
            />
          ),
          size: 'xl',
        }}
        size="md"
        variant="de-emphasized"
        onClick={openContactInfoModal}
      >
        Shipping Address
      </Button>
    </div>
  )
}

export default ShippingAddressButton
