import { useContext } from 'react'
import { BackToTopButton as Component } from './Button'
import { PDPContext } from '../../Context'

export const BackToTopButton = () => {
  const { isPastWaypointLine } = useContext(PDPContext)
  const isDisplayed = isPastWaypointLine

  if (!isDisplayed) return null
  return <Component isDisplayed={isDisplayed} />
}
