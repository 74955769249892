import { Link } from 'react-router-dom-v5-compat'
import classNames from 'classnames'
// @ts-ignore
import Tappable from 'react-tappable'
import { Media } from 'react-bootstrap'
import { helloBabyPostPath } from 'lib/urls'
import ImageWithPlaceholderColor from 'components/image-with-placeholder-color'

import css from './PostTeaser.styles.scss'

interface PostTeaserProps {
  bodyClassName?: string
  isResponsive?: boolean
  post: Post
  index: number
}

const PostTeaser = ({
  bodyClassName,
  isResponsive = true,
  post,
  index,
}: PostTeaserProps) => {
  const { url, thumbnailUrl } = post.featuredImage || {}

  const imageClasses = classNames('hover-overlay hover-lighten', {
    [css.postThumbnailMdResponsive]: isResponsive,
    [css.postThumbnailSm]: !isResponsive,
  })

  return (
    <Tappable
      className={css.postTeaser}
      classes={{ active: css.active }}
      component="div"
    >
      <Media>
        <Media.Left>
          <div className={imageClasses}>
            {post.featuredImage && (
              <ImageWithPlaceholderColor
                alt={post.title}
                aspectHeight={2}
                aspectWidth={3}
                className=""
                colorIndex={index}
                data-pin-nopin="true"
                src={thumbnailUrl?.includes('babylist') ? thumbnailUrl : url}
              />
            )}
          </div>
        </Media.Left>
        {/* @ts-ignore */}
        <Media.Body className={classNames(bodyClassName, 'pvn')}>
          <Link
            className={classNames(css.postTitle, 'media-heading', 'text-body')}
            to={helloBabyPostPath(post.slug)}
          >
            {post.title}
          </Link>
          {isResponsive && <p className="serif mbn">{post.teaser}</p>}
        </Media.Body>
      </Media>
    </Tappable>
  )
}

export default PostTeaser
