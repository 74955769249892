import { values } from 'lodash'

import { SET_POSTS } from '../actions'

const postsLookupReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_POSTS:
      const idSlugMap = values(action.posts).reduce((postIdsToSlugs, post) => {
        const rawId = post.id.split('#')
        const localization = rawId[1]
        // Only include english articles in the lookup if we want spanish articles,
        // we need to change the way we store the related articles to use slugs instead of ids.
        if (localization === 'en-US') {
          const id = rawId[0]
          postIdsToSlugs[id] = post.slug
        }
        return postIdsToSlugs
      }, {})
      return { ...state, ...idSlugMap }
    default:
      return state
  }
}

export default postsLookupReducer

export const getPostSlugById = (state, id) => state[id]
