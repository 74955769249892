import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { Content as Component } from './Content'

export const Content = () => {
  const { productId } = useParams()
  const { data, isLoading } = ProductsAPI.useShow(productId)

  if (isLoading || !data) return null
  return <Component genericProduct={data} />
}
