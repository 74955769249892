import React from 'react'

interface ClipboardWithCheckProps {
  className?: string
}

const ClipboardWithCheck: React.FC<ClipboardWithCheckProps> = ({
  className,
}: ClipboardWithCheckProps) => (
  <svg
    className={className}
    fill="none"
    height="33"
    viewBox="0 0 33 33"
    width="33"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Clipboard With Check Icon</title>
    <path
      clipRule="evenodd"
      d="M10.3341 4.73882C10.749 3.169 12.179 2.01172 13.8792 2.01172H19.2126C20.9128 2.01172 22.3428 3.169 22.7578 4.73883C25.8398 5.16651 28.2126 7.81201 28.2126 11.0117V21.1891C28.2126 22.6453 28.2126 23.7993 28.1366 24.7294C28.0589 25.6806 27.8967 26.4854 27.5223 27.2203C26.9151 28.412 25.9462 29.3809 24.7545 29.9881C24.0196 30.3625 23.2148 30.5247 22.2636 30.6024C21.3334 30.6784 20.1794 30.6784 18.7233 30.6784H14.3685C12.9124 30.6784 11.7584 30.6784 10.8282 30.6024C9.87703 30.5247 9.0722 30.3625 8.3373 29.9881C7.14561 29.3809 6.17673 28.412 5.56953 27.2203C5.19508 26.4854 5.03294 25.6806 4.95523 24.7294C4.87923 23.7992 4.87923 22.6452 4.87924 21.1891L4.87924 11.0117C4.87924 7.81201 7.25206 5.1665 10.3341 4.73882ZM10.3745 6.75936C8.38234 7.14973 6.87924 8.90519 6.87924 11.0117V21.1451C6.87924 22.655 6.88002 23.7274 6.94858 24.5665C7.01619 25.394 7.1447 25.9064 7.35155 26.3123C7.767 27.1277 8.42991 27.7906 9.24528 28.2061C9.65125 28.4129 10.1636 28.5414 10.9911 28.609C11.8303 28.6776 12.9026 28.6784 14.4126 28.6784H18.6792C20.1892 28.6784 21.2616 28.6776 22.1007 28.609C22.9282 28.5414 23.4406 28.4129 23.8465 28.2061C24.6619 27.7906 25.3248 27.1277 25.7403 26.3123C25.9471 25.9064 26.0756 25.394 26.1432 24.5665C26.2118 23.7274 26.2126 22.655 26.2126 21.1451V11.0117C26.2126 8.90519 24.7095 7.14973 22.7173 6.75936C22.256 8.25678 20.8614 9.34505 19.2126 9.34505H13.8792C12.2304 9.34505 10.8358 8.25678 10.3745 6.75936ZM13.8792 4.01172C12.9588 4.01172 12.2126 4.75791 12.2126 5.67839C12.2126 6.59886 12.9588 7.34505 13.8792 7.34505H19.2126C20.133 7.34505 20.8792 6.59886 20.8792 5.67839C20.8792 4.75791 20.133 4.01172 19.2126 4.01172H13.8792ZM21.2831 14.336C21.6563 14.7431 21.6288 15.3757 21.2216 15.7489L15.4035 21.0822C15.0211 21.4327 14.4343 21.4327 14.052 21.0822L11.8702 19.0822C11.4631 18.709 11.4356 18.0764 11.8088 17.6693C12.1819 17.2622 12.8145 17.2347 13.2216 17.6079L14.7277 18.9885L19.8702 14.2746C20.2773 13.9014 20.9099 13.9289 21.2831 14.336Z"
      fill="#4A2143"
      fillRule="evenodd"
    />
  </svg>
)

export default ClipboardWithCheck
