import React from 'react'

interface SimpleShippingGiftImageProps {
  className?: string
}

const SimpleShippingGiftImage: React.FC<SimpleShippingGiftImageProps> = ({
  className,
}) => (
  <svg
    className={className}
    fill="none"
    height="160"
    viewBox="0 0 120 160"
    width="120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3407 82.9803L59.9184 97.6024L100.1 83.0623L60.8503 69.1781L21.3407 82.9803Z"
      fill="url(#paint0_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M21.3407 82.9812V140.158L60.8503 153.52V97.6033L21.3407 82.9812Z"
      fill="url(#paint1_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M100 83.2L100.209 140.158L60.8 153.52L60.6852 97.6033L100 83.2Z"
      fill="url(#paint2_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M6.52576 97.6033L21.3402 82.9812L60.6853 97.6033L43.7194 113.756L6.52576 97.6033Z"
      fill="url(#paint3_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M27.5201 22.9814C27.4653 18.7997 27.5887 12.8826 28.6439 10.8327C30.3432 7.553 34.386 6.25477 37.6751 7.93563C39.347 8.79656 40.5119 10.2588 41.0326 11.9396L29.4799 21.7242L27.6024 23.3094C27.575 23.2 27.5475 23.1044 27.5338 22.995C27.5338 22.995 27.5338 22.995 27.5338 22.9677L27.5201 22.9814Z"
      fill="url(#paint4_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M24.0387 25.9197C24.1483 25.9607 24.2579 26.0017 24.3676 26.0427L22.586 27.5459L10.9373 37.4124C9.36131 36.6335 8.10052 35.2533 7.52493 33.4631C6.38747 29.951 8.33349 26.1793 11.8555 25.0451C14.0482 24.3482 19.9137 25.1818 24.0387 25.906V25.9197Z"
      fill="url(#paint5_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M14.2667 10.928C17.0898 8.53656 21.3244 8.8782 23.709 11.6933C25.1753 13.4015 26.6143 18.9224 27.5188 22.981C27.5188 22.981 27.5188 22.9947 27.5188 23.0084C27.5188 23.1314 27.5188 23.2543 27.5188 23.3773L24.4216 26.0011C24.2983 25.9738 24.1612 25.9601 24.0242 25.9328C20.1596 24.3886 14.9382 22.0791 13.4855 20.3573C11.0873 17.5422 11.4299 13.3195 14.253 10.9417L14.2667 10.928Z"
      fill="url(#paint6_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M42.1044 9.79281L8.77348 38.1694C6.69551 39.9385 6.4492 43.0524 8.22332 45.1245L18.3231 56.9205C20.0972 58.9925 23.2199 59.2382 25.2979 57.4691L58.6288 29.0924C60.7068 27.3233 60.9531 24.2094 59.179 22.1374L49.0792 10.3414C47.3051 8.26933 44.1824 8.02371 42.1044 9.79281Z"
      fill="url(#paint7_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M46.0104 39.852L29.486 20.5524L21.419 27.4203L37.9434 46.7199L46.0104 39.852Z"
      fill="url(#paint8_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M105.181 59.7499L65.5425 36.1968C64.0991 35.3391 62.2317 35.8106 61.3716 37.2499L38.8389 74.9568C37.9788 76.3961 38.4517 78.2582 39.8951 79.1159L79.5338 102.669C80.9772 103.527 82.8446 103.055 83.7047 101.616L106.237 63.909C107.097 62.4697 106.625 60.6076 105.181 59.7499Z"
      fill="url(#paint9_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M93.2734 52.6736L77.4391 43.2649L51.7974 86.1744L67.6317 95.5831L93.2734 52.6736Z"
      fill="url(#paint10_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M87.4063 48.9138C91.3668 47.5609 97.0404 45.8117 99.3291 46.167C102.988 46.7409 105.496 50.1573 104.92 53.806C104.633 55.6645 103.823 56.9892 102.398 58.0004L89.229 50.3623L87.1322 49.0914C87.2281 49.0367 87.3104 48.9821 87.4063 48.9274C87.4063 48.9274 87.4063 48.9274 87.4337 48.9274L87.4063 48.9138Z"
      fill="url(#paint11_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M83.5143 46.5345C83.5143 46.6575 83.5006 46.7668 83.5006 46.8761L81.4998 45.6599L68.4532 37.7202C68.6999 35.9847 69.6181 34.3585 71.1393 33.2516C74.1268 31.0787 78.3204 31.7347 80.5131 34.7138C81.8698 36.5723 82.9113 42.3938 83.5143 46.5208V46.5345Z"
      fill="url(#paint12_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M94.7243 32.569C97.89 34.4958 98.8904 38.6092 96.9581 41.7522C95.7795 43.6791 90.983 46.7675 87.4062 48.8993C87.4062 48.8993 87.3925 48.8993 87.3788 48.8993C87.2554 48.9403 87.1458 48.9813 87.0362 49.0223L83.569 46.9178C83.5553 46.7948 83.5279 46.6582 83.5142 46.5215C83.7745 42.3672 84.3364 36.7096 85.515 34.7965C87.4473 31.6397 91.5723 30.6421 94.7243 32.569Z"
      fill="url(#paint13_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M114.434 97.8631L100.14 83.0907L60.2742 97.4942L77.1305 113.77L114.434 97.8631Z"
      fill="url(#paint14_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <path
      d="M52.6535 126.821C52.5713 127.272 52.4617 127.709 52.3383 128.119C52.2287 128.433 52.119 128.748 51.9957 129.048C51.4064 130.456 50.5293 131.672 49.4878 132.765C49.2411 133.025 48.9807 133.271 48.7204 133.517C48.4463 133.776 48.1585 134.036 47.8707 134.282C47.857 134.296 47.8296 134.309 47.8022 134.337C45.445 136.291 42.7864 137.698 39.977 138.846C37.3458 137.357 34.879 135.621 32.7822 133.394C30.6717 131.139 29.0957 128.584 28.9176 125.345C28.7805 122.83 29.6713 120.808 31.7544 119.455C32.9878 118.662 34.2486 118.321 35.5368 118.43C35.619 118.43 35.7012 118.457 35.7835 118.457C36.1398 118.512 36.4961 118.58 36.8524 118.69C37.0991 118.772 37.3321 118.854 37.5787 118.963C37.8117 119.072 38.031 119.182 38.264 119.305C39.6207 120.07 40.4704 121.245 41.0185 122.68C41.7449 121.327 42.7179 120.261 44.1705 119.687C46.4866 118.758 48.6793 119.045 50.5979 120.658C52.4891 122.256 53.1195 124.388 52.6535 126.862V126.821Z"
      fill="url(#paint15_linear_5731_34231)"
      stroke="#772F67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.22609"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_5731_34231"
        x1="21.3407"
        x2="100.1"
        y1="83.3903"
        y2="83.3903"
      >
        <stop offset="0.03" stopColor="#F6D45D" />
        <stop offset="0.36" stopColor="#F1AB59" />
        <stop offset="1" stopColor="#EB7658" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_5731_34231"
        x1="41.0887"
        x2="41.0887"
        y1="153.482"
        y2="82.9812"
      >
        <stop offset="0.03" stopColor="#F6D45D" />
        <stop offset="0.36" stopColor="#F1AB59" />
        <stop offset="1" stopColor="#EB7658" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint2_linear_5731_34231"
        x1="80.4468"
        x2="80.4468"
        y1="153.482"
        y2="82.9812"
      >
        <stop offset="0.03" stopColor="#F6D45D" />
        <stop offset="0.36" stopColor="#F1AB59" />
        <stop offset="1" stopColor="#EB7658" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint3_linear_5731_34231"
        x1="33.6055"
        x2="33.6055"
        y1="113.756"
        y2="82.9812"
      >
        <stop offset="0.03" stopColor="#F6D45D" />
        <stop offset="0.36" stopColor="#F1AB59" />
        <stop offset="1" stopColor="#EB7658" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint4_linear_5731_34231"
        x1="28.8867"
        x2="36.3673"
        y1="24.1977"
        y2="7.64084"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint5_linear_5731_34231"
        x1="11.1801"
        x2="17.7362"
        y1="37.8271"
        y2="23.353"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint6_linear_5731_34231"
        x1="17.5679"
        x2="23.1555"
        y1="23.4125"
        y2="11.0439"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint7_linear_5731_34231"
        x1="13.2687"
        x2="54.0373"
        y1="51.0173"
        y2="16.1111"
      >
        <stop stopColor="#A1D2E2" />
        <stop offset="0.65" stopColor="#9FD1DE" />
        <stop offset="1" stopColor="#71B774" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint8_linear_5731_34231"
        x1="25.4525"
        x2="41.9227"
        y1="23.9863"
        y2="43.3321"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint9_linear_5731_34231"
        x1="41.1283"
        x2="104.847"
        y1="60.9811"
        y2="72.5392"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint10_linear_5731_34231"
        x1="55.6538"
        x2="89.9604"
        y1="64.8247"
        y2="71.0264"
      >
        <stop stopColor="#663562" />
        <stop offset="1" stopColor="#ABD8F9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint11_linear_5731_34231"
        x1="86.6482"
        x2="104.758"
        y1="50.5888"
        y2="52.5412"
      >
        <stop stopColor="#663562" />
        <stop offset="1" stopColor="#ABD8F9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint12_linear_5731_34231"
        x1="68.1059"
        x2="83.9509"
        y1="38.1135"
        y2="39.8081"
      >
        <stop stopColor="#663562" />
        <stop offset="1" stopColor="#ABD8F9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint13_linear_5731_34231"
        x1="83.8456"
        x2="97.3749"
        y1="39.6259"
        y2="41.0872"
      >
        <stop stopColor="#663562" />
        <stop offset="1" stopColor="#ABD8F9" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint14_linear_5731_34231"
        x1="87.1922"
        x2="87.5109"
        y1="113.852"
        y2="82.9557"
      >
        <stop offset="0.03" stopColor="#F6D45D" />
        <stop offset="0.36" stopColor="#F1AB59" />
        <stop offset="1" stopColor="#EB7658" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint15_linear_5731_34231"
        x1="28.7026"
        x2="52.555"
        y1="128.02"
        y2="129.529"
      >
        <stop offset="0.08" stopColor="#EB7658" />
        <stop offset="0.77" stopColor="#F6D6FA" />
      </linearGradient>
    </defs>
  </svg>
)

export default SimpleShippingGiftImage
