import { loginPathAsPopup } from 'lib/urls'
import onCurrentUserExternalLogin from 'shared/utils/onCurrentUserExternalLogin/onCurrentUserExternalLogin'
import { CurrentUser } from '../../../../global'

interface CallOrRedirectArguments {
  currentUser: CurrentUser | undefined
  callback: (...args: any[]) => any
}

export const callOrRedirectToLogin =
  ({ currentUser, callback }: CallOrRedirectArguments) =>
  () => {
    if (currentUser) {
      callback()
    } else {
      window.open(loginPathAsPopup, '_blank')

      onCurrentUserExternalLogin(callback)
    }
  }
