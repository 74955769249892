import { useDispatch } from 'react-redux'
import { openContactInfoModal } from 'registry/modals/actions'
import { Registry } from 'src/types/registry'

export const useGetOpenContactInfoModal = (
  registry: Registry,
  setIsShippingAddressButtonVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >
) => {
  const dispatch = useDispatch()

  return () => {
    dispatch(
      openContactInfoModal({
        continueToStoreButton: () => null,
        registry,
      })
    )
    setIsShippingAddressButtonVisible(false)
  }
}
