import {
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_EXTERNAL_REGISTRY,
  REG_ITEM_TYPE_GIFT_CARD,
  REG_ITEM_TYPE_GROUP_GIFT,
} from 'src/constants'
import { RegItemMinimal } from 'src/types/regItem'
import { ReservationWithVisitor } from '../../components/RegistryCategories/RegistryCategories.types'
import getRegItemType from '../getRegItemType/getRegItemType'

export const NO_QUANTITY_REG_ITEM_TYPES = [
  REG_ITEM_TYPE_CROWDFUND,
  REG_ITEM_TYPE_EXTERNAL_REGISTRY,
  REG_ITEM_TYPE_GROUP_GIFT,
  REG_ITEM_TYPE_GIFT_CARD,
]

const isQuantityUnlimited = (regItem: RegItemMinimal): boolean =>
  regItem.quantity === null || regItem.quantity < 0

export const shouldDisplayQuantity = (
  regItem?: RegItemMinimal,
  reservation?: ReservationWithVisitor
): boolean => {
  if (!regItem) return false

  const regItemType = getRegItemType(regItem)
  if (NO_QUANTITY_REG_ITEM_TYPES.includes(regItemType || '')) {
    return false
  }

  if (reservation && reservation.quantity > 1) {
    return true
  }

  const isUnlimited = isQuantityUnlimited(regItem)

  return regItem.quantityNeeded > 1 || isUnlimited
}

interface GetQuantityTextProps {
  regItem?: RegItemMinimal
  reservation?: ReservationWithVisitor
  isShortForm?: boolean
}

export const getQuantityText = ({
  regItem,
  reservation,
  isShortForm = true,
}: GetQuantityTextProps): { prefix: string; value: string } => {
  if (!regItem) return { prefix: '', value: '' }

  if (reservation) {
    return {
      prefix: 'Qty',
      value: `${reservation.quantity}`,
    }
  }

  const isUnlimited = isQuantityUnlimited(regItem)
  const value = isUnlimited ? 'Unlimited' : regItem.quantityNeeded
  return {
    prefix: isShortForm ? 'Requested' : 'Quantity requested',
    value: `${value}`,
  }
}
