import { StrictMode, FC, ReactElement } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { FlagsProvider } from 'flagged'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
  BrowserRouter,
  Routes,
  Route,
  StaticRouter,
} from 'react-router-dom-v5-compat'
import { Hydrate, QueryClientProvider, dehydrate } from '@tanstack/react-query'
import { RECAPTCHA_V3_SITE_KEY } from 'shared/constants'
import {
  generateRoutes,
  SubdomainContexts,
  SubdomainContext,
  groupRoutesByLayout,
  getSubdomainKey,
  DEFAULT_SUBDOMAIN,
} from './utils/routeHelpers/routeHelpers'
import { queryClient } from './queryClient'
import { ScrollToTop } from './ScrollToTop'
import { UIProvider } from './contexts/UIContext'

const isServer = typeof window === 'undefined'
const helmetContext = {}

interface AppProps {
  appWebView?: boolean
  featureFlags?: Record<string, boolean>
  hydrationData?: Array<{ key: Array<string | number>; data: any }>
  isMobileSSR?: boolean
  location?: string
  subdomain?: string
}

// Webpack needs to be aware of known subdomains to load the correct routes.
// Controllers should pass the subdomain to the App component.
const subdomainContexts: SubdomainContexts = {
  www: {
    routes: require.context(
      './routes',
      true,
      /^\.(?!\/\(subdomains\)).+\/Route\.tsx$/
    ),
    layouts: require.context(
      './routes',
      true,
      /^\.(?!\/\(subdomains\)).+\/Layout\.tsx$/
    ),
  },
  my: {
    routes: require.context(
      './routes/(subdomains)/(my)',
      true,
      /^\..+\/Route\.tsx$/
    ),
    layouts: require.context(
      './routes/(subdomains)/(my)',
      true,
      /^\..+\/Layout\.tsx$/
    ),
  },
  // Add more subdomains here as needed
}

const App: FC<AppProps> = ({
  appWebView = false,
  featureFlags = {},
  hydrationData,
  isMobileSSR = false,
  location,
  subdomain = DEFAULT_SUBDOMAIN,
}) => {
  hydrationData?.forEach(({ key, data }) => {
    queryClient.setQueryData(key, data)
  })

  const subdomainKey = getSubdomainKey(subdomain, subdomainContexts)
  const subdomainContext: SubdomainContext = subdomainContexts[subdomainKey]

  const routesConfig = generateRoutes(subdomainContext.routes)
  const layoutsConfig = generateRoutes(subdomainContext.layouts)
  const { groupedRoutes, ungroupedRoutes } = groupRoutesByLayout(
    routesConfig,
    layoutsConfig
  )

  const renderRoutes = (): ReactElement => (
    <Routes>
      {groupedRoutes.map(({ layout, routes }) => (
        <Route element={layout.element} key={layout.path}>
          {routes.map((route) => (
            <Route element={route.element} key={route.path} path={route.path} />
          ))}
        </Route>
      ))}
      {ungroupedRoutes.map((route) => (
        <Route element={route.element} key={route.path} path={route.path} />
      ))}
      <Route element={<></>} path="*" />
    </Routes>
  )

  return (
    <StrictMode>
      <FlagsProvider features={featureFlags}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydrate(queryClient)}>
            <HelmetProvider context={helmetContext}>
              <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
                <UIProvider appWebView={appWebView} isMobileSSR={isMobileSSR}>
                  {isServer ? (
                    <StaticRouter location={location || '/'}>
                      {renderRoutes()}
                    </StaticRouter>
                  ) : (
                    <BrowserRouter>
                      <ScrollToTop />
                      {renderRoutes()}
                    </BrowserRouter>
                  )}
                </UIProvider>
              </GoogleReCaptchaProvider>
            </HelmetProvider>
          </Hydrate>
        </QueryClientProvider>
      </FlagsProvider>
    </StrictMode>
  )
}

export default App
