import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import PriceTag from 'baby-design/components/PriceTag/PriceTag'
import {
  ELECTRONIC_GIFT_CARD_MAX,
  ELECTRONIC_GIFT_CARD_MIN,
} from 'shared/constants'

export const GiftCardPrice = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)

  if (!genericProduct?.activeProduct) return null

  const { isElectronicGiftCard } = genericProduct.activeProduct

  return (
    <PriceTag
      truncateZeroCents
      currentPrice={genericProduct.activeProduct.price?.current}
      maxPrice={isElectronicGiftCard ? ELECTRONIC_GIFT_CARD_MAX : undefined}
      minPrice={isElectronicGiftCard ? ELECTRONIC_GIFT_CARD_MIN : undefined}
      msrp={genericProduct.activeProduct.price?.msrp}
      size="md"
    />
  )
}
