import React from 'react'
import classNames from 'classnames'
import { helpAndFavorsPath, cashFundsPath, giftCardsPath } from 'lib/urls'
import { track } from 'lib/analytics'

import css from './CashGifts.styles.scss'

import { CashGiftsProps } from './CashGifts.types'

const CashGifts: React.FC<CashGiftsProps> = ({ className }) => {
  const CashGiftsClasses = classNames(css.CashGifts, className)

  return (
    <div className={CashGiftsClasses}>
      <h2>Register for cash gifts and favors</h2>
      <div className={css.CashGifts__modules}>
        <div
          className={css.CashGifts__module}
          onClick={() => {
            track.addItemsScreenClicked({
              eventLocation: track.EventLocation.ADD_ITEMS,
              eventCta: 'Cash Funds',
            })
            window.open(cashFundsPath, '_blank')
          }}
        >
          <div className={css.CashGifts__image}>
            <img
              alt="Cash funds"
              src="https://images.babylist.com/image/upload/f_auto,q_auto/v1712847663/AddChromeExtension/piggy-bank-3x.png"
            />
          </div>
          <h3>Cash funds</h3>
          <p>
            Give friends and family the option to contribute to big expenses
            like a doula, diapers or a college fund.
          </p>
        </div>
        <div
          className={css.CashGifts__module}
          onClick={() => {
            track.addItemsScreenClicked({
              eventLocation: track.EventLocation.ADD_ITEMS,
              eventCta: 'Gift Cards',
            })
            window.open(giftCardsPath, '_blank')
          }}
        >
          <div className={css.CashGifts__image}>
            <img
              alt="Gift cards"
              src="https://images.babylist.com/image/upload/f_auto,q_auto/v1712847741/AddChromeExtension/gift-card-3x.png"
            />
          </div>
          <h3>Gift cards</h3>
          <p>
            Ask for a gift card and enjoy the freedom to redeem when you're
            ready.
          </p>
        </div>
        <div
          className={css.CashGifts__module}
          onClick={() => {
            track.addItemsScreenClicked({
              eventLocation: track.EventLocation.ADD_ITEMS,
              eventCta: 'Help and Favors',
            })
            window.open(helpAndFavorsPath, '_blank')
          }}
        >
          <div className={css.CashGifts__image}>
            <img
              alt="Helps & favors"
              src="https://images.babylist.com/image/upload/f_auto,q_auto/v1712847778/AddChromeExtension/favor-3x.png"
            />
          </div>
          <h3>Helps &amp; favors</h3>
          <p>
            Ask for support for day-to-day tasks like home-cooked meals, dog
            walking, cleaning and more.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CashGifts
