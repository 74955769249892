import React, { useEffect, useRef } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RadioButton } from 'baby-design'
import { useAppliedSortsAndFiltersContext } from '../../../../contexts'
import { SortOrderProps } from './SortOrder.types'
import css from './SortOrder.styles.scss'

const sortOptionsIdPrefix = 'RegistrySortOrder'

const SortOrder: React.FC<SortOrderProps> = ({
  sortFilterOptions,
  closePopover,
}: SortOrderProps) => {
  const sortOrderRef = useRef<HTMLDivElement>(null)
  const { appliedSortsAndFilters, onSortFilterChange } =
    useAppliedSortsAndFiltersContext()

  useEffect(() => {
    sortOrderRef.current?.focus()
  }, [sortOrderRef])

  return (
    <div
      aria-label="Sort by"
      className={css.SortOrder}
      data-testid="SortOrder"
      ref={sortOrderRef}
      tabIndex={-1}
    >
      {Object.values(sortFilterOptions.sorts).map((sortOption: any) => {
        const radioId = `${sortOptionsIdPrefix}-${sortOption.key}`

        return (
          <label
            className={css.SortOrder__Label}
            htmlFor={radioId}
            key={sortOption.key}
          >
            <span>{sortOption.title}</span>
            <RadioButton
              inputProps={{
                checked: appliedSortsAndFilters.sort === sortOption.key,
                name: sortOption.key,
                value: sortOption.key,
                id: radioId,
                onChange: (e) => {
                  onSortFilterChange({
                    sort: e.target.value,
                    filters: appliedSortsAndFilters.filters,
                  })
                  closePopover()
                },
              }}
              size="lg"
            />
          </label>
        )
      })}
    </div>
  )
}

export default SortOrder
