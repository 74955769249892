import React from 'react'
import RightChevron from 'baby-design/icons/RightChevron.svg'
import { GiftTrackerProgress, SimpleCard } from 'baby-design'
import ShimmerCard from '../ShimmerCard'
import css from './GiftTrackerCard.styles.scss'
import { GiftTrackerCardProps } from './GiftTrackerCard.types'

const GiftTrackerCard: React.FC<GiftTrackerCardProps> = ({
  currentRegistry,
  onClick,
  variant,
}) => {
  if (!currentRegistry) return <ShimmerCard variant={variant} />

  const { regItemsCount, reservationCount } = currentRegistry
  let percentGiftTrackerComplete = 0

  if (regItemsCount && reservationCount) {
    percentGiftTrackerComplete = Math.round(
      (reservationCount / regItemsCount) * 100
    )
  }

  const rightIcon =
    variant === 'desktop' ? (
      <RightChevron
        className={css.GiftTrackerCard__rightIcon}
        data-testid="right-chevron-icon"
      />
    ) : null

  return (
    <div>
      <SimpleCard
        content={reservationCount}
        leftIcon={<GiftTrackerProgress value={percentGiftTrackerComplete} />}
        rightIcon={rightIcon}
        role="link"
        title="Gift tracker"
        onClick={onClick}
      />
    </div>
  )
}

export default GiftTrackerCard
