import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Tag } from 'baby-design'
import { Close, Lock, MostWantedFilled } from 'baby-design/icons'
import css from './RegItemFilterChips.styles.scss'

import { RegItemFilterChipsProps } from './RegItemFilterChips.types'
import { PRICE_FILTER_MAX, PRICE_FILTER_MIN } from '../../utils/routeHelpers'

const RegItemFilterChips: React.FC<RegItemFilterChipsProps> = ({
  className,
  regItemFilters,
  setRegItemFilters,
}) => {
  const regItemFilterChipsClasses = classNames(
    css.RegItemFilterChips,
    className
  )

  const [updatingStores, setUpdatingStores] = useState(false)
  const storeKeys = Object.keys(regItemFilters.stores)

  const allStoresSelected = () =>
    storeKeys.every((key) => regItemFilters.stores[key].show)
  const noStoresSelected = () =>
    storeKeys.every((key) => !regItemFilters.stores[key].show)

  useEffect(() => {
    if (updatingStores) {
      setUpdatingStores(false)

      // if all stores are set to show == false, set all stores to show == true
      if (noStoresSelected()) {
        setRegItemFilters({
          ...regItemFilters,
          stores: storeKeys.reduce((acc: any, key) => {
            acc[key] = {
              ...regItemFilters.stores[key],
              show: true,
            }
            return acc
          }, {}),
        })
      }
    }
  }, [
    storeKeys,
    updatingStores,
    setUpdatingStores,
    regItemFilters,
    setRegItemFilters,
    noStoresSelected,
  ])

  const { price } = regItemFilters

  const showPrice = price.min > PRICE_FILTER_MIN || price.max < PRICE_FILTER_MAX
  const priceText = `$${price.min} - $${price.max}`

  const removeShowFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      show: 'all',
    })
  }

  const removePriceFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      price: {
        min: PRICE_FILTER_MIN,
        max: PRICE_FILTER_MAX,
      },
    })
  }

  const removeMustHaveFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      itemSettings: {
        ...regItemFilters.itemSettings,
        mostWanteds: false,
      },
    })
  }

  const removePrivateFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      itemSettings: {
        ...regItemFilters.itemSettings,
        privates: false,
      },
    })
  }

  const removeGroupGiftFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      itemSettings: {
        ...regItemFilters.itemSettings,
        groupGifts: false,
      },
    })
  }

  const removeRegDiscountEligibleFilter = () => {
    setRegItemFilters({
      ...regItemFilters,
      itemSettings: {
        ...regItemFilters.itemSettings,
        eligibleForRegistryDiscount: false,
      },
    })
  }

  const removeStoreFilter = (storeKey: string) => {
    setUpdatingStores(true)

    setRegItemFilters({
      ...regItemFilters,
      stores: {
        ...regItemFilters.stores,
        [storeKey]: {
          ...regItemFilters.stores[storeKey],
          show: false,
        },
      },
    })
  }

  return (
    <div className={regItemFilterChipsClasses}>
      {regItemFilters.show === 'purchased' && (
        <Tag
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removeShowFilter}
        >
          Purchased
        </Tag>
      )}
      {regItemFilters.show === 'notPurchased' && (
        <Tag
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removeShowFilter}
        >
          Not purchased
        </Tag>
      )}
      {showPrice && (
        <Tag
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removePriceFilter}
        >
          {priceText}
        </Tag>
      )}
      {regItemFilters.itemSettings.mostWanteds && (
        <Tag
          LeftIcon={
            <MostWantedFilled
              className={css.RegItemFilterChips__mostWantedFilledIcon}
            />
          }
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removeMustHaveFilter}
        >
          Most wanted
        </Tag>
      )}
      {regItemFilters.itemSettings.privates && (
        <Tag
          LeftIcon={<Lock />}
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removePrivateFilter}
        >
          Private
        </Tag>
      )}
      {regItemFilters.itemSettings.groupGifts && (
        <Tag
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removeGroupGiftFilter}
        >
          Group gift
        </Tag>
      )}
      {regItemFilters.itemSettings.eligibleForRegistryDiscount && (
        <Tag
          RightIcon={<Close />}
          className={css.Tag}
          size="lg"
          variant="normal"
          onClick={removeRegDiscountEligibleFilter}
        >
          Registry discount eligible
        </Tag>
      )}
      {!allStoresSelected() &&
        storeKeys.map((key) => {
          const store = regItemFilters.stores[key]
          if (store.show) {
            return (
              <Tag
                RightIcon={<Close />}
                className={css.Tag}
                key={key}
                size="lg"
                variant="normal"
                onClick={() => removeStoreFilter(key)}
              >
                {key}
              </Tag>
            )
          }
        })}
    </div>
  )
}

export default RegItemFilterChips
