import BabylistRegItemOffer from './components/BabylistRegItemOffer/BabylistRegItemOffer'
import ExternalRegItemOffer from './components/ExternalRegItemOffer/ExternalRegItemOffer'

import { RegItemOfferProps } from './RegItemOffer.types'

const RegItemOffer: React.FC<RegItemOfferProps> = ({
  offer,
  regItem,
  registry,
  currentUser,
}) => {
  let RegItemOfferComponentVariant = ExternalRegItemOffer
  if (offer.isBabylist) {
    RegItemOfferComponentVariant = BabylistRegItemOffer
  }

  return (
    <RegItemOfferComponentVariant
      currentUser={currentUser}
      offer={offer}
      regItem={regItem}
      registry={registry}
    />
  )
}

export default RegItemOffer
