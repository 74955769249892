import { FC } from 'react'

import { Button, IconButton } from 'baby-design'
import { Filter, Plus } from 'baby-design/icons'
import { useNavigate } from 'react-router-dom-v5-compat'
import { registryAddItemsPath } from 'lib/urls'
import { REGISTRY_ADD_ITEM_SCREEN_VIEW_EVENT } from 'registry/constants'
import css from './RegistryItemsListHeader.styles.scss'

interface RegistryItemsListHeaderProps {
  appliedFilterCount: number
  regItemsCount: number
  openRegItemFilterDrawer: () => void
  regItemsHaveLoaded: boolean
}

const RegistryItemsListHeader: FC<RegistryItemsListHeaderProps> = ({
  appliedFilterCount,
  regItemsCount,
  openRegItemFilterDrawer,
  regItemsHaveLoaded,
}) => {
  const navigate = useNavigate()
  const handleClickAddItem = () => {
    navigate(
      `${registryAddItemsPath}?entry_point=${REGISTRY_ADD_ITEM_SCREEN_VIEW_EVENT}`
    )
  }

  return (
    <div className={css.RegistryItemsList_header}>
      {regItemsHaveLoaded ? (
        <div>
          {regItemsCount} Gift{regItemsCount === 1 ? '' : 's'}
        </div>
      ) : (
        <div className={css.loadingShimmer} />
      )}
      <div className={css.RegistryItemsList__buttonGroup}>
        <div className={css.RegistryItemsList__filterButtonContainer}>
          <IconButton
            size="md"
            variant="fill-tertiary"
            onClick={openRegItemFilterDrawer}
          >
            <Filter />
          </IconButton>
          {appliedFilterCount > 0 && (
            <div className={css.RegistryItemsList__filterCount}>
              <span>{appliedFilterCount}</span>
            </div>
          )}
        </div>
        <Button
          className={css.RegistryItemsList__addItemsButton}
          icon={{ side: 'left', icon: <Plus /> }}
          size="md"
          variant="primary"
          onClick={handleClickAddItem}
        >
          Add item
        </Button>
      </div>
    </div>
  )
}

export default RegistryItemsListHeader
