import PropTypes from 'prop-types'
import { Form, FormButtonGroup, Radio, RadioGroup } from 'components/forms'

import GiftAmountSelect from 'cart/components/gift-amount-select'

import {
  DEFAULT_GIFT_CARD_AMOUNT,
  GIFT_CARD_LABEL_DIGITAL,
  GIFT_CARD_LABEL_PHYSICAL,
  PRODUCT_TYPE_DIGITAL,
} from 'cart/constants'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { getGiftCardProductId, getGiftCardsByType } from 'cart/lib'
import { parseErrors } from 'lib/api-utils'

const GiftCardForm = ({
  giftCardOptions,
  isLoading,
  onSubmit,
  onCancel,
  regItemId,
  submitText,
}) => {
  const defaultValues = {
    giftCard: {
      amount: DEFAULT_GIFT_CARD_AMOUNT,
      giftCardType: PRODUCT_TYPE_DIGITAL,
    },
  }

  const { handleSubmit, register, watch } = useForm({
    defaultValues,
  })

  const {
    mutate,
    error,
    isLoading: localIsLoading,
    isError,
  } = useMutation({
    mutationFn: ({ data }) => onSubmit(data),
  })

  const giftCardsByType = getGiftCardsByType(giftCardOptions)

  const handleAddToCart = (values) => {
    const productId = getGiftCardProductId(
      values.amount,
      giftCardsByType[values.giftCardType]
    )

    const data = {
      amount: values.amount,
      quantity: 1,
      productId,
      regItemId,
    }

    mutate({ data })
  }

  const watchGiftCardType = watch(
    'giftCardType',
    defaultValues.giftCard.giftCardType
  )

  const giftCardTypes = giftCardsByType && Object.keys(giftCardsByType)

  return (
    <Form
      error={isError ? parseErrors(error)._error : null}
      onSubmit={handleSubmit(handleAddToCart)}
    >
      <GiftAmountSelect
        name="amount"
        amount={defaultValues.giftCard.amount}
        defaultGiftAmount={DEFAULT_GIFT_CARD_AMOUNT}
        defaultValue={defaultValues.giftCard.amount}
        options={giftCardsByType[watchGiftCardType].map((gc) => gc.price)}
        inputRef={register({
          required: true,
        })}
      />
      <RadioGroup
        title="Card Type"
        className={giftCardTypes.length === 1 ? 'hidden' : null}
      >
        {giftCardTypes.map((cardType) => (
          <Radio
            defaultChecked={cardType === PRODUCT_TYPE_DIGITAL}
            name="giftCardType"
            inputRef={register({
              required: true,
            })}
            label={
              cardType === PRODUCT_TYPE_DIGITAL
                ? GIFT_CARD_LABEL_DIGITAL
                : GIFT_CARD_LABEL_PHYSICAL
            }
            tappable
            value={cardType}
          />
        ))}
      </RadioGroup>
      <FormButtonGroup
        onClickSecondary={onCancel}
        submitting={
          typeof isLoading !== 'undefined' ? isLoading : localIsLoading
        }
        textPrimary={submitText || 'Add to Cart'}
      />
    </Form>
  )
}

GiftCardForm.propTypes = {
  giftCardOptions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  regItemId: PropTypes.number,
  submitText: PropTypes.string,
}

GiftCardForm.defaultProps = {
  isLoading: undefined,
  regItemId: null,
  submitText: null,
}

export default GiftCardForm
