import { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  CompatRouter,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom-v5-compat'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Router from 'shared/universal-router'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
import { registryPath, registrySubdomainPath, setHost } from 'lib/urls'
import * as consts from 'registry/constants'
import withReactQueryClientProvider from 'shared/HOC/withReactQueryClientProvider'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import onCurrentUserLoaded from 'shared/utils/onCurrentUserLoaded/onCurrentUserLoaded'
import { track, withContextualizedTracking } from 'lib/analytics'
import { RECAPTCHA_V3_SITE_KEY } from 'shared/constants'
import {
  getSearchId,
  REGISTRY_SEARCH_INDEX_KEY,
} from 'shared/hooks/useSearchId/useSearchId'
import registryReducer from '../reducers'
import { setUser, setRegistry, setIsMobile, setActiveModal } from '../actions'
import RegistryPageGuest from './registry-page-guest'
import {
  CrowdfundReservationModal,
  FundThisGiftModal,
  RecoverReservationModal,
  RegistryViewPurchaseInfoModal,
  RedirectModal,
  ContactInfoModal,
  AddToCartModal,
  ReservePurchasedGiftModal,
  GiftMessageModal,
  GiftTrackerModal,
  UnreserveModal,
  ThankYouAddressModal,
} from '../modals'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import useInProgressReservation from 'registry/hooks/useInProgressReservation'
import InProgressReservationContext from 'registry/contexts/inProgressReservation'
import NiceModal from '@ebay/nice-modal-react'

const middlewares = [thunk]

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger())
}

export const initReduxDevTools = () => {
  if (
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
  }
}

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(
  createStore
)

const DecoratedRegistryComponent = () => {
  const location = useLocation()
  const { inProgressReservation } = useInProgressReservation()
  const [reservation, setReservation] = useState(inProgressReservation)

  useEffect(() => {
    setReservation(inProgressReservation)
  }, [inProgressReservation])

  return (
    <InProgressReservationContext.Provider
      value={{ reservation, setInProgressReservation: setReservation }}
    >
      <AddToCartModal />
      <CrowdfundReservationModal />
      <FundThisGiftModal />
      <RegistryViewPurchaseInfoModal />
      <RedirectModal />
      <ReservePurchasedGiftModal />
      <RecoverReservationModal />
      <ContactInfoModal />
      <GiftMessageModal />
      <ThankYouAddressModal />
      <GiftTrackerModal />
      <UnreserveModal />
      <RegistryPageGuest location={location} />
    </InProgressReservationContext.Provider>
  )
}

class RegistryGuestApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentUserLoaded: false,
      trackedRegistryViewed: false,
    }

    this.store = createStoreWithMiddleware(
      combineReducers(registryReducer),
      initReduxDevTools()
    )

    this.store.dispatch(setUser(props.currentUser))
    onCurrentUserLoaded((currentUser) => {
      if (!props.currentUser && currentUser) {
        this.store.dispatch(setUser(currentUser))
      }
      this.setState({ currentUserLoaded: true })
    })

    this.store.dispatch(setRegistry(props.context.registry))
    this.store.dispatch(setIsMobile(props.context.isMobile))
    setHost(props._canonicalHost) // Sets the host for SSR absolute URLs
  }

  getChildContext() {
    const { context } = this.props
    return context
  }

  componentDidMount() {
    const { showInternationalUserModal } = this.props

    this.trackRegistryViewed()

    if (showInternationalUserModal) {
      setTimeout(() => this.showModal(consts.INTERNATIONAL_USER), 4000)
    }
  }

  componentDidUpdate() {
    this.trackRegistryViewed()
  }

  // Track RegistryViewed for non-owner, non-partner or non-authed user.
  trackRegistryViewed() {
    if (this.state.trackedRegistryViewed) {
      return
    }

    if (!this.state.currentUserLoaded) {
      return
    }
    const {
      context: {
        registry: { id: registryId, ownerId, type: listType },
      },
      currentUser,
    } = this.props

    // If current registry is in currentUser.registries they are an owner or partner on it.
    const registries = currentUser ? currentUser?.registries : []
    const registryCount = registries.filter(
      (reg) => reg.id === registryId
    ).length

    if (registryCount !== 0) {
      return
    }

    const searchId = getSearchId(REGISTRY_SEARCH_INDEX_KEY)
    const searchPreviewIds = getSearchPreviewIds()
    const currentUrl =
      typeof window !== 'undefined' ? window.location.href : null
    track.registryViewed({
      registryId,
      registryOwnerId: ownerId,
      isRegistryOwner: false,
      eventLocation: track.EventLocation.REGISTRY_MINUS_GIFT_GIVER_VIEW,
      listType: listType ? listType : track.ListType.BABY_REGISTRY,
      eventUrl: currentUrl,
      searchId,
      searchPreviewIds,
    })

    this.setState({ trackedRegistryViewed: true })
  }

  showModal = (modalName) => this.store.dispatch(setActiveModal(modalName))

  render() {
    const {
      context: {
        registry: { urlSlug },
      },
      path,
      isRegistrySubdomain,
    } = this.props

    return (
      <Provider store={this.store}>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_V3_SITE_KEY}>
          <NiceModal.Provider>
            <Router
              location={{ pathname: path }}
              initialEntries={[{ pathname: path }]}
            >
              <CompatRouter>
                <Routes>
                  <Route path={registryPath(urlSlug)}>
                    <Route index element={<DecoratedRegistryComponent />} />
                    <Route
                      path={'app-guest-view'}
                      element={<DecoratedRegistryComponent />}
                    />
                  </Route>
                  {isRegistrySubdomain && (
                    <Route path={registrySubdomainPath(urlSlug)}>
                      <Route index element={<DecoratedRegistryComponent />} />
                      <Route
                        path={'app-guest-view'}
                        element={<DecoratedRegistryComponent />}
                      />
                    </Route>
                  )}
                </Routes>
              </CompatRouter>
            </Router>
          </NiceModal.Provider>
        </GoogleReCaptchaProvider>
      </Provider>
    )
  }
}

RegistryGuestApp.propTypes = {
  context: PropTypes.shape({
    bookmarkletJS: PropTypes.string.isRequired,
    enableInaccuracyReportForm: PropTypes.bool.isRequired,
    isFacebookApp: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    registry: PropTypes.object.isRequired,
    showExtensionInstall: PropTypes.bool,
  }).isRequired,
  currentUser: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  isRegistrySubdomain: PropTypes.bool,
  showInternationalUserModal: PropTypes.bool,
}

RegistryGuestApp.defaultProps = {
  showInternationalUserModal: false,
}

RegistryGuestApp.childContextTypes = {
  bookmarkletJS: PropTypes.string,
  showExtensionInstall: PropTypes.bool,
  isFacebookApp: PropTypes.bool,
  isMobile: PropTypes.bool,
  enableInaccuracyReportForm: PropTypes.bool,
  registry: PropTypes.object.isRequired,
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.REGISTRY,
})(withCurrentUser(withReactQueryClientProvider(RegistryGuestApp)))
