import { Registry } from 'src/types/registry'

export const getHasPublicAddress = (registry: Registry) =>
  !registry.isShippingAddressPrivate && !!registry.addressId

export const getShouldLoadAddress = (
  registry: Registry,
  showAddress: boolean
) => getHasPublicAddress(registry) && showAddress

export const getName = (registry: Registry) =>
  registry?.attributeData?.firstName || registry.firstNames

export const getShouldShowPrivateAddressDisclaimer = (
  hasPublicAddress: boolean,
  showAddress: boolean,
  isAddressFetching: boolean,
  addressData: unknown
) => !hasPublicAddress || (showAddress && !isAddressFetching && !addressData)

export const getShouldShowAddress = (
  hasPublicAddress: boolean,
  showAddress: boolean,
  isAddressFetching: boolean,
  addressData: unknown
) => hasPublicAddress && showAddress && !isAddressFetching && addressData

export const getShouldShowAskForAddress = (
  hasPublicAddress: boolean,
  showAddress: boolean,
  isAddressFetching: boolean
) => hasPublicAddress && (!showAddress || (showAddress && isAddressFetching))
