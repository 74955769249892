import { useQuery } from '@tanstack/react-query'
import { FETCH_GUIDES_CONFIG_KEY, fetchGuidesConfig } from '../api/guidesConfig'
import { FETCH_POSTS_KEY, fetchPosts } from '../api/posts'
import {
  FETCH_FEATURED_POSTS_SLUGS,
  FETCH_HOME_TOPICS_KEY,
  FETCH_TOPIC_NAVIGATION_KEY,
  fetchFeaturedPostsSlugs,
  fetchHomeTopics,
  fetchTopicNavigation,
} from '../api/collections'

interface PostData {
  [slug: string]: Post
}
interface TopicNavigationData {
  items: Topic[]
  name: string
  slug: string
}

interface GuidesConfigData {
  emailUrls: Record<string, string>
  minimalView: boolean
}

export const useGuidesConfig = () => {
  const guidesConfigQuery = useQuery({
    queryKey: [FETCH_GUIDES_CONFIG_KEY],
    queryFn: fetchGuidesConfig,
    placeholderData: { emailUrls: {} },
    staleTime: 3600 * 1000,
  })

  return guidesConfigQuery.data as GuidesConfigData
}

export const useFeaturedPosts = () => {
  const posts = usePosts()

  const featuredPostsQuery = useQuery({
    queryKey: [FETCH_FEATURED_POSTS_SLUGS],
    queryFn: fetchFeaturedPostsSlugs,
    placeholderData: [],
    staleTime: 3600 * 1000,
  })

  if (!posts) return []

  return featuredPostsQuery.data?.itemIds?.map(
    (slug: string) => posts[slug]
  ) as Post[]
}

export const useHomeTopics = () => {
  const homeTopicsQuery = useQuery({
    queryKey: [FETCH_HOME_TOPICS_KEY],
    queryFn: fetchHomeTopics,
    staleTime: 3600 * 1000,
  })

  return homeTopicsQuery.data as TopicNavigationData
}

export const usePost = (slug: string) => {
  const options = { excludeBody: false, slugs: [slug] }

  const postQuery = useQuery({
    queryKey: [FETCH_POSTS_KEY, options],
    queryFn: () => fetchPosts(options),
    staleTime: 3600 * 1000,
  })

  const rawPosts = postQuery.data

  return rawPosts ? rawPosts[slug] : undefined
}

export const usePostByIds = (ids: string[]) => {
  const posts = usePosts()

  const postByIdQuery = useQuery({
    enabled: !!posts,
    queryKey: ['FETCH_POST_BY_IDS', ids],
    queryFn: () =>
      ids.map((id) =>
        Object.values(posts).find((post) => post.id === `${id}#en-US`)
      ),
    placeholderData: [],
  })

  return postByIdQuery.data as Post[]
}

export const usePosts = (options = {}) => {
  const postsQuery = useQuery({
    queryKey: [FETCH_POSTS_KEY, options],
    queryFn: () => fetchPosts(options),
    staleTime: 3600 * 1000,
  })

  return postsQuery.data as PostData
}

export const useTopicNavigation = () => {
  const topicNavigationQuery = useQuery({
    queryKey: [FETCH_TOPIC_NAVIGATION_KEY],
    queryFn: fetchTopicNavigation,
    staleTime: 3600 * 1000,
  })

  return topicNavigationQuery.data as TopicNavigationData
}

export const useTopic = (slug: string | undefined) => {
  const topicNavigation = useTopicNavigation()

  if (!slug) return undefined

  return topicNavigation?.items
    .flatMap((item) => (item.children ? [item, ...item.children] : item))
    .find((topic) => topic.slug === slug)
}
