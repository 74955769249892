import React from 'react'
import RightChevron from 'baby-design/icons/RightChevron.svg'
import { ChecklistProgress, SimpleCard } from 'baby-design'
import { useRegistryChecklistCategories } from 'src/routes/(registry)/registry/checklist/components/api/hooks'
import ShimmerCard from '../ShimmerCard'
import css from './ChecklistCard.styles.scss'
import { ChecklistCardProps } from './ChecklistCard.types'

const ChecklistCard: React.FC<ChecklistCardProps> = ({
  currentRegistry,
  onClick,
  variant,
}) => {
  let percentChecklistComplete = 0
  const { isLoading: checklistLoading, data: checklistData } =
    useRegistryChecklistCategories(!!currentRegistry && currentRegistry.id)
  const { completedCategoriesCount, totalCategoriesCount } = checklistData

  if (!currentRegistry) return <ShimmerCard variant={variant} />

  if (!checklistLoading && checklistData && totalCategoriesCount) {
    percentChecklistComplete = Math.round(
      (completedCategoriesCount / totalCategoriesCount) * 100
    )
  }

  const rightIcon =
    variant === 'desktop' ? (
      <RightChevron
        className={css.ChecklistCard__rightIcon}
        data-testid="right-chevron-icon"
      />
    ) : null

  return (
    <div>
      <SimpleCard
        content={`${percentChecklistComplete}%`}
        leftIcon={<ChecklistProgress value={percentChecklistComplete} />}
        rightIcon={rightIcon}
        role="link"
        title="Checklist"
        onClick={onClick}
      />
    </div>
  )
}

export default ChecklistCard
