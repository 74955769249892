import { useContext } from 'react'
import { Waypoint } from 'react-waypoint'
import { PDPContext } from '../../Context'

export const WaypointWrapper = () => {
  const { setIsPastWaypointLine } = useContext(PDPContext)
  const handleWaypointEvent = (currentPosition: string) => {
    if (currentPosition === 'above') {
      setIsPastWaypointLine(true)
    } else {
      setIsPastWaypointLine(false)
    }
  }
  return (
    <Waypoint
      onEnter={({ currentPosition }) => {
        handleWaypointEvent(currentPosition)
      }}
      onLeave={({ currentPosition }) => {
        handleWaypointEvent(currentPosition)
      }}
    />
  )
}
