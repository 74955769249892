import { useState } from 'react'
import { saveUserEvent } from 'registry/api'
import { ADD_BABYLIST_BUTTON_EVENT } from 'registry/constants'
import BookmarkButton from './BookmarkButton'
import { DraggableBookmarkButtonProps } from './DraggableBookmarkButton.types'
import css from './DraggableBookmarkButton.styles.scss'

const DraggableBookmarkButton: React.FC<DraggableBookmarkButtonProps> = ({
  bookmarkletJS,
  modalClassName,
}) => {
  const [visibleOverlay, setVisibleOverlay] = useState(false)

  // Without this offset, the chevron's position would be relative to the containing modal.
  // We don't want that because we want the chevron to point to the bookmarks bar.
  // This component will be re-rendered each time a drag starts, and a window resize
  // cannot happen during a drag event, so this does not have to be a state.
  const distanceFromTop =
    document.querySelector(`.${modalClassName}`)?.getBoundingClientRect().top ||
    0

  const handleDragStart = () => {
    setVisibleOverlay(true)
  }

  const handleDragEnd = () => {
    setVisibleOverlay(false)
    document.body.addEventListener('mousemove', mouseMoved)
  }

  const mouseMoved = (e: MouseEvent) => {
    if (e.clientY < 18) {
      saveUserEvent(ADD_BABYLIST_BUTTON_EVENT, true)
    }
    document.body.removeEventListener('mousemove', mouseMoved)
  }

  return (
    <div className={css.DraggableBookmarkButton}>
      {visibleOverlay && (
        <div>
          <div className={css.overlayBackground} />
          <div className={css.arrow} style={{ top: -distanceFromTop }}>
            <i aria-hidden="true" className="fa fa-angle-up fa-5x" />
          </div>
        </div>
      )}
      <div
        className={css.buttonAndImageContainer}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        <BookmarkButton bookmarkletJS={bookmarkletJS} />
        <div className={css.draggableArrowImage}>
          <img
            alt="Arrow with text 'Drag Me'."
            loading="lazy"
            src="https://res.cloudinary.com/babylist/image/upload/v1537228011/discovery/cta-drag.svg"
          />
        </div>
        {visibleOverlay && <div className={css.overlayCutout} />}
      </div>
    </div>
  )
}

export default DraggableBookmarkButton
