import PostTeaser from '../PostTeaser'

interface PostTeaserListProps {
  posts: Post[]
}

const PostTeaserList = ({ posts }: PostTeaserListProps) =>
  posts && posts.length ? (
    <ul className="list-unstyled mbxl pbxl">
      {posts.map((post, idx) => (
        <li className="pbxl" key={post.id}>
          <PostTeaser bodyClassName="plm" index={idx} post={post} />
        </li>
      ))}
    </ul>
  ) : (
    <p className="mtl">
      There are currently no articles in this category. Check back soon!
    </p>
  )

export default PostTeaserList
