import { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Image } from 'baby-design'
import { useNavigate } from 'react-router-dom-v5-compat'
import css from './AboutImpact.styles.scss'
import { values } from '../../careers/components/AboutCareers'

const AboutImpact: FC = () => {
  const navigate = useNavigate()

  return (
    <div className={css.AboutImpact}>
      <section className={css.AboutImpact__heroSection}>
        <div className={css.AboutImpact__contentContainer}>
          <div className={css.AboutImpact__heroSectionTextContent}>
            <h1 className={css.AboutImpact__heroSectionHeading}>Impact</h1>
            <div className={css.AboutImpact__heroSectionMobileImageContainer}>
              <Image
                className={css.AboutImpact__heroSectionImageMobile}
                src="https://images.babylist.com/image/upload/q_auto/v1719869097/Corporate%20About%20Pages/Non-Animated/impact_hero_mobile_3x.png"
              />
            </div>
            <div className={css.AboutImpact__heroSectionDescriptionContainer}>
              <p className={css.AboutImpact__heroSectionVision}>
                Babylist&rsquo;s vision is to support every baby and the people
                who love them—this means staying committed to building an
                inclusive company culture.
              </p>
              <p className={css.AboutImpact__heroSectionExplanation}>
                We celebrate diversity, practice ethical sourcing and partner
                with social impact organizations through product donations and
                financial grants to help build a long term legacy of positive
                change so all growing families can be confident, connected,
                happy and healthy.
              </p>
            </div>
          </div>
          <div className={css.AboutImpact__heroSectionDesktopImageContainer}>
            <div className={css.AboutImpact__heroSectionDesktopImageWrapper}>
              <Image
                className={css.AboutImpact__heroSectionImageDesktop}
                src="https://images.babylist.com/image/upload/q_auto/v1718221832/Corporate%20About%20Pages/Non-Animated/impact_hero_desktop.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className={css.AboutImpact__ourCommitmentSection}>
        <div className={css.AboutImpact__ourCommitmentHeadingContent}>
          <h2 className={css.AboutImpact__ourCommitmentHeading}>
            Our commitment
          </h2>
          <p className={css.AboutImpact__ourCommitmentHeadingDescription}>
            Babylist is committed to anti-racism and inclusivity and aims to
            create a supportive and inclusive community for parents of all kinds
            as well as our employees. Being actively anti-racist is a journey,
            not a destination.
          </p>
        </div>
        <div className={css.AboutImpact__ourCommitmentContent}>
          <div className={css.AboutImpact__ourCommitmentDetailContainer}>
            <p className={css.AboutImpact__ourCommitmentDetail}>
              To us, being actively anti-racist means:
            </p>
            <ul className={css.AboutImpact__ourCommitmentDetailList}>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We commit to challenging our own biases and those of people
                around us.
              </li>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We construct our company programs and processes to screen and
                correct for bias and unintended impact on underrepresented
                groups.
              </li>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We use our reach and our resources to support those who are
                doing anti-racist work that benefits our users and communities.
              </li>
            </ul>
          </div>
          <div className={css.AboutImpact__ourCommitmentDetailContainer}>
            <p className={css.AboutImpact__ourCommitmentDetail}>
              We commit to dismantling systemic racism in the ways that it
              creeps into corporate structures:
            </p>
            <ul className={css.AboutImpact__ourCommitmentDetailList}>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We interview manager candidates for diversity, equity and
                inclusion aptitude so everyone has a manager who supports their
                sense of belonging. If the candidate doesn't pass, they won't be
                hired.
              </li>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We articulate our anti-racist stance in our onboarding program
                so it's clear where we stand.
              </li>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We survey the team annually to make sure we understand
                inclusivity and belongingness at Babylist.
              </li>
              <li className={css.AboutImpact__ourCommitmentDetailListItem}>
                We measure the efforts of our DEI programs so we know if they're
                working and can set objective goals to fix them if they're not.
              </li>
            </ul>
          </div>
        </div>
        <Button
          size="md"
          variant="primary"
          onClick={() => navigate('/about/careers')}
        >
          Join the team
        </Button>
      </section>
      <section className={css.AboutImpact__valuesSection}>
        <div className={css.AboutImpact__valuesContainer}>
          <div className={css.AboutImpact__valuesHeadingContent}>
            <h2 className={css.AboutImpact__valuesHeading}>
              Our mission, vision & values
            </h2>
            <p className={css.AboutImpact__valuesHeadingDescription}>
              At Babylist, our mission is to take care of growing families so
              they can be confident, connected, happy and healthy. Our vision is
              to support every baby and the people who love them—from hoping and
              dreaming through kindergarten and beyond.
              <br />
              <br />
              We value diversity, innovation, growth and curiosity. Below are
              some of our values.
            </p>
          </div>
          <div className={css.AboutImpact__valuesContent}>
            {values.map((value) => (
              <div className={css.AboutImpact__value} key={value.title}>
                <h3 className={css.AboutImpact__valueHeading}>{value.title}</h3>
                <p className={css.AboutImpact__valueDescription}>
                  {value.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={css.AboutImpact__ourFocusSection}>
        <div className={css.AboutImpact__ourFocusContainer}>
          <div className={css.AboutImpact__ourFocusHeadingContent}>
            <h2 className={css.AboutImpact__ourFocusHeading}>Our focus</h2>
            <p className={css.AboutImpact__ourFocusHeadingDescription}>
              Babylist is here to help make the journey into parenthood easier
              by providing resources parents and their loved ones need.
            </p>
          </div>
          <div className={css.AboutImpact__ourFocusContent}>
            <div className={css.AboutImpact__focus}>
              <h3 className={css.AboutImpact__focusHeading}>
                Provide unbiased information
              </h3>
              <p className={css.AboutImpact__focusDescription}>
                Our industry-recognized editorial guides provide answers to
                questions parents don't even know they have yet, and our
                articles provide unbiased expert insights on product decisions,
                pregnancy and postpartum, feeding options and more.
              </p>
            </div>
            <div className={css.AboutImpact__focus}>
              <h3 className={css.AboutImpact__focusHeading}>
                Create connections
              </h3>
              <p className={css.AboutImpact__focusDescription}>
                We connect everyone who helps plan, prepare and shop for baby's
                arrival. From grandparents to aunts and uncles, colleagues to
                neighbors, we bring together the community around baby, as well
                as a network of millions of real parents sharing their own
                experiences.
              </p>
            </div>
            <div className={css.AboutImpact__focus}>
              <h3 className={css.AboutImpact__focusHeading}>
                Make it easy to find & get what baby needs
              </h3>
              <p className={css.AboutImpact__focusDescription}>
                We understand that every family is different. Babylist's
                checklist and sample registries combine proprietary data and
                patented technology to recommend editor- and parent-tested
                products. Our "add anything from anywhere" baby registry lets
                parents-to-be add products from any website, as well as
                game-changing gifts that can't be bought in-store, like
                babysitting, home-cooked meals or dog walking.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutImpact
