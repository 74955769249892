import fetch from 'lib/fetch'
import { useQuery } from '@tanstack/react-query'

const CACHE_KEY = 'guides'

interface GuideFeaturedImage {
  description: string
  thumbnailUrl: string
  url: string
}

interface GuideMeta {
  topics: string[]
}

interface Guide {
  id: string
  slug: string
  teaser: string
  title: string
  type: string
  featuredImage: GuideFeaturedImage
  meta: GuideMeta
}

const fetchIndex = async (genericProductId: number): Promise<Guide[]> =>
  await fetch(`/store/api/generic_product/${genericProductId}/guides`)

const useIndex = (genericProductId: number | undefined) => {
  if (genericProductId === undefined) {
    throw new Error('genericProductId must be defined')
  }
  return useQuery({
    queryKey: [CACHE_KEY, genericProductId],
    queryFn: () => fetchIndex(genericProductId),
  })
}

export const GuidesAPI = {
  useIndex,
}
