import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ContactInfoModal from 'registry/components/modals/ContactInfoModal'
import { RegistryGiftGiverHeaderProps } from './RegistryGiftGiverHeader.types'

const CopyContactInfoModal = NiceModal.create(
  ({ registry }: RegistryGiftGiverHeaderProps) => {
    const modal = useModal()
    const onHide = () => {
      modal.hide()
      modal.resolve(false)
      modal.remove()
    }

    return <ContactInfoModal registry={registry} onHide={onHide} />
  }
)

export default CopyContactInfoModal
