import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CopyAndIcon, ExpandableTextBox } from 'baby-design'
// eslint-disable-next-line import/no-extraneous-dependencies
import { HeartRating } from 'baby-design/icons'

interface Props {
  description: string
  windowFormat: 'desktop' | 'mobile'
}

const RegistryDescription: React.FC<Props> = ({
  description,
  windowFormat,
}) => {
  const descriptionLines = description.split('\n') || []
  const descriptionCopy = descriptionLines.map((line, index) => {
    if (index < descriptionLines.length - 1) {
      return <p>{line}</p>
    }
    return line
  })
  const size = windowFormat === 'desktop' ? 'md' : 'sm'

  return (
    <div data-testid="AdditionalHeaderDetails-description">
      {windowFormat === 'desktop' ? (
        <CopyAndIcon
          copy={descriptionCopy}
          icon={<HeartRating />}
          size={size}
        />
      ) : (
        <ExpandableTextBox
          copy={descriptionCopy}
          icon={<HeartRating />}
          size={size}
        />
      )}
    </div>
  )
}

export default RegistryDescription
