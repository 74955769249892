import { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import { ExpandIcon, EmailIcon } from 'components/icons'
import { Input } from 'components/forms'
import Link from 'components/link'
import { LinkButton } from 'components/buttons'
import RegItemCardGhostList from 'registry/components/reg-item-card-ghost'
import { track, withContextualizedTracking } from 'lib/analytics'

import * as blUrls from 'lib/urls'
import { SectionHeading } from 'components/headings'
import { parseErrorMessage, parseErrors } from 'lib/api-utils'
import { errorMessage, successMessage } from 'lib/flash-message'
import GiftReturnModal from './components/gift-returns/gift-return-modal'
import UnreserveModal from './components/unreserve-modal'
import AddressNudgeCallout from './components/NoAddressCallout'
import EmptyState from './components/EmptyState'
import css from './GiftTracker.styles.scss'
import { getReservedRegItems, removeReservation } from '../api/Reservations'
import { ExtendedRegistry, Reservation } from './GiftTracker.types'
import GiftGiverList from './components/GiftGiverList'

interface GiftTrackerProps {
  registry: ExtendedRegistry
}

const hasAddress = (registry: ExtendedRegistry) => {
  if (!registry || !registry.shippingAddress) return false

  return !!(
    registry.shippingAddress.streetAddress1 &&
    registry.shippingAddress.city &&
    registry.shippingAddress.state &&
    registry.shippingAddress.zip
  )
}

const GiftTracker: FC<GiftTrackerProps> = ({ registry }) => {
  const [expanded, setExpanded] = useState(false)
  const [searching, setSearching] = useState(false)
  const [query, setQuery] = useState(null)
  const [isPageReady, setIsPageReady] = useState(false)
  const [reservations, setReservations] = useState<Reservation[]>([])
  const [filteredReservations, setFilteredReservations] = useState<
    Reservation[]
  >([])
  const [giftsPurchasedCount, setGiftsPurchasedCount] = useState(
    registry?.giftsPurchasedCount
  )
  const [isRemovingReservation, setIsRemovingReservation] = useState(false)

  useEffect(() => {
    if (!registry) return

    setGiftsPurchasedCount(registry.giftsPurchasedCount)
    getReservedRegItems(registry.id)
      .then((resp: { [registryId: string]: Reservation }) => {
        setReservations(Object.values(resp))
        setIsPageReady(true)
      })
      .catch((resp) =>
        Promise.reject(
          parseErrorMessage(resp) ||
            "Sorry, we're having trouble loading the items on this registry. Please contact Babylist support if this problem continues."
        )
      )
  }, [registry])

  useEffect(() => {
    setFilteredReservations(reservations.slice())
    setQuery(null)
  }, [reservations])

  useEffect(() => {
    if (query === null) {
      setFilteredReservations(reservations.slice())
      return
    }

    setFilteredReservations(
      reservations.filter((res) => {
        const search = new RegExp(query, 'gi')
        return (
          search.test(res.regItem?.title || '') ||
          search.test(res.name) ||
          search.test(res.email)
        )
      })
    )
  }, [query])

  const cancelReservation = (reservation: Reservation) => {
    if (isRemovingReservation) return

    setIsRemovingReservation(true)
    return removeReservation(reservation.token)
      .then(() => {
        setReservations(reservations.filter((res) => res.id !== reservation.id))
        successMessage(
          `${reservation.name}'s reservation for ${reservation.regItem?.title} has been cancelled.`
        )
        // Decrement the gifts purchased count by quantity
        // setGiftsPurchasedCount(giftsPurchasedCount - 1)
        setIsRemovingReservation(false)
      })
      .catch((resp) => {
        errorMessage(
          "Sorry, we're having trouble cancelling this reservation. Please contact Babylist support if this problem continues."
        )
        setIsRemovingReservation(false)
        return Promise.reject(parseErrors(resp))
      })
  }

  const resetFilter = () => {
    setSearching(false)
    setQuery(null)
  }

  const handleSearchChange = (event: any) => {
    const updatedQuery = event.target.value

    if (updatedQuery.length > 0) {
      setSearching(true)
      setQuery(updatedQuery)
    } else {
      resetFilter()
    }
  }

  const renderInputAddon = () => {
    let inputAddon = <i className="prm fa fa-search" />
    if (searching) {
      inputAddon = (
        <LinkButton
          className="pas input-group-addon--submit"
          onClick={resetFilter}
        >
          Clear
        </LinkButton>
      )
    }
    return inputAddon
  }

  // Group reservations by email and name
  const reservationsByGiftGiver = filteredReservations.reduce<{
    [key: string]: Reservation[]
  }>((acc, res) => {
    const key = `${res.email},${res.name}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(res)
    return acc
  }, {})
  const reservationEmails = Object.keys(reservationsByGiftGiver)
  // Ensure unique emails
  const emails = reservationEmails.reduce<string[]>((acc, email) => {
    if (acc.some((e) => e === email)) {
      return acc
    }
    return [...acc, email]
  }, [])

  const showAddressNudgeCallout =
    isPageReady && !hasAddress(registry) && filteredReservations.length > 0

  return (
    <div style={{ minHeight: '40vh' }}>
      <Row>
        <Col xs={12}>
          <div className={css.header}>
            <div>
              <SectionHeading className="mvn mbs">Gift tracker</SectionHeading>
              <div className="h6 mvm">
                {giftsPurchasedCount === undefined && (
                  <div className={css.purchaseCountAndThankYouPlaceholder} />
                )}
                {giftsPurchasedCount > 0 && (
                  <>
                    <strong>{giftsPurchasedCount}</strong>
                    {` Gift${giftsPurchasedCount > 1 ? 's' : ''} Purchased`}
                  </>
                )}
                {giftsPurchasedCount > 0 && (
                  <>
                    <span className="phm hidden-xs">|</span>
                    <Link
                      className="hidden-xs text-brand-info"
                      url="/registry/gift-tracker.csv"
                    >
                      <EmailIcon style={{ marginTop: -2 }} width="16" />{' '}
                      Download Thank You List
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className={classNames(css.actionBar)}>
              <Input
                addonAfter={renderInputAddon()}
                addonStyle={{ borderRadius: '0px 25px 25px 0px' }}
                aria-label="Search"
                className="brn pll"
                groupClassName={classNames(
                  css.search,
                  'mbn input-search input-search--bordered'
                )}
                placeholder="Search"
                value={query}
                onChange={handleSearchChange}
              />
              <LinkButton
                className="mll pan"
                onClick={() => setExpanded(!expanded)}
              >
                <ExpandIcon />
              </LinkButton>
            </div>
          </div>

          {showAddressNudgeCallout && <AddressNudgeCallout />}

          <div className={classNames(css.heading, 'hidden-xs')}>
            <span>Name</span>
            <span>Date</span>
            <span>Gifts</span>
          </div>

          {isPageReady ? (
            <GiftGiverList
              emails={emails}
              reservationsByGiftGiver={reservationsByGiftGiver}
              show={searching || expanded}
            />
          ) : (
            <RegItemCardGhostList />
          )}

          {isPageReady && filteredReservations.length == 0 && (
            <EmptyState
              hasAddress={hasAddress(registry)}
              query={query}
              searching={searching}
            />
          )}

          {isPageReady && hasAddress(registry) && (
            <div className="mvxl text-center">
              <Link className="h6 text-underline" url={blUrls.loopGiftPath}>
                Return a gift from the Babylist shop that doesn't appear in Gift
                Tracker
              </Link>
            </div>
          )}
        </Col>
      </Row>

      <GiftReturnModal />
      <UnreserveModal onUnreserve={cancelReservation} />
    </div>
  )
}

export default withContextualizedTracking({
  eventLocation: track.EventLocation.GIFT_TRACKER,
})(GiftTracker)
