import { useEffect } from 'react'
import {
  SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT,
  SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT,
} from 'shared/constants'
import RegistryFeedbackModal from './RegistryFeedbackModal'
import { Responses } from '../../global'

const REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY =
  'RegistryFeedbackModalNotificationDismissed'

export const useDisplayRegistryFeedbackModal = (
  registry: Responses.Registry,
  shouldShowModal: boolean,
  eventLocation: string
) => {
  useEffect(() => {
    if (shouldShowModal) {
      displayRegistryFeedbackModal(registry, eventLocation)
    }
  }, [shouldShowModal, registry, eventLocation])
}

export const displayRegistryFeedbackModal = (
  registry: Responses.Registry,
  eventLocation: string
) => {
  if (!window) return

  const isDismissed = localStorage.getItem(
    REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY
  )

  if (
    isDismissed ||
    registry?.type === 'holiday' ||
    registry?.type === 'birthday'
  )
    return

  // Delay opening of modal for 1 second
  setTimeout(() => {
    PubSub.publish(SET_UNIVERSAL_NOTIFICATION_MODAL_CONFIG_EVENT, {
      renderContent: (cm: () => void) => (
        <RegistryFeedbackModal
          closeModal={cm}
          eventLocation={eventLocation}
          registry={registry}
        />
      ),
      onModalOpen: () =>
        localStorage.setItem(REGISTRY_FEEDBACK_MODAL_LOCAL_STORAGE_KEY, 'true'),
    })
    PubSub.publish(SHOW_UNIVERSAL_NOTIFICATION_MODAL_EVENT)
  }, 1_000)
}
