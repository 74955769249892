/* eslint-disable */
import { Component } from 'react'
import RegItemCategory, {
  categoryProps,
} from '../reg-item-category/reg-item-category'
import PropTypes from 'prop-types'
import fade from './fade.scss'

class RegItemCategoryList extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape(categoryProps)),
    isAllCategoriesCollapsed: PropTypes.bool,
    isContributorView: PropTypes.bool,
    isRegistryDiscountView: PropTypes.bool,
    filters: PropTypes.object,
    reservationsByCurrentVisitor: PropTypes.object,
    reservedRegItemsByCurrentVisitor: PropTypes.array,
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters !== prevProps.filters && this.list) {
      this.list.className = fade.hidden
      this.timeout = setTimeout(() => (this.list.className = fade.fadeIn), 1)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  regDiscountCategories = () =>
    this.props.categories.map((category) => {
      const regItems = category.regItems.filter(
        (regItem) => regItem.registryDiscount > 0 && !regItem.isReserved
      )
      return { ...category, regItems }
    })

  render() {
    const {
      categories,
      reservationsByCurrentVisitor,
      isAllCategoriesCollapsed,
      isRegistryDiscountView,
      isContributorView,
    } = this.props

    const categoriesToDisplay = (
      isRegistryDiscountView ? this.regDiscountCategories() : categories
    )
      .filter((category) => category.regItems.length > 0)
      .map((category) => (
        <RegItemCategory
          key={category.id}
          category={category}
          isAllCategoriesCollapsed={isAllCategoriesCollapsed}
          reservationsByCurrentVisitor={reservationsByCurrentVisitor}
          isRegistryDiscountView={isRegistryDiscountView}
          isContributorView={isContributorView}
        />
      ))

    return <div ref={(list) => (this.list = list)}>{categoriesToDisplay}</div>
  }
}

export default RegItemCategoryList
