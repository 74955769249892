import { useParams } from 'react-router-dom-v5-compat'
import { ProductsAPI } from 'src/routes/(shop)/api/products'
import { ReviewTeaser as LegacyReviewTeaser } from '../../../../../../../../../store/components/product-reviews'

export const ReviewTeaser = () => {
  const { productId } = useParams()
  const { data: genericProduct } = ProductsAPI.useShow(productId)
  const { activeProduct } = genericProduct

  if (!activeProduct?.turntoSku) return null

  // @ts-ignore
  return <LegacyReviewTeaser sku={activeProduct.turntoSku} />
}
