/* eslint-disable complexity */
import { useState } from 'react'
import classNames from 'classnames'
import { RegistryProfileIcon } from 'components/icons'
import { LinkButton } from 'components/buttons'
import {
  createAnotherRegistryPath,
  createBirthdayWishlistPath,
  createHolidayWishlistPath,
  listsPath,
  registryPath,
} from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import Chip, { ChipVariants } from 'components/Chip'
import Chevron from 'shared/svg/chevron.svg'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { CurrentUser } from '../../../../../global'
import css from './RegistrySwitcher.styles.scss'
import RegistryAvatar from '../../../../../registry/components/registry-avatar'

type RegistryItem = CurrentUser['registries'][number]

interface RegistryListItemProp {
  registry: RegistryItem
  onClick?(registry: RegistryItem): void
}

interface SeeAllRegistriesButtonProp {
  registryCount: number
  onClick?(): void
}

interface CreateNewListButtonProp {
  onClick?(): void
  title: string
  displayChip?: boolean
}

const RegistryListItem = ({ registry, onClick }: RegistryListItemProp) => (
  <li className={css.listItem}>
    <LinkButton
      aria-label={`Switch to ${registry.title} list`}
      className={css.registryListItemButton}
      key={registry.urlSlug}
      onClick={onClick}
    >
      {registry.photoUrl ? (
        <RegistryAvatar
          className={css.registryAvatar}
          url={registry.photoUrl}
          onEdit={() => {}}
        />
      ) : (
        <RegistryProfileIcon
          className={css.registryAvatar}
          height={40}
          width={40}
        />
      )}
      <div className={css.listItemDetails}>
        <div className={css.registryTitle}>{registry.title}</div>
        <div className={css.itemCount}>
          {registry.regItemsCount}
          {registry.regItemsCount === 1 ? ' Item' : ' Items'}
        </div>
      </div>
    </LinkButton>
  </li>
)

const CreateNewListButton = ({
  onClick,
  title,
  displayChip = false,
}: CreateNewListButtonProp) => (
  <LinkButton
    className={classNames(css.registryListItem, css.newList)}
    onClick={onClick}
  >
    <span className={css.addIcon} />
    <div aria-label={`Create ${title}`} className={css.registryTitle}>
      {title}
    </div>
    {displayChip && (
      <Chip
        className="mlm"
        label="New!"
        size="small"
        variant={ChipVariants.SUCCESS}
      />
    )}
  </LinkButton>
)

const SeeAllRegistriesButton = ({
  registryCount,
  onClick,
}: SeeAllRegistriesButtonProp) => (
  <LinkButton
    className={classNames(css.registryListItem, css.seeAllRegistriesButton)}
    onClick={onClick}
  >
    <div>
      See all <span>{registryCount}</span> lists{' '}
      <Chevron className={css.seeAllChevron} />
    </div>
  </LinkButton>
)

const RegistrySwitcher = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const tracker = useTracking()
  const [currentUser] = useCurrentUser()

  const { currentRegistry, registries = [] } =
    currentUser || ({} as CurrentUser)
  const {
    title: currentRegistryTitle,
    id: currentRegistryId,
    ownerId: registryOwnerId,
  } = currentRegistry || ({} as CurrentUser['currentRegistry'])

  // on document click, close the dropdown if it's open
  document.addEventListener('click', (e) => {
    if (
      e.target instanceof HTMLElement &&
      !e.target.closest(`.${css.dropdownWrapper}`)
    ) {
      setDropdownOpen(false)
    }
  })

  const handleRegistrySwitched = (registry: RegistryItem) => {
    tracker.trackEvent({
      event: track.registrySwitched,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: registry.id,
      registryOwnerId,
    })

    setDropdownOpen(false)
    window.location.href = registryPath(registry.urlSlug)
  }

  const handleCreateNewRegistryClicked = () => {
    tracker.trackEvent({
      event: track.createNewRegistryClicked,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: currentRegistryId,
      registryOwnerId,
    })
    window.location.href = createAnotherRegistryPath
  }

  const handleListTypeClicked =
    (listType: string, redirectPath: string) => () => {
      tracker.trackEvent({
        event: track.listTypeClicked,
        eventLocation: track.EventLocation.REGISTRY,
        listType,
        registryId: currentRegistryId,
        registryOwnerId,
      })
      window.location.href = redirectPath
    }

  const handleAllRegistriesViewed = () => {
    tracker.trackEvent({
      event: track.allRegistriesViewed,
      eventLocation: track.EventLocation.REGISTRY,
      registryId: currentRegistryId,
      registryOwnerId,
    })
    window.location.href = listsPath
  }

  const handleDropdownClick = () => {
    if (!dropdownOpen) {
      tracker.trackEvent({
        event: track.registryDropdownClicked,
        eventLocation: track.EventLocation.REGISTRY,
        registryId: currentRegistryId,
        registryOwnerId,
      })
    }
    setDropdownOpen(!dropdownOpen)
  }

  const list = [...registries]
    .filter((registry) => registry.title !== currentRegistryTitle)
    .slice(0, 3)

  return (
    <>
      <div className={css.dropdownWrapper}>
        <button
          className={classNames(
            css.dropdownButton,
            dropdownOpen ? css.dropdownOpen : undefined,
            !currentUser ? css.loading : undefined
          )}
          type="button"
          onClick={handleDropdownClick}
        >
          <div
            aria-label="Switch to different list"
            className={css.dropdownTitle}
          >
            {currentRegistryTitle}
          </div>
          <Chevron
            className={classNames(
              css.dropdownChevron,
              dropdownOpen ? css.open : css.closed
            )}
          />
        </button>
        {dropdownOpen ? (
          <div className={css.listWrapper}>
            {list.length > 0 && (
              <div className={css.listSection}>
                <div className={css.listSectionTitle}>
                  Switch List <span className="sr-only">Section</span>
                </div>
                <ul className={classNames(css.list, css.registryList)}>
                  {list.map((registry) => (
                    <RegistryListItem
                      key={registry.urlSlug}
                      registry={registry}
                      onClick={() => handleRegistrySwitched(registry)}
                    />
                  ))}
                </ul>
                {registries.length > 1 && (
                  <SeeAllRegistriesButton
                    registryCount={registries.length}
                    onClick={handleAllRegistriesViewed}
                  />
                )}
              </div>
            )}
            <div className={css.listSection}>
              <div className={css.listSectionTitle}>
                Create New List <span className="sr-only">Section</span>
              </div>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <CreateNewListButton
                    title="New Registry"
                    onClick={handleCreateNewRegistryClicked}
                  />
                </li>
                <li className={css.listItem}>
                  <CreateNewListButton
                    title="Birthday Wishlist"
                    onClick={handleListTypeClicked(
                      track.ListType.BIRTHDAY,
                      createBirthdayWishlistPath
                    )}
                  />
                </li>
                <li className={css.listItem}>
                  <CreateNewListButton
                    title="Holiday Wishlist"
                    onClick={handleListTypeClicked(
                      track.ListType.HOLIDAY,
                      createHolidayWishlistPath
                    )}
                  />
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default RegistrySwitcher
