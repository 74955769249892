import React from 'react'

interface MobileNavMenuProps {
  className?: string
}

const MobileNavMenu: React.FC<MobileNavMenuProps> = ({
  className,
}: MobileNavMenuProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Mobile Nav Menu Icon</title>
    <g id="icon_menu">
      <path
        clipRule="evenodd"
        d="M3.30273 6.67383C3.30273 6.25961 3.63852 5.92383 4.05273 5.92383H20.0527C20.4669 5.92383 20.8027 6.25961 20.8027 6.67383C20.8027 7.08804 20.4669 7.42383 20.0527 7.42383H4.05273C3.63852 7.42383 3.30273 7.08804 3.30273 6.67383ZM3.30273 12.6738C3.30273 12.2596 3.63852 11.9238 4.05273 11.9238H20.0527C20.4669 11.9238 20.8027 12.2596 20.8027 12.6738C20.8027 13.088 20.4669 13.4238 20.0527 13.4238H4.05273C3.63852 13.4238 3.30273 13.088 3.30273 12.6738ZM3.30273 18.6738C3.30273 18.2596 3.63852 17.9238 4.05273 17.9238H20.0527C20.4669 17.9238 20.8027 18.2596 20.8027 18.6738C20.8027 19.088 20.4669 19.4238 20.0527 19.4238H4.05273C3.63852 19.4238 3.30273 19.088 3.30273 18.6738Z"
        fill="#4A2143"
        fillRule="evenodd"
        id="Vector"
      />
    </g>
  </svg>
)

export default MobileNavMenu
