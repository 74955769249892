import css from './RegistryCategories.skeleton.styles.scss'

const MostWantedCardSkeleton = () => (
  <div className={css.MostWantedCardSkeleton}>
    <div className={css.MostWantedCardSkeleton__Image} />
    <div className={css.MostWantedCardSkeleton__Content}>
      <div className={css.MostWantedCardSkeleton__Line} />
      <div className={css.MostWantedCardSkeleton__Line} />
      <div className={css.MostWantedCardSkeleton__Line} />
    </div>
  </div>
)

const RegItemSkeleton = () => (
  <div className={css.RegItemSkeleton}>
    <div className={css.RegItemSkeleton__Image} />
    <div className={css.RegItemSkeleton__Content}>
      <div className={css.RegItemSkeleton__Line} />
      <div className={css.RegItemSkeleton__Line} />
      <div className={css.RegItemSkeleton__Line} />
      <div className={css.RegItemSkeleton__Line} />
    </div>
  </div>
)

const RegistryCategoriesSkeleton = () => (
  <div
    className={css.RegistryCategoriesSkeleton}
    data-testid="RegistryCategoriesSkeleton"
  >
    <div className={css.RegistryCategoriesSkeleton__CategoryTitle} />
    <div className={css.RegistryCategoriesSkeleton__MostWanted}>
      {Array.from({ length: 4 }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <MostWantedCardSkeleton key={`mostwantedcard-${i}`} />
      ))}
    </div>

    <div className={css.RegistryCategoriesSkeleton__CategoryTitle} />
    <div className={css.RegistryCategoriesSkeleton__Categories}>
      {Array.from({ length: 10 }).map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <RegItemSkeleton key={`regItemSkeleton-${i}`} />
      ))}
    </div>
  </div>
)

export default RegistryCategoriesSkeleton
